export const getTransferStudentFormSchema = () => {
    let schema = {};
    schema = {
        type: 'object',
        properties: {
            transfer_sudent: {
                type: 'string',
                enum: ['Yes', 'No'],
            },
            agent: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'number',
                        },
                        name: {
                            type: 'string',
                        },
                    },
                },
            },
        },
        required: ['transfer_sudent'],
        allOf: [
            {
                if: {
                    properties: {
                        transfer_sudent: {
                            enum: ['Yes'],
                        },
                    },
                },
                then: {
                    required: ['agent'],
                },
            },
        ],
    };
    return schema;
};
