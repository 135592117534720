import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
        border: '1px solid #C4C4C4',
        boxShadow: 'unset',
        marginBottom: theme.spacing(3.25),
    },
    title: {
        color: theme.palette.text.primary,
        fontSize: theme.spacing(2),
        fontWeight: theme.spacing(76),
    },
    description: {
        color: theme.palette.text.primary,
        fontSize: theme.spacing(1.75),
        fontWeight: theme.spacing(62.5),
    },
    avatar: {
        width: theme.spacing(12.5),
        height: theme.spacing(10.75),
        borderRadius: theme.spacing(0.75),
    },
    buttonBox: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        height: theme.spacing(11.25),
        margin: theme.spacing('auto', 1.25),
    },
    button: {
        color: 'rgb(255, 255, 255)',
        backgroundColor: 'rgb(0, 200, 83)',
        fontSize: '14px',
        borderRadius: '10px',
        padding: '10px 28px',
    },
    imageLoader: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    avatarImg: {
        width: 'inherit',
        height: 'inherit',
    },
}));
