import { createAction } from '@reduxjs/toolkit';

//api
import { getOnboardingPartner } from '@/api';

//utils
import { deserializePartner } from '@/utils/deserializers/onboarding-deserializer';

export const getPartnerData = createAction('GET_PARTNER_DATA', (data) => ({
    payload: data,
}));

//action
export const PartnerDataGetAndStore = () => {
    return (dispatch) => {
        getOnboardingPartner()
            .then((res) => {
                if (res.data) {
                    dispatch(getPartnerData(deserializePartner(res.data)));
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };
};
