import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    accordianRoot: {
        boxShadow: 'none',
    },
    priorityIconP1: {
        color: '#D7330F',
        verticalAlign: 'bottom',
    },
    priorityIconP2: {
        color: '#F9945B',
        verticalAlign: 'bottom',
    },
    priorityIconP3: {
        color: '#5CAE7E',
        verticalAlign: 'bottom',
    },
    priorityIconP4: {
        color: '#81A8FD',
        verticalAlign: 'bottom',
    },
    priorityIconP5: {
        color: '#A7A7A7',
        verticalAlign: 'bottom',
    },
}));
