import React, { useState } from 'react';

//lodash
import { isEmpty } from 'lodash';

//propstype
import PropTypes from 'prop-types';

//mui
import {
    Grid,
    Typography,
    Box,
    CircularProgress,
    Avatar,
    CardHeader,
    Card,
    useMediaQuery,
    Skeleton,
    Tooltip,
} from '@mui/material';
//component
import { Button, ExploreServiceModal } from 'azent-storybook/dist';
import PackageHead from '../package-head';

// Styles
import { useStyles } from './style';
import { goToRoute } from '@/store/actions/navigationActions';
import routePaths from '@/utils/route-paths';

//store
import { useDispatch, useSelector } from 'react-redux';
import { postServices } from '@/api/ancillary';
import { deserializeAncillaryService } from '@/utils/deserializers/ancillary-service-deserializer';
import getModalProps from '@/props-constants/ancillary-props/enquire-modal-props/getModalProps';

export default function Header({
    title,
    description,
    enquire,
    logo,
    isEnquireEnabled,
    setIsEnquireEnabled,
    serviceId,
    studentId,
    BackButton,
    profileLoading,
    studentServiceLoading,
    enquireLabel,
    setMessage,
}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const classes = useStyles();
    const HeaderLoading = useSelector((state) => state.serviceCards.loading);
    const loading = studentServiceLoading ? profileLoading : HeaderLoading;
    const isMobile = useMediaQuery('(max-width:600px)');
    const dispatch = useDispatch();
    const modalProps = getModalProps(studentId, setIsModalOpen);

    function goBack() {
        dispatch(goToRoute(routePaths.SERVICES));
    }

    function onEnquireClick() {
        const payload = {
            name: title,
            cms_service_id: serviceId,
        };

        postServices(payload, studentId).then((res) => {
            const serviceList = res.data.subscribed_services;
            const deserializeServiceList = deserializeAncillaryService(serviceList);
            if (!isEmpty(deserializeServiceList)) {
                deserializeServiceList[serviceId] &&
                    setIsEnquireEnabled(deserializeServiceList[serviceId].isEnquireEnabled);
            } else {
                setIsEnquireEnabled(true);
            }
            setIsModalOpen(true);
        });
    }
    return (
        <>
            <Grid>
                {BackButton && (
                    <Grid container>
                        <PackageHead name="Back" goBack={goBack} />
                    </Grid>
                )}
                <Grid>
                    <Card align="left" className={classes.root}>
                        <CardHeader
                            avatar={
                                loading ? (
                                    <Box className={classes.imageLoader}>
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    !isMobile && (
                                        <Avatar aria-label="avatar" className={classes.avatar}>
                                            <img className={classes.avatarImg} {...logo} />
                                        </Avatar>
                                    )
                                )
                            }
                            action={
                                loading ? (
                                    <Skeleton
                                        className={classes.buttonBox}
                                        style={{ height: '30px' }}
                                    />
                                ) : (
                                    !isMobile && (
                                        <Tooltip
                                            title={!studentId ? 'Please select the student' : ''}
                                            onClick={() => setMessage(true)}
                                            arrow
                                            followCursor>
                                            <Box className={classes.buttonBox}>
                                                <Button
                                                    justify="center"
                                                    color={'success'}
                                                    variant={'contained'}
                                                    {...enquire}
                                                    label={
                                                        studentId
                                                            ? !isEnquireEnabled
                                                                ? 'Enquired'
                                                                : enquireLabel
                                                            : enquireLabel
                                                    }
                                                    onClick={onEnquireClick}
                                                    className={classes.button}
                                                    disabled={!studentId ? true : !isEnquireEnabled}
                                                />
                                            </Box>
                                        </Tooltip>
                                    )
                                )
                            }
                            title={
                                <Typography
                                    gutterBottom
                                    variant="h2"
                                    component="div"
                                    color="black"
                                    className={classes.title}>
                                    {loading ? (
                                        <Skeleton
                                            className={classes.title}
                                            style={{ height: '30px', width: '150px' }}
                                        />
                                    ) : (
                                        title
                                    )}
                                </Typography>
                            }
                            subheader={
                                <Box
                                    color="black"
                                    className={classes.description}
                                    style={{ fontWeight: 500 }}>
                                    {loading ? (
                                        <Skeleton className={classes.description} />
                                    ) : (
                                        description
                                    )}
                                </Box>
                            }
                        />
                    </Card>
                </Grid>
            </Grid>
            <ExploreServiceModal {...modalProps} open={isModalOpen} />
        </>
    );
}
Header.propTypes = {
    label: PropTypes.string,
    showModalOnSuccess: PropTypes.bool,
    logo: PropTypes.object,
    title: PropTypes.string,
    enquire: PropTypes.object,
    description: PropTypes.string,
    loading: PropTypes.bool,
    profileLoading: PropTypes.bool,
    isEnquireEnabled: PropTypes.bool,
    serviceId: PropTypes.string,
    BackButton: PropTypes.bool,
    enquireLabel: PropTypes.string,
    setIsEnquireEnabled: PropTypes.bool,
    studentId: PropTypes.string.isRequired,
    studentServiceLoading: PropTypes.bool,
    isStudentSelected: PropTypes.bool,
    setMessage: PropTypes.bool,
};
