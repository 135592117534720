import { getCountryPlaces } from '@/api';
import moment from 'moment';

export default function (isViewPartnerProfile = false) {
    const uiSchema = {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        label: 'Company Name (If Individual, please mention your name)',
                        scope: '#/properties/company_profile/properties/organisation_name',
                        options: {
                            readOnly: isViewPartnerProfile ? true : false,
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/company_profile/properties/office_address/properties/address1',
                        label: 'Address',
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/company_profile/properties/office_address/properties/state',
                        rule: {
                            effect: isViewPartnerProfile ? 'SHOW' : 'HIDE',
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                        options: {
                            readOnly: true,
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/company_profile/properties/office_address/properties/city',
                        rule: {
                            effect: isViewPartnerProfile ? 'SHOW' : 'HIDE',
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                        options: {
                            readOnly: true,
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/company_profile/properties/office_address/properties/pin_code',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/company_profile/properties/office_address/properties/country',
                        options: {
                            component: 'search',
                            searchApi: getCountryPlaces,
                            searchApiArgs: [{ search: '{{search}}' }],
                            valueKey: 'name',
                            labelKey: 'name',
                            readOnly: isViewPartnerProfile ? true : false,
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        label: 'Year Of Establishment',
                        scope: '#/properties/company_profile/properties/year_of_establishment',
                        options: {
                            formatMaximum: moment().add(0, 'years'),
                            formatMinimum: moment().subtract(50, 'years'),
                            component: 'yearPicker',
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        label: 'Type of Business',
                        scope: '#/properties/company_profile/properties/type_of_business',
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        label: 'Please write here*',
                        scope: '#/properties/company_profile/properties/other_business_details',
                    },
                ],
                rule: {
                    effect: 'SHOW',
                    condition: {
                        scope: '#/properties/company_profile/properties/type_of_business',
                        schema: {
                            enum: ['Other'],
                        },
                    },
                },
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        label: 'Number of Employees',
                        scope: '#/properties/company_profile/properties/number_of_employees',
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                label: 'Which countries do you recruit students for ?',
                elements: [
                    {
                        type: 'Control',
                        label: 'Which countries do you recruit students for ?',
                        scope: '#/properties/company_profile/properties/target_countries',
                        options: {
                            component: 'multiSelect',
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        label: `How many visa's you do in a year ?`,
                        scope: '#/properties/customer_info/properties/students_sent_abroad_each_year',
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        label: 'Which are the top three fields/streams your students chose to study abroad ?',
                        scope: '#/properties/customer_info/properties/top_three_streams',
                        options: {
                            component: 'multiSelect',
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        label: 'Which other services do you provide to your students ?',
                        scope: '#/properties/customer_info/properties/other_services',
                        options: {
                            component: 'multiSelect',
                        },
                    },
                ],
            },
        ],
    };
    if (isViewPartnerProfile) {
        uiSchema.elements.splice(0, 0, {
            type: 'HorizontalLayout',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/fname',
                    label: 'First name',
                    options: {
                        readOnly: true,
                    },
                },
                {
                    type: 'Control',
                    label: 'Last name',
                    scope: '#/properties/lname',
                    options: {
                        readOnly: true,
                    },
                },
            ],
        });
        uiSchema.elements.splice(1, 0, {
            type: 'HorizontalLayout',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/email',
                    options: {
                        readOnly: true,
                    },
                },
                {
                    type: 'Control',
                    scope: '#/properties/mobile',
                    options: {
                        readOnly: true,
                    },
                },
            ],
        });
    }
    return {
        smallScreenUiSchema: {},
        largeScreenUiSchema: uiSchema,
    };
}
