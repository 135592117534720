import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

// mui
import { Box, Switch, Grid, Typography } from '@mui/material';

// styles
import { useStyles } from './styles';

//utils
import { getUserAvatarByGender } from '@/utils/get-user-avatar';
import { deserializeSubagentInfo } from '@/utils/deserializers/subagentInfo-deserializer';

import { toastMessages } from '@/utils/toastMessages';

// rbac
import { isSubAgent } from '@/rbac';

// Actions
import { addSubAgentToStore } from '@/store/slices/subAgentSlice';
import { setToast } from '@/store/slices/toastSlice';

//components
import TransferStudentModal from '../../../ListSubAgent/partials/TransferStudentModal';

//api
import { changeSubAgentStatus } from '@/api/sub-agent';

export default function SubAgentHeader({
    id,
    name,
    createdDate,
    createdBy,
    subAgentData,
    updateSubAgentUserStatusData,
    partnerId,
}) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const { is_active } = useSelector((state) => state.subAgentData.data);
    const { assignedStudents } = useSelector((state) => state.subAgentData);
    const [formDataAgentTransfer, setFormDataAgentTransfer] = useState({});

    const handleClose = () => {
        setIsModalOpen(false);
    };

    const changeStatus = () => {
        setFormDataAgentTransfer({});
        if (!is_active) {
            changeSubAgentStatus(id, { is_active: true })
                .then((res) => {
                    updateSubAgentUserStatusData(res.data);
                    dispatch(setToast(toastMessages.successMessage));
                    dispatch(addSubAgentToStore(deserializeSubagentInfo(res.data.data.User)));
                })
                .catch((err) => {
                    dispatch(
                        setToast({
                            message:
                                err?.response?.data?.error?.message ||
                                toastMessages.backendIssue.message,
                            toasterColor: 'error',
                        }),
                    );
                });
        } else if (is_active && assignedStudents.length == 0) {
            changeSubAgentStatus(id, { is_active: false })
                .then((res) => {
                    updateSubAgentUserStatusData(res.data);
                    dispatch(setToast(toastMessages.successMessage));
                    dispatch(addSubAgentToStore(deserializeSubagentInfo(res.data.data.User)));
                })
                .catch((err) => {
                    dispatch(
                        setToast({
                            message:
                                err?.response?.data?.error?.message ||
                                toastMessages.backendIssue.message,
                            toasterColor: 'error',
                        }),
                    );
                });
        } else {
            setIsModalOpen(true);
        }
    };

    return (
        <>
            <Grid
                className={classes.container}
                pt={4}
                pb={1}
                px={6}
                container
                display={'flex'}
                justifyContent={'space-between'}>
                <Grid item container xs={4}>
                    <Grid className={classes.centerElement} item xs={3}>
                        <img src={getUserAvatarByGender()} alt="name" />
                    </Grid>
                    <Grid item xs={9} px={0.9}>
                        <Box pt={3} mb={1.5}>
                            {!id && (
                                <Typography pt={2} variant="h4">
                                    New User
                                </Typography>
                            )}
                            {name.trim() && <Typography variant="h4">{name}</Typography>}

                            {id && (
                                <>
                                    <Typography pt={1} className={classes.disabledText}>
                                        <Box className={classes.disabledKey}>Created date</Box>
                                        <Box component={'span'}>
                                            : {moment(createdDate).format('DD-MM-YYYY')}
                                        </Box>
                                    </Typography>
                                    <Typography className={classes.disabledText}>
                                        <Box className={classes.disabledKey}>Created by</Box>
                                        <Box component={'span'}>: {createdBy}</Box>
                                    </Typography>
                                </>
                            )}
                        </Box>
                    </Grid>
                </Grid>
                {id && !isSubAgent() && (
                    <Grid display={'flex'} pr={2} alignItems={'center'}>
                        <Switch
                            className={classes.switchBtn}
                            onClick={() => changeStatus()}
                            checked={is_active}
                        />
                        <Typography variant="h4"> {is_active ? 'Active' : 'Inactive'}</Typography>
                    </Grid>
                )}
            </Grid>
            <TransferStudentModal
                isModalOpen={isModalOpen}
                handleClose={handleClose}
                subAgentProfileData={subAgentData}
                updateSubAgentUserStatusData={updateSubAgentUserStatusData}
                partnerId={partnerId}
                formData={formDataAgentTransfer}
                setFormData={setFormDataAgentTransfer}
            />
        </>
    );
}

SubAgentHeader.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    createdDate: PropTypes.string,
    createdBy: PropTypes.string,
    subAgentData: PropTypes.object,
    updateSubAgentUserStatusData: PropTypes.func,
    partnerId: PropTypes.number,
};
