let config = {
    envName: 'production',
    studentApi: 'https://student-api.azent.com/',
    studentServiceApi: 'https://student-api.azent.com',
    authApi: 'https://api.azent.com/',
    universityApi: 'https://api.azent.com/',
    fileApi: 'https://api.azent.com/',
    fileUploadURL: '/aws-request-sign-awssigningrequest-13W23EBGNMGJV',
    website: 'https://azent.com/',
    oldB2BPlatform: 'https://platform.azent.com/',
    cmsApi: 'https://cms-service-v2.azent.com/v1',
    partnerApi: 'https://student-api.azent.com',
    awsConfig: {
        awsKey: 'AKIA4KCCKZYLKVL63MFF',
        bucket: 'b-b-profile-documents-prod',
        region: 'ap-south-1',
        s3BucketBaseUrl: 'https://b-b-profile-documents-prod.s3.ap-south-1.amazonaws.com',
    },
    cookieDetails: {
        name: 'azentPartnerUserData.production',
        path: '/',
        maxAge: 31536000,
        domain: '.admissionbox.com',
    },
    marketingWebsiteUrl: 'https://azent.com/',
    roundCubeURL: 'https://mx.myapplication.co.in/mail/',
    plagiarismServiceApi: 'https://plagiarism-service.azent.com',
};

export default config;
