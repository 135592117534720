import axios from 'axios';
import constants from '@/constants';
import { replace } from 'connected-react-router';
import { store } from '@/store';
import RoutePaths from '@/utils/route-paths';
import getHeaders from '@/utils/get-headers';
import { removeAuthTokenAndGotoLogin } from '@/store/actions/userActions';

let instance = axios.create({
    baseURL: constants.partnerApi,
    headers: getHeaders(),
});

instance.interceptors.request.use(
    function (config) {
        config.headers = getHeaders();
        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);
instance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        const { status } = error && error.response;
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error && error.response && error.response.status == 404) {
            store.dispatch(replace(RoutePaths.NOT_FOUND));
        }
        if (error && error.response && error.response.status == 403) {
            store.dispatch(replace(RoutePaths.NOT_AUTHORISED));
        }
        if (status === 401 || status === 403) {
            store.dispatch(removeAuthTokenAndGotoLogin());
        }

        return Promise.reject(error);
    },
);

export default instance;
