// Constants
import {
    getGTEDocumentCategoriesMapping,
    getGeneralDocumentCategoriesMapping,
    getMiscellaneousDocumentCategoriesMapping,
} from '@/utils/documents';

import { getCityPlaces, getStatePlaces, getCountryPlaces } from '@/api';

const largeScreenUiSchema = (config) => {
    let data = {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'Control',
                scope: '#/properties/address',
                label: 'Address Details',
                options: {
                    highlightLabel: true,
                    detail: {
                        type: 'VerticalLayout',
                        elements: [
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/address',
                                    },
                                ],
                            },
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/city',
                                        options: {
                                            component: 'search',
                                            searchApi: getCityPlaces,
                                            searchApiArgs: [{ search: '{{search}}' }],
                                            valueKey: 'name',
                                            labelKey: 'name',
                                        },
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/state',
                                        options: {
                                            component: 'search',
                                            searchApi: getStatePlaces,
                                            searchApiArgs: [{ search: '{{search}}' }],
                                            valueKey: 'name',
                                            labelKey: 'name',
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/country',
                                        options: {
                                            component: 'search',
                                            searchApi: getCountryPlaces,
                                            searchApiArgs: [{ search: '{{search}}' }],
                                            valueKey: 'name',
                                            labelKey: 'name',
                                        },
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/pin_code',
                                    },
                                ],
                            },
                        ],
                    },
                },
            },
            {
                type: 'Control',
                scope: '#/properties/passport',
                label: 'Passport Details',
                options: {
                    highlightLabel: true,
                    detail: {
                        type: 'VerticalLayout',
                        elements: [
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/passport_number',
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/place_of_issue',
                                    },
                                ],
                            },
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/issued_on',
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/valid_till',
                                    },
                                ],
                            },
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/name_on_passport',
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/surname_on_passport',
                                    },
                                ],
                            },
                        ],
                    },
                },
            },
            {
                type: 'Control',
                scope: '#/properties/general_documents',
                label: 'Personal Documents',
                options: {
                    labelHighlight: `(Resume, Picture, Passport, etc)`,
                    component: 'resource',
                    selectCategoryLabel: 'Choose Document Category',
                    uniqueCategory: true,
                    fileCategories: getGeneralDocumentCategoriesMapping(config),
                },
            },
            {
                type: 'Control',
                scope: '#/properties/miscellaneous_documents',
                label: 'Miscellaneous Documents',
                options: {
                    labelHighlight: `(SOPs, LORs, Phd Document, Degree & marksheets of spouse, Visa Refusal Document)`,
                    component: 'resource',
                    selectCategoryLabel: 'Choose Document Category',
                    uniqueCategory: false,
                    fileCategories: getMiscellaneousDocumentCategoriesMapping(config),
                },
            },

            {
                type: 'Control',
                scope: '#/properties/gte_documents',
                label: 'GTE Documents',
                options: {
                    labelHighlight: `(GTE Affidavit of Support, PAN Card and Identity proofs etc.`,
                    component: 'resource',
                    selectCategoryLabel: 'Choose Document Category',
                    uniqueCategory: false,
                    fileCategories: getGTEDocumentCategoriesMapping(config),
                },
                rule: {
                    effect: config.showGTEUploadOption ? 'SHOW' : 'HIDE',
                    condition: {
                        scope: '#',
                        schema: {},
                    },
                },
            },
            {
                type: 'Control',
                scope: '#/properties/background_info',
                label: 'Other Info',
                options: {
                    highlightLabel: true,
                    detail: {
                        type: 'VerticalLayout',
                        elements: [
                            {
                                type: 'Control',
                                scope: '#/properties/ever_applied_immigration',
                                label: 'Has the applicant applied for any type of immigration into any country?',
                                options: {
                                    component: 'switch',
                                    labelPlacement: 'start',
                                },
                            },
                            {
                                type: 'VerticalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/immigration_details',
                                    },
                                ],
                                rule: {
                                    effect: 'SHOW',
                                    condition: {
                                        scope: '#/properties/ever_applied_immigration',
                                        schema: {
                                            enum: [true],
                                        },
                                    },
                                },
                            },
                            {
                                type: 'Control',
                                scope: '#/properties/ever_refused_visa',
                                label: 'Has the applicant ever had their Visa refused for any country?',
                                options: {
                                    component: 'switch',
                                    labelPlacement: 'start',
                                },
                            },
                            {
                                type: 'VerticalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/visa_refusal_reason',
                                    },
                                ],
                                rule: {
                                    effect: 'SHOW',
                                    condition: {
                                        scope: '#/properties/ever_refused_visa',
                                        schema: {
                                            enum: [true],
                                        },
                                    },
                                },
                            },
                            {
                                type: 'Control',
                                scope: '#/properties/has_medical_condition',
                                label: 'Does the applicant suffer from a serious medical condition?',
                                options: {
                                    component: 'switch',
                                    labelPlacement: 'start',
                                },
                            },
                            {
                                type: 'VerticalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/medical_condition_details',
                                    },
                                ],
                                rule: {
                                    effect: 'SHOW',
                                    condition: {
                                        scope: '#/properties/has_medical_condition',
                                        schema: {
                                            enum: [true],
                                        },
                                    },
                                },
                            },
                            {
                                type: 'Control',
                                scope: '#/properties/ever_convicted_criminal_offence',
                                label: 'Has the applicant ever been convicted of a criminal offence?',
                                options: {
                                    component: 'switch',
                                    labelPlacement: 'start',
                                },
                            },
                            {
                                type: 'VerticalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/criminal_offence_details',
                                    },
                                ],
                                rule: {
                                    effect: 'SHOW',
                                    condition: {
                                        scope: '#/properties/ever_convicted_criminal_offence',
                                        schema: {
                                            enum: [true],
                                        },
                                    },
                                },
                            },
                        ],
                    },
                },
            },
        ],
    };
    return data;
};

const smallScreenUiSchema = () => ({});

export default function (config = {}) {
    return {
        smallScreenUiSchema: smallScreenUiSchema(config),
        largeScreenUiSchema: largeScreenUiSchema(config),
    };
}
