import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

// mui
import { Grid } from '@mui/material';

// comps
import Tiles from './partials/Tiles';

// props
import {
    getTileContentPropsFromIndex,
    getMainContentPropsFromIndex,
    getTileIndexFromRoute,
    getRouteFromTileIndex,
} from '@/props-constants/student/student-profile';
import getMyProfileProps from '@/props-constants/student-schema/index';

// utils
import { deserializeStudent } from '@/utils/deserializers/student-deserializer';
import routePaths, { studentPaths } from '@/utils/route-paths';
import { getProfileSplit } from '@/utils/student';

// actions
import { goToRoute, setQuery } from '@/store/actions/navigationActions';
import { addStudentToStore } from '@/store/slices/studentSlice';

export default function StudentDetails({
    tiles,
    profileData,
    studentId,
    applicationId,
    path,
    serviceId,
    services,
}) {
    const dispatch = useDispatch();

    const search = useSelector((state) => state.router.location.search);
    const globalData = useSelector((state) => state.globalData.data);

    const params = new URLSearchParams(search);
    const currentFormIndex = Number(params.get('currentIndex')) || 0;
    const currentTileIndex = getTileIndexFromRoute(path);

    const [dirtyFormData, setDirtyFormData] = useState(getProfileSplit(profileData, {}));
    const [submittedData, setSubmittedData] = useState(null);

    const moveToNextForm = () => {
        dispatch(setQuery('currentIndex', currentFormIndex + 1));
    };

    const moveToPreviousForm = () => {
        dispatch(setQuery('currentIndex', currentFormIndex - 1));
    };

    const setCurrentTileIndex = (index) => {
        if (services && services.length && getRouteFromTileIndex(index) === studentPaths.SERVICES) {
            dispatch(
                goToRoute(routePaths.STUDENT_PROFILE_VIEW, {
                    id: studentId,
                    aid: services && services[0].cms_service_id,
                    path: getRouteFromTileIndex(index),
                }),
            );
        } else {
            dispatch(
                goToRoute(routePaths.STUDENT_PROFILE_VIEW, {
                    id: studentId,
                    path: getRouteFromTileIndex(index),
                }),
            );
        }
    };
    const setFormIndex = (index) => {
        dispatch(
            goToRoute(
                routePaths.STUDENT_PROFILE_VIEW,
                { id: studentId, path: studentPaths.VIEW },
                { currentIndex: index },
            ),
        );
    };

    const onChangeHandler = (dirtyFormData) => {
        setDirtyFormData(dirtyFormData);
    };

    const onJourneySuccess = () => {
        dispatch(
            goToRoute(routePaths.STUDENT_PROFILE_VIEW, {
                id: studentId,
                path: studentPaths.SUMMARY,
            }),
        );
    };

    const onSuccessCallback = async (responseData) => {
        const deserializedResponse = deserializeStudent(responseData);
        dispatch(addStudentToStore(deserializedResponse));
        // dispatch(setQuery('currentIndex', currentFormIndex + 1));
    };

    const profileProps = useMemo(() => {
        let partner_id,
            intake_month = null;
        let targetCountries = [];
        if (dirtyFormData[0]?.partner?.id) partner_id = dirtyFormData[0].partner.id;
        if (dirtyFormData[0]?.desired_profile?.intake_month)
            intake_month = dirtyFormData[0].desired_profile.intake_month;
        if (
            dirtyFormData[0]?.desired_profile?.interest_countries &&
            dirtyFormData[0].desired_profile.interest_countries.length
        )
            targetCountries = dirtyFormData[0].desired_profile.interest_countries;
        let data = null;
        data = getMyProfileProps(
            globalData,
            dirtyFormData,
            studentId,
            profileData,
            intake_month,
            dispatch,
            null,
            partner_id,
            targetCountries,
        );
        setSubmittedData(data.journey.defaultData);
        return data;
    }, [
        profileData,
        globalData,
        studentId,
        dirtyFormData &&
            dirtyFormData[1].education_history &&
            dirtyFormData[1].education_history.highest_education_level,
        dirtyFormData &&
            dirtyFormData[0].desired_profile &&
            dirtyFormData[0].desired_profile.intake_month,
        dirtyFormData &&
            dirtyFormData[0].point_of_contact &&
            dirtyFormData[0].point_of_contact.copy_number,
        dirtyFormData && dirtyFormData[0].partner && dirtyFormData[0].partner.id,
        dirtyFormData &&
            dirtyFormData[0].desired_profile &&
            dirtyFormData[0].desired_profile.interest_countries,
    ]);

    const allProps = useMemo(() => {
        let allProps = {};
        if (profileProps)
            allProps = {
                editFormCurrentFormIndex: currentFormIndex,
                editFormSubmittedData: submittedData,
                editFormFormsData: profileProps.journey.formsData,
                editFormSetSubmittedData: setSubmittedData,
                editFormStyleProps: profileProps.journey.styleProps,
                editFormSetDirtyFormData: setDirtyFormData,
                editFormOnChangeHandler: onChangeHandler,
                editFormOnSuccessCallback: onSuccessCallback,
                editFormMoveToNextForm: moveToNextForm,
                editFormOnJourneySuccess: onJourneySuccess,
                editFormPreviousButton: profileProps.journey.previousButton,
                editFormOnStepChange: () => {},
                editFormSetCurrentFormIndex: setFormIndex,
                editFormMoveToPreviousForm: moveToPreviousForm,
                editFormDefaultData: profileProps.journey.defaultData,
                applicationId,
                applications: get(profileData, 'applications', []),
                profileData,
                serviceId,
                studentId,
                services,
            };
        return allProps;
    }, [
        profileProps,
        currentFormIndex,
        submittedData,
        profileData,
        applicationId,
        serviceId,
        studentId,
        services,
    ]);

    const mainContentProps = useMemo(
        () => getMainContentPropsFromIndex(currentTileIndex, allProps),
        [currentTileIndex, allProps],
    );

    const tileContentProps = useMemo(
        () => getTileContentPropsFromIndex(currentTileIndex, allProps),
        [currentTileIndex, allProps],
    );

    return (
        <Grid container my={3} px={7}>
            <Grid item xs={3}>
                <Tiles
                    tiles={tiles}
                    currentTileProps={tileContentProps}
                    setCurrentTile={setCurrentTileIndex}
                />
            </Grid>
            <Grid item p={0.2} xs={9}>
                {tiles[currentTileIndex].mainContent(mainContentProps)}
            </Grid>
        </Grid>
    );
}

StudentDetails.propTypes = {
    tiles: PropTypes.array.isRequired,
    profileData: PropTypes.object.isRequired,
    studentId: PropTypes.string.isRequired,
    applicationId: PropTypes.string,
    serviceId: PropTypes.string,
    path: PropTypes.string,
    services: PropTypes.array.isRequired,
};
