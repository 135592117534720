import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import { PermanentNavigationDrawer } from 'azent-storybook/dist';

// Utils
import getNavigationDrawerProps from '@/props-constants/NavigationDrawerProps';

export default function NavigationDrawer({ isVisibleActions }) {
    const dispatch = useDispatch();
    const location = useSelector((state) => state.router.location);

    const shouldHighlightNavigationAction = (action) => {
        if (action.goto === location.pathname) {
            return true;
        } else if (action.matchPath && action.matchPath === location.pathname.split('/')[1]) {
            return true;
        } else if (action.path === location.pathname) return true;
    };

    const onClickAction = (action) => {
        action.goto && action.goto();
    };

    const NavigationDrawerProps = getNavigationDrawerProps(dispatch, isVisibleActions);

    return (
        <PermanentNavigationDrawer
            actionsList={NavigationDrawerProps.actionsList}
            styleProps={NavigationDrawerProps.styleProps}
            shouldHighlight={shouldHighlightNavigationAction}
            onClickAction={onClickAction}
        />
    );
}

NavigationDrawer.propTypes = {
    isVisibleActions: PropTypes.bool,
};
