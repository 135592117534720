import Cookies from 'universal-cookie';

// Constants
import constants from '@/constants';

function checkCookieEnabled() {
    if (typeof window !== 'undefined' && window.document && navigator && navigator.cookieEnabled) {
        return true;
    } else {
        return false;
    }
}

function setAuthTokenCookie(tokenValue) {
    try {
        const cookies = new Cookies();
        cookies.set(constants.cookieDetails.name, tokenValue, {
            path: constants.cookieDetails.path,
            maxAge: constants.cookieDetails.maxAge,
            domain: constants.cookieDetails.domain,
            sameSite: "none",
            secure: true
        });
        return true;
    } catch {
        return false;
    }
}

const deleteAuthTokenCookie = () => {
    try {
        const cookies = new Cookies();
        cookies.remove(constants.cookieDetails.name, {
            path: constants.cookieDetails.path,
            maxAge: 0,
            domain: constants.cookieDetails.domain,
            expires: new Date(1990, 1, 1, 1, 1, 1, 1),
        });
        return true;
    } catch {
        return false;
    }
};

function getAuthCookie() {
    const cookies = new Cookies();
    return cookies.get(constants.cookieDetails.name);
}

export default {
    checkCookieEnabled,
    setAuthTokenCookie,
    deleteAuthTokenCookie,
    getAuthCookie,
};
