import { setShortlistState, shortlistProgramsForProfile, downloadShortlistPdf } from '@/api';
import { addStudentToStore } from '@/store/slices/studentSlice';
import { deserializeStudent } from './deserializers/student-deserializer';
import { isObject } from 'lodash';

export const intakesEnumOptions = [
    {
        label: 'Jan to Apr',
        value: 'Jan-Feb-Mar-Apr',
    },
    {
        label: '\xa0 Jan',
        value: 'Jan',
    },
    {
        label: '\xa0 Feb',
        value: 'Feb',
    },
    {
        label: '\xa0 Mar',
        value: 'Mar',
    },
    {
        label: '\xa0 Apr',
        value: 'Apr',
    },
    {
        label: 'May to Jul',
        value: 'May-Jun-Jul',
    },
    {
        label: '\xa0 May',
        value: 'May',
    },
    {
        label: '\xa0 Jun',
        value: 'Jun',
    },
    {
        label: '\xa0 Jul',
        value: 'Jul',
    },
    {
        label: 'Aug to Dec',
        value: 'Aug-Sep-Oct-Nov-Dec',
    },
    {
        label: '\xa0 Aug',
        value: 'Aug',
    },
    {
        label: '\xa0 Sep',
        value: 'Sep',
    },
    {
        label: '\xa0 Oct',
        value: 'Oct',
    },
    {
        label: '\xa0 Nov',
        value: 'Nov',
    },
    {
        label: '\xa0 Dec',
        value: 'Dec',
    },
];

export const courseApplied = function (profile, course) {
    return !!profile.applications.find((application) => {
        if (course.program_info.id)
            return application.program_info.program_id == course.program_info.id;
        return application.program_info.name == course.program_info.name;
    });
};
// NOTE : TO BE MERGED IN COURSE APPLIED
export const courseAppliedv2 = function (profile, course) {
    if (isObject(course)) {
        return !!profile.applications.find((application) => {
            if (course.id && application.program_info && application.program_info.program_id)
                return application.program_info.program_id == course.id;
            if (application.program_info && application.program_info.name)
                return application.program_info.name == course.program_info.name;
            return false;
        });
    }
};

export const courseShortlisted = function (profile, course) {
    if (isObject(course) && profile.shortlists.length)
        return !!profile.shortlists.find((shortlist) => shortlist.program_info.id == course._id);
};

export const getActiveShortlists = (profile) => {
    if (profile && profile.shortlists && profile.shortlists.length) {
        return profile.shortlists.filter(
            (i) => ['NEW'].includes(i.status) || courseApplied(profile, i),
        );
    } else {
        return [];
    }
};

export const getRejectedShortlists = (profile) => {
    if (profile && profile.shortlists && profile.shortlists.length) {
        return profile.shortlists.filter((i) => ['REJECTED'].includes(i.status));
    } else {
        return [];
    }
};

export const acceptShortlist = function (profile, shortlist, dispatch, setLoading) {
    return function () {
        setLoading(true);
        setShortlistState(profile.id, {
            _id: shortlist._id,
            status: 'NEW',
        })
            .then((response) => {
                dispatch(addStudentToStore(deserializeStudent(response.data)));
                setLoading(false);
            })
            .catch(() => {
                // TODO: Handle Error
            });
    };
};

export const rejectShortlist = function (profile, shortlist, dispatch, setLoading) {
    return function () {
        setLoading(true);
        setShortlistState(profile.id, {
            _id: shortlist._id,
            status: 'REJECTED',
        })
            .then((response) => {
                dispatch(addStudentToStore(deserializeStudent(response.data)));
                setLoading(false);
            })
            .catch(() => {
                // TODO: Handle Error
            });
    };
};

export const addToShortlist = (id, program) => {
    const {
        _id,
        name,
        categories,
        university,
        type,
        level,
        length,
        intake_months,
        costs,
        applicationCost,
        applicationDeadline,
        program_link,
        level_and_type,
    } = program;

    const payload = {
        shortlists: [
            {
                program_info: {
                    id: _id,
                    name: name,
                    tuition_fee: `${
                        costs && costs.tuition_fee && costs.currency
                            ? costs.currency + costs.tuition_fee
                            : '-'
                    }`,
                    intakes: intake_months,
                    type,
                    level,
                    level_and_type,
                    application_fee: applicationCost,
                    application_deadline: applicationDeadline,
                    duration: length,
                    categories: categories ? categories.map((item) => item.name) : [''],
                    rank: university.rank,
                    program_link: program_link,
                },
                university_info: {
                    id: university._id || university.id,
                    name: university.name,
                    address: {
                        country:
                            university.country ||
                            (university.location && university.location.country),
                        city:
                            university.city ||
                            (university.location && university.location.city) ||
                            '-',
                    },
                },
            },
        ],
    };

    return shortlistProgramsForProfile(id, payload);
};

export const downloadShortlist = (profileId, setLoading) => {
    setLoading && setLoading(true);
    downloadShortlistPdf(profileId, { type: 'excel' }).then((res) => {
        if (
            res.data === 'This File Type is Not Supported' ||
            res.data === 'Profile has no shortlists marked'
        ) {
            setLoading(false);
            return;
        }
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data.slice(
            2,
            -1,
        )}`;
        const downloadLink = document.createElement('a');
        const fileName = `${profileId}-shortlist.xlsx`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        setLoading(false);
    });
};
