import moment from 'moment';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const getDateFormat = (data = '', _format = 'YYYY-MM-DD') => {
    if (!data) {
        return data;
    }
    return moment(data).format(_format);
};

const getCurrentDate = (_format = 'YYYY-MM-DD') => {
    return moment().format(_format);
};

const getMonthNumberFromName = (key = '') =>
    months.findIndex((month) => month.toLowerCase().includes(key.toLowerCase()));

export { getDateFormat, getCurrentDate, getMonthNumberFromName };
