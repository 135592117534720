import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-material-ui-carousel';
export default function GenericCarousel({ children }) {
    return (
        <Carousel
            indicatorIconButtonProps={{
                style: {
                    padding: '10px',
                    color: '#C4C4C4',
                },
            }}
            activeIndicatorIconButtonProps={{
                style: {
                    backgroundColor: 'transparent',
                    color: '#F56A20',
                },
            }}
        >
            {children}
        </Carousel>
    );
}

GenericCarousel.propTypes = {
    children: PropTypes.node.isRequired,
};
GenericCarousel.defaultProps = {};
