import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// mui
import { Box, Typography, CircularProgress } from '@mui/material';

// Comps
import { FormComponent, getIcon, IconButton, Button } from 'azent-storybook/dist';

// utils
import { getSchema, getUiSchema } from '@/props-constants/course-finder/custom-program-schemas';
import { toastMessages } from '@/utils/toastMessages';

// Styles
import { useStyles } from './styles';

// API's
import { getPrograms, shortlistProgramsForProfile } from '@/api';
import { addStudentToStore } from '@/store/slices/studentSlice';
import { deserializeStudent } from '@/utils/deserializers/student-deserializer';
import { setToast } from '@/store/slices/toastSlice';

let shortlistFormErrors = null;

export default function AddCustomProgram({ setFormOpen, profileID }) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const globalData = useSelector((state) => state.globalData.data);

    const [formData, setFormData] = useState({});
    const [showFormErrors, setShowFormErrors] = useState(false);
    const [loading, setShortlistLoading] = useState(false);
    const [programs, setPrograms] = useState([]);

    const pageSchema = useMemo(() => {
        if (globalData) return getSchema(globalData);
    }, [globalData]);

    const uiSchema = useMemo(() => {
        return getUiSchema({
            universities: formData.universities,
            programs: programs.map((item) => {
                return { name: item.name };
            }),
        });
    }, [formData.universities, programs]);

    useEffect(() => {
        getProgramsData();
    }, [formData.universities]);

    const getProgramsData = () => {
        if (formData && formData.universities && formData.universities._id) {
            const searchObj = { university_ids: formData.universities._id };
            getPrograms(searchObj)
                .then((response) => {
                    setPrograms(response.data);
                })
                .catch(() => {
                    dispatch(setToast(toastMessages.backendIssue));
                });
        }
    };

    const handleChange = (data, errors) => {
        setFormData(data);
        shortlistFormErrors = errors;
    };

    const afterSubmit = () => {
        setFormOpen(false);
        setShortlistLoading(false);
    };

    const addProgram = () => {
        setShowFormErrors(true);
        if (!(shortlistFormErrors && shortlistFormErrors.length > 0)) {
            const university_info = {
                id: formData.universities._id,
                name: formData.universities.name,
            };
            delete formData.universities;
            const program_info = formData;
            setShortlistLoading(true);
            const data = {
                shortlists: [
                    {
                        program_info,
                        university_info,
                    },
                ],
            };
            // TODO : Add failure request
            shortlistProgramsForProfile(profileID, data)
                .then((res) => {
                    dispatch(addStudentToStore(deserializeStudent(res.data)));
                    afterSubmit();
                })
                .catch(() => {
                    dispatch(setToast(toastMessages.backendIssue));
                });
        }
    };

    return (
        <Box id="scrollView" className={classes.mainContainer}>
            <Box className={classes.titleContainer} justifyContent="space-between">
                <Box className={classes.title}>
                    <Typography variant="h6">Add custom Programs</Typography>
                </Box>
                <Box className={classes.iconContainer}>
                    <IconButton
                        icon={getIcon('close')}
                        onClick={() => setFormOpen(false)}
                        label="close"
                        size="small"
                    />
                </Box>
            </Box>
            <Box className={classes.formContainer}>
                <FormComponent
                    schema={pageSchema}
                    uischema={uiSchema}
                    data={formData}
                    onChange={({ data, errors }) => handleChange(data, errors)}
                    showFormErrors={showFormErrors}
                />
            </Box>
            <Box className={classes.buttonContainer}>
                <Button
                    variant="contained"
                    color="secondary"
                    label={loading ? <CircularProgress size={24} /> : 'Add to Shortlist'}
                    className={classes.addProgramButton}
                    onClick={addProgram}
                />
            </Box>
        </Box>
    );
}

AddCustomProgram.propTypes = {
    setFormOpen: PropTypes.func.isRequired,
    profileID: PropTypes.string.isRequired,
};
