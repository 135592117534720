import React from 'react';

// material
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

// styles
import { useStyles } from './styles';
import { Typography } from '@mui/material';

// assets
import loader_icon from '@/assets/loader_icon.png';

export default function Redirecting() {
    const classes = useStyles();
    return (
        <Box
            width="100%"
            height="80vh"
            display="flex"
            alignItems={'center'}
            justifyContent="center">
            <Box position="relative" display="flex" className={classes.loader}>
                <CircularProgress size={60} color="primary" thickness={2} />
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                    <img src={loader_icon} alt={'AdmissionBox'} className={classes.loaderImage} />
                </Box>
            </Box>
            <Typography className={classes.loading} mt={9}>
                Redirecting
            </Typography>
        </Box>
    );
}
