// material
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    backArrowIcon: {
        color: '#78A5FF',
        fontSize: '20px',
    },
    topContainer: {
        display: 'flex',
        justifyContent: (props) => (props.goBack ? 'space-between' : 'center'),
        marginBottom: theme.spacing(2),
        alignItems: 'center',
        fontSize: theme.spacing(2),
    },
    filterIcon: {
        height: theme.spacing(4),
        width: theme.spacing(4),
        background: 'transparent',
        color: '#78A5FF',
    },
    dotIcon: {
        width: theme.spacing(2.1),
        height: theme.spacing(2.1),
        background: theme.palette.primary.main,
        borderRadius: '50%',
    },
    headName: {
        fontWeight: (props) => (!props.isFilter ? 500 : 600),
        fontSize: theme.spacing(2),
        lineHeight: '20px',
        color: (props) => (!props.isFilter ? '#78A5FF' : theme.palette.primary.main),
        marginLeft: '20px',
    },
    desktopContainer: { display: 'flex', cursor: 'pointer' },
}));
