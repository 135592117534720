import React from 'react';
import routePaths from '@/utils/route-paths';
import ListSubAgent from '@/components/modules/SubAgent/ListSubAgent';
import CreateSubAgent from '@/components/modules/SubAgent/CreateSubAgent';
import UpdateSubAgent from '@/components/modules/SubAgent/UpdateSubAgent';

export const subAzentRoutes = [
    {
        routePath: routePaths.SUB_AGENT_INDEX,
        component: (props) => <ListSubAgent {...props} />,
    },
    {
        routePath: routePaths.SUB_AGENT_CREATE,
        component: (props) => <CreateSubAgent {...props} />,
    },
    {
        routePath: routePaths.SUB_AGENT_UPDATE,
        component: (props) => <UpdateSubAgent {...props} />,
    },
];
