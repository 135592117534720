export const getCommentsWithFormattedFileName = (formData, crmStudentId, studentFullName) => {
    if (formData.files) {
        let newFiles = [];
        formData.files.map((file) => {
            file.original_file_name = `${crmStudentId}_${studentFullName}_${file.category}_${file.original_file_name}`;
            newFiles.push(file);
        });
        formData.files = newFiles;
    }
    return formData;
};

export const addRequiredFieldsToFilesToSend = (formData) => {
    if (formData.files) {
        let newFiles = [];
        formData.files.map((file) => {
            let newFile = {
                ...file,
                upload_platform: 's3',
                label: 'Applications',
                platform: 'B2B',
            };
            newFiles.push(newFile);
        });
        formData.files = newFiles;
    }
    return formData;
};
