// API
import { getCityPlaces, getCountryPlaces } from '@/api';

const uiSchema = (isMobile) => {
    let uiSchema = {
        type: 'Control',
        scope: '#/properties/address',
        options: {
            detail: {
                type: 'VerticalLayout',
                elements: [],
            },
        },
    };

    const city = {
        type: 'Control',
        scope: '#/properties/city',
        options: {
            component: 'search',
            searchApi: getCityPlaces,
            searchApiArgs: [{ search: '{{search}}' }],
            valueKey: 'name',
            labelKey: 'name',
        },
    };
    const country = {
        type: 'Control',
        scope: '#/properties/country',
        options: {
            component: 'search',
            searchApi: getCountryPlaces,
            searchApiArgs: [{ search: '{{search}}' }],
            valueKey: 'name',
            labelKey: 'name',
        },
    };

    if (isMobile) {
        uiSchema.options.detail.elements.push(city);
        uiSchema.options.detail.elements.push(country);
    } else {
        uiSchema.options.detail.elements.push({
            type: 'HorizontalLayout',
            elements: [city, country],
        });
    }
    return uiSchema;
};

export default uiSchema;
