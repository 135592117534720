import { isB2bAdmin } from '@/rbac';

export default function () {
    const uiSchema = {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/point_of_contact/properties/first_name',
                        rule: {
                            effect: isB2bAdmin() ? 'DISABLE' : 'ENABLE',
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/point_of_contact/properties/last_name',
                        rule: {
                            effect: isB2bAdmin() ? 'DISABLE' : 'ENABLE',
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/point_of_contact/properties/email',
                        rule: {
                            effect: isB2bAdmin() ? 'DISABLE' : 'ENABLE',
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/point_of_contact/properties/mobile',
                        label: 'Mobile Number (10 digits only)',
                        rule: {
                            effect: isB2bAdmin() ? 'DISABLE' : 'ENABLE',
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                        options: {
                            type: 'number',
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'VerticalLayout',
                        elements: [
                            {
                                type: 'Control',
                                scope: '#/properties/point_of_contact/properties/whatsapp_number',
                                label: 'Whatsapp Number (10 digits only)',
                                rule: {
                                    effect: isB2bAdmin() ? 'DISABLE' : 'ENABLE',
                                    condition: {
                                        scope: '#',
                                        schema: {},
                                    },
                                },
                                options: {
                                    type: 'number',
                                },
                            },
                            {
                                type: 'Control',
                                scope: '#/properties/point_of_contact/properties/copy_number',
                                label: 'Set same as mobile number',
                                options: {
                                    component: 'switch',
                                    labelPlacement: 'start',
                                    linkedToOtherField: true,
                                },
                            },
                        ],
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/point_of_contact/properties/designation',
                        rule: {
                            effect: isB2bAdmin() ? 'DISABLE' : 'ENABLE',
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                    },
                ],
            },
        ],
    };

    return {
        smallScreenUiSchema: {},
        largeScreenUiSchema: uiSchema,
    };
}
