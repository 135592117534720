// API
import {
    getPartners,
    searchActiveSubAgentForStudent,
    searchCityStateCountry,
    keywordSearch,
} from '@/api';

// Rbac
import { isB2bAdmin, isPSA, isPartner } from '@/rbac';

const largeScreenUiSchema = (config, id, editAccess, referralPartnerId, targetCountries) => {
    const uiPartnerSchema = {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/first_name',
                        label: 'First Name (According to Passport)*',
                        options: {
                            readOnly: editAccess,
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/last_name',
                        label: 'Last Name (According to Passport)*',
                        options: {
                            readOnly: editAccess,
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/mobile',
                        label: 'Mobile Number (10 digits only)*',
                        rule: {
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                        options: {
                            //readOnly: Boolean(id),
                            type: 'number',
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/email',
                        label: 'Email ID*',
                        rule: {
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                        options: {
                            // readOnly: Boolean(id),
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/date_of_birth',
                        label: 'Date Of Birth*',
                        options: {
                            readOnly: editAccess,
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/nationality',
                        label: 'Nationality*',
                        options: {
                            readOnly: editAccess,
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/gender',
                        label: 'Gender*',
                        options: {
                            readOnly: editAccess,
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/marital_status',
                        label: 'Marital Status*',
                        options: {
                            readOnly: editAccess,
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/alternate_mobile',
                        label: 'Emergency Contact Number (10 digits only)*',
                        rule: {
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                        options: {
                            type: 'number',
                        },
                    },
                    {
                        type: 'HorizontalLayout',
                    },
                ],
            },
            {
                type: 'Control',
                scope: '#/properties/desired_profile',
                label: 'Target Profile',
                options: {
                    highlightLabel: true,
                    detail: {
                        type: 'VerticalLayout',
                        elements: [
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/interest_countries',
                                        label: 'Target Countries',
                                        options: {
                                            component: 'multiSelect',
                                            readOnly: editAccess,
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/interest_regions',
                                        label: 'Target Regions',
                                        options: {
                                            component: 'search',
                                            searchApi: searchCityStateCountry,
                                            searchApiArgs: [
                                                {
                                                    cities: `{{search}}`,
                                                    countries:
                                                        targetCountries && targetCountries.length
                                                            ? targetCountries
                                                            : [],
                                                },
                                            ],
                                            disableDelayedFetch: true,
                                            valueKey: 'label',
                                            labelKey: 'label',
                                            readOnly: editAccess,
                                        },
                                    },
                                ],
                                rule: {
                                    effect:
                                        targetCountries && targetCountries.length
                                            ? 'ENABLE'
                                            : 'DISABLE',
                                    condition: {
                                        scope: '',
                                        schema: {},
                                    },
                                },
                            },
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/degree_types_new',
                                        label: 'Target Degree types',
                                        options: {
                                            component: 'multiSelect',
                                            readOnly: editAccess,
                                        },
                                    },
                                ],
                            },

                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/keywords',
                                        label: 'Target Programs',
                                        options: {
                                            component: 'search',
                                            searchApi: keywordSearch,
                                            searchApiArgs: [{ text: '{{search}}' }],
                                            valueKey: 'keyword',
                                            labelKey: 'keyword',
                                            readOnly: editAccess,
                                        },
                                    },
                                ],
                            },

                            /*  {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/interest_sub_areas',
                                        label: 'Target Program Sub Category',
                                        options: {
                                            component: 'search',
                                            searchApi: subCategorySearch,
                                            readOnly: editAccess,
                                            //  eslint-disable no-mixed-spaces-and-tabs 
                                            searchApiArgs:
                                                config.categories && config.categories.length
                                                    ? [
                                                          {
                                                              q: '{{search}}',
                                                              categories: config.categories,
                                                              allCategories: config.allCategories,
                                                          },
                                                      ]
                                                    : [{ q: '{{search}}' }],
                                            valueKey: 'name',
                                            labelKey: 'name',
                                        },
                                        rule: {
                                            effect:
                                                config.categories && config.categories.length
                                                    ? 'ENABLE'
                                                    : 'DISABLE',
                                            condition: {
                                                scope: '',
                                                schema: {},
                                            },
                                        },
                                    },
                                ],
                            }, */
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/intake_month',
                                        label: 'Target Intake Month',
                                        options: {
                                            readOnly: editAccess,
                                        },
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/intake_year',
                                        label: 'Target Intake Year',
                                        options: {
                                            readOnly: editAccess,
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/note',
                                        label: 'Additional Notes about the Program for the Counselor',
                                        options: {
                                            multi: true,
                                            rows: 3,
                                            readOnly: editAccess,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                },
            },
            {
                type: 'Control',
                scope: '#/properties/point_of_contact',
                label: 'Point Of Contact',
                subLabel:
                    '(All communications for this student will be sent on the details mentioned below)',
                options: {
                    highlightLabel: true,
                    detail: {
                        type: 'VerticalLayout',
                        elements: [
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/first_name',
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/last_name',
                                    },
                                ],
                            },
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/email',
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/mobile',
                                        label: 'Mobile Number (10 digits only)',
                                        rule: {
                                            condition: {
                                                scope: '#',
                                                schema: {},
                                            },
                                        },
                                        options: {
                                            type: 'number',
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'VerticalLayout',
                                        elements: [
                                            {
                                                type: 'Control',
                                                label: 'Whatsapp Number (10 digits only)',
                                                scope: '#/properties/whatsapp_number',
                                                options: {
                                                    type: 'number',
                                                },
                                            },
                                            {
                                                type: 'Control',
                                                scope: '#/properties/copy_number',
                                                label: 'Set same as mobile number',
                                                options: {
                                                    component: 'switch',
                                                    labelPlacement: 'start',
                                                    linkedToOtherField: true,
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/designation',
                                    },
                                ],
                            },
                        ],
                    },
                },
            },
        ],
    };

    const uiB2bAdminSchema = {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/first_name',
                        label: 'First Name (According to Passport)*',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/last_name',
                        label: 'Last Name (According to Passport)*',
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/mobile',
                        label: 'Mobile Number (10 digits only)*',
                        rule: {
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                        options: {
                            type: 'number',
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/email',
                        label: 'Email ID*',
                        rule: {
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/date_of_birth',
                        label: 'Date Of Birth*',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/nationality',
                        label: 'Nationality*',
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/gender',
                        label: 'Gender*',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/marital_status',
                        label: 'Marital Status*',
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/partner',
                        label: 'Referral Partner*',
                        options: {
                            component: 'search',
                            searchApi: getPartners,
                            searchApiArgs: [{ q: '{{search}}' }],
                            valueKey: 'id',
                            labelKey: 'name',
                            readOnly: Boolean(id),
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/alternate_mobile',
                        label: 'Emergency Contact Number (10 digits only)*',
                        rule: {
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                        options: {
                            type: 'number',
                        },
                    },
                    {
                        type: 'VerticalLayout',
                    },
                ],
            },
            {
                type: 'Control',
                scope: '#/properties/desired_profile',
                label: 'Target Profile',
                options: {
                    highlightLabel: true,
                    detail: {
                        type: 'VerticalLayout',
                        elements: [
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/interest_countries',
                                        label: 'Target Countries',
                                        options: {
                                            component: 'multiSelect',
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/interest_regions',
                                        label: 'Target Regions',
                                        options: {
                                            component: 'search',
                                            searchApi: searchCityStateCountry,
                                            searchApiArgs: [
                                                {
                                                    cities: `{{search}}`,
                                                    countries:
                                                        targetCountries && targetCountries.length
                                                            ? targetCountries
                                                            : [],
                                                },
                                            ],
                                            disableDelayedFetch: true,
                                            valueKey: 'label',
                                            labelKey: 'label',
                                        },
                                    },
                                ],
                                rule: {
                                    effect:
                                        targetCountries && targetCountries.length
                                            ? 'ENABLE'
                                            : 'DISABLE',
                                    condition: {
                                        scope: '',
                                        schema: {},
                                    },
                                },
                            },
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/degree_types_new',
                                        label: 'Target Degree types',
                                        options: {
                                            component: 'multiSelect',
                                        },
                                    },
                                ],
                            },

                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/keywords',
                                        label: 'Target Programs',
                                        options: {
                                            component: 'search',
                                            searchApi: keywordSearch,
                                            searchApiArgs: [{ text: '{{search}}' }],
                                            valueKey: 'keyword',
                                            labelKey: 'keyword',
                                        },
                                    },
                                ],
                            },

                            /*  {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/interest_sub_areas',
                                        label: 'Target Program Sub Category',
                                        options: {
                                            component: 'search',
                                            searchApi: subCategorySearch,
                                            //eslint-disable no-mixed-spaces-and-tabs 
                                            searchApiArgs:
                                                config.categories && config.categories.length
                                                    ? [
                                                          {
                                                              q: '{{search}}',
                                                              categories: config.categories,
                                                              allCategories: config.allCategories,
                                                          },
                                                      ]
                                                    : [{ q: '{{search}}' }],
                                            valueKey: 'name',
                                            labelKey: 'name',
                                        },
                                        rule: {
                                            effect:
                                                config.categories && config.categories.length
                                                    ? 'ENABLE'
                                                    : 'DISABLE',
                                            condition: {
                                                scope: '',
                                                schema: {},
                                            },
                                        },
                                    },
                                ],
                            }, */
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/intake_month',
                                        label: 'Target Intake Month',
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/intake_year',
                                        label: 'Target Intake Year',
                                    },
                                ],
                            },
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/note',
                                        label: 'Additional Notes about the Program for the Counselor',
                                        options: {
                                            multi: true,
                                            rows: 3,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                },
            },
            {
                type: 'Control',
                scope: '#/properties/point_of_contact',
                label: 'Point Of Contact',
                subLabel:
                    '(All communications for this student will be sent on the details mentioned below)',
                options: {
                    highlightLabel: true,
                    detail: {
                        type: 'VerticalLayout',
                        elements: [
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/first_name',
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/last_name',
                                    },
                                ],
                            },
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/email',
                                    },
                                    {
                                        type: 'Control',
                                        label: 'Mobile Number (10 digits only)',
                                        scope: '#/properties/mobile',
                                        rule: {
                                            condition: {
                                                scope: '#',
                                                schema: {},
                                            },
                                        },
                                        options: {
                                            type: 'number',
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'VerticalLayout',
                                        elements: [
                                            {
                                                type: 'Control',
                                                label: 'Whatsapp Number (10 digits only)',
                                                scope: '#/properties/whatsapp_number',
                                                options: {
                                                    type: 'number',
                                                },
                                            },
                                            {
                                                type: 'Control',
                                                scope: '#/properties/copy_number',
                                                label: 'Set same as mobile number',
                                                options: {
                                                    component: 'switch',
                                                    labelPlacement: 'start',
                                                    linkedToOtherField: true,
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/designation',
                                    },
                                ],
                            },
                        ],
                    },
                },
            },
        ],
    };

    if (isB2bAdmin() || isPSA()) {
        uiB2bAdminSchema.elements[4].elements.splice(1, 0, {
            type: 'HorizontalLayout',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/sub_agent',
                    label: 'Counsellor / Branch*',
                    options: {
                        component: 'search',
                        searchApi: searchActiveSubAgentForStudent(referralPartnerId, true),
                        searchApiArgs: [{ q: '{{search}}' }],
                        valueKey: 'id',
                        labelExp: '{{first_name}} {{last_name}}',
                        readOnly: Boolean(id),
                    },
                    rule: {
                        effect: referralPartnerId ? 'ENABLE' : 'DISABLE',
                        condition: {
                            scope: '',
                            schema: {},
                        },
                    },
                },
            ],
        });
    } else if (isPartner()) {
        uiPartnerSchema.elements[4].elements.splice(0, 0, {
            type: 'HorizontalLayout',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/sub_agent',
                    label: 'Counsellor / Branch*',
                    options: {
                        component: 'search',
                        searchApi: searchActiveSubAgentForStudent(referralPartnerId, true),
                        searchApiArgs: [{ q: '{{search}}' }],
                        valueKey: 'id',
                        labelExp: '{{first_name}} {{last_name}}',
                        readOnly: Boolean(id),
                    },
                },
            ],
        });
        uiPartnerSchema.elements[4].elements.pop(-1);
    }

    if (isB2bAdmin() || isPSA()) {
        return uiB2bAdminSchema;
    }

    return uiPartnerSchema;
};

const smallScreenUiSchema = {};

export default function (
    config = {},
    id = '',
    partnerEditAccess = true,
    referralPartnerId,
    targetCountries,
) {
    return {
        smallScreenUiSchema: smallScreenUiSchema,
        largeScreenUiSchema: largeScreenUiSchema(
            config,
            id,
            partnerEditAccess,
            referralPartnerId,
            targetCountries,
        ),
    };
}
