import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

// material
import { Box, Grid, useTheme, useMediaQuery, CircularProgress, Typography } from '@mui/material';

//components
import NotSupportedView from '@/components/errors/NotSupportedView';
import MenuBar from '@/components/shared/MenuBar';
import NavigationDrawer from '@/components/shared/NavigationDrawer';

//styles
import { useStyles } from './style';

//rbac
import { isPartner, isB2bAdmin, isSubAgent } from '@/rbac';

export default function DefaultLayout({ isLoading, children, isPartnerOnboarded }) {
    const theme = useTheme();
    const classes = useStyles();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const partnerOrganizationName = isSubAgent()
        ? useSelector((state) => state.userData.userData.partner_name)
        : useSelector((state) => state.partnerData.data.company_profile.organisation_name);

    const isVisibleActions = isB2bAdmin()
        ? true
        : isPartnerOnboarded
        ? true
        : isSubAgent()
        ? true
        : false;

    if (isLoading)
        return (
            <Box sx={classes.loaderContainer}>
                <CircularProgress color={'primary'} />
            </Box>
        );
    if (isMobile) return <NotSupportedView />;

    return (
        <Box sx={classes.layoutContainer}>
            <MenuBar
                isVisibleActions={isVisibleActions}
                partnerOrganizationName={partnerOrganizationName}
            />
            <Grid sx={{ mt: 11 }} container>
                {isVisibleActions && (
                    <Grid item xs={1}>
                        <NavigationDrawer isVisibleActions={isVisibleActions} />
                    </Grid>
                )}
                <Grid item xs={isVisibleActions ? 11 : 12}>
                    <Box sx={{ minHeight: '94%' }}>{children}</Box>
                    {isPartner() && isVisibleActions && (
                        <Box my={1.5} className={classes.footerContainer}>
                            <Typography sx={{ fontSize: '12px' }}>
                                &#169;Powered by AdmissionBox
                            </Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}

DefaultLayout.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.any.isRequired,
    isPartnerOnboarded: PropTypes.bool,
};

DefaultLayout.defaultProps = {
    isLoading: true,
};
