// @ts-nocheck
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { hot } from 'react-hot-loader';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

// Router
import Router from './Router';
// utils
import theme from '@/utils/theme';
import { CssBaseline } from '@mui/material';

class Root extends Component {
    render() {
        const { store, history } = this.props;
        return (
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <StyledEngineProvider injectFirst>
                        <CssBaseline />
                        <Router history={history} />
                    </StyledEngineProvider>
                </ThemeProvider>
            </Provider>
        );
    }
}

Root.propTypes = {
    store: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

export default hot(module)(Root);
