import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lowerCase, get } from 'lodash';

// comps
import { FormJourney } from 'azent-storybook/dist';

// material
import Box from '@mui/material/Box';

//prop-constants
import { getOnboardingSchema } from '@/props-constants/registration/schema';
import getOrganisationUiSchema from '@/props-constants/registration/ui-schemas/organisation-profile-ui';
import getCommunicationDetailUiSchema from '@/props-constants/registration/ui-schemas/communication-detail-ui';

//utils
import { getProfileSplit, submitApiByIndex, checkProfileStepCompleted } from '@/utils/registration';
import { toastMessages } from '@/utils/toastMessages';
import * as navigationActions from '@/store/actions/navigationActions';

// API
import { signRequest } from '@/api';
import { setToast } from '@/store/slices/toastSlice';
import { PartnerDataGetAndStore } from '@/store/actions/partnerActions';

// Constants
import constants from '@/constants';

const steps = [0, 1];

export default function partnerProfile() {
    const dispatch = useDispatch();

    const profileData = useSelector((state) => state.partnerData.data);
    const search = useSelector((state) => state.router.location.search);
    const partnerId = useSelector((state) => state.partnerData.data._id);
    const userData = useSelector((state) => state.userData);

    const [basicData, setBasicData] = useState(null);

    const params = new URLSearchParams(search);
    const [currentIndex, setCurrentIndex] = useState(Number(params.get('index')) || 0);
    const isViewPartnerProfile = true;
    const [copyNumber, setCopyNumber] = useState(null);
    const [dirtyFormData, setDirtyFormData] = useState(null);

    useEffect(() => {
        if (userData.loading == false) {
            const { first_name, last_name, mobile, email } = userData.userData;
            setBasicData({ fname: first_name, lname: last_name, email: email, mobile: mobile });
        }
    }, [userData]);

    const onJourneySuccess = () => {};

    const onSuccessCallback = async () => {
        dispatch(PartnerDataGetAndStore());
        if (currentIndex == 0) {
            dispatch(navigationActions.setQuery('index', currentIndex + 1));
            setCurrentIndex(currentIndex + 1);
        }
    };

    const checkStepCompleted = (submittedDetails, index) => {
        // NOTE submitted details not coming properly
        if (profileData) return checkProfileStepCompleted(profileData, index);
    };

    const checkStepEnabled = (_data, index) => {
        if (index === 0 || index === 1) return true;
        else return false;
    };

    const onStepChange = (index) => {
        dispatch(navigationActions.setQuery('index', index));
        setCurrentIndex(index);
    };

    const getFormUiSchema = (index, isViewPartnerProfile) => {
        switch (index) {
            case 0: {
                return getOrganisationUiSchema(isViewPartnerProfile);
            }
            case 1: {
                return getCommunicationDetailUiSchema();
            }

            default:
                return {};
        }
    };

    const validateForm = (formData) => {
        //mandatory prop to be sent, if no error then dafault case send true

        if (formData && formData.step == 2) {
            if (formData.kyc) {
                if (formData.kyc.acceptance == false) {
                    dispatch(setToast(toastMessages.partnerDeclarationAcceptance));
                    return false;
                } else return true;
            }
        } else return true;
    };

    const formConstantsByIndex = (index) => {
        switch (index) {
            default:
                return {};
        }
    };

    const getStepTitleByIndex = (index) => {
        switch (index) {
            case 0:
                return 'Profile Details';
            case 1:
                return 'Point Of Contact';
            default:
                return '';
        }
    };

    const myProfileProps = useMemo(() => {
        let data = null;
        if (basicData)
            data = {
                journey: {
                    styleProps: {
                        showBorder: true,
                        titleVariant: 'h5',
                        borderColor: 'secondary',
                    },
                    formsData: steps.map((index) => {
                        return {
                            title: getStepTitleByIndex(index),
                            schema: getOnboardingSchema(index, isViewPartnerProfile),
                            uiSchemas: getFormUiSchema(index, isViewPartnerProfile),
                            submitButton: {
                                validateForm: validateForm, //mandatory prop
                                color: 'success',
                                label:
                                    steps.length - 1 === index ? 'Save Profile' : 'Save & Proceed',
                                onClick: (submittedData, formData, formConstants) => {
                                    return submitApiByIndex(
                                        index,
                                        submittedData,
                                        {
                                            ...formData,
                                            ...formConstants,
                                        },
                                        profileData,
                                    );
                                },
                            },

                            formConstants: formConstantsByIndex(index),
                            uploadConfig: {
                                awsConfig: constants.awsConfig,
                                s3Postfix: `${lowerCase(constants.envName)}/${partnerId}`,
                                selfAuthApiCall: signRequest,
                            },
                        };
                    }),
                    previousButton: {
                        label: 'Previous',
                        variant: 'outlined',
                        color: 'inherit',
                        sx: {
                            fontSize: '14px',
                            fontWeight: '600',
                            color: 'black',
                            borderColor: 'rgba(0,0,0,0.23)',
                            mr: 2,
                            '&:hover': {
                                borderColor: 'rgba(0,0,0,0.23)',
                            },
                        },
                    },
                    customGridSize: {
                        journeyStepper: { desktop: 3, mobile: 12 },
                        formBox: { desktop: 9, mobile: 12 },
                    },
                    defaultData: profileData
                        ? getProfileSplit(profileData, true, basicData, dirtyFormData)
                        : {},
                },
            };

        return data;
    }, [profileData, basicData, copyNumber]);

    useEffect(() => {
        if (dirtyFormData) {
            let copyNumber = get(dirtyFormData, '1.point_of_contact.copy_number', null);
            setCopyNumber(copyNumber);
        }
    }, [dirtyFormData]);

    const onChangeHandler = (dirtyFormData) => {
        setDirtyFormData(dirtyFormData);
    };

    const FormJourneyComponent = useMemo(() => {
        let data = null;
        if (myProfileProps && myProfileProps.journey) {
            data = (
                <Box>
                    <FormJourney
                        key="form-journey-component"
                        {...myProfileProps.journey}
                        currentIndex={currentIndex}
                        onMobileBackNavigation={() => {}}
                        onJourneySuccess={onJourneySuccess}
                        onSuccessCallback={onSuccessCallback}
                        checkStepCompleted={checkStepCompleted}
                        checkStepEnabled={checkStepEnabled}
                        onStepChange={onStepChange}
                        successMessage={'Form Submitted Successfully'}
                        onChangeHandler={onChangeHandler}
                    />
                </Box>
            );
        }
        return data;
    }, [myProfileProps, currentIndex]);

    return (
        <Box>
            <Box m={2} mr={10}>
                {FormJourneyComponent}
            </Box>
        </Box>
    );
}
