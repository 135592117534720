import React from 'react';
import { Box, Typography } from '@mui/material';
import { getIcon } from 'azent-storybook/dist';

export const passwordNote = () => {
    return (
        <Box pl={1} pt={1}>
            <Typography variant="h6" color="text.disabled">
                <span style={{ verticalAlign: 'middle', marginRight: 3 }}>
                    {getIcon('info', 'inherit', 'small')}
                </span>
                {`If you change your password, please inform your referral partner`}
            </Typography>
            <Typography variant="h6" color="text.disabled">
                <span style={{ verticalAlign: 'middle', marginRight: 3 }}>
                    {getIcon('info', 'inherit', 'small')}
                </span>
                {`Once you click on set password, you'll be logged out in 3 seconds. Re-Login with your new password`}
            </Typography>
        </Box>
    );
};
