import instance from './instances/student-service';
import { deserializePriorityData } from '@/props-constants/application/create-application';

export function getApplications(query) {
    let search = new URLSearchParams(query);
    return instance.get(`/v1/applications?${search.toString()}`);
}
export function getApplication(id) {
    return instance.get(`/v1/applications/${id}`);
}
export function createApplication(data) {
    return instance.post('/v1/applications', data);
}
export function updateApplication(id, data) {
    return instance.put(`/v1/applications/${id}`, data);
}

export function changeApplicationStatus(id, data) {
    return instance.patch(`/v1/applications/${id}/change_status`, data);
}

export function saveApplicationComments(id, data) {
    return instance.patch(`/v1/applications/${id}/comment`, data);
}

export function getApplicationPriorityData(id) {
    return new Promise((resolve, reject) => {
        instance
            .get(`/v1/profiles/${id}/applications/priority-list`)
            .then((response) => {
                const newPriority = response?.data?.priorities
                    ? deserializePriorityData(response.data.priorities)
                    : [];
                resolve({
                    data: { priorities: newPriority },
                });
            })
            .catch((e) => {
                reject(e);
            });
    });
}
