// defaults
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// mui
import { Box, Tooltip, Typography } from '@mui/material';

// comps
import { ActionBox, getIcon } from 'azent-storybook/dist';

// utils
import { getFileName } from '@/props-constants/student/student-profile';
import { startCase } from 'lodash';
import ROUTE_PATHS, { getFullURLRoute } from '@/utils/route-paths';
import { VIDEO_FORMATS } from '@/utils/documents';
import getHash from '@/utils/hash';

//assets
import play_icon from '@/assets/svg/play_circle.svg';

// styles
import { useStyles } from './styles';

export default function DocumentList({ documents }) {
    const classes = useStyles();

    if (!documents.length)
        return (
            <ActionBox
                subtitle={{
                    text: 'No Documents found',
                    variant: 'subtitle1',
                }}
                raised={false}
                elevation={0}
            />
        );
    return documents.map((document) => {
        let isImage = new RegExp(/image\/*/).test(
            document.type ? document.type : document.file.type,
        );
        const isVideo = VIDEO_FORMATS.includes(document.type ? document.type : document.file.type);

        const fileName = document.file
            ? document.file.original_file_name
            : document.original_file_name;

        let onClick = function () {
            let url = getFullURLRoute(
                '',
                ROUTE_PATHS.DOCUMENT_PREVIEW,
                { id: getHash(fileName) },
                {
                    url: document.file ? document.file.url : document.url,
                    type: document.file ? document.file.type : document.type,
                    fileName,
                },
            );
            window.open(url, '_blank');
        };

        return (
            <Box
                key={document.label}
                mt={2}
                flexWrap="wrap"
                sx={{
                    display: 'inline-flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                }}>
                <Tooltip
                    title={document.name ? document.name : document.file.original_file_name}
                    placement={'top'}>
                    <Box className={classes.cardContainer} onClick={onClick}>
                        {isImage ? (
                            <img
                                src={document.url ? document.url : document.file.url}
                                className={classes.resourceClass}
                            />
                        ) : isVideo ? (
                            <img src={play_icon} className={classes.resourceClass} />
                        ) : (
                            <Box className={classes.fileIconContainer}>
                                {getIcon('assignment', 'inherit', 'small', classes.fileIcon)}
                            </Box>
                        )}
                        <Box>
                            <Typography variant="subtitle1">
                                {document.name
                                    ? getFileName(document.name)
                                    : getFileName(document.file.original_file_name)}
                            </Typography>
                            <Typography variant="subtitle2" color="text.disabled">
                                By: {startCase(document.createdBy)} (
                                {moment.utc(document.createdAt).format('Do MMM, YYYY')})
                            </Typography>
                        </Box>
                    </Box>
                </Tooltip>
            </Box>
        );
    });
}

DocumentList.propTypes = {
    documents: PropTypes.array.isRequired,
};
