import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: '24px !important',
        color: theme.palette.text.disabled,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    spin: {
        color: theme.palette.text.disabled,
        fontSize: '24px !important',
        '&:hover': {
            cursor: 'pointer',
        },
        animation: '$spin 1s 1',
    },
    '@keyframes spin': {
        '0%': {
            transform: 'rotate(0deg)',
        },
        '100%': {
            transform: 'rotate(360deg)',
        },
    },
}));
