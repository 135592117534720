import React from 'react';

const ExploreUserIcon = () => {
    return (
        <svg
            width="135"
            height="122"
            viewBox="0 0 135 122"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="74.4111" cy="67.4113" r="51.2353" fill="#F2F4F8" />
            <path
                d="M90.75 120.941C97.5049 121.833 113.691 118.481 113.691 104.117C113.691 89.5881 97.3299 88.1622 42.5737 104.117C-15.1617 120.941 -25.4851 3.94067 94.9558 36.441"
                stroke="#ADADAD"
                strokeWidth="1.52941"
                strokeDasharray="1.53 1.53"
            />
            <path
                d="M53.1094 74.4294C53.1094 74.4294 42.7305 92.6979 57.4587 111.263C57.4587 111.263 58.1506 109.88 60.1275 109.781C60.1275 109.781 62.8953 113.929 69.8146 113.83C76.8327 113.731 79.7981 110.769 79.7981 110.769L76.7339 64.4558C76.635 64.4558 61.6102 60.5059 53.1094 74.4294Z"
                fill="#AD4F3D"
            />
            <path
                d="M96.6021 74.4291C96.6021 74.4291 106.981 92.6975 92.2528 111.262C92.2528 111.262 91.5609 109.88 89.584 109.781C89.584 109.781 86.8162 113.928 79.8969 113.83C72.9776 113.731 69.9134 110.768 69.9134 110.768L72.9776 64.4555C73.0765 64.5542 88.1012 60.5056 96.6021 74.4291Z"
                fill="#AD4F3D"
            />
            <path
                d="M86.222 107.411C88.9897 109.683 92.3504 113.83 92.3504 120.94H56.5679C56.5679 113.83 59.8298 109.683 62.6963 107.411H86.222Z"
                fill="#465059"
            />
            <path
                d="M57.6552 114.324C56.8644 116.397 56.5679 118.669 56.5679 120.94H59.1379C59.1379 113.83 62.3998 109.683 65.2663 107.411H62.6963C61.2136 108.596 59.5332 110.374 58.347 112.744C58.0505 113.238 57.8528 113.731 57.6552 114.324Z"
                fill="#3C474D"
            />
            <path
                d="M71.2997 116.99C65.0723 114.422 62.6011 107.411 62.6011 107.411C64.6769 105.732 66.555 105.041 66.555 105.041H82.2717C82.2717 105.041 84.1498 105.633 86.2256 107.411C86.2256 107.411 83.7543 114.422 77.527 116.99H71.2997Z"
                fill="#EFEDEE"
            />
            <path
                d="M63.3919 109.09C63.985 108.398 64.578 107.905 65.1711 107.411C67.2469 105.732 69.125 105.041 69.125 105.041H66.555C66.555 105.041 66.3573 105.14 66.0607 105.239C64.7757 105.831 63.6884 106.522 62.6011 107.411C62.6011 107.411 62.8977 108.003 63.3919 109.09Z"
                fill="#D4D2D3"
            />
            <path
                d="M77.5258 116.989C76.5373 117.384 75.5489 117.68 74.4615 117.779C73.3742 117.68 72.2869 117.384 71.3973 116.989C71.7927 113.237 72.6822 110.175 72.6822 110.175L74.4615 108.102L76.2408 110.175C76.2408 110.175 77.1304 113.237 77.5258 116.989Z"
                fill="#C0344A"
            />
            <path
                d="M78.2171 105.931C78.2171 105.931 78.1183 107.609 76.2402 110.177H72.6816C70.8035 107.609 70.7047 105.931 70.7047 105.931H78.2171Z"
                fill="#DA5B65"
            />
            <path
                d="M60.8191 120.941V116.003C60.8191 115.51 61.2145 115.016 61.8076 115.016C62.3018 115.016 62.796 115.411 62.796 116.003V120.842H60.8191V120.941Z"
                fill="#3C474D"
            />
            <path
                d="M86.1238 120.941V116.003C86.1238 115.51 86.5192 115.016 87.1123 115.016C87.6065 115.016 88.1007 115.411 88.1007 116.003V120.842H86.1238V120.941Z"
                fill="#3C474D"
            />
            <path
                d="M48.6626 85.3904C48.6626 78.7743 51.3315 72.6519 56.2738 68.0107C61.1173 63.3695 67.5424 60.9008 74.3629 60.9008C81.1833 60.9008 87.6083 63.4683 92.4518 68.0107C97.3942 72.6519 100.063 78.873 100.063 85.3904H48.6626Z"
                fill="#C86D5C"
            />
            <path
                d="M63.8849 67.9124C67.8388 64.1599 72.7812 61.79 78.2178 61C76.9328 60.8025 75.7465 60.7037 74.4615 60.7037C67.6411 60.7037 61.216 63.2712 56.3725 67.8136C51.4301 72.4548 48.7613 78.6759 48.7613 85.1933H56.3725C56.2736 78.7747 58.9426 72.5535 63.8849 67.9124Z"
                fill="#AD4F3D"
            />
            <path d="M51.5297 60.8034H97.3946V74.1344H51.5297V60.8034Z" fill="#465059" />
            <path
                d="M101.942 85.786C101.942 88.4522 99.767 90.6246 97.0981 90.6246C94.4292 90.6246 92.2546 88.4522 92.2546 85.786C92.2546 83.1198 94.4292 80.9473 97.0981 80.9473C99.767 80.9473 101.942 83.1198 101.942 85.786Z"
                fill="#DFB297"
            />
            <path
                d="M56.1751 78.9716C53.5062 79.9591 51.727 82.5266 51.727 85.3903C51.727 97.2401 61.8094 106.819 74.363 106.819C86.8177 106.819 96.999 97.2401 96.999 85.3903C96.999 83.3165 96.7024 81.2428 96.1093 79.3666C95.7139 78.1816 94.4289 77.5891 93.3416 78.0829C78.4156 84.6003 68.2344 74.4292 56.1751 78.9716Z"
                fill="#FBC4AC"
            />
            <path
                d="M55.5814 85.3908C55.5814 85.292 55.5814 85.292 55.5814 85.3908C55.5814 83.5145 56.2733 80.6508 57.5583 78.4784C57.163 78.5771 56.6687 78.7746 56.1745 78.9721C54.8894 79.4659 53.901 80.2558 53.1102 81.2433C52.8137 81.0458 52.3194 80.9471 51.7263 80.9471C49.0575 80.9471 46.8828 83.1195 46.8828 85.7857C46.8828 88.452 49.0575 90.6244 51.7263 90.6244C51.924 90.6244 52.2205 90.6244 52.4182 90.5257C54.8894 99.9068 63.7857 106.819 74.3623 106.819C74.9554 106.819 75.6474 106.819 76.2405 106.72C64.6754 105.832 55.5814 96.5493 55.5814 85.3908Z"
                fill="#DFB297"
            />
            <path
                d="M86.2256 87.1686C85.7313 87.1686 85.2371 86.7736 85.2371 86.1811V83.9099C85.2371 83.4162 85.6325 82.9224 86.2256 82.9224C86.7198 82.9224 87.2141 83.3174 87.2141 83.9099V86.1811C87.2141 86.7736 86.8187 87.1686 86.2256 87.1686Z"
                fill="#3C474D"
            />
            <path
                d="M87.8061 90.5256H84.643C84.1488 90.5256 83.6546 90.1306 83.6546 89.5381C83.6546 89.0444 84.0499 88.5506 84.643 88.5506H87.8061C88.3003 88.5506 88.7946 88.9456 88.7946 89.5381C88.7946 90.1306 88.3003 90.5256 87.8061 90.5256Z"
                fill="#F98084"
            />
            <path
                d="M62.601 87.1686C62.1068 87.1686 61.6125 86.7736 61.6125 86.1811V83.9099C61.6125 83.4162 62.0079 82.9224 62.601 82.9224C63.0952 82.9224 63.5895 83.3174 63.5895 83.9099V86.1811C63.5895 86.7736 63.0952 87.1686 62.601 87.1686Z"
                fill="#3C474D"
            />
            <path
                d="M64.1804 90.5256H61.0174C60.5231 90.5256 60.0289 90.1306 60.0289 89.5381C60.0289 89.0444 60.4243 88.5506 61.0174 88.5506H64.1804C64.6747 88.5506 65.1689 88.9456 65.1689 89.5381C65.1689 90.1306 64.6747 90.5256 64.1804 90.5256Z"
                fill="#F98084"
            />
            <path
                d="M74.4612 90.4269C73.3739 90.4269 72.2865 89.9332 71.5946 89.1432C71.2981 88.7482 71.298 88.1557 71.6934 87.7607C72.0888 87.4644 72.6819 87.4644 73.0773 87.8594C73.3738 88.2544 73.967 88.4519 74.4612 88.4519C75.0543 88.4519 75.5485 88.2544 75.8451 87.8594C76.1416 87.4644 76.8336 87.4644 77.229 87.7607C77.6243 88.0569 77.6243 88.7482 77.3278 89.1432C76.537 89.9332 75.5485 90.4269 74.4612 90.4269Z"
                fill="#3C474D"
            />
            <path d="M51.5297 60.8034H97.3946V67.7157H51.5297V60.8034Z" fill="#3C474D" />
            <path
                d="M100.26 58.2348H48.5631C47.6735 58.2348 46.9815 58.926 46.9815 59.8148V62.2835C46.9815 63.1722 47.6735 63.8635 48.5631 63.8635H100.26C101.15 63.8635 101.842 63.1722 101.842 62.2835V59.8148C101.94 59.0248 101.15 58.2348 100.26 58.2348Z"
                fill="#465059"
            />
            <path
                d="M124.283 28.816C123.788 29.0138 123.264 29.316 122.763 29.7115C121.283 30.8805 120.554 32.4253 121.134 33.1621C121.716 33.8991 123.388 33.5476 124.868 32.3772C125.369 31.9817 125.784 31.543 126.092 31.1061L124.283 28.816Z"
                fill="#3E4347"
            />
            <path
                d="M125.7 31.5989C125.845 31.4363 125.977 31.2706 126.092 31.1059L124.284 28.8158C124.097 28.8901 123.905 28.9798 123.713 29.0837L125.7 31.5989Z"
                fill="#DEE4E9"
            />
            <path
                d="M124.059 36.1569C123.564 36.3547 123.039 36.6568 122.539 37.0524C121.058 38.2214 120.329 39.7662 120.91 40.503C121.49 41.2398 123.163 40.8885 124.644 39.7181C125.145 39.3225 125.56 38.8825 125.867 38.447L124.059 36.1569Z"
                fill="#3E4347"
            />
            <path
                d="M125.475 38.9398C125.621 38.7771 125.752 38.6114 125.868 38.4467L124.059 36.1567C123.872 36.231 123.68 36.3206 123.489 36.4246L125.475 38.9398Z"
                fill="#DEE4E9"
            />
            <path
                d="M109.25 9.78083C108.943 10.2164 108.527 10.6564 108.026 11.0519C106.546 12.221 104.874 12.5724 104.292 11.8369C103.712 11.1001 104.441 9.55528 105.921 8.38482C106.422 7.9893 106.946 7.68712 107.441 7.48932L109.25 9.78083Z"
                fill="#3E4347"
            />
            <path
                d="M106.869 7.75709C107.061 7.65311 107.253 7.56347 107.44 7.48911L109.25 9.77937C109.134 9.94407 109.003 10.1098 108.857 10.2724L106.869 7.75709Z"
                fill="#DEE4E9"
            />
            <path
                d="M102.057 8.29752C101.75 8.73447 101.335 9.1731 100.834 9.56862C99.3523 10.7389 97.6807 11.0889 97.0999 10.3536C96.5179 9.61662 97.2472 8.0718 98.7288 6.90293C99.2297 6.5074 99.7526 6.20506 100.249 6.00743L102.057 8.29752Z"
                fill="#3E4347"
            />
            <path
                d="M99.678 6.27552C99.8699 6.17154 100.06 6.08174 100.249 6.00754L102.057 8.29763C101.942 8.46234 101.81 8.62804 101.664 8.79066L99.678 6.27552Z"
                fill="#DEE4E9"
            />
            <path
                d="M122.046 45.6529L117.516 49.23C117.017 49.6243 116.277 49.3648 116.132 48.7454L110.44 24.2942L87.9745 13.0918C87.4054 12.8078 87.3245 12.0277 87.8238 11.6334L92.3532 8.05625C92.6511 7.82094 93.0374 7.72686 93.4113 7.79953L123.833 13.7166L122.541 44.683C122.525 45.0636 122.344 45.4176 122.046 45.6529Z"
                fill="#C64F10"
            />
            <path
                d="M99.4679 42.8511L96.4598 45.2281C96.268 45.3796 95.983 45.2727 95.9379 45.0317L94.5003 37.3316C94.4543 37.0861 94.2918 36.8787 94.0635 36.7785L86.9076 33.5958C86.6807 33.4958 86.6442 33.1939 86.836 33.0425L89.8456 30.6656C90.0484 30.5054 90.3069 30.4351 90.5629 30.4709L100.103 31.7872C100.507 31.8431 100.795 32.2062 100.754 32.6113L99.825 42.1986C99.7991 42.4558 99.6706 42.6923 99.4679 42.8511Z"
                fill="#3E4347"
            />
            <path
                d="M125.73 20.4528C115.665 28.4004 93.2222 42.4398 91.0106 39.6394C88.799 36.839 107.657 18.2608 117.722 10.3117C124.999 4.56418 130.831 3.64465 133.043 6.44502C135.253 9.24522 133.007 14.7038 125.73 20.4528Z"
                fill="#FB6F29"
            />
            <path
                d="M128.872 9.33933L127.314 10.5697C127.208 10.6536 127.054 10.6412 126.963 10.5399C126.143 9.6384 125.171 8.98653 124.177 8.62105C123.975 8.54551 123.951 8.26813 124.138 8.15781C125.431 7.40233 127.502 7.62412 128.892 8.95199C129.004 9.06009 128.994 9.24295 128.872 9.33933Z"
                fill="#3E4347"
            />
            <path
                d="M128.87 14.5625C128.743 13.511 128.336 12.4135 127.649 11.4083C127.571 11.2957 127.595 11.1446 127.701 11.0593L129.259 9.82893C129.381 9.73256 129.561 9.76523 129.64 9.89961C130.611 11.5607 130.346 13.6273 129.311 14.7078C129.162 14.8643 128.896 14.777 128.87 14.5625Z"
                fill="#3E4347"
            />
            <path
                d="M95.4152 37.6778C94.9191 38.0696 94.3764 38.4055 93.7974 38.6711L90.0615 40.389L92.5999 37.1516C93.924 35.4639 95.926 34.3446 97.9302 34.1746C97.4862 35.5185 96.5863 36.7529 95.4152 37.6778Z"
                fill="#5A5F63"
            />
        </svg>
    );
};

export default ExploreUserIcon;
