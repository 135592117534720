import { ListItemButton } from '@mui/material';
import { withStyles } from '@mui/styles';

export const StyledItem = withStyles({
    root: {
        '&.Mui-selected': {
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        padding: '0px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
})(ListItemButton);
