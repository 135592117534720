import React from 'react';

// mui
import Box from '@mui/material/Box';

// assets
import notFound from '@/assets/svg/404.svg';

export function NotFound() {
    return (
        <Box
            width="100%"
            height="60%"
            display={'flex'}
            alignItems="center"
            justifyContent={'center'}
        >
            <img src={notFound} alt="This Page does not exist!" />
        </Box>
    );
}
