import instance from './instances/cms-service';

export function fetchServicesFromCms(query = {}) {
    const newQuery = { ...query };
    newQuery.populate = ['logo', 'button', 'partners', 'partners.logo', 'enquire', 'faq'];
    const searchParams = new URLSearchParams(newQuery);
    return instance.get(`/service-pages?${searchParams.toString()}`);
}

export function fetchServiceFromCms(id) {
    const newQuery = {};
    newQuery.populate = ['logo', 'button', 'partners', 'partners.logo', 'enquire', 'faq'];
    const searchParams = new URLSearchParams(newQuery);
    return instance.get(`/service-pages/${id}?${searchParams.toString()}`);
}
