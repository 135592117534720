import React from 'react';

//mui styles
import { useStyles } from './style';

//mui
import { CardContent, Card, Box, Skeleton } from '@mui/material';

//component
import { PartnersCard } from 'azent-storybook/dist';

//store
import { useSelector } from 'react-redux';

//utils
import Markdown from '@/utils/Markdown';
import PropTypes from 'prop-types';

export default function Description({
    partners,
    fullDescription,
    profileLoading,
    studentServiceLoading,
}) {
    const classes = useStyles();
    const title = 'Partners';
    const DescriptionLoading = useSelector((state) => state.serviceCards.loading);
    const loading = studentServiceLoading ? profileLoading : DescriptionLoading;
    const loadingCards = [1, 2];
    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                <Box align="left" component="div" className={classes.description}>
                    {loading ? (
                        <>
                            <Skeleton className={classes.fullDescriptionLoader} />
                            <Skeleton className={classes.fullDescriptionLoader} />
                        </>
                    ) : (
                        <Box className={classes.markdownWrapper}>
                            <Markdown
                                source={fullDescription}
                                className={classes.fullDescription}
                            />
                        </Box>
                    )}
                </Box>
                <Box className={classes.subtitle} component="div" align="left">
                    {partners && partners.length ? partners && partners.length > 1 ? title : 'Partner':''}
                </Box>
                <Box>
                    {loading
                        ? loadingCards &&
                          loadingCards.map((index) => (
                              <Box component="div" align="left" key={index}>
                                  <PartnersCard loading={loading} />
                              </Box>
                          ))
                        : partners &&
                          partners.map(({ name, description, logo }, index, partners) => (
                              <Box
                                  component="div"
                                  align="left"
                                  className={classes.partnerCardWrapper}
                                  key={index}>
                                  <PartnersCard
                                      heading={name}
                                      description={description}
                                      coverImage={logo}
                                      withDivider={index < partners.length - 1}
                                  />
                              </Box>
                          ))}
                </Box>
            </CardContent>
        </Card>
    );
}

Description.propTypes = {
    partners: PropTypes.array,
    fullDescription: PropTypes.string,
    profileLoading: PropTypes.bool,
    studentServiceLoading: PropTypes.bool,
};
