import moment from 'moment';
import {
    displayChecklist,
    displayIntakeType,
    displayProgramFees,
} from '../application/create-application';

export const createApplicationUiSchema = (
    universityLocation,
    programFee,
    intakeType,
    hidePriorityField = false,
) => {
    let uiSchema = {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/intake/properties/month',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/intake/properties/year',
                    },
                ],
            },
        ],
    };
    if (!hidePriorityField) {
        uiSchema.elements.push({
            type: 'HorizontalLayout',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/preference',
                    label: 'Application Priority (Once the priority is set it cannot be edited)',
                },
            ],
        });
    }
    if (universityLocation) {
        uiSchema.elements.push({
            type: 'HorizontalLayout',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/required_docs',
                    label: '',
                    options: {
                        component: 'label',
                        item: () => displayChecklist(universityLocation),
                    },
                },
            ],
            rule: {
                effect: 'SHOW',
                condition: {
                    scope: '#/properties/university',
                    schema: {},
                },
            },
        });
    }
    let temp;
    if (programFee) {
        temp = {
            type: 'HorizontalLayout',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/program_fees',
                    label: '',
                    options: {
                        component: 'label',
                        item: () => displayProgramFees(programFee),
                    },
                },
            ],
            rule: {
                effect: 'SHOW',
                condition: {
                    scope: '#/properties/program',
                    schema: {},
                },
            },
        };

        uiSchema.elements.push(temp);
    }

    if (intakeType) {
        if (programFee) {
            temp.elements.push({
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/intake_type',
                        label: '',
                        options: {
                            component: 'label',
                            item: () => displayIntakeType(intakeType),
                        },
                    },
                ],
            });
        } else {
            temp = {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/intake_type',
                        label: '',
                        options: {
                            component: 'label',
                            item: () => displayIntakeType(intakeType),
                        },
                    },
                ],
            };

            uiSchema.elements.push(temp);
        }
    }
    return uiSchema;
};

// TODO : integrate months with Application Branch
export const createApplicationSchema = (
    months = ['Jan', 'Feb'],
    intakeMonth,
    priorityData,
    hidePriorityField = false,
) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    let nextFourYears = [
        currentYear,
        currentYear + 1,
        currentYear + 2,
        currentYear + 3,
        currentYear + 4,
    ];

    if (intakeMonth && moment().month(intakeMonth).format('M') <= currentMonth)
        nextFourYears = [currentYear + 1, currentYear + 2, currentYear + 3, currentYear + 4];

    return {
        type: 'object',
        properties: {
            intake: {
                type: 'object',
                properties: {
                    month: {
                        type: 'string',
                        enum: months,
                    },
                    year: {
                        type: 'number',
                        enum: nextFourYears,
                    },
                },
                required: ['month', 'year'],
            },
            required_docs: {
                type: 'string',
            },
            program_fees: {
                type: 'string',
            },
            intake_type: {
                type: 'string',
            },
            preference: {
                type: 'string',
                enum: priorityData && priorityData.length ? priorityData : [''],
            },
        },
        required: hidePriorityField ? ['intake'] : ['intake', 'preference'],
    };
};
