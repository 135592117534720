import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// mui
import { Box, Grid } from '@mui/material';

// comp
import { FormComponent, Button, Alert } from 'azent-storybook/dist';

// styles
import { useStyles } from './style';

//rbac
import { isSubAgent } from '@/rbac';

//utils
import { goToRoute } from '@/store/actions/navigationActions';
import routePaths from '@/utils/route-paths';
import { deserializeSubagentInfo } from '@/utils/deserializers/subagentInfo-deserializer';
import { addSubAgentToStore } from '@/store/slices/subAgentSlice';

//prop-constants
import { serializeSubagentPersonalInfo } from '@/props-constants/sub-agent/sub-agent-profile';

let formErrors = null;
let timeout = null;

export default function PersonalDetails({
    defaultData,
    onChangeHandler,
    schema,
    uiSchema,
    submitButton,
    onSuccessCallback,
    setSubmittedData,
    submittedData,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const location = useSelector((state) => state.router.location);

    const [data, setData] = useState(defaultData || {});
    const [loading, setLoading] = useState(false);
    const [displayErrors, setDisplayErrors] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);

    const stepType = location.pathname.split(`/sub-agent/`)[1];

    const onChange = ({ data, errors }) => {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            onChangeHandler(data);
            setData(data);
            formErrors = errors;
        }, 400);
    };

    const onClickSubmit = async () => {
        if (formErrors && formErrors.length) {
            setDisplayErrors(true);
            return;
        }
        setLoading(true);
        try {
            let response = null;
            response = await submitButton.onClick(serializeSubagentPersonalInfo(data));

            if (response) {
                if (response.data) {
                    setSubmittedData({ ...submittedData, 0: data });
                    response.data?.data?.User &&
                        dispatch(
                            addSubAgentToStore(deserializeSubagentInfo(response.data.data.User)),
                        );
                    await onSuccessCallback(response.data, data);
                }
            }
        } catch (err) {
            if (err?.response?.data?.error?.message) {
                setErrorMsg(err.response.data.error.message);
            }
        } finally {
            setLoading(false);
            setDisplayErrors(false);
        }
    };

    const onCloseErrorModalCallback = () => {
        setErrorMsg(null);
    };

    return (
        <>
            {errorMsg && (
                <Alert
                    title={errorMsg}
                    severity="error"
                    onCloseCallback={onCloseErrorModalCallback}
                />
            )}
            <Box className={classes.container}>
                <Grid container className={classes.subAgentSummaryContainer}>
                    <FormComponent
                        schema={schema}
                        uiSchema={uiSchema.largeScreenUiSchema}
                        data={data}
                        onChange={onChange}
                        readOnlyForm={false}
                        showFormErrors={displayErrors}
                    />
                </Grid>
            </Box>
            {!isSubAgent() && (
                <Grid container className={classes.buttonsContainer}>
                    <Grid item className={classes.previousButtonContainer}>
                        <Button
                            onClick={() => {
                                dispatch(goToRoute(routePaths.SUB_AGENT_INDEX));
                            }}
                            label={stepType == 'create' ? 'Cancel' : 'Go Back'}
                            variant="outlined"
                            fullWidth="false"
                            startIcon={null}
                            color="inherit"
                            size="medium"
                            className={`${classes.buttonWidth} ${classes.previousButton}`}
                            disabled={loading}
                        />
                    </Grid>
                    <Grid item className={classes.submitButtonContainer}>
                        <Button
                            disabled={loading}
                            onClick={onClickSubmit}
                            label={'Save & Proceed'}
                            variant="contained"
                            fullWidth="false"
                            endIcon={null}
                            color="primary"
                            size="medium"
                            className={`${classes.buttonWidth} ${classes.submitButton}`}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
}

PersonalDetails.propTypes = {
    defaultData: PropTypes.object,
    onChangeHandler: PropTypes.func,
    schema: PropTypes.object,
    uiSchema: PropTypes.object,
    submitButton: PropTypes.object,
    onSuccessCallback: PropTypes.func,
    setSubmittedData: PropTypes.func,
    submittedData: PropTypes.object,
};
