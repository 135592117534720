import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
    clearAll: {
        cursor: 'pointer',
        '&:hover': {
            fontWeight: 600,
        },
    },
    clearAllContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
}));
