export default {
    PROFILE_VIEW: 'PROFILE_VIEW',
    PROFILE_EDIT: 'PROFILE_EDIT',
    PROFILE_LIST: 'PROFILE_LIST',
    PROFILE_CREATE: 'PROFILE_CREATE',
    APPLICATION_LIST: 'APPLICATION_LIST',
    APPLICATION_VIEW: 'APPLICATION_VIEW',
    APPLICATION_CREATE: 'APPLICATION_CREATE',
    APPLICATION_EDIT: 'APPLICATION_EDIT',
    APPLICATION_STATUS_CHANGE: 'APPLICATION_STATUS_CHANGE',
    APPLICATION_WITHDRAW: 'APPLICATION_WITHDRAW',
    CHANGE_PARTNER: 'CHANGE_PARTNER',
    SEND_SHORTLIST_EMAIL: 'SEND_SHORTLIST_EMAIL',
};
