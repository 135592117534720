import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: '#D6E3FF',
    },
    disabledText: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#6D6D6D',
    },
    centerElement: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    disabledKey: {
        display: 'inline-block',
        width: '33%',
    },
}));
