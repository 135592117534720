import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    recommendRoot: {
        padding: theme.spacing(1),
    },
    editIcon: {
        fontSize: theme.spacing(1.5),
        color: theme.palette.primary.main,
    },
    recommendTag: {
        height: theme.spacing(9),
        left: '124px',
        background: '#EFF3F6',
        borderRadius: '10px',
        marginTop: '13px',
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
    },
    recommendTagTitle: {
        fontSize: theme.spacing(1.5),
        color: '#000000',
        fontWeight: 500,
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        lineHeight: '15px',
        paddingRight: theme.spacing(1),
    },
    recommendTagSubtitle: {
        color: '#ADADAD',
        fontSize: theme.spacing(1.4),
        fontStyle: 'normal',
        lineHeight: '15px',
        position: 'relative',
        top: theme.spacing(0.6),
    },
    textWrap: {
        position: 'relative',
        top: '3px',
        left: '4px',
    },
}));
