import { get } from 'lodash';

// Constants
const steps = [0, 1, 2];

// Utils
import { getProfileSplit } from '@/utils/sub-agent';
import { getSubAgentSchema } from '@/props-constants/sub-agent-schema/sub-agent-form-schemas/schema';
import getPersonalDetailsUiSchema from '@/props-constants/sub-agent-schema/sub-agent-form-schemas/ui-schema/personal-details-ui';
import getPasswordUiSchema from '@/props-constants/sub-agent-schema/sub-agent-form-schemas/ui-schema/password-ui';

// apis
import { createUser, setResetPassword, assignStudentToSubAgent, updateUser } from '@/api/sub-agent';

const submitApiByIndex = (id, submittedData, index, profileData) => {
    delete submittedData.step;

    switch (index) {
        case 0: {
            let objtoSend = { ...submittedData, create_sub_agent: true };
            objtoSend.mobile =
                (profileData && profileData.mobile ? profileData.mobile.slice(0, -10) : '') +
                objtoSend.mobile;
            objtoSend.whatsapp_number =
                (profileData && profileData.whatsapp_number
                    ? profileData.whatsapp_number.slice(0, -10)
                    : '') + objtoSend.whatsapp_number;
            delete objtoSend.is_whatsapp_same;
            delete objtoSend.id;
            return id ? updateUser(id, objtoSend) : createUser(objtoSend);
        }
        case 1: {
            const agentId = submittedData.id || profileData.id;
            let objtoSend = { ...submittedData };
            delete objtoSend.confirm_password;
            delete objtoSend.id;
            return setResetPassword(agentId, objtoSend);
        }
        case 2: {
            const objToSend = {
                sub_agent_id: submittedData.id || profileData.id,
                profiles: submittedData.selectedStudent,
                removed_profiles: submittedData.deSelectedStudent,
            };
            return assignStudentToSubAgent(objToSend);
        }
    }
};

const getFormUiSchema = (dirtyFormData, index, passConfig, isReadOnly, have_password) => {
    switch (index) {
        case 0: {
            let config = {};
            config.isWhatsappSame = get(dirtyFormData, '0.is_whatsapp_same', null);
            return getPersonalDetailsUiSchema(config, isReadOnly);
        }
        case 1: {
            return getPasswordUiSchema(passConfig, have_password);
        }
        default:
            return {};
    }
};

const getFormDataByIndex = (
    dirtyFormData,
    index,
    id,
    validateForm,
    passConfig,
    profileData,
    isReadOnly,
    have_password,
) => {
    return {
        schema: getSubAgentSchema(),
        uiSchemas: getFormUiSchema(dirtyFormData, index, passConfig, isReadOnly, have_password),
        submitButton: {
            validateForm: validateForm,
            color: 'success',
            label: index
                ? steps.length - 1 === index
                    ? 'Complete Profile'
                    : 'Save & Proceed'
                : id
                ? 'Save & Proceed'
                : 'Create Profile',
            onClick: (submittedData, formData) => {
                return submitApiByIndex(id, submittedData, index, profileData, {
                    ...formData,
                });
            },
        },
    };
};

export const getMyProfileProps = (
    dirtyFormData,
    profileData,
    id,
    validateForm,
    passConfig,
    isReadOnly,
    have_password,
) => {
    let data = {
        formsData: steps.map((index) =>
            getFormDataByIndex(
                dirtyFormData,
                index,
                id,
                validateForm,
                passConfig,
                profileData,
                isReadOnly,
                have_password,
            ),
        ),
        previousButton: {
            label: 'Previous',
            variant: 'outlined',
            color: 'inherit',
            sx: {
                fontSize: '14px',
                fontWeight: '600',
                color: 'black',
                borderColor: 'rgba(0,0,0,0.23)',
                mr: 2,
                '&:hover': {
                    borderColor: 'rgba(0,0,0,0.23)',
                },
            },
        },
        defaultData: profileData ? getProfileSplit(profileData, dirtyFormData) : {},
    };
    return data;
};
