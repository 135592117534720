import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material
import { Box, List, ListItem, Stack, Typography, Paper } from '@mui/material';

// schema
import { schema as commentsSchema } from '@/props-constants/student-schema/student-comments-schemas/schema';
import { getCommentsUiSchema } from '@/props-constants/student-schema/student-comments-schemas/ui-schema';

//utils
import { toastMessages } from '@/utils/toastMessages';

//css
import { useStyles } from './styles';

//comps
import StudentComment from '@/components/shared/Comment';

// API
import { signRequest, updateOnboardingComment } from '@/api';
import { setToast } from '@/store/slices/toastSlice';
import { PartnerDataGetAndStore } from '@/store/actions/partnerActions';

// constants
import constants from '@/constants';

export default function AgreementInitiated() {
    const dispatch = useDispatch();
    const classes = useStyles();

    const partnerId = useSelector((state) => state.partnerData.data._id);
    const comments = useSelector((state) => state.partnerData.data.comments);
    const [addAttachment, setAddAttachment] = useState(false);

    const onCommentSave = (formData) => {
        if (formData.comment) {
            return updateOnboardingComment(formData, partnerId);
        } else {
            dispatch(setToast(toastMessages.backendIssue));
        }
    };

    const onCommentSaveSuccess = (_data, setData) => {
        dispatch(PartnerDataGetAndStore());
        setAddAttachment(false);
        setData({});
    };

    const fileUploadConfig = {
        s3Postfix: partnerId && `${constants.envName}/${partnerId}`,
        awsConfig: constants.awsConfig,
        selfAuthApiCall: signRequest,
    };

    return (
        <Box p={5}>
            <Box mb={3}>
                <Stack sx={{ textAlign: 'center' }}>
                    <Typography className={classes.title}>
                        Hurray! We have sent an agreement on your registered email. Please Check and
                        Confirm
                    </Typography>
                </Stack>
            </Box>
            <Box className={classes.commentContainer}>
                <Paper variant="outlined">
                    <Box p={3}>
                        <Typography variant="h6"> Next steps:</Typography>
                        <List>
                            <ListItem>
                                <Typography variant="h6">
                                    1. Referral Partner to sign the agreement on DocuSign
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant="h6">
                                    2. AdmissionBox to verify the agreement
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant="h6">
                                    3. AdmissionBox will sign the agreement and unlock the access to
                                    the Partner Portal
                                </Typography>
                            </ListItem>
                        </List>
                    </Box>
                </Paper>
            </Box>
            <Box className={classes.commentContainer}>
                <Typography variant="h6" mb={1}>
                    <em> Comments</em>
                </Typography>
                <Paper variant="outlined">
                    <StudentComment
                        addAttachment={addAttachment}
                        setAddAttachment={setAddAttachment}
                        comments={comments && comments.length > 0 ? comments : []}
                        addComment={{
                            schema: commentsSchema,
                            commentsUiSchema: getCommentsUiSchema({
                                addAttachment: addAttachment,
                                type: 'partners',
                            }),
                            defaultData: {},
                            uploadConfig: fileUploadConfig,
                            submitButton: {
                                label: 'Save Comment',
                                color: 'success',
                                onClick: onCommentSave,
                                fullWidth: false,
                                className: classes.saveCommentButton,
                            },
                            onSuccessCallback: onCommentSaveSuccess,
                        }}
                    />
                </Paper>
            </Box>
        </Box>
    );
}
