import moment from 'moment';
import { isB2bAdmin, isPartner, isPSA } from '@/rbac';

// Schema Keys: Test Enumf
export const IELTS = 'IELTS';
export const TOEFL = 'TOEFL';
export const DUOLINGO = 'DUOLINGO';
export const GRE = 'GRE';
export const GMAT = 'GMAT';
export const PTE = 'PTE';
export const SAT = 'SAT';

export const TEST_ENUM = [IELTS, TOEFL, DUOLINGO, GRE, GMAT, PTE, SAT];
export const EXPERIENCE_CERTIFICATE = 'experience_certificate';
export const OFFER_LETTER = 'offer_letter';

// Schema Keys: Education Enum
export const PG_CERTIFICATE_OR_DIPLOMA = 'PG Certificate or Diploma';
export const CERTIFICATE_OR_DIPLOMA = 'Certificate or Diploma';
export const BACHELOR_DEGREE_3 = '3-Year Bachelor Degree';
export const BACHELOR_DEGREE_4 = '4-Year Bachelor Degree';
export const BACHELOR_DEGREE_3_OR_4 = '3 or 4 Year Bachelor Degree';
export const MASTERS = 'Masters';
export const PHD = 'PhD';
export const TWELTH = '12th or equivalent';
export const TENTH = '10th or equivalent';
// Backend Keys: Education Details
export const ED_GRADE_10_OR_EQUIVALENT = 'grade_10_or_equivalent';
export const ED_GRADE_12_OR_EQUIVALENT = 'grade_12_or_equivalent';
export const ED_GRADUATION = 'graduation';
export const ED_MASTERS = 'masters';
export const ED_PHD = 'phd';

export const EDUCATION_BACKEND_TO_ENUM_MAP = {
    [TENTH]: ED_GRADE_10_OR_EQUIVALENT,
    [TWELTH]: ED_GRADE_12_OR_EQUIVALENT,
    [CERTIFICATE_OR_DIPLOMA]: ED_GRADUATION,
    [BACHELOR_DEGREE_3_OR_4]: ED_GRADUATION,

    [PG_CERTIFICATE_OR_DIPLOMA]: ED_MASTERS,
    [MASTERS]: ED_MASTERS,
    [PHD]: ED_PHD,
};

export const INSTITUTE_LABEL = {
    SCHOOL: 'Board',
    ABOVE_SCHOOL: 'University',
};

export let config = {
    countries: [
        'Australia',
        'Canada',
        'Dubai',
        'Germany',
        'France',
        'Ireland',
        'Italy',
        'Malaysia',
        'New Zealand',
        'Singapore',
        'Spain',
        'UK',
        'USA',
    ],
    highest_degree: [
        TENTH,
        TWELTH,
        CERTIFICATE_OR_DIPLOMA,
        BACHELOR_DEGREE_3_OR_4,

        PG_CERTIFICATE_OR_DIPLOMA,
        MASTERS,
        PHD,
    ],
    target_degrees_old: [
        'Certificate or Diploma',
        '3-Year Bachelor Degree',
        '4-Year Bachelor Degree',
        'PG Certificate or Diploma',
        'Masters',
        'PhD',
    ],
    target_degrees: [
        `PG Degree/Masters`,
        `UG Degree/Bachelors`,
        `PG Certificate/Diploma`,
        `UG Certificate/Diploma`,
        `Integrated Courses (UG+PG)`,
        `UG Dual Degree`,
        `PG Dual Degree`,
        `UG Pathway Courses`,
        `PG Pathway Courses`,
    ],
    areas_of_study: [
        'Computing and Information Technology',
        'STEM (Science, Technology, Engineering & Maths)',
        'Business and Economics',
        'Management',
        'Visual Arts and Design',
        'Travel',
        'Tourism and Hospitality Management',
        'Agriculture Builing and Planning',
        'Liberal Arts',
        'Humanities',
        'Politics and Law',
        'Health and Medicine',
        'Paramedical',
        'Social Sciences',
    ],
    nationality_options: [
        {
            name: 'Afghan',
        },
        {
            name: 'Albanian',
        },
        {
            name: 'Algerian',
        },
        {
            name: 'Argentine',
        },
        {
            name: 'Argentinian',
        },
        {
            name: 'Australian',
        },
        {
            name: 'Austrian',
        },
        {
            name: 'Bangladeshi',
        },
        {
            name: 'Belgian',
        },
        {
            name: 'Bolivian',
        },
        {
            name: 'Batswana',
        },
        {
            name: 'Brazilian',
        },
        {
            name: 'Bulgarian',
        },
        {
            name: 'Cambodian',
        },
        {
            name: 'Cameroonian',
        },
        {
            name: 'Canadian',
        },
        {
            name: 'Chilean',
        },
        {
            name: 'Chinese',
        },
        {
            name: 'Colombian',
        },
        {
            name: 'Costa Rican',
        },
        {
            name: 'Croatian',
        },
        {
            name: 'Cuban',
        },
        {
            name: 'Czech',
        },
        {
            name: 'Danish',
        },
        {
            name: 'Dominican',
        },
        {
            name: 'Ecuadorian',
        },
        {
            name: 'Egyptian',
        },
        {
            name: 'Salvadorian',
        },
        {
            name: 'English',
        },
        {
            name: 'Estonian',
        },
        {
            name: 'Ethiopian',
        },
        {
            name: 'Fijian',
        },
        {
            name: 'Finnish',
        },
        {
            name: 'French',
        },
        {
            name: 'German',
        },
        {
            name: 'Ghanaian',
        },
        {
            name: 'Greek',
        },
        {
            name: 'Guatemalan',
        },
        {
            name: 'Haitian',
        },
        {
            name: 'Honduran',
        },
        {
            name: 'Hungarian',
        },
        {
            name: 'Icelandic',
        },
        {
            name: 'Indian',
        },
        {
            name: 'Indonesian',
        },
        {
            name: 'Iranian',
        },
        {
            name: 'Iraqi',
        },
        {
            name: 'Irish',
        },
        {
            name: 'Israeli',
        },
        {
            name: 'Italian',
        },
        {
            name: 'Jamaican',
        },
        {
            name: 'Japanese',
        },
        {
            name: 'Jordanian',
        },
        {
            name: 'Kenyan',
        },
        {
            name: 'Kuwaiti',
        },
        {
            name: 'Lao',
        },
        {
            name: 'Latvian',
        },
        {
            name: 'Lebanese',
        },
        {
            name: 'Libyan',
        },
        {
            name: 'Lithuanian',
        },
        {
            name: 'Malagasy',
        },
        {
            name: 'Malaysian',
        },
        {
            name: 'Malian',
        },
        {
            name: 'Maltese',
        },
        {
            name: 'Mexican',
        },
        {
            name: 'Mongolian',
        },
        {
            name: 'Moroccan',
        },
        {
            name: 'Mozambican',
        },
        {
            name: 'Namibian',
        },
        {
            name: 'Nepalese',
        },
        {
            name: 'Dutch',
        },
        {
            name: 'New Zealand',
        },
        {
            name: 'Nicaraguan',
        },
        {
            name: 'Nigerian',
        },
        {
            name: 'Norwegian',
        },
        {
            name: 'Pakistani',
        },
        {
            name: 'Panamanian',
        },
        {
            name: 'Paraguayan',
        },
        {
            name: 'Peruvian',
        },
        {
            name: 'Philippine',
        },
        {
            name: 'Polish',
        },
        {
            name: 'Portuguese',
        },
        {
            name: 'Romanian',
        },
        {
            name: 'Russian',
        },
        {
            name: 'Saudi',
        },
        {
            name: 'Scottish',
        },
        {
            name: 'Senegalese',
        },
        {
            name: 'Serbian',
        },
        {
            name: 'Singaporean',
        },
        {
            name: 'Slovak',
        },
        {
            name: 'South African',
        },
        {
            name: 'Korean',
        },
        {
            name: 'Spanish',
        },
        {
            name: 'Sri Lankan',
        },
        {
            name: 'Sudanese',
        },
        {
            name: 'Swedish',
        },
        {
            name: 'Swiss',
        },
        {
            name: 'Syrian',
        },
        {
            name: 'Taiwanese',
        },
        {
            name: 'Tajikistani',
        },
        {
            name: 'Thai',
        },
        {
            name: 'Tongan',
        },
        {
            name: 'Tunisian',
        },
        {
            name: 'Turkish',
        },
        {
            name: 'Ukrainian',
        },
        {
            name: 'Emirati',
        },
        {
            name: 'British',
        },
        {
            name: 'American',
        },
        {
            name: 'Uruguayan',
        },
        {
            name: 'Venezuelan',
        },
        {
            name: 'Vietnamese',
        },
        {
            name: 'Welsh',
        },
        {
            name: 'Zambian',
        },
        {
            name: 'Zimbabwean',
        },
    ],
    categories: {
        'Applied and Pure Sciences': [
            'Environmental Sciences',
            'Sports Science',
            'Mathematics',
            'Chemistry',
            'Physical Geography',
            'Astronomy',
            'Biology',
            'Food Science and Technology',
            'Earth Sciences',
            'Materials Sciences',
            'Biomedical Sciences',
            'Life Sciences',
            'Physics',
            'Applied Sciences',
        ],
        'Architecture and Construction': [
            'Planning',
            'Architecture',
            'Construction',
            'Surveying',
            'Maintenance Services',
            'Built Environment',
            'Property Management',
        ],
        'Creative Arts and Design': [
            'Art',
            'Design',
            'Art Administration',
            'Theatre and Drama Studies',
            'Crafts',
            'Music',
            'Interior Design',
            'Graphic Design',
            'Fashion and Textile Design',
            'Dance',
        ],
        'Business and Management': [
            'E-Commerce',
            'Finance',
            'Management',
            'Accounting',
            'Office Administration',
            'Transportation and Logistics',
            'Quality Management',
            'Retail',
            'Marketing',
            'Business Studies',
            'Human Resource Management',
            'Entrepreneurship',
            'MBA',
        ],
        'Education and Training': [
            'Education Learning',
            'Adult Education',
            'Pedagogy',
            'Teacher Training',
            'Childhood Education',
            'Coaching',
            'Special Education',
        ],
        Engineering: [
            'Electrical Engineering',
            'Civil Engineering',
            'Biomedical Engineering',
            'Aerospace Engineering',
            'Power and Energy Engineering',
            'Electronic Engineering',
            'Chemical and Materials Engineering',
            'Environmental Engineering',
            'Mining and Oil & Gas Operations',
            'Manufacturing and Production',
            'Marine Engineering',
            'Vehicle Engineering',
            'Mechanical Engineering',
            'Structural Engineering',
            'General Engineering and Technology',
            'Metallurgy',
            'Telecommunications',
        ],
        'Social Studies and Media': [
            'Media',
            'Social Sciences',
            'International relations',
            'Library Studies',
            'Journalism',
            'Sociology',
            'Writing',
            'Linguistics',
            'Environmental Management',
            'International Development',
            'Anthropology',
            'Public Administration',
            'Human Geography',
            'Film & Television',
            'Politics',
            'Photography',
        ],
        'Travel and Hospitality': [
            'Catering',
            'Hospitality',
            'Travel and Tourism',
            'Food and Drink Production',
            'Leisure Management',
            'Hotel Management',
            'Aviation',
        ],
        'Health and Medicine': [
            'Dentistry',
            'Nursing',
            'Nutrition and Health',
            'Medicine',
            'Pharmacology',
            'Ophthalmology',
            'Counselling',
            'Physiology',
            'Health Studies',
            'Public Health',
            'Health and Safety',
            'Physiotherapy',
            'Complementary Health',
            'Psychology',
            'Midwifery',
        ],
        'Computer Science and IT': ['Software', 'Computer Science', 'Data', 'IT'],
        'Agriculture and Veterinary Medicine': [
            'Veterinary Medicine',
            'Agriculture',
            'Farm Management',
            'Horticulture',
            'Plant and Crop Sciences',
        ],
        Law: ['Legal Studies'],
        Humanities: [
            'History',
            'Museum Studies',
            'Literature',
            'Regional Studies',
            'Archaeology',
            'Languages',
            'Religious Studies',
            'Classics',
            'English Studies',
            'Philosophy',
            'Cultural Studies',
            'Interdisciplinary Studies',
        ],
        'Personal Care and Fitness': [
            'Health and Fitness',
            'Massage',
            'Beauty Therapy',
            'Hairdressing',
            'Therapeutic',
        ],
    },
    cities: [
        'Ahmedabad',
        'Bangalore',
        'Chennai',
        'Delhi',
        'Hyderabad',
        'Jaipur',
        'Kochi',
        'Mumbai',
        'Pune',
        'Surat',
        'Thane',
        'Vadodara',
        'Vijayawada',
    ],
};

const schema = (_globalData, intakeMonth, dirtyFormData) => {
    // const config = store.getState().globalData.data;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    let intake_years = [
        currentYear,
        currentYear + 1,
        currentYear + 2,
        currentYear + 3,
        currentYear + 4,
    ];
    let month = null;
    if (intakeMonth) month = moment().month(intakeMonth).format('M');
    else if (
        dirtyFormData &&
        dirtyFormData[0].desired_profile &&
        dirtyFormData[0].desired_profile.intake_month
    ) {
        month = moment().month(dirtyFormData[0].desired_profile.intake_month).format('M');
    }

    if (month && month <= currentMonth)
        intake_years = [currentYear + 1, currentYear + 2, currentYear + 3, currentYear + 4];
    const schema = {
        definitions: {
            short_address: {
                type: 'object',
                properties: {
                    city: {
                        type: 'string',
                    },
                    country: {
                        type: 'string',
                    },
                },
                required: ['city', 'country'],
            },
            education: {
                type: 'object',
                properties: {
                    level: {
                        type: 'string',
                        enum: config.highest_degree,
                    },
                    institute_name: {
                        type: 'string',
                    },
                    from_year: {
                        type: 'number',
                        maximum: { $data: '1/to_year' },
                    },
                    to_year: {
                        type: 'number',
                    },
                    degree_awarded: {
                        type: 'string',
                    },
                    address: {
                        $ref: '#/definitions/short_address',
                    },
                    gpa_or_percentage: {
                        type: 'number',
                        minimum: 0,
                    },
                    overall_percentage: {
                        type: 'number',
                    },
                },
                allOf: [
                    {
                        if: {
                            properties: {
                                gpa_or_percentage: {
                                    maximum: 15,
                                },
                            },
                        },
                        then: {
                            properties: {
                                overall_percentage: {
                                    maximum: 15,
                                },
                            },
                        },
                    },
                    {
                        if: {
                            properties: {
                                gpa_or_percentage: {
                                    minimum: 16,
                                },
                            },
                        },
                        then: {
                            properties: {
                                overall_percentage: {
                                    const: 100,
                                },
                            },
                        },
                    },
                ],
                required: [
                    'institute_name',
                    'from_year',
                    'to_year',
                    'address',
                    'gpa_or_percentage',
                    'overall_percentage',
                ],
            },
            resource: {
                type: 'object',
                properties: {
                    original_file_name: {
                        type: 'string',
                    },
                    url: {
                        type: 'string',
                    },
                    objectId: {
                        type: 'string',
                    },
                    type: {
                        type: 'string',
                    },
                    category: {
                        type: 'string',
                    },
                },
            },
            address: {
                type: 'object',
                properties: {
                    address: {
                        type: 'string',
                    },
                    city: {
                        type: 'string',
                    },
                    state: {
                        type: 'string',
                    },
                    country: {
                        type: 'string',
                    },
                    pin_code: {
                        type: 'string',
                    },
                    email: {
                        type: 'string',
                    },
                    mobile: {
                        type: 'string',
                    },
                },
                required: ['address', 'city', 'state', 'country', 'pin_code'],
            },
        },
        type: 'object',
        properties: {
            step: {
                type: 'number',
            },
            first_name: {
                type: 'string',
            },
            last_name: {
                type: 'string',
            },
            mobile: {
                type: 'string',
                minLength: 10,
                maxLength: 10,
            },
            email: {
                type: 'string',
                format: 'email',
                pattern: '^([A-Za-z0-9+_.-])+@([a-zA-Z0-9-])+(\\.[a-zA-Z0-9-]+)+$',
            },
            gender: {
                type: 'string',
                enum: ['Male', 'Female', 'Other'],
            },
            date_of_birth: {
                type: 'string',
                format: 'date',
                formatMaximum: moment().subtract(16, 'years').format('YYYY-MM-DD'),
            },
            nationality: {
                type: 'string',
                enum: config.nationality_options.map((item) => item.name),
            },
            marital_status: {
                type: 'string',
                enum: ['Single', 'Married', 'Widowed', 'Divorced'],
            },
            address: {
                $ref: '#/definitions/address',
            },
            alternate_mobile: {
                type: 'string',
                minLength: 10,
                maxLength: 10,
            },
            desired_profile: {
                type: 'object',
                properties: {
                    interest_countries: {
                        type: 'array',
                        items: {
                            type: 'string',
                            enum: config.countries,
                        },
                        minItems: 1,
                    },
                    interest_regions: {
                        type: 'array',
                        items: {
                            type: 'object',
                            properties: {
                                city: {
                                    type: 'string',
                                },
                                state: {
                                    type: 'string',
                                },
                                country: {
                                    type: 'string',
                                },
                            },
                            required: ['state', 'country'],
                        },
                    },
                    keywords: {
                        type: 'array',
                        items: {
                            type: 'string',
                        },
                        minItems: 1,
                    },
                    degree_types_new: {
                        type: 'array',
                        items: {
                            type: 'string',
                            enum: config.target_degrees,
                        },
                        minItems: 1,
                    },
                    intake_month: {
                        type: 'string',
                        enum: [
                            'Jan',
                            'Feb',
                            'Mar',
                            'Apr',
                            'May',
                            'Jun',
                            'Jul',
                            'Aug',
                            'Sep',
                            'Oct',
                            'Nov',
                            'Dec',
                        ],
                    },
                    intake_year: {
                        type: 'number',
                        enum: intake_years,
                    },
                    note: {
                        type: 'string',
                    },
                },
                required: [
                    'interest_countries',
                    'keywords',
                    'degree_types_new',
                    'intake_month',
                    'intake_year',
                ],
            },
            point_of_contact: {
                type: 'object',
                properties: {
                    first_name: {
                        type: 'string',
                    },
                    last_name: {
                        type: 'string',
                    },
                    mobile: {
                        type: 'string',
                        minLength: 10,
                        maxLength: 10,
                    },
                    email: {
                        type: 'string',
                        format: 'email',
                        pattern: '^([A-Za-z0-9+_.-])+@([a-zA-Z0-9-])+(\\.[a-zA-Z0-9-]+)+$',
                    },
                    whatsapp_number: {
                        type: 'string',
                        minLength: 10,
                        maxLength: 10,
                    },
                    designation: {
                        type: 'string',
                    },
                    copy_number: {
                        type: 'boolean',
                    },
                },
                required: ['first_name', 'last_name', 'mobile', 'email', 'whatsapp_number'],
            },
            education_history: {
                type: 'object',
                properties: {
                    highest_education_level: {
                        type: 'string',
                        enum: config.highest_degree,
                    },
                    backlogs_count: {
                        type: 'number',
                        minimum: 0,
                    },
                    grade_10_or_equivalent: {
                        $ref: '#/definitions/education',
                    },
                    grade_12_or_equivalent: {
                        $ref: '#/definitions/education',
                    },
                    graduation: {
                        $ref: '#/definitions/education',
                    },
                    masters: {
                        $ref: '#/definitions/education',
                    },
                    phd: {
                        $ref: '#/definitions/education',
                    },
                },
                allOf: [
                    {
                        if: {
                            properties: {
                                highest_education_level: {
                                    const: TENTH,
                                },
                            },
                        },
                        then: {
                            required: [ED_GRADE_10_OR_EQUIVALENT],
                        },
                    },
                    {
                        if: {
                            properties: {
                                highest_education_level: {
                                    const: TWELTH,
                                },
                            },
                        },
                        then: {
                            required: [ED_GRADE_10_OR_EQUIVALENT, ED_GRADE_12_OR_EQUIVALENT],
                        },
                    },
                    {
                        if: {
                            properties: {
                                highest_education_level: {
                                    enum: [CERTIFICATE_OR_DIPLOMA, BACHELOR_DEGREE_3_OR_4],
                                },
                            },
                        },
                        then: {
                            required: [
                                ED_GRADE_10_OR_EQUIVALENT,
                                ED_GRADE_12_OR_EQUIVALENT,
                                ED_GRADUATION,
                            ],
                        },
                    },
                    {
                        if: {
                            properties: {
                                highest_education_level: {
                                    enum: [PG_CERTIFICATE_OR_DIPLOMA, MASTERS],
                                },
                            },
                        },
                        then: {
                            required: [
                                ED_GRADE_10_OR_EQUIVALENT,
                                ED_GRADE_12_OR_EQUIVALENT,
                                ED_GRADUATION,
                                ED_MASTERS,
                            ],
                        },
                    },
                    {
                        if: {
                            properties: {
                                highest_education_level: {
                                    enum: [PHD],
                                },
                            },
                        },
                        then: {
                            required: [
                                ED_GRADE_10_OR_EQUIVALENT,
                                ED_GRADE_12_OR_EQUIVALENT,
                                ED_GRADUATION,
                                ED_MASTERS,
                                ED_PHD,
                            ],
                        },
                    },
                ],
            },
            education_documents: {
                type: 'array',
                items: {
                    $ref: '#/definitions/resource',
                },
            },
            is_tests_opted: {
                type: 'string',
                enum: ['Yes', 'No'],
            },
            test_scores: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        name: {
                            type: 'string',
                            enum: TEST_ENUM,
                        },
                        test_date: {
                            type: 'string',
                            format: 'date',
                        },
                        scores: {
                            type: 'object',
                            properties: {
                                listening: {
                                    type: 'number',
                                    minimum: 0,
                                },
                                reading: {
                                    type: 'number',
                                    minimum: 0,
                                },
                                written: {
                                    type: 'number',
                                    minimum: 0,
                                },
                                speaking: {
                                    type: 'number',
                                    minimum: 0,
                                },
                                overall: {
                                    type: 'number',
                                    minimum: 0,
                                },
                                verbalScore: {
                                    type: 'number',
                                    minimum: 0,
                                },
                                quantitative: {
                                    type: 'number',
                                    minimum: 0,
                                },
                                math: {
                                    type: 'number',
                                    minimum: 0,
                                },
                                essay: {
                                    type: 'number',
                                    minimum: 0,
                                },
                            },
                        },
                    },
                },
            },
            test_documents: {
                type: 'array',
                items: {
                    $ref: '#/definitions/resource',
                },
            },
            has_work_experience: {
                type: 'string',
                enum: ['Yes', 'No'],
            },
            work_history: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        name_of_the_organisation: {
                            type: 'string',
                        },
                        position: {
                            type: 'string',
                        },
                        from_date: {
                            type: 'string',
                            format: 'date',
                            formatMaximum: { $data: '1/to_date' },
                        },
                        to_date: {
                            type: 'string',
                            format: 'date',
                            formatMinimum: { $data: '1/from_date' },
                            formatMaximum: moment().format('YYYY-MM-DD'),
                        },
                        city: {
                            type: 'string',
                        },
                        country: {
                            type: 'string',
                        },
                    },
                },
            },
            work_info: {
                type: 'object',
                properties: {
                    years_of_experience: {
                        type: 'number',
                    },
                    industry: {
                        type: 'string',
                        enum: [
                            'Aerospace',
                            'Agriculture',
                            'Construction',
                            'Education',
                            'Electronics',
                            'Energy',
                            'Entertainment',
                            'Food',
                            'Healthcare / Medical',
                            'Hospitality',
                            'IT / Computer',
                            'Manufacturing',
                            'Mining',
                            'Music',
                            'News Media',
                            'Other',
                            'Pharmaceutical',
                            'Telecommunication',
                            'Transport',
                        ],
                    },
                },
            },
            work_documents: {
                type: 'array',
                items: {
                    $ref: '#/definitions/resource',
                },
            },
            passport: {
                type: 'object',
                properties: {
                    passport_number: {
                        type: 'string',
                    },
                    issued_on: {
                        type: 'string',
                        format: 'date',
                    },
                    valid_till: {
                        type: 'string',
                        format: 'date',
                    },
                    place_of_issue: {
                        type: 'string',
                    },
                    name_on_passport: {
                        type: 'string',
                    },
                    surname_on_passport: {
                        type: 'string',
                    },
                },
                required: [
                    'passport_number',
                    'issued_on',
                    'valid_till',
                    'place_of_issue',
                    'name_on_passport',
                    'surname_on_passport',
                ],
            },
            background_info: {
                type: 'object',
                properties: {
                    ever_applied_immigration: {
                        type: 'boolean',
                    },
                    immigration_details: {
                        type: 'string',
                    },
                    ever_refused_visa: {
                        type: 'boolean',
                    },
                    visa_refusal_reason: {
                        type: 'string',
                    },
                    ever_convicted_criminal_offence: {
                        type: 'boolean',
                    },
                    criminal_offence_details: {
                        type: 'string',
                    },
                    has_medical_condition: {
                        type: 'boolean',
                    },
                    medical_condition_details: {
                        type: 'string',
                    },
                },
            },
            general_documents: {
                type: 'array',
                items: {
                    $ref: '#/definitions/resource',
                },
            },
            miscellaneous_documents: {
                type: 'array',
                items: {
                    $ref: '#/definitions/resource',
                },
            },

            gte_documents: {
                type: 'array',
                items: {
                    $ref: '#/definitions/resource',
                },
            },
            required_documents: {
                type: 'array',
                items: {
                    $ref: '#/definitions/resource',
                },
            },
        },
        allOf: [
            {
                if: {
                    properties: {
                        step: {
                            const: 0,
                        },
                    },
                },
                then: {
                    required: [
                        'first_name',
                        'last_name',
                        'mobile',
                        'email',
                        'date_of_birth',
                        'nationality',
                        'gender',
                        'desired_profile',
                        'marital_status',
                        'alternate_mobile',
                    ],
                },
            },
            {
                if: {
                    properties: {
                        step: {
                            const: 2,
                        },
                        is_tests_opted: {
                            enum: ['Yes'],
                        },
                    },
                },
                then: {
                    properties: {
                        test_scores: {
                            minItems: 1,
                            items: {
                                required: ['name', 'test_date', 'scores'],
                            },
                        },
                    },
                },
            },
            {
                if: {
                    properties: {
                        step: {
                            const: 3,
                        },
                        has_work_experience: {
                            enum: ['Yes'],
                        },
                    },
                },
                then: {
                    properties: {
                        work_history: {
                            minItems: 1,
                            items: {
                                required: [
                                    'name_of_the_organisation',
                                    'position',
                                    'from_date',
                                    'to_date',
                                    'city',
                                    'country',
                                ],
                            },
                        },
                        work_info: {
                            required: ['years_of_experience', 'industry'],
                        },
                    },
                },
            },
        ],
    };

    if (isB2bAdmin() || isPSA()) {
        schema.properties.partner = {
            type: 'object',
            properties: {
                name: {
                    type: 'string',
                },
                id: {
                    type: 'number',
                },
            },
            required: ['id'],
            errorMessage: {
                type: 'Field is required',
            },
        };
        schema.allOf[0].then.required.push('partner');

        schema.properties.sub_agent = {
            type: 'object',
            properties: {
                first_name: {
                    type: 'string',
                },
                last_name: {
                    type: 'string',
                },
                id: {
                    type: 'number',
                },
            },
            required: ['id'],
            errorMessage: {
                type: 'Field is required',
            },
        };
        schema.allOf[0].then.required.push('sub_agent');
    }

    if (isPartner()) {
        schema.properties.sub_agent = {
            type: 'object',
            properties: {
                first_name: {
                    type: 'string',
                },
                last_name: {
                    type: 'string',
                },
                id: {
                    type: 'number',
                },
            },
            required: ['id'],
            errorMessage: {
                type: 'Field is required',
            },
        };
        schema.allOf[0].then.required.push('sub_agent');
    }

    return schema;
};

export default schema;
