//Constants
import { INITIALIZE_SERVICES_CARD } from "../actions/serviceCmsAction";


const initializeState = { loading: true, data: [] };

const serviceCardsReducer = (state=initializeState,action)=>{
  if(action.type == INITIALIZE_SERVICES_CARD){
    return{
        data:action.payload,
        loading:false,
    };
  }else{
    return state;
  }
}

export default serviceCardsReducer;