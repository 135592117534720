import React from 'react';

// apis
import { createApplication } from '@/api';

// utils
import { getIcon } from 'azent-storybook/dist';
import { setToast } from '@/store/slices/toastSlice';

// schemas
import getApplicationFormSchema from '@/props-constants/application/create-application/application-schema/schema';
import getApplicationUiSchema from '@/props-constants/application/create-application/application-schema/uischema';

// component
import InstructionNotes from '@/components/modules/Application/CreateApplication/partials/InstructionNotes';

export const serializePriorityData = (data) => {
    return data.split(` `)[0];
};

const onApplicationCreate = (dispatch) => async (data) => {
    const payload = {
        documents: [],
        program_id: data.program._id,
        profile_id: data.profile._id,
        intake: data.intake,
        preference: serializePriorityData(data.preference),
    };

    try {
        return await createApplication(payload);
    } catch (err) {
        dispatch(
            setToast({ message: `${err.response.data.error.message}`, toasterColor: 'error' }),
        );
    }
};

export const getCreateApplicationProps = (
    dispatch,
    defaultData,
    classes,
    months,
    universityId,
    profileData,
    disableFields,
    disableButton,
    intakeMonth,
    universityLocation,
    programFee,
    intakeType,
    priorityData,
) => {
    const props = {
        defaultData,
        submitButton: {
            onClick: onApplicationCreate(dispatch),
            fullWidth: false,
            label: 'Create Application',
            variant: 'contained',
            size: 'medium',
            color: 'success',
            disabled: disableFields || disableButton,
        },
        modalTitle: {
            title: 'Create an application',
        },
        showCloseButton: true,
        modalTitleStartIcon: getIcon('person', 'primary', 'medium', classes.modalTitleIcon),
        classes: {
            cModalTitleContainer: classes.cModalTitleContainer,
            cModalTitle: classes.cModalTitle,
        },
        schema: getApplicationFormSchema(months, intakeMonth, priorityData),
        uiSchemas: {
            smallScreenUiSchema: {},
            largeScreenUiSchema: getApplicationUiSchema(
                universityId,
                profileData,
                disableFields,
                universityLocation,
                programFee,
                intakeType,
            ),
        },
        showPreview: false,
    };

    return props;
};

export const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const displayChecklist = (universityLocation) => {
    const documentList = getdocumentChecklist(universityLocation);
    const warningNote = `IMPORTANT: Please keep the below-mentioned documents handy for quick processing
    of the application.`;
    return <InstructionNotes notesList={documentList} warningNote={warningNote} />;
};

export const displayProgramFees = (programFees) => {
    const programFee =
        programFees.fee == 0
            ? `IMPORTANT: Application Fee is Waived Off`
            : `IMPORTANT: Application Fee is  ${programFees.currency} ${programFees.fee}`;
    return <InstructionNotes programFee={programFee} />;
};

export const displayIntakeType = (intakeType) => {
    const intakeNote = `Intake : ${intakeType}`;
    return <InstructionNotes intakeNote={intakeNote} />;
};

export const getdocumentChecklist = (country) => {
    switch (country) {
        case 'UK':
        case 'Ireland':
            return [
                `1. Passport copy (Front & Back Side)`,
                `2. Academic Documents – Marksheets of Grade 10th, 12th, Bachelors & Masters
            (including the A.T.K.T or Fail Marksheets)`,
                `3. Passing Certificates of all Academics (10th, 12th, Bachelors, Masters)`,
                `4. English Language Test Score for e.g., IELTS, PTE, TOEFL (As per University
            Requirement)`,
                `5. SOP (500 /1000 words or maximum 2 pages)`,
                `6. 2 Letters of Recommendations (It should be on the letterhead of the institution with
            date, name of the referee, contact no, and email id)`,
                `7. Work Experience letters/Academic Gap documents – if applicable (appointment
            letter in case currently working, salary slips of past 6 months, Bank statement of
            salary evidence)`,
                `8. Updated CV/ Resume`,
                `9. Immigration history of student for any country (if applicable)`,
            ];

        case 'USA':
            return [
                `1. Passport copy (Front & Back Side)`,
                `2. Academic Documents – Marksheets of Grade 10th, 12th, Bachelors & Masters
                    (including the A.T.K.T or Fail Marksheets)`,
                `3. Passing Certificates of all Academics (10th, 12th, Bachelors, Masters)`,
                `4. English Language test scores for e.g., IELTS, PTE, TOEFL`,
                `5. Backlog Summary (if required)`,
                `6. Transcripts (The transcripts have to be sealed from the institution, and students have to
                    take 5 copies each)`,
                `7. Grading scale (if required)`,
                `8. WES Evaluation (if required)`,
                `9. SOP (500 /1000 words or maximum 2 pages)`,
                `10. 2 Letters of Recommendations (It should be on the letterhead of the institution with
                    date, name of the referee, contact no, and email id) NOTE – email id mentioned on the
                    the reference letter should be the institution's email id and not Gmail, Yahoo, or any other,
                    multiple copies should be taken in a sealed envelope if it needs to be sent to the
                    university.`,
                `11. Work Experience letters/ Academic Gap documents – if applicable (appointment
                    letter incase currently working, salary slips of past 6 months, Bank statement of
                    salary evidence)`,
                `12. Updated CV/Resume`,
                `13. Immigration history of student for any country (if applicable)]`,
            ];

        case 'Canada':
            return [
                `1. Passport Copy (front & back side)`,
                `2. Academic documents – Marksheets of grade 10th, 12th, bachelors & Masters (including the A.T.K.T or fail mark sheets)`,
                `3. Passing certificates of all academic documents (10th, 12th, Bachelors, Masters)`,
                `4. English Language test scores for e.g. IELTS, PTE, TOEFL`,
                `5. Backlog Summary (if required)`,
                `6. Application fee (student needs to provide the CC details)`,
                `7. Work Experience letters/ Academic Gap Documents – if applicable (appointment letter incase currently working, salary slips for past 6 months, Bank statement of salary evidence)`,
                `8. Updated CV/ Resume`,
                `9. Immigration history of student for any country (if applicable)`,
            ];

        case 'New-Zealand':
            return [
                `1. Passport copy (front & back side)`,
                `2. Academic documents – Marksheets of grade 10th, 12th, bachelors & Masters (including the A.T.K.T or fail mark sheets)`,
                `3. Passing Certificates of all academics (10th, 12th, Bachelors, Masters)`,
                `4. English Language test scores for e.g. IELTS, PTE, TOEFL`,
                `5. SOP (500 /1000 words or maximum 2 pages)`,
                `6. 2 Letters of Recommendations (It should be on the letterhead of the institution with date, name of the referee, contact no, and email id)`,
                `7. Work Experience letters/ Academic Gap documents – if applicable (appointment
                letter incase currently working, salary slips for past 6 months, Bank statement of salary evidence)`,
                `8. Updated CV/ Resume`,
                `9. Immigration history of student for any country (if applicable)`,
            ];

        case 'Australia':
            return [
                `1. Passport copy (Front & Back Side)`,
                `2. Academic Documents – Marksheets of Grade 10th, 12th, Bachelors & Masters
                (including the A.T.K.T or Fail Marksheets)`,
                `3. Passing Certificates of all Academics (10th, 12th, Bachelors, Masters)`,
                `4. English Language Test Score for e.g., IELTS, PTE, TOEFL (As per University
                Requirement)`,
                `5. 2 Letters of Recommendations (It should be on the letterhead of the institution with
                date, name of the referee, contact no, and email id)`,
                `6. Work Experience letters/Academic Gap documents – if applicable (appointment
                letter in case currently working, salary slips of past 6 months, Bank statement of
                salary evidence)`,
                `7. Updated CV/ Resume`,
                `8. Immigration history of student for any country (if applicable)`,
            ];

        default:
            return [
                `1. Passport copy (Front & Back Side)`,
                `2. Academic Documents – Marksheets of Grade 10th, 12th, Bachelors & Masters
                (including the A.T.K.T or Fail Marksheets)`,
                `3. Passing Certificates of all Academics (10th, 12th, Bachelors, Masters)`,
                `4. English Language Test Score for e.g., IELTS, PTE, TOEFL (As per University
                Requirement)`,
                `5. SOP (500 /1000 words or maximum 2 pages)`,
                `6. 2 Letters of Recommendations (It should be on the letterhead of the institution with
                date, name of the referee, contact no, and email id)`,
                `7. Work Experience letters/Academic Gap documents – if applicable (appointment
                letter in case currently working, salary slips of past 6 months, Bank statement of
                salary evidence)`,
                `8. Updated CV/ Resume`,
                `9. Immigration history of student for any country (if applicable)`,
            ];
    }
};

export const deserializePriorityData = (data) => {
    return (data || []).map((priorityNumber) => {
        switch (priorityNumber) {
            case 'P1':
                return `${priorityNumber} (Only 01 allowed)`;
            case 'P2':
                return `${priorityNumber} (At max 02 allowed)`;
            case 'P3':
                return `${priorityNumber} `;
            case 'P4':
                return `${priorityNumber} `;
            case 'P5':
                return `${priorityNumber} `;
        }
    });
};
