import React, { useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// mui
import { Grid, Box, Chip, Typography, Skeleton } from '@mui/material';

// comps
import { FormComponent } from 'azent-storybook/dist';

// styles
import { useStyles } from './styles';

// utils
import {
    getFilterSchema,
    getFiltersUiSchema,
} from '@/props-constants/dashboard-props/filters-schema';
import { getFilteredData, removeFilter } from '@/utils/dashboard-filter-utils';
import { isB2bAdmin } from '@/rbac';

// api
import { getStatePlaces } from '@/api';

// Constant
const todayDate = new Date();
const currentYear = new Date().getFullYear();
const yearEndDate = new Date(`${currentYear}-09-30 23:59:00`);

export default function DashboardFilter({ formData, setFormData }) {
    const classes = useStyles();

    const global = useSelector((state) => state.globalData);
    const user = useSelector((state) => state.userData);

    const [stateOptions, setStateOptions] = useState(null);

    //setting default dates on dashboard
    let fromDate = '';
    let toDate = '';
    if (todayDate <= yearEndDate) {
        fromDate = `${currentYear - 1}-10-01`;
        toDate = `${currentYear}-09-30`;
    } else {
        fromDate = `${currentYear}-10-01`;
        toDate = `${currentYear + 1}-09-30`;
    }

    const handleChange = (data) => {
        setFormData(data);
    };

    useEffect(() => {
        if (!stateOptions)
            getStatePlaces().then((res) =>
                setStateOptions([...new Set(res.data.map((state) => state.name))]),
            );
        setFormData({ created_date: { from: fromDate, to: toDate } });
    }, []);

    const filteredList = useMemo(() => {
        const customFormData = formData ? formData : {};
        return getFilteredData(customFormData);
    }, [formData]);

    const FormComponentWrapper = useMemo(() => {
        let data = null;
        data = (
            <FormComponent
                schema={getFilterSchema(global.data, stateOptions, isB2bAdmin(user.userData))}
                uischema={getFiltersUiSchema(isB2bAdmin(user.userData), formData)}
                data={formData ? formData : {}}
                onChange={({ data }) => handleChange(data)}
            />
        );
        return data;
    }, [global && global.data, user && user.userData, stateOptions, formData]);

    if (!stateOptions || global.loading || user.loading)
        return (
            <Box pb={3} width="100%" display="flex" alignItems="center" justifyContent="center">
                <Skeleton animation="wave" height="60px" width="100%" />
            </Box>
        );

    return (
        <Box>
            {FormComponentWrapper}
            <Box mt={1} mb={4}>
                {filteredList.length > 0 && (
                    <Grid container>
                        <Grid item xs={11}>
                            {filteredList.map((filter) => (
                                <Chip
                                    key={filter}
                                    sx={{ mx: 0.5, my: 0.2 }}
                                    onDelete={() => {
                                        setFormData(removeFilter(filter, formData));
                                    }}
                                    label={filter}
                                    variant="contained"
                                />
                            ))}
                        </Grid>
                        <Grid item className={classes.clearAllContainer} xs={1}>
                            <Typography
                                onClick={() => setFormData({})}
                                className={classes.clearAll}
                                variant="subtitle1"
                                color="primary">
                                Clear All
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Box>
    );
}

DashboardFilter.propTypes = {
    formData: PropTypes.object,
    setFormData: PropTypes.func,
};
