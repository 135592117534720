import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

//mui
import {
    Paper,
    Box,
    Grid,
    Typography,
    ButtonBase,
    Menu,
    MenuItem,
    IconButton,
    Tooltip,
    Stack,
} from '@mui/material';

// utils
import { get } from 'lodash';
import { isB2bAdmin } from '@/rbac';

//components
import { getIcon, ColorCard } from 'azent-storybook/dist';

//prop-constants
import {
    getContainerClass,
    getTitleClass,
    getLabelForApplicationStatus,
} from '@/props-constants/application/application-status';
import { getAppPriorityIconStyle } from '@/props-constants/application/application-profile';

//comps
import ApplicationIntake from './ApplicationIntake';

//css
import { useStyles } from '../../../ListApplication/style';

const intakeOptions = ['Update intake'];

export const ApplicationHeader = ({ data, classes, handleModalOpen }) => {
    const [moreIconAnchor, setMoreIconAnchor] = React.useState(null);
    const [isIntakeModalOpen, setIntakeModalOpen] = React.useState(false);

    const _classes = useStyles();

    const isOpen = Boolean(moreIconAnchor);
    const AppStatus = data.application_status.current_status;
    const programName = get(data, 'program_info.name', '-');
    const priority = get(data, 'preference', '');

    const handleUpdateIntake = () => {
        setMoreIconAnchor(null);
        setIntakeModalOpen(true);
    };

    return (
        <React.Fragment>
            {data && (
                <Box>
                    <Paper elevation={0} className={classes.paperClass}>
                        {/* NOTE: told to hide this
                         <Typography variant="subtitle1" color="secondary">
                            {`Application Id: ${get(data, '_id', '-')}`}
                         </Typography> */}
                        <Grid
                            container
                            className={classes.applicationHeaderContainer}
                            wrap="nowrap">
                            <Grid item container xs={isB2bAdmin() ? 5 : 5.2} pr={3}>
                                <Box className={classes.flexRowContainer}>
                                    <Box pr={2}>
                                        <Box className={classes.iconContainer}>
                                            <Typography>
                                                {getIcon(
                                                    'account-balance',
                                                    'primary',
                                                    'small',
                                                    classes.iconTitle,
                                                )}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className={classes.flexColContainer}>
                                        <Stack direction={'row'}>
                                            <Typography
                                                variant="body2"
                                                className={classes.universityText}>
                                                {`${get(
                                                    data,
                                                    'program_info.university_name',
                                                    '-',
                                                )} `}
                                            </Typography>
                                            <Typography variant="h6" ml={1}>
                                                {priority.length
                                                    ? getIcon(
                                                          'fire',
                                                          'primary',
                                                          'small',
                                                          getAppPriorityIconStyle(
                                                              priority,
                                                              _classes,
                                                          ),
                                                      )
                                                    : ''}
                                                {priority}
                                            </Typography>
                                        </Stack>

                                        <Tooltip
                                            title={`${get(data, 'program_info.name', '-')}`}
                                            placement="top">
                                            <Box className={classes.programNameContainer}>
                                                <Typography className={classes.programNameText}>
                                                    {programName.length > 100
                                                        ? `${programName.slice(0, 50)}...`
                                                        : programName}
                                                </Typography>
                                            </Box>
                                        </Tooltip>

                                        <Box className={classes.flexRowContainer}>
                                            <Typography mr={1} className={classes.disabledText}>
                                                <em> {`Created on:`}</em>
                                            </Typography>
                                            <Typography className={classes.disabledText}>
                                                <em>
                                                    {moment(data.created_at).format('DD/MM/YYYY')}
                                                </em>
                                            </Typography>
                                        </Box>
                                        <Box className={classes.flexRowContainer}>
                                            <Typography mr={1} className={classes.disabledText}>
                                                <em>{`Intake: `}</em>
                                            </Typography>
                                            <Typography className={classes.disabledText}>
                                                <em>
                                                    {get(data, 'intake.month', '-')}
                                                    {' - '}
                                                    {get(data, 'intake.year', '-')}
                                                </em>
                                            </Typography>
                                        </Box>
                                        <Box className={classes.flexRowContainer}>
                                            <Typography mr={1} className={classes.disabledText}>
                                                <em>{`University Type: `}</em>
                                            </Typography>
                                            <Typography className={classes.disabledText}>
                                                <em>
                                                    {get(
                                                        data,
                                                        'university_partnership_info.partnership_type',
                                                        '-',
                                                    )}
                                                </em>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={isB2bAdmin() ? 2.8 : 3}>
                                <Typography className={classes.disabledText}>
                                    Application Owner
                                </Typography>
                                <Box display={'flex'}>
                                    <span sx={{ verticalAlign: 'middle' }}>
                                        {getIcon('person', 'inherit', 'small', classes.personIcon)}
                                    </span>
                                    <Typography variant="h6" className={classes.textFlowWrap}>
                                        {get(data, 'owner__name', '-')}
                                    </Typography>
                                </Box>
                                <Typography variant="h6">
                                    <span sx={{ verticalAlign: 'middle' }}>
                                        {getIcon('phone', 'inherit', 'small', classes.icon)}{' '}
                                    </span>
                                    {get(data, 'owner__mobile', '-')}
                                </Typography>
                                <Box display={'flex'}>
                                    <span sx={{ verticalAlign: 'middle' }}>
                                        {getIcon('mail', 'inherit', 'small', classes.icon)}
                                    </span>
                                    <Typography
                                        variant="h6"
                                        className={classes.textFlowWrap}
                                        pl={0.5}>
                                        {get(data, 'owner__email', '-')}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid
                                item
                                xs={3}
                                className={classes.appStatusContainer}
                                pr={isB2bAdmin() ? 0 : 2}>
                                <ButtonBase
                                    className={classes.cardAction}
                                    onClick={handleModalOpen}
                                    disabled={!isB2bAdmin()}>
                                    <ColorCard
                                        title={`${getLabelForApplicationStatus(AppStatus)}`}
                                        classes={{
                                            container: getContainerClass(classes, AppStatus),
                                            title: getTitleClass(classes, AppStatus),
                                        }}
                                        endIcon={
                                            isB2bAdmin() &&
                                            getIcon(
                                                'expand-more',
                                                'inherit',
                                                'small',
                                                classes.exploreMoreIcon,
                                            )
                                        }></ColorCard>
                                </ButtonBase>

                                {isB2bAdmin() && (
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={isOpen ? 'long-menu' : undefined}
                                        aria-expanded={isOpen ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={(event) => setMoreIconAnchor(event.currentTarget)}>
                                        {getIcon('more-vert', 'inherit', 'small')}
                                    </IconButton>
                                )}
                                <Menu
                                    id="long-menu"
                                    anchorEl={moreIconAnchor}
                                    open={isOpen}
                                    onClose={() => setMoreIconAnchor(null)}
                                    PaperProps={{
                                        style: {
                                            maxHeight: 48 * 4.5,
                                            width: '14ch',
                                            textAlign: 'center',
                                        },
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}>
                                    {intakeOptions.map((option) => (
                                        <MenuItem
                                            key={option}
                                            onClick={handleUpdateIntake}
                                            sx={{ fontSize: '14px' }}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Grid>
                        </Grid>
                    </Paper>
                    <ApplicationIntake
                        classes={classes}
                        isIntakeModalOpen={isIntakeModalOpen}
                        setIntakeModalOpen={setIntakeModalOpen}
                        data={data}
                    />
                </Box>
            )}
        </React.Fragment>
    );
};

ApplicationHeader.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    handleModalOpen: PropTypes.func,
};
