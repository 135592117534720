import * as React from 'react';
import PropTypes from 'prop-types';

// mui
import { Typography, Button, Grid, Box, Tooltip, tooltipClasses } from '@mui/material';
import { Skeleton } from '@mui/lab';
import { styled } from '@mui/material/styles';

// comps
import { getIcon } from 'azent-storybook/dist';

// styles
import { useStyles } from './styles';

//utils
import { isSubAgent, isPartner } from '@/rbac';
import { getIntakeType } from '@/utils/courseFinder';
import { toastMessages } from '@/utils/toastMessages';

const adminLoginTag = (type, createdByName, partner_name) => {
    switch (type) {
        case 'COUNSELLOR':
            return `By AdmissionBox - ${createdByName}`;
        case 'PARTNER':
            return `By Partner - ${createdByName}`;
        case 'SUB_AGENT': {
            return `By Partner - ${partner_name} (Sub agent - ${createdByName})`;
        }
        default:
            return `By ${createdByName}`;
    }
};

const partnerLoginTag = (type, createdByName) => {
    switch (type) {
        case 'COUNSELLOR':
            return `By AdmissionBox - ${createdByName}`;
        case 'PARTNER':
            return `By You`;
        case 'SUB_AGENT':
            return `By Sub agent - ${createdByName}`;
        default:
            return `By ${createdByName}`;
    }
};

const subAgentLoginTag = (type, createdByName) => {
    switch (type) {
        case 'COUNSELLOR':
            return `By AdmissionBox - ${createdByName}`;
        case 'PARTNER':
            return `By Partner - ${createdByName}`;
        case 'SUB_AGENT':
            return `By You`;
        default:
            return `By ${createdByName}`;
    }
};

export const ShortlistCard = ({
    courseName,
    university,
    tuitionCost,
    loading,
    intakes,
    duration,
    rank,
    color,
    isRejected,
    isApplied,
    onApply,
    onReject,
    onReAccept,
    onClickShortlistCard,
    programLink,
    applicationFee,
    intakeDetails,
    type,
    isDisableCreateApplication,
    createdByName,
    partner_name,
}) => {
    const classes = useStyles();
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            borderRadius: '0px',
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));
    const intakesCard = (intakes, loading) => {
        if (loading) {
            return (
                <Typography variant="subtitle1" className={classes.cardProperty}>
                    <Skeleton animation="wave" width={100} />
                </Typography>
            );
        }
        return (
            <div style={{ display: 'flex', maxWidth: '100%', flexWrap: 'wrap' }}>
                {renderIntake(intakes)}
            </div>
        );
    };

    const renderIntake = (data) => {
        return data.map((intake) => (
            <LightTooltip
                key={intake}
                title={getIntakeType(intake, intakeDetails) ? 'Intake Open' : ' Not Open Yet'}>
                <div
                    className={
                        getIntakeType(intake, intakeDetails)
                            ? classes.activeIntakeCard
                            : classes.intakeCard
                    }>
                    <Typography variant="subtitle1">{intake}</Typography>
                </div>
            </LightTooltip>
        ));
    };

    const onClickHandler = (e) => {
        e.stopPropagation();
        if (isDisableCreateApplication) return;
        else onApply();
    };

    return (
        <Box
            p={2}
            sx={{ cursor: 'pointer' }}
            onClick={onClickShortlistCard}
            className={classes.courseCard}>
            <Grid mb={0.5} container>
                <Grid item xs={1} />
                <Grid item xs={5}>
                    {/* NOTE: Told to not show applicationDeadline for now */}
                    <Typography color="primary.main" variant="h6">
                        {loading ? (
                            <Skeleton animation="wave" />
                        ) : applicationFee && applicationFee != 0 ? (
                            `Application Fee : ${applicationFee}`
                        ) : applicationFee == 0 && applicationFee ? (
                            'Application Fee : Wavied Off'
                        ) : null}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid display="flex" justifyContent={'center'} item xs={1}>
                    <Box
                        sx={{
                            borderRadius: '50%',
                            height: '40px',
                            width: '40px',
                            background: color,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ textAlign: 'left' }}>
                        <Typography variant="h5" mr={1}>
                            {loading ? (
                                <Skeleton animation="wave" />
                            ) : programLink ? (
                                <a
                                    href={programLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classes.programLink}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}>
                                    {courseName}
                                </a>
                            ) : (
                                courseName
                            )}
                            <span
                                className={
                                    type.includes('SUB_AGENT')
                                        ? classes.subAgentPill
                                        : type.includes('PARTNER')
                                        ? classes.partnerPill
                                        : classes.azentPill
                                }>
                                {isSubAgent()
                                    ? subAgentLoginTag(type, createdByName)
                                    : isPartner()
                                    ? partnerLoginTag(type, createdByName)
                                    : adminLoginTag(type, createdByName, partner_name)}
                            </span>
                        </Typography>
                    </Box>
                </Grid>
                <Grid container justifyContent={'flex-end'} item xs={5}>
                    {isApplied ? (
                        <Grid item px={1} xs={6}>
                            <Button
                                sx={{
                                    borderRadius: 1,
                                    fontSize: '14px',
                                    py: 1,
                                }}
                                variant="outlined"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                fullWidth={true}
                                disabled={true}>
                                Applied
                            </Button>
                        </Grid>
                    ) : isRejected ? (
                        <Grid item px={1} xs={6}>
                            <Button
                                sx={{
                                    borderRadius: 1,
                                    fontSize: '14px',
                                    py: 1,
                                }}
                                variant="outlined"
                                color="success"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    onReAccept();
                                }}
                                disabled={loading}
                                fullWidth={true}>
                                Re Activate
                            </Button>
                        </Grid>
                    ) : (
                        <>
                            <Grid px={1} item xs={6}>
                                <Button
                                    sx={{
                                        borderRadius: 1,
                                        fontSize: '14px',
                                        py: 1,
                                    }}
                                    variant="outlined"
                                    color="primary"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        onReject();
                                    }}
                                    disabled={loading}
                                    fullWidth={true}>
                                    Reject
                                </Button>
                            </Grid>
                            <Grid px={1} item xs={6}>
                                <Tooltip
                                    title={
                                        isDisableCreateApplication
                                            ? toastMessages.applicationLimit.message
                                            : 'Apply Now'
                                    }>
                                    <Button
                                        sx={{
                                            borderRadius: 1,
                                            fontSize: '14px',
                                            py: 1,
                                            '&.Mui-disabled': {
                                                pointerEvents: 'auto',
                                            },
                                        }}
                                        variant="outlined"
                                        color="success"
                                        onClick={(e) => onClickHandler(e)}
                                        component={isDisableCreateApplication ? 'div' : undefined}
                                        disabled={loading || isDisableCreateApplication}
                                        fullWidth={true}>
                                        Apply Now
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={1}>
                    <Box sx={{ height: '40px', width: '40px' }} />
                </Grid>
                <Grid item xs={4} className={classes.item}>
                    {getIcon('account-balance', 'inherit', 'small', classes.cardIcons)}
                    <Box className={classes.linkProperty} display="flex" justifyContent="flex-end">
                        {loading ? (
                            <Skeleton animation="wave" />
                        ) : (
                            (university && university.name) || '-'
                        )}
                    </Box>
                </Grid>
                <Grid item xs={4} className={classes.item}>
                    {getIcon('location-on', 'inherit', 'small', classes.cardIcons)}
                    <Typography variant="subtitle1" className={classes.cardProperty}>
                        {loading ? (
                            <Skeleton animation="wave" />
                        ) : (
                            (university && university.address && university.address.country) || '-'
                        )}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={classes.item}>
                    {getIcon('date-range', 'inherit', 'small', classes.cardIcons)}
                    <div className={classes.intakeCardsProperty}>
                        {intakesCard(intakes, loading)}
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={1}>
                    <Box sx={{ height: '40px', width: '40px' }} />
                </Grid>
                <Grid item xs={4} className={classes.item}>
                    {getIcon('monetization-on', 'inherit', 'small', classes.cardIcons)}
                    <Typography variant="subtitle1" className={classes.cardProperty}>
                        {loading ? <Skeleton animation="wave" /> : tuitionCost || '-'}
                    </Typography>
                </Grid>
                <Grid item xs={4} className={classes.item}>
                    {getIcon('trending-up', 'inherit', 'small', classes.cardIcons)}
                    <Typography variant="subtitle1" className={classes.cardProperty}>
                        {loading ? <Skeleton animation="wave" /> : rank || '-'}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={classes.item}>
                    {getIcon('schedule', 'inherit', 'small', classes.cardIcons)}
                    <Typography variant="subtitle1" className={classes.cardProperty}>
                        {loading ? <Skeleton animation="wave" /> : duration || '-'}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

ShortlistCard.propTypes = {
    courseName: PropTypes.string.isRequired,
    university: PropTypes.object.isRequired,
    tuitionCost: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    intakes: PropTypes.array.isRequired,
    intakeDetails: PropTypes.array.isRequired,
    color: PropTypes.string.isRequired,
    duration: PropTypes.string,
    rank: PropTypes.string,
    applicationFee: PropTypes.string,
    isRejected: PropTypes.bool,
    isApplied: PropTypes.bool,
    onReject: PropTypes.func,
    onApply: PropTypes.func,
    onReAccept: PropTypes.func,
    onClickShortlistCard: PropTypes.func,
    type: PropTypes.string,
    programLink: PropTypes.string,
    isDisableCreateApplication: PropTypes.bool,
    createdByName: PropTypes.string,
    partner_name: PropTypes.string,
};

ShortlistCard.defaultProps = {
    isRejected: false,
    isApplied: false,
    onReject: () => {},
    onApply: () => {},
    onReAccept: () => {},
};
