// @ts-nocheck
// Icons
import AvatarBoy from '@/assets/avatar-boy.png';
import AvatarGirl from '@/assets/avatar-girl.png';

import getSvg from '@/utils/get-svg';

const getUserAvatarByGender = (gender) => {
    switch (gender) {
        case 'male':
            return AvatarBoy;
        case 'female':
            return AvatarGirl;
        default:
            return AvatarBoy;
    }
};

const getProfileSuccessAvatarByGender = (gender, size = '120px') => {
    switch (gender) {
        case 'Male':
            return getSvg('boy-with-check', '', size);
        case 'Female':
            return getSvg('girl-with-check', '', size);
        default:
            return getSvg('girl-with-check', '', size);
    }
};

export { getUserAvatarByGender, getProfileSuccessAvatarByGender };
