import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        borderRadius: theme.spacing(2),
        backgroundColor: '#F5F6F7',
        marginBottom: theme.spacing(2.75),
        '&::before': { backgroundColor: 'inherit' },
        '.Mui-expanded': {
            margin: theme.spacing(2, 0),
            minHeight: theme.spacing(5),
        },
    },
    title: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    application: {
        overflowX: 'hidden',
        borderRadius: theme.spacing(2),
        backgroundColor: '#F5F6F7',
        marginBottom: theme.spacing(2.75),
        maxHeight: theme.spacing(55.5),
        '&::before': { backgroundColor: 'inherit' },
        '.Mui-expanded': {
            margin: theme.spacing(2, 0),
            minHeight: theme.spacing(5),
        },
        '&::-webkit-scrollbar': {
            width: 7,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
            marginTop: 10,
            marginBottom: 10,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#E2E2E2',
            borderRadius: 5,
        },
    },
    subTitle: {
        color: theme.palette.primary.main,
    },
}));
