import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2, 0, 2, 2),
        border: '1px solid #78A5FF !important',
        borderRadius: theme.spacing(2),
    },
    customTab: {
        fontSize: theme.spacing(1.75),
        color: theme.palette.text.light,
        fontWeight: 500,
        '&:active': {
            color: theme.palette.primary.main,
        },
        '&.Mui-selected': {
            color: theme.palette.primary.main,
        },
    },
    customContentContainer: {
        paddingLeft: theme.spacing(0.5),
    },
    historyTitle: {
        margin: theme.spacing(2, 0, 0, 1),
        color: theme.palette.text.disabled,
    },
    historyContainer: {
        height: theme.spacing(67),
        overflow: 'scroll',
        background: '#F5F6F7',
        margin: theme.spacing(1, 1.25),
        borderRadius: theme.spacing(2),
        '&::-webkit-scrollbar': {
            width: 7,
            height: '100%',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
            marginTop: 10,
            marginBottom: 10,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#E2E2E2',
            borderRadius: 5,
        },
    },
    timelineDotColor: {
        backgroundColor: theme.palette.neutral.dark,
    },
    documentListContainer: {
        maxHeight: theme.spacing(67),
        overflow: 'scroll',
        paddingLeft: theme.spacing(1.5),
    },
    cardAction: {
        marginTop: '4px',
        display: 'flex',
    },
    colorCardContainer: {
        padding: theme.spacing(1, 0),
        borderRadius: '12px',
        backgroundColor: '#E5EDFF',
        textAlign: 'center',
        border: '1px solid #6D96E6',
        display: 'flex',
        justifyContent: 'center',
    },
    title: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '400',
        color: '#6D96E6',
    },
    exploreMoreIcon: {
        width: '100%',
        fontSize: '14px',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '5px',
    },
    containerStudentBlue: {
        padding: '4px 8px 4px 8px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#E5EDFF',
        border: '1px solid #749BEB',
        minWidth: '180px',
        minHeight: '33px',
    },
    containerStudentRed: {
        padding: '4px 8px 4px 8px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#FFE2DCF0',
        border: '1px solid #E08A8A',
        minWidth: '180px',
        minHeight: '33px',
    },
    containerStudentGreen: {
        padding: '4px 8px 4px 8px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#E9FFE9',
        border: '1px solid #75D675',
        minWidth: '180px',
        minHeight: '33px',
    },
    containerStudentYellow: {
        padding: '4px 8px 4px 8px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#FFEBD3',
        border: '1px solid #F89B6D',
        minWidth: '180px',
        minHeight: '33px',
    },
    containerStudentPurple: {
        padding: '4px 8px 4px 8px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#E4DBFFF0',
        border: '1px solid #A288F1',
        minWidth: '180px',
        minHeight: '33px',
    },

    titleStudentBlue: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#749BEB',
        whiteSpace: 'pre-wrap !important',
    },
    titleStudentRed: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#E08A8A',
        whiteSpace: 'pre-wrap !important',
    },
    titleStudentGreen: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#75D675',
        whiteSpace: 'pre-wrap !important',
    },
    titleStudentYellow: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#F89B6D',
        whiteSpace: 'pre-wrap !important',
    },
    titleStudentPurple: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#A288F1',
        whiteSpace: 'pre-wrap !important',
    },
    saveCommentButton: {
        fontSize: '14px',
        fontWeight: '600',
        borderRadius: '10px',
        color: 'white',
        lineHeight: '1.75',
        fontFamily: 'Montserrat',
        padding: '5px 25px',
        height: '50px',
        backgroundColor: '#00c853',
        '&:hover': {
            backgroundColor: '#3D8944',
        },
    },
    icon: {
        fontSize: theme.spacing(2),
        color: theme.palette.text.disabled,
    },
    personIcon: {
        fontSize: theme.spacing(2),
        color: theme.palette.text.disabled,
        borderRadius: '50%',
        border: 'solid 1.5px #6D6D6D',
        verticalAlign: 'text-top',
        marginRight: theme.spacing(0.7),
    },
    disabledText: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#6D6D6D',
    },
    centerElement: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textFlowWrap: {
        overflowWrap: 'anywhere',
    },
    studentSummaryContainer: {
        paddingLeft: '14px',
        paddingRight: '7px',
    },
}));
