import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { capitalize, isEmpty } from 'lodash';

//components
import { MenuBar as B2BMenuBar } from 'azent-storybook/dist';

//props
import getMenuBarProps from '@/props-constants/MenubarProps';

//utils
import { getUserAvatarByGender } from '@/utils/get-user-avatar';

// styles
import { useStyles } from './styles';

let intervalId = null;

export default function MenuBar({ isVisibleActions, partnerOrganizationName }) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const data = useSelector((state) => state.userData);
    const location = useSelector((state) => state.router.location);
    const refreshData = useSelector((state) => state.refreshPlatformData.refreshData);
    const displayTime = useSelector((state) => state.refreshPlatformData.displayTime);
    const subAgentId = useSelector((state) => state.userData.userData.id);

    const [incrementalValue, setIncrementalValue] = React.useState(null);

    // Show switch on dashboard removed
    // location.pathname === routePaths.DASHBOARD, dispatch,

    const menubarProps = useMemo(
        () =>
            getMenuBarProps({
                classes,
                dispatch,
                path: location.pathname,
                refreshData,
                displayTime,
                isVisibleActions,
                partnerOrganizationName: capitalize(partnerOrganizationName),
                subAgentId,
            }),
        [location.pathname, refreshData, displayTime, incrementalValue, subAgentId],
    );

    const fname = useMemo(() => {
        if (!isEmpty(data)) {
            if (data.userData.first_name) return data.userData.first_name;
        }
    }, [data]);

    const userLetter = useMemo(() => {
        if (!isEmpty(data)) {
            if (data.userData.first_name) return data.userData.first_name.charAt(0);
        }
    }, [data]);

    // TODO:Create a custom hook for it
    React.useEffect(() => {
        if (displayTime) {
            setIncrementalValue(1);
            if (intervalId) {
                clearInterval(intervalId);
            }
            intervalId = setInterval(() => {
                setIncrementalValue((value) => value + 1);
            }, 1000 * 60);
        } else {
            setIncrementalValue(null);
        }
        return () => {
            intervalId && clearInterval(intervalId);
        };
    }, [displayTime]);

    // NOTE: to use when add notification
    // const profileData = useSelector((state) => state.profile.data);

    // const notifications = useSelector((state) => state.notifications);

    // const [notificationAction, setNotificationAction] = React.useState({
    //     color: "inherit",
    //     icon: getIcon("notifications-none"),
    //     label: "notifications",
    //     onClick: () => console.log("notifications clicked"),
    // });

    // React.useEffect(() => {
    //     if (!notifications.loading) {
    //         setNotificationAction({
    //             color: notifications.data.length > 0 ? "primary" : "inherit",
    //             icon:
    //                 notifications.data.length > 0
    //                     ? getIcon("notifications", "primary")
    //                     : getIcon("notifications-none"),
    //             label: "notifications",
    //             onClick: () => console.log("notifications clicked"),
    //         });
    //     }
    // }, [notifications]);

    return (
        <>
            <B2BMenuBar
                actionsList={menubarProps.actionsList}
                logo={{
                    ...menubarProps.logo,
                    onClick: () => {},
                }}
                notification={menubarProps.notification}
                user={{
                    userName: fname,
                    actionsList: menubarProps.user.actionsList,

                    userAvatarSrc: getUserAvatarByGender('male'),

                    onProfileClick: () => {},
                }}
                userLetter={userLetter}
                FAQ={menubarProps.FAQ}
            />
        </>
    );
}

MenuBar.propTypes = {
    // setHideMobileNav: PropTypes.func.isRequired,
    isVisibleActions: PropTypes.bool,
    partnerOrganizationName: PropTypes.string,
};
