import axios from 'axios';
import constants from '@/constants';
import getHeaders from '@/utils/get-headers';

let instance = axios.create({
    baseURL: constants.fileApi,
    headers: getHeaders(),
});

instance.interceptors.request.use(
    function (config) {
        config.headers = getHeaders();
        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);

export default instance;
