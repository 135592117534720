// mui
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: '8px 2px 8px 4px',
        borderRadius: '12px',
        textAlign: 'center',
    },
    title: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '400',
    },
    containerBlue: {
        padding: '8px 4px 8px 4px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#E5EDFF',
        border: '1px solid #6D96E6',
        width: '210px',
    },
    containerGreen: {
        padding: '8px 4px 8px 4px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#E9FFE9',
        border: '1px solid #5AAF5A',
        width: '210px',
    },
    containerYellow: {
        padding: '8px 4px 8px 4px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#FFEBD3',
        border: '1px solid #F56A20',
        minWidth: '160px',
    },
    customContentContainer: {
        paddingLeft: theme.spacing(0.5),
    },
    containerRed: {
        padding: '8px 4px 8px 4px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#FFE2DCF0',
        border: '1px solid #D07070',
        minWidth: '160px',
    },
    containerGrey: {
        padding: '8px 4px 8px 4px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#E7E7E7F0',
        border: '1px solid #6F716F',
        minWidth: '160px',
    },
    titleBlue: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#6D96E6',
        whiteSpace: 'pre-wrap !important',
    },
    titleGreen: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#5AAF5A',
        whiteSpace: 'pre-wrap !important',
    },
    titleYellow: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#F56A20',
        whiteSpace: 'pre-wrap !important',
    },
    titleRed: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#D07070',
        whiteSpace: 'pre-wrap !important',
    },
    titleGrey: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#6F716F',
        whiteSpace: 'pre-wrap !important',
    },
    exploreMoreIcon: {
        width: '100%',
        fontSize: '14px',
        marginTop: '4px',
    },
    paperClass: {
        padding: '10px 0px 0px 0px',
    },
    iconContainer: {
        backgroundColor: '#FFB684',
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '9px',
    },
    iconTitle: {
        backgroundColor: 'transparent',
        verticalAlign: 'bottom',
        color: 'white',
        fontSize: '31px',
    },
    cardAction: {},
    BoxContainer: {
        padding: theme.spacing(2, 0, 2, 2),
        border: '1px solid #78A5FF',
        borderRadius: theme.spacing(2),
        minWidth: '100%',
    },
    GridContainer: {
        flexGrow: 0,
    },
    customTab: {
        fontSize: theme.spacing(1.75),
        fontWeight: 500,
        color: theme.palette.text.light,
        '&:active': {
            color: theme.palette.primary.main,
        },
        '&.Mui-selected': {
            color: theme.palette.primary.main,
        },
    },
    historyTitle: {
        color: theme.palette.text.disabled,
        margin: theme.spacing(2, 0, 0, 1),
    },
    historyContainer: {
        overflow: 'scroll',
        background: '#F5F6F7',
        margin: theme.spacing(1, 1.25, 1, 1.25),
        borderRadius: theme.spacing(2, 2),
        minHeight: theme.spacing(67),
        maxHeight: theme.spacing(67),
        '&::-webkit-scrollbar': {
            width: 7,
            height: '100%',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
            marginTop: 10,
            marginBottom: 10,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#E2E2E2',
            borderRadius: 5,
        },
    },
    documentListContainer: {
        maxHeight: theme.spacing(67),
        overflow: 'scroll',
    },
    myfont12: {
        fontSize: '12px',
    },
    myfont13: {
        fontSize: '13px',
    },
    myfont14: {
        fontSize: '14px',
    },
    myfont15: {
        fontSize: '15px',
    },
    cModalTitleContainer: {
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        paddingLeft: '14px',
    },
    cModalTitle: {
        color: 'white',
        fontSize: '20px',
    },
    timelineDotColor: {
        backgroundColor: theme.palette.neutral.dark,
    },
    saveCommentButton: {
        fontSize: '14px',
        fontWeight: '600',
        borderRadius: '10px',
        color: 'white',
        lineHeight: '1.75',
        fontFamily: 'Montserrat',
        padding: '5px 25px',
        height: '50px',
        backgroundColor: '#00c853',
        '&:hover': {
            backgroundColor: '#3D8944',
        },
    },
    icon: {
        fontSize: theme.spacing(2),
        color: theme.palette.text.disabled,
    },
    personIcon: {
        fontSize: theme.spacing(2),
        color: theme.palette.text.disabled,
        borderRadius: '50%',
        border: 'solid 1.5px #6D6D6D',
        verticalAlign: 'text-top',
        marginRight: theme.spacing(0.7),
    },
    programNameContainer: {
        maxWidth: 500,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    programNameText: {
        fontWeight: '500',
        lineHeight: '1.43',
        fontSize: '1rem',
    },
    universityText: {
        color: '#78A5FF',
        fontWeight: '500',
    },
    flexRowContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    flexColContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    disabledText: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#6D6D6D',
    },
    centerElement: {
        display: 'flex',
        alignItems: 'center',
    },
    NameContainer: {
        maxWidth: 200,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    appStatusContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '32px',
        alignItems: 'start',
        justifyContent: 'right',
    },
    textFlowWrap: {
        overflowWrap: 'anywhere',
    },
    applicationHeaderContainer: {
        justifyContent: 'space-between',
        padding: '0px 0px 7px 14px',
    },
}));
