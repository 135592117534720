import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material
import { Box, Grid, TablePagination, Skeleton } from '@mui/material';

//components
import { CourseCard } from '@/components/shared/CourseCard';
import { ProgramDrawer, ActionBox, FormComponent } from 'azent-storybook/dist';
import ApplyModal from '@/components/shared/ApplyModal';

// utils
import { colorCodes } from '@/utils/courseFinder';
import { goToRoute, setQueries, setQuery } from '@/store/actions/navigationActions';
import { deserializeProgram } from '@/utils/deserializers/program-deserializer';
import routePaths, { courseFinderPaths } from '@/utils/route-paths';
import { schema, uiSchema } from '@/components/shared/StudentSelect';
import { addToShortlist, courseAppliedv2, courseShortlisted } from '@/utils/shortlists';
import { deserializeStudent } from '@/utils/deserializers/student-deserializer';
import { isPartner, isSubAgent } from '@/rbac';

// actions
import { addStudentToStore } from '@/store/slices/studentSlice';

//styles
import { useStyles } from './styles';

// apis
import { getProfile, getProgram, getPrograms } from '@/api';

let timeout = null;

export default function NewTieup() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [programs, setPrograms] = useState([1, 2, 3, 4, 5, 6, 7]);
    const [loading, setLoading] = useState(true);
    const [applyModalProps, setApplyModalProps] = useState(null);
    const [showProgram, setShowProgram] = useState(null);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [formData, setFormData] = useState({});
    const [profileLoading, setProfileLoading] = useState(false);
    const [disableCreateApplication, setDisableCreateApplication] = useState(false);

    const location = useSelector((state) => state.router.location);
    const search = new URLSearchParams(location.search);
    let profile = useSelector((state) => state.student.data[search.get('_id')]);

    useEffect(() => {
        if (
            (isPartner() || isSubAgent()) &&
            profile &&
            profile.applications &&
            profile.applications.length >= 5
        )
            setDisableCreateApplication(true);
        else setDisableCreateApplication(false);
    }, [profile]);

    useEffect(() => {
        if (search.get('courseId')) {
            getProgram(search.get('courseId')).then((res) => {
                const newProgram = {
                    ...deserializeProgram(res.data),
                    logoColor: colorCodes[0],
                };
                setShowProgram(newProgram);
            });
        }
        const student = {};
        const formData = Object.fromEntries(search);
        if (formData._id) {
            student._id = formData._id;
        }
        if (formData.first_name) {
            student.first_name = formData.first_name;
        }
        if (formData.last_name) {
            student.last_name = formData.last_name;
        }
        if (formData.mobile) {
            student.mobile = formData.mobile;
        }
        setFormData({ student });
        if (search.get('_id') && !profile) {
            setProfileLoading(true);
            getProfile(search.get('_id')).then((res) => {
                dispatch(addStudentToStore(deserializeStudent(res.data)));
                setProfileLoading(false);
            });
        }
    }, [location.search]);

    useEffect(() => {
        setLoading(true);
        const query = Object.fromEntries(search);
        getPrograms({ page: query.page, per_page: query.per_page }).then((res) => {
            setPrograms(res.data.map((program) => deserializeProgram(program)));
            setPage(Number(res.headers.page));
            setPerPage(Number(res.headers.per_page));
            setTotalRows(Number(res.headers.total_pages) * Number(res.headers.per_page));
            setLoading(false);
            setProfileLoading(false);
        });
    }, [search.get('page'), search.get('per_page')]);

    const selectStudent = (data) => {
        if (data.student) {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                const queries = [
                    { key: 'page', value: search.get('page') },
                    { key: 'per_page', value: search.get('per_page') },
                ];
                if (data.student._id) {
                    queries.push({
                        key: '_id',
                        value: data.student._id,
                    });
                }
                if (data.student.mobile) {
                    queries.push({
                        key: 'mobile',
                        value: data.student.mobile,
                    });
                }
                if (data.student.first_name) {
                    queries.push({
                        key: 'first_name',
                        value: data.student.first_name,
                    });
                }
                if (data.student.last_name) {
                    queries.push({
                        key: 'last_name',
                        value: data.student.last_name,
                    });
                }
                dispatch(setQueries(queries));
            }, [500]);
        } else {
            dispatch(
                setQueries([
                    { key: 'page', value: search.get('page') },
                    { key: 'per_page', value: search.get('per_page') },
                ]),
            );
        }
    };

    const shortlistProgram = (id, programs) => {
        if (id) {
            setLoading(true);
            addToShortlist(id, programs).then((res) => {
                dispatch(addStudentToStore(deserializeStudent(res.data)));
                setLoading(false);
            });
        }
    };

    const addCourseToShortlist = (course) => {
        if (profile) {
            shortlistProgram(profile.id, course);
        } else {
            document
                .getElementById('#/properties/studentinput-field')
                .getElementsByTagName('input')[0]
                .focus();
        }
    };

    const applyNow = (course) => {
        if (profile)
            setApplyModalProps({
                course,
                profile_id: profile.id,
                program_id: course.id,
            });
        else {
            document
                .getElementById('#/properties/studentinput-field')
                .getElementsByTagName('input')[0]
                .focus();
        }
    };

    const redirectToShortlist = () => {
        dispatch(
            goToRoute(routePaths.COURSE_FINDER, {
                path: courseFinderPaths.SHORTLISTS,
                id: profile.id,
            }),
        );
    };

    return (
        <Box className={classes.container}>
            {Boolean(applyModalProps) && (
                <ApplyModal
                    open={Boolean(applyModalProps)}
                    setOpen={setApplyModalProps}
                    showPreview={false}
                    {...applyModalProps}
                />
            )}
            <Box mt={3} mb={3} width="100%">
                {profileLoading ? (
                    <>
                        <Skeleton height="12px" width="32px" />
                        <Skeleton sx={{ borderRadius: 0.7 }} height="60px" width="100%" />
                    </>
                ) : (
                    <FormComponent
                        schema={schema}
                        uischema={uiSchema}
                        data={formData}
                        onChange={({ data, errors }) => {
                            selectStudent(data, errors);
                        }}
                    />
                )}
            </Box>
            <Box>
                {Boolean(showProgram) && (
                    <ProgramDrawer
                        open={Boolean(showProgram)}
                        onClose={() => {
                            setShowProgram(null);
                            dispatch(setQueries(Object.fromEntries(search)));
                        }}
                        onClickUniversity={() => {}}
                        programData={showProgram}
                        width="26%"
                    />
                )}
                <Grid container>
                    <Grid item xs={12} pt={1} pr={2} pl={3}>
                        {!programs.length ? (
                            <Box px={2} mt={2}>
                                <ActionBox
                                    raised={false}
                                    title={{
                                        text: 'Looks like there are no programs for this Query',
                                        variant: 'h5',
                                    }}
                                    buttons={[
                                        {
                                            color: 'secondary',
                                            label: 'clear filters',
                                            onClick: () => {},
                                        },
                                    ]}
                                />
                            </Box>
                        ) : (
                            <>
                                {programs.map((course, index) => (
                                    <Box key={index} py={1}>
                                        <CourseCard
                                            onClickName={() => {
                                                setShowProgram({
                                                    ...course,
                                                    logoColor: colorCodes[index % 3],
                                                });
                                            }}
                                            // NOTE: REMOVE APPLICATION DEADLINE
                                            applicationDeadline={course.applicationDeadline}
                                            programLink={course.program_link}
                                            courseName={course.name}
                                            university={course.university}
                                            loading={loading || profileLoading}
                                            intakes={course.intakes || []}
                                            intakeDetails={course.intakeDetails || []}
                                            tuitionCost={course.tuitionCost}
                                            color={colorCodes[index % 3]}
                                            duration={course.duration}
                                            disableButtons={Boolean(!profile)}
                                            isShortlisted={
                                                profile && courseShortlisted(profile, course)
                                            }
                                            isApplied={profile && courseAppliedv2(profile, course)}
                                            onClickAddToShortlist={() =>
                                                addCourseToShortlist(course)
                                            }
                                            applicationFee={
                                                course.applicationCost
                                                    ? course.applicationCost
                                                    : null
                                            }
                                            onClickApplyNow={() => {
                                                applyNow(course);
                                            }}
                                            redirectToShortlist={redirectToShortlist}
                                            // REMOVE SLUG URL USE UNIVERSITY
                                            slugUrl={
                                                course.university && course.university.slug
                                                    ? course.university.slug
                                                    : null
                                            }
                                            isDisableCreateApplication={disableCreateApplication}
                                        />
                                    </Box>
                                ))}
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20]}
                                    count={totalRows}
                                    rowsPerPage={perPage}
                                    page={page ? page - 1 : 0}
                                    onPageChange={(e, p) => {
                                        dispatch(setQuery('page', p + 1));
                                    }}
                                    onRowsPerPageChange={(e) => {
                                        dispatch(setQuery('per_page', e.target.value));
                                    }}
                                    component={'div'}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
