import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main,
    },
    saveCommentButton: {
        fontSize: '14px',
        fontWeight: '600',
        borderRadius: '10px',
        color: 'white',
        lineHeight: '1.75',
        fontFamily: 'Montserrat',
        padding: '5px 25px',
        height: '50px',
        backgroundColor: '#00c853',
        '&:hover': {
            backgroundColor: '#3D8944',
        },
    },
}));
