import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// material
import { Box, Typography } from '@mui/material';

// comps
import { FormComponent, Button, getIcon } from 'azent-storybook/dist';
import Loader from '@/components/shared/Loader';
import StudentSelect from '@/components/shared/StudentSelect';

// styles
import { useStyles } from './styles';

// utils
import { getCourseFinderUiSchema } from '@/props-constants/course-finder-schema/uiSchema';
import { getCourseFinderSchema } from '@/props-constants/course-finder-schema/schema';
import { getCourseFinderQueries, getFilterDataFromSearchParams } from '@/utils/courseFinder';
import { deserializeProgram } from '@/utils/deserializers/program-deserializer';
import routePaths, { courseFinderPaths } from '@/utils/route-paths';
import { isEmpty, trim } from 'lodash';

// actions
import { setQueries, goToRoute } from '@/store/actions/navigationActions';

// api
import { getPrograms } from '@/api';

let timeout = '';
let filterFormErrors = null;

export default function ProgramSearchFilters({
    id,
    path,
    setData,
    setPage,
    setPerPage,
    setTotalRows,
    loading,
    setLoading,
    profileData,
    setGoBack,
    page,
    perPage,
    filters,
    setFilters,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [searchText, setSearchText] = useState(null);
    const [showFormErrors, setShowFormErrors] = useState(false);
    const [interestCountries, setInterestCountries] = useState([]);

    const globalData = useSelector((state) => state.globalData.data);
    const location = useSelector((state) => state.router.location);

    let search = new URLSearchParams(location.search);
    let goBack = search.get('goBack');

    const getFilteredPrograms = (id, filtersData) => {
        if (id) filtersData['profileId'] = id;
        let searchObj = Object.fromEntries(search);
        getPrograms(searchObj).then((response) => {
            setData(response.data.map((program) => deserializeProgram(program)));
            setPage(Number(response.headers.page));
            setPerPage(Number(response.headers.per_page));
            setTotalRows(Number(response.headers.total_pages) * Number(response.headers.per_page));
            setSearchText(search.get('q') || '');

            setFilters({
                ...filtersData,
                ...getFilterDataFromSearchParams(search),
            });

            setLoading(false);
        });
    };

    React.useEffect(() => {
        setLoading(true);
        getFilteredPrograms(id, {});
    }, [location.search]);

    const uiSchema = useMemo(() => {
        return getCourseFinderUiSchema({
            countries: filters.countries,
            gpaPercentage: filters.cgpa,
            profileId: id,
        });
    }, [filters.countries, filters.cgpa, id]);

    const schema = useMemo(() => {
        return getCourseFinderSchema(globalData);
    }, [globalData.data]);

    const changeFilters = function (data, errors) {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            let flag = false;
            if (!isEmpty(data)) {
                const newFilters = { ...data };
                if (data.exam !== filters.exam) {
                    newFilters.scores = {};
                    flag = true;
                }
                if (
                    (data.level === 'Bachelor' && data.level !== filters.level) ||
                    (data.level === 'Master' && data.level !== filters.level)
                ) {
                    newFilters.type = 'Degree';
                    flag = true;
                }
                if (data.cgpa && data.cgpa > 15 && data.cgpa !== filters.cgpa) {
                    newFilters.scale = 100;
                    flag = true;
                }
                if (data.countries && data.countries.length != interestCountries.length) {
                    setInterestCountries(data.countries);
                    if (data.interest_regions) {
                        let newRegions = data.interest_regions.reduce((acc, item) => {
                            if (item.country && data.countries.includes(trim(item.country))) {
                                acc.push(item);
                                return acc;
                            } else return acc;
                        }, []);
                        newFilters.interest_regions = newRegions;
                    }
                    flag = true;
                }

                if (flag) setFilters(newFilters);
                else setFilters(data);
                filterFormErrors = errors;
            }
        }, 500);
    };

    const clearFilters = function () {
        setGoBack(false);
        setFilters({});
        dispatch(
            goToRoute(
                routePaths.COURSE_FINDER,
                {
                    path: courseFinderPaths.VIEW,
                },
                {
                    page: 1,
                    per_page: 10,
                    client_type: 'Client',
                },
            ),
        );
        setShowFormErrors(false);
    };

    const applyFilters = function () {
        setShowFormErrors(true);
        if (!(filterFormErrors && filterFormErrors.length > 0)) {
            dispatch(setQueries(getCourseFinderQueries(filters, searchText, id, 10, 1)));
        }
    };

    return (
        <>
            <Box ml={2.5} mt={2} mb={1} className={classes.titleContainer}>
                {getIcon('filter-list', 'inherit', 'small')}
                <Typography className={classes.pageTitle} variant="h5">
                    Filters
                </Typography>
            </Box>
            <Box className={classes.buttonsContainer}>
                <Button
                    variant="text"
                    color="primary"
                    onClick={clearFilters}
                    fullWidth
                    disabled={loading}
                    label="Clear all"
                    sx={{ borderRadius: 1 }}
                />

                <Button
                    variant="contained"
                    color="success"
                    onClick={applyFilters}
                    fullWidth
                    disabled={loading}
                    label="Apply Filters"
                    className={classes.applyButton}
                />
            </Box>
            <Box px={2}>
                {loading ? (
                    <Box display="flex" alignItem="center" justifyContent="center" width="100%">
                        <Loader />
                    </Box>
                ) : (
                    <>
                        <StudentSelect
                            path={path}
                            profileData={profileData}
                            loading={loading}
                            filters={filters}
                            searchText={searchText}
                            setGoBack={setGoBack}
                            page={page}
                            perPage={perPage}
                            goBack={goBack}
                            studentId={id}
                        />
                        <FormComponent
                            schema={schema}
                            uischema={uiSchema}
                            data={filters}
                            onChange={({ data, errors }) => {
                                changeFilters(data, errors);
                            }}
                            showFormErrors={showFormErrors}
                        />
                    </>
                )}
            </Box>
        </>
    );
}

ProgramSearchFilters.propTypes = {
    id: PropTypes.string,
    path: PropTypes.string,
    filters: PropTypes.object,
    setLoading: PropTypes.func,
    setData: PropTypes.func,
    setPage: PropTypes.func,
    setPerPage: PropTypes.func,
    setTotalRows: PropTypes.func,
    setSearchText: PropTypes.string,
    setFilters: PropTypes.func,
    globalData: PropTypes.object,
    loading: PropTypes.bool,
    profileData: PropTypes.object,
    setGoBack: PropTypes.func,
    page: PropTypes.number,
    perPage: PropTypes.number,
    goBack: PropTypes.bool,
};
