import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import StudentJourney from './StudentJourney';
import { useSelector } from 'react-redux';
import Loader from '@/components/shared/Loader';

export default function CreateStudent({ match }) {
    const globalData = useSelector((state) => state.globalData);
    if (globalData.loading) return <Loader />;
    return (
        <Box>
            <StudentJourney match={match} />
        </Box>
    );
}

CreateStudent.propTypes = {
    match: PropTypes.object,
};
CreateStudent.defaultProps = {};
