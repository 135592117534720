import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mapValues, keyBy, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

// api
import { getProfiles } from '@/api';

// material
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

// comps
import { FormComponent } from 'azent-storybook/dist';

// actions
import { goToRoute } from '@/store/actions/navigationActions';

// utils
import routePaths from '@/utils/route-paths';
import { getCourseFinderQueries, getStudentDefaultFilters } from '@/utils/courseFinder';

export const schema = {
    type: 'object',
    properties: {
        student: {
            type: 'object',
            properties: {
                _id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                },
            },
        },
    },
};

export const uiSchema = {
    type: 'VerticalLayout',
    elements: [
        {
            type: 'HorizontalLayout',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/student',
                    label: 'Student',
                    options: {
                        component: 'search',
                        searchApi: getProfiles,
                        searchApiArgs: [{ q: '{{search}}' }],
                        valueKey: '_id',
                        labelExp: '{{first_name}} {{last_name}}, {{mobile}}',
                    },
                },
            ],
        },
    ],
};

let timeout = '';
export default function StudentSelect({
    path,
    profileData,
    loading,
    filters,
    searchText,
    setGoBack,
    perPage,
    page,
    goBack,
    studentId,
}) {
    let profile = profileData;
    if (!profile) {
        profile = {};
    }
    const [data, setData] = useState({
        student: profileData,
        ...getStudentDefaultFilters(profile),
    });

    const dispatch = useDispatch();
    const location = useSelector((state) => state.router.location);
    const searchParams = new URLSearchParams(location.search);

    useEffect(() => {
        setData({
            student: profileData,
            ...getStudentDefaultFilters(profile),
        });
    }, [profileData]);

    const changeFilters = (data) => {
        if (timeout) {
            clearTimeout(timeout);
        }

        if ((data.student && data.student._id) != (profileData && profileData._id)) {
            setGoBack(false);
        }

        if (!data.student) {
            if (data.student === null) {
                timeout = setTimeout(() => {
                    dispatch(
                        goToRoute(
                            routePaths.COURSE_FINDER,
                            {
                                path,
                            },
                            {
                                page: 1,
                                per_page: perPage ? perPage : '',
                            },
                        ),
                    );
                }, [500]);
            } else {
                timeout = setTimeout(() => {
                    let query = {};
                    let pathParams = { path };
                    if (!isEmpty(filters)) {
                        query = {
                            page: page ? page : '',
                            per_page: perPage ? perPage : '',
                            ...mapValues(
                                keyBy(getCourseFinderQueries(filters, searchText), 'key'),
                                'value',
                            ),
                        };
                    } else {
                        query = { ...Object.fromEntries(searchParams) };
                    }
                    if (studentId) {
                        pathParams.id = studentId;
                    }
                    dispatch(goToRoute(routePaths.COURSE_FINDER, pathParams, query));
                }, [500]);
            }
        } else {
            timeout = setTimeout(() => {
                if ((data.student && data.student._id) != (profileData && profileData._id)) {
                    dispatch(
                        goToRoute(
                            routePaths.COURSE_FINDER,
                            {
                                path,
                                id: data.student._id,
                            },
                            {
                                page: page ? page : '',
                                per_page: perPage ? perPage : '',
                                ...mapValues(
                                    keyBy(
                                        getCourseFinderQueries(
                                            getStudentDefaultFilters(data.student),
                                            searchText,
                                            data.student._id,
                                        ),
                                        'key',
                                    ),
                                    'value',
                                ),
                            },
                        ),
                    );
                } else {
                    let query = {};
                    if (!isEmpty(filters)) {
                        query = {
                            page: page ? page : '',
                            per_page: perPage ? perPage : '',
                            ...mapValues(
                                keyBy(
                                    getCourseFinderQueries(filters, searchText, data.student._id),
                                    'key',
                                ),
                                'value',
                            ),
                        };
                    } else {
                        query = { ...Object.fromEntries(searchParams) };
                    }
                    if (goBack) query.goBack = 'true';
                    dispatch(
                        goToRoute(
                            routePaths.COURSE_FINDER,
                            {
                                path,
                                id: data.student._id,
                            },
                            query,
                        ),
                    );
                }
            }, [500]);
        }
    };

    return (
        <Box width="100%">
            {loading ? (
                <>
                    <Skeleton height="12px" width="32px" />
                    <Skeleton sx={{ borderRadius: 0.7 }} height="60px" width="100%" />
                </>
            ) : (
                <FormComponent
                    schema={schema}
                    uischema={uiSchema}
                    data={data}
                    onChange={({ data, errors }) => {
                        changeFilters(data, errors);
                    }}
                />
            )}
        </Box>
    );
}

StudentSelect.propTypes = {
    profileData: PropTypes.object,
    path: PropTypes.string,
    loading: PropTypes.bool,
    filters: PropTypes.object,
    searchText: PropTypes.string,
    setGoBack: PropTypes.func,
    studentDefaultFilters: PropTypes.object,
    perPage: PropTypes.number,
    page: PropTypes.number,
    goBack: PropTypes.bool,
    studentId: PropTypes.any,
};

StudentSelect.defaultProps = {
    filters: {},
    searchText: '',
};
