//storybook
import { getIcon } from 'azent-storybook/dist';

const largeScreenUiSchema = (config, have_password) => {
    const uiPartnerSchema = {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/new_password',
                        label: 'New Password*',
                        options: {
                            pattern: 'password',
                            type: config.showNewPassword ? 'text' : 'password',
                            endIcon: config.showNewPassword
                                ? () => getIcon('Offeye', 'inherit', 'small')
                                : () => getIcon('Eye', 'inherit', 'small'),
                            onEndIconClick: config.onChangeNewPasswordVisibility,
                            InputProps: {
                                placeholder: have_password ? '********' : '',
                            },
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/confirm_password',
                        label: 'Confirm Password*',
                        options: {
                            pattern: 'password',
                            type: config.showConfirmPassword ? 'text' : 'password',
                            endIcon: config.showConfirmPassword
                                ? () => getIcon('Offeye', 'inherit', 'small')
                                : () => getIcon('Eye', 'inherit', 'small'),
                            onEndIconClick: config.onChangeConfirmPasswordVisibility,
                            InputProps: {
                                onCopy: (e) => e.preventDefault(),
                                onCut: (e) => e.preventDefault(),
                                onPaste: (e) => e.preventDefault(),
                                placeholder: have_password ? '********' : '',
                            },
                        },
                    },
                ],
            },
        ],
    };

    return uiPartnerSchema;
};

const smallScreenUiSchema = {};

export default function (config, have_password) {
    return {
        smallScreenUiSchema: smallScreenUiSchema,
        largeScreenUiSchema: largeScreenUiSchema(config, have_password),
    };
}
