import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    loaderContainer: {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    layoutContainer: {
        width: '100vw',
    },
    footerContainer: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
    },
}));
