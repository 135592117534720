//Constants
import { INITIALIZE_SERVICES } from "../actions/servicesAction";


const initialState = {loding:true,data:[]};

const servicesReducer = (state = initialState,action) => {
  if(action.type == INITIALIZE_SERVICES){
    return{
        data:action.payload,
        loading:false,
    }
  }else{
    return state;
  }
}

export default servicesReducer