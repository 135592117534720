import JSZip from "jszip";

const downloadFile = (urlToSend, name) => {
    const req = new XMLHttpRequest();
    req.open("GET", urlToSend, true);
    req.responseType = "blob";
    req.onload = function () {
        const blob = req.response;
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
    };
    req.send();
};
export { downloadFile };

const downloadMultipleFiles = (filesArray, item) => {
    const zip = new JSZip();

    const promises = filesArray.map(async (fileInfo) => {
        const { url, original_file_name } = fileInfo;
        const response = await fetch(url);
        const blob = await response.blob();
        zip.file(original_file_name, blob);
    });

    Promise.all(promises).then(() => {
        zip.generateAsync({ type: "blob" }).then((content) => {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(content);
            link.download = `${item}.zip`;
            link.click();
        });
    });
};

export { downloadMultipleFiles };
