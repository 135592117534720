import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    customTab: {
        fontSize: theme.spacing(1.75),
        fontWeight: 500,

        alignItems: 'flex-start',
        '&:active': {
            color: theme.palette.text.black,
        },
        '&.Mui-selected': {
            color: theme.palette.text.black,
        },
    },
    emailButton: {
        fontSize: '14px',
        fontWeight: '600',
        borderRadius: '10px',
        color: 'white',
        lineHeight: '1.75',
        fontFamily: 'Montserrat',
        marginLeft: '10px',
        padding: '10px 0px',
        width: '220px',
        backgroundColor: '#00c853',
        '&:hover': {
            backgroundColor: '#3D8944',
        },
    },
    downloadButton: {
        fontSize: '14px',
        fontWeight: '600',
        borderRadius: '10px',
        lineHeight: '1.75',
        fontFamily: 'Montserrat',
        padding: '10px 0px',
        width: '220px',
    },
    emailButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        zIndex: '99',
        position: 'absolute',
        top: '105px',
        right: '18px',
    },
    customButton: {
        fontSize: '14px',
        fontWeight: '600',
        borderRadius: '10px',
        padding: '9px 20px',
        '&:hover': {
            backgroundColor: '#3D8944',
            color: 'white',
            border: '0px',
        },
    },
}));
