import { createAction } from '@reduxjs/toolkit';

//api
import { fetchGlobalData } from '@/api';

export const updateGlobalData = createAction('SET_GLOBAL_DATA', (data) => ({
    payload: data,
}));

export const globalDataGetAndStore = (successCallback) => {
    return (dispatch) => {
        fetchGlobalData()
            .then((res) => {
                if (res.data) {
                    if (res.data.nationality_options && res.data.nationality_options.length) {
                        res.data.nationality_options = res.data.nationality_options.map((i) => {
                            return { name: i };
                        });
                    }
                    dispatch(updateGlobalData(res.data));
                    successCallback();
                }
            })
            .catch(() => {});
    };
};
