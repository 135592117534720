import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    commentSectionContainer: {
        minHeight: theme.spacing(67),
        paddingLeft: theme.spacing(1.5),
    },
    commentSection: {
        maxHeight: theme.spacing(45),
        overflow: 'scroll',
        minHeight: theme.spacing(45),
        paddingRight: '5px',
        scrollBehavior: 'smooth',
        '&::-webkit-scrollbar': {
            width: 7,
            height: 10,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
            marginTop: 10,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#E2E2E2',
            borderRadius: 5,
        },
    },
    commentCard: {
        padding: theme.spacing(1.25, 1.5),
        margin: theme.spacing(0.2, 0),
        borderRadius: theme.spacing(1),
        background: '#F5F6F7',
    },
    img: {
        cursor: 'pointer',
        borderRadius: theme.spacing(2),
        height: theme.spacing(3),
        width: theme.spacing(3),
    },
    fileIcon: {
        cursor: 'pointer',
        color: theme.palette.common.white,
        height: theme.spacing(2),
        width: theme.spacing(2),
    },
    fileIconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        padding: theme.spacing(1),
        background: theme.palette.secondary.light,
        height: theme.spacing(3),
        width: theme.spacing(3),
    },
    copyIcon: {
        color: theme.palette.secondary.main,
        fontSize: '18px',
    },
    toggleText: {
        color: theme.palette.secondary.main,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    attachmentButton: {
        backgroundColor: 'transparent',
        width: theme.spacing(5),
        borderRadius: '50%',
        padding: theme.spacing(0.75),
        '&:hover': {
            cursor: 'pointer',
        },
    },
    attachmentIcon: {
        transform: 'rotate(20deg)',
        color: theme.palette.neutral.dark,
    },
    commentText: {
        fontFamily: 'Montserrat',
        fontSize: '12px',
        fontWeight: '550',
        minHeight: theme.spacing(2.5),
    },
    timeText: {
        color: '#6D6D6D',
        textAlign: 'right',
        fontSize: '12px',
        fontWeight: '500',
    },
    showMoreLink: {
        color: '#78A5FF',
        fontSize: '11px',
        fontWeight: 600,
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    },
    privateCommentChip: {
        padding: '1px 4px',
        borderRadius: '4px',
        textAlign: 'center',
        backgroundColor: '#5AC461',
        width: '51px',
        marginLeft: '12px',
        marginBottom: 'auto',
    },
    titlePrivateComment: {
        fontSize: '10px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#F2F2F2',
        whiteSpace: 'pre-wrap !important',
    },
    privateCommentContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        zIndex: '99',
        position: 'relative',
        top: '12px',
        right: '0px',
        height: '0px',
        color: '#8C8C8C',
        fontSize: '14px',
    },
    enablePrivateTitle: {
        '& .MuiFormControlLabel-label': {
            fontSize: '13px',
        },
    },
    enablePrivateCheckBox: {
        color: '#8C8C8C',
        '& .MuiSvgIcon-root': { fontSize: 16 },
    },
}));
