import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    ListItemLoading: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
    },
    ListItem:{
        paddingTop: theme.spacing(0),
    }
}));
