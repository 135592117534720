const labelKeyMap = {
    internal: 'Team AdmissionBox',
    partner: 'Referral Partner',
};

export const modifiedByList = Object.keys(labelKeyMap);

export const getModifiedByLabel = (key) => {
    return labelKeyMap[key] ? labelKeyMap[key] : '';
};
