import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

//mui
import { Paper, Box, Grid, Typography, Stack } from '@mui/material';

// navigation
import { goToRoute } from '@/store/actions/navigationActions';
import routePaths, { studentPaths } from '@/utils/route-paths';

// utils
import { get } from 'lodash';

// components
import { getIcon, ColorCard, Button } from 'azent-storybook/dist';

//prop-constants
import {
    getTitleClass,
    getContainerClass,
    getLabelForApplicationStatus,
} from '@/props-constants/application/application-status';
import { getAppPriorityIconStyle } from '@/props-constants/application/application-profile';

export default function ApplicationCard({ classes, data }) {
    const dispatch = useDispatch();

    const AppStatus = data.application_status.current_status;
    const priority = get(data, 'preference', '');

    const onCardClick = () => {
        dispatch(
            goToRoute(routePaths.STUDENT_PROFILE_VIEW, {
                id: data.profile_id,
                aid: data._id,
                path: studentPaths.APPLICATION,
            }),
        );
    };

    return (
        <Box className={classes.cardContainer} onClick={onCardClick} mb={2}>
            <Paper variant={'outlined'} className={classes.paperClass}>
                {/* <Typography variant="subtitle1" color="secondary">
                            {`Application Id: ${get(data, '_id', '-')}`}
            </Typography> */}

                <Grid container mt={2}>
                    <Grid item xs={1}>
                        <Box className={classes.iconContainer}>
                            <Typography varint="h6">
                                {getIcon('account-balance', 'primary', 'small', classes.iconTitle)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={7} pr={1}>
                        <Stack direction={'row'}>
                            <Typography
                                variant="body2"
                                sx={{ color: '#78A5FF', fontWeight: '500' }}>
                                {`${get(data, 'program_info.university_name', '-')}`}
                            </Typography>
                            <Typography variant="h6" ml={1}>
                                {priority.length
                                    ? getIcon(
                                          'fire',
                                          'primary',
                                          'small',
                                          getAppPriorityIconStyle(priority, classes),
                                      )
                                    : ''}
                                {priority}
                            </Typography>
                        </Stack>

                        <Typography variant="body2" sx={{ fontWeight: '500' }}>
                            {`${get(data, 'program_info.name', '-')}`}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        sx={{
                            display: 'grid',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                        }}>
                        <ColorCard
                            title={`${getLabelForApplicationStatus(AppStatus)}`}
                            classes={{
                                container: getContainerClass(classes, AppStatus),
                                title: getTitleClass(classes, AppStatus),
                            }}></ColorCard>
                    </Grid>
                </Grid>

                <Grid container pt={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3} pr={0.3} sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography mr={1} variant="h6" sx={{ color: '#6D6D6D' }}>
                            {`Created on:`}
                        </Typography>
                        <Typography variant="h6">
                            {moment(data.created_at).format('DD/MM/YYYY')}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}>
                        <Typography mr={1} ml={2} variant="h6" sx={{ color: '#6D6D6D' }}>
                            {`Intake: `}
                        </Typography>
                        <Typography variant="h6">
                            {get(data, 'intake.month', '-')}
                            {' - '}
                            {get(data, 'intake.year', '-')}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}>
                        <Button
                            variant={'text'}
                            size={'large'}
                            endIcon={getIcon('next-arrow', 'inherit', 'small')}
                            label={'View in Detail'}
                            className={classes.viewButton}></Button>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

ApplicationCard.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
};
