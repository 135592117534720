import { has } from 'lodash';
import {
    applicationStatus,
    applicationStatusLabelMapping,
} from '@/props-constants/application/application-status';
import { STUDENT_STATUS_CHOICES } from '@/props-constants/student/student-profile';

export const getStatusList = (status = {}, currentStatus) => {
    let data = Object.keys(currentStatus).reduce(
        (acc, curr) => {
            if (has(status, curr)) {
                acc[curr] = status[curr];
            }
            return acc;
        },
        { ...currentStatus },
    );
    return data;
};

export const defaultApplicationStatuses = applicationStatus.reduce((acc, curr) => {
    return { ...acc, [curr]: 0 };
}, {});

export const defaultStudentStatuses = STUDENT_STATUS_CHOICES.reduce((acc, curr) => {
    if (curr == 'application') return acc;
    return { ...acc, [curr]: 0 };
}, {});

export const studentStatusLabelMapping = {
    profile_received: 'Profile Received',
    profile_assessment: 'Profile Assessment',
    shortlisting: 'Shortlisting',
    shortlisting_required: 'Shortlisting Required',
    closed: 'Closed',
    application: 'Application',
};

export const statusLabelMapping = {
    ...studentStatusLabelMapping,
    ...applicationStatusLabelMapping,
};
