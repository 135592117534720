import moment from 'moment';

// utils
import {
    getLabelForApplicationStatus,
    getPaymentLink,
} from '@/props-constants/application/application-status';

export const getReferenceDocs = (history) => {
    let referenceDocuments = [];

    if (history && history.length)
        history.forEach((item) => {
            if (item.file) {
                referenceDocuments.push({
                    ...item.file,
                    name:
                        getLabelForApplicationStatus(item.file.original_file_name) ||
                        item.file.original_file_name,
                    createdBy: item.created_by__name,
                    createdAt: item.created_at,
                });
            }
        });
    return referenceDocuments;
};

export const getCommentDocs = (comments) => {
    let sample = [];
    if (comments) {
        sample = comments
            .map((comment) => {
                return comment.files.map((file) => {
                    return {
                        file: file,
                        createdBy: comment.created_by__name,
                        createdAt: comment.created_at,
                    };
                });
            })
            .flat()
            .filter((item) => !!item);
    }
    return sample;
};

export const getHistoryProps = (history, application_payment_link = null) => {
    if (history && history.length > 0) {
        let historyData = [...history];
        historyData = historyData.sort((a, b) =>
            moment(a.date).isBefore(moment(b.date)) ? 1 : -1,
        );
        return historyData.map((item) => ({
            link: getPaymentLink(item.status, application_payment_link),
            label: getLabelForApplicationStatus(item.status),
            description: `On: ${moment.utc(item.date).local().format('DD/MM/YYYY HH:mm')}`,
            name: `By: ${item.created_by__name ? item.created_by__name : item.created_by}`,
        }));
    } else return [];
};

export const getAppPriorityIconStyle = (priority, classes) => {
    switch (priority) {
        case 'P1':
            return classes.priorityIconP1;
        case 'P2':
            return classes.priorityIconP2;
        case 'P3':
            return classes.priorityIconP3;
        case 'P4':
            return classes.priorityIconP4;
        case 'P5':
            return classes.priorityIconP5;
        default:
            return;
    }
};
