import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    refreshData: false,
    displayTime: null,
};

export const refreshSlice = createSlice({
    name: 'refreshPlatformData',
    initialState,
    reducers: {
        setRefreshData: (state, { payload }) => {
            state.refreshData = payload;
        },
        setDisplayTime: (state, { payload }) => {
            state.displayTime = payload;
        },
    },
});

export const { setRefreshData, setDisplayTime } = refreshSlice.actions;

export default refreshSlice.reducer;
