import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.primary.main,
        zIndex: '3',
        position: 'absolute',
    },
    loaderImage: {
        height: '40px',
        width: '40px',
    },
}));
