import React, { useState, useEffect } from 'react';

//deserializer
import { deserializeServiceCard } from '@/utils/deserializers/servicecard-deserializer';
import { deserializeStudent } from '@/utils/deserializers/student-deserializer';
import { deserializeAncillaryService } from '@/utils/deserializers/ancillary-service-deserializer';

//mui
import { Box, Grid, Skeleton, useMediaQuery } from '@mui/material';

//store
import { useSelector, useDispatch } from 'react-redux';
import { initializeServiceCard } from '@/store/actions/serviceCmsAction';
import { addStudentToStore } from '@/store/slices/studentSlice';

//Components
import Description from './partials/description';
import Faqs from './partials/faqs';
import Header from './partials/header';
import { Tabs } from './partials/Tabs/Tabs';
import { FormComponent } from 'azent-storybook/dist';

//api
import { fetchServiceFromCms } from '@/api/cms';
import { getProfile } from '@/api';
import { getServices } from '@/api/ancillary';

//routes
import { setQueries } from '@/store/actions/navigationActions';

//schema
import { schema, uiSchema } from '@/components/shared/StudentSelect';

//utils
import { isEmpty } from 'lodash';
import { useStyles } from './style';

let timeout = null;

export default function index({ match }) {
    const isMobile = useMediaQuery('(max-width:600px)');
    const classes = useStyles();
    const [formData, setFormData] = useState({});
    const [value, setValue] = useState(0);
    const [serviceCard, setServiceCard] = useState(null);
    const [profileLoading, setProfileLoading] = useState(false);
    const [isEnquireEnabled, setIsEnquireEnabled] = useState(false);
    const [isStudentSelected, setIsStudentSelected] = useState(false);
    const [message, setMessage] = useState(false);
    const serviceId = match.params.id;
    const dispatch = useDispatch();
    const searchParams = useSelector((state) => state.router.location.search);
    const serviceCards = useSelector((state) => state.serviceCards);
    const search = new URLSearchParams(searchParams);
    let studentId = search.get('_id');
    let profile = useSelector((state) => state.student.data[search.get('_id')]);
    let Label = serviceCard && serviceCard.enquire && serviceCard.enquire.label;
    useEffect(() => {
        setIsStudentSelected(studentId ? true : false);
    }, [studentId]);

    useEffect(() => {
        const student = {};
        if (serviceId) {
            getService(studentId);
        }
        const formData = Object.fromEntries(search);
        if (formData._id) {
            student._id = formData._id;
        }
        if (formData.first_name) {
            student.first_name = formData.first_name;
        }
        if (formData.last_name) {
            student.last_name = formData.last_name;
        }
        if (formData.mobile) {
            student.mobile = formData.mobile;
        }
        setFormData({ student });
        if (studentId && !profile) {
            setProfileLoading(true);
            getProfile(studentId).then((res) => {
                dispatch(addStudentToStore(deserializeStudent(res.data)));
                setProfileLoading(false);
            });
        }
    }, [searchParams]);

    const selectStudent = (data) => {
        if (data.student) {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                const queries = [];
                if (data.student._id) {
                    queries.push({
                        key: '_id',
                        value: data.student._id,
                    });
                }
                if (data.student.mobile) {
                    queries.push({
                        key: 'mobile',
                        value: data.student.mobile,
                    });
                }
                if (data.student.first_name) {
                    queries.push({
                        key: 'first_name',
                        value: data.student.first_name,
                    });
                }
                if (data.student.last_name) {
                    queries.push({
                        key: 'last_name',
                        value: data.student.last_name,
                    });
                }
                dispatch(setQueries(queries));
                setMessage(false);
            }, [500]);
        } else {
            dispatch(setQueries([]));
            setIsEnquireEnabled(false);
        }
    };

    function getServiceCard() {
        serviceId &&
            fetchServiceFromCms(serviceId).then((res) => {
                const serviceCardList = [
                    ...serviceCards.data,
                    deserializeServiceCard(
                        res.data && res.data.data && res.data.data.attributes,
                        res.data && res.data.data && res.data.data.id,
                    ),
                ];
                dispatch(initializeServiceCard(serviceCardList));
            });
    }

    function getService(studentId) {
        studentId &&
            getServices(studentId).then((res) => {
                const serviceList = res.data.subscribed_services;
                const deserializeServiceList = deserializeAncillaryService(serviceList);
                if (!isEmpty(deserializeServiceList)) {
                    deserializeServiceList[serviceId]
                        ? setIsEnquireEnabled(deserializeServiceList[serviceId].isEnquireEnabled)
                        : setIsEnquireEnabled(true);
                } else {
                    setIsEnquireEnabled(true);
                }
            });
    }

    useEffect(() => {
        if (serviceCards && serviceCards.data && serviceCards.data.length) {
            const filteredCard = serviceCards.data.filter(
                (serviceCard) => serviceCard.id == serviceId,
            );
            if (filteredCard.length) {
                setServiceCard(filteredCard[0]);
            } else {
                getServiceCard();
            }
        } else {
            getServiceCard();
        }
    }, [serviceCards.data]);

    return (
        <>
            <Grid align="center" style={{ margin: isMobile ? '16px' : '20px' }}>
                <Box mt={3} mb={3} width="100%">
                    {profileLoading ? (
                        <>
                            <Skeleton height="12px" width="32px" />
                            <Skeleton className={classes.Loading} />
                        </>
                    ) : (
                        <>
                            <FormComponent
                                schema={schema}
                                uischema={uiSchema}
                                data={formData}
                                onChange={({ data, errors }) => {
                                    selectStudent(data, errors);
                                }}
                            />
                            {!studentId && message && (
                                <Box sx={{ textAlign: 'left', fontSize: 'small' }} color={'red'}>
                                    Please select the student
                                </Box>
                            )}
                        </>
                    )}
                </Box>
                <Header
                    {...serviceCard}
                    serviceId={serviceId}
                    isEnquireEnabled={isEnquireEnabled}
                    BackButton={true}
                    studentId={studentId}
                    setIsEnquireEnabled={setIsEnquireEnabled}
                    enquireLabel={Label}
                    isStudentSelected={isStudentSelected}
                    setMessage={setMessage}
                />
                <Tabs
                    tabList={[
                        {
                            label: 'Description',
                            content: () => {
                                return <Description {...serviceCard} />;
                            },
                        },
                        {
                            label: 'FAQs',
                            content: () => {
                                return <Faqs {...serviceCard} />;
                            },
                        },
                    ]}
                    orientation={`vertical`}
                    value={value}
                    setValue={setValue}
                    verticalGridSize={{
                        tab: 1,
                        content: 11,
                    }}
                />
            </Grid>
        </>
    );
}
