import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    editIcon: {
        fontSize: theme.spacing(1.5),
    },
    statusShimmer: {
        height: '30px',
        borderRadius: theme.spacing(1),
        width: '100%',
    },
    colorCardContainer: {
        padding: '4px 16px',
    },
    createAppButton: {
        fontSize: '14px',
        fontWeight: '600',
        borderRadius: '10px',
        color: 'white',
        lineHeight: '1.75',
        fontFamily: 'Montserrat',
        padding: '8px 20px',
        height: '50px',
        backgroundColor: '#00c853',
        '&:hover': {
            backgroundColor: '#3D8944',
        },
    },
    containerBlue: {
        padding: '4px 2px 4px 2px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#BED3FF',
        border: '1px solid #4571CE',
        width: '120px',
    },
    containerRed: {
        padding: '4px 2px 4px 2px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#FFCBCB',
        border: '1px solid #E08A8A',
        width: '120px',
    },
    containerGreen: {
        padding: '4px 2px 4px 2px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#9FE59F',
        border: '1px solid #4C984C',
        width: '120px',
    },
    containerLightGreen: {
        padding: '4px 2px 4px 2px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#D5F4D5',
        border: '1px solid #6CB76C',
        width: '120px',
    },
    containerYellow: {
        padding: '4px 2px 4px 2px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#FFE3C1',
        border: '1px solid #DE9059',
        width: '120px',
    },
    containerGrey: {
        padding: '4px 2px 4px 2px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#D4D4D4',
        border: '1px solid #A9A9A9',
        width: '120px',
    },
    titleBlue: {
        textAlign: 'center',
        fontFamily: 'Montserrat',
        color: '#4571CE',
        fontSize: '13px',
    },
    titleGreen: {
        textAlign: 'center',
        fontFamily: 'Montserrat',
        color: '#4C984C',
        fontSize: '13px',
    },
    titleRed: {
        textAlign: 'center',
        fontFamily: 'Montserrat',
        color: '#E08A8A',
        fontSize: '13px',
    },
    titleYellow: {
        textAlign: 'center',
        fontFamily: 'Montserrat',
        color: '#DE9059',
        fontSize: '13px',
    },
    titleGrey: {
        textAlign: 'center',
        fontFamily: 'Montserrat',
        color: '#A9A9A9',
        fontSize: '13px',
    },
    titleLightGreen: {
        textAlign: 'center',
        fontFamily: 'Montserrat',
        color: '#6CB76C',
        fontSize: '13px',
    },
    containerStudentBlue: {
        padding: '4px 2px 4px 2px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#E5EDFF',
        border: '1px solid #749BEB',
        width: '140px',
    },
    containerStudentRed: {
        padding: '4px 2px 4px 2px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#FFE2DCF0',
        border: '1px solid #E08A8A',
        width: '140px',
    },
    containerStudentGreen: {
        padding: '4px 2px 4px 2px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#E9FFE9',
        border: '1px solid #75D675',
        width: '140px',
    },
    containerStudentYellow: {
        padding: '4px 2px 4px 2px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#FFEBD3',
        border: '1px solid #F89B6D',
        width: '140px',
    },
    containerStudentPurple: {
        padding: '4px 2px 4px 2px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#E4DBFFF0',
        border: '1px solid #A288F1',
        width: '140px',
    },

    titleStudentBlue: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#749BEB',
        whiteSpace: 'pre-wrap !important',
    },
    titleStudentRed: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#E08A8A',
        whiteSpace: 'pre-wrap !important',
    },
    titleStudentGreen: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#75D675',
        whiteSpace: 'pre-wrap !important',
    },
    titleStudentYellow: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#F89B6D',
        whiteSpace: 'pre-wrap !important',
    },
    titleStudentPurple: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#A288F1',
        whiteSpace: 'pre-wrap !important',
    },
}));
