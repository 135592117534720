import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, isEmpty, difference } from 'lodash';

// actions
import { setQueries, goToRoute } from '@/store/actions/navigationActions';
import { setToast } from '@/store/slices/toastSlice';
import { setDisplayTime, setRefreshData } from '@/store/slices/refreshSlice';

// comps
import { Table } from 'azent-storybook/dist';
import { getRawColumns } from './partials/applicationColumns';

//module
import { CreateApplication } from '../CreateApplication';

//mui
import { Box } from '@mui/material';

// api
import { getApplications } from '@/api';

// utils
import RoutePaths, { studentPaths } from '@/utils/route-paths';
import { toastMessages } from '@/utils/toastMessages';
import localStorageActions from '@/utils/localStorage';

//prop-const
import { getFilterProps, filterMsg } from '@/props-constants/application/application-filter';
import {
    getApplicationListQueries,
    getFiltersFromSearchParams,
    convertFilterDataToUrlFormat,
} from '@/props-constants/application/list-application';
import { manageColumnsData } from '@/props-constants/student/list-student';

// styles
import { useStyles } from './style';

//rbac
import { isB2bAdmin } from '@/rbac';

let timeout = null;
let mounted = false;

export default function ListApplication() {
    const dispatch = useDispatch();
    const classes = useStyles();

    const location = useSelector((state) => state.router.location);
    const user = useSelector((state) => state.userData);
    const globalData = useSelector((state) => state.globalData.data);
    const refreshData = useSelector((state) => state.refreshPlatformData.refreshData);
    const visibleColumns = JSON.parse(
        localStorageActions.getDataFromLocalStorage('ApplicationColumns'),
    );

    const search = new URLSearchParams(location.search);
    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState([]);
    const [page, setPage] = React.useState(search.get('page') || 1);
    const [perPage, setPerPage] = React.useState(search.get('perPage') || 10);
    const [searchText, setSearchText] = React.useState(search.get('q') || '');
    const [totalPages, setTotalPages] = React.useState(0);
    const [isModalOpen, setModalOpen] = React.useState(false);
    const [isFilterFormOpen, setFilterFormOpen] = React.useState(false);
    const [filtersData, setFiltersData] = React.useState(getFiltersFromSearchParams(search));
    const [isBadgeVisible, setBadgeVisible] = React.useState(false);
    const [filtersDataMsg, setFiltersDataMsg] = React.useState(filterMsg.default);

    const userId = user.userData.id;
    {
        /* PHASE-2
    const classes = useStyles();
    const [selectedStudent, setSelectedStudent] = React.useState(null);
    const [selectedApplicationId, setSelectedApplicationId] =
        React.useState(null);
    const [drawerTabValue, setDrawerTabValue] = React.useState(0);
    const [selectedApplicationStatus, setSelectedApplicationStatus] =
    React.useState(null);
    const [profileId, setProfileId] = React.useState(null);
*/
    }

    const columns = useMemo(() => {
        if (user.loading) return [];
        return getRawColumns(user.userData);
    }, [user]);

    const allColumns = columns.map((col) => col.Header);
    const hiddenColumns = difference(allColumns, visibleColumns);

    const setQuery = function (pageChoice) {
        const query = getApplicationListQueries(filtersData, page, perPage, searchText, pageChoice);
        dispatch(setQueries(query));
        if (query.length > 3) {
            setBadgeVisible(true);
            setFiltersDataMsg(filterMsg.filters);
        }
    };

    const onRowClick = (_event, rowData) => {
        const id = get(rowData, '0', {}).value;
        const appId = get(rowData, '1', '').value;

        dispatch(
            goToRoute(RoutePaths.STUDENT_PROFILE_VIEW, {
                id: id,
                aid: appId,
                path: studentPaths.APPLICATION,
            }),
        );

        {
            /* Phase 2:
        const appStatus = get(rowData, '6', '').value;
        appId && setSelectedApplicationId(appId);
        appStatus && setSelectedApplicationStatus(appStatus);
        if (id) {
            setProfileId(id);
            getProfile(id)
                .then((res) => {
                    setSelectedStudent(deserializeStudent(res.data));
                })
                .catch((err) => console.log(err));
        } */
        }
    };

    {
        /* const handleDrawerClose = () => {
        setSelectedStudent(null);
    };

    const { studentDrawerProps } = useMemo(
        () => getListStudentProps(selectedStudent, selectedApplicationId),
        [selectedStudent, selectedApplicationId],
    ); */
    }

    //close create form modal
    const handleClose = () => {
        setModalOpen(false);
        dispatch(goToRoute(RoutePaths.APPLICATION)); //page reload for latest Appl
    };

    const getApplicationsData = function (location, refreshData) {
        let search = new URLSearchParams(location);
        let searchObj = Object.fromEntries(search);
        if (!searchObj.per_page) searchObj.per_page = 10;
        setLoading(true);
        getApplications(searchObj)
            .then((response) => {
                let _data =
                    response.data && response.data.length
                        ? response.data.map((application) => ({
                              ...application,
                              rowLink: `${RoutePaths.STUDENT_INDEX}/${get(
                                  application,
                                  'profile_id',
                              )}${RoutePaths.APPLICATION}/${get(application, '_id')}`,
                          }))
                        : [];
                setData(_data);
                setPage(Number(response.headers.page || 1));
                setPerPage(Number(response.headers.per_page || 0));
                setSearchText(search.get('q') || '');
                setTotalPages(Number(response.headers.total_pages || 0));
                setLoading(false);
                dispatch(setDisplayTime(new Date()));
                if (refreshData) {
                    dispatch(setRefreshData(false));
                }
            })
            .catch(() => {
                dispatch(setToast(toastMessages.backendIssue));
                setLoading(false);
            });
    };

    const onChange = (formdata) => {
        !isEmpty(formdata.data) && setFiltersData(formdata.data);
    };

    const onSuccessCallback = () => {
        closeFilterForm();
    };

    const clearFilters = () => {
        setFiltersData({});
        setBadgeVisible(false);
        setFiltersDataMsg(filterMsg.default);
        dispatch(
            setQueries([
                { key: 'page', value: 1 },
                { key: 'per_page', value: 10 },
                { key: 'q', value: searchText },
            ]),
        );
        localStorageActions.deleteDataFromLocalStorage('applicationFilter');
    };

    const closeFilterForm = () => {
        setFilterFormOpen(false);
    };

    const onApplyFilters = () => {
        setFilterFormOpen(false);
        setQuery(1);
        localStorageActions.setDataInLocalStorage(
            'applicationFilter',
            convertFilterDataToUrlFormat(filtersData),
        );
    };

    const filterProps = useMemo(() => {
        return {
            ...getFilterProps(isB2bAdmin(), onApplyFilters, globalData, filtersData, userId),
            onSuccessCallback: onSuccessCallback,
            clearFilters: clearFilters,
            onChange: onChange,
            closeFilterForm: closeFilterForm,
            setFilterFormOpen: setFilterFormOpen,
            isFilterFormOpen: isFilterFormOpen,
            data: filtersData,
            isBadgeVisible: isBadgeVisible,
        };
    }, [isFilterFormOpen, filtersData, isBadgeVisible]);

    React.useEffect(() => {
        setTimeout(() => {
            mounted = true;
        });
    }, []);

    React.useEffect(() => {
        if (mounted) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            timeout = setTimeout(() => {
                if (page > 1) {
                    setQuery(1);
                } else {
                    setQuery();
                }
            }, 1000);
        }
    }, [searchText]);

    React.useEffect(() => {
        if (mounted) {
            setQuery();
        }
    }, [page, perPage]);

    React.useEffect(() => {
        getApplicationsData(location.search);
    }, [location.search]);

    React.useEffect(() => {
        if (refreshData) {
            getApplicationsData(location.search, refreshData);
        }
    }, [refreshData]);

    return (
        <Box>
            {/* PHASE-2: <StudentDrawer
                open={Boolean(selectedStudent)}
                onClose={handleDrawerClose}
                {...studentDrawerProps}
                tabs={{
                    value: drawerTabValue,
                    setValue: setDrawerTabValue,
                    ...studentDrawerProps.tabs,
                }}
                applicationDrawerProps={
                    selectedApplicationStatus
                        ? applicationDrawerProps(
                              selectedApplicationStatus,
                              profileId,
                              classes,
                              dispatch,
                          )
                        : null
                }
            /> */}

            <Table
                title={'Applications'}
                onSelectColumns={manageColumnsData}
                config={{
                    columns,
                    data,
                    initialState: {
                        hiddenColumns:
                            visibleColumns == null
                                ? columns
                                      .filter((col) => col.showInit == false)
                                      .map((col) => col.Header)
                                : hiddenColumns,
                    },
                    filter: {
                        setFilterText: setSearchText,
                        filterText: searchText,
                    },
                    pagination: {
                        perPageOptions: [5, 10, 15, 20, 50],
                        perPage,
                        setPerPage,
                        page,
                        setPage,
                        totalPages,
                    },
                    actionButton: {
                        label: 'Create Application',
                        variant: 'contained',
                        onClick: () => setModalOpen(true),
                        className: classes.createAppButton,
                    },
                }}
                noDataMessage={filtersDataMsg}
                isCustomFilter={true}
                loading={loading}
                setSelected={onRowClick}
                filterProps={filterProps}></Table>
            <CreateApplication handleClose={handleClose} isModalOpen={isModalOpen} />
        </Box>
    );
}
