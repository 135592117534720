import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { difference, get, isEmpty } from 'lodash';

// actions
import { setQueries, goToRoute } from '@/store/actions/navigationActions';
import { addStudentToStore } from '@/store/slices/studentSlice';
import { setToast } from '@/store/slices/toastSlice';
import { setRefreshData, setDisplayTime } from '@/store/slices/refreshSlice';
import { getPartnerData } from '@/store/actions/partnerActions';

// comps
import { StudentDrawer, Table } from 'azent-storybook/dist';
import { getRawColumns } from './partials/studentColumns';

// utils
import routePaths, { studentPaths } from '@/utils/route-paths';
import {
    getListStudentProps,
    filterMsg,
    getFiltersFromSearchParams,
    getStudentListQueries,
    convertFilterDataToUrlFormat,
    manageColumnsData,
} from '@/props-constants/student/list-student';
import { toastMessages } from '@/utils/toastMessages';
import {
    getFilterSchema,
    getFilterUiSchema,
} from '@/props-constants/student/list-student/student-index-filters-schema';
import { isB2bAdmin, isSubAgent } from '@/rbac';
import localStorageActions from '@/utils/localStorage';

// api
import { getProfile, getProfiles, getPartnerProfile } from '@/api';

// deserializer
import { deserializeStudent } from '@/utils/deserializers/student-deserializer';
import { deserializePartner } from '@/utils/deserializers/onboarding-deserializer';

//styles
import { useStyles } from './styles';

let timeout = null;
let mounted = false;

export default function ListStudent() {
    const dispatch = useDispatch();
    const classes = useStyles();

    const location = useSelector((state) => state.router.location);
    const user = useSelector((state) => state.userData);
    const studentsList = useSelector((state) => state.student.data);
    const globalData = useSelector((state) => state.globalData.data);
    const refreshData = useSelector((state) => state.refreshPlatformData.refreshData);
    const visibleColumns = JSON.parse(
        localStorageActions.getDataFromLocalStorage('StudentColumns'),
    );
    let search = new URLSearchParams(location.search);
    const [data, setData] = React.useState([]);
    const [selectedStudent, setSelectedStudent] = React.useState(null);
    const [page, setPage] = React.useState(search.get('page') || 1);
    const [perPage, setPerPage] = React.useState(search.get('perPage') || 10);
    const [searchText, setSearchText] = React.useState(search.get('q' || ''));
    const [loading, setLoading] = React.useState(true);
    const [totalPages, setTotalPages] = React.useState(0);
    const [drawerTabValue, setDrawerTabValue] = React.useState(0);
    const [stateHandler, setStateHandler] = React.useState([]);
    const [showFilterBox, setShowFilterBox] = React.useState(false);
    const [isBadgeVisible, setBadgeVisible] = React.useState(false);
    const [filtersDataMsg, setFiltersDataMsg] = React.useState(filterMsg.default);
    const [filtersData, setFiltersData] = React.useState(getFiltersFromSearchParams(search));

    const subAgentPartnerId = user.userData.partner_id;
    const userId = user.userData.id;

    const columns = useMemo(() => {
        if (user.loading) return [];
        return getRawColumns(user.userData);
    }, [user]);

    const allColumns = columns.map((col) => col.Header);
    const hiddenColumns = difference(allColumns, visibleColumns);

    const setQuery = function (pageChoice) {
        const query = getStudentListQueries(filtersData, page, perPage, searchText, pageChoice);
        dispatch(setQueries(query));
        if (query.length > 3) {
            setBadgeVisible(true);
            setFiltersDataMsg(filterMsg.filters);
        }
    };

    const onRowClick = (_event, rowData) => {
        const id = get(rowData, '13', {}).value;
        if (id) {
            const cachedStudent = get(studentsList, id, {});
            if (!isEmpty(cachedStudent)) {
                setSelectedStudent(cachedStudent);
            } else {
                getProfile(id)
                    .then((res) => {
                        setSelectedStudent(deserializeStudent(res.data));
                        dispatch(addStudentToStore(deserializeStudent(res.data)));
                    })
                    .catch(() => {
                        dispatch(setToast(toastMessages.backendIssue));
                    });
            }
        }
    };

    const getProfilesData = function (location, refreshData) {
        let search = new URLSearchParams(location);
        let searchObj = Object.fromEntries(search);
        if (!searchObj.per_page) searchObj.per_page = 10;
        setLoading(true);
        getProfiles(searchObj)
            .then((response) => {
                let _data =
                    response.data && response.data.length
                        ? response.data.map((profile) => ({
                              ...profile,
                              rowLink: `${routePaths.STUDENT_INDEX}/${get(profile, '_id')}/${
                                  studentPaths.SUMMARY
                              }`,
                          }))
                        : [];
                setData(_data);
                setStateHandler(response.data);
                setPage(Number(response.headers.page || 1));
                setPerPage(Number(response.headers.per_page || 0));
                setTotalPages(Number(response.headers.total_pages || 0));
                setLoading(false);
                dispatch(setDisplayTime(new Date()));
                if (refreshData) {
                    dispatch(setRefreshData(false));
                }
            })
            .catch(() => {
                dispatch(setToast(toastMessages.backendIssue));
                setLoading(false);
            });
    };

    const onFilterChange = ({ data }) => {
        setFiltersData(data);
    };

    const applyFilters = () => {
        setShowFilterBox(false);
        setQuery(1);
        localStorageActions.setDataInLocalStorage(
            'studentFilter',
            convertFilterDataToUrlFormat(filtersData),
        );
    };

    const onClearFilters = () => {
        setFiltersData({});
        setBadgeVisible(false);
        setFiltersDataMsg(filterMsg.default);
        dispatch(
            setQueries([
                { key: 'page', value: 1 },
                { key: 'per_page', value: 10 },
                { key: 'q', value: searchText },
            ]),
        );
        localStorageActions.deleteDataFromLocalStorage('studentFilter');
    };

    const handleDrawerClose = () => {
        setDrawerTabValue(0);
        setSelectedStudent(null);
    };

    const { studentDrawerProps } = useMemo(
        () => getListStudentProps(selectedStudent, '', dispatch),
        [selectedStudent],
    );

    const onCreate = function () {
        dispatch(goToRoute(routePaths.STUDENT_PROFILE_CREATE));
    };

    React.useEffect(() => {
        if (isSubAgent())
            getPartnerProfile(subAgentPartnerId).then((res) => {
                dispatch(getPartnerData(deserializePartner(res.data)));
            });
        setTimeout(() => {
            mounted = true;
        });
    }, []);

    // used for caching student profiles
    React.useEffect(async () => {
        let tempList = [];
        let studentWaitList = [];
        stateHandler.forEach((item) => {
            if (isEmpty(get(studentsList, item._id))) {
                studentWaitList.push(
                    getProfile(item._id)
                        .then((res) => {
                            tempList.push(deserializeStudent(res.data));
                            dispatch(addStudentToStore(deserializeStudent(res.data)));
                        })
                        .catch(() => {
                            dispatch(setToast(toastMessages.backendIssue));
                        }),
                );
            } else {
                tempList.push(get(studentsList, item._id));
            }
        });
        if (studentWaitList.length > 0) await Promise.all(studentWaitList);
    }, [stateHandler]);

    React.useEffect(() => {
        if (mounted) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            timeout = setTimeout(() => {
                if (page > 1) {
                    setQuery(1);
                } else {
                    setQuery();
                }
            }, 1000);
        }
    }, [searchText]);

    React.useEffect(() => {
        if (mounted) {
            setQuery();
        }
    }, [page, perPage]);

    React.useEffect(() => {
        getProfilesData(location.search);
    }, [location.search]);

    React.useEffect(() => {
        if (refreshData) {
            getProfilesData(location.search, refreshData);
        }
    }, [refreshData]);

    const TableComp = useMemo(
        () => (
            <Table
                title={'Students'}
                noDataMessage={filtersDataMsg}
                onSelectColumns={manageColumnsData}
                config={{
                    columns,
                    data,
                    initialState: {
                        hiddenColumns:
                            visibleColumns == null
                                ? columns
                                      .filter((col) => col.showInit == false)
                                      .map((col) => col.Header)
                                : hiddenColumns,
                    },
                    filter: {
                        setFilterText: setSearchText,
                        filterText: searchText,
                    },
                    pagination: {
                        perPageOptions: [5, 10, 15, 20, 50],
                        perPage,
                        setPerPage,
                        page,
                        setPage,
                        totalPages,
                    },

                    actionButton: {
                        label: 'Add New Student',
                        variant: 'contained',
                        color: 'success',
                        onClick: onCreate,
                        className: classes.createAppButton,
                    },
                }}
                isCustomFilter={true}
                loading={loading || user.loading}
                setSelected={onRowClick}
                filterProps={{
                    setFilterFormOpen: () => setShowFilterBox(true),
                    isFilterFormOpen: showFilterBox,
                    data: filtersData,
                    showFormErrors: false,
                    closeFilterForm: () => {
                        setShowFilterBox(false);
                    },
                    schema: getFilterSchema(globalData, isB2bAdmin()),
                    uiSchema: getFilterUiSchema(isB2bAdmin(), userId, filtersData),
                    onChange: onFilterChange,
                    clearFilters: onClearFilters,
                    isBadgeVisible: isBadgeVisible,
                    submitButton: {
                        label: 'Apply Filters',
                        onClick: applyFilters,
                    },
                }}
            />
        ),
        [data, columns, loading, page, perPage, searchText, showFilterBox, filtersData],
    );

    return (
        <>
            <StudentDrawer
                open={Boolean(selectedStudent)}
                onClose={handleDrawerClose}
                {...studentDrawerProps}
                tabs={{
                    value: drawerTabValue,
                    setValue: setDrawerTabValue,
                    ...studentDrawerProps.tabs,
                }}
            />

            {TableComp}
        </>
    );
}
