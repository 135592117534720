import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    mainContainer: {
        background: theme.palette.common.white,
        boxShadow: '0 #DFE4EC',
        padding: theme.spacing(2.4),
    },
    titleContainer: {
        display: 'flex',
    },
    title: {
        color: '#78A5FF',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    addProgramButton: {
        background: '#00C853',
        '&:hover': {
            background: '#30b166',
        },
    },
}));
