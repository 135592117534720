import React from 'react';

// mui
import Box from '@mui/material/Box';

import NewRegistration from './NewRegistration';

export default function ReadyForRegistration() {
    return (
        <Box m={5}>
            <NewRegistration />
        </Box>
    );
}
