import React from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';

// Utils
import { getSubAgentProfileProps } from '@/props-constants/sub-agent/sub-agent-profile';
// Components
import SubAgentDetail from './partials/SubAgentDetail';
import SubAgentHeader from './partials/SubAgentHeader';

export default function CreateSubAgent() {
    const dispatch = useDispatch();

    const { subAgentHeaderProps, subAgentDetailProps } = React.useMemo(() => {
        return getSubAgentProfileProps({}, dispatch);
    }, []);

    return (
        <Box>
            <SubAgentHeader {...subAgentHeaderProps} />
            <SubAgentDetail {...subAgentDetailProps} profileData={{}} />
        </Box>
    );
}

CreateSubAgent.propTypes = {};

CreateSubAgent.defaultProps = {};
