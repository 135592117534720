import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'unset!important',
        border: '1px solid #C4C4C4',
        minWidth: 275,
        borderRadius: '12px!important',
        [theme.breakpoints.down('sm')]: {
            border: 'unset',
            margin: 'unset',
        },
    },
    content: {
        color: '#000000',
        padding: '0px!important',
    },
    subtitle: {
        color: '#F16621',
        marginLeft: theme.spacing(1.875),
        [theme.breakpoints.down('sm')]: {
            marginLeft: 'unset',
        },
    },
    description: {
        margin: theme.spacing(1.875),
        marginBottom: theme.spacing(4.125),
        fontSize: theme.spacing(1.75),
        fontWeight: theme.spacing(62.5),
        [theme.breakpoints.down('sm')]: {
            margin: 'unset',
            marginBottom: theme.spacing(2.25),
        },
        fullDescriptionLoader: {
            height: '20px',
            width: '150px',
        },
    },
    fullDescription: {
        fontWeight: 500,
    },
    partnerCardWrapper: {
        width: '100%',
        wordWrap: 'break-word',
        fontWeight: 500,
    },
    markdownWrapper: {
        width: '100%',
        wordWrap: 'break-word',
    },
}));
