import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    editIcon: {
        fontSize: theme.spacing(1.5),
    },
    container: {
        padding: '4px 8px 4px 8px',
        borderRadius: '18x !important',
        textAlign: 'center',
        maxWidth: '180px !important',
    },
    title: {
        fontSize: '12px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
    },
    cModalTitleContainer: {
        backgroundColor: '#ffffff',
        width: '100%',
    },
    cModalTitle: {
        color: 'black',
    },
    modalTitleIcon: {
        borderRadius: '50%',
        color: theme.palette.neutral.dark,
        backgroundColor: 'white',
        verticalAlign: 'bottom',
        marginRight: theme.spacing(1.5),
    },
    containerBlue: {
        padding: '4px 4px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#E5EDFF',
        border: '1px solid #6D96E6',
        width: '170px',
    },
    containerGreen: {
        padding: '4px 4px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#E9FFE9',
        border: '1px solid #5AAF5A',
        width: '170px',
    },
    containerYellow: {
        padding: '4px 4px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#FFEBD3',
        border: '1px solid #F56A20',
        width: '170px',
    },
    containerRed: {
        padding: '4px 4px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#FFE2DCF0',
        border: '1px solid #D07070',
        width: '170px',
    },
    containerGrey: {
        padding: '4px 4px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#E7E7E7F0',
        border: '1px solid #6F716F',
        width: '170px',
    },
    titleBlue: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#6D96E6',
        whiteSpace: 'pre-wrap !important',
    },
    titleGreen: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#5AAF5A',
        whiteSpace: 'pre-wrap !important',
    },
    titleYellow: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#F56A20',
        whiteSpace: 'pre-wrap !important',
    },
    titleRed: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#D07070',
        whiteSpace: 'pre-wrap !important',
    },
    titleGrey: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#6F716F',
        whiteSpace: 'pre-wrap !important',
    },
    createAppButton: {
        fontSize: '14px',
        fontWeight: '600',
        borderRadius: '10px',
        color: 'white',
        lineHeight: '1.75',
        fontFamily: 'Montserrat',
        padding: '8px 20px',
        height: '50px',
        backgroundColor: '#00c853',
        '&:hover': {
            backgroundColor: '#3D8944',
        },
    },
    priorityIconP1: {
        color: '#D7330F',
        verticalAlign: 'bottom',
    },
    priorityIconP2: {
        color: '#F9945B',
        verticalAlign: 'bottom',
    },
    priorityIconP3: {
        color: '#5CAE7E',
        verticalAlign: 'bottom',
    },
    priorityIconP4: {
        color: '#81A8FD',
        verticalAlign: 'bottom',
    },
    priorityIconP5: {
        color: '#A7A7A7',
        verticalAlign: 'bottom',
    },
}));
