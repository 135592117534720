// @ts-nocheck
import React from 'react';
import starTicket from '../assets/svg/starTicket.svg';
import calendarCheck from '../assets/svg/calendarCheck.svg';
import checkedGirlAvatar from '../assets/avatar-girl-with-check.svg';
import checkedBoyAvatar from '../assets/avatar-boy.png';
import noBookmarks from '../assets/svg/noBookmarks.svg';
import avatarBoy from '@/assets/avatar-boy.svg';
import avatarGirl from '@/assets/avatar-girl.svg';
import watchHalf from '@/assets/svg/watch-half.svg';
import noMustVisitUniversities from '@/assets/svg/noMustVisitUniversities.svg';
import noMustVisitUniversitiesOffline from '@/assets/svg/noMustVisitUniversitiesOffline.svg';
import studentProfileBar from '@/assets/svg/studentprofilebar.svg';
import userProfile from '@/assets/svg/userProfile.svg';
import search from '@/assets/svg/search.svg';
import startMark from '@/assets/svg/startMark.svg';
import checklistTick from '@/assets/svg/checklistTick.svg';
import caution from '@/assets/svg/caution.svg';
import ChecklistTickFilled from '@/assets/svg/checklistTickFilled';
import ExploreUserIcon from '@/assets/svg/exploreUserIcon';

const getSvg = (icon, color = 'inherit', size = '50px', theme) => {
    const style = {
        color: color || 'inherit',
        width: size || '50px',
        height: size || '50px',
    };
    switch (icon) {
        case 'star-ticket':
            return <img style={style} src={starTicket} alt="star-ticket"></img>;
        case 'calendar-check':
            return <img style={style} src={calendarCheck} alt="calendar-check"></img>;
        case 'girl-with-check':
            return <img style={style} src={checkedGirlAvatar} alt="Avatar"></img>;
        case 'boy-with-check':
            return <img style={style} src={checkedBoyAvatar} alt="Avatar"></img>;
        case 'no-bookmarks':
            return <img style={style} src={noBookmarks} alt="No Bookmarks"></img>;
        case 'avatar-boy':
            return <img style={style} src={avatarBoy} alt="user"></img>;
        case 'avatar-girl':
            return <img style={style} src={avatarGirl} alt="user"></img>;
        case 'watch-half':
            return <img style={style} src={watchHalf} alt="svg"></img>;
        case 'no-universities':
            return <img style={style} src={noMustVisitUniversities} alt="svg"></img>;
        case 'no-universities-offline':
            return <img style={style} src={noMustVisitUniversitiesOffline} alt="svg"></img>;
        case 'search':
            return <img style={style} src={search} alt="svg"></img>;
        case 'user-profile':
            return <img style={style} src={userProfile} alt="svg"></img>;
        case 'startMark':
            return <img style={style} src={startMark} alt="svg"></img>;
        case 'checklistTick':
            return <img style={style} src={checklistTick} alt="svg"></img>;
        case 'studentProfileBar':
            return <img style={style} src={studentProfileBar} alt="svg" />;
        case 'caution':
            return <img style={style} src={caution} alt="svg" />;
        case 'checklistTickFilled':
            return <ChecklistTickFilled theme={theme} size={size}></ChecklistTickFilled>;
        case 'explore-user-icon':
            return <ExploreUserIcon theme={theme} size={size}></ExploreUserIcon>;
        default:
            return <img style={style} src={starTicket} alt="star-ticket"></img>;
    }
};

export default getSvg;
