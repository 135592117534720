import React from 'react';
import PropTypes from 'prop-types';

// comps
import ApplicationSummary from '@/components/modules/Application/StudentApplication/ApplicationSummary';
import AllApplications from './AllApplications';

export default function StudentApplication({
    applications,
    applicationId,
    crmStudentId,
    studentFullName,
}) {
    if (applicationId)
        return (
            <ApplicationSummary
                applicationId={applicationId}
                applications={applications}
                crmStudentId={crmStudentId}
                studentFullName={studentFullName}
            />
        );
    return <AllApplications applications={applications} />;
}

StudentApplication.propTypes = {
    applications: PropTypes.array,
    applicationId: PropTypes.string,
    crmStudentId: PropTypes.string,
    studentFullName: PropTypes.string,
};
