import instance from './instances/university-service';
import constants from '../constants';
import axios from 'axios';
import getHeaders from '@/utils/get-headers';

export function getProgram(id) {
    return instance.get(`/v1/programs/${id}`);
}

export function getPrograms(query) {
    let search = new URLSearchParams(query);
    return instance.get(`/v1/programs?${search.toString()}`);
}

export function getFilteredPrograms(query) {
    return new Promise((resolve, reject) => {
        instance
            .get(`/v1/programs`)
            .then((response) => {
                const querys = query.ids.split(',').map((item) => item);
                resolve({ data: response.data.filter((item) => querys.includes(item._id)) });
            })
            .catch((e) => {
                reject(e);
            });
    });
}

export function getProgramsOfUniversity(university_id, query) {
    let search = new URLSearchParams(query);
    return instance.get(`v1/programs/university/${university_id}?${search.toString()}`);
}

// ADDED THIS API CALL TO BYPASS DEFAULT API INTERACTION WHEN REQUEST GIVES 400
export async function getIntakeMonthsFromProgramId(id) {
    try {
        const baseUrl = constants.universityApi;
        const program = await axios.get(`${baseUrl}v1/programs/${id}`, {
            headers: getHeaders(),
        });
        if (program.status === 200) {
            return {
                success: true,
                intake_months: program && program.data && program.data.intake_months,
            };
        } else {
            return { success: true, intake_months: ['None'] };
        }
    } catch (err) {
        return { success: false };
    }
}

export function getKeywords(payload) {
    return instance.post(`/v1/programs/autocomplete`, payload);
}

export function keywordSearch(payload) {
    return getKeywords(payload).then((response) => {
        const data =
            response.data.length === 0 && !payload.text ? constants.keywords : response.data;
        return { data: data.map((keyword) => ({ keyword })) };
    });
}
