import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    step: {
        color: theme.palette.text.disabled,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    focusedStep: {
        color: theme.palette.text.primary,
    },
}));
