//API

import { getServices } from "@/api/ancillary";
import { deserializeAncillaryService } from "@/utils/deserializers/ancillary-service-deserializer";


export const INITIALIZE_SERVICES = "INITIALIZE_SERVICES";

export const initializeServices = (payload) =>{
    return{
        type:INITIALIZE_SERVICES,
        payload,
    }
}

export const servicesGetAndStore = (EnqId) =>{
    return (dispatch) =>{
        getServices(EnqId).then((res) => {
          const serviceList = res.data.subscribed_services;
          dispatch(initializeServices(deserializeAncillaryService(serviceList)));
        });
    }
}