// Apis
import { getPartners, getOwners, getPSAUser, searchSubAgent, keywordSearch } from '@/api';

// utils
import { STUDENT_STATUS_CHOICES } from '@/props-constants/student/student-profile';
import { isPartner, isPSA, isSubAgent } from '@/rbac';
import { modifiedByList, getModifiedByLabel } from '@/utils/modifiedBy';

const currentYear = new Date().getFullYear();

export const getFilterSchema = (config, isB2bAdmin) => {
    const schema = {
        type: 'object',
        properties: {
            /* NOTE: To be removed for this phase
            first_name: {
                type: 'string',
            },
            last_name: {
                type: 'string',
            },
            mobile: {
                type: 'string',
                minLength: 10,
                maxLength: 10,
            },
            email: {
                type: 'string',
                format: 'email',
            },*/
            passport_number: {
                type: 'string',
            },
            intake_month: {
                type: 'array',
                items: {
                    type: 'string',
                    enum: [
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sep',
                        'Oct',
                        'Nov',
                        'Dec',
                    ],
                },
            },
            intake_year: {
                type: 'array',
                items: {
                    type: 'number',
                    enum: [
                        currentYear - 3,
                        currentYear - 2,
                        currentYear - 1,
                        currentYear,
                        currentYear + 1,
                        currentYear + 2,
                        currentYear + 3,
                        currentYear + 4,
                    ],
                },
            },
            target_countries: {
                type: 'array',
                items: {
                    type: 'string',
                    enum: config.countries,
                },
            },
            target_program: {
                type: 'array',
                items: {
                    type: 'string',
                },
            },
            profile_statuses: {
                type: 'array',
                items: {
                    type: 'string',
                    enum: STUDENT_STATUS_CHOICES,
                },
            },
            owner: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'string',
                        },
                        name: {
                            type: 'string',
                        },
                    },
                },
            },
            created_date: {
                type: 'object',
            },
        },
    };
    if (isB2bAdmin) {
        schema.properties.partner = {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    name: {
                        type: 'string',
                    },
                    id: {
                        type: 'string',
                    },
                },
            },
        };
        schema.properties.last_modified_by = {
            type: 'array',
            items: {
                type: 'string',
                enum: modifiedByList,
            },
        };
    }

    if (!(isPSA() || isPartner() || isSubAgent())) {
        schema.properties.psa = {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    first_name: {
                        type: 'string',
                    },
                    id: {
                        type: 'string',
                    },
                },
            },
        };
    }

    if (isB2bAdmin || isPartner()) {
        schema.properties.sub_agent = {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    name: {
                        type: 'string',
                    },
                    id: {
                        type: 'string',
                    },
                },
                default: null,
            },
        };
    }
    return schema;
};

export const getFilterUiSchema = (isB2bAdmin, userId, filtersData) => {
    const referralPartnerId = isPartner()
        ? userId || null
        : (filtersData.partner && filtersData.partner.length == 1 && filtersData.partner[0].id) ||
          null;

    const uiSchema = {
        type: 'VerticalLayout',
        elements: [
            /* NOTE: To be removed for this phase
           {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/first_name',
                        label: 'First Name',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/last_name',
                        label: 'Last Name',
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/mobile',
                        label: 'Mobile Number',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/email',
                        label: 'Email ID',
                    },
                ],
            },*/
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/passport_number',
                        label: 'Passport Number',
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/intake_month',
                        label: 'Target Intake Month',
                        options: {
                            component: 'multiSelect',
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/intake_year',
                        label: 'Target Intake Year',
                        options: {
                            component: 'multiSelect',
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'HorizontalLayout',
                        elements: [
                            {
                                type: 'Control',
                                scope: '#/properties/target_program',
                                label: 'Target Programs',
                                options: {
                                    component: 'search',
                                    searchApi: keywordSearch,
                                    searchApiArgs: [{ text: '{{search}}' }],
                                    valueKey: 'keyword',
                                    labelKey: 'keyword',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'HorizontalLayout',
                        elements: [
                            {
                                type: 'Control',
                                scope: '#/properties/target_countries',
                                label: 'Target Countries',
                                options: {
                                    component: 'multiSelect',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/profile_statuses',
                        label: 'Profile Status',
                        options: {
                            component: 'multiSelect',
                            labelTransformation: 'startCase',
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        label: 'Student Owner',
                        scope: '#/properties/owner',
                        options: {
                            component: 'search',
                            searchApi: getOwners,
                            searchApiArgs: [{ q: '{{search}}' }],
                            indexApi: getOwners,
                            indexApiArgs: [{ ids: '{{ids}}' }],
                            valueKey: 'id',
                            labelKey: 'first_name',
                            labelExp: '{{first_name}} {{last_name}}',
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/created_date',
                        options: {
                            component: 'date-range-picker',
                        },
                    },
                ],
            },
        ],
    };
    if (isB2bAdmin) {
        uiSchema.elements.push(
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/partner',
                        label: 'Referral Partner',
                        options: {
                            component: 'search',
                            searchApi: getPartners,
                            searchApiArgs: [{ q: '{{search}}' }],
                            indexApi: getPartners,
                            indexApiArgs: [{ ids: '{{ids}}' }],
                            valueKey: 'id',
                            labelKey: 'name',
                        },
                    },
                ],
            },
            {
                type: 'Control',
                scope: '#/properties/last_modified_by',
                label: 'Last Action By',
                options: {
                    enumOptions: modifiedByList.map((item) => ({
                        label: getModifiedByLabel(item),
                        value: item,
                    })),
                    component: 'multiSelect',
                },
            },
        );
    }
    if (!(isPSA() || isPartner() || isSubAgent())) {
        uiSchema.elements.push({
            type: 'HorizontalLayout',
            elements: [
                {
                    type: 'Control',
                    label: 'Business Manager',
                    scope: '#/properties/psa',
                    options: {
                        component: 'search',
                        searchApi: getPSAUser,
                        searchApiArgs: [{ q: '{{search}}' }],
                        indexApi: getPSAUser,
                        indexApiArgs: [{ ids: '{{ids}}' }],
                        valueKey: 'id',
                        labelKey: 'first_name',
                        labelExp: '{{first_name}} ({{email}})',
                    },
                },
            ],
        });
    }
    if (isPartner() && referralPartnerId) {
        uiSchema.elements.push({
            type: 'HorizontalLayout',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/sub_agent',
                    label: 'Counsellor / Branch',
                    options: {
                        component: 'search',
                        searchApi: searchSubAgent(referralPartnerId),
                        searchApiArgs: [{ q: '{{search}}' }],
                        indexApi: searchSubAgent(referralPartnerId),
                        indexApiArgs: [{ ids: '{{ids}}' }],
                        valueKey: 'id',
                        labelKey: 'first_name',
                        labelExp: '{{first_name}} {{last_name}}',
                    },
                },
            ],
        });
    }

    if (isB2bAdmin && referralPartnerId) {
        uiSchema.elements[8].elements.push({
            type: 'HorizontalLayout',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/sub_agent',
                    label: 'Counsellor / Branch',
                    options: {
                        component: 'search',
                        searchApi: searchSubAgent(referralPartnerId),
                        searchApiArgs: [{ q: '{{search}}' }],
                        indexApi: searchSubAgent(referralPartnerId),
                        indexApiArgs: [{ ids: '{{ids}}' }],
                        valueKey: 'id',
                        labelKey: 'first_name',
                        labelExp: '{{first_name}} {{last_name}}',
                    },
                },
            ],
        });
    }
    return uiSchema;
};
