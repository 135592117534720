import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

//api
import { signRequest, changeApplicationStatus, getProfile } from '@/api';

// utils
import Loader from '@/components/shared/Loader';
import { get } from 'lodash';
import { deserializeStudent } from '@/utils/deserializers/student-deserializer';
import { isB2bAdmin } from '@/rbac';
import { toastMessages } from '@/utils/toastMessages';
import { getCommentsWithFormattedFileName } from '@/utils/comments';

// constants
import constants from '@/constants';

// mui
import { Grid } from '@mui/material';

// styles
import { useStyles } from './style';

// comps
import { ApplicationDetails } from './ApplicationDetails';
import { FormModal } from 'azent-storybook/dist';

// props
import {
    getCreateApplicationProps,
    getFollowupDateFromStatus,
    isExtraKeyForStatus,
    checkPaymentStatus,
} from '@/props-constants/application/application-status';

//action
import { addStudentToStore } from '@/store/slices/studentSlice';
import { setToast } from '@/store/slices/toastSlice';

const ApplicationSummary = ({ applications, applicationId, crmStudentId, studentFullName }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [isModalOpen, setModalOpen] = useState(false);
    const [statusFormData, setStatusFormData] = useState(null);
    const [appStatus, setAppStatus] = useState(null);
    const [followUpdate, setFollowUpdate] = useState(null);
    const [comments, setComments] = useState(null);

    const applicationData = useMemo(() => {
        return applications.filter((application) => application._id == applicationId);
    }, [applications, applicationId]);

    const fileUploadConfig = {
        s3Postfix: applicationData && `${constants.envName}/${applicationData.profile_id}`,
        awsConfig: constants.awsConfig,
        selfAuthApiCall: signRequest,
    };

    const resetModalValues = () => {
        setAppStatus(null);
        setFollowUpdate(null);
    };
    const handleModalOpen = () => {
        isB2bAdmin() && setModalOpen(true);
        resetModalValues();
    };

    const onSuccessCallback = (e) => {
        getProfile(e.profile_id).then((res) => {
            dispatch(addStudentToStore(deserializeStudent(res.data)));
        });
        setModalOpen(false);
        dispatch(setToast(toastMessages.successMessage));
        resetModalValues();
    };

    const handleClose = () => {
        setModalOpen(false);
    };

    const onStatusUpdate = () => {
        const newFormData = getCommentsWithFormattedFileName(
            statusFormData,
            crmStudentId,
            studentFullName,
        );
        const message = checkPaymentStatus(
            statusFormData.application_status,
            toastMessages,
            applicationData[0],
        );
        if (message) {
            dispatch(setToast(message));
            return;
        }
        return changeApplicationStatus(applicationId, {
            ...newFormData,
            comment: '',
        });
    };

    let onChangeApp = (formdata) => {
        if (formdata && formdata.application_status) {
            setAppStatus(formdata.application_status);
            if (appStatus && formdata.application_status !== appStatus) {
                let resetKeys = [
                    'application_sub_status',
                    'status_date',
                    'offer_acceptance_deadline_date',
                    'missing_documents',
                    'application_source',
                    'application_id',
                    'deferred_date_month',
                    'deferred_date_year',
                ];

                resetKeys.forEach((key) => {
                    if (formdata[key]) {
                        delete formdata[key];
                    }
                });

                // changes for followup_date
                if (isExtraKeyForStatus('followup_date', formdata.application_status)) {
                    formdata.followup_date = getFollowupDateFromStatus(formdata.application_status);
                } else {
                    delete formdata.followup_date;
                }
            }
        }
        setStatusFormData(formdata);
    };

    const correctDependency = (formdata) => {
        // changes for followup_date on change in data
        if (
            formdata.followup_date == followUpdate &&
            formdata.deferred_date_month &&
            formdata.deferred_date_year &&
            isExtraKeyForStatus('followup_date', formdata.application_status) &&
            isExtraKeyForStatus('deferred_date_month', formdata.application_status) &&
            isExtraKeyForStatus('deferred_date_year', formdata.application_status)
        ) {
            formdata.followup_date = getFollowupDateFromStatus(formdata.application_status, {
                ...formdata,
            });
        }
        setFollowUpdate(formdata.followup_date);
        return { data: formdata };
    };

    const submitButton = () => {
        return {
            onClick: onStatusUpdate,
            fullWidth: false,
            label: 'UPDATE',
            variant: 'contained',
            size: 'medium',
            color: 'success',
        };
    };

    const appStatusFormProps = useMemo(() => {
        let data = null;
        if (applicationData) {
            data = getCreateApplicationProps(appStatus, classes, get(applicationData, '0', {}));
        }
        return data;
    }, [appStatus, followUpdate, applicationData]);

    if (!applicationData || applicationData.length < 1) return <Loader />;

    return (
        <React.Fragment>
            <Grid container>
                <ApplicationDetails
                    data={get(applicationData, '0', {})}
                    appId={applicationId}
                    classes={classes}
                    setComments={setComments}
                    comments={comments}
                    handleModalOpen={handleModalOpen}
                    crmStudentId={crmStudentId}
                    studentFullName={studentFullName}></ApplicationDetails>
            </Grid>

            {appStatusFormProps && (
                <FormModal
                    {...appStatusFormProps}
                    open={isModalOpen}
                    onSuccessCallback={onSuccessCallback}
                    onChangeApp={onChangeApp}
                    correctDependency={correctDependency}
                    uploadConfig={fileUploadConfig}
                    submitButton={submitButton()}
                    isB2bFormModal={true}
                    handleClose={handleClose}
                />
            )}
        </React.Fragment>
    );
};

export default ApplicationSummary;

ApplicationSummary.propTypes = {
    applications: PropTypes.array,
    applicationId: PropTypes.string,
    crmStudentId: PropTypes.string,
    studentFullName: PropTypes.string,
};
