import { get } from 'lodash';
import moment from 'moment';

const allowedKeys = [
    'financial_aid',
    'placements',
    'faculty',
    'infrastructure',
    'accommodation',
    'location',
    'weather',
    'alumni',
];

const deserializeProgram = (program) => {
    const lodashGet = (key, defaultValue) => get(program, key, defaultValue);

    let data = {};
    if (program) {
        const rankings =
            program?.university?.rankings && program.university.rankings.length
                ? program.university.rankings
                : program?.rankings && program.rankings.length
                ? program.rankings
                : [];
        const rank = rankings.length
            ? `#${rankings[0].country_rank || rankings[0].rank} -
            ${rankings[0].source_name || rankings[0].source}`
            : '-';
        const intakes =
            program.intake_months && program.intake_months.length > 0
                ? program.intake_months
                : program.university.intake_months;
        const intakeDetails = program.intakes ? program.intakes : [];
        const duration = lodashGet('length_in_months', '')
            ? lodashGet('length_in_months', '') + ' months'
            : '-';
        /* eslint-disable no-mixed-spaces-and-tabs */
        const tuitionCost = lodashGet('costs.tuition_fee', null)
            ? `${lodashGet('costs.currency', '')} ${
                  lodashGet('costs.tuition_fee', null)
                      ? Number(lodashGet('costs.tuition_fee', null)).toLocaleString()
                      : '-'
              }`
            : '';
        const applicationCost =
            program.costs && program.costs.application_fee === 0
                ? `${program.costs.application_fee}`
                : lodashGet('costs.application_fee', null) && program.costs.application_fee != 0
                ? `${lodashGet('costs.currency', '')} ${
                      lodashGet('costs.application_fee', null)
                          ? Number(lodashGet('costs.application_fee', null)).toLocaleString()
                          : '-'
                  }`
                : '';

        const applicationDeadline = getApplicationDeadline(program);

        const categories = program.categories ? program.categories : [{ name: '' }];

        let accordionData = [];
        Object.keys(get(program.university, 'details', {}))
            .sort((a, b) => allowedKeys.indexOf(a) - allowedKeys.indexOf(b))
            .forEach((i) => {
                if (allowedKeys.includes(i)) {
                    accordionData.push({
                        title: get(program.university, `details.${i}.title`, ''),
                        content: get(program.university, `details.${i}.description`, ''),
                    });
                }
                return null;
            });
        const universityDescription =
            program.university &&
            program.university.details &&
            program.university.details.about &&
            program.university.details.about.description;
        data = {
            ...program,
            id: program._id,
            name: program.name,
            slug: program.slug,
            category: categories && categories[0] && categories[0].name ? categories[0].name : '',
            categories,
            duration,
            intakes,
            intakeDetails,
            tuitionCost,
            applicationCost,
            applicationDeadline,
            level: program.level,
            type: program.type,
            university: {
                id: get(program.university, '_id', ''),
                name: get(program.university, 'name', ''),
                slug: get(program.university, 'slug', ''),
                logoSrc: lodashGet('university.images.logo.original', ''),
                rank,
                country: lodashGet('university.location.country', ''),
                city: lodashGet('university.location.city', ''),
                about: {
                    shortContent:
                        universityDescription &&
                        lodashGet('university.details.about.description', '').substr(0, 350) +
                            '...',
                    longContent:
                        universityDescription &&
                        lodashGet('university.details.about.description', ''),
                },
                accordionData,
                onClick: () => {},
                recommendation_reasons:
                    program.university &&
                    program.university.recommendation_reasons &&
                    program.university.recommendation_reasons.length > 0
                        ? program.university.recommendation_reasons[0]
                        : null,
                recommendation_type: program.university && program.university.recommendation_type,
            },
        };
    }
    return data;
};

const deserializeProgramList = (programs) => {
    return programs.map((program) => deserializeProgram(program));
};

export { deserializeProgram, deserializeProgramList };

const getApplicationDeadline = (program) => {
    let selectedIntake = {};
    let deadlines = '';
    const intakeDetails = get(program, 'university.intake_details', []);
    if (intakeDetails.length) {
        const bestIntakes = intakeDetails.filter((item, index) => {
            return (
                RegExp(moment().format('MMMM')).test(item.intake) ||
                RegExp(moment().format('MMM')).test(item.intake) ||
                intakeDetails.length - 1 === index
            );
        });
        if (bestIntakes.length) {
            selectedIntake = bestIntakes[0].details;
        } else {
            return '';
        }

        const ugMappings = ['Bachelor'];
        const level = get(program, 'level', '');
        const type = get(program, 'type', '');

        let courseLevel = '';
        let courseType = 'default';

        if (level) {
            courseLevel = ugMappings.includes(level) ? 'ug' : 'pg';
        }
        if (type) {
            courseType = type.toLowerCase();
        }
        if (courseLevel) {
            const selectedCourse = get(selectedIntake, courseLevel, null);

            if (selectedCourse) {
                const specificCourse = get(selectedCourse, courseType, '');

                if (specificCourse) {
                    if (specificCourse.application_deadlines.length) {
                        const filteredDeadline = specificCourse.application_deadlines[0];
                        deadlines = `Application Deadline: ${moment(filteredDeadline.date).format(
                            'Do MMM YYYY',
                        )}`;
                    }
                }
            }
        }
    }
    return deadlines;
};
