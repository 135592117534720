import { get, isEmpty } from 'lodash';

// Utils
import { deserializeInterestRegions } from './deserializers/student-deserializer';

// Constants
import { SELF_SUBAGENT_DATA } from '@/api';
import validateJsonSchema from '@/utils/validate-json-schema';
import getNextProfileStep from './get-next-profile-step';
import {
    EDUCATION_DOCUMENTS,
    TEST_DOCUMENTS,
    GENERAL_DOCUMENTS,
    MISCELLANEOUS_DOCUMENTS,
    PERSIST_DOCUMENTS,
    WORK_DOCUMENTS,
    GTE_DOCUMENTS,
    REQUIRED_DOCUMENTS,
} from '@/utils/documents';
const steps = [0, 1, 2, 3, 4, 5];
import schema from '@/props-constants/student-schema/student-form-schemas/schema';

const profileCompletionPercentage = (profileData) => {
    return get(profileData, 'profile_completion_percentage', 0);
};

const isProfileCompleted = (profileData) => {
    return profileCompletionPercentage(profileData) === 100;
};

const profileCompletionText = (profileData) => {
    return isProfileCompleted(profileData) ? 'Edit Profile' : 'Complete Profile';
};

const getUserDisplayCharacter = (profileData) => {
    return get(profileData, 'first_name', '').trim().substr(0, 1);
};

const getProfileRoutePath = (path, profileData) => {
    return `${path}?initialIndex=${
        isProfileCompleted(profileData) ? 0 : getNextProfileStep(profileData)
    }`;
};

const validateProfileDataByStep = (globalData, profileData, index, formData = {}) => {
    let stepData = getProfileSplitByIndex(profileData, index, formData);
    let validatorData = validateJsonSchema(globalData, stepData, schema);
    return !(validatorData && validatorData.length !== 0);
};

const getProfileSplitByIndex = (profileData, index, dirtyFormData) => {
    switch (index) {
        case 0: {
            const stepDirtyData = dirtyFormData &&
                dirtyFormData[index] && { ...dirtyFormData[index] };

            const poc = {
                ...profileData.point_of_contact,
                mobile:
                    profileData.point_of_contact.mobile &&
                    profileData.point_of_contact.mobile.length > 10
                        ? profileData.point_of_contact.mobile.slice(-10)
                        : profileData.point_of_contact.mobile,
                whatsapp_number:
                    profileData.point_of_contact.whatsapp_number &&
                    profileData.point_of_contact.whatsapp_number.length > 10
                        ? profileData.point_of_contact.whatsapp_number.slice(-10)
                        : profileData.point_of_contact.whatsapp_number,
            };

            const stepData = {
                step: index,
                first_name: profileData.first_name,
                last_name: profileData.last_name,
                email: profileData.email,
                mobile: profileData.mobile,
                onboarding_completed: profileData.onboarding_completed,
                date_of_birth: profileData.date_of_birth,
                nationality: profileData.nationality,
                gender: profileData.gender,
                marital_status: profileData.marital_status,
                desired_profile: {
                    ...profileData.desired_profile,
                    interest_countries: profileData.desired_profile.interest_countries || [],
                    interest_regions: profileData.desired_profile.interest_regions || [],
                },
                alternate_mobile: profileData.alternate_mobile,
                point_of_contact: poc,
                partner: undefined,
                sub_agent: undefined,
                [PERSIST_DOCUMENTS]: profileData[PERSIST_DOCUMENTS],
            };
            if (profileData.partner_id) {
                stepData.partner = {
                    id: parseInt(profileData.partner_id),
                    name: profileData.partner__name,
                };
                if (profileData.sub_agent_id && !isEmpty(profileData.sub_agent)) {
                    stepData.sub_agent = {
                        id: parseInt(profileData.sub_agent_id),
                        first_name: profileData.sub_agent.first_name,
                        last_name: profileData.sub_agent.last_name,
                    };
                } else {
                    stepData.sub_agent = {
                        id: SELF_SUBAGENT_DATA.id,
                        first_name: SELF_SUBAGENT_DATA.first_name,
                        last_name: SELF_SUBAGENT_DATA.last_name,
                    };
                }
            }

            if (stepDirtyData && get(stepDirtyData, 'point_of_contact.copy_number', null)) {
                stepDirtyData.point_of_contact.whatsapp_number = get(
                    stepDirtyData,
                    'point_of_contact.mobile',
                    null,
                );
            }
            if (stepDirtyData) {
                const targetCountry = get(stepDirtyData, 'desired_profile.interest_countries', []);
                const targetRegion = get(stepDirtyData, 'desired_profile.interest_regions', []);
                if (targetCountry.length && targetRegion.length) {
                    let newRegions = targetRegion.reduce((acc, item) => {
                        if (item.country && targetCountry.includes(item.country)) {
                            acc.push(item);
                            return acc;
                        } else return acc;
                    }, []);
                    stepDirtyData.desired_profile.interest_regions = newRegions;
                }
            }
            if (
                stepData.point_of_contact &&
                stepData.point_of_contact.mobile !== null &&
                stepData.point_of_contact.whatsapp_number !== null &&
                stepData.point_of_contact.mobile === stepData.point_of_contact.whatsapp_number &&
                !stepData.point_of_contact.copy_number
            ) {
                stepData.point_of_contact = { ...stepData.point_of_contact, copy_number: true };
            } else {
                stepData.point_of_contact = { ...stepData.point_of_contact, copy_number: false };
            }

            if (stepData.desired_profile.interest_regions.length) {
                stepData.desired_profile.interest_regions = deserializeInterestRegions(
                    stepData.desired_profile.interest_regions,
                );
            }

            return stepData;
        }
        case 1: {
            let educationHistory = profileData.education_history || {};
            let filteredEducationHistory = { highest_education_level: null };
            if (!isEmpty(educationHistory)) {
                filteredEducationHistory = Object.keys(educationHistory).reduce((acc, curr) => {
                    if (curr === 'backlogs_count' || curr === 'highest_education_level') {
                        acc[curr] = educationHistory[curr];
                    } else {
                        acc[curr] = {
                            ...educationHistory[curr],
                            from_year: get(educationHistory[curr], 'from_year', ''),
                            to_year: get(educationHistory[curr], 'to_year', ''),
                        };
                    }
                    return acc;
                }, {});
            }

            const stepData = {
                step: index,
                education_history: filteredEducationHistory,
                [EDUCATION_DOCUMENTS]: profileData[EDUCATION_DOCUMENTS],
            };

            return stepData;
        }
        case 2: {
            let is_tests_opted = profileData.is_tests_opted;
            if (!is_tests_opted) {
                if (profileData.test_scores && profileData.test_scores.length) {
                    is_tests_opted = 'Yes';
                }
            }
            const stepData = {
                step: index,
                is_tests_opted,
                test_scores: profileData.test_scores,
                [TEST_DOCUMENTS]: profileData[TEST_DOCUMENTS],
            };
            // test_document
            if (profileData['language_test'] && profileData['language_test'].length > 0) {
                stepData[TEST_DOCUMENTS] = [
                    ...profileData[TEST_DOCUMENTS],
                    profileData['language_test'],
                ];
            }
            return stepData;
        }
        case 3:
            return {
                step: index,
                has_work_experience: profileData.has_work_experience,
                work_info: profileData.work_info,
                work_history: profileData.work_history,
                [WORK_DOCUMENTS]: profileData[WORK_DOCUMENTS],
            };
        case 4:
            return {
                step: index,
                address: profileData.address,
                passport: profileData.passport,
                background_info: profileData.background_info,
                [GENERAL_DOCUMENTS]: profileData[GENERAL_DOCUMENTS],
                [MISCELLANEOUS_DOCUMENTS]: profileData[MISCELLANEOUS_DOCUMENTS],
                [GTE_DOCUMENTS]: profileData[GTE_DOCUMENTS],
            };

        case 5:
            return {
                step: index,
                requiredDocumentStep: true,
                required_documents: profileData[REQUIRED_DOCUMENTS],
                categoriesKeyMapping: profileData.categoriesKeyMapping,
                applications: profileData.applications,
                isAutoSave: true,
            };
    }
    return { step: index };
};

const getProfileSplit = (profileData, dirtyFormData) => {
    let profileSplit = {};

    if (profileData) {
        profileSplit = steps.reduce((acc, curr) => {
            acc[curr] = getProfileSplitByIndex(profileData, curr, dirtyFormData);

            return acc;
        }, {});
    } else {
        profileSplit = steps.reduce((acc, curr) => {
            acc[curr] = { step: curr };
            return acc;
        }, {});
    }

    return profileSplit;
};

//unused code
export const getProgramFiltersFromProfile = (profileData) => {
    let filtersData = {};
    if (profileData && profileData.desired_profile) {
        const { desired_profile } = profileData;
        const degree_types = get(desired_profile, 'degree_types_new', '');
        let level = null;

        if (degree_types.includes('PhD')) {
            level = 'PhD';
        } else if (degree_types.includes('Masters')) {
            level = 'Master';
        } else if (degree_types && degree_types.length) {
            level = 'Bachelor';
        }

        if (get(desired_profile, 'interest_countries', null)) {
            filtersData.countries = get(desired_profile, 'interest_countries', null);
        }
        if (get(desired_profile, 'interest_areas', null)) {
            filtersData.categories = get(desired_profile, 'interest_areas', null);
        }
        if (get(desired_profile, 'interest_sub_areas', null)) {
            filtersData.subcategories = get(desired_profile, 'interest_sub_areas', null);
        }
        if (get(desired_profile, 'intake_month', null)) {
            filtersData.intakes = get(desired_profile, 'intake_month', null);
        }
        if (level) {
            filtersData.level = level;
        }
    }
    return filtersData;
};

export const getProfileUserRole = (role, roles) => {
    return roles && roles.includes(role);
};

export {
    steps,
    profileCompletionPercentage,
    isProfileCompleted,
    profileCompletionText,
    getProfileSplit,
    validateProfileDataByStep,
    getUserDisplayCharacter,
    getProfileRoutePath,
};
