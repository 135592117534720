// @ts-nocheck
import { createReducer } from '@reduxjs/toolkit';

//actions
import { getPartnerData } from '../actions/partnerActions';

const initialState = { data: {}, loading: true };

export const partnerReducer = createReducer(initialState, {
    [getPartnerData]: (state, action) => {
        state.data = action.payload;
        state.loading = false;
    },
});
