import { get, lowerCase, isEmpty } from 'lodash';

// UiSchema
import personalDetailsUiSchemas from './student-form-schemas/ui-schemas/personal-details-ui';
import educationalDetailsUiSchemas from './student-form-schemas/ui-schemas/educational-details-ui';
import testScoreUiSchemas from './student-form-schemas/ui-schemas/test-score-ui';
import workDetailsUiSchemas from './student-form-schemas/ui-schemas/work-details-ui';
import applicationDetailsUiSchemas from './student-form-schemas/ui-schemas/application-details-ui';

// Schema
import schema from '@/props-constants/student-schema/student-form-schemas/schema';

// Utils
import { getProfileSplit } from '@/utils/student';
import {
    deserializeWorkHistory,
    serializeInterestRegions,
} from '@/utils/deserializers/student-deserializer';
import routePaths, { studentPaths } from '@/utils/route-paths';
import { goToRoute } from '@/store/actions/navigationActions';

// API
import {
    createProfile,
    signRequest,
    getProfile,
    updateProfile,
    updateApplication,
    SELF_SUBAGENT_DATA,
    verifyDocument,
} from '@/api';

// Constants
const steps = [0, 1, 2, 3, 4, 5];
import constants from '@/constants';
import {
    EDUCATION_DOCUMENTS,
    TEST_DOCUMENTS,
    GENERAL_DOCUMENTS,
    MISCELLANEOUS_DOCUMENTS,
    REQUIRED_DOCUMENTS,
    WORK_DOCUMENTS,
    GTE_DOCUMENTS,
    formatDocumentsToSend,
    getUniqueArrayOfObjectByObjectId,
    EDUCATION_DOCUMENT_LABEL,
    TEST_DOCUMENTS_LABEL,
    WORK_DOCUMENTS_LABEL,
    GENERAL_DOCUMENTS_LABEL,
    MISCELLANEOUS_DOCUMENTS_LABEL,
    GTE_DOCUMENTS_LABEL,
} from '@/utils/documents';

import allDocumentsUiSchemas from './student-form-schemas/ui-schemas/all-documents-ui';

const getStepTitleByIndex = (index) => {
    switch (index) {
        case 0:
            return 'Basic Details';
        case 1:
            return 'Academic Details';
        case 2:
            return 'Test Details';
        case 3:
            return 'Work Experience';
        case 4:
            return 'Application Requirement';

        case 5:
            return 'All documents';
        default:
            return '';
    }
};

const getFormUiSchema = (
    index,
    dirtyFormData,
    id,
    partnerEditAccess,
    categoriesKeysMapping = [],
    showGTEUploadOption,
    referralPartnerId,
    targetCountries,
) => {
    let config = {};
    config.categoriesKeyMapping = categoriesKeysMapping ? categoriesKeysMapping : [];
    switch (index) {
        case 0: {
            return personalDetailsUiSchemas(
                config,
                id,
                partnerEditAccess,
                referralPartnerId,
                targetCountries,
            );
        }
        case 1: {
            config.educationLevel = get(
                dirtyFormData && dirtyFormData[index],
                'education_history.highest_education_level',
            );
            return educationalDetailsUiSchemas(config);
        }
        case 2: {
            return testScoreUiSchemas(config);
        }
        case 3:
            return workDetailsUiSchemas(config);
        case 4:
            config.showGTEUploadOption = showGTEUploadOption;
            return applicationDetailsUiSchemas(config);
        case 5:
            config.showGTEUploadOption = showGTEUploadOption;
            return allDocumentsUiSchemas(config);
        default:
            return {};
    }
};

const deserializeFormData = (index, data, profileData) => {
    switch (index) {
        case 0: {
            let newData = JSON.parse(JSON.stringify(data));

            if (newData.partner && newData.partner.id)
                newData.partner_id = newData.partner.id.toString();

            if (newData.sub_agent && newData.sub_agent.id) {
                if (newData.sub_agent.id == SELF_SUBAGENT_DATA.id) delete newData.sub_agent;
                else {
                    newData.sub_agent_id = newData.sub_agent.id.toString();
                    delete newData.sub_agent;
                }
            }

            if (
                newData.desired_profile?.interest_regions &&
                newData.desired_profile.interest_regions.length
            ) {
                newData.desired_profile.interest_regions = serializeInterestRegions(
                    newData.desired_profile.interest_regions,
                );
            }

            // trim data
            newData.first_name = newData.first_name.toString().trim();
            newData.last_name = newData.last_name.toString().trim();
            newData.email = newData.email.toString().trim();
            newData.point_of_contact.mobile =
                (profileData && profileData.point_of_contact && profileData.point_of_contact.mobile
                    ? profileData.point_of_contact.mobile.slice(0, -10)
                    : '') + newData.point_of_contact.mobile;

            newData.point_of_contact.whatsapp_number =
                (profileData &&
                profileData.point_of_contact &&
                profileData.point_of_contact.whatsapp_number
                    ? profileData.point_of_contact.whatsapp_number.slice(0, -10)
                    : '') + newData.point_of_contact.whatsapp_number;

            delete newData.copy_number;
            return newData;
        }
        case 1: {
            let educationDocuments = data[EDUCATION_DOCUMENTS];
            let newData = data;

            if (educationDocuments) {
                delete data[EDUCATION_DOCUMENTS];
                const formattedDocuments = formatDocumentsToSend(
                    educationDocuments,
                    profileData,
                    EDUCATION_DOCUMENT_LABEL,
                );
                newData = {
                    ...data,
                    documents: formattedDocuments,
                };
            }

            return newData;
        }
        case 2: {
            let testDocuments = data[TEST_DOCUMENTS];
            let newData = data;

            if (testDocuments) {
                delete data[TEST_DOCUMENTS];
                const formattedDocuments = formatDocumentsToSend(
                    testDocuments,
                    profileData,
                    TEST_DOCUMENTS_LABEL,
                );
                newData = {
                    ...data,
                    documents: formattedDocuments,
                };
            }

            return newData;
        }
        case 3: {
            let newData = data;
            let workDocuments = data[WORK_DOCUMENTS];
            if (workDocuments) {
                delete data[WORK_DOCUMENTS];
                const formattedDocuments = formatDocumentsToSend(
                    workDocuments,
                    profileData,
                    WORK_DOCUMENTS_LABEL,
                );
                newData = {
                    ...data,
                    work_history: deserializeWorkHistory(data.work_history),
                    documents: formattedDocuments,
                };
            }

            return newData;
        }
        case 4: {
            let generalDocuments = data[GENERAL_DOCUMENTS];
            let miscellaneousDocuments = data[MISCELLANEOUS_DOCUMENTS];
            let gteDocuments = data[GTE_DOCUMENTS];
            let newData = data;
            let documents = [];

            if (generalDocuments) {
                delete data[GENERAL_DOCUMENTS];
                const formattedDocuments = formatDocumentsToSend(
                    generalDocuments,
                    profileData,
                    GENERAL_DOCUMENTS_LABEL,
                );
                documents = documents.concat(formattedDocuments);
            }
            if (miscellaneousDocuments) {
                delete data[MISCELLANEOUS_DOCUMENTS];
                const formattedDocuments = formatDocumentsToSend(
                    miscellaneousDocuments,
                    profileData,
                    MISCELLANEOUS_DOCUMENTS_LABEL,
                );
                documents = documents.concat(formattedDocuments);
            }

            if (gteDocuments) {
                delete data[GTE_DOCUMENTS];
                const formattedDocuments = formatDocumentsToSend(
                    gteDocuments,
                    profileData,
                    GTE_DOCUMENTS_LABEL,
                );
                documents = documents.concat(formattedDocuments);
            }

            if (documents) {
                newData = {
                    ...data,
                    documents,
                };
            }

            newData = { ...newData, ...newData.additional_personal_details };
            delete newData.additional_personal_details;

            return newData;
        }

        case 5: {
            const requiredDocuments = data[REQUIRED_DOCUMENTS];
            let newData = data;

            if (requiredDocuments) {
                delete data[REQUIRED_DOCUMENTS];
                const formattedDocuments = formatDocumentsToSend(requiredDocuments, profileData);
                const applicationDocuments = formattedDocuments.filter(
                    (document) => document.application_id,
                );
                const otherDocuments = formattedDocuments.filter(
                    (document) => !document.application_id,
                );
                newData = { documents: otherDocuments, applicationDocuments };
            }
            delete data['requiredDocumentStep'];
            delete data['applications'];
            delete data['categoriesKeyMapping'];
            delete data['isAutoSave'];

            return newData;
        }
        default:
            return data;
    }
};

function groupDocumentsByApplication(documents, profileData) {
    const documentsByApplication = documents.reduce((groups, document) => {
        // eslint-disable-next-line
        const {
            application_id,
            // eslint-disable-next-line
            university_name,
            // eslint-disable-next-line
            intake,
            // eslint-disable-next-line
            programName,
            isCommentDocument,
            ...othersProps
        } = document;

        if (!groups[application_id]) {
            groups[application_id] = [];
        }
        if (!isCommentDocument) {
            groups[application_id].push({ ...othersProps });
        }

        return groups;
    }, {});

    profileData.applications.forEach((profile) => {
        const { _id } = profile;
        if (!documentsByApplication[_id]) {
            documentsByApplication[_id] = [];
        }
    });

    return documentsByApplication;
}

function generatePutPromises(groupedDocuments) {
    return Object.entries(groupedDocuments).map(([key, value]) => {
        if (value.length === 0) {
            return updateApplication(key, {
                documents: [{}],
            });
        }
        const payload = {
            documents: value.map((document) => ({
                name: document.category,
                file: document,
            })),
        };
        return updateApplication(key, payload);
    });
}

async function uploadApplicationDocuments(applicationDocuments, profileData) {
    const groupedDocuments = groupDocumentsByApplication(applicationDocuments, profileData);
    const putPromises = generatePutPromises(groupedDocuments);
    await Promise.all(putPromises);
    await getProfile(profileData.id);
}

// eslint-disable-next-line
const submitApiByIndex = (id, index, submittedData, fromData, profileData) => {
    let profileDataToSend = {};
    // eslint-disable-next-line
    Object.values(submittedData).forEach(({ step, ...data }, index) => {
        data = deserializeFormData(step, data, profileData);

        if (data.documents) {
            if (get(profileDataToSend, 'documents.all_documents', false)) {
                profileDataToSend = {
                    ...profileDataToSend,
                    ...data,
                    documents: {
                        ...profileData.documents,
                        all_documents: getUniqueArrayOfObjectByObjectId([
                            ...data.documents,
                            ...profileDataToSend.documents.all_documents,
                        ]),
                    },
                };
            } else {
                profileDataToSend = {
                    ...profileDataToSend,
                    ...data,
                    documents: {
                        ...profileData.documents,
                        all_documents: getUniqueArrayOfObjectByObjectId(data.documents),
                    },
                };
            }
        } else {
            profileDataToSend = {
                ...profileDataToSend,
                ...data,
            };
        }

        if (data.applicationDocuments) {
            uploadApplicationDocuments(data.applicationDocuments, profileData);
            delete profileDataToSend['applicationDocuments'];
        }
    });

    return id ? updateProfile(id, profileDataToSend) : createProfile(profileDataToSend);
};

const submitManualVerification = (data) => {
    return verifyDocument(data);
};

const formConstantsByIndex = (index) => {
    switch (index) {
        default:
            return {};
    }
};

const getFormDataByIndex = (
    id,
    index,
    dirtyFormData,
    globalData,
    profileData,
    intakeMonth,
    validateForm,
    referralPartnerId,
    targetCountries,
) => {
    const partnerEditAccess = Boolean(get(profileData, 'applications.length', 0));

    return {
        title: getStepTitleByIndex(index),
        schema: schema(globalData, intakeMonth, dirtyFormData),
        uiSchemas: getFormUiSchema(
            index,
            dirtyFormData,
            id,
            partnerEditAccess,
            profileData.categoriesKeysMapping,
            profileData.showGTEUploadOption,
            referralPartnerId,
            targetCountries,
        ),
        submitButton: {
            validateForm: validateForm,
            color: 'success',
            label: index
                ? steps.length - 1 === index
                    ? 'Complete Profile'
                    : 'Save & Proceed'
                : id
                ? 'Save & Proceed'
                : 'Create Profile',
            onClick: (submittedData, formData, formConstants) => {
                return submitApiByIndex(
                    id,
                    index,
                    submittedData,
                    {
                        ...formData,
                        ...formConstants,
                    },
                    profileData,
                );
            },
            onAutoSave: (submittedData, formData, formConstants) => {
                return submitApiByIndex(
                    id,
                    index,
                    submittedData,
                    {
                        ...formData,
                        ...formConstants,
                    },
                    profileData,
                );
            },
            onVerifyDoc: (data) => {
                return submitManualVerification(data);
            },
        },
        formConstants: formConstantsByIndex(index),
        uploadConfig: {
            awsConfig: constants.awsConfig,
            s3Postfix: `${lowerCase(constants.envName)}/${id}`,
            selfAuthApiCall: signRequest,
        },
    };
};

export const checkProfileStepCompleted = (data, index) => {
    switch (index) {
        case 1:
            return (
                data[index].education_history &&
                !isEmpty(data[index].education_history.highest_education_level)
            );
        case 2: {
            if (!data[index].is_tests_opted) return false;
            return ['Yes', 'No'].includes(data[index].is_tests_opted);
        }
        case 3: {
            if (!data[index].has_work_experience) return false;
            return ['Yes', 'No'].includes(data[index].has_work_experience);
        }
        case 4: {
            if (
                !['address', 'general_documents', 'miscellaneous_documents', 'passport'].every(
                    (key) => Object.keys(data[index]).includes(key),
                )
            )
                return false;
            if (data[index].passport) {
                if (!data[index].passport.passport_number) {
                    return false;
                }
            } else {
                return false;
            }
            return !(
                isEmpty(data[index].address) ||
                !(data[index].general_documents && data[index].general_documents.length) ||
                !(data[index].miscellaneous_documents && data[index].miscellaneous_documents.length)
            );
        }
    }
};

const getMyProfileProps = (
    globalData,
    dirtyFormData,
    id,
    profileData,
    intakeMonth,
    dispatch,
    validateForm,
    referralPartnerId,
    targetCountries,
) => {
    let data = {
        journey: {
            styleProps: {
                showBorder: true,
                titleVariant: 'h5',
                borderColor: 'secondary',
            },
            formsData: steps.map((index) =>
                getFormDataByIndex(
                    id,
                    index,
                    dirtyFormData,
                    globalData,
                    profileData,
                    intakeMonth,
                    validateForm,
                    referralPartnerId,
                    targetCountries,
                ),
            ),
            previousButton: {
                label: 'Previous',
                variant: 'outlined',
                color: 'inherit',
                sx: {
                    fontSize: '14px',
                    fontWeight: '600',
                    color: 'black',
                    borderColor: 'rgba(0,0,0,0.23)',
                    mr: 2,
                    '&:hover': {
                        borderColor: 'rgba(0,0,0,0.23)',
                    },
                },
            },
            additionalButton: {
                label: 'View Student',
                variant: 'outlined',
                color: 'inherit',
                sx: {
                    fontSize: '14px',
                    fontWeight: '600',
                    color: 'black',
                    borderColor: 'rgba(0,0,0,0.23)',
                    mr: 2,
                    '&:hover': {
                        borderColor: 'rgba(0,0,0,0.23)',
                    },
                },
                onClick: () => {
                    id
                        ? dispatch(
                              goToRoute(routePaths.STUDENT_PROFILE_VIEW, {
                                  path: studentPaths.SUMMARY,
                                  id: id,
                              }),
                          )
                        : dispatch(goToRoute(routePaths.STUDENT_INDEX));
                },
            },
            goBackButton: {
                label: 'Go Back',
                variant: 'outlined',
                color: 'inherit',
                onClick: () => {
                    id
                        ? dispatch(
                              goToRoute(routePaths.STUDENT_PROFILE_VIEW, {
                                  path: studentPaths.SUMMARY,
                                  id: id,
                              }),
                          )
                        : dispatch(goToRoute(routePaths.STUDENT_INDEX));
                },
                sx: {
                    fontSize: '14px',
                    fontWeight: '600',
                    color: 'black',
                    borderColor: 'rgba(0,0,0,0.23)',
                    mr: 2,
                    '&:hover': {
                        borderColor: 'rgba(0,0,0,0.23)',
                    },
                },
            },
            customGridSize: {
                journeyStepper: { desktop: 3, mobile: 12 },
                formBox: { desktop: 9, mobile: 12 },
            },
            defaultData: profileData ? getProfileSplit(profileData, dirtyFormData) : {},
        },
    };

    return data;
};

export const isB2bAdminEditable = (isB2bAdmin, id) => {
    if (isB2bAdmin) {
        if (id) return true;
    }
    return false;
};

export const isPartnerEditable = (isPartner, id) => {
    if (isPartner) {
        if (id) return true;
    }
    return false;
};

export default getMyProfileProps;
