import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '-webkit-fill-available',
        padding: theme.spacing(2, 3),
        border: (props) => (props.showBorder ? `1px solid #78A5FF` : 'none'),
        borderColor: (props) => {
            switch (props.borderColor) {
                case 'primary':
                    return theme.palette.primary;
                case 'secondary':
                    return theme.palette.secondary;
                case 'success':
                    return theme.palette.success;
                default:
                    return theme.palette.grey.border;
            }
        },
        borderRadius: (props) => (props.borderRadius ? props.borderRadius : '20px'),
        boxShadow: (props) =>
            props.raised !== undefined && props.raised ? theme.shadows[10] : 'none',
        backgroundColor: (props) =>
            props.backgroundColor !== undefined && props.backgroundColor
                ? props.backgroundColor
                : theme.palette.common.white,
        [theme.breakpoints.down('sm')]: {
            borderRadius: () => '0px',
            border: () => 'none',
            backgroundColor: () => 'transparent',
        },
    },
    textContainer: {
        width: '100%',
        textAlign: 'center',
    },
    buttonsContainer: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            bottom: 0,
            margin: 0,
            padding: theme.spacing(2.2, 0),
            justifyContent: 'space-around',
            boxShadow: theme.shadows[20],
            zIndex: 2,
            backgroundColor: theme.palette.common.white,
        },
    },
    previousButtonContainer: {
        width: theme.spacing(25),
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: { width: theme.spacing(20) },
    },
    submitButtonContainer: {
        width: theme.spacing(25),
        display: 'flex',
        justifyContent: (props) => (props.previousButton ? 'flex-start' : 'center'),
        [theme.breakpoints.down('sm')]: { width: theme.spacing(20) },
    },
    buttonWidth: {
        width: theme.spacing(22.5),
        fontSize: '14px',
        fontWeight: '600',
    },
    submitButton: {
        fontWeight: '600',
        backgroundColor: '#00C853',
        color: '#FFFF',
        '&:hover': {
            backgroundColor: '#3D8944',
        },
    },
    previousButton: {
        '&:hover': {
            backgroundColor: '#0008051a',
        },
    },
}));
