import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: {} };

const studentSlice = createSlice({
    name: 'students',
    initialState,
    reducers: {
        addStudentToStore(state, action) {
            state.data[action.payload.id] = action.payload;
        },
        updateStudentInStore(state, action) {
            state.data[action.payload.id] = {
                ...state.data[action.payload.id],
                [action.payload.key]: action.payload.value,
            };
        },
        resetStudentDataInStore(state) {
            state.data = initialState.data;
        },
    },
});

export const { addStudentToStore, updateStudentInStore, resetStudentDataInStore } =
    studentSlice.actions;
export default studentSlice.reducer;
