export default function parseJwt(token) {
    if (!token) {
        return;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}

export function getStudentInfoFromToken(token) {
    let infoFromToken = parseJwt(token);
    let studentInfo = {
        accessToken: token,
        id: infoFromToken.sub,
        roles: infoFromToken.payload.roles,
        root_node_id: infoFromToken.payload.parent_node_id,
        name: infoFromToken.payload.name,
    };
    return studentInfo;
}

export function getPartnerInfoFromToken(token) {
    let infoFromToken = parseJwt(token);
    let partnerInfo = {
        accessToken: token,
        id: infoFromToken.sub,
        roles: infoFromToken.payload.roles,
        root_node_id: infoFromToken.payload.parent_node_id,
        node_id: infoFromToken.payload.node_id,
        name: infoFromToken.payload.name,
    };
    return partnerInfo;
}

export function getIdFromAccessToken(token) {
    let infoFromToken = parseJwt(token);
    return infoFromToken.sub;
}
