import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, trim } from 'lodash';

// material
import Box from '@mui/material/Box';

// styles
import { useStyles } from './styles';

// comps
import { FormJourney } from 'azent-storybook/dist';
import Loader from '@/components/shared/Loader';
import ProfileProgressIndicator from '../progressIndicator';

// props
import getMyProfileProps, { checkProfileStepCompleted } from '@/props-constants/student-schema';

// actions
import * as navigationActions from '@/store/actions/navigationActions';
import { setToast } from '@/store/slices/toastSlice';

// utils
import routePaths, { studentPaths } from '@/utils/route-paths';
import { deserializeStudent } from '@/utils/deserializers/student-deserializer';
import { validateProfileDataByStep } from '@/utils/student';
import { toastMessages } from '@/utils/toastMessages';

// Apis
import { getProfile, getPartnerProfile } from '@/api';

// Rbac
import { isPartner, isSubAgent } from '@/rbac';

export default function StudentJourney() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const search = useSelector((state) => state.router.location.search);
    const globalData = useSelector((state) => state.globalData.data);
    const userData = useSelector((state) => state.userData.userData);
    const partnerData = useSelector((state) => state.partnerData.data);

    const params = new URLSearchParams(search);
    const studentId = params.get('id') || null;
    const currentIndex = Number(params.get('index')) || 0;

    const [isLoading, setIsLoading] = useState(true);
    const [dirtyFormData, setFormDirtyData] = useState(null);
    const [educationLevel, setEducationLevel] = useState(null);
    const [profileData, setProfileData] = useState({
        desired_profile: {},
        point_of_contact:
            isPartner() || isSubAgent()
                ? partnerData.point_of_contact
                    ? partnerData.point_of_contact
                    : {}
                : {},
    });
    const [intakeMonth, setIntakeMonth] = useState(null);
    const [copyNumber, setCopyNumber] = useState(null);
    const [referralPartnerId, setReferralPartnerId] = useState(null);
    const [targetCountries, setTargetCountries] = useState([]);

    const isProfileNotEmpty = (profileData) => {
        return Object.keys(profileData).filter((key) => key != 'desired_profile').length;
    };

    useEffect(() => {
        if (studentId && !isProfileNotEmpty(profileData)) {
            setIsLoading(true);
            getProfile(studentId)
                .then((res) => {
                    setProfileData(deserializeStudent(res.data));
                    setIsLoading(false);
                })
                // TODO: Handle error
                .catch(() => {
                    dispatch(setToast(toastMessages.backendIssue));
                });
        } else {
            setIsLoading(false);
        }
    }, []);

    const onChangeHandler = (dirtyFormData) => {
        setFormDirtyData(dirtyFormData);
    };

    // TODO change route

    const onJourneySuccess = () => {
        dispatch(
            navigationActions.goToRoute(routePaths.STUDENT_PROFILE_VIEW, {
                id: studentId,
                path: studentPaths.SUMMARY,
            }),
        );
    };
    const onSuccessCallback = async (responseData) => {
        const deserializedResponse = deserializeStudent(responseData);
        setProfileData(deserializedResponse);
        dispatch(
            navigationActions.goToRoute(
                routePaths.STUDENT_PROFILE_CREATE,
                {},
                { index: currentIndex + 1, id: deserializedResponse.id },
            ),
        );
    };

    // eslint-disable-next-line
    const checkStepCompleted = (submittedDetails, index) => {
        if (profileData) {
            if (!index) return validateProfileDataByStep(globalData, profileData, index);
            return checkProfileStepCompleted(submittedDetails, index);
        }
        return false;
    };

    const checkStepEnabled = (_data, index) => {
        if (index === 0) return true;
        if (validateProfileDataByStep(globalData, profileData, 0)) {
            return true;
        }
        return false;
    };

    const onStepChange = (index) => {
        if (profileData && profileData.id) {
            dispatch(
                navigationActions.goToRoute(
                    routePaths.STUDENT_PROFILE_CREATE,
                    {},
                    { index: index, id: profileData.id },
                ),
            );
        } else {
            dispatch(
                navigationActions.goToRoute(routePaths.STUDENT_PROFILE_CREATE, {}, { index: 0 }),
            );
        }
    };

    //create student form validate
    function validateForm(formData) {
        if (formData && formData.step == 0) {
            if (formData.alternate_mobile && formData.mobile) {
                if (formData.alternate_mobile == formData.mobile) {
                    dispatch(setToast(toastMessages.mobileValidateMessage));
                    return false;
                } else return true;
            }
        } else return true;
    }

    let myProfileProps = useMemo(() => {
        let data = null;
        data = getMyProfileProps(
            globalData,
            dirtyFormData,
            studentId,
            profileData,
            intakeMonth,
            dispatch,
            validateForm,
            referralPartnerId,
            targetCountries,
        );
        return data;
    }, [
        profileData,
        educationLevel,
        globalData,
        studentId,
        intakeMonth,
        copyNumber,
        referralPartnerId,
        targetCountries,
    ]);

    useEffect(() => {
        if (dirtyFormData) {
            let targetCountry = get(dirtyFormData, '0.desired_profile.interest_countries', null);
            let educationLevel = get(
                dirtyFormData,
                '1.education_history.highest_education_level',
                null,
            );
            let intakeMonth = get(dirtyFormData, '0.desired_profile.intake_month', null);
            let copyNumber = get(dirtyFormData, '0.point_of_contact.copy_number', null);
            let partnerId = get(dirtyFormData, '0.partner.id', null);
            setIntakeMonth(intakeMonth);
            setTargetCountries(targetCountry);
            setEducationLevel(educationLevel);
            setCopyNumber(copyNumber);
            if (!isPartner() && partnerId != referralPartnerId) {
                setReferralPartnerId(partnerId);
            } else if (isPartner()) setReferralPartnerId(userData.id);
        }
    }, [dirtyFormData]);

    let partner =
        dirtyFormData && dirtyFormData[0].partner && dirtyFormData[0].partner.id
            ? dirtyFormData[0].partner.id
            : null;
    const correctDependency = async (data) => {
        let partnerId = get(data, 'partner.id', null);
        const interest_countries = get(data, 'desired_profile.interest_countries', []);
        const interest_regions = get(data, 'desired_profile.interest_regions', []);

        if (!isPartner() && partnerId != referralPartnerId && data.sub_agent)
            data.sub_agent = undefined;
        let poc = {};
        if (!isPartner() && data && data.partner && data.partner.id && data.partner.id != partner) {
            try {
                partner = data.partner.id;
                let resp = await getPartnerProfile(data.partner.user_id);
                poc = resp.data.point_of_contact;
            } catch (err) {
                console.log('something went wrong', err);
            } finally {
                data.point_of_contact = poc;
            }
        } else if (!isPartner() && !isSubAgent() && !(data && data.partner && data.partner.id)) {
            partner = null;
            data.point_of_contact = poc;
        }
        if (interest_countries.length && interest_regions.length) {
            let newRegions = interest_regions.reduce((acc, item) => {
                if (item.country && interest_countries.includes(trim(item.country))) {
                    acc.push(item);
                    return acc;
                } else return acc;
            }, []);
            data.desired_profile.interest_regions = newRegions;
        }
        return { data: data };
    };

    const FormJourneyComponent = useMemo(() => {
        let data = null;
        if (myProfileProps && myProfileProps.journey) {
            data = (
                <Box className={classes.formJourneyContainer}>
                    <FormJourney
                        key="form-journey-component"
                        {...myProfileProps.journey}
                        currentIndex={currentIndex}
                        onMobileBackNavigation={() => {}}
                        onJourneySuccess={onJourneySuccess}
                        onSuccessCallback={onSuccessCallback}
                        checkStepCompleted={checkStepCompleted}
                        checkStepEnabled={checkStepEnabled}
                        onChangeHandler={onChangeHandler}
                        onStepChange={onStepChange}
                        correctDependency={correctDependency}
                    />
                </Box>
            );
        }
        return data;
    }, [myProfileProps]);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Box>
                    <ProfileProgressIndicator
                        profileCompletionPercentage={parseInt(
                            profileData.profile_completion_percentage || 0,
                        )}
                    />
                    <Box m={2} mr={10}>
                        {FormJourneyComponent}
                    </Box>
                </Box>
            )}
        </>
    );
}
