import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// comps
import { FormModal, getIcon } from 'azent-storybook/dist';

// utils
import {
    createApplicationSchema,
    createApplicationUiSchema,
} from '@/props-constants/course-finder';
import { deserializeStudent } from '@/utils/deserializers/student-deserializer';
import routePaths, { studentPaths } from '@/utils/route-paths';
import { isEmpty } from 'lodash';
import { serializePriorityData } from '@/props-constants/application/create-application';

// styles
import { useStyles } from './styles';

// api
import { createApplication, getProfile } from '@/api';

// actions
import { addStudentToStore } from '@/store/slices/studentSlice';
import { goToRoute } from '@/store/actions/navigationActions';

export default function ApplyModal({
    open,
    setOpen,
    course,
    profile_id,
    program_id,
    priorityData,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [intakeMonth, setIntakeMonth] = React.useState(null);
    const [intakeType, setIntakeType] = React.useState(null);
    const [disableButton, setDisableButton] = React.useState(false);

    const programFee =
        course.costs && (course.costs.application_fee || course.costs.application_fee == 0)
            ? {
                  fee: course.costs.application_fee,
                  currency: course.costs.currency,
              }
            : course.program_info &&
              course.program_info.application_fee &&
              course.program_info.application_fee == 0
            ? {
                  fee: course.program_info.application_fee,
                  currency: '',
              }
            : course.program_info && course.program_info.application_fee
            ? {
                  fee: course.program_info.application_fee,
                  currency: '',
              }
            : null;
    const universityLocation =
        course.university && course.university.country
            ? course.university.country
            : course.university_info &&
              course.university_info.address &&
              course.university_info.address.country
            ? course.university_info.address.country
            : null;

    const onClickCreateApplication = (data) => {
        const payload = {
            program_id: program_id,
            profile_id: profile_id,
            intake: {
                month: data.intake.month,
                year: data.intake.year,
            },
            program_info: {
                program_id: program_id,
                name: course.program_info && course.program_info.name,
                university_name: course.university_info && course.university_info.name,
                university_id: course.university_info && course.university_info.id,
            },
            account_type: 'B2B',
            documents: [], //not sending sop
            preference: serializePriorityData(data.preference),
        };

        return createApplication(payload);
    };

    const onSuccessCallback = (application) => {
        getProfile(profile_id).then(
            (res) => dispatch(addStudentToStore(deserializeStudent(res.data))),
            dispatch(
                goToRoute(routePaths.STUDENT_PROFILE_VIEW, {
                    id: profile_id,
                    path: studentPaths.APPLICATION,
                    aid: application._id,
                }),
            ),
        );
    };

    const schema = useMemo(() => {
        if (course.intake_months && course.intake_months.length > 0)
            return createApplicationSchema(course.intake_months, intakeMonth, priorityData);
        else if (course.intakes && course.intakes.length > 0)
            return createApplicationSchema(course.intakes, intakeMonth, priorityData);
        else return createApplicationSchema(null, intakeMonth, priorityData);
    }, [course, intakeMonth, priorityData]);

    const uiSchema = useMemo(() => {
        return createApplicationUiSchema(universityLocation, programFee, intakeType);
    }, [universityLocation, programFee, intakeType]);

    let onChangeApp = function (formdata) {
        if (!isEmpty(formdata)) {
            if (
                formdata &&
                formdata.intake &&
                formdata.intake.month &&
                formdata.intake.month != intakeMonth
            ) {
                setIntakeMonth(formdata.intake.month);
            }
            if (formdata && formdata.intake && formdata.intake.month && formdata.intake.year) {
                const intakeTypes =
                    course &&
                    course.intakeDetails &&
                    course.intakeDetails.find(
                        (intake) =>
                            intake.intake_name ===
                            `${formdata.intake.month} ${formdata.intake.year}`,
                    );
                if (intakeTypes) {
                    if (intakeTypes.accepting_application) {
                        setIntakeType('Open');
                        setDisableButton(false);
                    } else {
                        setIntakeType('Closed');
                        setDisableButton(true);
                    }
                } else {
                    setIntakeType('Not Open Yet');
                    setDisableButton(false);
                }
            }
        }
    };

    return (
        <FormModal
            {...{
                open,
                defaultData: {},
                schema: schema,
                uiSchemas: {
                    smallScreenUiSchema: uiSchema,
                    largeScreenUiSchema: uiSchema,
                },
                classes: {
                    cModalTitleContainer: classes.cModalTitleContainer,
                    cModalTitle: classes.cModalTitle,
                },
                modalTitle: {
                    title: 'Create an application',
                },
                onChangeApp: onChangeApp,
                modalTitleStartIcon: getIcon('person', 'primary', 'small', classes.modalTitleIcon),
                isB2bFormModal: true,
                submitButton: {
                    fullWidth: false,
                    label: 'Create Application',
                    variant: 'contained',
                    size: 'medium',
                    onClick: onClickCreateApplication,
                    disabled: disableButton,
                },
                onSuccessCallback,
                handleClose: () => setOpen(null),
            }}
        />
    );
}

ApplyModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    course: PropTypes.object,
    profile_id: PropTypes.string,
    program_id: PropTypes.string,
    priorityData: PropTypes.array,
};
