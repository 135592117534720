import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch } from 'react-redux';

// mui
import { Box, Typography, Grid, Checkbox, FormGroup, FormControlLabel } from '@mui/material';

// styles
import { useStyles } from './styles';

// comps
import { FormBox, ActionBox, getIcon, DocumentPreview, ColorCard } from 'azent-storybook/dist';
import FormattedComments from './partials/formattedComment';

import { getPlagiarismPreviewHTML } from '@/api/plagiarism';
import { goToExternalRoute } from '@/store/actions/navigationActions';
import ROUTE_PATHS from '@/utils/route-paths';

export default function Comment({
    addAttachment,
    setAddAttachment,
    comments,
    addComment,
    enableAttachement,
    enablePrivateComment,
}) {
    const dispatch = useDispatch();
    const commentSection = useRef(null);

    const commentsList = useMemo(() => {
        let commentsData = [...comments];
        commentsData = commentsData.sort((a, b) =>
            moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1,
        );
        return commentsData;
    }, [comments]);

    const commentSuccess = (data, _formData, _formConstants, setData) => {
        addComment.onSuccessCallback(data, setData);
        if (commentSection) commentSection.current.scrollTo(0, 0);
    };

    const classes = useStyles();

    return (
        <Box className={classes.commentSectionContainer}>
            <Box ref={commentSection} className={classes.commentSection}>
                {!commentsList || commentsList.length == 0 ? (
                    <ActionBox
                        subtitle={{
                            text: 'No comments found',
                            variant: 'subtitle1',
                        }}
                        raised={false}
                        elevation={0}
                    />
                ) : (
                    commentsList.map((comment, index) => (
                        <Box key={index} pb={3}>
                            <Box display={'flex'}>
                                <Typography color="primary" variant="subtitle1">
                                    {comment.created_by__name
                                        ? comment.created_by__name
                                        : comment.created_by}
                                </Typography>
                                {comment.private && (
                                    <ColorCard
                                        key={index}
                                        title={'Private'}
                                        classes={{
                                            container: classes.privateCommentChip,
                                            title: classes.titlePrivateComment,
                                        }}
                                    />
                                )}
                            </Box>

                            <Box>
                                <Box className={classes.commentCard}>
                                    <FormattedComments
                                        comment={comment.comment}
                                        classes={classes}
                                        index={index}></FormattedComments>
                                </Box>
                                <Grid container>
                                    <Grid spacing={0.5} item container xs={10}>
                                        {comment.files &&
                                            comment.files.map((file, index) => (
                                                <Grid key={file.objectId || index} item xs={6}>
                                                    <DocumentPreview
                                                        url={file.url}
                                                        fileName={file.original_file_name}
                                                        type={file.type}
                                                        classes={{
                                                            resourceClass: classes.img,
                                                            outlinedFileIcon: classes.fileIcon,
                                                            outlinedFileIconContainer:
                                                                classes.fileIconContainer,
                                                            copyIcon: classes.copyIcon,
                                                        }}
                                                        getPlagiarismPreviewHTML={
                                                            getPlagiarismPreviewHTML
                                                        }
                                                        file={file}
                                                        onFileClick={() => {
                                                            dispatch(
                                                                goToExternalRoute(
                                                                    '',
                                                                    ROUTE_PATHS.DOCUMENT_PREVIEW,
                                                                    {},
                                                                    {
                                                                        url: file.url,
                                                                        type: file.type,
                                                                        fileName:
                                                                            file.original_file_name,
                                                                    },
                                                                    true,
                                                                ),
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                            ))}
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography className={classes.timeText}>
                                            <em>
                                                {moment
                                                    .utc(comment.created_at)
                                                    .local()
                                                    .format('DD/MM/YYYY HH:mm')}
                                            </em>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    ))
                )}
            </Box>
            {enablePrivateComment && (
                <Box className={classes.privateCommentContainer}>
                    <FormGroup>
                        <FormControlLabel
                            className={classes.enablePrivateTitle}
                            control={
                                <Checkbox
                                    checked={addComment.isPrivateComment}
                                    onChange={() => {
                                        addComment.setPrivateComment(!addComment.isPrivateComment);
                                    }}
                                    className={classes.enablePrivateCheckBox}
                                />
                            }
                            label="Keep it Private"
                        />
                    </FormGroup>
                </Box>
            )}
            <Grid container>
                <Grid item xs={enableAttachement ? 11 : 12}>
                    <FormBox
                        schema={addComment.schema}
                        uiSchemas={{
                            smallScreenUiSchema: {},
                            largeScreenUiSchema: addComment.commentsUiSchema,
                        }}
                        defaultData={addComment.defaultData}
                        styleProps={{ noPadding: true }}
                        submitButton={addComment.submitButton}
                        uploadConfig={addComment.uploadConfig}
                        onSuccessCallback={commentSuccess}
                        showPreview={false}
                    />
                </Grid>
                {enableAttachement && (
                    <Grid item xs={1} pt={5} display={'flex'} justifyContent={'center'}>
                        <Box
                            onClick={() => setAddAttachment(!addAttachment)}
                            className={classes.attachmentButton}>
                            <Typography className={classes.attachmentIcon} px={0} mx={0}>
                                {getIcon('attach-file')}
                            </Typography>
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}

Comment.propTypes = {
    comments: PropTypes.array.isRequired,
    addAttachment: PropTypes.bool,
    setAddAttachment: PropTypes.func,
    addComment: PropTypes.shape({
        schema: PropTypes.object.isRequired,
        commentsUiSchema: PropTypes.object.isRequired,
        defaultData: PropTypes.object.isRequired,
        submitButton: PropTypes.object.isRequired,
        uploadConfig: PropTypes.object.isRequired,
        onSuccessCallback: PropTypes.func.isRequired,
        setPrivateComment: PropTypes.func,
        isPrivateComment: PropTypes.bool,
    }).isRequired,
    enableAttachement: PropTypes.bool,
    enablePrivateComment: PropTypes.bool,
};

Comment.defaultProps = {
    enableAttachement: true,
    enablePrivateComment: false,
};
