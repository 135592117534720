import React from "react";
import PropTypes from "prop-types";

// mui
import {
  Tabs as MuiTabs,
  Tab,
  Grid,
  Box,
  Paper,
  useMediaQuery,
  useTheme,
  styled,
} from "@mui/material";

// styles
import { useStyles } from "./style";

const AzentTabs = styled((props) => (
  <MuiTabs
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    display: "none",
  },
});

const AzentTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.spacing(62.5),
    fontSize: theme.spacing(1.75),
    marginRight: theme.spacing(1),
    display: 'inline-flex!important',
    paddingTop:theme.spacing(0),
    paddingLeft:theme.spacing(1),
    paddingBottom:theme.spacing(0),
    color: theme.palette.text.disabled,
    textAlign: "left!important",
    alignItems: 'flex-start',
    justifyContent:"unset",
    minHeight:theme.spacing(4),
    "&.Mui-selected": {
      color: "#f16621",
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.spacing(1.75),
        height: "32px",
        minHeight:"32px",
        background: "#F16621",
        borderRadius: "16px",
        color: "white",
      },
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: theme.spacing(1.75),
        height: "32px",
        minHeight:"32px",
        borderRadius: "16px",
        color: "F16621",
      },
  })
);

export const Tabs = ({
  value,
  setValue,
  tabList,
  tabContainerProps,
  tabsProps,
  tabProps,
  orientation,
  classes,
  verticalGridSize,
}) => {
  const _classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (orientation === "vertical" && !isMobile) {
    return (
      <Paper
        elevation={0}
        {...tabContainerProps}
        className={`${_classes.container} ${classes.container}`}
      >
        <Grid container>
          <Grid item xs={verticalGridSize.tab}>
            <AzentTabs
              orientation={orientation}
              value={value}
              onChange={handleChange}
              {...tabsProps}
              className={`${classes.tabs}`}
            >
              {tabList &&
                tabList.map(({ label }, index) => (
                  <AzentTab
                    key={index}
                    label={label}
                    {...tabProps}
                    className={`${classes.tab} ${_classes.tabs}`}
                  />
                ))}
            </AzentTabs>
          </Grid>
          <Grid item xs={verticalGridSize.content}>
            <Box
              className={`${_classes.contentContainer} ${classes.contentContainer}`}
            >
              {tabList && tabList[value].content()}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    );
  }
  return (
    <Paper
      elevation={0}
      {...tabContainerProps}
      className={`${_classes.container} ${classes.container}`}
    >
      <AzentTabs
        variant="fullWidth"
        centered
        value={value}
        onChange={handleChange}
        {...tabsProps}
        className={`${classes.tabs}`}
      >
        {tabList &&
          tabList.map(({ label }, index) => (
            <AzentTab
              key={index}
              label={label}
              {...tabProps}
              className={`${classes.tab}`}
            />
          ))}
      </AzentTabs>
      <Box
        className={`${_classes.contentContainer} ${classes.contentContainer}`}
      >
        {tabList && tabList[value].content()}
      </Box>
    </Paper>
  );
};

Tabs.propTypes = {
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired,
  tabList: PropTypes.array.isRequired,
  tabContainerProps: PropTypes.object,
  tabsProps: PropTypes.object,
  tabProps: PropTypes.object,
  classes: PropTypes.object,
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
  verticalGridSize: PropTypes.shape({
    tab: PropTypes.number.isRequired,
    content: PropTypes.number.isRequired,
  }),
};

Tabs.defaultProps = {
  tabContainerProps: {},
  tabsProps: {},
  tabProps: {},
  classes: {},
  verticalGridSize: {
    tab: 3,
    content: 9,
  },
};
