import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//mui
import { Grid, Box, Typography, TextField, Button, Autocomplete } from '@mui/material';

//utils
import Loader from '@/components/shared/Loader';
import routePaths from '@/utils/route-paths';
import { toastMessages } from '@/utils/toastMessages';
import { setToast } from '@/store/slices/toastSlice';
import { removeAuthToken } from '@/utils/auth-token-utils';

// navigation action
import { goToRoute, setQuery } from '@/store/actions/navigationActions';

//style
import { useStyles } from './style';

//components
import ImageCarousel from './imageCarousel';

// api
import { createUser } from '@/utils/user/authUser';
import { getCityPlacesCustom, getStatePlacesCustom } from '@/api';

const defaultCityList = [
    { name: 'Ahmedabad' },
    { name: 'Bangalore' },
    { name: 'Chennai' },
    { name: 'Delhi' },
    { name: 'Hyderabad' },
    { name: 'Pune' },
    { name: 'Mumbai' },
    { name: 'Surat' },
    { name: 'Thane' },
    { name: 'Vadodara' },
    { name: 'Vijayawada' },
];
const defaultStateList = [
    { name: 'Maharashtra' },
    { name: 'Gujarat' },
    { name: 'Tamil Nadu' },
    { name: 'Telangana' },
    { name: 'Karnataka' },
    { name: 'Delhi' },
    { name: 'Tamil Nadu' },
    { name: 'West Bengal' },
];

//this is registration page
export default function SignUp() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const search = useSelector((state) => state.router.location.search);

    const [loading, setLoading] = React.useState(false);
    let [serverErrorMessage, setServerErrorMessage] = React.useState('');
    let [values, setValues] = React.useState({
        fname: '',
        lname: '',
        email: '',
        phone: '',
    });
    let [errorTexts, setErrorTexts] = React.useState({
        fname: '',
        lname: '',
        email: '',
        phone: '',
        city: '',
        state: '',
    });
    const [cityPlaces, setCityPlaces] = React.useState(defaultCityList);
    const [stateNames, setStateNames] = React.useState(defaultStateList);
    const [city, setCity] = React.useState('');
    const [cityInputVal, setCityInputVal] = React.useState('');
    const [state, setState] = React.useState('');
    const [stateInputVal, setStateInputVal] = React.useState('');

    const numbersCheck = new RegExp('^[0-9]+$|^$');

    useEffect(() => {
        dispatch(setQuery('partnerNew', true));
    }, []);

    useEffect(() => {
        removeAuthToken();
    }, []);

    const validateEmail = (email) => {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email,
        );
    };

    const handleValidations = () => {
        let validations = {
            fname: '',
            lname: '',
            phone: '',
            email: '',
            city: '',
            state: '',
        };

        const { fname, lname, phone, email } = values;

        if (phone.length < 10 || phone.length > 10) {
            validations.phone = 'Mobile Number Should Be 10 digits only';
        }
        if (phone.length === 0) {
            validations.phone = 'Please Enter Your Mobile Number';
        }
        if (isNaN(phone)) {
            validations.phone = 'Please Enter Valid Mobile Number';
        }
        if (fname.length === 0) {
            validations.fname = 'Please Enter Your First Name';
        }
        if (lname.length === 0) {
            validations.lname = 'Please Enter Your Last Name';
        }
        if (email.length === 0) {
            validations.email = 'Please Enter Your Email Id';
        }
        if (!isNaN(email)) {
            validations.email = 'Email Id Should Be Valid';
        }
        if (isNaN(email) && !validateEmail(email)) {
            validations.email = 'Email Id Should Be Valid';
        }
        if (!city || (city && city.length === 0)) {
            validations.city = 'Please choose your city';
        }
        if (!state || (state && state.length === 0)) {
            validations.state = 'Please choose your state';
        }
        setErrorTexts(validations);
        if (
            validations.fname ||
            validations.lname ||
            validations.email ||
            validations.phone ||
            validations.city ||
            validations.state
        ) {
            return false;
        }
        return true;
    };

    const handleSubmit = async () => {
        setLoading(true);
        setServerErrorMessage('');
        let createPartnerResult = {};

        if (handleValidations()) {
            const { fname, lname, phone, email } = values;
            createPartnerResult = await createUser({
                first_name: fname,
                last_name: lname,
                email: email,
                mobile: phone,
                city: city.name,
                state: state.name,
            });
            if (createPartnerResult) {
                if (createPartnerResult.success == false) {
                    setServerErrorMessage(createPartnerResult.error.message);
                } else if (createPartnerResult.ok == false) {
                    setServerErrorMessage(createPartnerResult.message);
                } else if (
                    createPartnerResult.data &&
                    createPartnerResult.data.success &&
                    createPartnerResult.data.success == true
                ) {
                    dispatch(goToRoute(`${routePaths.SET_PASSWORD}${search}`));
                } else {
                    setServerErrorMessage(toastMessages.backendIssue.message);
                }
            } else {
                setServerErrorMessage(toastMessages.backendIssue.message);
            }
        }
        setLoading(false);
    };

    const handleGetCities = (value) => {
        getCityPlacesCustom(value)
            .then((res) => {
                setCityPlaces(res.data);
            })
            .catch(() => dispatch(setToast(toastMessages.backendIssue)));
    };

    const handleGetStates = (value) => {
        getStatePlacesCustom(value)
            .then((res) => {
                setStateNames(res.data);
            })
            .catch(() => dispatch(setToast(toastMessages.backendIssue)));
    };

    return (
        <Box className={classes.rootContainer}>
            {loading ? (
                <Loader />
            ) : (
                <Grid container alignItems="center">
                    <Grid item xs={5}>
                        <ImageCarousel classes={classes} />
                    </Grid>
                    <Grid item xs={2}>
                        <div className={classes.verticalLine}></div>
                    </Grid>
                    <Grid item xs={5} pl={4}>
                        <Box className={classes.formHeaderContainer} mb={1}>
                            <Typography className={classes.azentTitle}>
                                Referral Partner Registration
                            </Typography>
                        </Box>
                        <Box className={classes.formContentContainer}>
                            {serverErrorMessage ? (
                                <Box mt={2} mb={2} textAlign="center">
                                    <Typography color="error">{serverErrorMessage}</Typography>
                                </Box>
                            ) : null}
                            <Box
                                className={classes.fieldContainer}
                                mb={errorTexts['fname'] ? 4 : 2}>
                                <Typography className={classes.inputTitle}>First Name*</Typography>
                                <TextField
                                    className={classes.textFieldRoot}
                                    value={values['fname']}
                                    name="fname"
                                    autoFocus
                                    onChange={(event) => {
                                        setValues({
                                            ...values,
                                            fname: event.target.value,
                                        });
                                    }}
                                    helperText={errorTexts['fname'] ? errorTexts['fname'] : null}
                                    error={errorTexts['fname'] ? true : false}
                                    variant="outlined"
                                />
                            </Box>
                            <Box
                                className={classes.fieldContainer}
                                mb={errorTexts['lname'] ? 4 : 2}>
                                <Typography className={classes.inputTitle}>Last Name*</Typography>
                                <TextField
                                    className={classes.textFieldRoot}
                                    value={values['lname']}
                                    name="lname"
                                    onChange={(event) => {
                                        setValues({
                                            ...values,
                                            lname: event.target.value,
                                        });
                                    }}
                                    helperText={errorTexts['lname'] ? errorTexts['lname'] : null}
                                    error={errorTexts['lname'] ? true : false}
                                    variant="outlined"
                                />
                            </Box>
                            <Box
                                className={classes.fieldContainer}
                                mb={errorTexts['email'] ? 4 : 2}>
                                <Typography className={classes.inputTitle}>Email Id*</Typography>
                                <TextField
                                    className={classes.textFieldRoot}
                                    value={values['email']}
                                    name="email"
                                    onChange={(event) => {
                                        setValues({
                                            ...values,
                                            email: event.target.value,
                                        });
                                    }}
                                    helperText={errorTexts['email'] ? errorTexts['email'] : null}
                                    error={errorTexts['email'] ? true : false}
                                    variant="outlined"
                                />
                            </Box>
                            <Box
                                className={classes.fieldContainer}
                                mb={errorTexts['phone'] ? -2 : 0}>
                                <Typography className={classes.inputTitle}>
                                    Phone Number*
                                </Typography>
                                <TextField
                                    className={classes.phonePrefix}
                                    value={'+91'}
                                    name="prefix"
                                    variant="outlined"
                                    disabled
                                />
                                <TextField
                                    sx={{ width: '80%' }}
                                    value={values['phone']}
                                    name="phone"
                                    onChange={(event) => {
                                        if (numbersCheck.test(event.target.value))
                                            setValues({
                                                ...values,
                                                phone: event.target.value,
                                            });
                                    }}
                                    helperText={errorTexts['phone'] ? errorTexts['phone'] : null}
                                    error={errorTexts['phone'] ? true : false}
                                    variant="outlined"
                                    inputProps={{
                                        maxLength: 10,
                                    }}
                                />
                            </Box>
                            <Box
                                className={classes.fieldContainer}
                                mb={errorTexts['city'] ? -2 : 0}>
                                <Typography className={classes.inputTitle}>City*</Typography>
                                <Autocomplete
                                    disableClearable
                                    sx={{ width: '100%' }}
                                    value={city}
                                    options={cityPlaces}
                                    autoHighlight
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                            {...props}>
                                            {option.name}
                                        </Box>
                                    )}
                                    getOptionLabel={(option) => option.name || ''}
                                    inputValue={cityInputVal}
                                    onChange={(event, value) => {
                                        setCity(value);
                                    }}
                                    onInputChange={(event, value) => {
                                        setCityInputVal(value);
                                        handleGetCities(value);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label=""
                                            helperText={
                                                errorTexts['city'] ? errorTexts['city'] : null
                                            }
                                            error={errorTexts['city'] ? true : false}
                                            variant="outlined"
                                        />
                                    )}
                                />
                            </Box>
                            <Box className={classes.fieldContainer} mb={3}>
                                <Typography className={classes.inputTitle}>State*</Typography>
                                <Autocomplete
                                    disableClearable
                                    sx={{ width: '100%' }}
                                    options={stateNames}
                                    value={state}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name || ''}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                            {...props}>
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label=""
                                            helperText={
                                                errorTexts['state'] ? errorTexts['state'] : null
                                            }
                                            error={errorTexts['state'] ? true : false}
                                            variant="outlined"
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        setState(value);
                                    }}
                                    inputValue={stateInputVal}
                                    onInputChange={(event, value) => {
                                        setStateInputVal(value);
                                        handleGetStates(value);
                                    }}
                                />
                            </Box>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.greenButton}
                                onClick={() => handleSubmit()}>
                                Next
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}
