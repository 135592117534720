import React from 'react';
import PropTypes from 'prop-types';

// mui
import { Typography, Grid, Box, Tooltip, tooltipClasses } from '@mui/material';
import { Skeleton } from '@mui/lab';
import { styled } from '@mui/material/styles';

// comps
import { getIcon, Button } from 'azent-storybook/dist';

// styles
import { useStyles } from './styles';

//utils
import { toastMessages } from '@/utils/toastMessages';
import { getIntakeType } from '@/utils/courseFinder';

export const CourseCard = ({
    courseName,
    university,
    tuitionCost,
    loading,
    intakes,
    intakeDetails,
    color,
    onClickName,
    onClickAddToShortlist,
    onClickApplyNow,
    isShortlisted,
    isApplied,
    duration,
    noActionButton,
    disableButtons,
    applicationFee,
    programLink,
    isDisableCreateApplication,
}) => {
    const classes = useStyles();
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            borderRadius: '0px',
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));
    const intakesCard = (intakes, loading) => {
        if (loading) {
            return (
                <Typography variant="subtitle1" className={classes.cardProperty}>
                    <Skeleton animation="wave" width={100} />
                </Typography>
            );
        }
        return (
            <div style={{ display: 'flex', maxWidth: '100%', flexWrap: 'wrap' }}>
                {renderIntake(intakes)}
            </div>
        );
    };
    const renderIntake = (data) => {
        return data.map((intake) => (
            <LightTooltip
                key={intake}
                title={getIntakeType(intake, intakeDetails) ? 'Intake Open' : ' Not Open Yet'}>
                <div
                    className={
                        getIntakeType(intake, intakeDetails)
                            ? classes.activeIntakeCard
                            : classes.intakeCard
                    }>
                    <Typography variant="subtitle1">{intake}</Typography>
                </div>
            </LightTooltip>
        ));
    };
    // NOTE: Phase - 2, hide it now
    // const onClickUniversity = (e) => {
    //     let url = 'https://azent.com/course-finder';
    //     slugUrl && (url = url + '/universities/' + slugUrl);
    //     window.open(url, '_blank');
    //     e.stopPropagation();
    // };

    const onApplyHandler = (e) => {
        e.stopPropagation();
        if (isDisableCreateApplication || disableButtons) return;
        else {
            onClickApplyNow();
        }
    };

    return (
        <Box
            p={2}
            sx={{ cursor: 'pointer' }}
            onClick={() => !loading && onClickName && onClickName()}
            className={classes.courseCard}>
            <Grid mb={0.5} container>
                <Grid item xs={1} />
                <Grid item xs={5}>
                    {/* NOTE: Told to not show applicationDeadline for now */}
                    <Typography color="primary.main" variant="h6">
                        {loading ? (
                            <Skeleton animation="wave" />
                        ) : applicationFee && applicationFee != 0 ? (
                            `Application Fee : ${applicationFee}`
                        ) : applicationFee == 0 && applicationFee ? (
                            'Application Fee : Waived Off'
                        ) : null}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid display="flex" justifyContent={'center'} item xs={1}>
                    <Box
                        sx={{
                            borderRadius: '50%',
                            height: '40px',
                            width: '40px',
                            background: color,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5">
                        {loading ? (
                            <Skeleton animation="wave" />
                        ) : programLink ? (
                            <a
                                href={programLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.programLink}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}>
                                {courseName}
                            </a>
                        ) : (
                            courseName
                        )}
                    </Typography>
                </Grid>
                {!noActionButton && (
                    <Grid justifyContent={'flex-end'} item container xs={5}>
                        <Grid px={1} item xs={6}>
                            {isShortlisted && (
                                <Button
                                    sx={{
                                        borderRadius: 1,
                                        '&.Mui-disabled': {
                                            pointerEvents: 'auto',
                                        },
                                    }}
                                    component={disableButtons ? 'div' : undefined}
                                    fullWidth={true}
                                    label={isShortlisted ? 'Shortlisted' : 'Add to Shortlist'}
                                    variant="outlined"
                                    color="primary"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        !isShortlisted && onClickAddToShortlist();
                                    }}
                                    disabled={loading || disableButtons || isShortlisted}
                                />
                            )}
                            {!isShortlisted && (
                                <Tooltip
                                    title={
                                        disableButtons
                                            ? 'Please Select A Student!'
                                            : 'Add to Shortlist!'
                                    }>
                                    <Button
                                        sx={{
                                            borderRadius: 1,
                                            '&.Mui-disabled': {
                                                pointerEvents: 'auto',
                                            },
                                        }}
                                        component={disableButtons ? 'div' : undefined}
                                        fullWidth={true}
                                        label={isShortlisted ? 'Shortlisted' : 'Add to Shortlist'}
                                        variant="outlined"
                                        color="primary"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            !isShortlisted && onClickAddToShortlist();
                                        }}
                                        disabled={loading || disableButtons || isShortlisted}
                                    />
                                </Tooltip>
                            )}
                        </Grid>
                        <Grid item px={1} xs={6}>
                            <Tooltip
                                title={
                                    disableButtons
                                        ? 'Please Select A Student!'
                                        : isDisableCreateApplication
                                        ? toastMessages.applicationLimit.message
                                        : 'Apply Now'
                                }>
                                <Button
                                    sx={{
                                        borderRadius: 1,
                                        '&.Mui-disabled': {
                                            pointerEvents: 'auto',
                                        },
                                    }}
                                    fullWidth={true}
                                    label={isApplied ? 'Applied' : 'Apply Now'}
                                    variant="outlined"
                                    color="success"
                                    component={
                                        disableButtons || isDisableCreateApplication
                                            ? 'div'
                                            : undefined
                                    }
                                    onClick={(e) => onApplyHandler(e)}
                                    disabled={
                                        loading ||
                                        disableButtons ||
                                        isApplied ||
                                        isDisableCreateApplication
                                    }
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid container>
                <Grid item xs={1}>
                    <Box sx={{ height: '40px', width: '40px' }} />
                </Grid>
                <Grid item xs={4} className={classes.item}>
                    {getIcon('account-balance', 'inherit', 'small', classes.cardIcons)}
                    <Box
                        className={classes.linkProperty}
                        display="flex"
                        justifyContent="flex-end"
                        // NOTE: TOLD to hide this functionality
                        // onClick={(e) => onClickUniversity(e)}
                    >
                        {loading ? (
                            <Skeleton animation="wave" />
                        ) : (
                            (university && university.name) || '-'
                        )}
                    </Box>
                </Grid>
                <Grid item xs={4} className={classes.item}>
                    {getIcon('location-on', 'inherit', 'small', classes.cardIcons)}
                    <Typography variant="subtitle1" className={classes.cardProperty}>
                        {loading ? (
                            <Skeleton animation="wave" />
                        ) : (
                            (university && university.country) || '-'
                        )}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={classes.item}>
                    {getIcon('date-range', 'inherit', 'small', classes.cardIcons)}
                    <div className={classes.intakeCardsProperty}>
                        {intakesCard(intakes, loading)}
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={1}>
                    <Box sx={{ height: '40px', width: '40px' }} />
                </Grid>
                <Grid item xs={4} className={classes.item}>
                    {getIcon('monetization-on', 'inherit', 'small', classes.cardIcons)}
                    <Typography variant="subtitle1" className={classes.cardProperty}>
                        {loading ? <Skeleton animation="wave" /> : tuitionCost || '-'}
                    </Typography>
                </Grid>
                <Grid item xs={4} className={classes.item}>
                    {getIcon('trending-up', 'inherit', 'small', classes.cardIcons)}
                    <Typography variant="subtitle1" className={classes.cardProperty}>
                        {loading ? (
                            <Skeleton animation="wave" />
                        ) : (
                            (university && university.rank) || '-'
                        )}
                    </Typography>
                </Grid>
                <Grid item xs={3} className={classes.item}>
                    {getIcon('schedule', 'inherit', 'small', classes.cardIcons)}
                    <Typography variant="subtitle1" className={classes.cardProperty}>
                        {loading ? <Skeleton animation="wave" /> : duration || '-'}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

CourseCard.propTypes = {
    courseName: PropTypes.string,
    university: PropTypes.object,
    tuitionCost: PropTypes.string,
    loading: PropTypes.bool,
    intakes: PropTypes.array,
    intakeDetails: PropTypes.array,
    color: PropTypes.string,
    onClickName: PropTypes.func,
    onClickAddToShortlist: PropTypes.func,
    redirectToShortlist: PropTypes.func,
    onClickApplyNow: PropTypes.func,
    isShortlisted: PropTypes.bool,
    isApplied: PropTypes.bool,
    duration: PropTypes.string,
    applicationDeadline: PropTypes.string,
    noActionButton: PropTypes.bool,
    disableButtons: PropTypes.bool,
    applicationFee: PropTypes.string,
    programLink: PropTypes.string,
    isDisableCreateApplication: PropTypes.bool,
};

CourseCard.defaultProps = {
    onClickAddToShortlist: () => {
        console.log('default prop called');
    },
    onClickApplyNow: () => {
        console.log('default prop called');
    },
    isShortlisted: false,
    isApplied: false,
    noActionButton: false,
};
