import { startCase } from 'lodash';
// Component library
import { getIcon } from 'azent-storybook/dist';

// api
import {
    getProfiles,
    getUniversities,
    getPartners,
    universitySearch,
    getOwners,
    getPrograms,
    getPSAUser,
    searchSubAgent,
} from '@/api';

// utils
import {
    applicationStatus,
    getLabelForApplicationStatus,
    getApplicationSubstatusEnum,
    subStatusChoices,
    APPLICATION_SUBMITTED,
    extraKeysOption,
} from '@/props-constants/application/application-status';
import { modifiedByList, getModifiedByLabel } from '@/utils/modifiedBy';

// rbac
import { isPartner, isPSA, isSubAgent } from '@/rbac';

const currentYear = new Date().getFullYear();
export const filterMsg = {
    default: 'Please click on Create Application button',
    filters: 'No entry found! Please remove a few filters',
};

export const getFilterProps = (isB2bAdmin, onApplyFilters, config, filtersData, userId) => {
    const referralPartnerId = isPartner()
        ? userId || null
        : (filtersData.partner && filtersData.partner.length == 1 && filtersData.partner[0].id) ||
          null;

    const schema = {
        type: 'object',
        properties: {
            profile_id: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        _id: {
                            type: 'string',
                        },
                        name: {
                            type: 'string',
                        },
                    },
                },
            },
            universities: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        _id: {
                            type: 'string',
                        },
                        name: {
                            type: 'string',
                        },
                    },
                },
            },
            program_id: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        _id: {
                            type: 'string',
                        },
                        name: {
                            type: 'string',
                        },
                    },
                },
            },
            intake_months: {
                type: 'array',
                items: {
                    type: 'string',
                    enum: [
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sep',
                        'Oct',
                        'Nov',
                        'Dec',
                    ],
                },
            },
            intake_years: {
                type: 'array',
                items: {
                    type: 'number',
                    enum: [
                        currentYear - 3,
                        currentYear - 2,
                        currentYear - 1,
                        currentYear,
                        currentYear + 1,
                        currentYear + 2,
                        currentYear + 3,
                        currentYear + 4,
                    ],
                },
            },
            application_statuses: {
                type: 'array',
                items: {
                    type: 'string',
                    enum: applicationStatus,
                },
            },
            application_sub_statuses: {
                type: 'string',
                enum:
                    getApplicationSubstatusEnum(filtersData.application_statuses) &&
                    getApplicationSubstatusEnum(filtersData.application_statuses).length
                        ? getApplicationSubstatusEnum(filtersData.application_statuses)
                        : ['Value1', 'Value2'],
            },
            application_source: {
                type: 'string',
                enum: extraKeysOption.application_source,
            },
            // Note: Phase-2
            //created_at_before: {
            //     type: 'string',
            //     format: 'date',
            // },
            // created_at_after: {
            //     type: 'string',
            //     format: 'date',
            // },
            countries: {
                type: 'array',
                items: {
                    type: 'string',
                    enum: config.countries,
                },
            },
            owner: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'string',
                        },
                        name: {
                            type: 'string',
                        },
                    },
                },
            },
            followup_date: {
                type: 'object',
            },
            created_date: {
                type: 'object',
            },
            preference: {
                type: 'array',
                items: {
                    type: 'string',
                    enum: ['P1', 'P2', 'P3', 'P4', 'P5'],
                },
            },
        },
    };
    if (isB2bAdmin) {
        schema.properties.partner = {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    name: {
                        type: 'string',
                    },
                    id: {
                        type: 'string',
                    },
                },
            },
        };
        schema.properties.last_modified_by = {
            type: 'array',
            items: {
                type: 'string',
                enum: modifiedByList,
            },
        };
    }

    if (!(isPSA() || isPartner() || isSubAgent())) {
        schema.properties.psa = {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    name: {
                        type: 'string',
                    },
                    id: {
                        type: 'string',
                    },
                },
            },
        };
    }

    if (isB2bAdmin || isPartner()) {
        schema.properties.sub_agent = {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    name: {
                        type: 'string',
                    },
                    id: {
                        type: 'string',
                    },
                },
                default: null,
            },
        };
    }

    const uiSchema = {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        label: 'Name',
                        type: 'Control',
                        scope: '#/properties/profile_id',
                        options: {
                            component: 'search',
                            searchApi: getProfiles,
                            searchApiArgs: [{ q: '{{search}}' }],
                            indexApi: getProfiles,
                            indexApiArgs: [{ ids: '{{ids}}' }],
                            valueKey: '_id',
                            labelKey: 'first_name',
                            labelExp: '{{first_name}} {{last_name}}',
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        label: 'University',
                        type: 'Control',
                        scope: '#/properties/universities',
                        options: {
                            component: 'search',
                            searchApi: universitySearch,
                            searchApiArgs: [{ q: '{{search}}' }],
                            indexApi: getUniversities,
                            indexApiArgs: [{ ids: '{{ids}}' }],
                            valueKey: '_id',
                            labelKey: 'name',
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/preference',
                        label: 'Priority',
                        options: {
                            component: 'multiSelect',
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        label: 'Program',
                        type: 'Control',
                        scope: '#/properties/program_id',
                        options: {
                            component: 'search',
                            searchApi: getPrograms,
                            searchApiArgs: [{ q: '{{search}}' }],
                            indexApi: getPrograms,
                            indexApiArgs: [{ ids: '{{ids}}' }],
                            valueKey: '_id',
                            labelKey: 'name',
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/countries',
                        label: 'Country',
                        options: {
                            component: 'multiSelect',
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/intake_months',
                        label: 'Intake Month',
                        options: {
                            component: 'multiSelect',
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/intake_years',
                        label: 'Intake Year',
                        options: {
                            component: 'multiSelect',
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/application_statuses',
                        label: 'Application Status',
                        options: {
                            enumOptions: applicationStatus.map((item) => ({
                                label: getLabelForApplicationStatus(item),
                                value: item,
                            })),
                            component: 'multiSelect',
                        },
                    },
                    {
                        type: 'Control',
                        label: 'Application Owner',
                        scope: '#/properties/owner',
                        options: {
                            component: 'search',
                            searchApi: getOwners,
                            searchApiArgs: [{ q: '{{search}}' }],
                            indexApi: getOwners,
                            indexApiArgs: [{ ids: '{{ids}}' }],
                            valueKey: 'id',
                            labelKey: 'first_name',
                            labelExp: '{{first_name}} {{last_name}}',
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        label: 'Application Sub Status',
                        scope: '#/properties/application_sub_statuses',
                        options: {
                            enumOptions: getApplicationSubstatusEnum(
                                filtersData.application_statuses,
                            ).map((item) => ({
                                label: startCase(item, /[^ ]+/g),
                                value: item,
                            })),
                        },
                        rule: {
                            effect:
                                filtersData.application_statuses &&
                                filtersData.application_statuses.some(
                                    (status) => status in subStatusChoices,
                                )
                                    ? 'SHOW'
                                    : 'HIDE',
                            condition: {
                                scope: '',
                                schema: {},
                            },
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        label: 'Application Source',
                        scope: '#/properties/application_source',

                        rule: {
                            effect:
                                filtersData.application_statuses &&
                                filtersData.application_statuses.includes(APPLICATION_SUBMITTED)
                                    ? 'SHOW'
                                    : 'HIDE',
                            condition: {
                                scope: '',
                                schema: {},
                            },
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/created_date',
                        options: {
                            component: 'date-range-picker',
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/followup_date',
                        options: {
                            component: 'date-range-picker',
                        },
                    },
                ],
            },
            // NOTE: Phase - 2
            // {
            //     type: 'HorizontalLayout',
            //     elements: [
            //         {
            //             type: 'Control',
            //             scope: '#/properties/created_at_before',
            //         },
            //         {
            //             type: 'Control',
            //             scope: '#/properties/created_at_after',
            //         },
            //     ],
            // },
        ],
    };
    if (isB2bAdmin) {
        uiSchema.elements.push({
            type: 'HorizontalLayout',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/partner',
                    label: 'Referral Partner',
                    options: {
                        component: 'search',
                        searchApi: getPartners,
                        searchApiArgs: [{ q: '{{search}}' }],
                        indexApi: getPartners,
                        indexApiArgs: [{ ids: '{{ids}}' }],
                        valueKey: 'id',
                        labelKey: 'name',
                    },
                },
            ],
        });
        uiSchema.elements.push({
            type: 'HorizontalLayout',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/last_modified_by',
                    label: 'Last Action By',
                    options: {
                        enumOptions: modifiedByList.map((item) => ({
                            label: getModifiedByLabel(item),
                            value: item,
                        })),
                        component: 'multiSelect',
                    },
                },
            ],
        });
    }

    if (!(isPSA() || isPartner() || isSubAgent())) {
        uiSchema.elements.push({
            type: 'HorizontalLayout',
            elements: [
                {
                    type: 'Control',
                    label: 'Business Manager',
                    scope: '#/properties/psa',
                    options: {
                        component: 'search',
                        searchApi: getPSAUser,
                        searchApiArgs: [{ q: '{{search}}' }],
                        indexApi: getPSAUser,
                        indexApiArgs: [{ ids: '{{ids}}' }],
                        valueKey: 'id',
                        labelKey: 'first_name',
                        labelExp: '{{first_name}} ({{email}})',
                    },
                },
            ],
        });
    }

    if (isB2bAdmin && referralPartnerId) {
        uiSchema.elements[8].elements.push({
            type: 'HorizontalLayout',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/sub_agent',
                    label: 'Counsellor / Branch',
                    options: {
                        component: 'search',
                        searchApi: searchSubAgent(referralPartnerId),
                        searchApiArgs: [{ q: '{{search}}' }],
                        indexApi: searchSubAgent(referralPartnerId),
                        indexApiArgs: [{ ids: '{{ids}}' }],
                        valueKey: 'id',
                        labelKey: 'first_name',
                        labelExp: '{{first_name}} {{last_name}}',
                    },
                },
            ],
        });
    }
    if (isPartner() && referralPartnerId) {
        uiSchema.elements.push({
            type: 'HorizontalLayout',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/sub_agent',
                    label: 'Counsellor / Branch',
                    options: {
                        component: 'search',
                        searchApi: searchSubAgent(referralPartnerId),
                        searchApiArgs: [{ q: '{{search}}' }],
                        indexApi: searchSubAgent(referralPartnerId),
                        indexApiArgs: [{ ids: '{{ids}}' }],
                        valueKey: 'id',
                        labelKey: 'first_name',
                        labelExp: '{{first_name}} {{last_name}}',
                    },
                },
            ],
        });
    }

    const props = {
        submitButton: {
            onClick: onApplyFilters,
            fullWidth: false,
            label: 'Apply Filters',
            variant: 'contained',
            size: 'medium',
            color: 'success',
        },
        showCloseButton: true,
        modalTitleStartIcon: getIcon('FilterAlt', 'primary', 'medium'),
        schema: schema,
        uiSchema: uiSchema,
        showPreview: false,
    };

    return props;
};
