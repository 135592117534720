// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

// material
import { Box } from '@mui/system';

// components
import Loader from '@/components/shared/Loader';
import Toast from '@/components/shared/Toast';
import CrmComments from '@/components/shared/CrmPartnerComments';
import Registration from '@/components/modules/Registration';
import Login from '@/components/Login/index';
import { NotAuthorised } from '@/components/errors/NotAuthorised';

//utils
import { getAuthTokenValue } from '@/utils/auth-token-utils';
import { isPartner, isRoleDefined, isB2bAdmin, isSubAgent } from '@/rbac';

// layout
import DefaultLayout from '@/components/layout/DefaultLayout';
import BlankLayout from '@/components/layout/BlankLayout';

// actions
import { globalDataGetAndStore } from '@/store/actions/globalActions';
import { userGetAndStore, authenticateCounsellorUser } from '@/store/actions/userActions';
import { setToast } from '@/store/slices/toastSlice';
import { PartnerDataGetAndStore } from '@/store/actions/partnerActions';
import { goToRoute } from '@/store/actions/navigationActions';

// routes
import routePaths, {
    checkValidRoute,
    isLoginPath,
    isCrmPath,
    isdocumentPreviewPathFlag,
    isPreLoginTokenPath,
} from '@/utils/route-paths';
import routes from '@/routes';
import { loginRoutes } from '@/routes/loginRoutes';
import DocumentPreview from './shared/DocumentPreview';

const useOptimizedComponentWillMount = (callback) => {
    const mounted = useRef(false);
    if (!mounted.current) callback();

    useEffect(() => {
        mounted.current = true;
    }, []);
};

let Router = function ({ history }) {
    const dispatch = useDispatch();

    const globalData = useSelector((state) => state.globalData);
    const userAuthData = useSelector((state) => state.userData);
    const partnerData = useSelector((state) => state.partnerData);
    const toast = useSelector((state) => state.toaster.toast);
    const location = useSelector((state) => state.router.location);

    const [isOnboarded, setIsOnboarded] = useState(null);

    const userAuthToken = getAuthTokenValue();
    const isValidPath = checkValidRoute(routes);
    const crmPathFlag = isCrmPath(location);
    const documentPreviewPathFlag = isdocumentPreviewPathFlag(location);
    const loginRoutesFlag = isLoginPath(location);
    const preLoginTokenFlag = isPreLoginTokenPath(location);

    useOptimizedComponentWillMount(() => {
        dispatch(globalDataGetAndStore());
        crmPathFlag && !userAuthToken
            ? dispatch(authenticateCounsellorUser(location))
            : dispatch(userGetAndStore());
    }); // save user details in the redux store after login

    useEffect(() => {
        if (!crmPathFlag && !preLoginTokenFlag && userAuthToken) dispatch(PartnerDataGetAndStore());
    }, [crmPathFlag, userAuthToken, preLoginTokenFlag]); //call partner api in the paths of non-crm and after login paths

    useEffect(() => {
        if (userAuthToken && userAuthToken != 'undefined' && isOnboarded && loginRoutesFlag) {
            dispatch(goToRoute('/'));
        }
    }, [location]); //impact: push the user back into dashboard route, if he clicks on browser back

    useEffect(() => {
        if (!partnerData.loading) {
            if (['onboarded', 'transferred_to_psa'].includes(partnerData.data.onboarding_status))
                setIsOnboarded(true);
            else setIsOnboarded(false);
        }
    }, [partnerData]); //setting isOnboarded state

    if (crmPathFlag) {
        //crm route
        if (userAuthData.loading || globalData.loading) return <Loader />;

        return (
            <Box>
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route
                            exact
                            path={routePaths.CRM_COMMENTS}
                            component={(props) => <CrmComments {...props} />}
                        />
                    </Switch>
                </ConnectedRouter>
            </Box>
        );
    } else if (documentPreviewPathFlag) {
        if (userAuthData.loading || globalData.loading) return <Loader />;

        return (
            <Box>
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route
                            exact
                            path={routePaths.DOCUMENT_PREVIEW}
                            component={(props) => <DocumentPreview {...props} />}
                        />
                    </Switch>
                </ConnectedRouter>
            </Box>
        );
    } else if (!userAuthToken || preLoginTokenFlag) {
        // Before login routes
        return (
            <Box>
                {toast && toast.message && (
                    <Toast
                        autoHideDuration={2000}
                        onCloseCallback={() => {
                            dispatch(setToast(null));
                        }}
                        title={toast.message}
                        severity={toast.toasterColor}
                    />
                )}
                <ConnectedRouter history={history}>
                    <Switch>
                        <BlankLayout>
                            {!loginRoutesFlag && <Route render={(props) => <Login {...props} />} />}
                            {loginRoutesFlag &&
                                loginRoutes.map((loginRoute, index) => (
                                    <Route
                                        key={index}
                                        exact
                                        path={loginRoute.routePath}
                                        component={loginRoute.component}
                                    />
                                ))}
                        </BlankLayout>
                    </Switch>
                </ConnectedRouter>
            </Box>
        );
    } else if (userAuthToken) {
        // After login routes
        if (
            globalData.loading ||
            userAuthData.loading ||
            partnerData.loading ||
            isOnboarded === null
        )
            return <Loader />;
        else
            return (
                <Box>
                    {toast && toast.message && (
                        <Toast
                            autoHideDuration={4000}
                            onCloseCallback={() => {
                                dispatch(setToast(null));
                            }}
                            title={toast.message}
                            severity={toast.toasterColor}
                        />
                    )}
                    <ConnectedRouter history={history}>
                        <Switch>
                            <DefaultLayout isLoading={false} isPartnerOnboarded={isOnboarded}>
                                {!isRoleDefined() && (
                                    <>
                                        <Redirect to={routePaths.NOT_AUTHORISED} />
                                        <Route component={() => <NotAuthorised />} />
                                    </>
                                )}

                                {!isValidPath && <Route render={() => <Redirect to="/" />} />}

                                {isPartner() && !isOnboarded && (
                                    <Route component={(props) => <Registration {...props} />} />
                                )}

                                {((isPartner() && isOnboarded) || isB2bAdmin() || isSubAgent()) &&
                                    routes.map((route, index) => (
                                        <Route
                                            key={index}
                                            exact
                                            path={route.routePath}
                                            component={route.component}
                                        />
                                    ))}

                                {/* TODO <Route component={() => <NotFound />} /> */}
                            </DefaultLayout>
                        </Switch>
                    </ConnectedRouter>
                </Box>
            );
    }
};

Router.propTypes = {
    history: PropTypes.object.isRequired,
};

export default Router;
