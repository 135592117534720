import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

function IframeGoogleDoc({ setIsLoading, url, className }) {
    const [iframeTimeoutId, setIframeTimeoutId] = useState(undefined);
    const iframeRef = useRef(null);

    useEffect(() => {
        const intervalId = setInterval(updateIframeSrc, 1000 * 5);
        setIframeTimeoutId(intervalId);

        return () => {
            clearInterval(iframeTimeoutId);
        };
    }, []);

    function iframeLoaded() {
        clearInterval(iframeTimeoutId);
        setIsLoading(false);
    }

    function getIframeLink() {
        return `https://docs.google.com/gview?url=${url}&embedded=true`;
    }

    function updateIframeSrc() {
        if (iframeRef.current) {
            iframeRef.current.src = getIframeLink();
        }
    }

    return (
        <iframe
            className={className}
            onLoad={iframeLoaded}
            onError={updateIframeSrc}
            ref={iframeRef}
            src={getIframeLink()}
        />
    );
}

export default IframeGoogleDoc;

IframeGoogleDoc.propTypes = {
    url: PropTypes.string,
    setIsLoading: PropTypes.func,
    className: PropTypes.string,
};
