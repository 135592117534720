import { config } from '@/props-constants/student-schema/student-form-schemas/schema';

export const getCourseFinderSchema = function (options) {
    return {
        type: 'object',
        properties: {
            global_search: {
                type: 'string',
            },
            student: {
                type: 'object',
                properties: {
                    _id: {
                        type: 'string',
                    },
                    name: {
                        type: 'string',
                    },
                },
            },
            universities: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        _id: {
                            type: 'string',
                        },
                        name: {
                            type: 'string',
                        },
                    },
                    required: ['_id', 'name'],
                },
            },
            countries: {
                type: 'array',
                items: {
                    type: 'string',
                    enum: options.countries || ['india'],
                },
            },
            interest_regions: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        city: {
                            type: 'string',
                        },
                        state: {
                            type: 'string',
                        },
                        country: {
                            type: 'string',
                        },
                    },
                    required: ['state', 'country'],
                },
            },
            level: {
                type: 'string',
                enum: ['Bachelor', 'Master', 'PhD'],
            },
            application_fee: {
                type: 'string',
                enum: ['Required', 'Waived Off', 'Not Required'],
            },
            client_type: {
                type: 'string',
                enum: ['Client', 'Partner'],
            },

            fee: {
                type: 'object',
                properties: {
                    min: {
                        type: 'number',
                        minimum: 0,
                    },
                    max: {
                        type: 'number',
                        maximum: 100000,
                    },
                },
            },
            //TODO: Add Months from Application Branch
            intakes: {
                type: 'array',
                items: {
                    type: 'string',
                    enum: [
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sep',
                        'Oct',
                        'Nov',
                        'Dec',
                    ],
                },
            },

            programLength: {
                type: 'object',
                properties: {
                    min: {
                        type: 'number',
                        minimum: 0,
                    },
                    max: {
                        type: 'number',
                        maximum: 60,
                    },
                },
            },
            type: {
                type: 'string',
                enum: ['Pathway', 'Certificate', 'Diploma', 'Degree'],
            },
            level_and_type: {
                type: 'array',
                items: {
                    type: 'string',
                    enum: config.target_degrees,
                },
            },
            categories: {
                type: 'array',
                items: {
                    type: 'string',
                },
            },
            subCategories: {
                type: 'array',
                items: {
                    type: 'string',
                },
            },
            exam: {
                type: 'string',
                enum: ['IELTS', 'TOEFL', 'DUOLINGO', 'GRE', 'GMAT', 'PTE', 'SAT'],
            },
            scores: {
                type: 'object',
                properties: {
                    listening: {
                        type: 'number',
                        minimum: 0,
                    },
                    reading: {
                        type: 'number',
                        minimum: 0,
                    },
                    written: {
                        type: 'number',
                        minimum: 0,
                    },
                    speaking: {
                        type: 'number',
                        minimum: 0,
                    },
                    overall: {
                        type: 'number',
                        minimum: 0,
                    },
                    verbalScore: {
                        type: 'number',
                        minimum: 0,
                    },
                    quantitative: {
                        type: 'number',
                        minimum: 0,
                    },
                    essay: {
                        type: 'number',
                        minimum: 0,
                    },
                },
            },
            backlogs: {
                type: 'number',
                minimum: 0,
                maximum: 99,
            },
            cgpa: {
                type: 'number',
                minimum: 0,
                maximum: 100,
            },
            highest_education: {
                type: 'string',
                enum: config.highest_degree,
            },
            scale: {
                type: 'number',
                minimum: 0,
                maximum: 100,
            },
        },
        allOf: [
            {
                if: {
                    properties: {
                        cgpa: {
                            maximum: 15,
                        },
                    },
                },
                then: {
                    properties: {
                        scale: {
                            maximum: 15,
                        },
                    },
                },
            },
        ],
        dependencies: {
            cgpa: {
                if: {
                    properties: {
                        cgpa: {
                            minimum: 1,
                            maximum: 100,
                        },
                    },
                },
                then: {
                    required: ['scale'],
                },
            },
            scale: {
                if: {
                    properties: {
                        cgpa: {
                            minimum: 1,
                            maximum: 100,
                        },
                    },
                },
                then: {
                    required: ['cgpa'],
                },
            },
        },
    };
};

export const courseFinderStudentSelectionSchema = {
    type: 'object',
    properties: {
        student: {
            type: 'object',
            properties: {
                _id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                },
            },
        },
    },
};
