import React from 'react';
import PropTypes from 'prop-types';

// MUI components
import { Box, Stepper, Step, StepLabel, Button, Typography } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

// styles
import { styled } from '@mui/material/styles';
import { useStyles } from './styles';

//comps
import StepIcon from './StepIcon';

const QontoConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#88ADF9',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#00C853',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#88ADF9',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

export default function StepperHorizontal({
    stepNames,
    tabData,
    activeStep,
    setActiveStep,
    showStepperControls,
    isStepClickEnabled,
    completed,
    setCompleted,
}) {
    // const [completed, setCompleted] = React.useState(stepsCompleted);
    const classes = useStyles();

    const totalSteps = () => {
        return stepNames.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                  stepNames.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        if (isStepClickEnabled) setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ width: '100%', backgroundColor: '#DBE7FF' }} p={2} pt={4}>
                <Stepper
                    nonLinear
                    activeStep={activeStep}
                    alternativeLabel
                    connector={<QontoConnector />}>
                    {stepNames.map((label, index) => (
                        <Step key={label} completed={completed[index]}>
                            <StepLabel
                                StepIconComponent={() => (
                                    <StepIcon
                                        active={activeStep == index ? true : false}
                                        completed={completed[index]}
                                        classes={classes}
                                    />
                                )}
                                color="inherit"
                                onClick={handleStep(index)}
                                sx={{
                                    '& .MuiStepLabel-alternativeLabel': {
                                        fontWeight: 550,
                                    },
                                    '& .MuiStepLabel-label &.Mui-active': {
                                        fontWeight: 550,
                                    },
                                }}>
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <>
                {allStepsCompleted() ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Box> {tabData}</Box>
                        {showStepperControls && (
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}>
                                    Back
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button onClick={handleNext} sx={{ mr: 1 }}>
                                    Next
                                </Button>
                                {activeStep !== stepNames.length &&
                                    (completed[activeStep] ? (
                                        <Typography
                                            variant="caption"
                                            sx={{ display: 'inline-block' }}>
                                            Step {activeStep + 1} already completed
                                        </Typography>
                                    ) : (
                                        <Button onClick={handleComplete}>
                                            {completedSteps() === totalSteps() - 1
                                                ? 'Finish'
                                                : 'Complete Step'}
                                        </Button>
                                    ))}
                            </Box>
                        )}
                    </React.Fragment>
                )}
            </>
        </Box>
    );
}

StepperHorizontal.propTypes = {
    stepNames: PropTypes.array,
    tabData: PropTypes.any,
    activeStep: PropTypes.number,
    setActiveStep: PropTypes.func,
    showStepperControls: PropTypes.bool,
    isStepClickEnabled: PropTypes.bool,
    completed: PropTypes.object,
    setCompleted: PropTypes.func,
};
