/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { get, startCase } from 'lodash';

// mui
import { Box, Typography, IconButton } from '@mui/material';

// utils
import routePaths, { studentPaths } from '@/utils/route-paths';
import { getModifiedByLabel } from '@/utils/modifiedBy';
import { isB2bAdmin, isPartner, isPSA, isSubAgent } from '@/rbac';

// actions
import { goToRoute } from '@/store/actions/navigationActions';

// component
import { getIcon, ColorCard } from 'azent-storybook/dist';

// styles
import { useStyles } from '../style';

//prop-constants
import {
    getContainerClass,
    getTitleClass,
    getLabelForApplicationStatus,
    isSubStatusKey,
} from '@/props-constants/application/application-status';
import { getAppPriorityIconStyle } from '@/props-constants/application/application-profile';

export const getRawColumns = (user) => [
    {
        Header: 'ProfileId',
        Footer: 'ProfileId',
        accessor: (c) => `${get(c, 'profile_id', '-')}`,
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: false,
        show: false,
    },
    {
        Header: 'ApplicationId',
        Footer: 'ApplicationId',
        accessor: (c) => `${get(c, '_id', '-')}`,
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: false,
        show: false,
    },
    {
        Header: 'Student Id',
        Footer: 'Student Id',
        accessor: (c) => `${get(c, 'profile.crm_uid', '-')}`,
        Cell: ({ value }) => (
            <Typography variant="h6" color="primary">
                {value}
            </Typography>
        ),
        showInit: true,
        show: true,
    },
    {
        Header: 'Name',
        Footer: 'Name',
        accessor: (c) => `${get(c, 'profile.first_name', '')} ${get(c, 'profile.last_name', '-')}`,
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: true,
        show: true,
    },

    {
        Header: 'University',
        Footer: 'University',
        accessor: (c) => `${get(c, 'program_info.university_name', '-')}`,
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: true,
        show: true,
    },
    {
        Header: 'Application Priority',
        Footer: 'Application Priority',
        accessor: (c) => get(c, 'preference', ''),
        Cell: ({ value }) => {
            const classes = useStyles();
            return (
                <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                    {value &&
                        getIcon(
                            'fire',
                            'inherit',
                            'small',
                            getAppPriorityIconStyle(value, classes),
                        )}
                    {value ? ` ${value}` : '-'}
                </Typography>
            );
        },
        showInit: true,
        show: true,
    },
    {
        Header: 'Program',
        Footer: 'Program',
        accessor: (c) => `${get(c, 'program_info.name', '-')}`,
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: true,
        show: true,
    },
    {
        Header: 'Country',
        Footer: 'Country',
        accessor: (c) => `${get(c, 'university_location_info.country', '-')}`,
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: true,
        show: true,
    },
    {
        Header: 'Intake',
        Footer: 'Intake',
        accessor: (c) =>
            get(c, 'intake.month', '-')
                ? `${get(c, 'intake.month', '-')} - ${get(c, 'intake.year', '-')}`
                : '-',
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: true,
        show: true,
    },
    {
        Header: 'Status',
        Footer: 'Status',
        accessor: (c) => `${get(c, 'application_status.current_status', '-')}`,
        Cell: ({ value }) => {
            const classes = useStyles();
            return (
                <Typography
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                    variant="h6">
                    <ColorCard
                        title={getLabelForApplicationStatus(value)}
                        classes={{
                            container: getContainerClass(classes, value),
                            title: getTitleClass(classes, value),
                        }}
                    />
                </Typography>
            );
        },
        showInit: false,
        show: true,
    },
    {
        Header: 'Sub Status',
        Footer: 'Sub Status',
        accessor: (c) =>
            isSubStatusKey(`${get(c, 'application_status.current_status')}`)
                ? `${get(c, 'application_status.current_sub_status', '')}`
                : '',
        Cell: ({ value }) => {
            return (
                <Typography variant="h6" sx={{ textAlign: 'left', fontWeight: 400 }}>
                    {value.length ? startCase(value) : 'N/A'}
                </Typography>
            );
        },
        showInit: false,
        show: true,
    },

    {
        Header: 'Application Source',
        Footer: 'Application Source',
        accessor: (c) => `${get(c, 'application_source', '')}`,
        Cell: ({ value }) => {
            return (
                <Typography variant="h6" sx={{ textAlign: 'left', fontWeight: 400 }}>
                    {value.length ? startCase(value) : 'N/A'}
                </Typography>
            );
        },
        showInit: false,
        show: true,
    },

    {
        Header: 'Email',
        Footer: 'Email',
        accessor: (c) => `${get(c, 'profile.email', '-')}`,
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: false,
        show: true,
    },
    {
        Header: 'Mobile',
        Footer: 'Mobile',
        accessor: (c) => c.profile.mobile,
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: false,
        show: true,
    },
    {
        Header: 'Referral Partner',
        Footer: 'Referral Partner',
        accessor: (c) => get(c, 'partner__name', '-'),
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: isB2bAdmin(user),
        show: isB2bAdmin(user),
    },
    {
        Header: 'Counsellor / Branch',
        Footer: 'Counsellor / Branch',
        accessor: (c) => {
            let name = `${get(c, 'sub_agent.first_name', '')} ${get(c, 'sub_agent.last_name', '')}`;
            name = name.trim().length ? name : 'N/A';
            return name;
        },
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: false,
        show: isB2bAdmin() || isPartner(),
    },
    {
        Header: 'Application Owner',
        Footer: 'Application Owner',
        accessor: (c) => get(c, 'owner__name', 'Not Assigned Yet'),
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: false,
        show: true,
    },
    {
        Header: 'Created Date',
        Footer: 'Created Date',
        accessor: (c) => get(c, 'created_at', '-'),
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {moment(value).format('DD/MM/YYYY')}
            </Typography>
        ),
        showInit: false,
        show: true,
    },
    {
        Header: 'Business Manager Name',
        Footer: 'Business Manager Name',
        accessor: (c) => get(c, 'psa_name', '-'),
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: false,
        show: isPartner() || isPSA() || isB2bAdmin(user) || isSubAgent(),
    },
    {
        Header: 'Business Manager Email',
        Footer: 'Business Manager Email',
        accessor: (c) => get(c, 'psa_email', '-'),
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: false,
        show: !(isPartner() || isPSA() || isSubAgent()),
    },
    {
        Header: 'Business Manager Mobile',
        Footer: 'Business Manager Mobile',
        accessor: (c) => get(c, 'psa_mobile', '-'),
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: false,
        show: !(isPartner() || isPSA() || isSubAgent()),
    },
    {
        Header: 'Last Action By',
        Footer: 'Last Action By',
        accessor: (c) => {
            let modifiedBy = get(c, 'last_modified_by', null);
            return {
                updatedAt: `${moment(get(c, 'modified_at', '-'))
                    .add(5, 'hours')
                    .add(30, 'minutes')
                    .fromNow()} `,
                updatedBy: `${modifiedBy ? 'By ' + getModifiedByLabel(modifiedBy) : ''}`,
            };
        },
        Cell: ({ value, row }) => {
            const _classes = useStyles();
            const dispatch = useDispatch();
            return (
                <Box display="flex" alignItems={'flex-start'}>
                    <Box>
                        <Typography
                            sx={{ textAlign: 'left', color: 'text.disabled', fontSize: '12px' }}
                            variant="h6">
                            {value.updatedAt}
                        </Typography>
                        <Typography
                            sx={{ textAlign: 'left', color: 'text.disabled', fontSize: '12px' }}
                            variant="h6">
                            {value.updatedBy}
                        </Typography>
                    </Box>
                    <IconButton
                        onClick={() =>
                            dispatch(
                                goToRoute(routePaths.STUDENT_PROFILE_VIEW, {
                                    id: row.values.ProfileId,
                                    aid: row.values.ApplicationId,
                                    path: studentPaths.APPLICATION,
                                }),
                            )
                        }>
                        {getIcon('edit', 'inherit', 'small', _classes.editIcon)}
                    </IconButton>
                </Box>
            );
        },
        showInit: true,
        show: true,
    },
];
