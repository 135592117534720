//Actions
import * as navigationActions from "@/store/actions/navigationActions";
import { store } from "@/store";
import customerCare from "@/assets/customerCare.png";

// Utils
import routePaths, { studentPaths } from "@/utils/route-paths";

const getModalProps = (studentId,setIsModalOpen) => {
  return {
    coverImage: {
      src: customerCare ,
      alt: "customerCare",
    },
    handleClose: () => setIsModalOpen(false),
    descriptions: [
      "Thank you for submitting your enquiry. Someone from our team will reach out to you within a day or two to take this forward."
    ],
    buttons: [
      {
        label: "Goto Student Profile",
        color: "success",
        onClick: () => {
          store.dispatch(navigationActions.goToRoute(routePaths.STUDENT_PROFILE_VIEW,{
            id: studentId,
            path: studentPaths.SUMMARY,
        }));
        },
        variant: "outlined",
      }
    ],
  };
};

export default getModalProps