import dev from './dev.js';
import prod from './prod.js';
import staging from './staging.js';
import local from './local';
import defaultConfig from './default';
import testing from './testing.js';

let envConfig = null;
let env = process.env.APP_ENV;

switch (env) {
    case 'prod':
    case 'production':
        envConfig = { ...defaultConfig, ...prod };
        break;
    case 'staging':
    case 'stag':
        envConfig = { ...defaultConfig, ...staging };
        break;
    case 'dev':
    case 'development':
        envConfig = { ...defaultConfig, ...dev };
        break;
    case 'testing':
    case 'test':
        envConfig = { ...defaultConfig, ...testing };
        break;
    default:
        envConfig = { ...defaultConfig, ...local };
}

export default envConfig;
