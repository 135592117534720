import moment from 'moment';

// Actions
import { goToExternalRoute, goToRoute } from '@/store/actions/navigationActions';
import { setRefreshData } from '@/store/slices/refreshSlice';

// Utils
import routePaths, {
    isValidRefreshDataPath,
    handleLogout,
    isServiceRoute,
    goToServices,
} from '@/utils/route-paths';

// Images
import admissionBoxLogo from '@/assets/AdmisssionBox.png';
// import { setUniversalDialog } from '@/store/slices/universalDialogSlice';

// Rbac
import { isPartner, isSubAgent } from '@/rbac';

//constants
import constants from '@/constants';

// ccomponent libraray
import { getIcon } from 'azent-storybook/dist';

const getMenuBarProps = ({
    classes,
    dispatch,
    path,
    refreshData,
    displayTime,
    isVisibleActions,
    partnerOrganizationName,
    subAgentId,
}) => {
    const menuBarProps = {
        logo: {
            src: admissionBoxLogo,
            alt: 'Azent Overseas Education',
            width: '265px',
            height: '70px',
        },
        user: {
            actionsList: [
                {
                    label: 'Logout',
                    onClick: () => {
                        handleLogout();
                    },
                },
            ],
        },
        actionsList: [],
    };

    if (isVisibleActions == true) {
        !isSubAgent() &&
            menuBarProps.user.actionsList.splice(0, 0, {
                label: 'My Profile',
                onClick: () => {
                    dispatch(goToRoute(routePaths.PARTNER_PROFILE));
                },
            });

        if (isValidRefreshDataPath(path) && displayTime) {
            menuBarProps.actionsList.push({
                label: refreshData
                    ? 'Update in Progress...'
                    : `Updated ${moment(displayTime).fromNow()}`,
                variant: 'text',
                color: 'inherit',
                size: 'medium',
                icon: getIcon(
                    'refresh',
                    'inherit',
                    'small',
                    refreshData ? classes.spin : classes.icon,
                ),
                onClick: () => {
                    dispatch(setRefreshData(true));
                },
                isDrawerMenu: false,
            });
        }

        menuBarProps.actionsList = [
            ...menuBarProps.actionsList,
            {
                label: 'Destination Guide',
                buttonType: 'menuButton',
                variant: 'text',
                color: 'inherit',
                size: 'medium',
                onClick: () => {},
                actionsList: [
                    {
                        label: 'USA',
                        onClick: () =>
                            goToExternalRoute(
                                constants.notion,
                                'USA-a5e7ed47983d48328cc18138f3bb2237',
                                {},
                                {},
                                true,
                            ),
                    },
                    {
                        label: 'UK',
                        onClick: () =>
                            goToExternalRoute(
                                constants.notion,
                                'UK-f9538f4250bc4130bdd58fb0b4868502',
                                {},
                                {},
                                true,
                            ),
                    },
                    {
                        label: 'Australia',
                        onClick: () =>
                            goToExternalRoute(
                                constants.notion,
                                'Australia-8d1107004c074804811918738a8006d9',
                                {},
                                {},
                                true,
                            ),
                    },
                    {
                        label: 'New Zealand',
                        onClick: () =>
                            goToExternalRoute(
                                constants.notion,
                                'New-Zealand-a91baf4c2aec42e0aa32b79df4126ae5',
                                {},
                                {},
                                true,
                            ),
                    },
                    {
                        label: 'Canada',
                        onClick: () =>
                            goToExternalRoute(
                                constants.notion,
                                'Canada-dd750c04777c4fa59f4ad7a31ad5fe0b',
                                {},
                                {},
                                true,
                            ),
                    },
                    {
                        label: 'Singapore',
                        onClick: () =>
                            goToExternalRoute(
                                constants.notion,
                                'Singapore-ce0ae0e8a8d84ddba52ca574799e4d68',
                                {},
                                {},
                                true,
                            ),
                    },
                    {
                        label: 'Ireland',
                        onClick: () =>
                            goToExternalRoute(
                                constants.notion,
                                'Ireland-459075f1ac0a4d90a485ce3977758f26',
                                {},
                                {},
                                true,
                            ),
                    },
                    {
                        label: 'Germany',
                        onClick: () =>
                            goToExternalRoute(
                                constants.notion,
                                'Germany-e8dfc500dc724b9a86f4a08bec214038',
                                {},
                                {},
                                true,
                            ),
                    },
                ],
                isDrawerMenu: false,
            },
            {
                buttonType: 'Button',
                color: isServiceRoute(path) ? 'primary' : 'inherit',
                label: 'Ancillary Services',
                onClick: () => goToServices(),
                variant: 'text',
                isDrawerMenu: false,
            },
        ];
        menuBarProps.FAQ = {
            icon: getIcon('Help'),
            color: 'primary',
            label: 'FAQ',
            onClick: () =>
                window.open(
                    'https://quilt-echo-a9d.notion.site/FAQ-s-9574b96235ed4079be8c6860380fe2a1',
                    '_blank',
                ),
        };

        if (isPartner()) {
            menuBarProps.logo.partnerOrganizationName = partnerOrganizationName;
            menuBarProps.user.actionsList.splice(0, 0, {
                label: 'Manage Users',
                onClick: () => {
                    dispatch(goToRoute(routePaths.SUB_AGENT_INDEX));
                },
            });
        }
        if (isSubAgent()) {
            menuBarProps.logo.partnerOrganizationName = partnerOrganizationName;
            menuBarProps.user.actionsList.splice(0, 0, {
                label: 'Update Password',
                onClick: () => {
                    dispatch(
                        goToRoute(
                            routePaths.SUB_AGENT_UPDATE,
                            {},
                            { step: 'set-password', id: subAgentId },
                        ),
                    );
                },
            });
        }
    }
    // Removed new <=> old redirection
    // if (isDashboard)
    //     menuBarProps.actionsList.push({
    //         labels: { tab1: 'New Version', tab2: 'Old Version' },
    //         buttonType: 'toggleButton',
    //         onToggle: () => {
    //             dispatch(setUniversalDialog(true));
    //         },
    //     });

    return menuBarProps;
};

export default getMenuBarProps;
