import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

// Constants
import shortAddressDetailsUiSchema from './short-address-details-ui';
import {
    getEducationLevelToDocumentCategoriesMapping,
    getEducationLevelToLabelHighlight,
} from '@/utils/documents';
import {
    PG_CERTIFICATE_OR_DIPLOMA,
    CERTIFICATE_OR_DIPLOMA,
    MASTERS,
    PHD,
    TWELTH,
    TENTH,
    INSTITUTE_LABEL,
    BACHELOR_DEGREE_3_OR_4,
} from '../schema';

const getInstituteFieldDef = (educationLevel = '') => {
    let instituteFieldDef = {
        type: 'Control',
        scope: '#/properties/institute_name',
    };

    switch (educationLevel) {
        case TENTH:
        case TWELTH:
            instituteFieldDef.label = INSTITUTE_LABEL.SCHOOL;
            break;

        case PG_CERTIFICATE_OR_DIPLOMA:
        case CERTIFICATE_OR_DIPLOMA:
        case BACHELOR_DEGREE_3_OR_4:
        case MASTERS:
        case PHD:
            instituteFieldDef.label = INSTITUTE_LABEL.ABOVE_SCHOOL;
            break;

        default:
            return instituteFieldDef;
    }

    return instituteFieldDef;
};

let options = (isMobile, showDegreeAwarded, showLevel, educationLevel = '') => {
    let uiSchema = {
        expandable: true,
        ensureData: {
            address: {},
        },
        detail: {
            type: 'VerticalLayout',
            elements: [],
        },
    };

    const institute_name = getInstituteFieldDef(educationLevel);

    const gpa_or_percentage = {
        type: 'Control',
        scope: '#/properties/gpa_or_percentage',
        label: 'Marks Obtained (Gpa/Percentage)',
    };
    const level = {
        type: 'Control',
        scope: '#/properties/level',
    };
    const degree_awarded = {
        type: 'Control',
        scope: '#/properties/degree_awarded',
    };

    const overall_percentage = {
        type: 'Control',
        scope: '#/properties/overall_percentage',
        label: 'Out of Marks (Gpa/percentage)',
        options: {
            const: 100,
        },
    };

    const duration = [
        {
            type: 'Control',
            scope: '#/properties/from_year',
            options: {
                formatMaximum: moment().add(2, 'years'),
                formatMinimum: moment().subtract(50, 'years'),
                component: 'yearPicker',
            },
        },
        {
            type: 'Control',
            scope: '#/properties/to_year',
            options: {
                formatMaximum: moment().add(2, 'years'),
                formatMinimum: moment().subtract(50, 'years'),
                component: 'yearPicker',
            },
        },
    ];

    if (isMobile) {
        uiSchema.detail.elements.push(institute_name);
        uiSchema.detail.elements.push(gpa_or_percentage);
        if (showLevel) {
            uiSchema.detail.elements.push(level);
        }
        if (showDegreeAwarded) {
            uiSchema.detail.elements.push(degree_awarded);
        }
        uiSchema.detail.elements.push(...duration);
    } else {
        uiSchema.detail.elements.push({
            type: 'HorizontalLayout',
            elements: [institute_name],
        });

        let middleRow = {
            type: 'HorizontalLayout',
            elements: [gpa_or_percentage],
        };

        middleRow.elements.push(overall_percentage);

        uiSchema.detail.elements.push(middleRow);

        if (showDegreeAwarded) {
            uiSchema.detail.elements.push({
                type: 'HorizontalLayout',
                elements: showLevel ? [level, degree_awarded] : [degree_awarded],
            });
        }

        uiSchema.detail.elements.push({
            type: 'HorizontalLayout',
            elements: duration,
        });
    }

    uiSchema.detail.elements.push({ ...shortAddressDetailsUiSchema(isMobile) });

    return uiSchema;
};

const largeScreenUiSchema = (config) => {
    let data = {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'Control',
                scope: '#/properties/education_history',
                options: {
                    detail: {
                        type: 'VerticalLayout',
                        elements: [
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/highest_education_level',
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/backlogs_count',
                                    },
                                ],
                            },
                            {
                                type: 'Control',
                                scope: '#/properties/phd',
                                options: cloneDeep(options(false, true, false, PHD)),
                                rule: {
                                    effect: 'SHOW',
                                    condition: {
                                        scope: '#/properties/highest_education_level',
                                        schema: {
                                            const: PHD,
                                        },
                                    },
                                },
                            },
                            {
                                type: 'Control',
                                scope: '#/properties/masters',
                                options: cloneDeep(options(false, true, true, MASTERS)),
                                rule: {
                                    effect: 'SHOW',
                                    condition: {
                                        scope: '#/properties/highest_education_level',
                                        schema: {
                                            enum: [PG_CERTIFICATE_OR_DIPLOMA, MASTERS, PHD],
                                        },
                                    },
                                },
                            },
                            {
                                type: 'Control',
                                scope: '#/properties/graduation',
                                options: cloneDeep(
                                    options(false, true, true, CERTIFICATE_OR_DIPLOMA),
                                ),
                                rule: {
                                    effect: 'SHOW',
                                    condition: {
                                        scope: '#/properties/highest_education_level',
                                        schema: {
                                            enum: [
                                                CERTIFICATE_OR_DIPLOMA,
                                                BACHELOR_DEGREE_3_OR_4,
                                                PG_CERTIFICATE_OR_DIPLOMA,
                                                MASTERS,
                                                PHD,
                                            ],
                                        },
                                    },
                                },
                            },
                            {
                                type: 'Control',
                                scope: '#/properties/grade_12_or_equivalent',
                                options: cloneDeep(options(false, false, false, TWELTH)),
                                rule: {
                                    effect: 'SHOW',
                                    condition: {
                                        scope: '#/properties/highest_education_level',
                                        schema: {
                                            enum: [
                                                CERTIFICATE_OR_DIPLOMA,
                                                BACHELOR_DEGREE_3_OR_4,
                                                PG_CERTIFICATE_OR_DIPLOMA,
                                                MASTERS,
                                                PHD,
                                                TWELTH,
                                            ],
                                        },
                                    },
                                },
                            },
                            {
                                type: 'Control',
                                scope: '#/properties/grade_10_or_equivalent',
                                options: cloneDeep(options(false, false, false, TENTH)),
                                rule: {
                                    effect: 'SHOW',
                                    condition: {
                                        scope: '#/properties/highest_education_level',
                                        schema: {
                                            enum: [
                                                CERTIFICATE_OR_DIPLOMA,
                                                BACHELOR_DEGREE_3_OR_4,
                                                PG_CERTIFICATE_OR_DIPLOMA,
                                                MASTERS,
                                                PHD,
                                                TWELTH,
                                                TENTH,
                                            ],
                                        },
                                    },
                                },
                            },
                        ],
                    },
                },
            },
            {
                type: 'Control',
                scope: '#/properties/education_documents',
                label: 'Upload Documents',
                options: {
                    labelHighlight: `(${getEducationLevelToLabelHighlight(config.educationLevel)})`,
                    component: 'resource',
                    selectCategoryLabel: 'Choose Document Category',
                    uniqueCategory: false,
                    fileCategories: getEducationLevelToDocumentCategoriesMapping(config),
                },
            },
        ],
    };

    return data;
};

const smallScreenUiSchema = () => ({});

export default function (config = {}) {
    return {
        smallScreenUiSchema: smallScreenUiSchema(),
        largeScreenUiSchema: largeScreenUiSchema(config),
    };
}
