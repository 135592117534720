import React from 'react';

import { Box, Typography, Link, Stack } from '@mui/material';

export default function TermsAndConditions() {
    const goTo = (url) => {
        window.open(url, '_blank');
    };
    const fontStyle = {
        fontFamily: 'Montserrat',
        fontWeight: '500',
        fontSize: '12px',
        color: '#A9A9A9',
        lineHeight: '15px',
    };

    const linkStyle = {
        fontFamily: 'Montserrat',
        fontWeight: '500',
        fontSize: '12px',
        color: '#37474f',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    };

    return (
        <Box mt={2} mb={2} mr="auto" ml={'auto'}>
            <Typography sx={fontStyle} align="center">
                {'By creating an account or logging in to your account, you agree to our '}
                <Link onClick={() => goTo('https://www.azent.com/terms/')} sx={linkStyle}>
                    Terms
                </Link>
                {' and confirm that you have read our '}
                <Link onClick={() => goTo('https://www.azent.com/privacy')} sx={linkStyle}>
                    Privacy Policy
                </Link>
                {
                    '. You may receive phone calls or text messages from us about your plans to study overseas. You can opt out or delete your account at any time.'
                }
            </Typography>
            <Typography mt={3} align="center">
                <Link sx={linkStyle} onClick={() => goTo('https://www.azent.com')}>
                    &#169; 2022 AZENT OVERSEAS EDUCATION
                </Link>
            </Typography>
        </Box>
    );
}

export const PasswordPolicy = () => {
    const noteColor = { color: '#66686a' };
    return (
        <Stack direction={'column'}>
            <Typography variant="h6" sx={noteColor}>
                New Password Guidelines:
            </Typography>
            <Typography variant="h6" sx={noteColor}>
                1. At least one uppercase letter
            </Typography>
            <Typography variant="h6" sx={noteColor}>
                2. At least one lowercase letter
            </Typography>
            <Typography variant="h6" sx={noteColor}>
                3. At least one digit
            </Typography>
            <Typography variant="h6" sx={noteColor}>
                4. At least one special character
            </Typography>
            <Typography variant="h6" sx={noteColor}>
                5. Minimum 8 characters
            </Typography>
        </Stack>
    );
};
