import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

// material
import { Box, Typography, Paper } from '@mui/material';

// schema
import { schema as commentsSchema } from '@/props-constants/student-schema/student-comments-schemas/schema';
import { getCommentsUiSchema } from '@/props-constants/student-schema/student-comments-schemas/ui-schema';

//utils
import { toastMessages } from '@/utils/toastMessages';
import getParameterByName from '@/utils/user/getQueryParams';
import { getAuthTokenValue } from '@/utils/auth-token-utils';
import Loader from '@/components/shared/Loader';

//css
import { useStyles } from './styles';

//comps
import StudentComment from '@/components/shared/Comment';

// API
import { signRequest, updateOnboardingComment, getPartnerProfile } from '@/api';
import { setToast } from '@/store/slices/toastSlice';

// constants
import constants from '@/constants';

export default function CrmComments() {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [comments, setComments] = useState([]);
    const [addAttachment, setAddAttachment] = useState(false);
    const [loader, setLoader] = useState(true);

    const platform_id = getParameterByName('platform_id', window.location.href);
    const userData = getAuthTokenValue();

    useEffect(() => {
        getPartnerComments(platform_id);
    }, [platform_id]);

    const getPartnerComments = (platform_id) => {
        if (userData && platform_id) {
            getPartnerProfile(platform_id)
                .then((res) => {
                    if (res.data && res.data.comments && res.data.comments.length > 0) {
                        setComments(res.data.comments);
                    }
                })
                .catch(() => dispatch(setToast(toastMessages.backendIssue)));
            setLoader(false);
        } else setLoader(true);
    };

    const onCommentSave = (formData) => {
        if (formData.comment) {
            return updateOnboardingComment(formData, platform_id);
        } else {
            dispatch(setToast(toastMessages.backendIssue));
        }
    };

    const onCommentSaveSuccess = (_data, setData) => {
        getPartnerComments(platform_id);
        setAddAttachment(false);
        setData({});
    };

    const fileUploadConfig = {
        s3Postfix: platform_id && `${constants.envName}/${platform_id}`,
        awsConfig: constants.awsConfig,
        selfAuthApiCall: signRequest,
    };

    return (
        <Box p={5}>
            {loader ? (
                <Loader />
            ) : (
                <Box mt={3} mx={5}>
                    <Typography variant="h6"> Comments</Typography>
                    <Paper variant="outlined">
                        <StudentComment
                            addAttachment={addAttachment}
                            setAddAttachment={setAddAttachment}
                            comments={comments && comments.length > 0 ? comments : []}
                            addComment={{
                                schema: commentsSchema,
                                commentsUiSchema: getCommentsUiSchema({
                                    addAttachment: addAttachment,
                                    type: 'partners',
                                }),
                                defaultData: {},
                                uploadConfig: fileUploadConfig,
                                submitButton: {
                                    label: 'Save Comment',
                                    color: 'success',
                                    onClick: onCommentSave,
                                    fullWidth: false,
                                    className: classes.saveCommentButton,
                                },
                                onSuccessCallback: onCommentSaveSuccess,
                            }}
                        />
                    </Paper>
                </Box>
            )}
        </Box>
    );
}
