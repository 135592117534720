import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/******* Commenting all code related to dashboard New tie-ups and Notes *******/

// material
import { Box, Backdrop } from '@mui/material';

//components
// import { FormComponent, Button } from 'azent-storybook/dist';
import InfoBar from '@/components/shared/InfoBar';
import DashboardFilter from '@/components/shared/DashboardFilter';
// import TieupCard from '@/components/shared/TieupCard';
import Redirecting from '@/components/shared/Redirecting';
import UniversalModal from '@/components/shared/UniversalModal';

//styles
import { useStyles } from './styles';

// api
import { getStatsNew } from '@/api';

// actions
import { goToExternalRoute, setQueries } from '@/store/actions/navigationActions';
// import { setToast } from '@/store/slices/toastSlice';

// utils
// import routePaths from '@/utils/route-paths';
// import { notesSchema, notesUiSchema } from '@/props-constants/dashboard-props/notes-schema';
// import { isEmpty } from 'lodash';

import {
    statusLabelMapping,
    getStatusList,
    defaultApplicationStatuses,
    defaultStudentStatuses,
} from '@/props-constants/dashboard-props';
// import { toastMessages } from '@/utils/toastMessages';

// constants
import constants from '@/constants';
let timeout = null;

export default function Dashboard() {
    const classes = useStyles();
    const dispatch = useDispatch();

    // const [programs, setPrograms] = useState([1, 2, 3, 4]);
    // const [notesData, setNotesData] = useState({});
    // const [loading, setLoading] = useState(true);
    const [filterData, setFilterData] = useState(null);
    const [applicationStatsLoading, setApplicationStatsLoading] = useState(false);
    const [applicationStatuses, setApplicationStatuses] = useState(defaultApplicationStatuses);
    const [studentStatusLoading, setStudentStatusLoading] = useState(false);
    const [studentStatuses, setStudentStatuses] = useState(defaultStudentStatuses);
    // const [notes, setNotes] = useState(['', '', '', '']);
    // const [showErrors, setShowErrors] = useState(false);
    // const [notesLoading, setNotesLoading] = useState(true);
    const [isBackDropOpen, setIsBackDropOpen] = useState(false);

    // const user = useSelector((state) => state.userData);
    const location = useSelector((state) => state.router.location);
    const isUniversalModalOpen = useSelector((state) => state.universalDialog.dialog);

    // useEffect(() => {
    //     setLoading(true);
    //     const query = { per_page: 4 };
    //     getPrograms(query).then((res) => {
    //         setPrograms(res.data);
    //         setLoading(false);
    //     });
    // }, []);

    useEffect(() => {
        if (isUniversalModalOpen) {
            setTimeout(() => {
                dispatch(goToExternalRoute(constants.oldB2BPlatform));
            }, '5000');
        }
    }, [isUniversalModalOpen]);

    // useEffect(() => {
    //     if (!user.loading) {
    //         setNotesLoading(true);
    //         getPartnerNotes()
    //             .then((res) => {
    //                 setNotes(res.data.notes.reverse());
    //                 setNotesLoading(false);
    //             })
    //             .catch((err) => {
    //                 setNotes([]);
    //                 console.log(err);
    //             });
    //     }
    // }, [user]);

    useEffect(() => {
        const filters = {};
        const search = new URLSearchParams(location.search);
        const searchObject = Object.fromEntries(search);
        if (searchObject.country) {
            filters.country = searchObject.country.split(',');
        }
        if (searchObject.intake_month) {
            filters.intake_month = searchObject.intake_month.split(',');
        }
        if (searchObject.intake_year) {
            filters.intake_year = searchObject.intake_year.split(',');
        }
        // if (searchObject.indian_state) {
        //     filters.indian_state = searchObject.indian_state.split(',');
        // }
        if (searchObject.created_date_from && searchObject.created_date_to) {
            filters.created_date = {
                from: searchObject.created_date_from,
                to: searchObject.created_date_to,
            };
        }
        if (searchObject.partner_name && searchObject.partner_id) {
            filters.partner = {
                id: searchObject.partner_id,
                name: searchObject.partner_name,
            };
        }
        if (searchObject.sub_agent_id && searchObject.sub_agent_name) {
            filters.sub_agent = {
                id: searchObject.sub_agent_id,
                first_name: searchObject.sub_agent_name,
            };
        }
        if (searchObject.psa_name && searchObject.psa_id) {
            filters.psa = {
                id: searchObject.psa_id,
                first_name: searchObject.psa_name,
            };
        }
        setFilterData(filters);
    }, [location.search]);

    useEffect(() => {
        if (filterData) {
            setApplicationStatsLoading(true);
            setStudentStatusLoading(true);
            setStudentStatuses(defaultStudentStatuses);
            setApplicationStatuses(defaultApplicationStatuses);

            const payload = { ...filterData };
            if (filterData.created_date) {
                payload.created_date_from = filterData.created_date.from;
                payload.created_date_to = filterData.created_date.to;
            }
            if (filterData.partner) {
                payload.partner_id = filterData.partner.id;
            }
            if (filterData.sub_agent) {
                payload.sub_agent_id = filterData.sub_agent.id;
            }
            if (filterData.psa) {
                payload.psa_id = filterData.psa.id;
            }

            delete payload.created_date;
            delete payload.partner;
            delete payload.sub_agent;
            delete payload.psa;
            getStatsNew('student', payload).then((res) => {
                let data = getStatusList(res.data.status, defaultStudentStatuses);
                setStudentStatuses(data);
                setStudentStatusLoading(false);
            });
            getStatsNew('application', payload).then((res) => {
                let data = getStatusList(res.data.status, defaultApplicationStatuses);
                setApplicationStatuses(data);
                setApplicationStatsLoading(false);
            });
        }
    }, [filterData]);

    // const onClickSeeMore = () => {
    //     dispatch(goToRoute(routePaths.TIEUPS, {}, { page: 1, per_page: 10 }));
    // };

    // const addNewNote = () => {
    //     if (isEmpty(notesData)) {
    //         setShowErrors(true);
    //         return;
    //     }
    //     if (!notesData.note.trim()) {
    //         setShowErrors(true);
    //         return;
    //     }
    //     if (!user.loading) {
    //         addPartnerNote(notesData)
    //             .then((res) => {
    //                 setNotes(res.data.notes.reverse());
    //                 setShowErrors(false);
    //                 setNotesData({});
    //                 setNotesLoading(false);
    //                 document.getElementById('notes-scroll-container').scrollTo(0, 0);
    //             })
    //             .catch((err) => {
    //                 console.log(err);
    //                 setShowErrors(false);
    //                 dispatch(setToast(toastMessages.backendIssue));
    //             });
    //     }
    // };

    const setFilterQuery = (filtersData) => {
        const query = [];
        if (filtersData.intake_year && filtersData.intake_year.length)
            query.push({
                key: 'intake_year',
                value: filtersData.intake_year ? filtersData.intake_year.join(',') : '',
            });
        if (filtersData.intake_month && filtersData.intake_month.length)
            query.push({
                key: 'intake_month',
                value: filtersData.intake_month ? filtersData.intake_month.join(',') : '',
            });
        // if (filtersData.indian_state && filtersData.indian_state.length)
        //     query.push({
        //         key: 'indian_state',
        //         value: filtersData.indian_state ? filtersData.indian_state.join(',') : '',
        //     });
        if (filtersData.country && filtersData.country.length)
            query.push({
                key: 'country',
                value: filtersData.country ? filtersData.country.join(',') : '',
            });
        if (filtersData.created_date) {
            if (filtersData.created_date.from)
                query.push({
                    key: 'created_date_from',
                    value: filtersData.created_date.from,
                });
            if (filtersData.created_date.to)
                query.push({
                    key: 'created_date_to',
                    value: filtersData.created_date.to,
                });
        }
        if (filtersData.partner) {
            query.push({ key: 'partner_id', value: filtersData.partner.id });
            query.push({
                key: 'partner_name',
                value: filtersData.partner.name,
            });
        }
        if (filtersData.sub_agent) {
            query.push({ key: 'sub_agent_id', value: filtersData.sub_agent.id });
            query.push({
                key: 'sub_agent_name',
                value: filtersData.sub_agent.first_name,
            });
        }
        if (filtersData.psa) {
            query.push({ key: 'psa_id', value: filtersData.psa.id });
            query.push({ key: 'psa_name', value: filtersData.psa.first_name });
        }

        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            dispatch(setQueries(query));
        }, 100);
    };

    return (
        <Box className={classes.container}>
            {isUniversalModalOpen && (
                <UniversalModal open={isUniversalModalOpen} setBackDrop={setIsBackDropOpen} />
            )}
            {isBackDropOpen && (
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={isBackDropOpen}>
                    <Redirecting />
                </Backdrop>
            )}
            <DashboardFilter formData={filterData} setFormData={setFilterQuery} />
            <InfoBar
                title={'Student Statuses'}
                statusLabelMapping={statusLabelMapping}
                cardList={studentStatuses}
                loading={studentStatusLoading}
            />
            <InfoBar
                title={'Application Statuses'}
                statusLabelMapping={statusLabelMapping}
                cardList={applicationStatuses}
                loading={applicationStatsLoading}
            />
            {/* Hiding Dashboard New tie-ups and notes
            <Grid mt={4.25} container>
                <Grid item xs={7}>
                    <Box className={classes.tieupCardsContainer}>
                        <Typography variant="h6">New Tie-ups</Typography>
                        {programs.map((program, index) => (
                            <Box sx={{ cursor: 'pointer' }} my={1} key={index}>
                                <TieupCard
                                    course={loading ? '' : program.name}
                                    university={loading ? '' : program.university.name}
                                    loading={loading}
                                    onClick={() => {
                                        dispatch(
                                            goToRoute(
                                                routePaths.TIEUPS,
                                                {},
                                                {
                                                    page: 1,
                                                    per_page: 10,
                                                    courseId: program._id,
                                                },
                                            ),
                                        );
                                    }}
                                />
                            </Box>
                        ))}
                        <Typography
                            variant="subtitle1"
                            className={classes.seeMoreText}
                            onClick={onClickSeeMore}>
                            see more
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Box className={classes.notesContainer}>
                        <Typography mb={1} variant="h6">
                            My Notes
                        </Typography>
                        <FormComponent
                            schema={notesSchema}
                            uischema={notesUiSchema}
                            data={notesData}
                            showFormErrors={showErrors}
                            onChange={({ data }) => setNotesData(data)}
                        />
                        <Button
                            fullWidth
                            label="Add New Note"
                            onClick={addNewNote}
                            className={classes.noteButton}
                        />
                        <Box
                            id="notes-scroll-container"
                            mt={1}
                            maxHeight="180px"
                            overflow="scroll"
                            sx={{ scrollBehavior: 'smooth' }}>
                            {!notes.length ? (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    height="180px">
                                    <Typography variant="h5">No Notes Yet!</Typography>
                                </Box>
                            ) : (
                                notes.map((note, index) => (
                                    <Box
                                        my={1}
                                        py={notesLoading ? 0 : 1}
                                        px={notesLoading ? 0 : 0.5}
                                        key={index}
                                        sx={{
                                            borderRadius: 0.5,
                                            background: '#FFF7AF',
                                            border: '1px solid #FBE082',
                                        }}>
                                        {notesLoading ? (
                                            <Skeleton animation="wave" height="100%" width="100%" />
                                        ) : (
                                            <Typography variant="h6">{note}</Typography>
                                        )}
                                    </Box>
                                ))
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid> */}
        </Box>
    );
}
