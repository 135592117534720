// @ts-nocheck
import { createReducer } from '@reduxjs/toolkit';

//actions
import { updateGlobalData } from '../actions/globalActions';

const initialState = { data: {}, loading: true };

export const globalDataReducer = createReducer(initialState, {
    [updateGlobalData]: (state, action) => {
        state.data = action.payload;
        state.loading = false;
    },
});
