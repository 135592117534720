import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2, 0, 2, 2),
        border: '1px solid #78A5FF !important',
        borderRadius: theme.spacing(2),
    },
    subAgentSummaryContainer: {
        paddingLeft: '14px',
        paddingRight: '7px',
    },
    buttonsContainer: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            position: 'fixed',
            bottom: 0,
            margin: 0,
            padding: theme.spacing(2.2, 0),
            justifyContent: 'space-around',
            boxShadow: theme.shadows[20],
            zIndex: 2,
            backgroundColor: theme.palette.common.white,
        },
    },
    submitButtonContainer: {
        width: theme.spacing(25),
        display: 'flex',
        justifyContent: (props) => (props.previousButton ? 'flex-start' : 'center'),
        [theme.breakpoints.down('sm')]: { width: theme.spacing(20) },
    },
    buttonWidth: {
        width: theme.spacing(22.5),
        fontSize: '14px',
        fontWeight: '600',
    },
    submitButton: {
        fontWeight: '600',
        backgroundColor: '#00C853',
        color: '#FFFF',
        '&:hover': {
            backgroundColor: '#3D8944',
        },
    },
    previousButtonContainer: {
        width: theme.spacing(25),
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: { width: theme.spacing(20) },
    },
    previousButton: {
        '&:hover': {
            backgroundColor: '#0008051a',
        },
    },
}));
