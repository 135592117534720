import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
//mui
import { Grid, Box, Typography, Dialog, IconButton } from '@mui/material';
import { TimelineDot } from '@mui/lab';

//style
import { useStyles } from './styles';

import { isB2bAdmin, isPartner, isPSA, isSubAgent } from '@/rbac';
//components
import { getIcon } from 'azent-storybook/dist';

//utils
import { toastMessages } from '@/utils/toastMessages';

// actions
import { updateStudentInStore } from '@/store/slices/studentSlice';
import { setToast } from '@/store/slices/toastSlice';

// APIs
import { generateEmail } from '@/api';

//constants
import constants from '@/constants';

export default function StudentReleventDetails({
    isModalOpen,
    handleClose,
    email,
    mobile,
    partnerData,
    studentId,
    point_of_contact,
    inbox_credentials,
    psaInfo,
    id,
    sub_agent,
    sub_agent_id,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const { partnerEmail, partnerMobile, partnerName } = partnerData;
    const { first_name, last_name } = sub_agent;

    const gotoLogin = () => {
        return window.open(constants.roundCubeURL, '_blank');
    };

    const generateCustomEmail = () => {
        generateEmail(id)
            .then((res) => {
                dispatch(
                    updateStudentInStore({
                        id,
                        key: 'inbox_credentials',
                        value: res.data.inbox_credentials,
                    }),
                );

                dispatch(
                    updateStudentInStore({
                        id,
                        key: 'comments',
                        value: res.data.comments,
                    }),
                );
            })
            .catch(() => {
                dispatch(setToast(toastMessages.backendIssue));
            });
    };

    const renderPasword = (password) => {
        if (password) {
            return password
                .split('')
                .reduce((acc) => {
                    return (acc += '*');
                }, '*')
                .slice(0, -1);
        }
        return '-';
    };

    return (
        <>
            <Dialog open={isModalOpen} onClose={() => handleClose()} maxWidth={'sm'} fullWidth>
                <Grid container direction="column">
                    <Grid item container direction="row" justifyContent="space-between">
                        <Grid item className={classes.dialogHeader}>
                            All Details
                        </Grid>
                        <Grid p={2} item>
                            <IconButton
                                onClick={() => handleClose()}
                                size="sm"
                                sx={{ fontSize: '5px' }}
                                color="secondary">
                                {getIcon('close', 'inherit', 'small', classes.closeIcon)}
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        container
                        direction="column"
                        wrap="nowrap"
                        className={classes.detailWrapper}>
                        <Grid container xs item className={classes.rowWrapper} spacing={3}>
                            <Grid item xs={6}>
                                <Box className={classes.detailContainer}>
                                    <Typography className={classes.detailHeader}>
                                        Student Details
                                    </Typography>
                                    <Box display="flex" alignItems="center">
                                        <TimelineDot className={classes.timeline} />
                                        <Typography className={classes.detailContent}>
                                            ID {studentId ? studentId : '-'}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <TimelineDot className={classes.timeline} />
                                        <Typography className={classes.detailContent}>
                                            {mobile ? mobile : '-'}
                                        </Typography>
                                    </Box>

                                    <Box display="flex" alignItems="center">
                                        <TimelineDot className={classes.timeline} />
                                        <Typography
                                            className={`${classes.detailContent} ${classes.ellipsis}`}>
                                            {email ? email : '-'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            {!(isPartner() || isSubAgent()) && (
                            <Grid item xs={6}>
                                <Box className={classes.detailContainer}>
                                    <Typography className={classes.detailHeader}>
                                        Email Credentials
                                    </Typography>
                                    {(isPSA() || isPartner() || isSubAgent()) &&
                                        !inbox_credentials?.inbox_email && (
                                            <Typography className={`${classes.naLabel}`}>
                                                NA
                                            </Typography>
                                        )}
                                    {!(isPSA() || isPartner() || isSubAgent()) &&
                                        !inbox_credentials?.inbox_email && (
                                            <Typography
                                                onClick={() => generateCustomEmail()}
                                                className={classes.emailLabel}>
                                                Generate Email
                                            </Typography>
                                        )}
                                    {inbox_credentials?.inbox_email && (
                                        <>
                                            <Box display="flex" alignItems="center">
                                                <Typography
                                                    style={{ marginTop: '3px' }}
                                                    className={`${classes.naLabel} ${classes.ellipsis}`}>
                                                    {inbox_credentials?.inbox_email || '-'}
                                                </Typography>
                                            </Box>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between">
                                                <Typography
                                                    className={`${classes.naLabel} ${classes.passHidden}`}>
                                                    {!showPassword
                                                        ? renderPasword(
                                                              inbox_credentials?.inbox_password,
                                                          )
                                                        : inbox_credentials?.inbox_password || '-'}
                                                </Typography>
                                                <Box onClick={() => setShowPassword(!showPassword)}>
                                                    {getIcon(
                                                        !showPassword ? 'Eye' : 'Offeye',
                                                        'disabled',
                                                        'small',
                                                        classes.icon,
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between">
                                                <Typography
                                                    onClick={() => {
                                                        gotoLogin();
                                                    }}
                                                    className={`${classes.naLabel} ${classes.emailLabel}`}>
                                                    Go to Login Page
                                                </Typography>
                                                <Box
                                                    className={classes.copyWrapper}
                                                    onClick={() =>
                                                        navigator.clipboard.writeText(
                                                            constants.roundCubeURL,
                                                        )
                                                    }>
                                                    {getIcon(
                                                        'copy',
                                                        'inherit',
                                                        'small',
                                                        classes.icon,
                                                    )}
                                                    <span className={classes.copy}>copy</span>
                                                </Box>
                                            </Box>
                                        </>
                                    )}
                                </Box>
                            </Grid>)}

                            <Grid item xs={6}>
                                <Box className={classes.detailContainer}>
                                    <Typography className={classes.detailHeader}>
                                        Point Of Contact
                                    </Typography>
                                    <Box display="flex" alignItems="center">
                                        <TimelineDot className={classes.timeline} />
                                        <Typography className={classes.detailContent}>
                                            {point_of_contact &&
                                            point_of_contact.first_name &&
                                            point_of_contact.last_name
                                                ? point_of_contact.first_name +
                                                  ' ' +
                                                  point_of_contact.last_name
                                                : '-'}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <TimelineDot className={classes.timeline} />
                                        <Typography className={classes.detailContent}>
                                            {point_of_contact && point_of_contact.mobile
                                                ? point_of_contact.mobile
                                                : '-'}
                                        </Typography>
                                    </Box>

                                    <Box display="flex" alignItems="center">
                                        <TimelineDot className={classes.timeline} />
                                        <Typography
                                            className={`${classes.detailContent} ${classes.ellipsis}`}>
                                            {point_of_contact && point_of_contact.email
                                                ? point_of_contact.email
                                                : '-'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={6}>
                                <Box className={classes.detailContainer}>
                                    <Typography className={classes.detailHeader}>
                                        Business Manager
                                    </Typography>
                                    <Box display="flex" alignItems="center">
                                        <TimelineDot className={classes.timeline} />
                                        <Typography className={classes.detailContent}>
                                            {psaInfo && psaInfo.name ? psaInfo.name : '-'}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center">
                                        <TimelineDot className={classes.timeline} />
                                        <Typography className={classes.detailContent}>
                                            {psaInfo && psaInfo.mobile ? psaInfo.mobile : '-'}
                                        </Typography>
                                    </Box>

                                    <Box display="flex" alignItems="center">
                                        <TimelineDot className={classes.timeline} />
                                        <Typography
                                            className={`${classes.detailContent} ${classes.ellipsis}`}>
                                            {psaInfo && psaInfo.email ? psaInfo.email : '-'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            {isB2bAdmin() && (
                                <Grid item xs={6}>
                                    <Box className={classes.detailContainer}>
                                        <Typography className={classes.detailHeader}>
                                            Referral Partner Details
                                        </Typography>
                                        <Box display="flex" alignItems="center">
                                            <TimelineDot className={classes.timeline} />
                                            <Typography className={classes.detailContent}>
                                                {partnerName ? partnerName : '-'}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                            <TimelineDot className={classes.timeline} />
                                            <Typography className={classes.detailContent}>
                                                {partnerMobile ? partnerMobile : '-'}
                                            </Typography>
                                        </Box>

                                        <Box display="flex" alignItems="center">
                                            <TimelineDot className={classes.timeline} />
                                            <Typography
                                                className={`${classes.detailContent} ${classes.ellipsis}`}>
                                                {partnerEmail ? partnerEmail : '-'}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                            {sub_agent_id && (
                                <Grid item xs={6}>
                                    <Box className={classes.detailContainer}>
                                        <Typography className={classes.detailHeader}>
                                            Counsellor / Branch
                                        </Typography>
                                        <Box display="flex" alignItems="center">
                                            <TimelineDot className={classes.timeline} />
                                            <Typography className={classes.detailContent}>
                                                {`${first_name ? first_name : '-'} ${
                                                    last_name ? last_name : ''
                                                }`}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                            <TimelineDot className={classes.timeline} />
                                            <Typography className={classes.detailContent}>
                                                {sub_agent
                                                    ? sub_agent.is_active == true
                                                        ? 'Active'
                                                        : 'Inactive'
                                                    : '-'}
                                            </Typography>
                                        </Box>

                                        <Box display="flex" alignItems="center">
                                            <TimelineDot className={classes.timeline} />
                                            <Typography
                                                className={`${classes.detailContent} ${classes.ellipsis}`}>
                                                {sub_agent.email ? sub_agent.email : '-'}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                            <TimelineDot className={classes.timeline} />
                                            <Typography
                                                className={`${classes.detailContent} ${classes.ellipsis}`}>
                                                {sub_agent.mobile ? sub_agent.mobile : '-'}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
}

StudentReleventDetails.propTypes = {
    isModalOpen: PropTypes.boolean,
    handleClose: PropTypes.func,
    email: PropTypes.string,
    mobile: PropTypes.string,
    partnerData: PropTypes.object,
    psaInfo: PropTypes.object,
    studentId: PropTypes.string,
    point_of_contact: PropTypes.object,
    inbox_credentials: PropTypes.object,
    id: PropTypes.string,
    sub_agent: PropTypes.object,
    sub_agent_id: PropTypes.string,
};
