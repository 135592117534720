// Constants
import { getWorkLevelToDocumentCategoriesMapping } from '@/utils/documents';
import shortAddressDetailsUiSchema from './short-address-details-ui';

const largeScreenUiSchema = (config) => {
    return {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'Control',
                scope: '#/properties/has_work_experience',
                label: 'Would you like to Add your Work Experience?',
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/work_info/properties/years_of_experience',
                        label: 'Total years of experience',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/work_info/properties/industry',
                    },
                ],
                rule: {
                    effect: 'SHOW',
                    condition: {
                        scope: '#/properties/has_work_experience',
                        schema: {
                            const: 'Yes',
                        },
                    },
                },
            },
            {
                type: 'Control',
                scope: '#/properties/work_history',
                label: 'Add the work experience here.',
                options: {
                    detail: {
                        type: 'VerticalLayout',
                        elements: [
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/name_of_the_organisation',
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/position',
                                    },
                                ],
                            },
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/from_date',
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/to_date',
                                    },
                                ],
                            },
                            shortAddressDetailsUiSchema().options.detail.elements[0],
                        ],
                    },
                },
                rule: {
                    effect: 'SHOW',
                    condition: {
                        scope: '#/properties/has_work_experience',
                        schema: {
                            const: 'Yes',
                        },
                    },
                },
            },
            {
                type: 'Control',
                scope: '#/properties/work_documents',
                label: 'Upload Documents',
                options: {
                    labelHighlight: `(Add the documents for the above work)`,
                    component: 'resource',
                    selectCategoryLabel: 'Choose Document Category',
                    uniqueCategory: false,
                    fileCategories: getWorkLevelToDocumentCategoriesMapping(config),
                },
                rule: {
                    effect: 'SHOW',
                    condition: {
                        scope: '#/properties/has_work_experience',
                        schema: {
                            const: 'Yes',
                        },
                    },
                },
            },
        ],
    };
};

const smallScreenUiSchema = () => {};

export default (config) => {
    return {
        smallScreenUiSchema: smallScreenUiSchema(config),
        largeScreenUiSchema: largeScreenUiSchema(config),
    };
};
