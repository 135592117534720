import React from 'react';
import { useDispatch } from 'react-redux';

//mui
import { Grid, Box, Typography, TextField, Link, IconButton } from '@mui/material';
import InputAdornment from '@material-ui/core/InputAdornment';

//utils
import Loader from '@/components/shared/Loader';
import routePaths from '@/utils/route-paths';
import { toastMessages } from '@/utils/toastMessages';

// navigation action
import { goToRoute } from '@/store/actions/navigationActions';

//actions
import { userGetAndStore } from '@/store/actions/userActions';

//style
import { useStyles } from './style';

//components
import ImageCarousel from './imageCarousel';
import { Button, getIcon } from 'azent-storybook/dist';

import { loginUserViaPassword, loginUserViaOtp, sendLoginOTP } from '@/utils/user/authUser';

const RESENT_OTP_TIME = 30;

// previous Loging now called sign in
export default function SignIn() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [curTime, setCurTime] = React.useState(RESENT_OTP_TIME);
    const [loading, setLoading] = React.useState(false);
    const [otpSent, setOtpSent] = React.useState(false);
    const [isResendOtp, setResendOTP] = React.useState(true);
    const [loginViaOTP, setLoginViaOTP] = React.useState(false);
    let [serverErrorMessage, setServerErrorMessage] = React.useState('');
    let [values, setValues] = React.useState({
        username: '',
        password: '',
        otp: '',
        showPassword: false,
    });
    let [errorTexts, setErrorTexts] = React.useState({
        username: '',
        password: '',
        otp: '',
    });

    React.useEffect(() => {
        const instanceOFTime = setInterval(() => {
            if (otpSent && curTime !== 0) {
                setCurTime(curTime - 1);
            }
        }, 1000);
        return () => clearInterval(instanceOFTime);
    }, [curTime, otpSent]);

    const validateEmail = (email) => {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email,
        );
    };

    const handleValidations = (isResendOtp) => {
        let validations = {
            username: '',
            password: '',
            otp: '',
        };
        const { username, password, otp } = values;
        if (!isNaN(username) && (username.length <= 9 || username.length > 10)) {
            validations.username = 'Mobile Number Should Be 10 digits only';
        }
        if (username.length === 0) {
            if (loginViaOTP) validations.username = 'Please Enter Your Email / Phone Number';
            else validations.username = 'Please Enter Your Email Id';
        }
        if (isNaN(username) && !validateEmail(username)) {
            validations.username = 'Email Id Should Be Valid';
        }
        if (loginViaOTP) {
            if (otpSent && !isResendOtp && otp.length !== 6)
                validations.otp = 'Incorrect OTP, please check again';
        } else {
            if (password.length === 0) {
                validations.password = 'Please Enter Your Password';
            }
        }
        setErrorTexts(validations);
        if (validations.username || validations.password || validations.otp) {
            return false;
        }
        return true;
    };

    const sendOTP = async (isResendOtp) => {
        setLoading(true);
        setServerErrorMessage('');
        if (handleValidations(isResendOtp)) {
            const { username } = values;
            const sendOtpResult = await sendLoginOTP(
                !isNaN(username) ? `+91${username}` : username,
            );
            if (sendOtpResult.success) {
                setOtpSent(true);
                setCurTime(RESENT_OTP_TIME);
                setResendOTP(true);
            } else {
                setServerErrorMessage(sendOtpResult.error.message);
            }
        }
        setLoading(false);
    };

    const onSubmit = async () => {
        setLoading(true);
        setServerErrorMessage('');
        let loginResult = {};
        if (handleValidations()) {
            const { username, otp, password } = values;
            if (loginViaOTP) {
                loginResult = await loginUserViaOtp(
                    !isNaN(username) ? `+91${username}` : username,
                    otp,
                );
            } else {
                loginResult = await loginUserViaPassword(username, password);
            }
            if (
                loginResult &&
                (loginResult.success == false || loginResult.data.success == false)
            ) {
                setServerErrorMessage(loginResult.error.message);
            } else if (!loginResult) {
                setServerErrorMessage(toastMessages.backendIssue.message);
            } else {
                dispatch(userGetAndStore());
                dispatch(goToRoute(routePaths.DASHBOARD));
            }
        }
        setLoading(false);
    };
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <Box className={classes.rootContainer}>
            {loading ? (
                <Loader />
            ) : (
                <Grid container alignItems="center">
                    <Grid item xs={5}>
                        <ImageCarousel classes={classes} />
                    </Grid>
                    <Grid item xs={2}>
                        <div className={classes.verticalLine}></div>
                    </Grid>
                    <Grid item xs={5} pl={5}>
                        <Box className={classes.formHeaderContainer}>
                            <Typography className={classes.loginTitle}>Log in</Typography>
                        </Box>
                        <Box className={classes.formContentContainer}>
                            {serverErrorMessage ? (
                                <Box mt={2} mb={2} textAlign="center">
                                    <Typography color="error">{serverErrorMessage}</Typography>
                                </Box>
                            ) : null}
                            <Box className={classes.fieldContainer} mb={3}>
                                <Typography className={classes.inputTitle}>
                                    {loginViaOTP ? 'Email Id / Mobile' : 'Email Id'}
                                </Typography>
                                <TextField
                                    className={classes.textFieldRoot}
                                    value={values['username']}
                                    name="username"
                                    autoFocus
                                    onChange={(event) => {
                                        setValues({
                                            ...values,
                                            username: event.target.value,
                                        });
                                    }}
                                    helperText={
                                        errorTexts['username'] ? errorTexts['username'] : null
                                    }
                                    error={errorTexts['username'] ? true : false}
                                    variant="outlined"
                                />
                            </Box>
                            {/* timer text & Buttons for Send and resend otp */}
                            {loginViaOTP ? (
                                otpSent ? (
                                    curTime !== 0 ? (
                                        <Box display="flex" justifyContent="flex-end">
                                            <Typography
                                                className={
                                                    classes.otpName
                                                }>{`Resend OTP in ${curTime} seconds`}</Typography>
                                        </Box>
                                    ) : (
                                        <Box
                                            display="flex"
                                            flexDirection={'row'}
                                            justifyContent="flex-end">
                                            <>
                                                <Typography
                                                    pt={0.3}
                                                    variant="body2"
                                                    sx={{ color: ' #6D6D6D' }}>
                                                    Didn’t receive OTP?
                                                </Typography>
                                                &nbsp;
                                                <Link
                                                    onClick={() => {
                                                        setResendOTP(!isResendOtp);
                                                        sendOTP('Resend OTP', true);
                                                    }}
                                                    size="small"
                                                    color="primary"
                                                    underline="false"
                                                    component="button"
                                                    variant="body2">
                                                    <Typography>RESEND OTP</Typography>
                                                </Link>
                                            </>
                                        </Box>
                                    )
                                ) : (
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={sendOTP}
                                        label={'Send OTP'}
                                        className={classes.greenButton}
                                        sx={{ mt: 3 }}
                                    />
                                )
                            ) : null}

                            <Box className={classes.fieldContainer}>
                                {loginViaOTP && otpSent && (
                                    <Typography className={classes.inputTitle}>
                                        Enter OTP
                                    </Typography>
                                )}
                                {!loginViaOTP && (
                                    <Typography className={classes.inputTitle} pt={1}>
                                        Password
                                    </Typography>
                                )}

                                {/* Text boxes for for password / OTP */}
                                {loginViaOTP && otpSent && (
                                    <TextField
                                        className={classes.textFieldRoot}
                                        variant="outlined"
                                        autoComplete="current-password"
                                        type={'password'}
                                        value={values[loginViaOTP ? 'otp' : 'password']}
                                        onChange={(event) => {
                                            let valueName = loginViaOTP ? 'otp' : 'password';
                                            setValues({
                                                ...values,
                                                [valueName]: event.target.value,
                                            });
                                        }}
                                        helperText={
                                            loginViaOTP
                                                ? errorTexts['otp']
                                                    ? errorTexts['otp']
                                                    : null
                                                : errorTexts['password']
                                                ? errorTexts['password']
                                                : null
                                        }
                                        error={
                                            loginViaOTP
                                                ? errorTexts['otp']
                                                    ? true
                                                    : false
                                                : errorTexts['password']
                                                ? true
                                                : false
                                        }
                                        onKeyPress={(event) => event.key === 'Enter' && onSubmit()}
                                    />
                                )}
                                {!loginViaOTP && (
                                    <TextField
                                        className={classes.textFieldRoot}
                                        variant="outlined"
                                        autoComplete="current-password"
                                        type={values.showPassword ? 'text' : 'password'}
                                        value={values[loginViaOTP ? 'otp' : 'password']}
                                        onChange={(event) => {
                                            let valueName = loginViaOTP ? 'otp' : 'password';
                                            setValues({
                                                ...values,
                                                [valueName]: event.target.value,
                                            });
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}>
                                                        {values.showPassword ? (
                                                            <Box>
                                                                {getIcon(
                                                                    'Offeye',
                                                                    'inherit',
                                                                    'small',
                                                                )}
                                                            </Box>
                                                        ) : (
                                                            <Box>
                                                                {getIcon('Eye', 'inherit', 'small')}
                                                            </Box>
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        helperText={
                                            loginViaOTP
                                                ? errorTexts['otp']
                                                    ? errorTexts['otp']
                                                    : null
                                                : errorTexts['password']
                                                ? errorTexts['password']
                                                : null
                                        }
                                        error={
                                            loginViaOTP
                                                ? errorTexts['otp']
                                                    ? true
                                                    : false
                                                : errorTexts['password']
                                                ? true
                                                : false
                                        }
                                        onKeyPress={(event) => event.key === 'Enter' && onSubmit()}
                                    />
                                )}

                                {loginViaOTP ? null : (
                                    <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
                                        <Button
                                            variant={'text'}
                                            className={classes.forgotPassLink}
                                            sx={{ mt: 1, pb: 0 }}
                                            label={'Forgot password?'}
                                            onClick={() =>
                                                dispatch(goToRoute(routePaths.LOGIN_VIA_OTP))
                                            }
                                        />
                                    </Box>
                                )}
                            </Box>
                            {/* Buttons for Login and Login via OTP*/}
                            <Box className={classes.buttonsContainer}>
                                {loginViaOTP ? (
                                    otpSent ? (
                                        <Button
                                            variant="contained"
                                            onClick={onSubmit}
                                            label={'Login via OTP'}
                                            className={classes.greenButton}
                                            sx={{ mt: 6 }}
                                        />
                                    ) : null
                                ) : (
                                    <Button
                                        variant="contained"
                                        onClick={onSubmit}
                                        label={'Login'}
                                        className={classes.greenButton}
                                        sx={{ mt: 2 }}
                                    />
                                )}

                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        if (loginViaOTP) {
                                            setOtpSent(false);
                                        }
                                        setLoginViaOTP(!loginViaOTP);
                                        setErrorTexts({
                                            username: '',
                                            password: '',
                                            otp: '',
                                        });
                                        setServerErrorMessage('');
                                    }}
                                    label={
                                        loginViaOTP ? 'Login Via Email & Password' : 'Login Via OTP'
                                    }
                                    className={classes.whiteButton}
                                    sx={{ mt: 3 }}
                                />
                            </Box>
                            <Box display="flex" justifyContent="center" mt={2}>
                                <Button
                                    variant={'text'}
                                    className={classes.forgotPassLink}
                                    label={'Go Back'}
                                    onClick={() => dispatch(goToRoute(routePaths.LOGIN))}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}
