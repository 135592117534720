import { get, cloneDeep } from 'lodash';

// Utils
import { getDateFormat } from '@/utils/date';
import { capitalizeText } from '@/utils/textModifiers';
import { getDocumentsSerializedByCategory, sortDocuments } from '@/utils/documents';
import { getApplicationsWithSDS } from '@/props-constants/student/list-student/index';

const deserializeStudent = (profile) => {
    const lodashGet = (key, defaultValue) => get(profile, key, defaultValue);

    let requiredDocuments = lodashGet('documents.all_documents', []);

    let categoriesKeysMapping = lodashGet('required_documents', []).map((category) => {
        const label = category.subcategory === '' ? category.category : category.subcategory;
        return {
            label: label,
            parentLabel: category.label,
            value: label,
            required: get(category, 'required', null),
            multiple: true,
            additionalDropdown: get(category, 'additional_dropdown', []),
        };
    });
    const applicationDocuments = [];
    lodashGet('applications', []).forEach((application) => {
        const documents = get(application, 'documents', []);
        const comments = get(application, 'comments', []);

        if (documents && documents.length > 0) {
            documents.forEach((document) => {
                if (document.file && document.file.category) {
                    applicationDocuments.push({
                        ...document.file,
                        application_id: get(application, '_id', ''),
                        university_name: get(application, 'program_info.university_name'),
                        intake: `${get(application, 'intake.month')}-${get(
                            application,
                            'intake.year',
                        )}`,
                        programName: get(application, 'program_info.name'),
                    });
                }
            });
        }
        if (comments && comments.length > 0) {
            comments.forEach((comment) => {
                comment.files.forEach((file) => {
                    if (file && file.category) {
                        applicationDocuments.push({
                            ...file,
                            application_id: get(application, '_id', ''),
                            university_name: get(application, 'program_info.university_name'),
                            intake: `${get(application, 'intake.month')}-${get(
                                application,
                                'intake.year',
                            )}`,
                            programName: get(application, 'program_info.name'),
                            label: 'Applications',
                            isCommentDocument: true,
                        });
                    }
                });
            });
        }
    });

    requiredDocuments = requiredDocuments.concat(applicationDocuments);

    let showGTEUploadOption = false;
    lodashGet('desired_profile.interest_countries', []).forEach((country) => {
        if (country === 'Australia') {
            showGTEUploadOption = true;
        }
    });
    lodashGet('applications').forEach((application) => {
        if (get(application, 'university_location_info.country') === 'Australia') {
            showGTEUploadOption = true;
        }
    });

    let data = {};
    if (profile) {
        data = {
            id: lodashGet('_id', ''),
            _id: lodashGet('_id', ''),
            user_id: lodashGet('user_id', ''),
            crm_uid: lodashGet('crm_uid', '-'),
            onboarding_completed: lodashGet('onboarding_completed', false),
            first_name: lodashGet('first_name', ''),
            last_name: lodashGet('last_name', ''),
            mobile: lodashGet('mobile', ''),
            alternate_mobile: lodashGet('alternate_mobile', ''),
            email: lodashGet('email', ''),
            gender: lodashGet('gender', null),
            date_of_birth: getDateFormat(lodashGet('date_of_birth', null)),
            nationality: lodashGet('nationality', null),
            marital_status: lodashGet('marital_status', null),
            address: lodashGet('address', {}),
            education_history: lodashGet('education_history', {}),
            work_history: serializeWorkHistory(
                serializeDateFormat(lodashGet('work_history', []), ['from_date', 'to_date']),
            ),
            created_at: lodashGet('created_at', ''),
            modified_at: lodashGet('modified_at', ''),
            partner__name: lodashGet('partner__name', ''),
            partner_id: lodashGet('partner_id', ''),
            partner__email: lodashGet('partner__email', ''),
            partner__mobile: lodashGet('partner__mobile', ''),
            counsellor__name: lodashGet('counsellor__name', ''),
            counsellor__email: lodashGet('counsellor__email', ''),
            counsellor__mobile: lodashGet('counsellor__mobile', ''),
            documents: lodashGet('documents', {}),
            work_info: lodashGet('work_info', {}),
            is_tests_opted: lodashGet('is_tests_opted', ''),
            test_scores: serializeDateFormat(lodashGet('test_scores', []), ['test_date']),
            desired_profile: lodashGet('desired_profile', {}),
            has_work_experience: lodashGet('has_work_experience', ''),
            profile_completion_percentage: lodashGet('profile_completion_percentage', 0),
            comments: lodashGet('comments', []),
            shortlists: lodashGet('shortlists', []),
            categorizedApplications: getApplicationsWithSDS(lodashGet('applications', [])),
            applications: lodashGet('applications', []),
            profile_status: lodashGet('profile_status', []),
            passport: {
                passport_number: lodashGet('passport.passport_number', null),
                issued_on: getDateFormat(lodashGet('passport.issued_on', null)),
                valid_till: getDateFormat(lodashGet('passport.valid_till', null)),
                place_of_issue: lodashGet('passport.place_of_issue', null),
                name_on_passport: lodashGet('passport.name_on_passport', null),
                surname_on_passport: lodashGet('passport.surname_on_passport', null),
            },
            background_info: {
                ever_applied_immigration: lodashGet(
                    'background_info.ever_applied_immigration',
                    false,
                ),
                immigration_details: lodashGet('background_info.immigration_details', ''),
                ever_refused_visa: lodashGet('background_info.ever_refused_visa', false),
                visa_refusal_reason: lodashGet('background_info.visa_refusal_reason', ''),
                ever_convicted_criminal_offence: lodashGet(
                    'background_info.ever_convicted_criminal_offence',
                    false,
                ),
                criminal_offence_details: lodashGet('background_info.criminal_offence_details', ''),
                has_medical_condition: lodashGet('background_info.has_medical_condition', false),
                medical_condition_details: lodashGet(
                    'background_info.medical_condition_details',
                    '',
                ),
            },
            point_of_contact: {
                first_name: lodashGet('point_of_contact.first_name', null),
                last_name: lodashGet('point_of_contact.last_name', null),
                mobile: lodashGet('point_of_contact.mobile', null),
                whatsapp_number: lodashGet('point_of_contact.whatsapp_number', null),
                email: lodashGet('point_of_contact.email', null),
                designation: lodashGet('point_of_contact.designation', null),
                copy_number: null,
            },
            sub_agent_id: lodashGet('sub_agent_id', null),
            sub_agent: {
                first_name: lodashGet('sub_agent.first_name', null),
                last_name: lodashGet('sub_agent.last_name', null),
                mobile: lodashGet('sub_agent.mobile', null),
                email: lodashGet('sub_agent.email', null),
                is_active: lodashGet('sub_agent.is_active', null),
            },
            inbox_credentials: {
                inbox_email: lodashGet('inbox_credentials.inbox_email', null),
                inbox_password: lodashGet('inbox_credentials.inbox_password', null),
                forward_to: lodashGet('inbox_credentials.forward_to', []),
            },
            psa_name: lodashGet('psa_name', ''),
            psa_email: lodashGet('psa_email', ''),
            psa_mobile: lodashGet('psa_mobile', ''),

            graduation_degree_mark_sheet: lodashGet('documents.graduation_degree_mark_sheet'),
            masters_degree_mark_sheet: lodashGet('documents.masters_degree_mark_sheet'),

            categoriesKeysMapping: categoriesKeysMapping,

            ...getDocumentsSerializedByCategory(lodashGet('documents.all_documents', [])),
            required_documents: sortDocuments(requiredDocuments),
            showGTEUploadOption,
        };
    }

    return data;
};

const serializeDateFormat = (data, keys) => {
    let clonedData = cloneDeep(data);
    return clonedData.map((item) => {
        Object.keys(item).forEach((key) => {
            if (keys.indexOf(key) !== -1) {
                item[key] = getDateFormat(get(item, key, ''));
            }
        });
        return item;
    });
};

const serializeWorkHistory = (data = []) => {
    return data.map(({ address, ...exp }) => (address ? { ...exp, ...address } : exp));
};

const deserializeWorkHistory = (data = []) => {
    return data.map(({ city, country, ...exp }) => ({
        ...exp,
        address: { city, country },
    }));
};

const getFullName = (student, defaultText = '') => {
    let firstName = '';
    let lastName = '';
    firstName = student.first_name ? student.first_name : defaultText;
    lastName = student.last_name ? student.last_name : defaultText;
    return `${capitalizeText(firstName)} ${capitalizeText(lastName)}`;
};

const deserializeInterestRegions = (data) =>
    (data || []).map((region) => {
        let label = region.city || '';
        if (region.state) label = label ? label + `, ${region.state}` : region.state;
        if (region.country) label = label ? label + `, ${region.country}` : region.country;
        return { ...region, label };
    });

const serializeInterestRegions = (data) =>
    (data || []).map(({ city, state, country }) => ({ city, state, country }));

export {
    deserializeStudent,
    serializeWorkHistory,
    deserializeWorkHistory,
    getFullName,
    deserializeInterestRegions,
    serializeInterestRegions,
};
