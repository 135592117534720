import PropTypes from 'prop-types';
import React from 'react';

// material
import { Grid, useTheme, useMediaQuery } from '@mui/material';

//components
import NotSupportedView from '@/components/errors/NotSupportedView';

export default function BlankLayout({ children }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    if (isMobile) return <NotSupportedView />;

    return <Grid>{children}</Grid>;
}

BlankLayout.propTypes = {
    children: PropTypes.any.isRequired,
};
