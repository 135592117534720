import { courseFinderPaths } from './route-paths';
import { get } from 'lodash';
import { isB2bAdmin } from '@/rbac';
import { EDUCATION_BACKEND_TO_ENUM_MAP } from '@/props-constants/student-schema/student-form-schemas/schema';
import { deserializeInterestRegions } from './deserializers/student-deserializer';

export const getFilterDataFromSearchParams = (search) => {
    const filtersData = {};

    if (search.get('min_tuition_fee'))
        filtersData['fee']['min'] = Number(search.get('min_tuition_fee')) || 0;
    if (search.get('max_tuition_fee'))
        filtersData['fee']['max'] = Number(search.get('max_tuition_fee')) || 100000;
    if (search.get('min_length'))
        filtersData['programLength']['min'] = Number(search.get('min_length')) || 0;
    if (search.get('max_length'))
        filtersData['programLength']['max'] = Number(search.get('max_length')) || 60;
    if (search.get('type')) filtersData['type'] = search.get('type');
    if (search.get('client_type')) filtersData['client_type'] = search.get('client_type');
    if (search.get('level')) filtersData['level'] = search.get('level');
    if (search.get('fee')) filtersData['application_fee'] = search.get('fee');
    if (search.get('university_ids'))
        filtersData.universities = search
            .get('university_ids')
            .split(',')
            .map((i) => ({ _id: i }));
    if (search.get('countries')) filtersData.countries = search.get('countries').split(',');
    if (search.get('intakes')) filtersData.intakes = search.get('intakes').split(',');
    if (search.get('keywords')) filtersData.categories = search.get('keywords').split(',');
    if (search.get('eligibility_criteria')) {
        const { exam, scores } = JSON.parse(search.get('eligibility_criteria'));
        filtersData.exam = exam;
        filtersData.scores = scores;
    }
    if (search.get('q')) filtersData.global_search = search.get('q');
    if (search.get('backlogs')) filtersData.backlogs = Number(search.get('backlogs'));
    if (search.get('cgpa')) filtersData.cgpa = Number(search.get('cgpa'));
    if (search.get('highest_education'))
        filtersData.highest_education = search.get('highest_education');
    if (search.get('scale')) filtersData.scale = Number(search.get('scale'));
    if (search.get('level_and_type'))
        filtersData.level_and_type = search.get('level_and_type').split(',');
    if (search.get('interest_regions'))
        filtersData.interest_regions = JSON.parse(search.get('interest_regions'));
    return filtersData;
};

export const getCourseFinderTabIndexFromPath = (path) => {
    switch (path) {
        case courseFinderPaths.VIEW: {
            return 0;
        }
        case courseFinderPaths.RECOMMENDED_COURSES: {
            return 1;
        }
        case courseFinderPaths.SHORTLISTS: {
            return 2;
        }
        default: {
            return 0;
        }
    }
};

export const getCourseFinderPathFromTabIndex = (index) => {
    switch (index) {
        case 0: {
            return courseFinderPaths.VIEW;
        }
        case 1: {
            return courseFinderPaths.RECOMMENDED_COURSES;
        }
        case 2: {
            return courseFinderPaths.SHORTLISTS;
        }
        default: {
            return courseFinderPaths.VIEW;
        }
    }
};

export const getCourseFinderQueries = function (filters, searchText, profileId, per_page, page) {
    let query = [];
    if (page) {
        query.push({ key: 'page', value: page });
    }
    if (per_page) {
        query.push({ key: 'per_page', value: per_page });
    }
    if (searchText) {
        query.push({ key: 'q', value: searchText });
    }
    if (profileId) {
        query.push({ key: 'profileId', value: profileId });
    }
    if (filters.global_search) {
        query.push({ key: 'q', value: filters.global_search });
    }
    if (filters.universities && filters.universities.length) {
        query.push({
            key: 'university_ids',
            value: filters.universities.map((i) => i._id),
        });
    }
    if (filters.countries && filters.countries.length) {
        query.push({
            key: 'countries',
            value: filters.countries,
        });
    }
    if (filters.intakes && filters.intakes.length) {
        query.push({
            key: 'intakes',
            value: filters.intakes,
        });
    }
    if (filters.type) {
        query.push({ key: 'type', value: filters.type });
    }
    if (filters.client_type) {
        query.push({ key: 'client_type', value: filters.client_type });
    }
    if (filters.level) {
        query.push({ key: 'level', value: filters.level });
    }
    if (filters.application_fee) {
        query.push({ key: 'fee', value: filters.application_fee });
    }
    if (
        filters.fee &&
        ![null, undefined].includes(filters.fee.min) &&
        ![null, undefined].includes(filters.fee.max)
    ) {
        query.push({ key: 'min_tuition_fee', value: filters.fee.min });
        query.push({ key: 'max_tuition_fee', value: filters.fee.max });
    }
    if (
        filters.programLength &&
        ![null, undefined].includes(filters.programLength.min) &&
        ![null, undefined].includes(filters.programLength.max)
    ) {
        query.push({ key: 'min_length', value: filters.programLength.min });
        query.push({ key: 'max_length', value: filters.programLength.max });
    }
    if (filters.categories && filters.categories.length) {
        query.push({ key: 'keywords', value: filters.categories });
    }
    if (filters.exam) {
        const elegibilityCriteria = {
            exam: filters.exam,
            scores: filters.scores || {},
        };
        query.push({
            key: 'eligibility_criteria',
            value: JSON.stringify(elegibilityCriteria),
        });
    } else if (filters.eligibility_criteria) {
        query.push({
            key: 'eligibility_criteria',
            value: filters.eligibility_criteria,
        });
    }
    if (filters.backlogs) {
        query.push({ key: 'backlogs', value: filters.backlogs });
    }
    if (filters.cgpa) {
        query.push({ key: 'cgpa', value: filters.cgpa });
    }
    if (filters.highest_education) {
        query.push({ key: 'highest_education', value: filters.highest_education });
    }
    if (filters.scale) {
        query.push({ key: 'scale', value: filters.scale });
    }
    if (filters.level_and_type) {
        query.push({ key: 'level_and_type', value: filters.level_and_type });
    }
    if (filters.interest_regions && filters.interest_regions.length) {
        query.push({
            key: 'interest_regions',
            value: JSON.stringify(filters.interest_regions),
        });
    }
    return query;
};

export const getTuitionCost = (course) => {
    return get(course, 'costs.tuition_fee', null)
        ? `${get(course, 'costs.currency', '')} ${
              get(course, 'costs.tuition_fee', null)
                  ? Number(get(course, 'costs.tuition_fee', null)).toLocaleString()
                  : '-'
          }`
        : '';
};

export const colorCodes = ['#FFB684', '#B5E9B5', '#D6E5FC'];

export const getStudentDefaultFilters = (profileData) => {
    if (!profileData) return {};
    if (!profileData._id) return {};
    let filters = { profileId: profileData._id, client_type: 'Client' };
    if (!profileData.desired_profile) return filters;
    let countries = profileData.desired_profile.interest_countries;
    let interest_regions = profileData.desired_profile.interest_regions || [];
    let eligibility_criteria = profileData.test_scores || [];
    let month = profileData.desired_profile.intake_month;
    let degree_types_new = profileData.desired_profile.degree_types_new || [];
    let categories = get(profileData, 'desired_profile.keywords', []);
    let backlogs = get(profileData, 'education_history.backlogs_count', '');
    let highest_education = get(profileData, 'education_history.highest_education_level', '');
    let cgpa = highest_education.length
        ? get(
              profileData,
              `education_history.${EDUCATION_BACKEND_TO_ENUM_MAP[highest_education]}.gpa_or_percentage`,
              '',
          )
        : '';
    let scale = highest_education.length
        ? get(
              profileData,
              `education_history.${EDUCATION_BACKEND_TO_ENUM_MAP[highest_education]}.overall_percentage`,
              '',
          )
        : '';

    if (degree_types_new.length) filters['level_and_type'] = degree_types_new;
    if (countries) filters['countries'] = countries;
    if (interest_regions.length)
        filters['interest_regions'] = deserializeInterestRegions(interest_regions);
    if (eligibility_criteria.length && isB2bAdmin()) {
        filters['eligibility_criteria'] = JSON.stringify({
            exam: eligibility_criteria[0].name,
            scores: eligibility_criteria[0].scores,
        });
    }
    if (month) filters['intakes'] = [month];
    if (backlogs) filters['backlogs'] = backlogs;
    if (highest_education) filters['highest_education'] = highest_education;
    if (cgpa) filters['cgpa'] = cgpa;
    if (scale) {
        filters['scale'] = scale;
    } else if (cgpa) {
        filters['scale'] = cgpa > 15 ? '100' : '15';
    }
    if (categories.length) {
        filters['categories'] = categories;
    }

    return filters;
};

export const getIntakeType = (intake, intakeDetails) => {
    return intakeDetails.some((intakeDetail) => {
        return intakeDetail?.intake_name?.includes(intake) && intakeDetail.accepting_application;
    });
};
