// Utils
import cookieActions from './cookie';
/**
 * FIXME: Not using localstorage in auth because subdomains do not share localstorage,
 * on logout local storage token is not cleared from auth,
 * Solution: use a clear local storage param in redirect url on logout */

//import localStorageActions from './local-storage';

export function getAuthTokenValue() {
    let value = null;
    // if (localStorageActions.checkLocalStorageEnabled()) {
    //     value = localStorageActions.getAuthFromLocalStorage();
    //     if (value && value !== 'null') return value;
    // }
    if (cookieActions.checkCookieEnabled()) {
        value = cookieActions.getAuthCookie();
        if (value) {
            return value;
        }
    }
    return null;
}

export function setAuthToken(tokenValue) {
    // if (localStorageActions.checkLocalStorageEnabled()) {
    //     return localStorageActions.setAuthTokenInLocalStorage(tokenValue);
    // }
    if (cookieActions.checkCookieEnabled()) {
        return cookieActions.setAuthTokenCookie(tokenValue);
    }
}

export function removeAuthToken() {
    //localStorageActions.deleteAuthTokenFromLocalStorage();
    cookieActions.deleteAuthTokenCookie();
}
