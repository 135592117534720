import React from 'react';
import PropTypes from 'prop-types';

// mui
import { Box, Typography, Skeleton } from '@mui/material';

// styles
import { useStyles } from './styles';

export default function InfoCard({ label, value, onClickCard, loading }) {
    const classes = useStyles();

    return (
        <Box onClick={onClickCard} className={classes.cardContainer}>
            <Typography variant="h6">{label ? label : <Skeleton width="50%" />}</Typography>
            <Typography className={classes.value} variant="h1">
                {!loading && (value || value == 0) ? value : <Skeleton width="40px" />}
            </Typography>
        </Box>
    );
}

InfoCard.propTypes = {
    label: PropTypes.any.isRequired,
    value: PropTypes.any,
    onClickCard: PropTypes.func,
    loading: PropTypes.bool,
};
