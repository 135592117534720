import { get } from 'lodash';
import { getUniversity } from '@/api';

const allowedKeys = [
    'financial_aid',
    'placements',
    'faculty',
    'infrastructure',
    'accommodation',
    'location',
    'weather',
    'alumni',
];

const asyncDeserializeShortlist = async (program) => {
    const lodashGet = (key, defaultValue) => get(program, key, defaultValue);
    const universityId = lodashGet('university_info.id', null);
    if (universityId) {
        return await getUniversity(universityId).then((res) => {
            const universityGet = (key, defaultValue) => get(res.data, key, defaultValue);

            let accordionData = [];
            Object.keys(universityGet('details', {}))
                .sort((a, b) => allowedKeys.indexOf(a) - allowedKeys.indexOf(b))
                .forEach((i) => {
                    if (allowedKeys.includes(i)) {
                        accordionData.push({
                            title: universityGet(`details.${i}.title`, ''),
                            content: universityGet(`details.${i}.description`, ''),
                        });
                    }
                    return null;
                });

            let data = {
                _id: lodashGet('_id', ''),
                university: {
                    id: universityGet('_id', ''),
                    name: universityGet('name', ''),
                    slug: universityGet('slug', ''),
                    logoSrc: universityGet('images.logo.original', ''),
                    rank: lodashGet('program_info.rank', ''),
                    country: universityGet('location.country', ''),
                    city: universityGet('location.city', ''),
                    about: {
                        shortContent:
                            universityGet('details.about.description', '').substr(0, 350) + '...',
                        longContent: universityGet('details.about.description', ''),
                    },
                    accordionData,
                    onClick: () => {},
                    recommendation_reasons: universityGet('recommendation_reasons.0', ''),
                    recommendation_type: universityGet('recommendation_type', ''),
                },
                slug: '',
                name: lodashGet('program_info.name', ''),
                length: lodashGet('program_info.duration', ''),
                length_in_months: '',
                type: lodashGet('program_info.type', ''),
                level: lodashGet('program_info.level', ''),
                rankings: [lodashGet('program_info.rank', '')],
                categories: [],
                intake_months: lodashGet('program_info.intakes', []),
                is_published: false,
                published_date: lodashGet('created_at', ''),
                id: lodashGet('_id', ''),
                category: lodashGet('program_info.categories.0', ''),
                duration: lodashGet('program_info.duration', ''),
                intakes: lodashGet('program_info.intakes', []),
                tuitionCost: lodashGet('program_info.tuition_fee', ''),
                applicationCost: lodashGet('program_info.application_fee', ''),
                applicationDeadline: lodashGet('program_info.application_deadline', ''),
                level_and_type: lodashGet('program_info.level_and_type', 'N/A'),
            };
            return data;
        });
    }
};

export { asyncDeserializeShortlist };
