// @ts-nocheck
import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { store, history } from './store';

// components
import Root from './components/Root';

// utils

import * as Sentry from '@sentry/react';

// constants
import constants from '@/constants';

// initailize sentry
if (!__DEV__) {
    Sentry.init({
        dsn: constants.sentryDsn,
        environment: constants.envName,
        release: process.env.sentryReleaseVersion,
    });
}

render(
    <AppContainer>
        <Root store={store} history={history} />
    </AppContainer>,
    document.getElementById('app'),
);

if (module.hot) {
    module.hot.accept('./components/Root', () => {
        const NewRoot = require('./components/Root').default;
        render(
            <AppContainer>
                <NewRoot store={store} history={history} />
            </AppContainer>,
            document.getElementById('app'),
        );
    });
}
