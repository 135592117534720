import React, { useState, useMemo, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// comps
import { Tabs, Button, ActionBox } from 'azent-storybook/dist';
import ShortlistTab from './shortlistTab';
import Loader from '@/components/shared/Loader';
import AddCustomProgram from './add-custom-program';

// mui
import { Box, Backdrop } from '@mui/material';

// utils
import { getActiveShortlists, getRejectedShortlists, downloadShortlist } from '@/utils/shortlists';
import { isB2bAdmin } from '@/rbac';
import { setToast } from '@/store/slices/toastSlice';
import { toastMessages } from '@/utils/toastMessages';
import { colorCodes } from '@/utils/courseFinder';
import { asyncDeserializeShortlist } from '@/utils/deserializers/shortlists-deserializer';

// styles
import { useStyles } from './styles';

// APIs
import { sendEmailToPartner, getApplicationPriorityData } from '@/api';

export default function Shortlist({
    id,
    profile,
    loading,
    setApplyModalProps,
    setShowProgram,
    isDisableCreateApplication,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [shortlistTab, setShortlistTab] = useState(0);
    const [downloadingShortlist, setDownloadingShortlist] = useState(false);
    const [isFormOpen, setFormOpen] = useState(false);
    const [priorityData, setPriorityData] = useState(['']);

    const user = useSelector((state) => state.userData);

    const activeShortlists = useMemo(() => getActiveShortlists(profile), [profile]);

    const rejectedShortlists = useMemo(() => getRejectedShortlists(profile), [profile]);

    const onClickShortlistCard = (course, index) => {
        asyncDeserializeShortlist(course)
            .then((res) => setShowProgram({ ...res, logoColor: colorCodes[index % 3] }))
            .catch(() => dispatch(setToast(toastMessages.backendIssue)));
    };
    // NOTE : MOVE TO UTILS/SHORTLISTS
    const sendEmail = () => {
        if (profile) {
            setDownloadingShortlist(true);
            sendEmailToPartner(profile._id)
                .then(() => {
                    setDownloadingShortlist(false);
                    dispatch(setToast(toastMessages.emailSent));
                })
                .catch(() => {
                    setDownloadingShortlist(false);
                    dispatch(setToast(toastMessages.backendIssue));
                });
        }
    };

    useEffect(() => {
        if (id) {
            getApplicationPriorityData(id)
                .then((res) => {
                    setPriorityData(res.data.priorities);
                })
                .catch(() => {
                    setPriorityData(['']);
                });
        }
    }, [id]);

    if (!id)
        return (
            <Box px={2} mt={2}>
                <ActionBox
                    raised={false}
                    title={{
                        text: 'Please Select A Student To Show Shortlists!',
                        variant: 'h5',
                    }}
                />
            </Box>
        );

    return (
        <Box pr={3.5} mb={5} sx={{ overflow: 'scroll' }}>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={downloadingShortlist}>
                <Loader />
            </Backdrop>
            {loading || user.loading ? (
                <Loader />
            ) : (
                <>
                    {/* send email /download button */}
                    <Box className={classes.emailButtonContainer}>
                        {!isB2bAdmin() && activeShortlists.length ? (
                            <Button
                                label="Download Shortlist"
                                color="success"
                                onClick={() =>
                                    downloadShortlist(profile._id, setDownloadingShortlist)
                                }
                                className={classes.emailButton}
                            />
                        ) : isB2bAdmin() && activeShortlists.length ? (
                            <>
                                <Button
                                    label="Download Active Shortlist"
                                    variant="outlined"
                                    color="success"
                                    onClick={() =>
                                        downloadShortlist(profile._id, setDownloadingShortlist)
                                    }
                                    className={classes.downloadButton}
                                />
                                <Button
                                    label="Send Email to RP"
                                    color="success"
                                    onClick={sendEmail}
                                    className={classes.emailButton}
                                />
                            </>
                        ) : (
                            <Box sx={{ py: '20px' }} />
                        )}
                    </Box>
                    <Tabs
                        value={shortlistTab}
                        setValue={setShortlistTab}
                        orientation="vertical"
                        classes={{
                            tab: classes.customTab,
                            tabs: classes.customTabs,
                        }}
                        tabsProps={{
                            centered: false,
                        }}
                        verticalGridSize={{ tab: 2, content: 10 }}
                        tabList={[
                            {
                                label: `Active Shortlist (${activeShortlists.length})`,
                                content: () => (
                                    <>
                                        <ShortlistTab
                                            profile={profile}
                                            shortlists={activeShortlists}
                                            onClickShortlistCard={onClickShortlistCard}
                                            setDownloadingShortlist={setDownloadingShortlist}
                                            showShortlistButton={
                                                !isB2bAdmin() && activeShortlists.length
                                            }
                                            showEmailButton={
                                                isB2bAdmin() && activeShortlists.length
                                            }
                                            setApplyModalProps={setApplyModalProps}
                                            isDisableCreateApplication={isDisableCreateApplication}
                                            priorityData={priorityData}
                                        />
                                        {!isFormOpen && isB2bAdmin(user.userData) && (
                                            <Box
                                                width="100%"
                                                display="flex"
                                                justifyContent="center">
                                                <Button
                                                    color="success"
                                                    label="Add Custom Programs"
                                                    variant="outlined"
                                                    onClick={() => {
                                                        setFormOpen(!isFormOpen);
                                                    }}
                                                    className={classes.customButton}
                                                />
                                            </Box>
                                        )}
                                        {isFormOpen && (
                                            <AddCustomProgram
                                                setFormOpen={setFormOpen}
                                                profileID={id}
                                            />
                                        )}
                                    </>
                                ),
                            },
                            {
                                label: `Rejected Shortlist (${rejectedShortlists.length})`,
                                content: () => (
                                    <ShortlistTab
                                        profile={profile}
                                        shortlists={rejectedShortlists}
                                        isRejected={true}
                                        onClickShortlistCard={onClickShortlistCard}
                                        isDisableCreateApplication={isDisableCreateApplication}
                                    />
                                ),
                            },
                        ]}
                    />
                </>
            )}
        </Box>
    );
}

Shortlist.propTypes = {
    id: PropTypes.string,
    profile: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    setApplyModalProps: PropTypes.func,
    setShowProgram: PropTypes.func,
    isDisableCreateApplication: PropTypes.bool,
};
