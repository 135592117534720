import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: '#D6E3FF',
    },
    icon: {
        fontSize: theme.spacing(2),
        color: theme.palette.text.disabled,
    },
    personIcon: {
        fontSize: theme.spacing(2),
        color: theme.palette.text.disabled,
        borderRadius: '50%',
        border: 'solid 1.5px #6D6D6D',
        verticalAlign: 'middle',
        marginRight: theme.spacing(0.7),
    },
    applicationLoader: {
        width: theme.spacing(14),
        height: theme.spacing(3),
        margin: theme.spacing(0.5, 1),
        borderRadius: theme.spacing(3),
    },
    colorCardContainer: {
        padding: '4px 16px',
    },
    createAppButton: {
        fontSize: '14px',
        fontWeight: '600',
        borderRadius: '10px',
        color: 'white',
        lineHeight: '1.75',
        fontFamily: 'Montserrat',
        padding: '8px 20px',
        height: '50px',
        backgroundColor: '#00c853',
        '&:hover': {
            backgroundColor: '#3D8944',
        },
        '&.Mui-disabled': {
            pointerEvents: 'auto',
        },
    },
    containerStudentBlue: {
        padding: '4px 12px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#E5EDFF',
        border: '1px solid #749BEB',
    },
    containerStudentRed: {
        padding: '4px 12px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#FFE2DCF0',
        border: '1px solid #E08A8A',
    },
    containerStudentGreen: {
        padding: '4px 12px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#E9FFE9',
        border: '1px solid #75D675',
    },
    containerStudentYellow: {
        padding: '4px 12px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#FFEBD3',
        border: '1px solid #F89B6D',
    },
    containerStudentPurple: {
        padding: '4px 12px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#E4DBFFF0',
        border: '1px solid #A288F1',
    },
    titleStudentBlue: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#749BEB',
        whiteSpace: 'pre-wrap !important',
    },
    titleStudentRed: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#E08A8A',
        whiteSpace: 'pre-wrap !important',
    },
    titleStudentGreen: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#75D675',
        whiteSpace: 'pre-wrap !important',
    },
    titleStudentYellow: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#F89B6D',
        whiteSpace: 'pre-wrap !important',
    },
    titleStudentPurple: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#A288F1',
        whiteSpace: 'pre-wrap !important',
    },
    ownerDetailsContainer: {
        display: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingTop: '6px',
        paddingBottom: '12px',
        whiteSpace: 'nowrap',
    },
    disabledText: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#6D6D6D',
    },
    centerHorizontal: {
        display: 'flex',
        justifyContent: 'center',
    },
    centerVertical: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        verticalAlign: 'middle',
    },
    detailsText: {
        fontSize: '14px',
        fontWeight: 500,
    },
    centerElement: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    detailLink: {
        fontSize: '15px',
        color: '#78A5FF',
        fontWeight: '600',
        cursor: 'pointer',
    },
}));
