import {
    loginViaOtpApi,
    loginViaPasswordApi,
    sendLoginOtpApi,
    setPasswordApi,
    createUserApi,
    resetPasswordPartnerApi,
} from '@/api';

//utils
import { setAuthToken, removeAuthToken, getAuthTokenValue } from '@/utils/auth-token-utils';
import getParameterByName from '@/utils/user/getQueryParams';
import { getStudentInfoFromToken } from '@/utils/user/TokenParser';

const userType = 'partner';

export const createUser = async (userDetails) => {
    try {
        let res = {};
        res = await createUserApi(userDetails, userType);
        if (res && res.data && res.data.success && res.data.data && res.data.data.access_token) {
            setAuthToken(res.data.data.access_token);
            return res;
        } else return res;
    } catch (e) {
        return e.response.data;
    }
};

export const loginUserViaPassword = async (username, password) => {
    try {
        const res = await loginViaPasswordApi({
            username,
            password,
        });
        if (res && res.data && res.data.success && res.data.data && res.data.data.access_token) {
            setAuthToken(res.data.data.access_token);
            return res;
        } else return res;
    } catch (e) {
        return e.response.data;
    }
};

export const loginUserViaOtp = async (username, otp, redirectUser = true, userType = 'partner') => {
    try {
        const res = await loginViaOtpApi({
            username,
            otp,
            userType,
        });
        if (res && res.data && res.data.success && res.data.data && res.data.data.access_token) {
            setAuthToken(res.data.data.access_token);
            if (redirectUser) {
                return res;
            } else {
                return {
                    success: true,
                };
            }
        }
    } catch (e) {
        return e.response.data;
    }
};

export async function sendLoginOTP(username, userType = 'partner') {
    try {
        const res = await sendLoginOtpApi({ username, userType });
        if (res && res.data && res.data.success) {
            return res.data;
        }
    } catch (e) {
        return e.response.data;
    }
}

export async function setPassword(password) {
    try {
        const accessToken =
            getParameterByName('OneTimeAccessToken', window.location.href) || getAuthTokenValue();
        let userID = getParameterByName('userID', window.location.href);
        if (!userID) {
            const userInfo = getStudentInfoFromToken(accessToken);
            userID = userInfo.id;
        }
        const res = await setPasswordApi(userID, password, accessToken);
        if (res && res.data && res.data.success && res.data.data) {
            removeAuthToken();
            return res.data;
        }
    } catch (e) {
        removeAuthToken();
        return e.response.data;
    }
}

//will not remove auth token and redirect to dashboard direct
export async function setNewUserPassword(password) {
    try {
        const accessToken =
            getParameterByName('OneTimeAccessToken', window.location.href) || getAuthTokenValue();
        let userID = getParameterByName('userID', window.location.href);
        if (!userID) {
            const userInfo = getStudentInfoFromToken(accessToken);
            userID = userInfo.id;
        }
        const res = await setPasswordApi(userID, password, accessToken);
        if (res && res.data && res.data.success && res.data.data) {
            return res.data;
        }
    } catch (e) {
        removeAuthToken();
        if (e.response && e.response.data) return e.response.data;
    }
}

// will reset password using reset_token from query params
export async function resetPassword(password, token) {
    try {
        const res = await resetPasswordPartnerApi({ reset_token: token, password: password });
        if (res && res.data && res.data.success && res.data.data) {
            return res.data;
        }
    } catch (e) {
        removeAuthToken();
        return e.response.data;
    }
}
