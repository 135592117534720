/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import { get } from 'lodash';

//MUI
import { Switch, Typography } from '@mui/material';

export const getRawColumns = (handleOpen) => [
    {
        Header: 'Id',
        Footer: 'Id',
        accessor: (c) => `${get(c, 'id', '')}`,
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: false,
        show: false,
    },
    {
        Header: 'Counsellor / Branch name',
        Footer: 'Counsellor / Branch name',
        accessor: (c) => `${get(c, 'first_name', '')} ${get(c, 'last_name', '-')}`,
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: true,
        show: true,
    },
    {
        Header: 'Email id',
        Footer: 'Email id',
        accessor: (c) => `${get(c, 'email', '-')}`,
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: true,
        show: true,
    },
    {
        Header: 'Mobile',
        Footer: 'Mobile',
        accessor: (c) => `${get(c, 'mobile', '-')}`,
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: true,
        show: true,
    },
    // {
    //     Header: 'Total Students',
    //     Footer: 'Total Students',
    //     accessor: (c) => `${get(c, 'total_student', '-')}`,
    //     Cell: ({ value }) => (
    //         <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
    //             {value}
    //         </Typography>
    //     ),
    //     showInit: false,
    //     show: false,
    // },
    // {
    //     Header: 'Total Application',
    //     Footer: 'Total Application',
    //     accessor: (c) => `${get(c, 'total_application', '-')}`,
    //     Cell: ({ value }) => (
    //         <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
    //             {value}
    //         </Typography>
    //     ),
    //     showInit: false,
    //     show: false,
    // },
    {
        Header: 'Active/Inactive',
        Footer: 'Active/Inactive',
        accessor: (c) => c.is_active,
        Cell: ({ value, row }) => (
            <Switch
                checked={value}
                onClick={(_event) => {
                    _event.stopPropagation();
                    handleOpen(row);
                }}
            />
        ),
        showInit: true,
        show: true,
    },
    {
        Header: 'Created date',
        Footer: 'Created date',
        accessor: (c) => `${get(c, 'created_date', '-')}`,
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {moment(value).format('DD/MM/YYYY')}
            </Typography>
        ),
        showInit: true,
        show: true,
    },
    // {
    //     Header: 'Last login',
    //     Footer: 'Last login',
    //     accessor: (c) => `${get(c, 'last_login', '-')}`,
    //     Cell: ({ value }) => (
    //         <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
    //             {value}
    //         </Typography>
    //     ),
    //     showInit: false,
    //     show: false,
    // },
];
