import React from 'react';
import PropTypes from 'prop-types';

// mui
import {
    Box,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';

// styles
import { useStyles } from './style';

export default function Tiles({ tiles, currentTileProps, setCurrentTile, isTileDisabled }) {
    const classes = useStyles();
    return (
        <Box pr={3}>
            {tiles.map((tile, index) => (
                <Accordion
                    key={index}
                    className={
                        tile.title.indexOf('Application') >= 0
                            ? classes.application
                            : classes.container
                    }
                    sx={{
                        boxShadow:
                            currentTileProps.tileIndex === index
                                ? '5px 5px 10px rgba(0, 0, 0, 0.25)'
                                : 0,
                        backgroundColor:
                            currentTileProps.tileIndex === index ? 'white !important' : null,
                        border: currentTileProps.tileIndex === index ? '1px solid #78A5FF' : null,
                        // !important Accordian CSS
                        '& .MuiButtonBase-root': {
                            minHeight: '50px',
                            '&:hover': {
                                cursor: isTileDisabled(index) ? 'not-allowed' : 'pointer',
                            },
                        },
                        '& .MuiAccordionSummary-content': {
                            margin: 0,
                        },
                        '& .Mui-expanded': {
                            margin: 0,
                        },
                        '& .MuiAccordionSummary-content & .Mui-expanded': {
                            margin: 0,
                        },
                        '& .MuiButtonBase-root & .MuiAccordionSummary-root & .Mui-expanded': {
                            minHeight: '50px !important',
                        },
                    }}
                    expanded={currentTileProps.tileIndex === index}
                    onChange={() =>
                        tile.onClick
                            ? tile.onClick()
                            : !isTileDisabled(index) && setCurrentTile(index)
                    }>
                    <AccordionSummary
                        sx={{
                            root: {
                                height: '10px',
                            },
                            '& .MuiButtonBase-root': {
                                minHeight: '50px',
                            },
                            '& .MuiAccordionSummary-content': {
                                margin: 0,
                            },
                            '& .Mui-expanded': {
                                margin: 0,
                            },
                            '& .MuiAccordionSummary-content & .Mui-expanded': {
                                margin: 0,
                            },
                            '& .MuiButtonBase-root & .MuiAccordionSummary-root & .Mui-expanded': {
                                minHeight: '50px !important',
                            },
                        }}>
                        <Grid container>
                            <Grid item xs={10} sx={{ height: '25px' }}>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        '& .MuiTypography-root': {
                                            '&:hover': {
                                                cursor: isTileDisabled(index)
                                                    ? 'not-allowed'
                                                    : 'pointer',
                                            },
                                        },
                                    }}>
                                    {tile.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.subTitle} variant="h6">
                                    {tile.subTitle}
                                </Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    {tiles[index] && tiles[index].tileContent && (
                        <AccordionDetails
                            sx={{
                                pt: 0,
                                pb: 0,
                                px: 0.6,
                            }}>
                            <Box>{tiles[index].tileContent(currentTileProps)}</Box>
                        </AccordionDetails>
                    )}
                </Accordion>
            ))}
        </Box>
    );
}

Tiles.propTypes = {
    tiles: PropTypes.array,
    currentTileProps: PropTypes.object,
    setCurrentTile: PropTypes.func,
    isTileDisabled: PropTypes.func,
};

Tiles.defaultProps = {
    isTileDisabled: () => false,
};
