import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(0, 8, 0, 3),
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(8),
        },
    },
    headingText: {
        fontWeight: '500',
        fontSize: theme.spacing(2.25),
    },
    rightText: {
        fontSize: theme.spacing(1.5),
        float: 'right',
        color: theme.palette.success.main,
    },
    percentageText: {
        fontWeight: 700,
    },
}));
