import React from 'react';
import { useDispatch } from 'react-redux';

//mui
import { Grid, Box, Typography, TextField, Link } from '@mui/material';

//utils
import Loader from '@/components/shared/Loader';
import routePaths from '@/utils/route-paths';

// navigation action
import { goToRoute } from '@/store/actions/navigationActions';

//style
import { useStyles } from './style';

//components
import ImageCarousel from './imageCarousel';
import { Button } from 'azent-storybook/dist';
import TermsAndConditions from './TermsAndConditions';

// api
import { loginUserViaOtp, sendLoginOTP } from '@/utils/user/authUser';

const RESENT_OTP_TIME = 30;

export default function LoginWithOTP() {
    const classes = useStyles();
    const dispatch = useDispatch();

    let timer = null;
    const [otpSent, setOtpSent] = React.useState(false);
    const [isResendOtp, setResendOTP] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [serverErrorMessage, setServerErrorMessage] = React.useState('');
    let [values, setValues] = React.useState({ username: '', otp: '' });
    let [errorTexts, setErrorTexts] = React.useState({
        username: '',
        otp: '',
    });
    const [curTime, setCurTime] = React.useState(RESENT_OTP_TIME);

    React.useEffect(() => {
        const instanceOFTime = setInterval(() => {
            if (otpSent && curTime !== 0) {
                setCurTime(curTime - 1);
            }
        }, 1000);
        return () => clearInterval(instanceOFTime);
    }, [curTime, otpSent]);

    const handleValidations = (isResendOtp) => {
        let validations = {
            username: '',
            otp: '',
        };
        const { username, otp } = values;
        if (!isNaN(username) && (username.length <= 9 || username.length > 10)) {
            validations.username = 'Mobile Number Should Be 10 digits only';
        }
        if (username.length === 0) {
            validations.username = 'Please Enter Your Email / Phone Number';
        }
        if (isNaN(username) && !validateEmail(username)) {
            validations.username = 'Email Id Should Be Valid';
        }
        if (otpSent && !isResendOtp && otp.length !== 6) {
            validations.otp = 'Incorrect OTP, please check again';
        }
        setErrorTexts(validations);
        if (validations.username || validations.otp) {
            return false;
        }
        return true;
    };

    const sendOTP = async (isResendOtp) => {
        setServerErrorMessage('');
        setLoading(true);
        if (handleValidations(isResendOtp)) {
            let sendOtpResult = await sendLoginOTP(values.username);
            if (sendOtpResult.success) {
                setLoading(false);
                setOtpSent(true);
                setCurTime(RESENT_OTP_TIME);
                setResendOTP(true);
            } else {
                setServerErrorMessage(sendOtpResult.error.message);
            }
        }
        setLoading(false);
    };

    const onSubmit = async () => {
        setLoading(true);
        setServerErrorMessage('');
        let loginResult = {};
        if (handleValidations()) {
            loginResult = await loginUserViaOtp(values.username, values.otp, false);
            if (loginResult.success == false) {
                setServerErrorMessage(loginResult.error.message);
            } else {
                clearTimeout(timer);
                dispatch(goToRoute(routePaths.SET_PASSWORD));
            }
        }
        setLoading(false);
    };

    const validateEmail = (email) => {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email,
        );
    };

    return (
        <Box className={classes.rootContainer}>
            {loading ? (
                <Loader />
            ) : (
                <Grid container alignItems="center">
                    <Grid item xs={5}>
                        <ImageCarousel classes={classes} />
                    </Grid>
                    <Grid item xs={2}>
                        <div className={classes.verticalLine}></div>
                    </Grid>
                    <Grid item xs={5} pl={5}>
                        <Box className={classes.formHeaderContainer}>
                            <Typography className={classes.loginTitle}>Log in</Typography>
                        </Box>
                        <Box className={classes.formContentContainer}>
                            {serverErrorMessage ? (
                                <Box mt={2} mb={2} textAlign="center">
                                    <Typography color="error">{serverErrorMessage}</Typography>
                                </Box>
                            ) : null}
                            <Box className={classes.fieldContainer}>
                                <Typography className={classes.inputTitle}>
                                    {'Email Id / Mobile'}
                                </Typography>
                                <TextField
                                    className={classes.textFieldRoot}
                                    variant="outlined"
                                    id="username"
                                    value={values['username']}
                                    name="username"
                                    autoFocus
                                    onChange={(event) => {
                                        setValues({
                                            ...values,
                                            username: event.target.value,
                                        });
                                    }}
                                    helperText={
                                        errorTexts['username'] ? errorTexts['username'] : null
                                    }
                                    error={errorTexts['username'] ? true : false}
                                />
                            </Box>
                            {otpSent ? (
                                <Box
                                    display="flex"
                                    flexDirection={'row'}
                                    justifyContent="flex-end"
                                    sx={{ mt: 2 }}>
                                    {curTime !== 0 ? (
                                        <Box display="flex" justifyContent="flex-end">
                                            <Typography
                                                className={
                                                    classes.otpName
                                                }>{`Resend OTP in ${curTime} seconds`}</Typography>
                                        </Box>
                                    ) : (
                                        <>
                                            <Typography
                                                pt={0.3}
                                                variant="body2"
                                                sx={{ color: ' #6D6D6D' }}>
                                                Didn’t receive OTP?
                                            </Typography>
                                            &nbsp;
                                            <Link
                                                onClick={() => {
                                                    setResendOTP(!isResendOtp);
                                                    sendOTP('Resend OTP', true);
                                                }}
                                                size="small"
                                                color="primary"
                                                component="button"
                                                underline="false"
                                                variant="body2">
                                                <Typography>RESEND OTP</Typography>
                                            </Link>
                                        </>
                                    )}
                                </Box>
                            ) : (
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={sendOTP}
                                    label={'Send OTP'}
                                    className={classes.greenButton}
                                    sx={{ mt: 6 }}
                                />
                            )}
                            {otpSent ? (
                                <>
                                    <Box className={classes.fieldContainer}>
                                        <Typography className={classes.inputTitle}>
                                            {'Enter OTP'}
                                        </Typography>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name={'otp'}
                                            type={'password'}
                                            id={'otp'}
                                            value={values.otp}
                                            onChange={(event) =>
                                                setValues({
                                                    ...values,
                                                    otp: event.target.value,
                                                })
                                            }
                                            className={classes.textFieldRoot}
                                            helperText={
                                                errorTexts['otp'] ? errorTexts['otp'] : null
                                            }
                                            error={errorTexts['otp'] ? true : false}
                                        />
                                    </Box>

                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.greenButton}
                                        onClick={onSubmit}
                                        label={'Login via OTP'}
                                        sx={{ mt: 6 }}
                                    />
                                </>
                            ) : null}
                            <Box display="flex" justifyContent="center">
                                <Button
                                    variant={'text'}
                                    className={classes.forgotPassLink}
                                    label={'Go Back'}
                                    onClick={() => dispatch(goToRoute(routePaths.SIGNIN_PAGE))}
                                />
                            </Box>
                            <TermsAndConditions />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}
