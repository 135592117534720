let config = {
    envName: 'local',
    sentryDsn: 'https://04c4d198ed0b41109fe89d78d19f8e67@o409291.ingest.sentry.io/6750320',
    awsConfig: {
        awsKey: 'AKIA4KCCKZYLKVL63MFF',
        bucket: 'b-b-profile-documents-staging',
        region: 'ap-south-1',
        s3BucketBaseUrl: 'https://b-b-profile-documents-staging.s3.ap-south-1.amazonaws.com',
    },
    studentApi: 'https://staging-api.azent.com/',
    studentServiceApi: 'https://staging-student-api.azent.com',
    authApi: 'https://staging-api.azent.com/',
    universityApi: 'https://staging-api.azent.com/',
    partnerApi: 'https://staging-student-api.admissionbox.com',
    fileApi: 'https://staging-api.azent.com/',
    fileUploadURL: '/aws-request-sign-awssigningrequest-LDH4GFWVPV2T',
    authPlatformUrl: 'http://localhost:3000',
    authCookieName: 'azentPartnerUserData.local',
    cookieDomain: '.azent.com',
    notion: 'https://quilt-echo-a9d.notion.site/',
    cookiePath: '/',
    allowedFormats:
        'image/*,application/pdf,application/vnd.oasis.opendocument.text,text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/gzip,application/vnd.rar,application/zip,application/x-7z-compressed',
    pdfFormat: 'application/pdf',
    maxFileSize: 5242880,
    keywords: [
        'Computer Science',
        'MBA',
        'International Business Management',
        'Information Systems',
        'Business Analytics',
        'Data Analytics',
        'Data Science',
        'Hotel Management',
        'Biomedical',
        'Biotech',
        'Public Health',
        'Marketing',
        'Digital Marketing',
        'Advertising',
        'Branding',
        'Engineering Management',
        'Project Management',
        'Construction Management',
        'Mechanical Enginnering',
        'Cyber Security',
        'Information System',
        'Supply Chain',
        'Healthcare Management',
        'Finance',
        'Accounting',
        'Management',
        'Information Technology',
        'Data Science and Analytics',
        'MBA/Business Adminstration',
        'Bio Med/Tech',
        'Healthcare',
        'Mechanical Engineering',
        'MSc clinical pharmacy',
        'Mobile development',
        'Electrical Engineering',
        'Business Management',
        'Industrial Engineering',
        'HR',
        'IT',
        'Hotel, Tourism and Hospitality Management',
        'design',
    ],
    plagiarismServiceApi: 'https://staging-plagiarism-service.azent.com',
};

export default config;
