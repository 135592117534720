import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// api
import { getRecommendedProgramsForProfile, getApplicationPriorityData } from '@/api';

// mui
import { Box, Grid, Typography } from '@mui/material';

// comps
import { ActionBox } from 'azent-storybook/dist';
import { CourseCard } from '@/components/shared/CourseCard';

// utils
import { getTuitionCost, colorCodes } from '@/utils/courseFinder';
import { deserializeStudent } from '@/utils/deserializers/student-deserializer';
import { deserializeProgram } from '@/utils/deserializers/program-deserializer';
import {
    addToShortlist,
    courseAppliedv2,
    courseApplied,
    courseShortlisted,
} from '@/utils/shortlists';
import { goToRoute } from '@/store/actions/navigationActions';
import routePaths, { courseFinderPaths } from '@/utils/route-paths';
import { toastMessages } from '@/utils/toastMessages';

// assets
import recommendRobot from '@/assets/svg/robot-recommend.svg';

// styles
import { useStyles } from './styles';

// actions
import { addStudentToStore } from '@/store/slices/studentSlice';
import { setToast } from '@/store/slices/toastSlice';

export default function RecommendedCourses({
    id,
    profile,
    profileLoading,
    setApplyModalProps,
    setShowProgram,
    isDisableCreateApplication,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const location = useSelector((state) => state.router.location);
    const searchParams = new URLSearchParams(location.search);

    const [recommendedCourses, setRecommendedCourses] = useState([1, 2, 3, 4]);
    const [loading, setLoading] = useState(true);
    const [priorityData, setPriorityData] = useState(['']);

    const getRecommendedPrograms = (id) => {
        if (id && profile) {
            setLoading(true);
            getRecommendedProgramsForProfile(id)
                .then((res) => {
                    if (res.data) {
                        setRecommendedCourses(
                            res.data
                                .filter(
                                    (item) =>
                                        !courseApplied(profile, {
                                            ...item,
                                            program_info: { id: item._id },
                                        }),
                                )
                                .map((item) => deserializeProgram(item)),
                        );
                    }
                })
                .catch(() => {
                    dispatch(setToast(toastMessages.backendIssue));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const applyNow = (course) => {
        if (id)
            setApplyModalProps({
                course,
                profile_id: id,
                program_id: course._id,
                priorityData: priorityData,
            });
    };

    const redirectToShortlist = () => {
        dispatch(
            goToRoute(routePaths.COURSE_FINDER, {
                path: courseFinderPaths.SHORTLISTS,
                id: id,
            }),
        );
    };

    useEffect(() => {
        setRecommendedCourses([1, 2, 3, 4]);
        getRecommendedPrograms(id);
    }, [id, profile]);

    useEffect(() => {
        if (id) {
            getApplicationPriorityData(id)
                .then((res) => {
                    setPriorityData(res.data.priorities);
                })
                .catch(() => {
                    setPriorityData(['']);
                });
        }
    }, [id]);

    const shortlistProgram = (id, programs) => {
        if (id) {
            setLoading(true);
            addToShortlist(id, programs).then((res) => {
                dispatch(addStudentToStore(deserializeStudent(res.data)));
            });
        }
    };

    if (!id)
        return (
            <Box px={2} mt={2}>
                <ActionBox
                    raised={false}
                    title={{
                        text: 'Please Select A Student To Show recommendations!',
                        variant: 'h5',
                    }}
                />
            </Box>
        );

    return (
        <Box px={2}>
            <Box className={classes.recommendTag}>
                <Grid container className={classes.recommendRoot}>
                    <Grid item>
                        <img
                            style={{
                                color: 'inherit',
                                width: '50px',
                                height: '50px',
                                position: 'relative',
                                top: '-6px',
                            }}
                            src={recommendRobot}
                            alt="user"
                        />
                    </Grid>
                    <Grid item xs={10} sm={11} className={classes.textWrap}>
                        <Typography variant="subtitle2" className={classes.recommendTagTitle}>
                            <span>
                                Please note that these are AI-generated based on the details given
                                in your profile
                            </span>
                        </Typography>
                        <Typography variant="h2" className={classes.recommendTagSubtitle}>
                            AI can go only so far! For a more comprehensive list of recommendations,
                            reach out to our counselors.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            {recommendedCourses.length ? (
                recommendedCourses.map((course, index) => (
                    <Box key={index} py={1}>
                        <CourseCard
                            onClickName={() => {
                                setShowProgram({
                                    ...course,
                                    logoColor: colorCodes[index % 3],
                                });
                            }}
                            courseName={course.name}
                            university={course.university}
                            programLink={course.program_link}
                            loading={loading || profileLoading}
                            isShortlisted={profile && courseShortlisted(profile, course)}
                            isApplied={profile && courseAppliedv2(profile, course)}
                            disableButtons={Boolean(!id)}
                            intakes={course.intake_months || []}
                            intakeDetails={course.intakeDetails || []}
                            tuitionCost={getTuitionCost(course)}
                            color={colorCodes[index % 3]}
                            onClickAddToShortlist={() => shortlistProgram(id, course)}
                            applicationFee={course.applicationCost ? course.applicationCost : null}
                            onClickApplyNow={() => {
                                applyNow(course);
                            }}
                            redirectToShortlist={redirectToShortlist}
                            slugUrl={
                                course.university && course.university.slug
                                    ? course.university.slug
                                    : null
                            }
                            recommendReason={
                                course.university && course.university.recommendation_reasons
                                    ? course.university.recommendation_reasons
                                    : null
                            }
                            isDisableCreateApplication={isDisableCreateApplication}
                        />
                    </Box>
                ))
            ) : (
                <ActionBox
                    raised={false}
                    loading={profileLoading}
                    title={{
                        text: `Our AI couldn't find any suitable recommendations for you. Please explore more courses via course finder`,
                        variant: 'h5',
                    }}
                    buttons={[
                        {
                            label: 'Find Programs',
                            color: 'secondary',
                            onClick: () => {
                                dispatch(
                                    goToRoute(
                                        routePaths.COURSE_FINDER,
                                        {
                                            id: id,
                                            path: courseFinderPaths.VIEW,
                                        },
                                        { ...Object.fromEntries(searchParams) },
                                    ),
                                );
                            },
                        },
                    ]}
                />
            )}
        </Box>
    );
}

RecommendedCourses.propTypes = {
    id: PropTypes.string,
    profile: PropTypes.object,
    profileLoading: PropTypes.bool,
    setApplyModalProps: PropTypes.func,
    setShowProgram: PropTypes.func,
    isDisableCreateApplication: PropTypes.bool,
};
