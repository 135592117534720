import React from 'react';
import PropTypes from 'prop-types';

//mui
import { IconButton } from '@mui/material';
import Check from '@mui/icons-material/Check';

// styles
import { styled } from '@mui/material/styles';

//comps
import { getIcon } from 'azent-storybook/dist';

const QontoStepIconRoot = styled('div')(({ ownerState }) => ({
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: 'red',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: 'white',
        zIndex: 1,
        fontSize: 25,
        fontWeight: 700,
        backgroundColor: '#00C853',
        border: '#00C853 2px solid',
        borderRadius: '50%',
    },
    '& .QontoStepIcon-circle': {
        width: 16,
        height: 16,
        borderRadius: '50%',
        backgroundColor: '#A9A9A9',
    },
}));

export default function StepIcon({ active, completed, classes }) {
    return (
        <QontoStepIconRoot ownerState={{ active }} className={classes}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : !active ? (
                <div className={'QontoStepIcon-circle'} />
            ) : (
                <IconButton className={classes.myicon}>
                    {getIcon('expand-more', 'inherit', 'small', classes.innerCircle)}
                </IconButton>
            )}
        </QontoStepIconRoot>
    );
}

StepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    classes: PropTypes.any,
};
