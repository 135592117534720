import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    previewContainer: {
        height: '100vh',
        borderTop: '70px solid black',
        borderBottom: '70px solid black',
        borderLeft: '100px solid black',
        borderRight: '100px solid black',
    },
    imagePreview: {
        cursor: 'pointer',
        height: '100%',
        width: '100%',
    },
    imagePreviewContainer: {
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    imagePreviewLoder: {
        position: 'absolute',
        top: '351px',
        right: '600px',
    },
    iconContainer: {
        position: 'absolute',
        top: '20px',
        right: '100px',
        cursor: 'pointer',
        borderRadius: '4px',
        padding: '5px 10px',
        background: '#F5F6F7',
    },
    downloadImage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        '&:hover': {
            cursor: 'not-allowed',
        },
    },
    activeImage: {
        '&:hover': {
            cursor: 'pointer',
        },
        '& $downloadText': {
            color: '#3E8944',
        },
        '& $downloadIcon': {
            fill: '#3E8944',
        },
    },
    downloadIcon: {
        color: '#A7A7A7',
        fontSize: '26px',
    },
    downloadText: {
        fontWeight: '700',
        color: '#A7A7A7',
    },
}));
