import React from 'react';
import PropTypes from 'prop-types';

//mui
import { Typography, Paper, Grid } from '@mui/material';

//component
import GenericCarousel from '@/components/shared/generic-carousel';

//props
import { LoginProps } from '@/props-constants/LoginProps';

export default function ImageCarousel({ classes }) {
    const imageItems = LoginProps();

    return (
        <GenericCarousel>
            {imageItems.map((item, i) => (
                <Paper key={i} className={classes.carouselWrapper}>
                    <Grid xs={12}>
                        <img src={item.src} className={classes.image} />
                    </Grid>
                    <Typography pt={4} className={classes.title}>
                        {item.title}
                    </Typography>
                    <Typography className={classes.description}>{item.description}</Typography>
                </Paper>
            ))}
        </GenericCarousel>
    );
}

ImageCarousel.propTypes = {
    classes: PropTypes.object,
};
