import React from 'react';

//mui styles
import { useStyles } from './style';

//mui
import { Box, CardContent, Card, Divider, Skeleton } from '@mui/material';

//store
import { useSelector } from 'react-redux';

//utils
import Markdown from '@/utils/Markdown';

import PropTypes from 'prop-types'

export default function Faqs({ faq, profileLoading, studentServiceLoading }) {
    const classes = useStyles();
    const loaders = [1, 2];
    const FaqsLoading = useSelector((state) => state.serviceCards.loading);
    const loading = studentServiceLoading ? profileLoading : FaqsLoading;

    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                {loading
                    ? loaders.map((index) => (
                          <>
                              <Box
                                  component="div"
                                  align="left"
                                  key={index}
                                  className={classes.card}>
                                  <Box align="left" component="div">
                                      <Skeleton />
                                  </Box>
                                  <Box component="div" align="left">
                                      <Skeleton />
                                  </Box>
                              </Box>
                              {index < faq.length - 1 && (
                                  <Divider className={classes.divider} />
                              )}
                          </>
                      ))
                    : faq &&
                      faq.map(({ question, answer }, index, faq) => (
                          <>
                              <Box
                                  component="div"
                                  align="left"
                                  key={index}
                                  className={classes.card}>
                                  <Box align="left" component="div" style={{ fontWeight: 520 }}>
                                      {question}
                                  </Box>
                                  <Box component="div" align="left">
                                      <Markdown source={answer} />
                                  </Box>
                              </Box>
                              {index < faq.length - 1 ? (
                                  <Divider className={classes.divider} />
                              ) : (
                                  ``
                              )}
                          </>
                      ))}
            </CardContent>
        </Card>
    );
}



Faqs.propTypes = {
    faq:PropTypes.array,
    profileLoading:PropTypes.bool,
    studentServiceLoading:PropTypes.bool
};