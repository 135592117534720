import React from 'react';
import PropTypes from 'prop-types';

//mui
import { Box, Typography } from '@mui/material';

// styles
import { useStyles } from '../style';

export default function InstructionNotes({ notesList, warningNote, programFee, intakeNote }) {
    const classes = useStyles();

    const intakeNoteClass =
        intakeNote === 'Intake : Closed'
            ? classes.closedNote
            : intakeNote === 'Intake : Open'
            ? classes.openNote
            : classes.text;

    return (
        <Box mt={1} mr={-2.5} className={classes.notesList}>
            <Box mx={2} py={1}>
                {warningNote && (
                    <Typography variant="h6" className={classes.warningText}>
                        {warningNote}
                    </Typography>
                )}
                {programFee && (
                    <Typography variant="h6" className={classes.programFee}>
                        {programFee}
                    </Typography>
                )}
                {intakeNote && (
                    <Typography variant="h6" className={intakeNoteClass}>
                        {intakeNote}
                    </Typography>
                )}
                {notesList && (
                    <Box ml={1}>
                        {notesList.map((label, index) => (
                            <Typography variant="h6" key={index}>
                                {label}
                            </Typography>
                        ))}
                    </Box>
                )}
            </Box>
        </Box>
    );
}

InstructionNotes.propTypes = {
    notesList: PropTypes.array,
    warningNote: PropTypes.string,
    programFee: PropTypes.string,
    intakeNote: PropTypes.string,
};
