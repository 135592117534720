import { createSlice } from '@reduxjs/toolkit';

const initialState = { data: {}, assignedStudents: [] };

const subAgentSlice = createSlice({
    name: 'SUBAGENT',
    initialState,
    reducers: {
        addSubAgentToStore: (state, { payload }) => {
            state.data = payload;
        },
        addAssignedStudentsToStore: (state, { payload }) => {
            state.assignedStudents = payload;
        },
    },
});

export const { addSubAgentToStore, addAssignedStudentsToStore } = subAgentSlice.actions;
export default subAgentSlice.reducer;
