import React from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

// comps
import { Button, getIcon } from 'azent-storybook/dist';

// styles
import { useStyles } from './style';

// actions
import { goToRoute } from '@/store/actions/navigationActions';
import { addSubAgentToStore } from '@/store/slices/subAgentSlice';

// utils
import routePaths from '@/utils/route-paths';
import personAdd from '@/assets/person_add.svg';

export default function SubAgentNoData() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const GotoSubAgentCreate = () => {
        dispatch(addSubAgentToStore({}));
        dispatch(goToRoute(routePaths.SUB_AGENT_CREATE, {}, { step: 'personal-detail' }));
    };

    return (
        <Box className={classes.container}>
            <Box className={classes.iconWrapper}>
                <span className={classes.circle}></span>
                <img className={classes.userIcon} src={personAdd} alt="adduser" />
            </Box>
            <Box className={classes.label}>No users at this moment</Box>
            <Button
                onClick={GotoSubAgentCreate}
                variant="contained"
                color="success"
                label="Create New User"
                className={classes.createAppButton}
                startIcon={getIcon('add-circle', 'inherit', 'small')}
            />
        </Box>
    );
}
