import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// mui
import { Box, Typography } from '@mui/material';

export default function FormattedComments({ comment, classes, index }) {
    const [showMoreButtonToggle, setShowMoreButtonToggle] = useState(false);
    const [displayShowMoreLink, setDisplayShowMoreLink] = useState(true);
    const [commentMessage, setCommentMessage] = useState(['']);
    const [compressedMessage, setCompressedMessage] = useState(['']);
    const [expandedMessage, setExpandedMesssage] = useState(['']);

    const textLimit = 150;
    const singleLineCharacterLimit = 80;

    useEffect(() => {
        setShowMoreButtonToggle(false);
        setDisplayShowMoreLink(true);
        if (comment.includes('\n')) handleMultilineMessage(comment.split('\n'));
        else handleSingleLineMessage();
    }, [comment]);

    //display first two lines only in UI followed by Show More button
    const handleMultilineMessage = (commentText) => {
        let arr;
        commentText.length <= 2 && setDisplayShowMoreLink(false);
        if (commentText[0].length < singleLineCharacterLimit) {
            if (commentText[1].length < singleLineCharacterLimit) {
                arr = commentText.slice(0, 2);
            } else {
                arr = [commentText[0]];
                arr.push([commentText[1].slice(0, singleLineCharacterLimit)]);
                setDisplayShowMoreLink(true);
            }
        } else {
            arr = [commentText[0].slice(0, textLimit)];
            setDisplayShowMoreLink(true);
        }
        setCompressedMessage(arr);
        setExpandedMesssage(comment.split('\n'));
        commentText.length >= 2 ? setCommentMessage(arr) : setCommentMessage(comment.split('\n'));
    };

    const handleSingleLineMessage = () => {
        if (comment.length > textLimit) {
            setDisplayShowMoreLink(true);
            setCommentMessage([comment.slice(0, textLimit)]);
        } else {
            setDisplayShowMoreLink(false);
            setCommentMessage([comment]);
        }
        setCompressedMessage([comment.slice(0, textLimit)]);
        setExpandedMesssage([comment]);
    };

    const showMoreHandler = () => {
        setShowMoreButtonToggle(!showMoreButtonToggle);
        showMoreButtonToggle
            ? setCommentMessage(compressedMessage)
            : setCommentMessage(expandedMessage);
    };

    return (
        <Box key={index}>
            {commentMessage &&
                commentMessage.map((comment, index) => (
                    <Typography variant="subtitle1" className={classes.commentText} key={index}>
                        {comment}
                    </Typography>
                ))}

            {commentMessage.length && displayShowMoreLink && (
                <span className={classes.showMoreLink} onClick={() => showMoreHandler()}>
                    {!showMoreButtonToggle ? '. . . Show more' : 'Show less'}
                </span>
            )}
        </Box>
    );
}

FormattedComments.propTypes = {
    comment: PropTypes.string,
    classes: PropTypes.object,
    index: PropTypes.number,
};
