// Constants
import { getTestLevelToDocumentCategoriesMapping } from '@/utils/documents';
import { IELTS, TOEFL, DUOLINGO, GRE, GMAT, PTE, SAT } from '../schema';

const largeScreenUiSchema = (config) => {
    let data = {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'Control',
                scope: '#/properties/is_tests_opted',
                label: 'Have you taken any tests?',
            },
            {
                type: 'Control',
                scope: '#/properties/test_scores',
                label: 'Add the tests details here.',
                options: {
                    ensureData: {
                        scores: {},
                    },
                    detail: {
                        type: 'VerticalLayout',
                        elements: [
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/name',
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/test_date',
                                    },
                                ],
                            },
                            {
                                type: 'VerticalLayout',
                                elements: [
                                    {
                                        type: 'HorizontalLayout',
                                        elements: [
                                            {
                                                type: 'Control',
                                                scope: '#/properties/scores/properties/listening',
                                            },
                                            {
                                                type: 'Control',
                                                scope: '#/properties/scores/properties/reading',
                                            },
                                        ],
                                    },
                                    {
                                        type: 'HorizontalLayout',
                                        elements: [
                                            {
                                                type: 'Control',
                                                scope: '#/properties/scores/properties/written',
                                            },
                                            {
                                                type: 'Control',
                                                scope: '#/properties/scores/properties/speaking',
                                            },
                                        ],
                                    },
                                    {
                                        type: 'HorizontalLayout',
                                        elements: [
                                            {
                                                type: 'Control',
                                                label: 'Overall Marks',
                                                scope: '#/properties/scores/properties/overall',
                                            },
                                            {
                                                type: 'VerticalLayout',
                                                elements: [],
                                            },
                                        ],
                                    },
                                ],
                                rule: {
                                    effect: 'SHOW',
                                    condition: {
                                        scope: '#/properties/name',
                                        schema: {
                                            enum: [IELTS, TOEFL, PTE],
                                        },
                                    },
                                },
                            },
                            {
                                type: 'VerticalLayout',
                                elements: [
                                    {
                                        type: 'HorizontalLayout',
                                        elements: [
                                            {
                                                type: 'Control',
                                                label: 'Verbal Score',
                                                scope: '#/properties/scores/properties/verbalScore',
                                            },
                                            {
                                                type: 'Control',
                                                label: 'Quantitative Score',
                                                scope: '#/properties/scores/properties/quantitative',
                                            },
                                        ],
                                    },
                                    {
                                        type: 'HorizontalLayout',
                                        elements: [
                                            {
                                                type: 'Control',
                                                label: 'Written Score',
                                                scope: '#/properties/scores/properties/written',
                                            },
                                            {
                                                type: 'Control',
                                                label: 'Overall Marks',
                                                scope: '#/properties/scores/properties/overall',
                                            },
                                        ],
                                    },
                                ],
                                rule: {
                                    effect: 'SHOW',
                                    condition: {
                                        scope: '#/properties/name',
                                        schema: {
                                            enum: [GRE, GMAT],
                                        },
                                    },
                                },
                            },
                            {
                                type: 'VerticalLayout',
                                elements: [
                                    {
                                        type: 'HorizontalLayout',
                                        elements: [
                                            {
                                                type: 'Control',
                                                scope: '#/properties/scores/properties/quantitative',
                                            },
                                            {
                                                type: 'Control',
                                                scope: '#/properties/scores/properties/verbalScore',
                                            },
                                        ],
                                    },
                                    {
                                        type: 'HorizontalLayout',
                                        elements: [
                                            {
                                                type: 'Control',
                                                scope: '#/properties/scores/properties/essay',
                                            },
                                            {
                                                type: 'Control',
                                                scope: '#/properties/scores/properties/overall',
                                            },
                                        ],
                                    },
                                ],
                                rule: {
                                    effect: 'SHOW',
                                    condition: {
                                        scope: '#/properties/name',
                                        schema: {
                                            enum: [SAT],
                                        },
                                    },
                                },
                            },
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        label: 'Overall Marks',
                                        scope: '#/properties/scores/properties/overall',
                                    },
                                    {
                                        type: 'VerticalLayout',
                                        elements: [],
                                    },
                                ],
                                rule: {
                                    effect: 'SHOW',
                                    condition: {
                                        scope: '#/properties/name',
                                        schema: {
                                            enum: [DUOLINGO],
                                        },
                                    },
                                },
                            },
                        ],
                    },
                },
                rule: {
                    effect: 'SHOW',
                    condition: {
                        scope: '#/properties/is_tests_opted',
                        schema: {
                            const: 'Yes',
                        },
                    },
                },
            },
            {
                type: 'Control',
                scope: '#/properties/test_documents',
                label: 'Upload Documents',
                options: {
                    labelHighlight: `(Add the documents for the above tests)`,
                    component: 'resource',
                    selectCategoryLabel: 'Choose Document Category',
                    uniqueCategory: false,
                    fileCategories: getTestLevelToDocumentCategoriesMapping(config),
                },
                rule: {
                    effect: 'SHOW',
                    condition: {
                        scope: '#/properties/is_tests_opted',
                        schema: {
                            const: 'Yes',
                        },
                    },
                },
            },
        ],
    };
    return data;
};

const smallScreenUiSchema = (config) => {
    let data = {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'Control',
                scope: '#/properties/is_tests_opted',
            },
            {
                type: 'Control',
                scope: '#/properties/test_scores',
                label: 'Add the tests details here.',
                options: {
                    ensureData: {
                        scores: {},
                    },
                    detail: {
                        type: 'VerticalLayout',
                        elements: [
                            {
                                type: 'VerticalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/name',
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/test_date',
                                    },
                                ],
                            },
                            {
                                type: 'VerticalLayout',
                                elements: [
                                    {
                                        type: 'VerticalLayout',
                                        elements: [
                                            {
                                                type: 'Control',
                                                scope: '#/properties/scores/properties/listening',
                                            },
                                            {
                                                type: 'Control',
                                                scope: '#/properties/scores/properties/reading',
                                            },
                                        ],
                                    },
                                    {
                                        type: 'VerticalLayout',
                                        elements: [
                                            {
                                                type: 'Control',
                                                scope: '#/properties/scores/properties/written',
                                            },
                                            {
                                                type: 'Control',
                                                scope: '#/properties/scores/properties/speaking',
                                            },
                                        ],
                                    },
                                    {
                                        type: 'VerticalLayout',
                                        elements: [
                                            {
                                                type: 'Control',
                                                label: 'Overall Marks',
                                                scope: '#/properties/scores/properties/overall',
                                            },
                                            {
                                                type: 'VerticalLayout',
                                                elements: [],
                                            },
                                        ],
                                    },
                                ],
                                rule: {
                                    effect: 'SHOW',
                                    condition: {
                                        scope: '#/properties/name',
                                        schema: {
                                            enum: [IELTS, TOEFL, PTE],
                                        },
                                    },
                                },
                            },
                            {
                                type: 'VerticalLayout',
                                elements: [
                                    {
                                        type: 'VerticalLayout',
                                        elements: [
                                            {
                                                type: 'Control',
                                                label: 'Verbal Score',
                                                scope: '#/properties/scores/properties/verbalScore',
                                            },
                                            {
                                                type: 'Control',
                                                label: 'Quantitative Score',
                                                scope: '#/properties/scores/properties/quantitative',
                                            },
                                        ],
                                    },
                                    {
                                        type: 'VerticalLayout',
                                        elements: [
                                            {
                                                type: 'Control',
                                                label: 'Written Score',
                                                scope: '#/properties/scores/properties/written',
                                            },
                                            {
                                                type: 'Control',
                                                label: 'Overall Marks',
                                                scope: '#/properties/scores/properties/overall',
                                            },
                                        ],
                                    },
                                ],
                                rule: {
                                    effect: 'SHOW',
                                    condition: {
                                        scope: '#/properties/name',
                                        schema: {
                                            enum: [GRE, GMAT],
                                        },
                                    },
                                },
                            },
                            {
                                type: 'VerticalLayout',
                                elements: [
                                    {
                                        type: 'VerticalLayout',
                                        elements: [
                                            {
                                                type: 'Control',
                                                scope: '#/properties/scores/properties/quantitative',
                                            },
                                            {
                                                type: 'Control',
                                                scope: '#/properties/scores/properties/verbalScore',
                                            },
                                        ],
                                    },
                                    {
                                        type: 'VerticalLayout',
                                        elements: [
                                            {
                                                type: 'Control',
                                                scope: '#/properties/scores/properties/essay',
                                            },
                                            {
                                                type: 'Control',
                                                scope: '#/properties/scores/properties/overall',
                                            },
                                        ],
                                    },
                                ],
                                rule: {
                                    effect: 'SHOW',
                                    condition: {
                                        scope: '#/properties/name',
                                        schema: {
                                            enum: [SAT],
                                        },
                                    },
                                },
                            },
                            {
                                type: 'VerticalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        label: 'Overall Marks',
                                        scope: '#/properties/scores/properties/overall',
                                    },
                                    {
                                        type: 'VerticalLayout',
                                        elements: [],
                                    },
                                ],
                                rule: {
                                    effect: 'SHOW',
                                    condition: {
                                        scope: '#/properties/name',
                                        schema: {
                                            enum: [DUOLINGO],
                                        },
                                    },
                                },
                            },
                        ],
                    },
                },
                rule: {
                    effect: 'SHOW',
                    condition: {
                        scope: '#/properties/is_tests_opted',
                        schema: {
                            const: 'Yes',
                        },
                    },
                },
            },
            {
                type: 'Control',
                scope: '#/properties/test_documents',
                label: 'Upload Documents',
                options: {
                    labelHighlight: `(Add the documents for the above tests)`,
                    component: 'resource',
                    selectCategoryLabel: 'Choose Document Category',
                    uniqueCategory: false,
                    fileCategories: getTestLevelToDocumentCategoriesMapping(config),
                },
                rule: {
                    effect: 'SHOW',
                    condition: {
                        scope: '#/properties/is_tests_opted',
                        schema: {
                            const: 'Yes',
                        },
                    },
                },
            },
        ],
    };
    return data;
};

export default function (config = {}) {
    return {
        smallScreenUiSchema: smallScreenUiSchema(config),
        largeScreenUiSchema: largeScreenUiSchema(config),
    };
}
