// @ts-nocheck
import { createReducer } from '@reduxjs/toolkit';

//actions
import { initializeUserData } from '../actions/userActions';

const initialState = { userData: {}, loading: true };

export const userDataReducer = createReducer(initialState, {
    [initializeUserData]: (state, action) => {
        state.userData = action.payload;
        state.loading = false;
    },
});
