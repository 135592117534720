import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    customTab: {
        fontSize: theme.spacing(2),
        fontWeight: 500,
        '&:active': {
            color: theme.palette.primary.main,
        },
        '&.Mui-selected': {
            color: theme.palette.primary.main,
        },
    },
    pageTitle: {
        marginLeft: theme.spacing(2),
        color: theme.palette.primary.main,
        fontWeight: 600,
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    titleContainerClickable: {
        display: 'flex',
        alignItems: 'flex-end',
        cursor: 'pointer',
    },
}));
