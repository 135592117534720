import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(() => ({
    myicon: {
        width: '23px',
        height: '23px',
        borderRadius: '50%',
        backgroundColor: '#F56A20',
    },
    innerCircle: {
        width: '17px',
        height: '17px',
        borderRadius: '50%',
        backgroundColor: '#F56A20',
        border: '3px white solid',
        color: '#F56A20',
    },
}));
