export const getProfileStatusUiSchema = () => {
    let uiSchema = {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'VerticalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/profile_status',
                        options: {
                            labelTransformation: 'startCase',
                        },
                    },
                ],
            },
            // NOTE: Asked to comment document upload
            // {
            //     type: 'HorizontalLayout',
            //     elements: [
            //         {
            //             type: 'Control',
            //             scope: '#/properties/files',
            //             label: '',
            //             options: {
            //                 component: 'resource',
            //                 selectCategoryLabel: 'Choose Document Category',
            //                 uniqueCategory: false,
            //             },
            //         },
            //     ],
            // },
        ],
    };

    return { smallScreenUiSchema: uiSchema, largeScreenUiSchema: uiSchema };
};
