// Constants
import { validationJsonSchema } from '@/utils/validate-json-schema';

//utils
import moment from 'moment';

// prop-constants
import { getOnboardingSchema } from '@/props-constants/registration/schema';
import { getDocumentsDeserializedByCategory } from './partner-documents';

//api
import { updateOnboardingData } from '@/api';

//lodash
import { isEmpty, get } from 'lodash';

const steps = [0, 1, 2];

export const validateProfileDataByStep = (profileData, index, formData = {}) => {
    if (profileData && profileData[index]) {
        let stepData = getProfileSplitByIndex(profileData[index], index, formData);
        const schema = getOnboardingSchema(index);
        let validatorData = validationJsonSchema(stepData, schema);
        return !(validatorData && validatorData.length !== 0);
    } else return false;
};

export const checkProfileStepCompleted = (data, index) => {
    switch (index) {
        case 0: {
            if (data && data.company_profile && data.company_profile.organisation_name) return true;
            else return false;
        }

        case 1: {
            if (
                data &&
                data.point_of_contact &&
                data.point_of_contact.first_name &&
                data.point_of_contact.last_name &&
                data.point_of_contact.email &&
                data.point_of_contact.mobile &&
                data.point_of_contact.whatsapp_number
            )
                return true;
            else return false;
        }

        case 2: {
            return false;
        }
    }
};

export const submitApiByIndex = (index, submittedData, formData, profileData) => {
    let data = {};

    let newSubmittedData = JSON.parse(JSON.stringify(submittedData));
    if (index == 0 && newSubmittedData[index]) {
        data = { ...newSubmittedData[index] };
    } else if (index == 1 && newSubmittedData[index]) {
        data = { ...newSubmittedData[0], ...newSubmittedData[1] };
    } else if (index == 2 && newSubmittedData[index]) {
        data = { ...newSubmittedData[0], ...newSubmittedData[1], ...newSubmittedData[2] };
    }
    delete data.step;

    switch (index) {
        case 0: {
            delete data.documents;
            delete data.kyc;
            delete data.onboarding_date;
            delete data.onboarding_location;
            delete data.point_of_contact;
            break;
        }
        case 1: {
            delete data.documents;
            delete data.kyc;
            delete data.onboarding_date;
            delete data.onboarding_location;
            delete data.point_of_contact.copy_number;
            data.point_of_contact.mobile =
                (profileData && profileData.point_of_contact && profileData.point_of_contact.mobile
                    ? profileData.point_of_contact.mobile.slice(0, -10)
                    : '') + data.point_of_contact.mobile;
            data.point_of_contact.whatsapp_number =
                (profileData &&
                profileData.point_of_contact &&
                profileData.point_of_contact.whatsapp_number
                    ? profileData.point_of_contact.whatsapp_number.slice(0, -10)
                    : '') + data.point_of_contact.whatsapp_number;
            break;
        }
        case 2: {
            if (data.documents)
                data.documents = getDocumentsDeserializedByCategory(
                    newSubmittedData[index].documents,
                );
            delete data.customer_info;
            break;
        }
    }
    return updateOnboardingData(data);
};

const getProfileSplitByIndex = (profileData, index, isDefaultData, basicData, dirtyFormData) => {
    switch (index) {
        case 0:
        case 2: {
            let stepData = {
                step: index,
                kyc: {},
                customer_info: {},
                company_profile: {
                    office_address: {},
                },
                point_of_contact: {},
            };

            if (profileData.documents) stepData.documents = profileData.documents;

            let {
                onboarding_location,
                onboarding_date,
                kyc,
                onboarding_status,
                onboarding_completed,
            } = profileData;
            stepData.onboarding_date = onboarding_date || moment().format('YYYY-MM-DD');
            stepData.onboarding_location = onboarding_location;
            stepData.status = onboarding_status;
            stepData.onboarding_completed = onboarding_completed;

            if (profileData.company_profile) {
                let {
                    office_address,
                    year_of_establishment,
                    number_of_employees,
                    type_of_business,
                    other_business_details,
                    target_countries,
                    organisation_name,
                } = profileData.company_profile;

                if (!stepData.onboarding_location)
                    stepData.onboarding_location = office_address.city;
                if (organisation_name)
                    stepData.company_profile.organisation_name = organisation_name;
                if (year_of_establishment)
                    stepData.company_profile.year_of_establishment = year_of_establishment;
                if (number_of_employees)
                    stepData.company_profile.number_of_employees = number_of_employees;
                if (type_of_business) stepData.company_profile.type_of_business = type_of_business;
                if (other_business_details)
                    stepData.company_profile.other_business_details = other_business_details;

                if (target_countries && !isEmpty(target_countries))
                    stepData.company_profile.target_countries = target_countries;

                if (office_address) {
                    let { address1, city, state, country, pin_code } = office_address;
                    if (address1) stepData.company_profile.office_address.address1 = address1;
                    if (city) stepData.company_profile.office_address.city = city;
                    if (state) stepData.company_profile.office_address.state = state;
                    if (country) stepData.company_profile.office_address.country = country;
                    if (pin_code)
                        stepData.company_profile.office_address.pin_code = Number(pin_code);
                }
            }

            if (profileData.customer_info) {
                let { students_sent_abroad_each_year, top_three_streams, other_services } =
                    profileData.customer_info;

                if (students_sent_abroad_each_year && !isEmpty(students_sent_abroad_each_year))
                    stepData.customer_info.students_sent_abroad_each_year =
                        students_sent_abroad_each_year;
                if (top_three_streams && !isEmpty(top_three_streams))
                    stepData.customer_info.top_three_streams = top_three_streams;
                if (other_services && !isEmpty(other_services))
                    stepData.customer_info.other_services = other_services;
            }
            if (profileData.point_of_contact) {
                let {
                    first_name,
                    last_name,
                    mobile,
                    whatsapp_number,
                    email,
                    designation,
                    copy_number,
                } = profileData.point_of_contact;

                if (first_name) stepData.point_of_contact.first_name = first_name;
                if (last_name) stepData.point_of_contact.last_name = last_name;
                if (mobile) stepData.point_of_contact.mobile = mobile;
                if (whatsapp_number) stepData.point_of_contact.whatsapp_number = whatsapp_number;
                if (email) stepData.point_of_contact.email = email;
                if (designation) stepData.point_of_contact.designation = designation;
                if (copy_number) stepData.point_of_contact.copy_number = copy_number;
            }

            if (kyc) {
                let { govt_license, other_docs, acceptance } = kyc;
                if (govt_license) stepData.kyc.govt_license = govt_license;
                if (other_docs && !isEmpty(other_docs)) stepData.kyc.other_docs = other_docs;
                if (acceptance) stepData.kyc.acceptance = acceptance;
            }

            if (isDefaultData) {
                stepData.kyc.acceptance = true;
            }

            if (basicData) {
                stepData.fname = basicData.fname;
                stepData.lname = basicData.lname;
                stepData.email = basicData.email;
                stepData.mobile = basicData.mobile;
            }

            return stepData;
        }
        case 1: {
            let stepData = {
                step: index,
                point_of_contact: profileData.point_of_contact,
            };

            let stepDirtyData = dirtyFormData &&
                dirtyFormData[index] && { ...dirtyFormData[index] };

            if (stepDirtyData && get(stepDirtyData, 'point_of_contact.copy_number', null)) {
                stepDirtyData.point_of_contact.whatsapp_number = get(
                    stepDirtyData,
                    'point_of_contact.mobile',
                    null,
                );
                return stepDirtyData;
            }

            if (
                stepData.point_of_contact &&
                stepData.point_of_contact.mobile !== null &&
                stepData.point_of_contact.whatsapp_number !== null &&
                stepData.point_of_contact.mobile === stepData.point_of_contact.whatsapp_number &&
                !stepData.point_of_contact.copy_number
            ) {
                stepData.point_of_contact = { ...stepData.point_of_contact, copy_number: true };
            } else {
                stepData.point_of_contact = { ...stepData.point_of_contact, copy_number: false };
            }

            return stepData;
        }
    }
};

export const getProfileSplit = (partnerData, isDefaultData, basicData, dirtyFormData) => {
    let profileSplit = {};
    if (partnerData) {
        profileSplit = steps.reduce((acc, curr) => {
            acc[curr] = getProfileSplitByIndex(
                partnerData,
                curr,
                isDefaultData,
                basicData,
                dirtyFormData,
            );
            return acc;
        }, {});
    } else {
        profileSplit = steps.reduce((acc, curr) => {
            acc[curr] = { step: curr };
            return acc;
        }, {});
    }
    return profileSplit;
};
