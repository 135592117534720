import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3, 4),
    },
    tieupCardsContainer: {
        marginRight: theme.spacing(1),
        borderRadius: theme.spacing(2),
        background: '#ECF3FF',
        padding: theme.spacing(1.25, 2.25),
    },
    seeMoreText: { textAlign: 'right', cursor: 'pointer' },
    notesContainer: {
        marginLeft: theme.spacing(1),
        padding: theme.spacing(1.25, 2.25),
        background: '#FFFBDA',
        borderRadius: theme.spacing(2),
    },
    noteButton: {
        fontSize: '14px',
        fontWeight: '600',
        borderRadius: '9px',
        color: 'white',
        lineHeight: '1.75',
        fontFamily: 'Montserrat',
        padding: '8px 0px',
        backgroundColor: '#00c853',
        '&:hover': {
            backgroundColor: '#3D8944',
        },
    },
}));
