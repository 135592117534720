import instance from './instances/student-service';

export function getPartnerNotes() {
    return instance.get(`/v1/partners/notes`);
}

export function addPartnerNote(payload) {
    return instance.post(`/v1/partners/notes`, payload);
}

export function getProfiles(query) {
    let search = new URLSearchParams(query);
    return instance.get(`/v1/profiles?${search.toString()}`);
}

export function getFilteredProfiles(query) {
    return new Promise((resolve, reject) => {
        instance
            .get(`/v1/profiles`)
            .then((response) => {
                const querys = query.ids.split(',').map((item) => item);
                resolve({ data: response.data.filter((item) => querys.includes(item._id)) });
            })
            .catch((e) => {
                reject(e);
            });
    });
}
export function getProfile(id) {
    return instance.get(`/v1/profiles/${id}`);
}

export function createProfile(data) {
    return instance.post('/v1/profiles', data);
}
export function updateProfile(id, data) {
    return instance.put(`/v1/profiles/${id}`, data);
}

export function shortlistProgramsForProfile(id, data) {
    return instance.post(`/v1/profiles/${id}/shortlists`, data);
}

export function setShortlistState(profileId, data) {
    return instance.post(`/v1/profiles/${profileId}/shortlist-update`, data);
}

export function sendEmailToPartner(profileId) {
    return instance.post(`/v1/profiles/${profileId}/send-shortlist-email`);
}

export function getRecommendedProgramsForProfile(id) {
    return instance.get(`/v1/profiles/recommend/${id}`);
}

export function saveProfileComments(id, data) {
    return instance.patch(`/v1/profiles/${id}/comment`, data);
}

export function changeProfileStatus(id, data) {
    return instance.patch(`/v1/profiles/${id}/change_status`, data);
}

export function downloadShortlistPdf(id, query) {
    let search = new URLSearchParams(query);
    return instance.get(`/v1/profiles/${id}/shortlist/download?${search.toString()}`);
}

export function getSubAgentProfilesAssigned(id) {
    return instance.get(`/v1/profiles?sub_agent=${id}`);
}
export function generateEmail(profileId) {
    return instance.get(`/v1/profiles/${profileId}/custom-email`);
}

export function getDocumentsCategories() {
    return instance.get(`/v1/documents/fetch?p=B2B`);
}

export function verifyDocument(data) {
    return instance.put(`/v1/documents-verify`, data);
}
