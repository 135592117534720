//default
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';

// mui
import { Box, Tooltip, Typography } from '@mui/material';
import { getIcon } from 'azent-storybook/dist';
import CircularProgress from '@mui/material/CircularProgress';

//styles
import { useStyles } from './styles';

//partials
import IframeGoogleDoc from './partials/iframeGoogleDoc';

//utils
import { VIDEO_FORMATS } from '@/utils/documents';
import getHash from '@/utils/hash';

export default function DocumentPreview() {
    const classes = useStyles();
    const location = useSelector((state) => state.router.location);
    const params = Object.fromEntries(new URLSearchParams(location.search));
    const [isLoading, setIsLoading] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);
    const downloadIconID = useRef(getHash(params.fileName));

    const isVideo = params && VIDEO_FORMATS.includes(params.type);
    const isImage = params && params.type.split('/')[0] == 'image';
    const isIframeDocument = params && params.type.split('/')[0] == 'application';

    useEffect(() => {
        (async () => {
            const downloadIcon = document.getElementById(downloadIconID.current);
            if (!isVideo) {
                setIsDownloading(true);
                const response = await axios({
                    url: `${params.url}?timeStamp=${moment().valueOf()}`,
                    method: 'GET',
                    responseType: 'blob',
                });
                const objectURL = URL.createObjectURL(response.data);
                downloadIcon.setAttribute('href', objectURL);
                downloadIcon.setAttribute('download', params.fileName);
                setIsDownloading(false);
            } else {
                downloadIcon.setAttribute('href', params.url);
                downloadIcon.setAttribute('target', '_blank');
            }
        })();
    }, []);

    return (
        <Box>
            <Box className={classes.previewContainer}>
                {isImage ? (
                    <img src={`${params.url}`} className={classes.imagePreview} />
                ) : isVideo ? (
                    <video width="100%" height="100%" controls>
                        <source src={`${params.url}`} type={`${params.type}`} />
                    </video>
                ) : isIframeDocument ? (
                    <Box className={classes.imagePreviewContainer}>
                        {isLoading && <CircularProgress className={classes.imagePreviewLoder} />}
                        <IframeGoogleDoc
                            setIsLoading={setIsLoading}
                            url={params.url}
                            className={classes.imagePreview}
                        />
                    </Box>
                ) : null}
            </Box>
            <Tooltip title="Download" placement="top">
                <Box className={classes.iconContainer}>
                    <a
                        id={downloadIconID.current}
                        className={`${classes.downloadImage} ${
                            !isDownloading && classes.activeImage
                        }`}
                        onClick={(e) => {
                            if (isDownloading) {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }}>
                        <Typography variant="h5" className={classes.downloadText}>
                            Download
                        </Typography>
                        {getIcon('download', 'inherit', 'large', classes.downloadIcon)}
                    </a>
                </Box>
            </Tooltip>
        </Box>
    );
}
