import { STUDENT_STATUS_CHOICES } from '@/props-constants/student/student-profile';

export const getProfileStatusSchema = () => {
    let schema = {
        type: 'object',
        definitions: {
            resource: {
                type: 'object',
                properties: {
                    original_file_name: {
                        type: 'string',
                    },
                    url: {
                        type: 'string',
                    },
                    objectId: {
                        type: 'string',
                    },
                    type: {
                        type: 'string',
                    },
                },
            },
        },
        properties: {
            profile_status: {
                type: 'string',
                enum: STUDENT_STATUS_CHOICES,
            },
            // NOTE: Asked to comment document upload
            // files: {
            //     type: 'array',
            //     items: {
            //         $ref: '#/definitions/resource',
            //     },
            // },
        },
        required: ['profile_status'],
    };
    return schema;
};
