// Utils
import { getAuthTokenValue } from './auth-token-utils';

const getHeaders = () => {
    let headers = {};
    const authTokenValue = getAuthTokenValue();
    if (authTokenValue) {
        headers.Authorization = 'Bearer ' + authTokenValue;
    }
    return headers;
};

export default getHeaders;
