import React from 'react';

import { useStyles } from './styles';
import { Box, Stack, Typography, Paper } from '@mui/material';
import { Button } from 'azent-storybook/dist/components/Button/Button';
export default function Onboarded() {
    const classes = useStyles();
    return (
        <Box p={5} sx={{ textAlign: 'center' }}>
            <Box mb={3} p={3}>
                <Stack sx={{ textAlign: 'center' }}>
                    <Typography className={classes.title}>
                        Congratulations! Welcome to the AdmissionBox family.
                    </Typography>
                </Stack>
            </Box>
            <Box mx={5} p={3} mb={3}>
                <Paper variant="outlined">Video link</Paper>
            </Box>
            <Button className={classes.saveCommentButton} label={'Go to Dashboard'}></Button>
        </Box>
    );
}
