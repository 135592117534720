let getHash = function (str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = (hash & 0xffffffff).toString(16).toUpperCase();
    return hash;
};

export default getHash;
