// api
import { searchSubAgentToTransfer } from '@/api';

export const getTransferStudentFormUiSchema = (partnerId, transfer_from_id) => {
    const transferStudentUiSchema = {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/transfer_sudent',
                        label: 'Do you want to transfer all the students to another agent?',
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/agent',
                        label: 'Select Agent*',
                        options: {
                            component: 'search',
                            searchApi: searchSubAgentToTransfer(partnerId, transfer_from_id),
                            searchApiArgs: [{ q: '{{search}}' }],
                            indexApi: searchSubAgentToTransfer(partnerId, transfer_from_id),
                            indexApiArgs: [{ ids: '{{ids}}' }],
                            valueKey: 'id',
                            labelKey: 'first_name',
                            labelExp: '{{first_name}} {{last_name}}',
                        },
                        rule: {
                            effect: 'SHOW',
                            condition: {
                                scope: '#/properties/transfer_sudent',
                                schema: {
                                    enum: ['Yes'],
                                },
                            },
                        },
                    },
                ],
            },
        ],
    };
    return transferStudentUiSchema;
};
