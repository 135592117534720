import { createAction } from '@reduxjs/toolkit';

//api
import { authenticate, counsellorLogin } from '@/api';

//navigation
import { goToRoute } from './navigationActions';

//utils
import { removeAuthToken, getAuthTokenValue, setAuthToken } from '@/utils/auth-token-utils';
import routePaths from '@/utils/route-paths';
import { getSearchObject } from '@/utils/get-search-params';

export const initializeUserData = createAction('INITIALISE_USER_DATA', (data) => ({
    payload: data,
}));

export const userGetAndStore = () => {
    const authToken = getAuthTokenValue();
    return (dispatch) => {
        authToken &&
            authenticate()
                .then((res) => {
                    if (res.data.success && res.data.data && res.data.data.user) {
                        let userData = {
                            ...res.data.data,
                            ...res.data.data.user,
                            authToken,
                        };
                        dispatch(initializeUserData(userData));
                    } else {
                        removeAuthToken();
                        dispatch(goToRoute(routePaths.LOGIN));
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
    };
};

export const removeAuthTokenAndGotoLogin = () => {
    removeAuthToken();
    // window.location.reload();
    return (dispatch) => {
        dispatch(goToRoute(routePaths.LOGIN));
    };
};

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

// for crm counsellor login
export const authenticateCounsellorUser = (location) => {
    let crm_user =
        (getSearchObject(location) &&
            getSearchObject(location).crm_user &&
            isJsonString(getSearchObject(location).crm_user) &&
            JSON.parse(getSearchObject(location).crm_user)) ||
        {};
    return (dispatch) => {
        return counsellorLogin(crm_user)
            .then((res) => {
                const { success, data } = res.data;
                if (success && data && data.user) {
                    setAuthToken(data.access_token);
                    window.location.reload();
                }
            })
            .catch((err) => {
                const { data } = err.response;
                if (data && data.error && data.error.code) {
                    dispatch(goToRoute(routePaths.NOT_FOUND));
                }
            });
    };
};
