// mui
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        minWidth: '100%',
        padding: '8px 8px 8px 0px',
        borderRadius: '18px',
        textAlign: 'center',
    },
    title: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '400',
    },
    containerBlue: {
        display: 'flex',
        alignItems: 'center',
        padding: '4px 4px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#E5EDFF',
        border: '1px solid #6D96E6',
        width: '200px',
    },
    containerGreen: {
        display: 'flex',
        alignItems: 'center',
        padding: '4px 4px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#E9FFE9',
        border: '1px solid #5AAF5A',
        width: '200px',
    },
    containerYellow: {
        display: 'flex',
        alignItems: 'center',
        padding: '4px 4px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#FFEBD3',
        border: '1px solid #F56A20',
        width: '200px',
    },
    containerRed: {
        display: 'flex',
        alignItems: 'center',
        padding: '4px 4px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#FFE2DCF0',
        border: '1px solid #D07070',
        width: '200px',
    },
    containerGrey: {
        display: 'flex',
        alignItems: 'center',
        padding: '4px 4px',
        borderRadius: '11px',
        textAlign: 'center',
        backgroundColor: '#E7E7E7F0',
        border: '1px solid #6F716F',
        width: '200px',
    },
    titleBlue: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#6D96E6',
        whiteSpace: 'pre-wrap !important',
    },
    titleGreen: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#5AAF5A',
        whiteSpace: 'pre-wrap !important',
    },
    titleYellow: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#F56A20',
        whiteSpace: 'pre-wrap !important',
    },
    titleRed: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#D07070',
        whiteSpace: 'pre-wrap !important',
    },
    titleGrey: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        color: '#6F716F',
        whiteSpace: 'pre-wrap !important',
    },
    viewButton: {
        padding: '0px',
        fontSize: '12px',
        color: theme.palette.neutral.dark,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    paperClass: {
        width: '100%',
        maxHeight: '85vh',
        padding: '10px',
    },
    BoxContainer: {
        padding: theme.spacing(2, 0, 2, 2),
        border: '1.5px solid #E2E2E2',
        borderRadius: theme.spacing(2),
    },
    cardContainer: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    myfont14: {
        fontSize: '14px',
    },
    iconContainer: {
        backgroundColor: '#FFB684',
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '9px',
    },
    iconTitle: {
        backgroundColor: 'transparent',
        verticalAlign: 'bottom',
        color: 'white',
        fontSize: '31px',
    },
    priorityIconP1: {
        color: '#D7330F',
        verticalAlign: 'bottom',
    },
    priorityIconP2: {
        color: '#F9945B',
        verticalAlign: 'bottom',
    },
    priorityIconP3: {
        color: '#5CAE7E',
        verticalAlign: 'bottom',
    },
    priorityIconP4: {
        color: '#81A8FD',
        verticalAlign: 'bottom',
    },
    priorityIconP5: {
        color: '#A7A7A7',
        verticalAlign: 'bottom',
    },
}));
