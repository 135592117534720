import * as React from 'react';

//utils
import { getDocumentCategoriesMapping } from '@/utils/partner-documents';

//api
import { getCityPlaces, getStatePlaces, getCountryPlaces } from '@/api';

//mui
import { Box, Typography } from '@mui/material';

const documentList = [
    `1. Company's Pan Card`,
    `2. Director's Aadhar Card`,
    `3. Company Registration (Not Required for Freelancer)`,
    `4. Cancelled Cheque`,
    `5. GST Certificate`,
    `6. Govt Registration for Study Overseas Business ( Only for  Punjab based Referral partners)`,
    `7. Business Card for freelancer`,
];

const displayLabel = () => {
    return (
        <Box pt={2}>
            <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>Documents Required</Typography>
            <Box px={2} pt={1}>
                {documentList.map((label, index) => (
                    <Typography sx={{ fontSize: '16px', mb: 1 }} key={index}>
                        {label}
                    </Typography>
                ))}
            </Box>
        </Box>
    );
};

export default function () {
    const uiSchema = {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'Control',
                scope: '#/properties/required_docs',
                label: '',
                options: {
                    component: 'label',
                    labelPlacement: 'end',
                    item: displayLabel,
                },
            },
            {
                type: 'Control',
                scope: '#/properties/documents',
                label: 'Upload Documents',
                options: {
                    component: 'resource',
                    selectCategoryLabel: 'Choose Document Category',
                    uniqueCategory: true,
                    fileCategories: getDocumentCategoriesMapping(),
                },
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/kyc/properties/acceptance',
                        label: 'I am interested in associating with AdmissionBox as a referral partner and i hereby confirm that the above information provided by me is correct*',
                        options: {
                            component: 'switch',
                            labelPlacement: 'start',
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        label: 'Organisation Name',
                        scope: '#/properties/company_profile/properties/organisation_name',
                        options: {
                            readOnly: true,
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/company_profile/properties/office_address/properties/address1',
                        label: 'Address',
                        rule: {
                            effect: 'HIDE',
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/company_profile/properties/office_address/properties/state',
                        options: {
                            component: 'search',
                            searchApi: getStatePlaces,
                            searchApiArgs: [{ search: '{{search}}' }],
                            valueKey: 'name',
                            labelKey: 'name',
                        },
                        rule: {
                            effect: 'HIDE',
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/company_profile/properties/office_address/properties/city',
                        options: {
                            component: 'search',
                            searchApi: getCityPlaces,
                            searchApiArgs: [{ search: '{{search}}' }],
                            valueKey: 'name',
                            labelKey: 'name',
                        },
                        rule: {
                            effect: 'HIDE',
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/company_profile/properties/office_address/properties/pin_code',
                        rule: {
                            effect: 'HIDE',
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/company_profile/properties/office_address/properties/country',
                        options: {
                            component: 'search',
                            searchApi: getCountryPlaces,
                            searchApiArgs: [{ search: '{{search}}' }],
                            valueKey: 'name',
                            labelKey: 'name',
                        },
                        rule: {
                            effect: 'HIDE',
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        label: 'Date',
                        scope: '#/properties/onboarding_date',
                        options: {
                            readOnly: true,
                        },
                    },
                    {
                        type: 'Control',
                        label: 'Location',
                        scope: '#/properties/onboarding_location',
                        options: {
                            readOnly: true,
                        },
                    },
                ],
            },
        ],
    };

    return {
        smallScreenUiSchema: {},
        largeScreenUiSchema: uiSchema,
    };
}
