//mui styles
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
    Loading: {
        borderRadius: 0.7,
        height: '60px',
        width: '100%',
    },
}));
