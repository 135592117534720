import React, { useEffect } from 'react';

//components
import { ServiceCard } from 'azent-storybook/dist';

//mui
import { Box, Grid, Typography } from '@mui/material';

//store
import { useSelector, useDispatch } from 'react-redux';
//routes
import routePaths from '@/utils/route-paths';
import { goToRoute } from '@/store/actions/navigationActions';
import { servicesCmsGetAndStore } from '@/store/actions/serviceCmsAction';

//style
import { useStyles } from './style';
import { getServiceProps } from '@/props-constants/ancillary-props/service-props/getServiceProps';

export default function Services() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const loadingCards = [1, 2, 3, 4, 5, 6];
    const serviceCard = useSelector((state) => state.serviceCards);
    
    useEffect(() => {
        if (
            !serviceCard ||
            !serviceCard.data ||
            serviceCard.data.length === null ||
            serviceCard.data.length < 2
        ) {
            dispatch(servicesCmsGetAndStore());
        }else{
            dispatch(servicesCmsGetAndStore());
        }
    }, []);
    
    function goToService(service) {
        service.id && dispatch(goToRoute(routePaths.SERVICE_PAGE_VIEW, { id: service.id }));
    }

    return (
        <Box className={classes.container}>
            <Grid container>
                <Typography variant="h2" className={classes.title}>
                    {getServiceProps.title}
                </Typography>
            </Grid>
            <Grid container>
                <Typography variant="p" className={classes.description}>
                    {getServiceProps.subTitle}
                </Typography>
            </Grid>
            <Grid container item xs={12} alignItems="left" justify="left">
                {serviceCard.loading ? (
                    loadingCards.map((index) => (
                        <Grid container lg={4} md={6} sm={6} xs={12} key={index}>
                            <Box className={classes.loadingCards}>
                                <ServiceCard loading={serviceCard.loading} />
                            </Box>
                        </Grid>
                    ))
                ) : serviceCard.data.length ? (
                    serviceCard.data.map((service, index) => (
                        <Grid container item lg={4} md={6} sm={6} xs={12} key={index}>
                            <Box style={{ margin: '10px', width: '100vw' }}>
                                <ServiceCard
                                    description={service.description}
                                    heading={service.title}
                                    button={
                                        service.button
                                            ? {
                                                  ...service.button,
                                                  onClick: () => {
                                                      goToService(service);
                                                  },
                                              }
                                            : {}
                                    }
                                    coverImage={service.logo}
                                />
                            </Box>
                        </Grid>
                    ))
                ) : (
                    <Typography variant="p" className={classes.noDataDescription}>
                        {getServiceProps.noServiceCard}
                    </Typography>
                )}
            </Grid>
        </Box>
    );
}
