export const getSubAgentSchema = () => {
    let schema = {};
    schema = {
        type: 'object',
        properties: {
            step: {
                type: 'number',
            },
            first_name: {
                type: 'string',
            },
            last_name: {
                type: 'string',
            },
            mobile: {
                type: 'string',
                minLength: 10,
                maxLength: 10,
                errorMessage: {
                    minLength: 'Mobile number must be 10 digits',
                    maxLength: 'Mobile number must be 10 digits',
                },
            },
            email: {
                type: 'string',
                format: 'email',
                pattern: '^([A-Za-z0-9+_.-])+@([a-zA-Z0-9-])+(\\.[a-zA-Z0-9-]+)+$',
            },
            whatsapp_number: {
                type: 'string',
                minLength: 10,
                maxLength: 10,
                errorMessage: {
                    minLength: 'Mobile number must be 10 digits',
                    maxLength: 'Mobile number must be 10 digits',
                },
            },
            is_whatsapp_same: {
                type: 'boolean',
            },
            new_password: {
                type: 'string',
                pattern: '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})',
            },
            confirm_password: {
                type: 'string',
            },
        },
        allOf: [
            {
                if: {
                    properties: {
                        step: {
                            const: 0,
                        },
                    },
                },
                then: {
                    required: ['first_name', 'last_name', 'mobile', 'email'],
                },
            },
            {
                if: {
                    properties: {
                        step: {
                            const: 1,
                        },
                    },
                },
                then: {
                    required: ['new_password', 'confirm_password'],
                },
            },
        ],
    };
    return schema;
};

export const getSubAgentPasswordSchema = () => {
    let schema = {};
    schema = {
        type: 'object',
        properties: {
            new_password: {
                type: 'string',
            },
            confirm_password: {
                type: 'string',
            },
        },
    };
    return schema;
};
