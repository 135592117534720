import moment from 'moment';

export const getFilteredData = (formData) => {
    const filters = {};
    Object.keys(formData).map((key) => {
        if (key === 'created_date') {
            filters[
                `${moment(formData[key].from).format('Do MMM YYYY')} - ${moment(
                    formData[key].to,
                ).format('Do MMM YYYY')}`
            ] = ' ';
        } else if (key === 'partner') {
            if (formData[key]) filters[formData[key].name] = ' ';
        } else if (key === 'sub_agent') {
            if (formData[key]) filters[`${formData[key].first_name}`] = ' ';
        } else if (key === 'psa') {
            if (formData[key]) filters[`${formData[key].first_name}`] = ' ';
        } else {
            formData[key].map((item) => (filters[item] = ' '));
        }
    });
    return Object.keys(filters);
};

export const removeFilter = (removedFilter, filterData) => {
    const filteredData = { ...filterData };

    Object.keys(filteredData).map((key) => {
        if (key === 'created_date') delete filteredData.created_date;
        else if (key === 'partner') {
            if (filteredData[key].name === removedFilter) {
                delete filteredData[key];
            }
        } else if (key === 'sub_agent') {
            if (filteredData[key].first_name === removedFilter) {
                delete filteredData[key];
            }
        } else if (key === 'psa') {
            if (`${filteredData[key].first_name}` === removedFilter) {
                delete filteredData[key];
            }
        } else {
            if (filteredData[key].includes(removedFilter)) {
                filteredData[key].splice(filteredData[key].indexOf(removedFilter), 1);
            }
        }
    });

    return filteredData;
};
