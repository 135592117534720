import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// styles
import { useStyles } from './style';

// components
import { FormModal } from 'azent-storybook/dist';

//props
import {
    getCreateApplicationProps,
    months as defaultMonths,
} from '@/props-constants/application/create-application';

//actions
import { addStudentToStore } from '@/store/slices/studentSlice';

//utils
import { deserializeStudent } from '@/utils/deserializers/student-deserializer';
import { isEmpty, get } from 'lodash';
import { toastMessages } from '@/utils/toastMessages';
import { isPartner, isSubAgent } from '@/rbac';

//api
import { getProfile, getUniversity, getApplicationPriorityData } from '@/api';

export const CreateApplication = ({ handleClose, isModalOpen }) => {
    const defaultData = {};
    const classes = useStyles();
    const dispatch = useDispatch();

    const [universityId, setUniversityId] = React.useState(null);
    const [months, setMonths] = React.useState(defaultMonths);
    const [profileId, setProfileId] = React.useState(null);
    const [disableFields, setDisableFields] = React.useState(false);
    const [disableButton, setDisableButton] = React.useState(false);
    const [formMessage, setFormMessage] = React.useState('');
    const [intakeMonth, setIntakeMonth] = React.useState(null);
    const [universityLocation, setUniversityLocation] = React.useState(null);
    const [programId, setProgramId] = React.useState(null);
    const [programFee, setProgramFee] = React.useState(null);
    const [intakeType, setIntakeType] = React.useState(null);
    const [priorityData, setPriorityData] = React.useState(['']);

    useEffect(() => {
        if (!isModalOpen) {
            setFormMessage('');
            setUniversityLocation(null);
            setUniversityId(null);
            setProfileId(null);
            setProgramId(null);
            setProgramFee(null);
            setIntakeType(null);
            setDisableButton(false);
            setPriorityData(['']);
        }
    }, [isModalOpen]);

    useEffect(() => {
        if (universityId) {
            getUniversity(universityId).then((res) => {
                res.data &&
                    res.data.location &&
                    res.data.location.country &&
                    setUniversityLocation(res.data.location.country);
            });
        }
    }, [universityId]);

    let onChangeApp = function (formdata) {
        if (!isEmpty(formdata)) {
            if (
                formdata &&
                formdata.university &&
                formdata.university._id &&
                formdata.university._id != universityId
            ) {
                setUniversityId(formdata.university._id);
                setUniversityLocation(null);
            }
            if (
                formdata &&
                formdata.program &&
                formdata.program._id &&
                formdata.program._id != programId
            ) {
                setProgramId(formdata.program._id);
                setMonths(formdata.program.intake_months);
                if (
                    formdata.program.costs &&
                    (formdata.program.costs.application_fee ||
                        formdata.program.costs.application_fee == 0)
                )
                    setProgramFee({
                        fee: formdata.program.costs.application_fee,
                        currency: get(formdata, 'program.costs.currency', null),
                    });
                else setProgramFee(null);
            }
            if (formdata.profile && formdata.profile._id && formdata.profile._id != profileId) {
                setProfileId(formdata.profile._id);
                if (
                    (isPartner() || isSubAgent()) &&
                    formdata.profile.applications &&
                    formdata.profile.applications.length >= 5
                ) {
                    setDisableFields(true);
                    setFormMessage(toastMessages.applicationLimit.message);
                } else {
                    setDisableFields(false);
                    setFormMessage('');
                }
            }
            if (
                formdata &&
                formdata.intake &&
                formdata.intake.month &&
                formdata.intake.month != intakeMonth
            ) {
                setIntakeMonth(formdata.intake.month);
            }

            if (
                formdata &&
                formdata.program &&
                formdata.intake &&
                formdata.intake.month &&
                formdata.intake.year
            ) {
                const intakeType =
                    formdata.program &&
                    formdata.program.intakes.find(
                        (intake) =>
                            intake.intake_name ===
                            `${formdata.intake.month} ${formdata.intake.year}`,
                    );
                if (intakeType) {
                    if (intakeType.accepting_application) {
                        setIntakeType('Open');
                        setDisableButton(false);
                    } else {
                        setIntakeType('Closed');
                        setDisableButton(true);
                    }
                } else {
                    setIntakeType('Not Open Yet');
                    setDisableButton(false);
                }
            }
        }
    };

    useEffect(() => {
        if (profileId) {
            getApplicationPriorityData(profileId)
                .then((res) => {
                    setPriorityData(res.data.priorities);
                })
                .catch(() => {
                    setPriorityData(['']);
                });
        }
    }, [profileId]);

    const onSuccessCallback = (studentData) => {
        handleClose();
        getProfile(studentData.profile_id).then((res) => {
            dispatch(addStudentToStore(deserializeStudent(res.data)));
        });
    };

    const correctDependency = (data) => {
        if (
            data &&
            data.university &&
            data.university._id &&
            data.university._id !== universityId
        ) {
            data.program = null;
        }
        return {
            data: data,
        };
    };

    const createApplicationProps = useMemo(() => {
        return getCreateApplicationProps(
            dispatch,
            defaultData,
            classes,
            months,
            universityId,
            null,
            disableFields,
            disableButton,
            intakeMonth,
            universityLocation,
            programFee,
            intakeType,
            priorityData,
        );
    }, [
        months,
        universityId,
        disableFields,
        disableButton,
        intakeMonth,
        universityLocation,
        programFee,
        intakeType,
        priorityData,
    ]);

    return (
        <FormModal
            {...createApplicationProps}
            open={isModalOpen}
            onSuccessCallback={onSuccessCallback}
            handleClose={handleClose}
            onChangeApp={onChangeApp}
            isB2bFormModal={true}
            showPreview={false}
            correctDependency={correctDependency}
            customFormError={formMessage}
        />
    );
};

CreateApplication.propTypes = {
    handleClose: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
};
