// api
import { getPartners, getFilteredPartners, getPSAUser, searchSubAgent } from '@/api';

// rbac
import { isPartner, isPSA, isSubAgent } from '@/rbac';

// utils
import { store } from '@/store';

const SUBAGENT_FIELD_UISCHEMA = (referralPartnerId) => ({
    type: 'Control',
    scope: '#/properties/sub_agent',
    label: 'Counsellor / Branch',
    options: {
        component: 'search',
        searchApi: searchSubAgent(referralPartnerId, true),
        searchApiArgs: [{ q: '{{search}}' }],
        indexApi: searchSubAgent(referralPartnerId, true),
        indexApiArgs: [{ ids: '{{ids}}' }],
        valueKey: 'id',
        labelKey: 'first_name',
    },
});
const PARTNER_FIELD_UISCHEMA = {
    type: 'Control',
    scope: '#/properties/partner',
    label: 'Referral Partner',
    options: {
        component: 'search',
        searchApi: getPartners,
        searchApiArgs: [{ q: '{{search}}' }],
        indexApi: getFilteredPartners,
        indexApiArgs: [{ ids: '{{ids}}' }],
        valueKey: 'id',
        labelKey: 'name',
    },
};
// const INDIANSTATE_FIELD_UISCHEMA = {
//     type: 'Control',
//     scope: '#/properties/indian_state',
//     options: {
//         component: 'noChipMultiSelect',
//     },
// };

export const getFilterSchema = (options, stateOptions, isB2bAdmin) => {
    const currentYear = new Date().getFullYear();

    const nextFourYears = [
        (currentYear - 3).toString(),
        (currentYear - 2).toString(),
        (currentYear - 1).toString(),
        currentYear.toString(),
        (currentYear + 1).toString(),
        (currentYear + 2).toString(),
        (currentYear + 3).toString(),
        (currentYear + 4).toString(),
    ];

    const schema = {
        type: 'object',
        properties: {
            country: {
                type: 'array',
                items: {
                    type: 'string',
                    enum: options.countries || ['india'],
                },
            },
            intake_month: {
                type: 'array',
                items: {
                    type: 'string',
                    enum: [
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sep',
                        'Oct',
                        'Nov',
                        'Dec',
                    ],
                },
            },
            intake_year: {
                type: 'array',
                items: { type: 'string', enum: nextFourYears },
            },
            created_date: {
                type: 'object',
            },
        },
    };
    if (isB2bAdmin) {
        // schema.properties.indian_state = {
        //     type: 'array',
        //     items: {
        //         type: 'string',
        //         enum: stateOptions,
        //     },
        // };
        schema.properties.partner = {
            type: 'object',
            items: {
                type: 'object',
                properties: {
                    name: {
                        type: 'string',
                    },
                    id: {
                        type: 'number',
                    },
                },
            },
        };
    }
    if (!(isPSA() || isPartner())) {
        schema.properties.psa = {
            type: 'object',
            items: {
                type: 'object',
                properties: {
                    first_name: {
                        type: 'string',
                    },
                    id: {
                        type: 'number',
                    },
                },
            },
        };
    }
    if (isB2bAdmin || isPartner()) {
        schema.properties.sub_agent = {
            type: 'object',
            items: {
                type: 'object',
                properties: {
                    first_name: {
                        type: 'string',
                    },
                    id: {
                        type: 'number',
                    },
                },
            },
        };
    }

    return schema;
};

export const getFiltersUiSchema = (isB2bAdmin, filtersData) => {
    const referralPartnerId = isPartner()
        ? store.getState().userData.userData.id || null
        : (filtersData && filtersData.partner && filtersData.partner.id) || null;

    const uiSchema = {
        type: 'HorizontalLayout',
        elements: [
            {
                type: 'Control',
                scope: '#/properties/country',
                options: {
                    component: 'noChipMultiSelect',
                },
            },
            {
                type: 'Control',
                scope: '#/properties/intake_month',
                options: {
                    component: 'noChipMultiSelect',
                },
            },
            {
                type: 'Control',
                scope: '#/properties/intake_year',
                options: {
                    component: 'noChipMultiSelect',
                },
            },
            {
                type: 'Control',
                scope: '#/properties/created_date',
                options: {
                    component: 'date-range-picker',
                },
            },
        ],
    };

    if (isB2bAdmin) {
        if (referralPartnerId) {
            uiSchema.elements.push(
                PARTNER_FIELD_UISCHEMA,
                SUBAGENT_FIELD_UISCHEMA(referralPartnerId),
            );
        } else {
            uiSchema.elements.push(PARTNER_FIELD_UISCHEMA);
        }
    }

    if (isPartner() && referralPartnerId) {
        uiSchema.elements.push(SUBAGENT_FIELD_UISCHEMA(referralPartnerId));
    }

    if (!(isPSA() || isPartner() || isSubAgent())) {
        uiSchema.elements.push({
            type: 'HorizontalLayout',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/psa',
                    label: 'Business Manager',
                    options: {
                        component: 'search',
                        searchApi: getPSAUser,
                        searchApiArgs: [{ q: '{{search}}' }],
                        valueKey: 'id',
                        labelKey: 'first_name',
                    },
                },
            ],
        });
    }
    return uiSchema;
};
