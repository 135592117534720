import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(1.5),
    },
    cardContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    infoCardContainer: {
        width: '14%',
        padding: theme.spacing(0, 1, 1, 1),
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(0, 1),
        },
    },
}));
