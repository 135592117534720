import { deserializeServiceCardList } from "@/utils/deserializers/servicecard-deserializer";

import { fetchServicesFromCms } from "@/api/cms"

export const INITIALIZE_SERVICES_CARD = "INITIALIZE_SERVICES_CARD";

export const initializeServiceCard = (payload) =>{
    return{
        type:INITIALIZE_SERVICES_CARD,
        payload,
    };
};

export const servicesCmsGetAndStore = () =>{
	return (dispatch) =>{
		fetchServicesFromCms()
		.then((res) =>{
			const deserializedServiceList = deserializeServiceCardList(res.data.data || []);
			dispatch(initializeServiceCard(deserializedServiceList));
		})
		.catch((err) => {
			console.log(err);
		})
	}
}