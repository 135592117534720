import React, { useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

// mui
import { Box, Typography, Grid } from '@mui/material';

// comps
import { Tabs, getIcon, ProgramDrawer } from 'azent-storybook/dist';
import ProgramSearch from './partials/ProgramSearch';
import RecommendedCourses from './partials/Recommended';
import Shortlist from './partials/Shortlist';
import ApplyModal from '@/components/shared/ApplyModal';
import ProgramSearchFilters from './partials/ProgramSearchFilters';
import StudentSelect from '@/components/shared/StudentSelect';

// utils
import {
    getCourseFinderPathFromTabIndex,
    getCourseFinderTabIndexFromPath,
} from '@/utils/courseFinder';
import routePaths, { studentPaths } from '@/utils/route-paths';
import { deserializeStudent } from '@/utils/deserializers/student-deserializer';
import { isPartner, isSubAgent } from '@/rbac';

// styles
import { useStyles } from './styles';

// api
import { getProfile, getApplicationPriorityData } from '@/api';

// actions
import { addStudentToStore } from '@/store/slices/studentSlice';
import { goToRoute } from '@/store/actions/navigationActions';

export default function CourseFinder({ match }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const id = match.params.id;
    const path = match.params.path;

    const [searchProgramList, setSearchProgramList] = useState([1, 2, 3, 4, 5]);
    const [programsLoading, setProgramsLoading] = useState(true);
    const [applyModalProps, setApplyModalProps] = useState(null);
    const [showProgram, setShowProgram] = useState(null);
    const [loading, setLoading] = useState(false);
    const [student, setStudent] = useState();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [filters, setFilters] = useState({});
    const [priorityData, setPriorityData] = React.useState(['']);

    const profileData = useSelector((state) => state.student.data[id]);
    const location = useSelector((state) => state.router.location);
    const searchParams = new URLSearchParams(location.search);

    const [goBack, setGoBack] = useState(searchParams.get('goBack'));
    const [isDisableCreateApplication, setDisableCreateApplication] = useState(false);

    useEffect(() => {
        if (id && !profileData) {
            setLoading(true);
            getProfile(id)
                .then((response) => {
                    dispatch(addStudentToStore(deserializeStudent(response.data)));
                    setLoading(false);
                    setStudent(deserializeStudent(response.data));
                })
                // TODO : ADD Failure case
                .catch((err) => {
                    console.log('failed to fetch profile', err);
                    setLoading(false);
                });
        }
    }, [id]);

    useEffect(() => {
        setStudent(profileData);
    }, [profileData]);

    useEffect(() => {
        if (id) {
            getApplicationPriorityData(id)
                .then((res) => {
                    setPriorityData(res.data.priorities);
                })
                .catch(() => {
                    setPriorityData(['']);
                });
        }
    }, [id]);

    useEffect(() => {
        if (
            (isPartner() || isSubAgent()) &&
            student &&
            student.applications &&
            student.applications.length >= 5
        )
            setDisableCreateApplication(true);
        else setDisableCreateApplication(false);
    }, [student]);

    const selectedTab = useMemo(() => getCourseFinderTabIndexFromPath(path), [path]);

    const showProgramFilters = useMemo(() => selectedTab === 0, [selectedTab]);

    const setSelectedTab = (index) => {
        dispatch(
            goToRoute(
                routePaths.COURSE_FINDER,
                {
                    path: getCourseFinderPathFromTabIndex(index),
                    id,
                },
                { ...Object.fromEntries(searchParams) },
            ),
        );
    };

    const handleGoBack = () => {
        dispatch(
            goToRoute(routePaths.STUDENT_PROFILE_VIEW, {
                path: studentPaths.SUMMARY,
                id: id,
            }),
        );
    };

    return (
        <Box pt={1}>
            {Boolean(applyModalProps) && (
                <ApplyModal
                    open={Boolean(applyModalProps)}
                    setOpen={setApplyModalProps}
                    showPreview={false}
                    {...applyModalProps}
                />
            )}
            {Boolean(showProgram) && (
                <ProgramDrawer
                    open={Boolean(showProgram)}
                    onClose={() => {
                        setShowProgram(null);
                    }}
                    onClickUniversity={() => {}}
                    programData={showProgram}
                    width="26%"
                />
            )}
            {
                goBack && (
                    <Box
                        onClick={handleGoBack}
                        ml={2}
                        mt={3}
                        mb={1.5}
                        className={classes.titleContainerClickable}>
                        {getIcon('prev-arrow', 'primary', 'small')}
                        <Typography color="primary" variant="h5">
                            Back to Student
                        </Typography>
                    </Box>
                )
                // Hide this for now
                // <Box ml={2} mt={3} mb={1.5} className={classes.titleContainer}>
                //     {getIcon('search', 'primary', 'medium')}
                //     <Typography className={classes.pageTitle} variant="h5">
                //         Program Search
                //     </Typography>
                // </Box>
            }
            <Grid container>
                {showProgramFilters && (
                    <Grid item xs={3}>
                        <ProgramSearchFilters
                            id={id}
                            path={path}
                            setData={setSearchProgramList}
                            setPage={setPage}
                            setPerPage={setPerPage}
                            setTotalRows={setTotalRows}
                            loading={programsLoading}
                            setLoading={setProgramsLoading}
                            profileData={profileData}
                            student={student}
                            setStudent={setStudent}
                            setGoBack={setGoBack}
                            page={page}
                            perPage={perPage}
                            filters={filters}
                            setFilters={setFilters}
                        />
                    </Grid>
                )}
                <Grid item xs={showProgramFilters ? 9 : 12}>
                    <Tabs
                        value={selectedTab}
                        setValue={setSelectedTab}
                        tabList={[
                            {
                                label: 'Course Finder',
                                content: () => (
                                    <ProgramSearch
                                        id={id}
                                        profileData={profileData}
                                        setApplyModalProps={setApplyModalProps}
                                        data={searchProgramList}
                                        totalRows={totalRows}
                                        page={page}
                                        perPage={perPage}
                                        programsLoading={programsLoading}
                                        setProgramsLoading={setProgramsLoading}
                                        setShowProgram={setShowProgram}
                                        setPerPage={setPerPage}
                                        setPage={setPage}
                                        isDisableCreateApplication={isDisableCreateApplication}
                                        priorityData={priorityData}
                                    />
                                ),
                            },
                            {
                                label: 'AI recommendations',
                                content: () => (
                                    <>
                                        <Box px={2}>
                                            <StudentSelect
                                                path={path}
                                                profileData={profileData}
                                                filters={filters}
                                                loading={loading}
                                                setGoBack={setGoBack}
                                                page={page}
                                                perPage={perPage}
                                                studentId={id}
                                            />
                                        </Box>
                                        <RecommendedCourses
                                            id={id}
                                            profile={profileData}
                                            profileLoading={loading}
                                            setApplyModalProps={setApplyModalProps}
                                            setShowProgram={setShowProgram}
                                            isDisableCreateApplication={isDisableCreateApplication}
                                        />
                                    </>
                                ),
                            },
                            {
                                label: `Shortlisted ${
                                    get(profileData, 'shortlists', []).length
                                        ? `(${get(profileData, 'shortlists', []).length})`
                                        : ''
                                }`,
                                content: () => (
                                    <>
                                        <Box px={2}>
                                            <StudentSelect
                                                path={path}
                                                profileData={profileData}
                                                loading={loading}
                                                filters={filters}
                                                setGoBack={setGoBack}
                                                page={page}
                                                perPage={perPage}
                                                studentId={id}
                                            />
                                        </Box>
                                        <Shortlist
                                            id={id}
                                            profile={profileData}
                                            loading={loading}
                                            setApplyModalProps={setApplyModalProps}
                                            setShowProgram={setShowProgram}
                                            isDisableCreateApplication={isDisableCreateApplication}
                                        />
                                    </>
                                ),
                            },
                        ]}
                        tabsProps={{
                            centered: false,
                            variant: 'standard',
                        }}
                        classes={{
                            tab: classes.customTab,
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
CourseFinder.propTypes = {
    match: PropTypes.object.isRequired,
};
