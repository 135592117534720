import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// utils
import { canAccessForPolicies, isCounsellor } from './can-access';

// components
import { NotAuthorised } from '@/components/errors/NotAuthorised';

let AuthenticatedRoute = function (props) {
    let policies = useSelector((state) => state.userData.userData.policies);

    return isCounsellor() ? (
        <NotAuthorised />
    ) : (
        <>
            {canAccessForPolicies(props.feature, policies) ? (
                <>{props.children}</>
            ) : (
                <NotAuthorised />
            )}
        </>
    );
};

AuthenticatedRoute.propTypes = {
    children: PropTypes.node.isRequired,
    feature: PropTypes.string.isRequired,
};

export default AuthenticatedRoute;
