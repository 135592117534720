import React from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';

// Mui
import { Dialog, Card, Box, Typography } from '@mui/material';

// utils
import getSvg from '@/utils/get-svg';

// actions
import { setUniversalDialog } from '@/store/slices/universalDialogSlice';

export default function UniversalModal({ open, setBackDrop, modalWidth }) {
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setUniversalDialog(false));
        setBackDrop(true);
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={modalWidth || 'sm'} fullWidth>
            <Card sx={{ p: 5 }}>
                <Box
                    mt={4}
                    mb={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width="100%">
                    {getSvg('caution', 'inherit', '80px')}
                </Box>
                <Typography textAlign="center" variant="h5">
                    IMPORTANT: We will be completely switching to the new version within 30 days.
                </Typography>
            </Card>
        </Dialog>
    );
}

UniversalModal.propTypes = {
    modalWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    open: PropTypes.bool.isRequired,
    setBackDrop: PropTypes.func.isRequired,
};

UniversalModal.defaultProps = {
    open: false,

    setBackDrop: () => {},
};
