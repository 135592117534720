import { get } from 'lodash';

import instance from './instances/auth';
import universityInstance from './instances/university-service';
import studentInstance from './instances/student-service';

export const SELF_SUBAGENT_DATA = {
    id: 999999999,
    first_name: 'Self/ Partner',
    last_name: '',
    is_active: true,
    role_name: 'SUB_AGENT',
};

export function getSubAgentUsers(id, query) {
    let search = new URLSearchParams(query);
    return instance.get(`/v1/partners/${id}/sub-agents?${search.toString()}`);
}

export const searchSubAgent = (partnerId, getFilteredDataForKeys) => (query) => {
    let search = new URLSearchParams(query);
    return new Promise((resolve, reject) => {
        if (!partnerId) {
            resolve({
                data: [],
            });
        }
        instance
            .get(`/v1/partners/${partnerId}/sub-agents?${search.toString()}`)
            .then((response) => {
                let subAgentList = get(response, 'data.data.sub_agents', []);
                if (getFilteredDataForKeys) {
                    subAgentList = subAgentList.map(({ id, first_name }) => ({
                        id,
                        first_name,
                    }));
                }
                resolve({
                    data: subAgentList,
                });
            })
            .catch((e) => {
                reject(e);
            });
    });
};

export const searchActiveSubAgentForStudent = (partnerId, getFilteredDataForKeys) => (query) => {
    let search = new URLSearchParams(query);
    return new Promise((resolve, reject) => {
        if (!partnerId) {
            resolve({
                data: [],
            });
        }
        instance
            .get(`/v1/partners/${partnerId}/sub-agents?${search.toString()}`)
            .then((response) => {
                let subAgentList = get(response, 'data.data.sub_agents', []);
                subAgentList.push(SELF_SUBAGENT_DATA);
                subAgentList = subAgentList.filter((subagent) => subagent.is_active == true);
                if (getFilteredDataForKeys) {
                    subAgentList = subAgentList.map(({ id, first_name, last_name }) => ({
                        id,
                        first_name,
                        last_name,
                    }));
                }
                resolve({
                    data: subAgentList,
                });
            })
            .catch((e) => {
                reject(e);
            });
    });
};

export const searchSubAgentToTransfer = (partnerId, transfer_from_id) => (query) => {
    let search = new URLSearchParams(query);
    return new Promise((resolve, reject) => {
        instance
            .get(`/v1/partners/${partnerId}/sub-agents?${search.toString()}`)
            .then((response) => {
                let subAgentList = get(response, 'data.data.sub_agents', []);
                subAgentList.push(SELF_SUBAGENT_DATA);
                resolve({
                    data: subAgentList.filter(
                        (subagent) => subagent.id != transfer_from_id && subagent.is_active == true,
                    ),
                });
            })
            .catch((e) => {
                reject(e);
            });
    });
};

export function getSubAgentProfile(id) {
    return instance.get(`/v1/partners/sub-agent/${id}`);
}

export function createUser(data) {
    return instance.post('/v1/partners', data);
}

export function updateUser(id, data) {
    return instance.put(`/v1/partners/sub-agent/${id}`, data);
}

export function setResetPassword(id, data) {
    return universityInstance.put(`/v1/auth/${id}/reset`, data);
}

export function assignStudentToSubAgent(data) {
    return studentInstance.post('/v1/profiles/sub_agent/assign/', data);
}

export function changeSubAgentStatus(id, data) {
    return instance.patch(`/v1/partners/status/${id}`, data);
}

export function transferStudent(data) {
    return studentInstance.post('/v1/profiles/sub_agent/transfer/', data);
}
