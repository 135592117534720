import PropTypes from 'prop-types';
import React from 'react';

// material
import { Typography, Box } from '@mui/material';

// components
import { LinearProgressBar } from 'azent-storybook/dist';

// styles
import { useStyles } from './styles';

export default function ProfileProgressIndicator({ profileCompletionPercentage }) {
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Box>
                <Typography variant={'p'} className={classes.headingText}>
                    Profile
                    <span className={classes.rightText}>
                        <span className={classes.percentageText}>
                            {profileCompletionPercentage}%{' '}
                        </span>
                        Profile Completed
                    </span>
                </Typography>
            </Box>
            <LinearProgressBar profileCompletionPercentage={profileCompletionPercentage} />
        </Box>
    );
}

ProfileProgressIndicator.propTypes = {
    profileCompletionPercentage: PropTypes.number.isRequired,
};
