// utils
import { intakesEnumOptions } from '@/utils/shortlists';

// APIs
import { universitySearch, getUniversities } from '@/api';

const getCategorySearch = (categories) => {
    return Promise.resolve({ data: categories });
};

export function getSchema() {
    return {
        type: 'object',
        properties: {
            universities: {
                type: 'object',
                items: {
                    type: 'object',
                    properties: {
                        _id: {
                            type: 'string',
                        },
                        name: {
                            type: 'string',
                        },
                    },
                },
            },
            name: {
                type: 'string',
            },
            level: {
                type: 'string',
                enum: ['Bachelor', 'Master', 'PhD'],
            },
            intakes: {
                type: 'array',
                items: {
                    type: 'string',
                    enum: intakesEnumOptions
                        .filter(
                            (item) =>
                                item.value !== 'Jan-Feb-Mar-Apr' &&
                                item.value !== 'Aug-Sep-Oct-Nov-Dec' &&
                                item.value !== 'May-Jun-Jul',
                        )
                        .map((data) => data.value),
                },
            },
            type: {
                type: 'string',
                enum: ['Pathway', 'Certificate', 'Diploma', 'Degree'],
            },
            tuition_fee: {
                type: 'string',
            },
            duration: {
                type: 'string',
            },
            application_fee: {
                type: 'string',
            },
            application_deadline: {
                type: 'string',
                format: 'date',
            },
        },
        required: ['universities', 'name', 'level', 'intakes', 'type'],
    };
}

export function getUiSchema({ universities, programs }) {
    return {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'VerticalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/universities',
                        options: {
                            component: 'search',
                            searchApi: universitySearch,
                            searchApiArgs: universities
                                ? [
                                      {
                                          q: '{{search}}',
                                          universities: universities,
                                      },
                                  ]
                                : [{ q: '{{search}}' }],
                            indexApi: getUniversities,
                            indexApiArgs: [{ ids: '{{ids}}' }],
                            valueKey: '_id',
                            labelKey: 'name',
                        },
                        label: 'University',
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/name',
                        label: 'Course Name',
                        options: {
                            component: 'search',
                            searchApi: () => getCategorySearch(programs),
                            searchApiArgs: [{ q: '{{search}}' }],
                            valueKey: 'name',
                            labelKey: 'name',
                            isSearchCustom: true,
                        },
                        rule: {
                            effect: universities ? 'ENABLE' : 'DISABLE',
                            condition: {
                                scope: '',
                                schema: {},
                            },
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/intakes',
                        label: 'Intakes',
                        options: {
                            component: 'multiSelect',
                            enumOptions: intakesEnumOptions,
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/tuition_fee',
                        label: 'Tuition Fee',
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/type',
                        label: 'Type',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/level',
                        label: 'Level',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/duration',
                        label: 'Duration(in months)',
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/application_fee',
                        label: 'Application Fee',
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/application_deadline',
                        label: 'Application Deadline',
                    },
                ],
            },
        ],
    };
}
