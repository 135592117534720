// constants
import featureRoleMap from './feature-policy-map';

// utils
import { store } from '@/store';
import { isEmpty } from 'lodash';

let canAccessForPolicies = function (feature, policies) {
    return policies.includes(featureRoleMap[feature]);
};

let canAccess = function (feature) {
    let policies = store.getState().userData.userData.policies;
    return isCounsellor() ? false : canAccessForPolicies(feature, policies);
};

let isCounsellor = function () {
    let roles = store.getState().userData.userData.roles;
    return roles.map((i) => i.name).includes('COUNSELLOR');
};

let isRoleDefined = function () {
    let roles = store.getState().userData.userData.roles;
    if (isEmpty(roles)) return false;
    else return true;
};

export { canAccessForPolicies, canAccess, isCounsellor, isRoleDefined };
