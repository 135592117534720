import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// utils
import { colorCodes } from '@/utils/courseFinder';
import routePaths, { courseFinderPaths } from '@/utils/route-paths';
import { addToShortlist, courseShortlisted, courseAppliedv2 } from '@/utils/shortlists';
import { deserializeStudent } from '@/utils/deserializers/student-deserializer';

// mui
import { Box, Grid, TablePagination } from '@mui/material';

// actions
import { goToRoute, setQuery } from '@/store/actions/navigationActions';
import { addStudentToStore } from '@/store/slices/studentSlice';

// comps
import { CourseCard } from '@/components/shared/CourseCard';
import { ActionBox } from 'azent-storybook/dist';

let timeout = null;
export default function ProgramSearch({
    id,
    profileData,
    setApplyModalProps,
    data,
    totalRows,
    page,
    perPage,
    programsLoading,
    setProgramsLoading,
    setShowProgram,
    setPerPage,
    setPage,
    isDisableCreateApplication,
    priorityData,
}) {
    const dispatch = useDispatch();
    const courseBoxRef = useRef();
    const location = useSelector((state) => state.router.location);
    const searchParams = new URLSearchParams(location.search);

    const shortlistProgram = (id, programs) => {
        if (id) {
            setProgramsLoading(true);
            addToShortlist(id, programs).then((res) => {
                dispatch(addStudentToStore(deserializeStudent(res.data)));
                setProgramsLoading(false);
            });
        }
    };
    const applyNow = (course) => {
        if (id)
            setApplyModalProps({
                course,
                profile_id: id,
                program_id: course.id,
                priorityData: priorityData,
            });
        else {
            document
                .getElementById('#/properties/studentinput-field')
                .getElementsByTagName('input')[0]
                .focus();
        }
    };

    const redirectToShortlist = () => {
        dispatch(
            goToRoute(
                routePaths.COURSE_FINDER,
                {
                    path: courseFinderPaths.SHORTLISTS,
                    id: id,
                },
                { ...Object.fromEntries(searchParams) },
            ),
        );
    };

    const addCourseToShortlist = (course) => {
        if (id) {
            shortlistProgram(id, course);
        } else {
            document
                .getElementById('#/properties/studentinput-field')
                .getElementsByTagName('input')[0]
                .focus();
        }
    };

    const onClickClearAll = () => {
        dispatch(
            goToRoute(
                routePaths.COURSE_FINDER,
                {
                    path: courseFinderPaths.VIEW,
                    id,
                },
                {
                    page: 1,
                    per_page: '10',
                    profileId: id,
                    client_type: 'Client',
                },
            ),
        );
    };

    return (
        <Box ref={courseBoxRef} sx={{ overflow: 'scroll', scrollBehavior: 'smooth' }}>
            <Grid container>
                <Grid item xs={12} pt={1} pr={3} pl={1}>
                    {!data.length ? (
                        <Box px={2} mt={2}>
                            <ActionBox
                                raised={false}
                                title={{
                                    text: 'Looks like there are no programs for these filters',
                                    variant: 'h5',
                                }}
                                buttons={[
                                    {
                                        color: 'secondary',
                                        label: 'clear filters',
                                        onClick: onClickClearAll,
                                    },
                                ]}
                            />
                        </Box>
                    ) : (
                        <>
                            {data.map((course, index) => (
                                <Box key={index} py={1}>
                                    <CourseCard
                                        onClickName={() => {
                                            setShowProgram({
                                                ...course,
                                                logoColor: colorCodes[index % 3],
                                            });
                                        }}
                                        disableButtons={Boolean(!id)}
                                        isShortlisted={
                                            profileData && courseShortlisted(profileData, course)
                                        }
                                        isApplied={
                                            profileData && courseAppliedv2(profileData, course)
                                        }
                                        programLink={course.program_link}
                                        courseName={course.name}
                                        university={course.university}
                                        loading={programsLoading}
                                        intakes={course.intakes || []}
                                        intakeDetails={course.intakeDetails || []}
                                        tuitionCost={course.tuitionCost}
                                        color={colorCodes[index % 3]}
                                        duration={course.duration}
                                        onClickAddToShortlist={() => addCourseToShortlist(course)}
                                        applicationFee={
                                            course.applicationCost ? course.applicationCost : null
                                        }
                                        onClickApplyNow={() => {
                                            applyNow(course);
                                        }}
                                        redirectToShortlist={redirectToShortlist}
                                        // NOTE :REMOVE SLUG URL
                                        slugUrl={
                                            course.university && course.university.slug
                                                ? course.university.slug
                                                : null
                                        }
                                        isDisableCreateApplication={isDisableCreateApplication}
                                    />
                                </Box>
                            ))}
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 20, 50]}
                                count={totalRows}
                                rowsPerPage={perPage}
                                page={page ? page - 1 : 0}
                                onPageChange={(_e, p) => {
                                    setPage(p + 1);
                                    if (timeout) clearTimeout(timeout);
                                    timeout = setTimeout(() => {
                                        courseBoxRef.current.scrollTo(0, 0);
                                        dispatch(setQuery('page', p + 1));
                                    }, [500]);
                                }}
                                onRowsPerPageChange={(e) => {
                                    setPerPage(e.target.value);
                                    if (timeout) clearTimeout(timeout);
                                    timeout = setTimeout(() => {
                                        dispatch(setQuery('per_page', e.target.value));
                                    }, [500]);
                                }}
                                component={'div'}
                            />
                            <Box sx={{ margin: '40px 0px 15px 0px' }}>
                                Disclaimer: For the latest information visit the university&apos;s
                                website.
                            </Box>
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}

ProgramSearch.propTypes = {
    id: PropTypes.string,
    initialFilters: PropTypes.object,
    loading: PropTypes.bool,
    setApplyModalProps: PropTypes.func,
    profileData: PropTypes.object,
    data: PropTypes.array,
    perPage: PropTypes.number,
    page: PropTypes.number,
    totalRows: PropTypes.number,
    programsLoading: PropTypes.bool,
    setProgramsLoading: PropTypes.func,
    setShowProgram: PropTypes.func,
    setPerPage: PropTypes.func,
    setPage: PropTypes.func,
    isDisableCreateApplication: PropTypes.bool,
    priorityData: PropTypes.array,
};
