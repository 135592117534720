import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

const renderers = {
	image: (imageProps) => (
		<img {...imageProps} style={{ maxWidth: "100%", maxHeight: "100%" }} />
	),
};

export default function Markdown(props) {
	return (
		<ReactMarkdown
			escapeHtml={false}
			renderers={renderers}
			className={props.className || ""}
		>
			{props.source}
		</ReactMarkdown>
	);
}

Markdown.propTypes = {
	source: PropTypes.string.isRequired,
	className: PropTypes.string,
};

Markdown.defaultProps = {
	className: "",
};
