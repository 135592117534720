import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0, 3),
    },
    rootContainer: {
        padding: theme.spacing(8, 13),
        background:
            'linear-gradient(180deg, rgba(120, 165, 255, 0.2) -28%, rgba(245, 211, 32, 0.15) 100%)',
        backgroundPosition: 'bottom',
        height: '100vh',
    },
    container: {
        direction: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    // Image carousel
    carouselWrapper: {
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        height: '500px',
        borderRadius: 0,
        boxShadow: 'none',
        background: 'transparent',
    },
    image: {
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(35),
        },
        [theme.breakpoints.up('lg')]: {
            width: theme.spacing(45),
        },
        [theme.breakpoints.up('xl')]: {
            width: theme.spacing(50),
        },
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: '24px !important',
        fontWeight: '600',
        paddingBottom: '14px',
    },
    description: {
        fontWeight: 400,
        fontSize: theme.spacing(1.8),
        textAlign: 'center',
    },
    //vertical divider
    verticalLine: {
        border: '0.5px solid #C4C4C4',
        width: '0px',
        height: '400px',
        left: '683px',
        top: '145px',
        margin: '100px 100px 32px',
    },
    // Login Panel Right-side
    beginJourneyTitle: {
        textAlign: 'center',
    },
    loginTitle: {
        marginBottom: '10px !important',
        fontFamily: 'Montserrat !important',
        fontWeight: '600 !important',
        fontSize: '28px !important',
        color: '#000000',
    },
    beginTitle: {
        fontFamily: 'Montserrat !important',
        fontWeight: '500 !important',
        fontSize: '20px !important',
        color: '#000000',
    },
    azentTitle: {
        fontFamily: 'Montserrat !important',
        fontWeight: '500 !important',
        fontSize: '20px !important',
        color: '#F56A20',
    },
    errorClass: {
        color: 'red',
        fontSize: theme.spacing(1.5),
    },
    //OTP
    resendOtpWrapper: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        top: theme.spacing(2),
        fontFamily: 'Montserrat !important',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: theme.spacing(2),
    },
    dontReceive: {
        color: ' #6D6D6D',
    },
    resendOtp: {
        color: '#F56A20',
        cursor: 'pointer',
    },

    // form fields and buttons
    fieldContainer: {
        position: 'relative',
    },
    formHeaderContainer: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
    },
    formContentContainer: {
        width: '100%',
        margin: '0 auto',
    },
    inputTitle: {
        fontFamily: 'Montserrat !important',
        fontSize: '16px !important',
        lineHeight: '42px !important',
        color: '#6D6D6D !important',
        width: '100%',
    },
    textFieldRoot: {
        borderRadius: '10px',
        height: '44px',
        width: '100%',
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            border: `1px solid #A9A9A9 !important`,
        },
    },
    cssFocused: {},
    notchedOutline: {
        border: `1px solid #A9A9A9 !important`,
    },

    buttonsContainer: {
        position: 'relative',
    },
    greenButton: {
        background: '#00C853',
        height: '44px',
        borderRadius: '22px',
        color: theme.palette.common.white,
        boxShadow: '0px 4px 50px #CFFBCF',
        width: '100%',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '22px',
        textAlign: 'center',
        '&:hover': {
            backgroundColor: '#3D8944',
        },
    },
    whiteButton: {
        background: '#FFFFFF',
        height: '44px',
        borderRadius: '22px',
        color: '#000000',
        border: '1px solid #A9A9A9',
        width: '100%',
        fontFamily: 'Montserrat',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '22px',
        textAlign: 'center',
        top: '10px',
        '&:hover': {
            background: '#FFFFFF',
            color: '#000000',
            border: '1px solid #A9A9A9',
        },
    },
    outlinedButton: {
        height: '44px',
        borderRadius: '22px',
        border: '1px solid #F56A20',
        fontFamily: 'Montserrat',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '22px',
        textAlign: 'center',
        color: '#F56A20',
    },
    buttonWidth: {
        width: '80%',
    },
    forgotPassLink: {
        fontFamily: 'Montserrat',
        color: theme.palette.neutral.dark,
        fontSize: '14px',
        zIndex: '999',
        '&:hover': {
            background: 'transparent',
            textDecoration: 'underline',
        },
        paddingRight: '0px',
        marginRight: '7px',
    },
    otpName: {
        cursor: 'progress',
        fontWeight: '500',
        fontSize: '15px',
        [theme.breakpoints.down('sm')]: {
            left: '80px',
        },
    },
    phonePrefix: {
        width: '15%',
        marginRight: '5%',
    },
    editIcon: {
        fontSize: '18px',
        color: 'darkgray',
    },
}));
