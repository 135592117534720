import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3, 4),
        height: 'calc(100vh - 123px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    createAppButton: {
        fontSize: '14px',
        fontWeight: '600',
        borderRadius: '10px',
        color: 'white',
        lineHeight: '1.75',
        fontFamily: 'Montserrat',
        padding: '8px 20px',
        height: '50px',
        backgroundColor: '#00c853',
        '&:hover': {
            backgroundColor: '#3D8944',
        },
    },
    circle: {
        height: '63px',
        width: '63px',
        backgroundColor: '#E9E9E9',
        borderRadius: '50%',
        display: 'inline-block',
    },
    userIcon: {
        position: 'absolute',
        right: '11px',
        top: '17px',
    },
    iconWrapper: {
        position: 'relative',
    },
    label: {
        paddingBottom: theme.spacing(1.5),
        paddingTop: theme.spacing(0.5),
    },
}));
