import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

//routes
import routePaths, { studentPaths } from '@/utils/route-paths';

// actions
import { goToRoute } from '@/store/actions/navigationActions';

// mui
import { List, ListItem, ListItemText, Typography, Skeleton, Divider } from '@mui/material';

// mui styles
import { useStyles } from './style';
import { StyledItem } from './StyledItem';
import { deserializeServiceCard } from '@/utils/deserializers/servicecard-deserializer';
import { fetchServiceFromCms } from '@/api/cms';
import { servicesCmsGetAndStore } from '@/store/actions/serviceCmsAction';

export default function AncillaryStepper({ services, servicesCount, studentId, serviceId }) {
    const dispatch = useDispatch();
    const loaderData = [1, 2];
    const urlPath = useSelector((state) => state.router.location.pathname);
    const activeServiceId = urlPath.split('/services/').pop();
    const classes = useStyles;
    const [serviceCard, setServiceCard] = useState();
    const servicesCard = useSelector((state) => state.serviceCards.data);

    useEffect(() => {
        if (
            !serviceCard ||
            !serviceCard.data ||
            serviceCard.data.length === null ||
            serviceCard.data.length < 2
        ) {
            dispatch(servicesCmsGetAndStore());
        }
    }, []);
    function getAncillaryServiceCard() {
        fetchServiceFromCms(serviceId).then((res) => {
            deserializeServiceCard(res.data, serviceId);
            setServiceCard(
                deserializeServiceCard(
                    res.data && res.data.data && res.data.data.attributes,
                    res.data && res.data.data && res.data.data.id,
                ),
            );
        });
    }

    useEffect(() => {
        getAncillaryServiceCard();
    }, [serviceId]);

    let getDescription = function (cms_service_id) {
        let description = null;
        let particularServiceCard = servicesCard.filter((scard) => scard.id == cms_service_id);
        description =
            cms_service_id &&
            particularServiceCard &&
            particularServiceCard.length &&
            particularServiceCard[0].description;
        if (!description) return;
        if (description.length < 61) {
            return description;
        } else {
            return description.substr(0, 65) + ' ...';
        }
    };

    const onStepperClick = (event, service) => {
        event.stopPropagation();
        dispatch(
            goToRoute(routePaths.STUDENT_PROFILE_VIEW, {
                id: studentId,
                aid: service.cms_service_id,
                path: studentPaths.SERVICES,
            }),
        );
    };

    if (!services)
        return (
            <List dense>
                {loaderData.map((index) => (
                    <ListItem key={index} className={classes.ListItemLoading}>
                        <ListItemText>
                            <Skeleton animation="wave" width={'60%'} variant="text" />
                            <Skeleton animation="wave" width={'100%'} variant="text" />
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        );

    return (
        <List dense className={classes.ListItem}>
            {services.map((service, index) => (
                <>
                    <ListItem key={index} sx={{ px: 2 }}>
                        <StyledItem
                            selected={service.cms_service_id == activeServiceId ? true : false}
                            onClick={(event) => onStepperClick(event, service)}>
                            <ListItemText>
                                <Typography
                                    variant={'h6'}
                                    sx={{
                                        color:
                                            service.cms_service_id == activeServiceId
                                                ? '#78A5FF'
                                                : '#A9A9A9',
                                    }}>
                                    {service.name}
                                </Typography>
                                <Typography
                                    variant={'h6'}
                                    sx={{
                                        color:
                                            service.cms_service_id == activeServiceId
                                                ? '#000000'
                                                : '#6D6D6D',
                                    }}>
                                    {getDescription(service.cms_service_id)}
                                </Typography>
                            </ListItemText>
                        </StyledItem>
                    </ListItem>
                    {servicesCount - 1 != index && <Divider variant="middle"></Divider>}
                </>
            ))}
        </List>
    );
}

AncillaryStepper.propTypes = {
    services: PropTypes.array,
    servicesCount: PropTypes.number,
    studentId: PropTypes.string,
    serviceId: PropTypes.string,
};
