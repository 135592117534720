import { getDocumentTaggingForComments } from '@/utils/partner-documents';

export const getCommentsUiSchema = function (config) {
    let uiSchema = {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'Control',
                scope: '#/properties/comment',
                label: 'Add Comment',
                description: 'Add Your Comment Here!',
                options: {
                    multi: true,
                    rows: 1,
                    // endIcon: getIcon(
                    //     'attach-file',
                    //     config.addAttachment ? 'primary' : 'secondary',
                    // ),
                    // onEndIconClick: config.onAttachFileClick,
                },
            },
        ],
    };

    if (config.addAttachment) {
        uiSchema.elements.push({
            type: 'Control',
            scope: '#/properties/files',
            label: '',
            options: {
                component: 'resource',
                selectCategoryLabel: 'Choose Document Category',
                uniqueCategory: false,
                fileCategories: getDocumentTaggingForComments(config?.type),
            },
        });
    }

    return uiSchema;
};
