import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// mui
import { Box, Grid } from '@mui/material';

// css
import { useStyles } from './style';

//comps
import { FormComponent, Button, Alert } from 'azent-storybook/dist';

//utils
import { isSubAgent } from '@/rbac';
import { setToast } from '@/store/slices/toastSlice';
import { toastMessages } from '@/utils/toastMessages';
import { goToRoute } from '@/store/actions/navigationActions';
import routePaths from '@/utils/route-paths';
import { deserializeSubagentInfo } from '@/utils/deserializers/subagentInfo-deserializer';
import { addSubAgentToStore } from '@/store/slices/subAgentSlice';

//comps
import { passwordNote } from '@/props-constants/sub-agent/setPassword';
import { PasswordPolicy } from '@/components/Login/TermsAndConditions';

//api
import { getSubAgentProfile } from '@/api/sub-agent';

let formErrors = null;
let timeout = null;

export default function SetPassword({
    defaultData,
    onChangeHandler,
    schema,
    uiSchema,
    submitButton,
    onSuccessCallback,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const location = useSelector((state) => state.router.location);
    let search = new URLSearchParams(location.search);
    const subAgentId = search.get('id');

    const [data, setData] = useState(defaultData || {});
    const [loading, setLoading] = useState(false);
    const [displayErrors, setDisplayErrors] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);

    const strongPassword = new RegExp(
        '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})',
    );

    const onChange = ({ data, errors }) => {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            onChangeHandler(data);
            setData(data);
            formErrors = errors;
        }, 400);
    };

    function validateForm(formData) {
        if (formData) {
            const { confirm_password, new_password } = formData;
            if (confirm_password && new_password) {
                if (confirm_password != new_password) {
                    dispatch(setToast(toastMessages.passwordDontMatch));
                    return;
                } else if (!strongPassword.test(confirm_password)) {
                    dispatch(setToast(toastMessages.passwordGuidelines));
                    return;
                } else return true;
            }
        }
    }

    const resetFormData = () => {
        setData({
            id: subAgentId,
            step: 1,
            password: undefined,
            new_password: null,
            confirm_password: null,
        });
    };

    const onClickSubmit = async () => {
        if (formErrors && formErrors.length) {
            setDisplayErrors(true);
            return;
        }
        setLoading(true);
        try {
            let response = null;
            if (validateForm(data)) {
                response = await submitButton.onClick(data);
            }
            if (response) {
                if (response.data) {
                    await onSuccessCallback(response.data, data, isSubAgent());
                    resetFormData();
                    getSubAgentProfile(subAgentId)
                        .then((res) => {
                            dispatch(
                                addSubAgentToStore(
                                    deserializeSubagentInfo(res.data.data.user_info),
                                ),
                            );
                        })
                        .catch(() => {});
                }
            }
        } catch (err) {
            if (err?.response?.data?.error?.message) setErrorMsg(err.response.data.error.message);
            else dispatch(setToast(toastMessages.backendIssue));
        } finally {
            setLoading(false);
            setDisplayErrors(false);
        }
    };

    const onCloseErrorModalCallback = () => {
        setErrorMsg(null);
    };

    return (
        <>
            {errorMsg && (
                <Alert
                    title={errorMsg}
                    severity="error"
                    onCloseCallback={onCloseErrorModalCallback}
                />
            )}
            <Box className={classes.container}>
                <Grid container className={classes.subAgentSummaryContainer}>
                    <FormComponent
                        schema={schema}
                        uiSchema={uiSchema.largeScreenUiSchema}
                        data={data}
                        onChange={onChange}
                        readOnlyForm={false}
                        showFormErrors={displayErrors}
                    />
                </Grid>
            </Box>
            {isSubAgent() && passwordNote()}
            <Box pl={1} pt={1}>
                {PasswordPolicy()}
            </Box>

            <Grid container className={classes.buttonsContainer}>
                <Grid item className={classes.previousButtonContainer}>
                    <Button
                        onClick={() => {
                            dispatch(goToRoute(routePaths.SUB_AGENT_INDEX));
                        }}
                        label={'Go Back'}
                        variant="outlined"
                        fullWidth="false"
                        startIcon={null}
                        color="inherit"
                        size="medium"
                        className={`${classes.buttonWidth} ${classes.previousButton}`}
                        disabled={loading}
                    />
                </Grid>
                <Grid item className={classes.submitButtonContainer}>
                    <Button
                        onClick={onClickSubmit}
                        label={'Set Password'}
                        variant="contained"
                        fullWidth="false"
                        endIcon={null}
                        color="primary"
                        size="medium"
                        className={`${classes.buttonWidth} ${classes.submitButton}`}
                        disabled={loading}
                    />
                </Grid>
            </Grid>
        </>
    );
}

SetPassword.propTypes = {
    defaultData: PropTypes.object,
    onChangeHandler: PropTypes.func,
    schema: PropTypes.object,
    uiSchema: PropTypes.object,
    submitButton: PropTypes.object,
    onSuccessCallback: PropTypes.func,
};
