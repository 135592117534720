/* eslint-disable react/prop-types */
import React from 'react';

// utils
import { get, startCase } from 'lodash';
import {
    getStudentContainerClass,
    getStudentTitleClass,
    STUDENT_STATUS_COLOR,
} from '@/props-constants/student/list-student';

// mui
import { Typography, Checkbox, Tooltip, Box } from '@mui/material';

// comps
import { ColorCard } from 'azent-storybook/dist';

// styles
import { useStyles } from '@/components/modules/Students/ListStudent/styles';

const getStudentStatus = (profileStatus) => {
    const classes = useStyles();
    if (profileStatus && profileStatus.length) {
        const status = get(profileStatus, `${profileStatus.length - 1}.status`, '');
        return (
            <Box my={0.5} mx={0.4}>
                <ColorCard
                    classes={{
                        container: getStudentContainerClass(classes, status),
                        title: getStudentTitleClass(classes, status),
                    }}
                    color={get(STUDENT_STATUS_COLOR, status, '#BED3FF')}
                    title={startCase(status)}
                />
            </Box>
        );
    } else {
        return (
            <Typography sx={{ fontWeight: 400, alignItems: 'center' }} variant="h6">
                -
            </Typography>
        );
    }
};

export const getRawColumns = (selectStudent, have_password) => [
    {
        Header: 'Id',
        Footer: 'Id',
        accessor: (c) => `${get(c, '_id', '-')}`,
        Cell: ({ value }) => (
            <Typography variant="h6" color="primary">
                {value}
            </Typography>
        ),
        showInit: false,
        show: false,
    },
    {
        Header: 'Select Student',
        Footer: 'Select Student',
        accessor: (c) => c.isAssigned,
        Cell: ({ value, row }) => {
            return (
                <Tooltip
                    placement="top"
                    title={!have_password ? 'Set password before assigning a student' : ''}>
                    <div>
                        <Checkbox
                            disabled={!have_password}
                            name="is_selected"
                            checked={value}
                            onClick={(_event) => {
                                _event.stopPropagation();
                                selectStudent(row, _event);
                            }}
                        />
                    </div>
                </Tooltip>
            );
        },
        showInit: true,
        show: true,
    },
    {
        Header: 'Student Id',
        Footer: 'Student Id',
        accessor: (c) => `${get(c, 'crm_uid', '-')}`,
        Cell: ({ value }) => (
            <Typography variant="h6" color="primary">
                {value}
            </Typography>
        ),
        showInit: true,
        show: true,
    },
    {
        Header: 'Student Name',
        Footer: 'Student Name',
        accessor: (c) => `${c.first_name} ${c.last_name}`,
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: true,
        show: true,
    },
    {
        Header: 'Target Countries',
        Footer: 'Target Countries',
        accessor: (c) =>
            get(c, 'desired_profile.interest_countries', []).length
                ? get(c, 'desired_profile.interest_countries', []).join(', ')
                : '-',
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: true,
        show: true,
    },
    {
        Header: 'Status',
        Footer: 'Status',
        accessor: (c) => get(c, 'profile_status', null),
        disableSortBy: true,
        Cell: ({ value }) => getStudentStatus(value),
        showInit: true,
        show: true,
    },
];
