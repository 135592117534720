import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

// mui
import { Box } from '@mui/material';

// comps
import { ShortlistCard } from '@/components/shared/ShortlistCard';
import { ActionBox } from 'azent-storybook/dist';

// utils
import { colorCodes } from '@/utils/courseFinder';
import { acceptShortlist, rejectShortlist, courseApplied } from '@/utils/shortlists';

export default function ShortlistTab({
    profile,
    shortlists,
    isRejected,
    setApplyModalProps,
    onClickShortlistCard,
    isDisableCreateApplication,
    priorityData,
}) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const applyNow = (course) => {
        setApplyModalProps({
            course: {
                ...course,
                ...(course.program_info || {}),
            },
            profile_id: course.profile_id,
            program_id: (course.program_info && course.program_info.id) || '',
            priorityData: priorityData,
        });
    };

    return (
        <Box mb={2}>
            {shortlists.length ? (
                shortlists.map((shortlist, index) => (
                    <Box key={index} my={1.5}>
                        <ShortlistCard
                            key={index}
                            programLink={get(shortlist, 'program_info.program_link')}
                            applicationFee={get(shortlist, 'program_info.application_fee', null)}
                            type={get(shortlist, 'type')}
                            courseName={get(shortlist, 'program_info.name', '')}
                            university={get(shortlist, 'university_info')}
                            programInfo={get(shortlist, 'program_info')}
                            rank={get(shortlist, 'program_info.rank', '-')}
                            tuitionCost={get(shortlist, 'program_info.tuition_fee', '-')}
                            duration={get(shortlist, 'program_info.duration', '-')}
                            intakes={get(shortlist, 'program_info.intakes', [])}
                            intakeDetails={get(shortlist, 'intakes', [])}
                            createdByName={get(shortlist, 'created_by_name', ' ')}
                            loading={loading}
                            color={colorCodes[index % 3]}
                            onApply={() => {
                                applyNow(shortlist);
                            }}
                            onReAccept={acceptShortlist(profile, shortlist, dispatch, setLoading)}
                            onReject={rejectShortlist(profile, shortlist, dispatch, setLoading)}
                            isRejected={isRejected}
                            isApplied={courseApplied(profile, shortlist)}
                            onClickShortlistCard={() => onClickShortlistCard(shortlist, index)}
                            isDisableCreateApplication={isDisableCreateApplication}
                            partner_name={get(profile, 'partner__name', '')}
                            level_and_type={get(shortlist, 'program_info.level_and_type', 'N/A')}
                        />
                    </Box>
                ))
            ) : (
                <ActionBox
                    raised={false}
                    title={{
                        text: 'There Are No Shortlisted Courses!',
                        variant: 'h5',
                    }}
                />
            )}
        </Box>
    );
}
ShortlistTab.propTypes = {
    profile: PropTypes.object.isRequired,
    shortlists: PropTypes.array.isRequired,
    isRejected: PropTypes.bool,
    setApplyModalProps: PropTypes.func,
    onClickShortlistCard: PropTypes.func,
    isDisableCreateApplication: PropTypes.bool,
    priorityData: PropTypes.array,
};

ShortlistTab.defaultProps = {
    isRejected: false,
};
