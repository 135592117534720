import universityInstance from './instances/university-service';
import studentInstance from './instances/student-service';
import { store } from '@/store';
import { deserializeInterestRegions } from '@/utils/deserializers/student-deserializer';

export function fetchGlobalData() {
    return universityInstance.get(`v1/data`);
}

export function getStatePlaces(query) {
    let search = new URLSearchParams(query);
    return universityInstance.get(`/v1/data/places/state?${search.toString()}`);
}

export function getCountryPlaces(query) {
    let search = new URLSearchParams(query);
    return universityInstance.get(`/v1/data/places/country?${search.toString()}`);
}

export function getCityPlaces(query) {
    let search = new URLSearchParams(query);
    return universityInstance.get(`/v1/data/places/city?${search.toString()}`);
}

export function getCityPlacesCustom(query) {
    return universityInstance.get(`/v1/data/places/city?search=${query}`);
}

export function getStatePlacesCustom(query) {
    return universityInstance.get(`/v1/data/places/state?search=${query}`);
}

export function getStats() {
    return studentInstance.get(`/v1/stats`);
}

export function getStatsNew(type, query) {
    let search = new URLSearchParams(query);
    return studentInstance.get(`/v1/stats/${type}?${search.toString()}`);
}

export function getNationality() {
    let nationalities = store.getState().globalData.data.nationality_options;
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                data: nationalities,
            });
        }, 0);
    });
}

export function subCategorySearch(query) {
    let queryCat = query.categories || [];
    let categories = store.getState().globalData.data.categories;
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                data: queryCat
                    .reduce((acc, item) => {
                        return acc.concat(
                            categories[item].map((i) => {
                                return { name: i };
                            }),
                        );
                    }, [])
                    .sort((a, b) => a.name.localeCompare(b.name)),
            });
        }, 0);
    });
}

export const searchCityStateCountry = (query) => {
    const NEW_REGION_API = true;
    const search = new URLSearchParams(query);
    return universityInstance
        .get(`/v1/data/regions?regions=${NEW_REGION_API}&${search.toString()}`)
        .then((response) => ({ data: deserializeInterestRegions(response.data) }))
        .catch(() => ({ data: [] }));
};
