import { get } from "lodash";

export const deserializeServiceCard = (serviceCard, id) => {
  const lodashGet = (key, defaultValue = null) =>
    get(serviceCard, key, defaultValue);

  let data = {};
  if (serviceCard) {
    const partners = lodashGet("partners", []);
    const faq = lodashGet("faq", []);
    const logo = lodashGet("logo.data.attributes", {});
    data = {
      id: id,
      title:serviceCard.name,
      description:serviceCard.sortDescription,
      fullDescription:serviceCard.longDescription,
      logo: {
        src: logo.url,
        alt: logo.alternativeText,
      },
      button: {
        id: lodashGet("button.id", ""),
        label: lodashGet("button.label", ""),
        link: lodashGet("button.link", ""),
        fullWidth:true,
      },
      enquire: {
        id: lodashGet("enquire.id", ""),
        label: lodashGet("enquire.label", ""),
        showModalOnSuccess: lodashGet("enquire.showModalOnSuccess", ""),
        onClick: lodashGet("enquire.onClick", ""),
      },
      partners:
        partners &&
        partners.map((partner) => {
          const logo = get(partner,"logo.data.attributes", {});
          return {
            name: get(partner, "name", ""),
            description: get(partner, "description", ""),
            logo: {
              src: get(logo, "url", ""),
              alt: get(logo, "alternativeText", ""),
            },
          };
        }),
      faq:
        faq &&
        faq.map((Faq) => ({
          question: get(Faq, "question", ""),
          answer: get(Faq, "answer", ""),
        })),
    };
  }
  return data;
};

export const deserializeServiceCardList = (serviceCards = []) => {
  return serviceCards.map(({ attributes, id }) =>
    deserializeServiceCard(attributes, id)
  );
};
