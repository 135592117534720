import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// mui
import { Box } from '@mui/material';

//utils
import { toastMessages } from '@/utils/toastMessages';
import { deserializeSubagentInfo } from '@/utils/deserializers/subagentInfo-deserializer';

//actions
import { setToast } from '@/store/slices/toastSlice';
import { addSubAgentToStore, addAssignedStudentsToStore } from '@/store/slices/subAgentSlice';

//api
import { getSubAgentProfile } from '@/api/sub-agent';
import { getSubAgentProfilesAssigned } from '@/api';

//prop-constants
import { getSubAgentProfileProps } from '@/props-constants/sub-agent/sub-agent-profile';

//comps
import Loader from '@/components/shared/Loader';
import SubAgentDetail from '../CreateSubAgent/partials/SubAgentDetail';
import SubAgentHeader from '../CreateSubAgent/partials/SubAgentHeader';

export default function UpdateSubAgent() {
    const dispatch = useDispatch();

    const search = useSelector((state) => state.router.location.search);
    const user = useSelector((state) => state.userData);
    const params = new URLSearchParams(search);
    const userId = params.get('id');

    const [subAgentData, setSubAgentData] = useState();
    const [studentsDataLoading, setStudentsDataLoading] = useState(true);

    const { subAgentHeaderProps, subAgentDetailProps } = useMemo(() => {
        return getSubAgentProfileProps(subAgentData, dispatch, user.userData);
    }, [subAgentData]);

    const updateSubAgentUserStatusData = (respData) => {
        setSubAgentData(respData.data.User);
    };

    React.useEffect(() => {
        if (userId) {
            getSubAgentProfile(userId)
                .then((res) => {
                    setSubAgentData(deserializeSubagentInfo(res.data.data.user_info));
                    dispatch(addSubAgentToStore(deserializeSubagentInfo(res.data.data.user_info)));
                })
                .catch(() => {
                    dispatch(setToast(toastMessages.backendIssue));
                });

            getSubAgentProfilesAssigned(userId)
                .then((res) => {
                    if (res.data.length > 0) {
                        let studentList = res.data.map(({ _id, first_name, last_name }) => {
                            return { _id, first_name, last_name };
                        });
                        dispatch(addAssignedStudentsToStore(studentList));
                    } else {
                        dispatch(addAssignedStudentsToStore([]));
                    }
                })
                .catch(() => {
                    dispatch(setToast(toastMessages.backendIssue));
                })
                .finally(() => {
                    setStudentsDataLoading(false);
                });
        }
    }, [userId]);

    const partnerId = useMemo(() => {
        return user.userData.id;
    }, [user]);

    return !subAgentData ? (
        <Loader />
    ) : (
        <Box>
            <SubAgentHeader
                {...subAgentHeaderProps}
                subAgentData={subAgentData}
                updateSubAgentUserStatusData={updateSubAgentUserStatusData}
                partnerId={partnerId}
            />
            {subAgentData && !studentsDataLoading && (
                <SubAgentDetail {...subAgentDetailProps} profileData={subAgentData} />
            )}
        </Box>
    );
}

UpdateSubAgent.propTypes = {};

UpdateSubAgent.defaultProps = {};
