import { toNumber } from 'lodash';

export const getFiltersFromSearchParams = (search) => {
    const filters = {};
    const searchObject = Object.fromEntries(search);

    if (searchObject.intake_months) filters.intake_months = searchObject.intake_months.split(',');
    if (searchObject.last_modified_by)
        filters.last_modified_by = searchObject.last_modified_by.split(',');
    if (searchObject.intake_years)
        filters.intake_years = searchObject.intake_years.split(',').map((d) => toNumber(d));
    if (searchObject.countries) filters.countries = searchObject.countries.split(',');
    if (searchObject.application_statuses)
        filters.application_statuses = searchObject.application_statuses.split(',');
    if (searchObject.application_sub_statuses)
        filters.application_sub_statuses = searchObject.application_sub_statuses;
    if (searchObject.application_source)
        filters.application_source = searchObject.application_source;
    if (searchObject.universities) {
        const universitiesList = [];
        searchObject.universities.split(',').forEach((id) => {
            universitiesList.push({ _id: id });
        });
        filters.universities = universitiesList;
    }
    if (searchObject.profile_id) {
        const profileList = [];
        searchObject.profile_id.split(',').forEach((id) => {
            profileList.push({ _id: id });
        });
        filters.profile_id = profileList;
    }
    if (searchObject.program_id) {
        const program_list = [];
        searchObject.program_id.split(',').forEach((id) => {
            program_list.push({ _id: id });
        });
        filters.program_id = program_list;
    }
    if (searchObject.partner) {
        const partners = [];
        searchObject.partner.split(',').forEach((id) => {
            partners.push({ id });
        });
        filters.partner = partners;
    }
    if (searchObject.psa_id) {
        const psa = [];
        searchObject.psa_id.split(',').forEach((id) => {
            psa.push({ id });
        });
        filters.psa = psa;
    }
    if (searchObject.owner) {
        const owners = [];
        searchObject.owner.split(',').forEach((id) => {
            owners.push({ id });
        });
        filters.owner = owners;
    }
    if (searchObject.sub_agent) {
        const sub_agents = [];
        searchObject.sub_agent.split(',').forEach((id) => {
            sub_agents.push({ id });
        });
        filters.sub_agent = sub_agents;
    }

    if (searchObject.created_date_from && searchObject.created_date_to)
        filters.created_date = {
            from: searchObject.created_date_from,
            to: searchObject.created_date_to,
        };

    if (searchObject.followup_date_from && searchObject.followup_date_to)
        filters.followup_date = {
            from: searchObject.followup_date_from,
            to: searchObject.followup_date_to,
        };

    return filters;
};

export const convertFilterDataToUrlFormat = (filterData) => {
    const filters = {};

    if (filterData.intake_months) filters.intake_months = filterData.intake_months;
    if (filterData.last_modified_by) filters.last_modified_by = filterData.last_modified_by;
    if (filterData.intake_years) filters.intake_years = filterData.intake_years;
    if (filterData.countries) filters.countries = filterData.countries;
    if (filterData.application_statuses)
        filters.application_statuses = filterData.application_statuses;
    if (filterData.application_source) filters.application_source = filterData.application_source;
    if (filterData.application_sub_statuses)
        filters.application_sub_statuses = filterData.application_sub_statuses;
    if (filterData.universities) {
        const universitiesList = [];
        filterData.universities.forEach((item) => {
            universitiesList.push({ _id: item._id });
        });
        filters.universities = universitiesList;
    }
    if (filterData.profile_id) {
        const profileList = [];
        filterData.profile_id.forEach((item) => {
            profileList.push({ _id: item._id });
        });
        filters.profile_id = profileList;
    }
    if (filterData.program_id) {
        const program_list = [];
        filterData.program_id.forEach((item) => {
            program_list.push({ _id: item._id });
        });
        filters.program_id = program_list;
    }
    if (filterData.partner) {
        const partners = [];
        filterData.partner.forEach((item) => {
            partners.push({ id: item.id });
        });
        filters.partner = partners;
    }
    if (filterData.psa) {
        const psa = [];
        filterData.psa.forEach((item) => {
            psa.push({ id: item.id });
        });
        filters.psa = psa;
    }
    if (filterData.owner) {
        const owners = [];
        filterData.owner.forEach((item) => {
            owners.push({ id: item.id });
        });
        filters.owner = owners;
    }
    if (filterData.sub_agent) {
        const sub_agents = [];
        filterData.sub_agent.forEach((item) => {
            sub_agents.push({ id: item.id });
        });
        filters.sub_agent = sub_agents;
    }

    if (filterData.created_date) {
        filters.created_date = filterData.created_date;
    }

    if (filterData.followup_date) {
        filters.followup_date = filterData.followup_date;
    }
    if (filterData.preference) filters.preference = filterData.preference;

    return filters;
};

export const getApplicationListQueries = function (
    filtersData,
    page,
    per_page,
    searchText,
    pageChoice,
) {
    const query = [
        { key: 'page', value: pageChoice || page },
        { key: 'per_page', value: per_page || '10' },
        { key: 'q', value: searchText || '' },
    ];

    if (!filtersData) return query;

    if (filtersData.intake_months && filtersData.intake_months.length)
        query.push({
            key: 'intake_months',
            value: filtersData.intake_months ? filtersData.intake_months.join(',') : '',
        });

    if (filtersData.last_modified_by && filtersData.last_modified_by.length)
        query.push({
            key: 'last_modified_by',
            value: filtersData.last_modified_by ? filtersData.last_modified_by.join(',') : '',
        });
    if (filtersData.intake_years && filtersData.intake_years.length)
        query.push({
            key: 'intake_years',
            value: filtersData.intake_years ? filtersData.intake_years.join(',') : '',
        });
    if (filtersData.universities && filtersData.universities.length)
        query.push({
            key: 'universities',
            value: filtersData.universities
                ? filtersData.universities.map((item) => item._id).join(',')
                : '',
        });
    if (filtersData.profile_id && filtersData.profile_id.length)
        query.push({
            key: 'profile_id',
            value: filtersData.profile_id
                ? filtersData.profile_id.map((item) => item._id).join(',')
                : '',
        });
    if (filtersData.program_id && filtersData.program_id.length)
        query.push({
            key: 'program_id',
            value: filtersData.program_id
                ? filtersData.program_id.map((item) => item._id).join(',')
                : '',
        });
    if (filtersData.countries && filtersData.countries.length)
        query.push({
            key: 'countries',
            value: filtersData.countries ? filtersData.countries.join(',') : '',
        });
    if (filtersData.application_statuses && filtersData.application_statuses.length)
        query.push({
            key: 'application_statuses',
            value: filtersData.application_statuses
                ? filtersData.application_statuses.join(',')
                : '',
        });
    /* NOTE: Phase 2 implementation
     if (filtersData.created_at_before)
        query.push({
            key: 'created_at_before',
            value: filtersData.created_at_before
                ? filtersData.created_at_before
                : '',
        });
    if (filtersData.created_at_after)
        query.push({
            key: 'created_at_after',
            value: filtersData.created_at_after
                ? filtersData.created_at_after
                : '',
        }); */
    if (filtersData.partner && filtersData.partner.length)
        query.push({
            key: 'partner',
            value: filtersData.partner ? filtersData.partner.map((item) => item.id).join(',') : '',
        });
    if (filtersData.sub_agent && filtersData.sub_agent.length)
        query.push({
            key: 'sub_agent',
            value: filtersData.sub_agent
                ? filtersData.sub_agent.map((item) => item.id).join(',')
                : '',
        });
    if (filtersData.psa && filtersData.psa.length)
        query.push({
            key: 'psa_id',
            value: filtersData.psa ? filtersData.psa.map((item) => item.id).join(',') : '',
        });
    if (filtersData.owner && filtersData.owner.length)
        query.push({
            key: 'owner',
            value: filtersData.owner ? filtersData.owner.map((item) => item.id).join(',') : '',
        });
    if (filtersData.created_date) {
        if (filtersData.created_date.from)
            query.push({
                key: 'created_date_from',
                value: filtersData.created_date.from,
            });
        if (filtersData.created_date.to)
            query.push({
                key: 'created_date_to',
                value: filtersData.created_date.to,
            });
    }
    if (filtersData.application_sub_statuses)
        query.push({
            key: 'application_sub_statuses',
            value: filtersData.application_sub_statuses,
        });
    if (filtersData.application_source)
        query.push({ key: 'application_source', value: filtersData.application_source });

    if (filtersData.followup_date) {
        if (filtersData.followup_date.from)
            query.push({
                key: 'followup_date_from',
                value: filtersData.followup_date.from,
            });
        if (filtersData.followup_date.to)
            query.push({
                key: 'followup_date_to',
                value: filtersData.followup_date.to,
            });
    }
    if (filtersData.preference && filtersData.preference.length)
        query.push({
            key: 'preference',
            value: filtersData.preference ? filtersData.preference.join(',') : '',
        });

    return query;
};
