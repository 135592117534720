import { isSubAgent } from '@/rbac';

const largeScreenUiSchema = (config, isReadOnly) => {
    const isDisabled = isReadOnly || isSubAgent();
    const uiPartnerSchema = {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/first_name',
                        label: 'First Name*',
                        rule: {
                            effect: isDisabled ? 'DISABLE' : 'ENABLE',
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/last_name',
                        label: 'Last Name*',
                        rule: {
                            effect: isDisabled ? 'DISABLE' : 'ENABLE',
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/email',
                        label: 'Email ID*',
                        rule: {
                            effect: isDisabled ? 'DISABLE' : 'ENABLE',
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/mobile',
                        label: 'Mobile Number*',
                        rule: {
                            effect: isDisabled ? 'DISABLE' : 'ENABLE',
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                        options: {
                            type: 'number',
                        },
                    },
                    {
                        type: 'VerticalLayout',
                        elements: [
                            {
                                type: 'Control',
                                scope: '#/properties/whatsapp_number',
                                label: 'WhatsApp (Optional)',
                                options: {
                                    type: 'number',
                                },
                                rule: {
                                    effect:
                                        config.isWhatsappSame || isDisabled ? 'DISABLE' : 'ENABLE',
                                    condition: {
                                        scope: '',
                                        schema: {},
                                    },
                                },
                            },
                            {
                                type: 'Control',
                                scope: '#/properties/is_whatsapp_same',
                                label: 'Same as mobile',
                                options: {
                                    component: 'switch',
                                    labelPlacement: 'start',
                                    linkedToOtherField: true,
                                },
                                rule: {
                                    effect: isDisabled ? 'DISABLE' : 'ENABLE',
                                    condition: {
                                        scope: '#',
                                        schema: {},
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    };

    return uiPartnerSchema;
};

const smallScreenUiSchema = {};

export default function (config = {}, isReadOnly) {
    return {
        smallScreenUiSchema: smallScreenUiSchema,
        largeScreenUiSchema: largeScreenUiSchema(config, isReadOnly),
    };
}
