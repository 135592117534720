import { getAjvInstance } from 'azent-storybook/dist';

export default function validateJsonSchema(globalData, data, schema) {
    let ajv = getAjvInstance();
    let validate = ajv.compile(schema(globalData));
    let valid = validate(data);
    return !valid && validate.errors;
}

export function validationJsonSchema(data, schema) {
    let ajv = getAjvInstance();
    let validate = ajv.compile(schema);
    let valid = validate(data);
    return !valid && validate.errors;
}
