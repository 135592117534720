import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

//mui
import { Grid } from '@mui/material';

//utils
import { getProfileSplit } from '@/utils/sub-agent';
import { toastMessages } from '@/utils/toastMessages';
import routePaths from '@/utils/route-paths';

//actions
import { setToast } from '@/store/slices/toastSlice';
import { goToRoute } from '@/store/actions/navigationActions';
import { isEmpty } from 'lodash';

// comps
import {
    getTileIndexFromRoute,
    getMainContentPropsForSubAgentFromIndex,
    getTileContentPropsForSubAgentFromIndex,
} from '@/props-constants/sub-agent/sub-agent-profile';
import Tiles from '@/components/modules/Students/StudentProfile/partials/StudentDetails/partials/Tiles';

//props constants
import { getMyProfileProps } from '@/props-constants/sub-agent-schema';

export default function SubAgentDetail({ tiles, profileData }) {
    const dispatch = useDispatch();

    const search = useSelector((state) => state.router.location.search);
    const location = useSelector((state) => state.router.location);
    const params = new URLSearchParams(search);
    const { have_password } = useSelector((state) => state.subAgentData.data);
    const sub_agent = useSelector((state) => state.subAgentData.data);

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [dirtyFormData, setDirtyFormData] = useState(getProfileSplit(profileData, {}));
    const [submittedData, setSubmittedData] = useState(null);

    const currentTileIndex = getTileIndexFromRoute(params.get('step'));
    const stepType = location.pathname.split(`/sub-agent/`)[1];

    const isPersonalInfoReadOnly = useMemo(() => {
        if (stepType == 'create') return true;
        else return false;
    }, [location && location.pathname]);

    const checkTileDisabled = (index) => {
        if (index == 0) {
            return false;
        } else if (index == 1) {
            if (!isEmpty(sub_agent)) return false;
            else return true;
        } else if (index == 2) {
            if (!isEmpty(sub_agent) && sub_agent.have_password) return false;
            else return true;
        }
    };

    const onChangeNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const onChangeConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    let passConfig = {
        showNewPassword,
        showConfirmPassword,
        onChangeConfirmPasswordVisibility,
        onChangeNewPasswordVisibility,
    };

    const setCurrentTileIndex = (index) => {
        if (index === 0) {
            if (dirtyFormData && dirtyFormData[index] && dirtyFormData[index].id) {
                dispatch(
                    goToRoute(
                        routePaths.SUB_AGENT_UPDATE,
                        {},
                        { step: 'personal-detail', id: dirtyFormData[index].id },
                    ),
                );
            } else {
                dispatch(goToRoute(routePaths.SUB_AGENT_CREATE, {}, { step: 'personal-detail' }));
            }
        }
        if (index === 1) {
            if (dirtyFormData && dirtyFormData[index] && dirtyFormData[index].id) {
                dispatch(
                    goToRoute(
                        routePaths.SUB_AGENT_UPDATE,
                        {},
                        { step: 'set-password', id: dirtyFormData[index].id },
                    ),
                );
            } else {
                dispatch(goToRoute(routePaths.SUB_AGENT_CREATE, {}, { step: 'set-password' }));
            }
        }
        if (index === 2) {
            if (dirtyFormData && dirtyFormData[index] && dirtyFormData[index].id) {
                dispatch(
                    goToRoute(
                        routePaths.SUB_AGENT_UPDATE,
                        {},
                        {
                            step: 'student-list',
                            id: dirtyFormData[index].id,
                            page: '1',
                            per_page: '10',
                            q: '',
                        },
                    ),
                );
            } else {
                dispatch(
                    goToRoute(
                        routePaths.SUB_AGENT_CREATE,
                        {},
                        { step: 'student-list', page: '1', per_page: '10', q: '' },
                    ),
                );
            }
        }
    };

    const onChangeHandler = (data) => {
        setDirtyFormData(data);
    };

    //create student form validate
    function validateForm(formData) {
        if (formData && formData.step == 1) {
            if (formData.new_password && formData.confirm_password) {
                if (formData.new_password !== formData.confirm_password) {
                    dispatch(setToast(toastMessages.confirmPasswordErrorMsg));
                    return false;
                } else return true;
            }
        } else return true;
    }

    const profileProps = useMemo(() => {
        let data = null;
        const isReadOnly = !isPersonalInfoReadOnly;
        data = getMyProfileProps(
            dirtyFormData,
            profileData,
            dirtyFormData[0].id,
            validateForm,
            passConfig,
            isReadOnly,
            have_password,
        );
        setSubmittedData(data.defaultData);
        return data;
    }, [
        profileData,
        showNewPassword,
        showConfirmPassword,
        dirtyFormData && dirtyFormData[0] && dirtyFormData[0].is_whatsapp_same,
        isPersonalInfoReadOnly,
        have_password,
    ]);

    const allProps = useMemo(() => {
        let allProps = {};
        if (profileProps) {
            allProps = {
                submittedData,
                onChangeHandler,
                setDirtyFormData,
                currentTileIndex,
                defaultData: profileProps.defaultData,
                formsData: profileProps.formsData,
                setSubmittedData,
            };
        }
        return allProps;
    }, [profileProps, submittedData]);

    const mainContentProps = useMemo(() => {
        return getMainContentPropsForSubAgentFromIndex(currentTileIndex, allProps);
    }, [currentTileIndex, allProps]);

    const tileContentProps = useMemo(
        () => getTileContentPropsForSubAgentFromIndex(currentTileIndex, allProps),
        [currentTileIndex, allProps],
    );

    return (
        <Grid container my={3} px={7}>
            <Grid item xs={3}>
                <Tiles
                    tiles={tiles}
                    currentTileProps={tileContentProps}
                    setCurrentTile={setCurrentTileIndex}
                    isTileDisabled={checkTileDisabled}
                />
            </Grid>
            <Grid item p={0.2} xs={9}>
                {tiles[currentTileIndex].mainContent(mainContentProps)}
            </Grid>
        </Grid>
    );
}

SubAgentDetail.propTypes = {
    tiles: PropTypes.array.isRequired,
    path: PropTypes.string,
    profileData: PropTypes.object,
};
