import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { has } from 'lodash';

//utils
import routePaths, { studentPaths } from '@/utils/route-paths';
import {
    applicationStatus,
    getLabelForApplicationStatus,
} from '@/props-constants/application/application-status';

// actions
import { goToRoute } from '@/store/actions/navigationActions';

// mui
import { List, ListItem, ListItemText, Skeleton } from '@mui/material';

//component
import AccordianDetails from './partials/accordianDetails';

export default function ApplicationStepper({ applications, applicationCount }) {
    const dispatch = useDispatch();
    const location = useSelector((state) => state.router.location);
    const [applicationsData, setApplicationsData] = useState({});
    const [sortedApplicationsData, setSortedApplicationsData] = useState(null);
    const [openPanelIndex, setOpenPanelIndex] = useState(0);
    const activeAppId = location.pathname.split('/applications/').pop();
    const loaderData = [1, 2];

    useEffect(() => {
        let tempObj = {};
        applications.map((item) => {
            has(tempObj, item?.application_status?.current_status)
                ? tempObj[item?.application_status?.current_status].push(item)
                : (tempObj[item?.application_status?.current_status] = [item]);
        });
        setApplicationsData(tempObj);
    }, [applications]);

    // FIXME: need to fix the order using array instead of object
    useEffect(() => {
        let tempObj = {};
        applicationStatus.map((statusLabel) => {
            if (applicationsData && has(applicationsData, statusLabel))
                tempObj[statusLabel] = applicationsData[statusLabel];
        });
        setSortedApplicationsData(tempObj);
    }, [applicationsData]);

    const onStepperClick = (event, application) => {
        event.stopPropagation();
        dispatch(
            goToRoute(routePaths.STUDENT_PROFILE_VIEW, {
                id: application.profile_id,
                aid: application._id,
                path: studentPaths.APPLICATION,
            }),
        );
    };

    const onClickPanel = (panelIndex) => {
        setOpenPanelIndex(panelIndex);
    };

    if (applicationCount > 0 && !sortedApplicationsData)
        return (
            <List dense>
                {loaderData.map((index) => (
                    <ListItem key={index} sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        <ListItemText>
                            <Skeleton animation="wave" width={'60%'} variant="text" />
                            <Skeleton animation="wave" width={'100%'} variant="text" />
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        );

    return (
        applicationCount > 0 && (
            <>
                {sortedApplicationsData &&
                    Object.entries(sortedApplicationsData).map(
                        ([label, applicationList], index) => (
                            <AccordianDetails
                                label={getLabelForApplicationStatus(label)}
                                applicationList={applicationList}
                                activeAppId={activeAppId}
                                onStepperClick={onStepperClick}
                                key={index}
                                panelIndex={index}
                                openPanelIndex={openPanelIndex}
                                onClickPanel={onClickPanel}
                            />
                        ),
                    )}
            </>
        )
    );
}

ApplicationStepper.propTypes = {
    applications: PropTypes.array,
    applicationCount: PropTypes.number,
};
