import React from 'react';
import PropTypes from 'prop-types';
// comps
import ServiceSummary from '../ServiceSummary';
import { useStyles } from './style';

export default function StudentService({ services, serviceId, studentId }) {
    const classes = useStyles();
    
    if (serviceId) {
        return <ServiceSummary serviceId={serviceId} services={services} studentId={studentId} />;
    } else {
        return <div className={classes.unsubscribed}>There are no services to display</div>;
    }
}

StudentService.propTypes = {
    services: PropTypes.array,
    serviceId: PropTypes.string,
    studentId: PropTypes.string,
};
