import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// mui
import { Box, Grid, Typography, Divider, ButtonBase } from '@mui/material';

// styles
import { useStyles } from './style';

// utils
import { get, snakeCase } from 'lodash';
import { deserializeStudent } from '@/utils/deserializers/student-deserializer';
import {
    getHistoryProps,
    getStudentContainerClass,
    getStudentTitleClass,
} from '@/props-constants/student/list-student/index';
import {
    getTargetCountries,
    getIntakeFromProfile,
    getProfileStatusAndSubStatus,
    getReferenceDocs,
    getCommentDocs,
} from '@/props-constants/student/student-profile';
import { toastMessages } from '@/utils/toastMessages';
import { isB2bAdmin, isPartner, isSubAgent } from '@/rbac';

import { getCommentsWithFormattedFileName } from '@/utils/comments';

// comps
import { Tabs, History, getIcon, ColorCard } from 'azent-storybook/dist';
import StudentComment from '@/components/shared/Comment';
import DocumentsTab from './DocumentsTab';
import ProfileStatus from './ProfileStatus';

// schema
import { schema as commentsSchema } from '@/props-constants/student-schema/student-comments-schemas/schema';
import { getCommentsUiSchema } from '@/props-constants/student-schema/student-comments-schemas/ui-schema';

// constants
import constants from '@/constants';

// api
import { signRequest, saveProfileComments } from '@/api';

// actions
import { addStudentToStore } from '@/store/slices/studentSlice';
import { setToast } from '@/store/slices/toastSlice';

export default function Summary({ profileData }) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const [tabValue, setTabValue] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false);
    const [addAttachment, setAddAttachment] = useState(false);
    const [isPrivateComment, setPrivateComment] = useState(false);

    const { counsellor__name, counsellor__email, counsellor__mobile } = profileData;
    const studentFullName = `${snakeCase(profileData.first_name)}_${snakeCase(
        profileData.last_name,
    )}`;

    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const onCommentSave = (formData) => {
        let newFormData = getCommentsWithFormattedFileName(
            formData,
            profileData.crm_uid,
            studentFullName,
        );
        newFormData = { ...newFormData, private: isPrivateComment ? true : false };
        if (newFormData.comment) return saveProfileComments(profileData.id, newFormData);
        else {
            dispatch(setToast(toastMessages.backendIssue));
        }
    };

    const onCommentSaveSuccess = (data, setData) => {
        setAddAttachment(false);
        setData({});
        setPrivateComment(false);
        dispatch(addStudentToStore(deserializeStudent(data)));
    };

    const fileUploadConfig = {
        s3Postfix: `${constants.envName}/${profileData.id}`,
        awsConfig: constants.awsConfig,
        selfAuthApiCall: signRequest,
    };

    return (
        <>
            {profileData && (
                <ProfileStatus
                    profile={profileData}
                    open={isModalOpen}
                    handleClose={handleModalClose}
                    uploadConfig={fileUploadConfig}
                />
            )}
            <Box className={classes.container}>
                {/* NOTE: ASKED TO REMOVE */}
                {/* <Typography
                    sx={{ mb: 1.5 }}
                    color="primary"
                    variant="subtitle1">
                    Student id - {get(profileData, 'id', '-')}
                </Typography> */}
                <Grid container wrap={'nowrap'} className={classes.studentSummaryContainer}>
                    <Grid item container spacing={2} xs={9}>
                        <Grid item container xs={7}>
                            <Grid item xs={6}>
                                <Typography className={classes.disabledText}>Created on</Typography>
                                <Typography variant="h6">
                                    {moment
                                        .utc(get(profileData, 'created_at', ''))
                                        .format('DD/MM/YYYY')}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.disabledText}>Intake</Typography>
                                <Typography variant="h6">
                                    {getIntakeFromProfile(profileData)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.disabledText}>
                                    Target Country
                                </Typography>
                                <Typography variant="h6">
                                    {getTargetCountries(profileData)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.disabledText}>
                                    Total Applications
                                </Typography>
                                <Typography variant="h6">
                                    {get(profileData, 'applications').length}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="h6" color="text.disabled">
                                Counsellor
                            </Typography>
                            <Box display={'flex'}>
                                <span style={{ verticalAlign: 'middle' }}>
                                    {getIcon('person', 'inherit', 'small', classes.personIcon)}
                                </span>
                                <Typography variant="h6" className={classes.textFlowWrap}>
                                    {counsellor__name ? counsellor__name : '-'}
                                </Typography>
                            </Box>
                            <Typography variant="h6">
                                <span style={{ verticalAlign: 'middle' }}>
                                    {getIcon('phone', 'inherit', 'small', classes.icon)}{' '}
                                </span>
                                {counsellor__mobile ? counsellor__mobile : '-'}
                            </Typography>

                            <Box display={'flex'}>
                                <span style={{ verticalAlign: 'middle' }}>
                                    {getIcon('mail', 'inherit', 'small', classes.icon)}
                                </span>
                                <Typography variant="h6" className={classes.textFlowWrap} pl={0.5}>
                                    {counsellor__email ? counsellor__email : '-'}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item container pl={2} xs={3}>
                        <Grid item xs={12} className={classes.centerElement}>
                            <ButtonBase
                                className={classes.cardAction}
                                disabled={!isB2bAdmin()}
                                onClick={handleModalOpen}>
                                <ColorCard
                                    title={getProfileStatusAndSubStatus(profileData)}
                                    classes={{
                                        container: getStudentContainerClass(
                                            classes,
                                            getProfileStatusAndSubStatus(profileData),
                                        ),
                                        title: getStudentTitleClass(
                                            classes,
                                            getProfileStatusAndSubStatus(profileData),
                                        ),
                                    }}
                                    endIcon={
                                        isB2bAdmin() &&
                                        getIcon(
                                            'expand-more',
                                            'inherit',
                                            'small',
                                            classes.exploreMoreIcon,
                                        )
                                    }></ColorCard>
                            </ButtonBase>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider sx={{ mt: 2 }} variant="middle" />

                <Grid container>
                    <Grid item xs={9}>
                        <Tabs
                            value={tabValue}
                            setValue={setTabValue}
                            tabsProps={{
                                centered: false,
                                variant: 'standard',
                            }}
                            classes={{
                                tab: classes.customTab,
                                contentContainer: classes.customContentContainer,
                            }}
                            tabList={
                                (profileData.comments &&
                                    getCommentDocs(profileData.comments).length > 0) ||
                                (profileData.profile_status &&
                                    getReferenceDocs(profileData.profile_status).length > 0)
                                    ? [
                                          {
                                              label: 'Comments',
                                              content: () => (
                                                  <StudentComment
                                                      addAttachment={addAttachment}
                                                      setAddAttachment={setAddAttachment}
                                                      enableAttachement={false}
                                                      comments={profileData.comments}
                                                      addComment={{
                                                          schema: commentsSchema,
                                                          commentsUiSchema: getCommentsUiSchema({
                                                              addAttachment: addAttachment,
                                                          }),
                                                          defaultData: {},
                                                          uploadConfig: fileUploadConfig,
                                                          submitButton: {
                                                              label: 'Save Comment',
                                                              color: 'success',
                                                              onClick: onCommentSave,
                                                              fullWidth: false,
                                                              className: classes.saveCommentButton,
                                                          },
                                                          onSuccessCallback: onCommentSaveSuccess,
                                                          setPrivateComment: setPrivateComment,
                                                          isPrivateComment: isPrivateComment,
                                                      }}
                                                      enablePrivateComment={
                                                          !isPartner() && !isSubAgent()
                                                      }
                                                  />
                                              ),
                                          },

                                          {
                                              label: 'Documents',
                                              content: () => (
                                                  <DocumentsTab
                                                      profileStatus={profileData.profile_status}
                                                      comments={profileData.comments}
                                                  />
                                              ),
                                          },
                                      ]
                                    : [
                                          {
                                              label: 'Comments',
                                              content: () => (
                                                  <StudentComment
                                                      addAttachment={addAttachment}
                                                      setAddAttachment={setAddAttachment}
                                                      comments={profileData.comments}
                                                      enableAttachement={false}
                                                      addComment={{
                                                          schema: commentsSchema,
                                                          commentsUiSchema: getCommentsUiSchema({
                                                              addAttachment: addAttachment,
                                                          }),
                                                          defaultData: {},
                                                          uploadConfig: fileUploadConfig,
                                                          submitButton: {
                                                              label: 'Save Comment',
                                                              color: 'success',
                                                              onClick: onCommentSave,
                                                              fullWidth: false,
                                                              className: classes.saveCommentButton,
                                                          },
                                                          onSuccessCallback: onCommentSaveSuccess,
                                                          setPrivateComment: setPrivateComment,
                                                          isPrivateComment: isPrivateComment,
                                                      }}
                                                      enablePrivateComment={
                                                          !isPartner() && !isSubAgent()
                                                      }
                                                  />
                                              ),
                                          },
                                      ]
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h6" className={classes.historyTitle}>
                            Student History
                        </Typography>
                        <Box className={classes.historyContainer}>
                            <History
                                styles={classes.timelineDotColor}
                                history={getHistoryProps(profileData.profile_status)}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

Summary.propTypes = {
    profileData: PropTypes.object,
};
