import React from 'react';

import { Typography, Box } from '@mui/material';
import { getIcon } from 'azent-storybook/dist';
import { useStyles } from './style';

let NotSupportedView = function () {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            {getIcon('notSupportedView', 'inherit', 'large')}
            <Box marginTop={1}>
                <Typography variant="h6">We do not support this screen size yet!</Typography>
            </Box>
        </Box>
    );
};

export default NotSupportedView;
