import moment from 'moment';

const DIFF_DAYS = 4;
export const deserializeAncillaryService = (serviceList) => {
    const uniqueIdList = serviceList.map((item) => item.cms_service_id);
    const data = uniqueIdList.reduce((acc, id) => {
        const uniqueServiceList = serviceList.filter((service) => service.cms_service_id === id);
        const sortedArray = uniqueServiceList.sort(
            (a, b) => moment(a.date).format('YYYYMMDD') - moment(b.date).format('YYYYMMDD'),
        );

        if (sortedArray.length) {
            const data = { ...sortedArray[sortedArray.length - 1] };
            data.isEnquireEnabled = moment().diff(moment(data.created_at), 'days') > DIFF_DAYS;

            acc[id] = data;
        }
        return acc;
    }, {});
    return data;
};
