import { months as defaultMonths } from '../../create-application';
import moment from 'moment';

export default function getApplicationFormSchema(months, intakeMonth, priorityData) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    let nextFourYears = [
        currentYear,
        currentYear + 1,
        currentYear + 2,
        currentYear + 3,
        currentYear + 4,
    ];

    let programMonths = defaultMonths;
    if (months && months.length > 0) {
        programMonths = months;
    }

    if (intakeMonth && moment().month(intakeMonth).format('M') <= currentMonth)
        nextFourYears = [currentYear + 1, currentYear + 2, currentYear + 3, currentYear + 4];

    return {
        type: 'object',
        properties: {
            profile: {
                type: 'object',
                properties: {
                    _id: {
                        type: 'string',
                    },
                    name: {
                        type: 'string',
                    },
                },
                required: ['_id'],
            },
            university: {
                type: 'object',
                properties: {
                    _id: {
                        type: 'string',
                    },
                    name: {
                        type: 'string',
                    },
                },
            },
            program: {
                type: 'object',
                properties: {
                    _id: {
                        type: 'string',
                    },
                    name: {
                        type: 'string',
                    },
                },
                required: ['_id'],
                default: null,
            },
            intake: {
                type: 'object',
                properties: {
                    month: {
                        type: 'string',
                        enum: programMonths,
                    },
                    year: {
                        type: 'number',
                        enum: nextFourYears,
                    },
                },
                required: ['month', 'year'],
            },
            required_docs: {
                type: 'string',
            },
            program_fees: {
                type: 'string',
            },
            intake_type: {
                type: 'string',
            },
            preference: {
                type: 'string',
                enum: priorityData && priorityData.length ? priorityData : [''],
            },
        },
        required: ['profile', 'program', 'intake', 'preference'],
    };
}
