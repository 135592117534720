// Constants
import constants from '@/constants';

const checkLocalStorageEnabled = () => {
    if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
        try {
            window.localStorage.setItem('local_storage_test', 'yes');
            if (window.localStorage.getItem('local_storage_test') === 'yes') {
                window.localStorage.removeItem('local_storage_test');
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    } else {
        return false;
    }
};

const setAuthTokenInLocalStorage = (tokenValue) => {
    try {
        window.localStorage.setItem(constants.cookieDetails.name, tokenValue);
        return true;
    } catch {
        return false;
    }
};

const deleteAuthTokenFromLocalStorage = () => {
    try {
        window.localStorage.removeItem(constants.cookieDetails.name);
        return true;
    } catch {
        return false;
    }
};

const getAuthFromLocalStorage = () => {
    try {
        return window.localStorage.getItem(constants.cookieDetails.name);
    } catch {
        return null;
    }
};

export default {
    checkLocalStorageEnabled,
    setAuthTokenInLocalStorage,
    getAuthFromLocalStorage,
    deleteAuthTokenFromLocalStorage,
};
