import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    cModalTitleContainer: {
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        padding: theme.spacing(2, 1.5),
    },
    cModalTitle: {
        color: 'white',
        fontSize: '20px',
    },
    modalTitleIcon: {
        borderRadius: '50%',
        backgroundColor: 'white',
        verticalAlign: 'middle',
        marginRight: theme.spacing(1.5),
    },
    notesList: {
        borderRadius: '15px',
        background: '#F0F3F5',
        maxHeight: '175px',
        overflow: 'scroll',
        '&::-webkit-scrollbar': {
            width: 7,
            height: '100%',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#E2E2E2',
            borderRadius: 5,
        },
    },
    warningText: {
        color: '#F4565B',
    },

    openNote: {
        color: theme.palette.success.main,
        paddingRight: '270px',
    },
    programFee: {
        color: '#F4565B',
        paddingRight: '30px',
        width: '360px',
    },
    closedNote: {
        color: theme.palette.text.disabled,
        paddingRight: '261px',
    },

    text: {
        color: 'black',
        paddingRight: '213px',
    },
}));
