import React from 'react';
import routePaths from '@/utils/route-paths';

import LoginWithOTP from '@/components/Login/loginWithOTP';
import SetPassord from '@/components/Login/setPassword';

import SignIn from '@/components/Login/signIn';
import SignUp from '@/components/Login/signUp';
import Login from '@/components/Login/index';

export const loginRoutes = [
    {
        routePath: routePaths.LOGIN,
        component: (props) => <Login {...props} />,
    },
    {
        routePath: routePaths.LOGIN_VIA_OTP,
        component: (props) => <LoginWithOTP {...props} />,
    },
    {
        routePath: routePaths.SET_PASSWORD,
        component: (props) => <SetPassord {...props} />,
    },
    {
        routePath: routePaths.SIGNIN_PAGE,
        component: (props) => <SignIn {...props} />,
    },
    {
        routePath: routePaths.SIGNUP_PAGE,
        component: (props) => <SignUp {...props} />,
    },
];
