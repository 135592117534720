//Assets
import login_one from '@/assets/login_one.svg';
import login_two from '@/assets/login_two.svg';
import login_three from '@/assets/login_three.svg';

export const LoginProps = () => {
    return [
        {
            src: login_one,
            title: 'Upto 90% Commission sharing',
            description: '',
        },
        {
            src: login_two,
            title: '1500+ Universities worldwide',
            description: '',
        },
        {
            src: login_three,
            title: 'Earn upto ₹25,000 extra per Visa*',
            description: '',
        },
        {
            src: login_one,
            title: '< 24 hrs Fastest turnaround time in the industry',
            description: '',
        },
        {
            src: login_two,
            title: 'Real time university updates',
            description: '',
        },
        {
            src: login_three,
            title: 'Trusted by Referral Partners around 100+ cities in India',
            description: '',
        },
    ];
};
