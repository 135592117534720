import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: 'flex',
        borderRadius: theme.spacing(1.25),
        padding: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        cursor: 'pointer',
        boxShadow: 'none',
        transition: 'all 0.2s',
        '&:hover': {
            boxShadow: theme.shadows[3],
        },
        width: theme.spacing(35),
        [theme.breakpoints.up('sm')]: {
            minWidth: theme.spacing(14),
        },
        [theme.breakpoints.up('md')]: {
            width: theme.spacing(33),
        },
    },
    cardFooter: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(0.5, 1, 1.5),
    },
    fileIcon: {
        cursor: 'pointer',
        color: 'black',
        height: theme.spacing(6),
        width: theme.spacing(5),
    },
    fileIconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        padding: theme.spacing(1),
        background: theme.palette.secondary.white,
        height: theme.spacing(6),
        width: theme.spacing(5),
        marginRight: '4px',
    },
    resourceClass: {
        maxWidth: '300px',
        maxHeight: '300px',
        height: theme.spacing(6),
        width: theme.spacing(5),
        marginRight: '6px',
    },
    copyIcon: {
        color: theme.palette.secondary.main,
        fontSize: '18px',
    },
}));
