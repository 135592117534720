import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    courseCard: {
        border: '1.5px solid #E2E2E2',
        borderRadius: theme.spacing(2.5),
    },
    universityName: {
        width: '80%',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.spacing(1.75),
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    item: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: theme.spacing(2),
    },
    cardProperty: {
        width: '80%',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.spacing(1.75),
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    cardIcons: {
        color: theme.palette.text.disabled,
        fontSize: theme.spacing(2),
    },
    intakeCard: {
        background: '#E2E2E2',
        margin: theme.spacing(0.2, 0.2),
        padding: theme.spacing(0.5, 1.5),
        borderRadius: theme.spacing(2),
    },
    activeIntakeCard: {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        margin: theme.spacing(0.2, 0.2),
        padding: theme.spacing(0.5, 1.5),
        borderRadius: theme.spacing(2),
    },
    intakeCardsProperty: {
        display: 'block',
        maxWidth: '170px',
    },
    commentWrap: {
        display: 'flex',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(1),
        },
    },
    commentIcon: {
        marginTop: '3px',
        height: theme.spacing(2),
        width: theme.spacing(2),
    },
    comment: {
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.spacing(1.75),
        marginLeft: theme.spacing(1),
        fontStyle: 'italic',
        width: '100%',
    },
    disabledColor: {
        color: theme.palette.text.disabled,
    },
    appliedCard: {
        padding: theme.spacing(1.5, 5),
        borderRadius: theme.spacing(2),
        background: '#E2E2E2',
    },
    azentPill: {
        padding: '4px',
        fontSize: '11px',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        backgroundColor: '#fef1d4',
        maxHeight: '25px',
        borderRadius: theme.spacing(1.5),
        marginLeft: theme.spacing(1),
    },
    partnerPill: {
        padding: '4px',
        fontSize: '11px',
        textAlign: 'center',
        backgroundColor: '#bed3ff',
        maxHeight: '25px',
        whiteSpace: 'nowrap',
        borderRadius: theme.spacing(1.5),
        marginLeft: theme.spacing(1),
    },
    subAgentPill: {
        padding: '4px',
        fontSize: '11px',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        backgroundColor: '#F9CCC1',
        maxHeight: '25px',
        borderRadius: theme.spacing(1.5),
        marginLeft: theme.spacing(1),
    },
    linkProperty: {
        width: '80%',
        display: 'block',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.spacing(1.75),
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        '&:hover': {
            background: 'transparent',
        },
    },
    programLink: {
        color: '#181A1B',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
            color: '#3d5bb6',
        },
    },
}));
