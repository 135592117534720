import React from 'react';
import routePaths from '@/utils/route-paths';
import CreateStudent from '@/components/modules/Students/CreateStudent';
import StudentProfile from '@/components/modules/Students/StudentProfile';
import ListStudent from '@/components/modules/Students/ListStudent';
import { AuthorisedRoute } from '@/rbac';

export const studentRoutes = [
    {
        routePath: routePaths.STUDENT_INDEX,
        component: (props) => (
            <AuthorisedRoute feature="PROFILE_LIST">
                <ListStudent {...props} />
            </AuthorisedRoute>
        ),
    },
    {
        routePath: routePaths.STUDENT_PROFILE_CREATE,
        component: (props) => (
            <AuthorisedRoute feature="PROFILE_CREATE">
                <CreateStudent {...props} />
            </AuthorisedRoute>
        ),
    },
    {
        routePath: routePaths.STUDENT_PROFILE_VIEW,
        component: (props) => (
            <AuthorisedRoute feature="PROFILE_VIEW">
                <StudentProfile {...props} />
            </AuthorisedRoute>
        ),
    },
];
