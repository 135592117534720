import AuthorisedRoute from './authorised-route';
import { canAccess, isCounsellor, isRoleDefined } from './can-access';
import { store } from '@/store';

let isPartner = function () {
    let roles = store.getState().userData.userData.roles;
    return roles.map((i) => i.name).includes('PARTNER');
};

let isB2bAdmin = function (user = null) {
    let roles = [];

    if (user) roles = user.roles;
    else roles = store.getState().userData.userData.roles;

    return (
        roles.map((i) => i.name).includes('ADMIN') ||
        roles.map((i) => i.name).includes('B2B_ADMIN') ||
        roles.map((i) => i.name).includes('PSA')
    );
};

let isSubAgent = function () {
    let roles = store.getState().userData.userData.roles;
    return roles.map((i) => i.name).includes('SUB_AGENT');
};
let isPSA = function () {
    let roles = store.getState().userData.userData.roles;
    return roles.map((i) => i.name).includes('PSA');
};

export {
    AuthorisedRoute,
    canAccess,
    isPartner,
    isCounsellor,
    isB2bAdmin,
    isRoleDefined,
    isSubAgent,
    isPSA,
};
