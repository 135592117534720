// mui styles
import { makeStyles } from '@mui/styles';

// Styles
export const useStyles = makeStyles((theme) => ({
    container: {
        margin: theme.spacing(1.25, 1.25, 10, 1.25),
    },
    title: {
        padding: theme.spacing(0, 1.5, 1, 1.5),
        fontSize: theme.spacing(2.2),
        textAlign: 'left',
        fontWeight: theme.spacing(75),
    },
    description: {
        color: '#A9A9A9',
        fontSize: theme.spacing(1.6),
        textAlign: 'left',
        fontWeight: theme.spacing(62.5),
        padding: theme.spacing(0, 1.5, 1, 1.5),
    },
    noDataDescription: {
        textAlign: 'left',
        padding: theme.spacing(2.5, 0, 1, 1.5),
    },
    button: {
        color: 'rgb(255, 255, 255)',
        backgroundColor: 'rgb(0, 200, 83)',
        fontSize: '14px',
        borderRadius: '10px',
        padding: '10px 28px',
    },
    loadingCards: {
        margin: '10px',
        width: '100vw',
    },
}));
