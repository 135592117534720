/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { get, startCase } from 'lodash';

// utils
import routePaths, { studentPaths } from '@/utils/route-paths';
import { getModifiedByLabel } from '@/utils/modifiedBy';
import {
    getStudentContainerClass,
    getStudentTitleClass,
    STUDENT_STATUS_COLOR,
} from '@/props-constants/student/list-student';

// Rbac
import { isB2bAdmin, isPSA, isPartner, isSubAgent } from '@/rbac';

// mui
import { Box, Typography, IconButton } from '@mui/material';

// actions
import { goToRoute } from '@/store/actions/navigationActions';

// comps
import { getIcon, ColorCard } from 'azent-storybook/dist';

// styles
import { useStyles } from '../styles';

const getStudentStatus = (profileStatus) => {
    const classes = useStyles();
    if (profileStatus && profileStatus.length) {
        const status = get(profileStatus, `${profileStatus.length - 1}.status`, '');
        return (
            <Box my={0.5} mx={0.4}>
                <ColorCard
                    classes={{
                        container: getStudentContainerClass(classes, status),
                        title: getStudentTitleClass(classes, status),
                    }}
                    color={get(STUDENT_STATUS_COLOR, status, '#BED3FF')}
                    title={startCase(status)}
                />
            </Box>
        );
    } else {
        return (
            <Typography sx={{ fontWeight: 400, alignItems: 'center' }} variant="h6">
                -
            </Typography>
        );
    }
};

export const getRawColumns = (user) => [
    {
        Header: 'Student Id',
        Footer: 'Student Id',
        accessor: (c) => `${get(c, 'crm_uid', '-')}`,
        Cell: ({ value }) => (
            <Typography variant="h6" color="primary">
                {value}
            </Typography>
        ),
        showInit: true,
        show: true,
    },
    {
        Header: 'Name',
        Footer: 'Name',
        accessor: (c) => `${c.first_name} ${c.last_name}`,
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: true,
        show: true,
    },
    {
        Header: 'Applications',
        Footer: 'Applications',
        accessor: (c) => c.applications.length || 0,
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: false,
        show: true,
    },
    {
        Header: 'Target Countries',
        Footer: 'Target Countries',

        accessor: (c) =>
            get(c, 'desired_profile.interest_countries', []).length
                ? get(c, 'desired_profile.interest_countries', []).join(', ')
                : '-',
        Cell: ({ value }) => (
            <Typography sx={{ fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: true,
        show: true,
    },
    {
        Header: 'Target Program',
        Footer: 'Target Program',
        accessor: (c) =>
            get(c, 'desired_profile.keywords', []).length
                ? get(c, 'desired_profile.keywords', []).join(', ')
                : '-',
        Cell: ({ value }) => (
            <Typography sx={{ fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: true,
        show: true,
    },
    {
        Header: 'Target Intake',
        Footer: 'Target Intake',
        accessor: (c) =>
            get(c, 'desired_profile.intake_month', '')
                ? `${get(c, 'desired_profile.intake_month', '-')} - ${get(
                      c,
                      'desired_profile.intake_year',
                      '-',
                  )}`
                : '-',
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: true,
        show: true,
    },
    {
        Header: 'Referral Partner',
        Footer: 'Referral Partner',
        accessor: (c) => get(c, 'partner__name', '-'),
        Cell: ({ value }) => (
            <Typography sx={{ fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: isB2bAdmin(user),
        show: isB2bAdmin(user),
    },
    {
        Header: 'Counsellor / Branch',
        Footer: 'Counsellor / Branch',
        accessor: (c) => {
            let name = `${get(c, 'sub_agent.first_name', '')} ${get(c, 'sub_agent.last_name', '')}`;
            name = name.trim().length ? name : 'N/A';
            return name;
        },
        Cell: ({ value }) => (
            <Typography sx={{ fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        show: isB2bAdmin() || isPartner(),
        showInit: false,
    },
    {
        Header: 'Passport Number',
        Footer: 'Passport Number',
        accessor: (c) => get(c, 'passport.passport_number', ''),
        disableSortBy: true,
        Cell: ({ value }) => (
            <Typography sx={{ fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: true,
        show: true,
    },
    {
        Header: 'Student Owner',
        Footer: 'Student Owner',
        accessor: (c) => get(c, 'counsellor__name', 'Not Assigned Yet'),
        Cell: ({ value }) => (
            <Typography sx={{ fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        show: true,
        showInit: true,
    },
    {
        Header: 'Status',
        Footer: 'Status',
        accessor: (c) => get(c, 'profile_status', null),
        disableSortBy: true,
        Cell: ({ value }) => getStudentStatus(value),
        showInit: true,
        show: true,
    },
    {
        Header: 'Mobile',
        Footer: 'Mobile',
        accessor: (c) => `${c.mobile}`,
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),

        showInit: false,
        show: true,
    },
    {
        Header: 'Email',
        Footer: 'Email',
        accessor: (c) => `${c.email}`,
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: false,
        show: true,
    },
    {
        Header: 'Student Profile Id',
        Footer: 'Student Profile Id',
        accessor: (c) => `${c._id}`,
        Cell: ({ value }) => (
            <Typography sx={{ fontWeight: 400 }} variant="h6" color="primary">
                {value}
            </Typography>
        ),
        show: false,
        showInit: false,
    },
    {
        Header: 'RP Mobile',
        Footer: 'RP Mobile',
        accessor: (c) => get(c, 'partner__mobile', '-'),
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: false,
        show: true,
    },
    {
        Header: 'RP Email',
        Footer: 'RP Email',
        accessor: (c) => get(c, 'partner__email', '-'),
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: false,
        show: true,
    },
    {
        Header: 'Created Date',
        Footer: 'Created Date',
        accessor: (c) => get(c, 'created_at', '-'),
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {moment(value).format('DD/MM/YYYY')}
            </Typography>
        ),
        showInit: false,
        show: true,
    },
    {
        Header: 'Business Manager Name',
        Footer: 'Business Manager Name',
        accessor: (c) => get(c, 'psa_name', '-'),
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: false,
        show: !(isPartner() || isPSA() || isSubAgent()),
    },
    {
        Header: 'Business Manager Email',
        Footer: 'Business Manager Email',
        accessor: (c) => get(c, 'psa_email', '-'),
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: false,
        show: !(isPartner() || isPSA() || isSubAgent()),
    },
    {
        Header: 'Business Manager Mobile',
        Footer: 'Business Manager Mobile',
        accessor: (c) => get(c, 'psa_mobile', '-'),
        Cell: ({ value }) => (
            <Typography sx={{ textAlign: 'left', fontWeight: 400 }} variant="h6">
                {value}
            </Typography>
        ),
        showInit: false,
        show: !(isPartner() || isPSA() || isSubAgent()),
    },
    {
        Header: 'Last Action By',
        Footer: 'Last Action By',
        accessor: (c) => {
            let modifiedBy = get(c, 'last_modified_by', null);
            return {
                updatedAt: `${moment(get(c, 'modified_at', '-'))
                    .add(5, 'hours')
                    .add(30, 'minutes')
                    .fromNow()} `,
                updatedBy: `${modifiedBy ? 'By ' + getModifiedByLabel(modifiedBy) : ''}`,
            };
        },
        Cell: ({ value, row }) => {
            const _classes = useStyles();
            const dispatch = useDispatch();
            return (
                <Box display="flex" alignItems={'flex-start'}>
                    <Box>
                        <Typography
                            sx={{ textAlign: 'left', color: 'text.disabled', fontSize: '12px' }}
                            variant="h6">
                            {value.updatedAt}
                        </Typography>
                        <Typography
                            sx={{ textAlign: 'left', color: 'text.disabled', fontSize: '12px' }}
                            variant="h6">
                            {value.updatedBy}
                        </Typography>
                    </Box>
                    <IconButton
                        onClick={() =>
                            dispatch(
                                goToRoute(routePaths.STUDENT_PROFILE_VIEW, {
                                    id: row.values['Student Profile Id'],
                                    path: studentPaths.VIEW,
                                }),
                            )
                        }>
                        {getIcon('edit', 'inherit', 'small', _classes.editIcon)}
                    </IconButton>
                </Box>
            );
        },
        showInit: true,
        show: true,
    },
];
