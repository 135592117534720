import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { lowerCase, get } from 'lodash';

// comps
import { FormJourney } from 'azent-storybook/dist';

// material
import Box from '@mui/material/Box';

//prop-constants
import { getOnboardingSchema } from '@/props-constants/registration/schema';
import getOrganisationUiSchema from '@/props-constants/registration/ui-schemas/organisation-profile-ui';
import getKycUiSchema from '@/props-constants/registration/ui-schemas/kyc-ui';
import getCommunicationDetailUiSchema from '@/props-constants/registration/ui-schemas/communication-detail-ui';

//utils
import * as navigationActions from '@/store/actions/navigationActions';
import {
    validateProfileDataByStep,
    getProfileSplit,
    submitApiByIndex,
    checkProfileStepCompleted,
} from '@/utils/registration';
import { toastMessages } from '@/utils/toastMessages';

// API
import { signRequest, updateOnboardingData } from '@/api';
import { setToast } from '@/store/slices/toastSlice';
import { PartnerDataGetAndStore } from '@/store/actions/partnerActions';

// Constants
import constants from '@/constants';

const steps = [0, 1, 2];

export default function NewRegistration() {
    const dispatch = useDispatch();

    const profileData = useSelector((state) => state.partnerData.data);
    const search = useSelector((state) => state.router.location.search);
    const partnerId = useSelector((state) => state.partnerData.data._id);
    const params = new URLSearchParams(search);
    const [currentIndex, setCurrentIndex] = useState(Number(params.get('index')) || 0);
    const [dirtyFormData, setDirtyFormData] = useState(null);
    const [copyNumber, setCopyNumber] = useState(profileData);

    const onJourneySuccess = () => {
        updateOnboardingData({ status: 'application_submitted', onboarding_completed: true }).then(
            () => {
                setTimeout(() => {
                    dispatch(PartnerDataGetAndStore());
                }, 2000);
            },
        );
    };

    const onSuccessCallback = async () => {
        if (currentIndex == 0 || currentIndex == 1) {
            dispatch(navigationActions.setQuery('index', currentIndex + 1));
            setCurrentIndex(currentIndex + 1);
            dispatch(PartnerDataGetAndStore());
            window.location.reload();
        }
    };

    const checkStepCompleted = (submittedDetails, index) => {
        // NOTE submitted details not coming properly

        if (profileData) return checkProfileStepCompleted(profileData, index);
    };

    const checkStepEnabled = (_data, index) => {
        if (index === 0) return true;
        if (validateProfileDataByStep(_data, index - 1)) {
            return true;
        }
        return false;
    };

    const onStepChange = (index) => {
        dispatch(navigationActions.setQuery('index', index));
        setCurrentIndex(index);
    };

    const getFormUiSchema = (index) => {
        switch (index) {
            case 0: {
                return getOrganisationUiSchema();
            }
            case 1: {
                return getCommunicationDetailUiSchema();
            }
            case 2: {
                return getKycUiSchema();
            }
            default:
                return {};
        }
    };

    const validateForm = (formData) => {
        //mandatory prop to be sent, if no error send true
        if (formData && formData.step == 2) {
            if (formData.kyc) {
                if (formData.kyc.acceptance == false) {
                    dispatch(setToast(toastMessages.partnerDeclarationAcceptance));
                    return false;
                } else return true;
            }
        } else return true;
    };

    const formConstantsByIndex = (index) => {
        switch (index) {
            default:
                return {};
        }
    };

    const getStepTitleByIndex = (index) => {
        switch (index) {
            case 0:
                return 'Company Profile';
            case 1:
                return 'Point Of Contact';
            case 2:
                return 'KYC';
            default:
                return '';
        }
    };

    useEffect(() => {
        if (dirtyFormData) {
            let copyNumber = get(dirtyFormData, '1.point_of_contact.copy_number', null);
            setCopyNumber(copyNumber);
        }
    }, [dirtyFormData]);

    const onChangeHandler = (dirtyFormData) => {
        setDirtyFormData(dirtyFormData);
    };

    const myProfileProps = useMemo(() => {
        let data = null;
        data = {
            journey: {
                styleProps: {
                    showBorder: true,
                    titleVariant: 'h5',
                    borderColor: 'secondary',
                },
                formsData: steps.map((index) => {
                    return {
                        title: getStepTitleByIndex(index),
                        schema: getOnboardingSchema(index),
                        uiSchemas: getFormUiSchema(index),
                        submitButton: {
                            validateForm: validateForm,
                            color: 'success',
                            label: steps.length - 1 === index ? 'Save Profile' : 'Save & Proceed',
                            onClick: (submittedData, formData, formConstants) => {
                                return submitApiByIndex(
                                    index,
                                    submittedData,
                                    {
                                        ...formData,
                                        ...formConstants,
                                    },
                                    profileData,
                                );
                            },
                        },

                        formConstants: formConstantsByIndex(index),
                        uploadConfig: {
                            awsConfig: constants.awsConfig,
                            s3Postfix: `${lowerCase(constants.envName)}/${partnerId}`,
                            selfAuthApiCall: signRequest,
                        },
                    };
                }),
                previousButton: {
                    label: 'Previous',
                    variant: 'outlined',
                    color: 'inherit',
                    sx: {
                        fontSize: '14px',
                        fontWeight: '600',
                        color: 'black',
                        borderColor: 'rgba(0,0,0,0.23)',
                        mr: 2,
                        '&:hover': {
                            borderColor: 'rgba(0,0,0,0.23)',
                        },
                    },
                },
                customGridSize: {
                    journeyStepper: { desktop: 3, mobile: 12 },
                    formBox: { desktop: 9, mobile: 12 },
                },
                defaultData: profileData
                    ? getProfileSplit(profileData, true, null, dirtyFormData)
                    : {},
            },
        };
        return data;
    }, [profileData, copyNumber]);

    const FormJourneyComponent = useMemo(() => {
        let data = null;
        if (myProfileProps && myProfileProps.journey) {
            data = (
                <Box>
                    <FormJourney
                        key="form-journey-component"
                        {...myProfileProps.journey}
                        currentIndex={currentIndex}
                        onMobileBackNavigation={() => {}}
                        onJourneySuccess={onJourneySuccess}
                        onSuccessCallback={onSuccessCallback}
                        checkStepCompleted={checkStepCompleted}
                        checkStepEnabled={checkStepEnabled}
                        onStepChange={onStepChange}
                        successMessage={'Form Submitted Successfully'}
                        onChangeHandler={onChangeHandler}
                    />
                </Box>
            );
        }
        return data;
    }, [myProfileProps, currentIndex]);

    return (
        <Box>
            <Box m={2} mr={10}>
                {FormJourneyComponent}
            </Box>
        </Box>
    );
}
