import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// mui
import { List, ListItem, Typography } from '@mui/material';

// styles
import { useStyles } from './style';

export default function ProfileStepper({ currentFormIndex, setCurrentFormIndex, steps }) {
    const classes = useStyles();

    return (
        <List dense sx={{ pt: 0 }}>
            {steps.map((step, index) => {
                const classNames = [classes.step];

                if (currentFormIndex === index) {
                    classNames.push(classes.focusedStep);
                }
                return (
                    <ListItem
                        key={index}
                        onClick={(event) => {
                            event.stopPropagation();
                            setCurrentFormIndex(index);
                        }}
                    >
                        <Typography className={clsx(...classNames)} variant={'h6'}>
                            {step.title}
                        </Typography>
                    </ListItem>
                );
            })}
        </List>
    );
}

ProfileStepper.propTypes = {
    currentFormIndex: PropTypes.number.isRequired,
    setCurrentFormIndex: PropTypes.func.isRequired,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
        }),
    ).isRequired,
};
