import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { difference } from 'lodash';

// mui
import { Box, Grid, Chip, Tooltip } from '@mui/material';

// storybook comps
import { Table, Button, Alert } from 'azent-storybook/dist';

//utils
import routePaths from '@/utils/route-paths';
import { getRawColumns } from './studentColumns';

//action
import { setQueries, goToRoute } from '@/store/actions/navigationActions';

// api
import { getProfiles } from '@/api';

// css
import { useStyles } from './style';

//rbac
import { isSubAgent } from '@/rbac';

//comps
import { subAgentNoStudentsMsg } from '@/props-constants/sub-agent/sub-agent-profile';

let timeout = null;
let mounted = false;

export default function StudentList({ defaultData, submitButton, onSuccessCallback }) {
    const dispatch = useDispatch();
    const classes = useStyles();

    const location = useSelector((state) => state.router.location);
    const { assignedStudents, ...subAgentData } = useSelector((state) => state.subAgentData);
    const have_password = useSelector((state) => state.subAgentData?.data?.have_password);

    let search = new URLSearchParams(location.search);

    const [loading, setLoading] = React.useState(true);
    const [page, setPage] = React.useState(search.get('page') || 1);
    const [perPage, setPerPage] = React.useState(search.get('perPage') || 10);
    const [totalPages, setTotalPages] = React.useState(0);
    const [searchText, setSearchText] = React.useState(search.get('q' || ''));
    const [errorMsg, setErrorMsg] = React.useState(null);
    const [studentListingData, setStudentListingData] = React.useState([]);
    const [selectedStudents, setSelectedStudents] = React.useState([...assignedStudents]);

    const isSelectedStudent = (sid) => {
        return selectedStudents.findIndex(({ _id }) => _id === sid) !== -1;
    };
    const studentListingDataIndex = (sid) => {
        return studentListingData.findIndex(({ _id }) => _id === sid);
    };
    const getSelectedStudentFormat = (student) => {
        return {
            _id: student._id,
            first_name: student.first_name,
            last_name: student.last_name,
        };
    };
    const formatStudentList = (respData) => {
        let studentList = respData.map((data) => {
            if (isSelectedStudent(data._id)) {
                data.isAssigned = true;
            } else {
                data.isAssigned = false;
            }
            return data;
        });
        return studentList;
    };
    const selectStudent = (row, e) => {
        let student = row.original;
        let studentData = getSelectedStudentFormat(student);
        let studentListingCopy = studentListingData;
        studentListingCopy = studentListingCopy.map((s) => {
            if (s._id === studentData._id) {
                s.isAssigned = e.target.checked;
            }
            return s;
        });
        if (e.target.checked && !isSelectedStudent(studentData._id)) {
            setSelectedStudents((data) => [...data, studentData]);
        } else {
            setSelectedStudents((data) => data.filter(({ _id }) => _id !== studentData._id));
        }
        setStudentListingData([...studentListingCopy]);
    };
    const handleSelectAll = () => {
        let studentListingCopy = [...studentListingData];
        let selectedStudentsCopy = [...selectedStudents];

        studentListingCopy = studentListingCopy.map((student) => {
            let studentData = getSelectedStudentFormat(student);
            if (!isSelectedStudent(studentData._id)) {
                selectedStudentsCopy.push(studentData);
            }
            student.isAssigned = true;
            return student;
        });

        setSelectedStudents(selectedStudentsCopy);
        setStudentListingData(studentListingCopy);
    };
    const deleteChip = (student) => {
        setSelectedStudents((data) => data.filter(({ _id }) => _id !== student._id));
        let listingDataIndex = studentListingDataIndex(student._id);
        if (listingDataIndex !== -1) {
            let listingDataCopy = [...studentListingData];
            listingDataCopy[listingDataIndex].isAssigned = false;
            setStudentListingData(listingDataCopy);
        }
    };

    const getStudentQueries = function (page, per_page, searchText, pageChoice) {
        const query = [
            { key: 'step', value: 'student-list' },
            { key: 'id', value: defaultData.id },
            { key: 'page', value: pageChoice || page },
            { key: 'per_page', value: per_page || '10' },
            { key: 'q', value: searchText || '' },
        ];
        return query;
    };

    const setQuery = function (pageChoice) {
        const query = getStudentQueries(page, perPage, searchText, pageChoice);
        dispatch(setQueries(query));
    };

    const getStudentList = (location) => {
        let search = new URLSearchParams(location);
        let searchObj = Object.fromEntries(search);
        if (!searchObj.per_page) searchObj.per_page = 10;
        setLoading(true);
        getProfiles({ ...searchObj, subagent_assigned: false })
            .then((response) => {
                let unAssignedStudentList = response.data || [];
                unAssignedStudentList = formatStudentList(response.data);
                setStudentListingData(unAssignedStudentList);
                setPage(Number(response.headers.page));
                setPerPage(Number(response.headers.per_page));
                setTotalPages(Number(response.headers.total_pages));
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const onClickSubmit = async () => {
        setLoading(true);
        let assignedStudentsIds = assignedStudents.map(({ _id }) => _id);
        let selectedStudentsIds = selectedStudents.map(({ _id }) => _id);
        try {
            let payload = {
                id: defaultData.id,
                step: defaultData.step,
                selectedStudent: selectedStudentsIds,
                deSelectedStudent: difference(assignedStudentsIds, selectedStudentsIds),
            };
            let response = null;
            if (submitButton.validateForm(payload)) {
                response = await submitButton.onClick(payload);
            }
            if (response && response.data) {
                await onSuccessCallback(response.data, payload);
                // reset student store
            }
        } catch (err) {
            if (err?.response?.data?.error?.message) {
                setErrorMsg(err.response.data.error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const onCloseErrorModalCallback = () => {
        setErrorMsg(null);
    };

    const getSelectAllConfig = useMemo(() => {
        if (studentListingData.length > 0)
            return {
                onSelect: handleSelectAll,
                label: 'Select All',
                className: '',
            };
        else return {};
    }, [studentListingData]);

    React.useEffect(() => {
        setTimeout(() => {
            mounted = true;
        });
    }, []);

    React.useEffect(() => {
        if (mounted) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            timeout = setTimeout(() => {
                if (page > 1) {
                    setQuery(1);
                } else {
                    setQuery();
                }
            }, 1000);
        }
    }, [searchText]);

    React.useEffect(() => {
        if (mounted) {
            setQuery();
        }
    }, [page, perPage]);

    useEffect(() => {
        getStudentList(location.search);
    }, [location.search]);

    useEffect(() => {
        setSelectedStudents([...assignedStudents]);
    }, [assignedStudents]);

    const columns = getRawColumns(selectStudent, have_password);

    const TableComp = useMemo(
        () => (
            <Table
                title={'Student List'}
                noDataMessage={'No data'}
                config={{
                    columns,
                    data: studentListingData,
                    initialState: {
                        hiddenColumns: columns
                            .filter((col) => col.showInit == false)
                            .map((col) => col.Header),
                    },
                    filter: {
                        setFilterText: setSearchText,
                        filterText: searchText,
                    },
                    pagination: {
                        perPageOptions: [5, 10, 15, 20],
                        perPage,
                        setPerPage,
                        page,
                        setPage,
                        totalPages,
                    },
                }}
                isCustomFilter={false}
                loading={loading}
                setSelected={() => {}}
                selectAllConfig={getSelectAllConfig}
            />
        ),
        [loading, page, perPage, totalPages, searchText, studentListingData],
    );

    return (
        <>
            {errorMsg && (
                <Alert
                    title={errorMsg}
                    severity="error"
                    onCloseCallback={onCloseErrorModalCallback}
                />
            )}
            {!isSubAgent() && <Box className={classes.container}>{TableComp}</Box>}

            {isSubAgent() && selectedStudents.length == 0 && subAgentNoStudentsMsg(classes)}

            <Box mt={1}>
                {selectedStudents && !!selectedStudents.length && (
                    <Box className={classes.existingStudentsLabel}>Existing students assigned</Box>
                )}
                {selectedStudents &&
                    !!selectedStudents.length &&
                    selectedStudents.map((student, index) => (
                        <Chip
                            key={index}
                            sx={{
                                mx: 0.5,
                                my: 0.2,
                                backgroundColor: 'bisque',
                            }}
                            onDelete={!isSubAgent() ? () => deleteChip(student) : null}
                            label={`${student.first_name} ${student.last_name} `}
                            variant="contained"
                        />
                    ))}
            </Box>
            {!isSubAgent() && (
                <Grid container className={classes.buttonsContainer}>
                    <Grid item className={classes.previousButtonContainer}>
                        <Button
                            onClick={() => {
                                dispatch(goToRoute(routePaths.SUB_AGENT_INDEX));
                            }}
                            label={'Go Back'}
                            variant="outlined"
                            fullWidth="false"
                            startIcon={null}
                            color="inherit"
                            size="medium"
                            className={`${classes.buttonWidth} ${classes.previousButton}`}
                            disabled={loading}
                        />
                    </Grid>
                    <Tooltip
                        title={
                            !subAgentData.data.is_active
                                ? `Please activate the sub agent`
                                : !have_password
                                ? 'Set password before assigning a student'
                                : ''
                        }
                        placement="top">
                        <Grid item className={classes.submitButtonContainer}>
                            <Button
                                onClick={onClickSubmit}
                                label={'Save'}
                                variant="contained"
                                fullWidth="false"
                                endIcon={null}
                                color="primary"
                                size="medium"
                                className={`${classes.buttonWidth} ${classes.submitButton}`}
                                disabled={loading || !subAgentData.data.is_active || !have_password}
                            />
                        </Grid>
                    </Tooltip>
                </Grid>
            )}
        </>
    );
}

StudentList.propTypes = {
    defaultData: PropTypes.object,
    submitButton: PropTypes.object,
    onSuccessCallback: PropTypes.func,
};
