import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3, 2),
    },
    searchLoading: {
        width: '100%',
        height: theme.spacing(5),
        borderRadius: theme.spacing(5),
    },
}));
