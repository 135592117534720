import React from 'react';
import routePaths from '@/utils/route-paths';

// routes
import { studentRoutes } from './studentRoutes';
import { applicationRoutes } from './applicationRoutes';

// comps
import { NotAuthorised, NotFound } from '@/components/errors';
import Dashboard from '@/components/modules/Dashboard';
import CourseFinder from '@/components/modules/CourseFinder';
import NewTieup from '@/components/modules/NewTieup';
import Services from '@/components/modules/Services';
import ServicePage from '@/components/modules/Services/service-page';
import PartnerProfile from '@/components/modules/PartnerProfile';
import { subAzentRoutes } from './subAgentRoutes';

const routes = [
    {
        routePath: routePaths.NOT_FOUND,
        component: (props) => <NotFound {...props} />,
    },
    {
        routePath: routePaths.NOT_AUTHORISED,
        component: (props) => <NotAuthorised {...props} />,
    },
    {
        routePath: routePaths.DASHBOARD,
        component: (props) => <Dashboard {...props} />,
    },
    {
        routePath: routePaths.COURSE_FINDER,
        component: (props) => <CourseFinder {...props} />,
    },
    {
        routePath: routePaths.TIEUPS,
        component: (props) => <NewTieup {...props} />,
    },
    {
        routePath: routePaths.SERVICES,
        component: (props) => <Services {...props} />,
    },
    {
        routePath: routePaths.SERVICE_PAGE_VIEW,
        component: (props) => <ServicePage {...props} />,
    },
    {
        routePath: routePaths.PARTNER_PROFILE,
        component: (props) => <PartnerProfile {...props} />,
    },

    ...applicationRoutes,
    ...studentRoutes,
    ...subAzentRoutes,
];

export default routes;
