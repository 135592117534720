// Utils
import { getApplicationListQueries } from '@/props-constants/application/list-application';
import { getStudentListQueries } from '@/props-constants/student/list-student';
import { removeAuthToken } from '@/utils/auth-token-utils';
import localStorageActions from '@/utils/localStorage';
import { reverse } from 'named-urls';
// Store
import { store } from '@/store';
// Actions
import * as navigationActions from '@/store/actions/navigationActions';

const LOGIN_PATHS = {
    LOGIN: '/login',
    SIGNIN_PAGE: '/signin',
    LOGIN_VIA_OTP: '/login-with-otp',
    SET_PASSWORD: '/set-password',
    SIGNUP_PAGE: '/signup',
};

const LOGIN_AUTHTOKEN_PATH = {
    SET_PASSWORD: '/set-password',
    SIGNUP_PAGE: '/signup',
};

const CRM_PATHS = {
    CRM_COMMENTS: '/crm-comments',
};

const SERVICE_PATHS = {
    SERVICES: '/services',
    SERVICE_PAGE_VIEW: '/services/:id',
};

const SUBAGENTS_PATHS = {
    SUB_AGENT_CREATE: '/sub-agent/create',
    SUB_AGENT_UPDATE: '/sub-agent/update',
    SUB_AGENT_INDEX: '/sub-agent',
};

const ROUTE_PATHS = {
    NOT_FOUND: '/404',
    NOT_AUTHORISED: '/403',
    DOCUMENT_PREVIEW: '/document-preview/:id',
    DASHBOARD: '/',
    STUDENT_INDEX: '/students',
    STUDENT_PROFILE_CREATE: '/students/create',
    STUDENT_PROFILE_VIEW: '/students/:id/:path/:aid?',
    APPLICATION: '/applications',
    COURSE_FINDER: '/course-finder/:path/:id?',
    TIEUPS: '/tieups',
    PARTNER_PROFILE: '/profile',
    REGISTRATION: '/',

    ...LOGIN_PATHS,
    ...CRM_PATHS,
    ...SERVICE_PATHS,
    ...SUBAGENTS_PATHS,
};

const serviceRoutes = ['/services', 'service/'];

export const studentPaths = {
    VIEW: 'profile',
    SUMMARY: 'summary',
    APPLICATION: 'applications',
    SERVICES: 'services',
};
export const nestedStudentPaths = ['/profile', '/summary', '/applications'];

export const courseFinderPaths = {
    VIEW: 'courses',
    RECOMMENDED_COURSES: 'recommended_courses',
    SHORTLISTS: 'shortlists',
};
export const nestedCourseFinderPaths = [
    '/course-finder/courses',
    '/course-finder/recommended_courses',
    '/course-finder/shortlists',
];

export const registrationPaths = {
    VIEW: 'details',
    APPLICATION_SUBMITTED: 'application_submitted',
    AGREEMENT_INITIATED: 'agreement_initiated',
    ONBOARDED: 'onboarded',
};

export const handleLogout = () => {
    removeAuthToken();
    window.location.reload();
};

export const checkValidRoute = (routes) => {
    const urlPath = window.location.pathname.toString();
    //level one paths
    const validRoute = routes.filter((route) => route.routePath == urlPath);

    //Nested Paths :
    const courseFinderValidRoute = nestedCourseFinderPaths.filter((route) =>
        urlPath.includes(route),
    );
    const studentValidRoute = nestedStudentPaths.filter(
        (route) => urlPath.includes(route) && urlPath.includes('/students'),
    );
    const serviceValidRoutes = serviceRoutes.filter((route) => urlPath.includes(route));

    if (
        validRoute.length > 0 ||
        courseFinderValidRoute.length > 0 ||
        studentValidRoute.length > 0 ||
        serviceValidRoutes.length > 0
    )
        return true;
    else return false;
};

export const isValidRefreshDataPath = (path) => {
    return (
        path &&
        (path.includes(ROUTE_PATHS.APPLICATION) ||
            (path.includes(ROUTE_PATHS.STUDENT_INDEX) &&
                !path.includes(ROUTE_PATHS.STUDENT_PROFILE_CREATE)))
    );
};

export const isLoginPath = (location) => {
    return (
        Object.values(LOGIN_PATHS).findIndex(
            (route) => location && location.pathname && location.pathname.includes(route),
        ) !== -1
    );
};
export const isCrmPath = (location) => {
    return (
        Object.values(CRM_PATHS).findIndex(
            (route) => location && location.pathname && location.pathname.includes(route),
        ) !== -1
    );
};
export const isdocumentPreviewPathFlag = (location) => {
    return location.pathname && location.pathname.includes('/document-preview');
};
export const isPreLoginTokenPath = (location) => {
    return (
        Object.values(LOGIN_AUTHTOKEN_PATH).findIndex(
            (route) => location && location.pathname && location.pathname.includes(route),
        ) !== -1
    );
}; //paths where auth token created before user has logged in

export const studentListFiltersQueries = () => {
    let studentFiltersData = localStorageActions.getDataFromLocalStorage('studentFilter');
    studentFiltersData = studentFiltersData && JSON.parse(studentFiltersData);

    const filterData = getStudentListQueries(studentFiltersData, '1').reduce((obj, item) => {
        obj[item.key] = item.value;
        return obj;
    }, {});

    return filterData;
};
export const applicationListFiltersQueries = () => {
    let applicationFiltersData = localStorageActions.getDataFromLocalStorage('applicationFilter');
    applicationFiltersData = applicationFiltersData && JSON.parse(applicationFiltersData);

    const filterData = getApplicationListQueries(applicationFiltersData, '1').reduce(
        (obj, item) => {
            obj[item.key] = item.value;
            return obj;
        },
        {},
    );

    return filterData;
};

export function goToServices() {
    store.dispatch(navigationActions.goToRoute(ROUTE_PATHS.SERVICES));
}
export const isServiceRoute = (pathname) => {
    const regEx = new RegExp(/\/services\/?/);
    return regEx.test(pathname);
};

export default ROUTE_PATHS;

export const getFullURLRoute = (baseURL, path, params = {}, query = null) => {
    let routePath = path ? reverse(path, params) : reverse(params);
    if (query) {
        let search = new URLSearchParams(query);
        routePath = `${routePath}?${search.toString()}`;
    }
    let fullUrl = baseURL + routePath;
    return fullUrl;
};
