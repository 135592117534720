import { get } from 'lodash';
import moment from 'moment';

// Utils
import { capitalizeText } from '@/utils/textModifiers';
import { deserializeDocuments } from '../partner-documents';

const deserializePartner = (partner) => {
    const lodashGet = (key, defaultValue) => get(partner, key, defaultValue);

    let data = {};

    if (partner) {
        data = {
            _id: lodashGet('_id', ''),
            onboarding_status: lodashGet('status', ''),
            onboarding_location: lodashGet('onboarding_location', ''),
            onboarding_date: lodashGet('onboarding_date', undefined)
                ? moment(lodashGet('onboarding_date')).format('YYYY-MM-DD')
                : undefined,
            notes: lodashGet('notes', []),
            company_profile: {
                organisation_name: lodashGet('company_profile.organisation_name', ''),
                office_address: {
                    address1: lodashGet('company_profile.office_address.address1', ''),
                    address2: lodashGet('company_profile.office_address.address2', ''),
                    city: lodashGet('company_profile.office_address.city', ''),
                    state: lodashGet('company_profile.office_address.state', ''),
                    country: lodashGet('company_profile.office_address.country', ''),
                    pin_code: lodashGet('company_profile.office_address.pin_code', '').toString(),
                },
                year_of_establishment: lodashGet('company_profile.year_of_establishment')
                    ? moment(lodashGet('company_profile.year_of_establishment').toString()).format(
                          'YYYY',
                      )
                    : '',
                number_of_employees: lodashGet('company_profile.number_of_employees', ''),
                type_of_business: lodashGet('company_profile.type_of_business', ''),
                other_business_details: lodashGet('company_profile.other_business_details', ''),
                target_countries: lodashGet('company_profile.target_countries', []),
            },
            customer_info: {
                students_advised_each_year: lodashGet(
                    'customer_info.students_advised_each_year',
                    '',
                ),
                students_sent_abroad_each_year: lodashGet(
                    'customer_info.students_sent_abroad_each_year',
                    '',
                ),
                program_type: lodashGet('customer_info.program_type', ''),
                course_duration: lodashGet('customer_info.course_duration', ''),
                top_three_streams: lodashGet('customer_info.top_three_streams', []),
                other_services: lodashGet('customer_info.other_services', []),
            },
            point_of_contact: {
                first_name: lodashGet('point_of_contact.first_name', ''),
                last_name: lodashGet('point_of_contact.last_name', ''),
                mobile: lodashGet('point_of_contact.mobile', null),
                whatsapp_number: lodashGet('point_of_contact.whatsapp_number', null),
                email: lodashGet('point_of_contact.email', ''),
                designation: lodashGet('point_of_contact.designation', ''),
                copy_number: null,
            },
            documents: deserializeDocuments(lodashGet('documents')),
            comments: lodashGet('comments', []),
            onboarding_completed: lodashGet('onboarding_completed', false),
        };
    }
    return data;
};

const getFullName = (student, defaultText = '') => {
    let firstName = '';
    let lastName = '';
    firstName = student.first_name ? student.first_name : defaultText;
    lastName = student.last_name ? student.last_name : defaultText;
    return `${capitalizeText(firstName)} ${capitalizeText(lastName)}`;
};

export { deserializePartner, getFullName };
