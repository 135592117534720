import React, { useState } from 'react';
import PropTypes from 'prop-types';

// mui
import { Grid, Box, Typography, Skeleton, Tooltip } from '@mui/material';

// comps
import { Button } from 'azent-storybook/dist';

// styles
import { useStyles } from './styles';

//utils
import { toastMessages } from '@/utils/toastMessages';
import { isPartner, isSubAgent } from '@/rbac';
import StudentReleventDetails from './partials/StudentReleventDetails';

export default function StudentHeader({
    avatar,
    name,
    setCreateApplicationModalOpen,
    studentId,
    applicationNumber,
    ...props
}) {
    const classes = useStyles();
    const disableCreateApplication = (isPartner() || isSubAgent()) && applicationNumber >= 5;
    const [viewDetailModalOpen, setViewDetailModalOpen] = useState(false);
    const onCreateApplication = () => {
        if (disableCreateApplication) return;
        else setCreateApplicationModalOpen(true);
    };
    const handleReleventModalClose = () => setViewDetailModalOpen(false);

    return (
        <>
            <Grid
                className={classes.container}
                pt={4}
                pb={1}
                px={6}
                container
                justifyContent="space-between">
                <Grid item container xs={4}>
                    <Grid className={classes.centerElement} item xs={3}>
                        <img src={avatar} alt={name} />
                    </Grid>
                    <Grid item xs={9} px={0.9}>
                        <Box pt={0} mb={1.5}>
                            {name.trim() ? (
                                <>
                                    <Typography className={classes.detailsText}>
                                        Id {studentId ? studentId : '-'}
                                    </Typography>
                                    <Typography variant="h4">{name}</Typography>
                                    <Typography
                                        mt={0.5}
                                        className={classes.detailLink}
                                        onClick={() => setViewDetailModalOpen(true)}>
                                        View relevant details
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Skeleton animation="wave" variant="text" width="50%" />
                                    <Skeleton
                                        animation="wave"
                                        sx={{ marginTop: '10px', borderRadius: '10px' }}
                                        variant="rect"
                                        width="120px"
                                        height={'20px'}
                                    />
                                </>
                            )}
                        </Box>
                    </Grid>
                </Grid>
                <Grid item className={classes.centerVertical} xs={3} pb={2}>
                    {name.trim() ? (
                        <Tooltip
                            title={
                                disableCreateApplication
                                    ? toastMessages.applicationLimit.message
                                    : ''
                            }>
                            <Button
                                label="Create Application"
                                color="success"
                                className={classes.createAppButton}
                                onClick={() => onCreateApplication()}
                                component={disableCreateApplication ? 'div' : undefined}
                                disabled={disableCreateApplication}
                            />
                        </Tooltip>
                    ) : (
                        <Skeleton
                            animation="wave"
                            sx={{ borderRadius: '10px' }}
                            variant="rect"
                            width="100px"
                            height={'40px'}
                        />
                    )}
                </Grid>
            </Grid>
            <StudentReleventDetails
                {...props}
                studentId={studentId}
                isModalOpen={viewDetailModalOpen}
                handleClose={handleReleventModalClose}
            />
        </>
    );
}

StudentHeader.propTypes = {
    id: PropTypes.string,
    avatar: PropTypes.node,
    name: PropTypes.string,
    setCreateApplicationModalOpen: PropTypes.func,
    studentId: PropTypes.string,
    applicationNumber: PropTypes.number,
};
