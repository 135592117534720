import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    unsubscribed: {
        display: 'flex',
        padding: theme.spacing(5, 0),
        textAlign: 'center',
        alignItems: 'center',
        borderRadius: theme.spacing(2.5),
        flexDirection: 'column',
        justifyContent: 'center',
    },
}));
