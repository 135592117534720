import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    cModalTitleContainer: {
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        padding: theme.spacing(2, 1.5),
    },
    cModalTitle: {
        color: 'white',
        fontSize: '20px',
    },
    modalTitleIcon: {
        borderRadius: '50%',
        backgroundColor: 'white',
        verticalAlign: 'middle',
        marginRight: theme.spacing(1.5),
    },
}));
