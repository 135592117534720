import React from 'react';
import { useDispatch } from 'react-redux';

//mui
import { Grid, Box, Typography, TextField, IconButton } from '@mui/material';
import InputAdornment from '@material-ui/core/InputAdornment';

//utils
import Loader from '@/components/shared/Loader';
import routePaths from '@/utils/route-paths';
import { toastMessages } from '@/utils/toastMessages';
import getParameterByName from '@/utils/user/getQueryParams';

// actions
import { goToRoute } from '@/store/actions/navigationActions';
import { userGetAndStore } from '@/store/actions/userActions';
import { setToast } from '@/store/slices/toastSlice';

//style
import { useStyles } from './style';

//api
import { setPassword, setNewUserPassword, resetPassword } from '@/utils/user/authUser';

//components
import ImageCarousel from './imageCarousel';
import { Button, getIcon } from 'azent-storybook/dist';
import TermsAndConditions, { PasswordPolicy } from './TermsAndConditions';

export default function SetPassord() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const partnerParam = getParameterByName('partnerNew', window.location.href);
    const resetToken = getParameterByName('reset_token', window.location.href);

    let [values, setValues] = React.useState({
        password1: '',
        password2: '',
        showPassword1: false,
        showPassword2: false,
    });
    let [errorTexts, setErrorTexts] = React.useState({
        password1: '',
        password2: '',
    });
    let [serverErrorMessage, setServerErrorMessage] = React.useState('');
    let [loading, setLoading] = React.useState(false);

    let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    let timeout;

    const checkPasswordStrength = (value) => {
        if (value.length > 0) {
            if (strongPassword.test(value)) setErrorTexts({ password1: '', password2: '' });
            else setErrorTexts({ password1: 'Please check the password guidelines below' });
        } else setErrorTexts({ password1: '', password2: '' });
    };

    const handleValidations = () => {
        let validations = {
            password1: '',
            password2: '',
        };
        if (values.password1.length === 0) {
            validations.password1 = 'Please Enter Your New Password';
        } else if (!strongPassword.test(values.password1)) {
            validations.password1 = 'Please check the password guidelines below';
        } else if (values.password2.length === 0) {
            validations.password2 = 'Please Re-Enter Your New Password';
        }
        if (
            values.password1.length !== 0 &&
            values.password2.length !== 0 &&
            values.password1 !== values.password2
        ) {
            validations.password2 = "Your Passwords Don't Match";
        }
        setErrorTexts(validations);
        if (validations.password1 || validations.password2) {
            return false;
        }

        return true;
    };

    const onSubmit = async () => {
        setLoading(true);
        setServerErrorMessage('');
        if (handleValidations()) {
            if (!partnerParam && !resetToken) {
                // normal scenario
                let setPasswordResult = await setPassword(values.password1);
                if (setPasswordResult.success || setPasswordResult.data.success) {
                    dispatch(setToast(toastMessages.passwordSetMessage));
                    dispatch(goToRoute(routePaths.SIGNIN_PAGE));
                } else {
                    setLoading(false);
                    setServerErrorMessage(setPasswordResult.error.message);
                }
            } else {
                if (resetToken) {
                    //via reset_token query param
                    let setPasswordResult = await resetPassword(values.password1, resetToken);
                    if (setPasswordResult.success || setPasswordResult.data.success) {
                        dispatch(setToast(toastMessages.newPartnerPasswordSet));
                        dispatch(goToRoute(routePaths.SIGNIN_PAGE));
                    } else {
                        setLoading(false);
                        setServerErrorMessage(setPasswordResult.error.message);
                    }
                } else {
                    // register partner and then set pwd
                    let setPasswordResult = await setNewUserPassword(values.password1);
                    if (
                        setPasswordResult &&
                        (setPasswordResult.success ||
                            (setPasswordResult.data && setPasswordResult.data.success))
                    ) {
                        dispatch(userGetAndStore());
                        dispatch(goToRoute(routePaths.REGISTRATION));
                    } else {
                        setLoading(false);
                        setPasswordResult &&
                            setPasswordResult.error &&
                            setPasswordResult.error.message &&
                            setServerErrorMessage(setPasswordResult.error.message);
                    }
                }
            }
        }
        setLoading(false);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Box className={classes.rootContainer}>
            {loading ? (
                <Loader />
            ) : (
                <Grid container alignItems="center">
                    <Grid item xs={5}>
                        <ImageCarousel classes={classes} />
                    </Grid>
                    <Grid item xs={2}>
                        <div className={classes.verticalLine}></div>
                    </Grid>
                    <Grid item xs={5} pl={5}>
                        <Box className={classes.formHeaderContainer} mb={1}>
                            <Typography className={classes.azentTitle}>Set Password</Typography>
                        </Box>
                        <Box className={classes.formContentContainer}>
                            {serverErrorMessage ? (
                                <Box mt={2} mb={2} textAlign="center">
                                    <Typography color="error">{serverErrorMessage}</Typography>
                                </Box>
                            ) : null}
                            <Box className={classes.fieldContainer} mb={4}>
                                <Typography className={classes.inputTitle}>
                                    {'New Password*'}
                                </Typography>
                                <TextField
                                    placeholder={'Minimum 8 characters'}
                                    className={classes.textFieldRoot}
                                    required
                                    name="password-1"
                                    type={values.showPassword1 ? 'text' : 'password'}
                                    id="password-1"
                                    value={values['password1']}
                                    onCopy={(e) => e.preventDefault()}
                                    onCut={(e) => e.preventDefault()}
                                    onPaste={(e) => e.preventDefault()}
                                    onChange={(event) => {
                                        setValues({
                                            ...values,
                                            password1: event.target.value,
                                        });
                                        clearTimeout(timeout);
                                        timeout = setTimeout(
                                            () => checkPasswordStrength(event.target.value),
                                            1000,
                                        );
                                    }}
                                    helperText={
                                        errorTexts['password1'] ? errorTexts['password1'] : null
                                    }
                                    error={errorTexts['password1'] ? true : false}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => {
                                                        setValues({
                                                            ...values,
                                                            showPassword1: !values.showPassword1,
                                                        });
                                                    }}
                                                    onMouseDown={handleMouseDownPassword}>
                                                    {values.showPassword1 ? (
                                                        <Box>
                                                            {getIcon('Offeye', 'inherit', 'small')}
                                                        </Box>
                                                    ) : (
                                                        <Box>
                                                            {getIcon('Eye', 'inherit', 'small')}
                                                        </Box>
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            <Box className={classes.fieldContainer} mb={3}>
                                <Typography className={classes.inputTitle}>
                                    {'Re-Enter New Password*'}
                                </Typography>
                                <TextField
                                    className={classes.textFieldRoot}
                                    variant="outlined"
                                    required
                                    name="password-2"
                                    type={values.showPassword2 ? 'text' : 'password'}
                                    id="password-2"
                                    value={values['password2']}
                                    onCopy={(e) => e.preventDefault()}
                                    onCut={(e) => e.preventDefault()}
                                    onPaste={(e) => e.preventDefault()}
                                    onChange={(event) => {
                                        setValues({
                                            ...values,
                                            password2: event.target.value,
                                        });
                                    }}
                                    helperText={
                                        errorTexts['password2'] ? errorTexts['password2'] : null
                                    }
                                    error={errorTexts['password2'] ? true : false}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => {
                                                        setValues({
                                                            ...values,
                                                            showPassword2: !values.showPassword2,
                                                        });
                                                    }}
                                                    onMouseDown={handleMouseDownPassword}>
                                                    {values.showPassword2 ? (
                                                        <Box>
                                                            {getIcon('Offeye', 'inherit', 'small')}
                                                        </Box>
                                                    ) : (
                                                        <Box>
                                                            {getIcon('Eye', 'inherit', 'small')}
                                                        </Box>
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>

                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.greenButton}
                                onClick={onSubmit}
                                label={'Set Password'}
                                sx={{ mt: 3 }}
                            />
                            <Box mt={2}>
                                <PasswordPolicy />
                            </Box>
                            <TermsAndConditions />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}
