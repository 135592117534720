import React from 'react';

// utils
import routePaths, { handleLogout } from '@/utils/route-paths';
import { get } from 'lodash';
import { getFullName } from '@/utils/deserializers/student-deserializer';
import { setToast } from '@/store/slices/toastSlice';
import { toastMessages } from '@/utils/toastMessages';

//api
import { getSubAgentProfilesAssigned } from '@/api';

// navigation action
import { goToRoute } from '@/store/actions/navigationActions';
import { addAssignedStudentsToStore } from '@/store/slices/subAgentSlice';
import { resetStudentDataInStore } from '@/store/slices/studentSlice';

//mui
import { Box, Typography } from '@mui/material';

//comps
import PersonalDetails from '@/components/modules/SubAgent/CreateSubAgent/partials/SubAgentDetail/partials/PersonalDetails';
import StudentList from '@/components/modules/SubAgent/CreateSubAgent/partials/SubAgentDetail/partials/StudentList';
import SetPassword from '@/components/modules/SubAgent/CreateSubAgent/partials/SubAgentDetail/partials/SetPassword';

export const getTileIndexFromRoute = (route) => {
    switch (route) {
        case 'personal-detail':
            return 0;
        case 'set-password':
            return 1;
        case 'student-list':
            return 2;
        default:
            return 0;
    }
};

export const getSubAgentProfileProps = (subAgentData = {}, dispatch = () => {}, userData = {}) => {
    const props = {
        subAgentHeaderProps: {
            id: get(subAgentData, 'id', ''),
            name: getFullName(subAgentData),
            isActive: get(subAgentData, 'is_active', ''),
            createdDate: get(subAgentData, 'created_date', ''),
            createdBy: getFullName(userData),
        },
        subAgentDetailProps: {
            tiles: [
                {
                    title: 'Personal Details',
                    mainContent: (props) => {
                        const {
                            submittedData,
                            onChangeHandler,
                            setDirtyFormData,
                            currentTileIndex,
                            defaultData,
                            formsData,
                            setSubmittedData,
                        } = props;
                        return (
                            <PersonalDetails
                                setSubmittedData={setSubmittedData}
                                submittedData={submittedData}
                                defaultData={
                                    submittedData ? submittedData[currentTileIndex] : defaultData
                                }
                                onChangeHandler={(data) => {
                                    let finalData = { ...submittedData, [currentTileIndex]: data };
                                    setDirtyFormData(finalData);
                                    onChangeHandler && onChangeHandler(finalData);
                                }}
                                schema={formsData[currentTileIndex].schema}
                                uiSchema={formsData[currentTileIndex].uiSchemas}
                                submitButton={{
                                    ...formsData[currentTileIndex].submitButton,
                                    onClick: async (formData) => {
                                        let finalData = {
                                            ...submittedData,
                                            [currentTileIndex]: {
                                                ...formData,
                                            },
                                        };

                                        setSubmittedData(finalData);
                                        return formsData[currentTileIndex].submitButton.onClick(
                                            finalData[currentTileIndex],
                                            formData,
                                        );
                                    },
                                }}
                                onSuccessCallback={async (responseData, formData) => {
                                    const responseId = responseData?.data?.User?.id;
                                    dispatch(
                                        responseId || (formData && formData.id)
                                            ? goToRoute(
                                                  routePaths.SUB_AGENT_UPDATE,
                                                  {},
                                                  {
                                                      step: 'set-password',
                                                      id: formData.id || responseId,
                                                  },
                                              )
                                            : goToRoute(
                                                  routePaths.SUB_AGENT_CREATE,
                                                  {},
                                                  { step: 'set-password' },
                                              ),
                                    );
                                }}
                            />
                        );
                    },
                },
                {
                    title: 'Password',
                    mainContent: (props) => {
                        const {
                            submittedData,
                            onChangeHandler,
                            setDirtyFormData,
                            currentTileIndex,
                            defaultData,
                            formsData,
                            setSubmittedData,
                        } = props;
                        return (
                            <SetPassword
                                defaultData={
                                    submittedData ? submittedData[currentTileIndex] : defaultData
                                }
                                onChangeHandler={(data) => {
                                    let finalData = { ...submittedData, [currentTileIndex]: data };
                                    setDirtyFormData(finalData);
                                    onChangeHandler && onChangeHandler(finalData);
                                }}
                                schema={formsData[currentTileIndex].schema}
                                uiSchema={formsData[currentTileIndex].uiSchemas}
                                submitButton={{
                                    ...formsData[currentTileIndex].submitButton,
                                    onClick: async (formData) => {
                                        let finalData = {
                                            ...submittedData,
                                            [currentTileIndex]: {
                                                ...formData,
                                            },
                                        };

                                        setSubmittedData(finalData);

                                        return formsData[currentTileIndex].submitButton.onClick(
                                            finalData[currentTileIndex],
                                            formData,
                                        );
                                    },
                                }}
                                onSuccessCallback={async (responseData, formData, isSubAgent) => {
                                    isSubAgent
                                        ? dispatch(
                                              setToast(toastMessages.passwordSetSubAgentMessage),
                                          )
                                        : dispatch(setToast(toastMessages.newPartnerPasswordSet));

                                    const gotoNextStep = () =>
                                        dispatch(
                                            formData.id
                                                ? goToRoute(
                                                      routePaths.SUB_AGENT_UPDATE,
                                                      {},
                                                      {
                                                          step: 'student-list',
                                                          id: formData.id,
                                                          page: '1',
                                                          per_page: '10',
                                                          q: '',
                                                      },
                                                  )
                                                : goToRoute(
                                                      routePaths.SUB_AGENT_CREATE,
                                                      {},
                                                      {
                                                          step: 'student-list',
                                                          page: '1',
                                                          per_page: '10',
                                                          q: '',
                                                      },
                                                  ),
                                        );

                                    !isSubAgent
                                        ? setTimeout(gotoNextStep, 3000)
                                        : setTimeout(handleLogout, 3000);
                                }}
                            />
                        );
                    },
                },
                {
                    title: 'Student List',
                    mainContent: (props) => {
                        const {
                            submittedData,
                            currentTileIndex,
                            formsData,
                            defaultData,
                            setSubmittedData,
                        } = props;
                        return (
                            <StudentList
                                defaultData={
                                    submittedData ? submittedData[currentTileIndex] : defaultData
                                }
                                submitButton={{
                                    ...formsData[currentTileIndex].submitButton,
                                    onClick: async (formData) => {
                                        let finalData = {
                                            ...submittedData,
                                            [currentTileIndex]: {
                                                ...formData,
                                            },
                                        };

                                        setSubmittedData(finalData);
                                        return formsData[currentTileIndex].submitButton.onClick(
                                            finalData[currentTileIndex],
                                            formData,
                                        );
                                    },
                                }}
                                onSuccessCallback={(responseData, formData) => {
                                    dispatch(setToast(toastMessages.studentAssignToSubAgent));
                                    dispatch(
                                        goToRoute(
                                            routePaths.SUB_AGENT_UPDATE,
                                            {},
                                            {
                                                step: 'personal-detail',
                                                id: formData.id,
                                            },
                                        ),
                                    );
                                    dispatch(resetStudentDataInStore());
                                    getSubAgentProfilesAssigned(formData.id).then((res) => {
                                        if (res.data.length > 0) {
                                            let studentList = res.data.map(
                                                ({ _id, first_name, last_name }) => {
                                                    return { _id, first_name, last_name };
                                                },
                                            );
                                            dispatch(addAssignedStudentsToStore(studentList));
                                        } else {
                                            dispatch(addAssignedStudentsToStore([]));
                                        }
                                    });
                                }}
                            />
                        );
                    },
                },
            ],
        },
    };
    return props;
};

export const getMainContentPropsForSubAgentFromIndex = (tileIndex, allProps) => {
    const props = {};
    props.submittedData = allProps.submittedData;
    props.onChangeHandler = allProps.onChangeHandler;
    props.setDirtyFormData = allProps.setDirtyFormData;
    props.currentTileIndex = tileIndex;
    props.defaultData = allProps.defaultData;
    props.formsData = allProps.formsData;
    props.setSubmittedData = allProps.setSubmittedData;
    return props;
};

export const getTileContentPropsForSubAgentFromIndex = (tileIndex) => {
    const props = {};
    props.currentFormIndex = tileIndex;
    props.tileIndex = tileIndex;
    return props;
};

export const serializeSubagentPersonalInfo = (subagentInfo) => {
    const lodashGet = (key, defaultValue) => get(subagentInfo, key, defaultValue);

    let data = {};

    if (subagentInfo) {
        data = {
            id: lodashGet('id', ''),
            first_name: lodashGet('first_name', ''),
            last_name: lodashGet('last_name', ''),
            email: lodashGet('email', ''),
            mobile: lodashGet('mobile', null),
            whatsapp_number: lodashGet('whatsapp_number', ''),
            step: lodashGet('step', ''),
            is_whatsapp_same: lodashGet('is_whatsapp_same', false),
        };
    }
    return data;
};

export const filterMsg = {
    default: 'Please click on Create New User button',
    filters: 'No entry found! Please remove a few filters',
};

export const subAgentNoStudentsMsg = (classes) => {
    return (
        <Box
            className={classes.container}
            display="flex"
            alignItems="center"
            justifyContent={'center'}>
            <Typography variant="body2">
                {'No students assigned, please connect with your Referral Partner'}
            </Typography>
        </Box>
    );
};
