import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// API
import { changeProfileStatus } from '@/api';

// comps
import { FormModal, getIcon } from 'azent-storybook/dist';

// props
import { decodeSdsChoice } from '@/props-constants/student/student-profile';
import { getProfileStatusSchema } from '@/props-constants/student-schema/student-status-schema/schema';
import { getProfileStatusUiSchema } from '@/props-constants/student-schema/student-status-schema/ui-schema';

// utils
import { deserializeStudent } from '@/utils/deserializers/student-deserializer';
import { toastMessages } from '@/utils/toastMessages';

// styles
import { useStyles } from './styles';

// Actions
import { addStudentToStore } from '@/store/slices/studentSlice';
import { setToast } from '@/store/slices/toastSlice';

let ProfileStatus = function ({ profile, open, handleClose, uploadConfig }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    let getDefaultData = (profile) => ({
        profile_status:
            profile.profile_status.length > 0
                ? decodeSdsChoice(profile.profile_status.slice(-1).pop().status)
                : '',
        comment: '',
    });

    const onStatusUpdateSuccess = (profileData) => {
        dispatch(addStudentToStore(deserializeStudent(profileData)));
        dispatch(setToast(toastMessages.successMessage));
    };

    return (
        <FormModal
            open={open}
            onSuccessCallback={onStatusUpdateSuccess}
            modalTitle={{
                title: 'Update Status',
            }}
            schema={getProfileStatusSchema()}
            defaultData={getDefaultData(profile)}
            isB2bFormModal={true}
            uploadConfig={uploadConfig}
            uiSchemas={getProfileStatusUiSchema()}
            submitButton={{
                onClick: (data) => changeProfileStatus(profile.id, data),
                fullWidth: false,
                label: 'Update Status',
                variant: 'contained',
                size: 'medium',
                color: 'success',
            }}
            showCloseButton={true}
            handleClose={handleClose}
            modalTitleStartIcon={getIcon('person', 'primary', 'medium', classes.modalTitleIcon)}
            classes={{
                cModalTitleContainer: classes.cModalTitleContainer,
                cModalTitle: classes.cModalTitle,
            }}
            showPreview={false}
        />
    );
};

ProfileStatus.propTypes = {
    profile: PropTypes.object,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    uploadConfig: PropTypes.object,
};

export default ProfileStatus;
