import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme) => ({
    icon: {
        fontSize: '96px',
    },
    root: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        background: theme.palette.primary.main,
        color: 'white',
    },
}));
