// api
import { getProfiles, getUniversities, universitySearch, getProgramsOfUniversity } from '@/api';

// prop-constants
import { displayChecklist, displayIntakeType, displayProgramFees } from '../';

export default function getApplicationUiSchema(
    universityId,
    profileData,
    disableFields,
    universityLocation,
    programFee,
    intakeType,
) {
    let uiSchema = {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/profile',
                        options: {
                            readOnly: Boolean(profileData),
                            component: 'search',
                            searchApi: getProfiles,
                            searchApiArgs: [{ q: '{{search}}' }],
                            valueKey: '_id',
                            labelExp: '{{first_name}} {{last_name}}, {{mobile}}',
                        },
                        label: 'Student',
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/university',
                        options: {
                            component: 'search',
                            searchApi: universitySearch,
                            searchApiArgs: [{ q: '{{search}}' }],
                            indexApi: getUniversities,
                            indexApiArgs: [{ ids: '{{ids}}' }],
                            valueKey: '_id',
                            labelKey: 'name',
                            readOnly: disableFields,
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/intake/properties/month',
                        options: {
                            readOnly: disableFields,
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/intake/properties/year',
                        options: {
                            readOnly: disableFields,
                        },
                    },
                ],
            },
        ],
    };
    if (universityId) {
        uiSchema.elements.splice(2, 0, {
            type: 'HorizontalLayout',
            elements: [
                {
                    type: 'Control',
                    scope: '#/properties/program',
                    options: {
                        component: 'search',
                        searchApi: getProgramsOfUniversity,
                        searchApiArgs: [universityId, { q: '{{search}}' }],
                        valueKey: '_id',
                        labelKey: 'name',
                        readOnly: disableFields,
                    },
                },
                {
                    type: 'Control',
                    scope: '#/properties/preference',
                    label: 'Application Priority (Once the priority is set it cannot be edited)',
                },
            ],
        });
        if (universityLocation) {
            uiSchema.elements.push({
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/required_docs',
                        label: '',
                        options: {
                            component: 'label',
                            item: () => displayChecklist(universityLocation),
                        },
                    },
                ],
                rule: {
                    effect: 'SHOW',
                    condition: {
                        scope: '#/properties/university',
                        schema: {},
                    },
                },
            });
        }
        let temp;
        if (programFee) {
            temp = {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/program_fees',
                        label: '',
                        options: {
                            component: 'label',
                            item: () => displayProgramFees(programFee),
                        },
                    },
                ],
                rule: {
                    effect: 'SHOW',
                    condition: {
                        scope: '#/properties/program',
                        schema: {},
                    },
                },
            };

            uiSchema.elements.push(temp);
        }

        if (intakeType) {
            if (programFee) {
                temp.elements.push({
                    type: 'HorizontalLayout',
                    elements: [
                        {
                            type: 'Control',
                            scope: '#/properties/intake_type',
                            label: '',
                            options: {
                                component: 'label',
                                item: () => displayIntakeType(intakeType),
                            },
                        },
                    ],
                    rule: {
                        effect: 'SHOW',
                        condition: {
                            scope: '#/properties/intake',
                            schema: {},
                        },
                    },
                });
            } else {
                temp = {
                    type: 'HorizontalLayout',
                    elements: [
                        {
                            type: 'Control',
                            scope: '#/properties/intake_type',
                            label: '',
                            options: {
                                component: 'label',
                                item: () => displayIntakeType(intakeType),
                            },
                        },
                    ],
                    rule: {
                        effect: 'SHOW',
                        condition: {
                            scope: '#/properties/intake',
                            schema: {},
                        },
                    },
                };

                uiSchema.elements.push(temp);
            }
        }
    }
    return uiSchema;
}
