import React from 'react';
import PropTypes from 'prop-types';

//mui
import { Avatar, Typography, Box } from '@mui/material';

// Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getIcon } from 'azent-storybook/dist/utils/get-icon';

//style
import { useStyles } from './style';

function PackageHead({ name, isFilter, goBack }) {
    const classes = useStyles({ goBack, isFilter });
    const isDesktop = true;

    return (
        <div className={classes.topContainer}>
            {isDesktop ? (
                <>
                    <Box className={classes.desktopContainer} onClick={goBack}>
                        {!isFilter ? (
                            <ArrowBackIcon className={classes.backArrowIcon} onClick={goBack} />
                        ) : (
                            <Typography className={classes.dotIcon}></Typography>
                        )}
                        <Typography className={classes.headName}>{name}</Typography>
                    </Box>
                </>
            ) : (
                <>
                    {goBack && <ArrowBackIcon className={classes.backArrowIcon} onClick={goBack} />}

                    {isFilter ? (
                        <Avatar className={classes.filterIcon}>{getIcon('filter-list')}</Avatar>
                    ) : (
                        <Typography> </Typography>
                    )}
                </>
            )}
        </div>
    );
}

PackageHead.propTypes = {
    name: PropTypes.string,
    isFilter: PropTypes.bool,
    goBack: PropTypes.func,
};

export default PackageHead;
