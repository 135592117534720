import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

// mui
import { Box } from '@mui/material';

// api
import { getProfile } from '@/api';

// utils
import { deserializeStudent } from '@/utils/deserializers/student-deserializer';
import { getStudentProfileProps } from '@/props-constants/student/student-profile';
import { toastMessages } from '@/utils/toastMessages';

// actions
import { addStudentToStore } from '@/store/slices/studentSlice';
import { setToast } from '@/store/slices/toastSlice';
import { setRefreshData, setDisplayTime } from '@/store/slices/refreshSlice';

// comps
import StudentHeader from './partials/StudentHeader';
import StudentDetails from './partials/StudentDetails';
import Loader from '@/components/shared/Loader';
import { CreateApplication } from '@/components/shared/CreateApplication';
import { getServices } from '@/api/ancillary';
import moment from 'moment';
import { servicesCmsGetAndStore } from '@/store/actions/serviceCmsAction';

export default function StudentProfile({ match }) {
    const dispatch = useDispatch();

    const id = match.params.id;
    const path = match.params.path;
    const aid = match.params.aid;
    const sid = match.params.aid;

    const [services, setServices] = useState([]);
    const studentData = useSelector((state) => state.student.data[id]);

    const serviceCard = useSelector((state) => state.serviceCards);
    const refreshData = useSelector((state) => state.refreshPlatformData.refreshData);

    const [createApplicationModalOpen, setCreateApplicationModalOpen] = useState(false);
    const [numberOfApplications, setNumberOfApplications] = React.useState(0);

    const getStudentProfileData = (id, refreshData) => {
        getProfile(id)
            .then((res) => {
                dispatch(addStudentToStore(deserializeStudent(res.data)));
                dispatch(setDisplayTime(new Date()));
                if (refreshData) {
                    dispatch(setRefreshData(false));
                }
            })
            .catch(() => dispatch(setToast(toastMessages.backendIssue)));
    };

    React.useEffect(() => {
        if (isEmpty(studentData)) {
            getStudentProfileData(id);
        }
        if (id) {
            getService(id);
        }
    }, [id]);

    React.useEffect(() => {
        if (refreshData) {
            getStudentProfileData(id, refreshData);
        }
    }, [refreshData]);

    React.useEffect(() => {
        studentData &&
            studentData.applications &&
            setNumberOfApplications(studentData.applications.length);
    }, [studentData]);

    const { studentHeaderProps, studentDetailProps } = React.useMemo(
        () => getStudentProfileProps(studentData, dispatch),
        [studentData],
    );
    let servicesList = [];
    function getService(id) {
        const DIFF_DAYS = 4;
        getServices(id).then((res) => {
            const serviceList = res.data.subscribed_services;
            const uniqueIdList = serviceList.map((item) => item.cms_service_id);
            const data = uniqueIdList.reduce((acc, id) => {
                const uniqueServiceList = serviceList.filter(
                    (service) => service.cms_service_id == id,
                );
                const sortedArray = uniqueServiceList.sort(
                    (a, b) => moment(a.data).format('YYYYMMDD') - moment(b.date).format('YYYYMMDD'),
                );
                if (sortedArray.length) {
                    const data = { ...sortedArray[sortedArray.length - 1] };
                    data.isEnquireEnabled =
                        moment().diff(moment(data.created_at), 'days') > DIFF_DAYS;
                    acc[id] = data;
                    return acc;
                }
            }, []);
            data.map((item) => {
                servicesList = [...servicesList, { ...item }];
            });
            setServices(servicesList);
        });
    }

    useEffect(() => {
        if (serviceCard && serviceCard.data && serviceCard.data.length === null) {
            dispatch(servicesCmsGetAndStore());
        }
    }, [serviceCard]);

    return (
        <Box>
            <StudentHeader
                {...studentHeaderProps}
                id={id}
                setCreateApplicationModalOpen={setCreateApplicationModalOpen}
                applicationNumber={numberOfApplications}
            />
            {studentData ? (
                <StudentDetails
                    {...studentDetailProps}
                    studentId={id}
                    applicationId={aid}
                    path={path}
                    profileData={studentData}
                    serviceId={sid}
                    services={services}
                />
            ) : (
                <Box>
                    <Loader />
                </Box>
            )}
            {studentData && createApplicationModalOpen && (
                <CreateApplication
                    profileData={studentData}
                    isModalOpen={createApplicationModalOpen}
                    handleClose={() => setCreateApplicationModalOpen(false)}
                />
            )}
        </Box>
    );
}

StudentProfile.propTypes = {
    match: PropTypes.object.isRequired,
};

StudentProfile.defaultProps = {};
