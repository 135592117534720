import React from 'react';
import routePaths from '@/utils/route-paths';

// comps
import ListApplication from '@/components/modules/Application/ListApplication';
import { AuthorisedRoute } from '@/rbac';

export const applicationRoutes = [
    {
        routePath: routePaths.APPLICATION,
        component: (props) => (
            <AuthorisedRoute feature="APPLICATION_LIST">
                <ListApplication {...props} />
            </AuthorisedRoute>
        ),
    },
];
