import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'unset!important',
        border: '1px solid #C4C4C4',
        borderRadius: `12px!important`,
        minHeight: theme.spacing(50),
        [theme.breakpoints.down('sm')]: {
            border: 'unset',
            borderRadius: 'unset',
            minHeight: 'unset',
        },
    },
    content: {
        color: '#000000',
        fontSize: '14px',
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
            padding: '0px!important',
        },
    },
    divider: {
        height: '1px',
        background: theme.palette.common.black,
        margin: theme.spacing(1.3, 0),
    },
}));