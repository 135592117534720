import instance from './instances/auth';
import partnerInstance from './instances/university-service';
import loginInstance from './instances/login';

export function getPartner(id) {
    return instance.get(`/v1/partners/${id}`);
}

export function authenticate() {
    return instance.get(`/v1/auth`);
}

export function getPSAUser(query) {
    let search = new URLSearchParams(query);
    return new Promise((resolve, reject) => {
        instance
            .get(`/v1/auth/users?${search.toString()}&role=PSA`)
            .then((response) => {
                resolve({
                    data: response.data.data.users,
                });
            })
            .catch((e) => {
                reject(e);
            });
    });
}

export function getPartners(query) {
    let search = new URLSearchParams(query);
    return new Promise((resolve, reject) => {
        instance
            .get(`/v1/partners?${search.toString()}`)
            .then((response) => {
                resolve({
                    data: response.data.data.partners || [],
                });
            })
            .catch((e) => {
                reject(e);
            });
    });
}

export function getFilteredPartners(query) {
    return new Promise((resolve, reject) => {
        instance
            .get(`/v1/partners`)
            .then((response) => {
                const querys = query.ids.split(',').map((item) => Number(item));
                resolve({
                    data:
                        (response.data.data.partners &&
                            response.data.data.partners.filter((item) =>
                                querys.includes(item.id),
                            )) ||
                        [],
                });
            })
            .catch((e) => {
                reject(e);
            });
    });
}

export function getOwners(query) {
    let search = new URLSearchParams(query);
    return new Promise((resolve, reject) => {
        instance
            .get(`/v1/auth/users?${search.toString()}&role=B2B_ADMIN`)
            .then((response) => {
                resolve({
                    data: response.data.data.users,
                });
            })
            .catch((e) => {
                reject(e);
            });
    });
}

export function getFilteredOwners(query) {
    return new Promise((resolve, reject) => {
        instance
            .get(`/v1/auth/users?q=&role=B2B_ADMIN`)
            .then((response) => {
                const querys = query.ids.split(',').map((item) => Number(item));
                resolve({
                    data: response.data.data.counsellors.filter((item) => querys.includes(item.id)),
                });
            })
            .catch((e) => {
                reject(e);
            });
    });
}

export const resetPasswordApi = (user_id, old_password, new_password) => {
    return instance.put(`/v1/auth/${String(user_id)}/reset`, {
        user_id,
        old_password,
        new_password,
    });
};

export const setPasswordApi = (user_id, password, access_token) => {
    return loginInstance.put(`/v1/auth/${String(user_id)}/password`, {
        user_id,
        password,
        access_token,
    });
};

export const updateUserApi = (user_id, updates) => {
    return instance.put(`/${String(user_id)}`, updates);
};

export const createUserApi = (userData) => {
    return loginInstance.post('/v1/partners/register', userData);
};

export const ssoLoginApi = (ssoLoginData) => {
    return instance.post('/v1/auth/login/sso', ssoLoginData);
};

export const loginViaOtpApi = (credentials) => {
    return loginInstance.post('/v1/auth/login/otp', credentials);
};

export const loginViaPasswordApi = (credentials) => {
    return loginInstance.post('/v1/auth/login', credentials);
};

export const sendLoginOtpApi = (credentials) => {
    return loginInstance.post('/v1/auth/login/request_otp', credentials);
};
export const sendPasswordResetEmailApi = (email) => {
    return instance.post('/v1/auth/password-reset-email', { email });
};

//for admin session login in crm
export function counsellorLogin(data) {
    return partnerInstance.post(`/v1/counsellors/auth/login`, data);
}

//set password from query params reset_token
export function resetPasswordPartnerApi(data) {
    return partnerInstance.post(`/v1/auth/reset-password`, data);
}
