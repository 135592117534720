import React from 'react';
import PropTypes from 'prop-types';

// mui
import { Box, Typography } from '@mui/material';

// comps
import { ActionBox } from 'azent-storybook/dist';
import DocumentList from '@/components/shared/DocumentList';

// styles
import { useStyles } from '../style';

// utils
import { getReferenceDocs, getCommentDocs } from '@/props-constants/student/student-profile';

export default function DocumentsTab({ profileStatus, comments }) {
    const classes = useStyles();

    return (
        <Box className={classes.documentListContainer}>
            <Box mt={2}>
                <Typography variant="h6">Reference/Supporting Documents</Typography>
                {profileStatus && profileStatus.length > 0 ? (
                    <DocumentList documents={getReferenceDocs(profileStatus)} />
                ) : (
                    <ActionBox
                        subtitle={{
                            text: 'No supporting documents found',
                            variant: 'subtitle1',
                        }}
                        raised={false}
                        elevation={0}
                    ></ActionBox>
                )}
            </Box>

            <Box mt={2}>
                <Typography variant="h6">Documents from comments</Typography>
                {comments && comments.length > 0 ? (
                    <DocumentList documents={getCommentDocs(comments)} />
                ) : (
                    <ActionBox
                        subtitle={{
                            text: 'No documents from comments found',
                            variant: 'subtitle1',
                        }}
                        raised={false}
                        elevation={0}
                    ></ActionBox>
                )}
            </Box>
        </Box>
    );
}

DocumentsTab.propTypes = {
    profileStatus: PropTypes.array.isRequired,
    comments: PropTypes.array.isRequired,
};
