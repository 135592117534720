import { createSlice } from '@reduxjs/toolkit';

const initialState = { dialog: false };

const universalDialogSlice = createSlice({
    name: 'universalDialog',
    initialState,
    reducers: {
        setUniversalDialog(state, action) {
            state.dialog = action.payload;
        },
    },
});

export const { setUniversalDialog } = universalDialogSlice.actions;
export default universalDialogSlice.reducer;
