import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    customTab: {
        fontSize: theme.spacing(2),
        fontWeight: 500,
        '&:active': {
            color: theme.palette.primary.main,
        },
        '&.Mui-selected': {
            color: theme.palette.primary.main,
        },
    },
    buttonsContainer: {
        display: 'flex',
        padding: theme.spacing(2, 2, 1),
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        color: theme.palette.secondary.main,
    },
    pageTitle: {
        marginLeft: theme.spacing(2),
        fontWeight: 600,
    },
    applyButton: {
        fontWeight: '600',
        borderRadius: '10px',
        color: 'white',
        backgroundColor: '#00c853',
        '&:hover': {
            backgroundColor: '#3D8944',
        },
    },
}));
