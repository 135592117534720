import React from 'react';
import PropTypes from 'prop-types';

const ChecklistTickFilled = ({ size, theme }) => {
    const config = {
        size,
    };

    if (theme === 'primary') {
        config.fill = '#F16621';
    } else if (theme === 'secondary') {
        config.fill = '#ffffffcc';
    }

    return (
        <svg
            width={config.size}
            height={config.size}
            viewBox="0 0 25 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 0H2.44444C1.1 0 0 1.1 0 2.44444V19.5556C0 20.9 1.1 22 2.44444 22H22C23.3444 22 24.4444 20.9 24.4444 19.5556V2.44444C24.4444 1.1 23.3444 0 22 0ZM9.77778 17.1111H3.66667V14.6667H9.77778V17.1111ZM9.77778 12.2222H3.66667V9.77778H9.77778V12.2222ZM9.77778 7.33333H3.66667V4.88889H9.77778V7.33333ZM15.6689 14.6667L12.2222 11.1956L13.9456 9.47222L15.6689 11.2078L19.5433 7.33333L21.2789 9.06889L15.6689 14.6667Z"
                fill={config.fill}
            />
        </svg>
    );
};

ChecklistTickFilled.propTypes = {
    theme: PropTypes.oneOf(['primary', 'secondary']),
    size: PropTypes.string,
};

ChecklistTickFilled.defaultProps = {
    theme: 'primary',
    size: '25',
};

export default ChecklistTickFilled;
