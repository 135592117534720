import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

//component
import { ApplicationHeader } from './ApplicationHeader';

//mui
import { Box, Grid, Typography, Divider } from '@mui/material';

//utils
import { deserializeStudent } from '@/utils/deserializers/student-deserializer';
import { toastMessages } from '@/utils/toastMessages';
import { isPartner, isSubAgent } from '@/rbac';

// components
import { Tabs, History } from 'azent-storybook/dist';
import StudentComment from '@/components/shared/Comment';
import DocumentsTab from './DocumentsTab';

// schema
import { schema as commentsSchema } from '@/props-constants/student-schema/student-comments-schemas/schema';
import { getCommentsUiSchema } from '@/props-constants/student-schema/student-comments-schemas/ui-schema';

// constants
import constants from '@/constants';

// api
import { signRequest, saveApplicationComments, getProfile } from '@/api';
import { getHistoryProps } from '@/props-constants/application/application-profile';

// actions
import { addStudentToStore } from '@/store/slices/studentSlice';
import { setToast } from '@/store/slices/toastSlice';
import { APPLICATION_DOCUMENTS_LABEL, formatDocumentsToSend } from '@/utils/documents';
import { get } from 'lodash';

export const ApplicationDetails = ({
    classes,
    appId,
    data,
    setComments,
    comments,
    handleModalOpen,
}) => {
    const dispatch = useDispatch();
    const studentData = useSelector((state) => state.student.data[data.profile_id]);

    const [tabValue, setTabValue] = useState(0);
    const [addAttachment, setAddAttachment] = useState(false);
    const [isPrivateComment, setPrivateComment] = useState(false);

    const onCommentSave = (formData) => {
        const files = get(formData, 'files', []);
        const documents = get(studentData, 'application_documents', []).concat(files);

        const formattedDocuments = formatDocumentsToSend(
            documents,
            studentData,
            APPLICATION_DOCUMENTS_LABEL,
        );
        const newFormData = {
            ...formData,
            private: isPrivateComment ? true : false,
            files: formattedDocuments.slice(documents.length - files.length, documents.length),
        };

        if (newFormData.comment && appId) {
            return saveApplicationComments(appId, newFormData);
        } else {
            dispatch(setToast(toastMessages.backendIssue));
        }
    };

    const applicationData = useMemo(() => {
        let data = null;
        if (studentData && studentData.applications) {
            data = studentData.applications.find((application) => application._id == appId);
        }
        return data;
    }, [appId, studentData]);

    useEffect(() => {
        if (applicationData) setComments(applicationData.comments);
    }, [applicationData]);

    const onCommentSaveSuccess = (data, setData) => {
        setComments(data.comments);
        getProfile(data.profile_id).then((res) => {
            dispatch(addStudentToStore(deserializeStudent(res.data)));
        });
        setAddAttachment(false);
        setData({});
        setPrivateComment(false);
    };

    const fileUploadConfig = {
        s3Postfix: data && `${constants.envName}/${data.profile_id}`,
        awsConfig: constants.awsConfig,
        selfAuthApiCall: signRequest,
    };

    return (
        <Box className={classes.BoxContainer}>
            {data && (
                <>
                    <ApplicationHeader
                        classes={classes}
                        handleModalOpen={handleModalOpen}
                        data={data}
                    />
                    <Divider variant="middle" />
                </>
            )}
            <Grid container item>
                <Grid item xs={9}>
                    {data && (
                        <Tabs
                            value={tabValue}
                            setValue={setTabValue}
                            tabsProps={{
                                centered: false,
                                variant: 'standard',
                            }}
                            classes={{
                                tab: classes.customTab,
                                contentContainer: classes.customContentContainer,
                            }}
                            tabList={[
                                {
                                    label: 'Comments',
                                    content: () => (
                                        <StudentComment
                                            enableAttachement={true}
                                            addAttachment={addAttachment}
                                            setAddAttachment={setAddAttachment}
                                            comments={comments ? comments : data.comments}
                                            addComment={{
                                                schema: commentsSchema,
                                                commentsUiSchema: getCommentsUiSchema({
                                                    addAttachment: addAttachment,
                                                }),
                                                defaultData: {},
                                                uploadConfig: fileUploadConfig,
                                                submitButton: {
                                                    label: 'Save Comment',
                                                    color: 'success',
                                                    onClick: onCommentSave,
                                                    fullWidth: false,
                                                    className: classes.saveCommentButton,
                                                },
                                                onSuccessCallback: onCommentSaveSuccess,
                                                setPrivateComment: setPrivateComment,
                                                isPrivateComment: isPrivateComment,
                                            }}
                                            enablePrivateComment={!isPartner() && !isSubAgent()}
                                        />
                                    ),
                                },
                                {
                                    label: 'Documents',
                                    content: () => (
                                        <DocumentsTab
                                            comments={comments ? comments : data.comments}
                                            classes={classes}
                                            history={data.documents}
                                        />
                                    ),
                                },
                            ]}
                        />
                    )}
                </Grid>
                {data && (
                    <Grid item xs={3}>
                        <Typography variant="h6" className={classes.historyTitle}>
                            Application History
                        </Typography>
                        <Box className={classes.historyContainer}>
                            <History
                                styles={classes.timelineDotColor}
                                history={getHistoryProps(
                                    data.application_status.history,
                                    data.application_payment_link,
                                )}
                            />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

ApplicationDetails.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.any,
    handleModalOpen: PropTypes.func,
    appId: PropTypes.string,
    setComments: PropTypes.func,
    comments: PropTypes.array,
    crmStudentId: PropTypes.string,
    studentFullName: PropTypes.string,
};
