//mui styles
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  contentContainer: {
    minHeight: theme.spacing(40),
    padding: theme.spacing(0),
  },
  tabs: {
    minWidth: "unset",
    display: "contents",
    "& span": {
      alignItems: "flex-start",
      fontSize: theme.spacing(1.75),
      fontWeight: "500",
    },
  },
}));
