import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    loaderContainer: {
        margin: theme.spacing(5, 'auto'),
    },
    studentProfileBar: {
        width: '100%',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        '&>:first-child': {
            margin: theme.spacing(0, 1, 0, 0),
        },
    },
}));
