import { isArray, startCase, snakeCase } from 'lodash';

const ID_DOC = 'id_doc';
const ADDRESS_DOC = 'address_doc';
const GST = 'gst';
const REGISTRATION_CERTIFICATE = 'registration_certificate';
const BANK_ACCOUNT = 'bank_account';
const GOVT_LICENSE = 'govt_license';
const OTHER_DOCS = 'other_docs';

const GENERAL_DOCUMENTS_KEYS = [
    ID_DOC,
    ADDRESS_DOC,
    GST,
    REGISTRATION_CERTIFICATE,
    BANK_ACCOUNT,
    OTHER_DOCS,
    GOVT_LICENSE,
];

const COMMENTS_DOCUMENT_KEYS = [
    `WORK_EXPERIENCE_CERTIFICATE`,
    `SOP_(UNIVERSITY_AND_PROGRAM_SPECIFIC)`,
    `BANK_STATEMENT_(LAST_36'_MONTHS)`,
    `SALARY_SLIP_(LAST_36'_MONTHS)`,
    `COVID_VACCINATION_CERTIFICATE`,
    `DIGILOCKER_10TH_CERTIFICATE/MARKSHEET`,
    `DIGILOCKER_12TH_CERTIFICATE/MARKSHEET`,
    `AFFIDAVIT_OF_SUPPORT/FINANCIAL_AFFIDAVIT`,
    `TRANSCRIPTS_EVALUATION`,
    `MOI`,
    `APPLICATION_FORM`,
    `GAP_JUSTIFICATION`,
    `CONSOLIDATED_GRADUATION_DEGREE_MARKSHEET_OR_TRANSCRIPT`,
    `CONSOLIDATED_MASTERS_DEGREE_MARKSHEET_OR_TRANSCRIPT`,
    `OTHERS`,
];

const APPLICATION_DOCUMENT_KEYS = [
    `University & Course Specific SOP`,
    `University Application Form`,
    `Authority Letter / Consent Form`,
    `Change of Agent Authorisation Letter`,
    `Others`,
    `Visa Approval Letter / Copy`,
    `Offer Letter`,
    `Deposit Slips`,
    `Application Updates`,
];

const GENERAL_DOCUMENTS_LABELS = {
    id_doc: `Company's Pan card`,
    address_doc: `Director's Aadhar card`,
    gst: 'GST certificate',
    registration_certificate: 'Company Registration',
    bank_account: 'Cancelled cheque',
    govt_license: 'Govt registration license for study overseas business',
    other_docs: 'Other Documents',
};

export const getDocumentCategoriesMapping = () => {
    return GENERAL_DOCUMENTS_KEYS.map((item) => ({
        label: GENERAL_DOCUMENTS_LABELS[item],
        value: item,
    }));
};

export const getDocumentsDeserializedByCategory = (documents) => {
    let docs = {};
    if (documents) {
        docs = documents.reduce((acc, file) => {
            if (GENERAL_DOCUMENTS_KEYS.includes(file.category)) {
                if (file.category === OTHER_DOCS) {
                    acc[OTHER_DOCS] && acc[OTHER_DOCS].length
                        ? acc[OTHER_DOCS].push(file)
                        : (acc[OTHER_DOCS] = [file]);
                } else {
                    acc[file.category] = file;
                }
            }
            return acc;
        }, {});
    }
    return docs;
};

export const deserializeDocuments = (documents) => {
    const docs = [];
    if (documents) {
        Object.keys(documents).map((item) => {
            if (item === OTHER_DOCS && isArray(documents[item])) {
                documents[item].map((document) => {
                    docs.push(document);
                });
            } else {
                docs.push(documents[item]);
            }
        });
    }
    return docs;
};

export const getDocumentTaggingForComments = (type = 'applications') => {
    if (type === 'comments') {
        return COMMENTS_DOCUMENT_KEYS.map((item) => ({
            label: startCase(item),
            value: snakeCase(item),
        }));
    } else if (type === 'partners') {
        return GENERAL_DOCUMENTS_KEYS.map((item) => ({
            label: GENERAL_DOCUMENTS_LABELS[item],
            value: item,
        }));
    }
    return APPLICATION_DOCUMENT_KEYS.map((item) => ({
        label: item,
        value: item,
    }));
};
