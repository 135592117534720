import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

// material
import { Typography, Box, CircularProgress, Grid, useMediaQuery, useTheme } from '@mui/material';

// components
import { Button, Alert, FormComponent } from 'azent-storybook/dist';

// styles
import { useStyles } from './style';

//utils
import { toastMessages } from '@/utils/toastMessages';

//actions
import { setToast } from '@/store/slices/toastSlice';

// api
import { getPartnerProfile } from '@/api';

// rbac
import { isPartner } from '@/rbac';
import { setRefreshData } from '@/store/slices/refreshSlice';

// constants
let formErrors = null;
let timeout = null;

export default function ProfileEditFormBox({
    defaultData,
    schema,
    uiSchemas,
    submitButton,
    styleProps,
    formConstants,
    uploadConfig,
    onChangeHandler,
    onSuccessCallback,
    previousButton,
    disabledSuccessMessage,
    ...props
}) {
    const classes = useStyles({ ...styleProps, previousButton });
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const dispatch = useDispatch();

    const [data, setData] = useState(defaultData || {});
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [displayErrors, setDisplayErrors] = useState(false);
    const referralPartnerId = defaultData?.partner?.id || null;

    const uiSchema = isDesktop ? uiSchemas.largeScreenUiSchema : uiSchemas.smallScreenUiSchema;
    const initialRender = useRef(true);

    const profileId = get(props, 'profileData.id', '');

    const onChange = ({ data, errors }) => {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            let partnerId = get(data, 'partner.id', null);
            if (partnerId != referralPartnerId) data.sub_agent = null;
            onChangeHandler(data, formConstants);

            setData(data);
            formErrors = errors;
        }, 400);
    };

    const onAutoSave = async (finalData, action) => {
        try {
            if (!get(data, 'isAutoSave', false)) {
                return;
            }

            let response = null;
            const newData = { ...data, required_documents: finalData };

            response = await submitButton.onAutoSave(newData, formConstants);
            dispatch(setRefreshData(true));
            if (response) {
                if (response.data) {
                    if (action === 'verify') {
                        setSuccessMessage('Document verified Successfully');
                    } else {
                        setSuccessMessage('Document uploaded Successfully');
                    }
                } else {
                    setServerError(response.data.message);
                }
            }
        } catch (err) {
            if (get(err, 'err.data.message')) {
                setServerError(get(err, 'err.data.message'));
            }
            if (get(err, 'err.response.data.error.message')) {
                setErrorMsg(get(err, 'err.response.data.error.message'));
            }
        }
    };

    const onVerifyDoc = async (file, approved) => {
        try {
            let response = null;

            let newData = {
                file_url: file.url,
                approved,
            };
            const applicationId = get(file, 'application_id', null);
            if (applicationId) {
                newData = {
                    ...newData,
                    source: 'application',
                    source_id: applicationId,
                };
            } else {
                newData = {
                    ...newData,
                    source: 'profile',
                    source_id: profileId,
                };
            }

            response = await submitButton.onVerifyDoc(newData);
            dispatch(setRefreshData(true));
            if (response) {
                if (response.data) {
                    if (response?.data?.approved) {
                        setSuccessMessage('Document verified Successfully');
                    } else {
                        setErrorMsg('Document rejected Successfully');
                    }
                } else {
                    setServerError(response.data.message);
                }
            }
        } catch (err) {
            if (get(err, 'err.data.message')) {
                setServerError(get(err, 'err.data.message'));
            }
            if (get(err, 'err.response.data.error.message')) {
                setErrorMsg(get(err, 'err.response.data.error.message'));
            }
        }
    };

    // Edit student profile validation
    function validateForm(formData) {
        if (formData && formData.step == 0) {
            if (formData.alternate_mobile && formData.mobile) {
                if (formData.alternate_mobile == formData.mobile) {
                    dispatch(setToast(toastMessages.mobileValidateMessage));
                    return false;
                } else return true;
            }
        } else return true;
    }

    const onClickSubmit = async () => {
        if (formErrors && formErrors.length) {
            setDisplayErrors(true);
            return;
        }
        setLoading(true);
        setServerError(null);
        try {
            let response = null;
            if (validateForm(data)) response = await submitButton.onClick(data, formConstants);

            if (response) {
                if (response.data) {
                    setSuccessMessage('Data Submitted');
                    await onSuccessCallback(response.data, data, formConstants);
                } else {
                    setServerError(response.data.message);
                }
            }
        } catch (err) {
            if (get(err, 'err.data.message')) {
                setServerError(get(err, 'err.data.message'));
            }
            if (get(err, 'err.response.data.error.message')) {
                setErrorMsg(get(err, 'err.response.data.error.message'));
            }
        } finally {
            setLoading(false);
            setDisplayErrors(false);
        }
    };

    const onCloseSuccessModalCallback = () => {
        setSuccessMessage(null);
    };

    const onCloseErrorModalCallback = () => {
        setErrorMsg(null);
    };

    React.useEffect(() => {
        setData(defaultData);
    }, [defaultData]);

    useEffect(() => {
        let poc = {};
        if (
            !isPartner() &&
            !initialRender.current &&
            data &&
            data.partner &&
            data.partner.user_id
        ) {
            (async () => {
                try {
                    let resp = await getPartnerProfile(data.partner.user_id);
                    if (resp && resp.data) {
                        poc = resp.data.point_of_contact;
                    }
                } catch (err) {
                    console.log('something went wrong');
                } finally {
                    setData({ ...data, point_of_contact: poc });
                }
            })();
        }
        initialRender.current = false;
    }, [data && data.partner && data.partner.user_id]);

    return (
        <>
            {!disabledSuccessMessage && successMessage && (
                <Alert
                    title={successMessage}
                    severity="success"
                    onCloseCallback={onCloseSuccessModalCallback}
                />
            )}
            {errorMsg && (
                <Alert
                    title={errorMsg}
                    severity="error"
                    onCloseCallback={onCloseErrorModalCallback}
                />
            )}
            <Box className={classes.root}>
                <Box className={classes.textContainer}>
                    {serverError && (
                        <Typography variant="subtitle1" color="error">
                            {serverError}
                        </Typography>
                    )}
                </Box>
                <Box>
                    <FormComponent
                        schema={schema}
                        uiSchema={uiSchema}
                        data={data}
                        onChange={onChange}
                        readOnlyForm={false}
                        showFormErrors={displayErrors}
                        uploadConfig={uploadConfig}
                        onAutoSave={onAutoSave}
                        onVerifyDoc={onVerifyDoc}
                        {...props}
                    />
                </Box>
            </Box>
            <Grid container className={classes.buttonsContainer}>
                {previousButton && (
                    <Grid item className={classes.previousButtonContainer}>
                        <Button
                            {...previousButton}
                            onClick={previousButton.onClick}
                            label={previousButton.label}
                            variant={previousButton.variant || 'contained'}
                            fullWidth={
                                previousButton.fullWidth !== undefined
                                    ? previousButton.fullWidth
                                    : isDesktop
                                    ? false
                                    : true
                            }
                            startIcon={previousButton.startIcon}
                            color={previousButton.color || 'default'}
                            size={submitButton.size || 'medium'}
                            className={`${classes.buttonWidth} ${classes.previousButton}`}
                        />
                    </Grid>
                )}
                <Grid item className={classes.submitButtonContainer}>
                    <Button
                        disabled={loading}
                        onClick={onClickSubmit}
                        label={loading ? <CircularProgress size={20} /> : submitButton.label}
                        variant={submitButton.variant || 'contained'}
                        fullWidth={
                            submitButton.fullWidth !== undefined
                                ? submitButton.fullWidth
                                : isDesktop
                                ? false
                                : true
                        }
                        endIcon={(!loading && submitButton.endIcon) || null}
                        color={submitButton.color || 'primary'}
                        size={submitButton.size || 'medium'}
                        className={`${classes.buttonWidth} ${classes.submitButton}`}
                    />
                </Grid>
            </Grid>
        </>
    );
}

ProfileEditFormBox.propTypes = {
    defaultData: PropTypes.object,
    schema: PropTypes.object.isRequired,
    uiSchemas: PropTypes.shape({
        smallScreenUiSchema: PropTypes.object.isRequired,
        largeScreenUiSchema: PropTypes.object.isRequired,
    }),
    formConstants: PropTypes.object,
    uploadConfig: PropTypes.object,
    submitButton: PropTypes.shape({
        label: PropTypes.string.isRequired,
        variant: PropTypes.oneOf(['outlined', 'contained', 'text']),
        fullWidth: PropTypes.bool,
        endIcon: PropTypes.node,
        onClick: PropTypes.func.isRequired,
        color: PropTypes.oneOf(['primary', 'secondary', 'inherit', 'default', 'success']),
        size: PropTypes.oneOf(['small', 'medium', 'large']),
        onAutoSave: PropTypes.func,
        onVerifyDoc: PropTypes.func,
    }),
    styleProps: PropTypes.shape({
        showBorder: PropTypes.bool,
        raised: PropTypes.bool,
        backgroundColor: PropTypes.string,
        borderRadius: PropTypes.string,
        borderColor: PropTypes.oneOf(['primary', 'secondary', 'success', 'default', 'inherit']),
    }),
    onChangeHandler: PropTypes.func,
    onSuccessCallback: PropTypes.func,
    previousButton: PropTypes.shape({
        label: PropTypes.string.isRequired,
        variant: PropTypes.oneOf(['outlined', 'contained', 'text']),
        fullWidth: PropTypes.bool,
        startIcon: PropTypes.node,
        color: PropTypes.oneOf(['primary', 'secondary', 'inherit', 'default', 'success']),
        size: PropTypes.oneOf(['small', 'medium', 'large']),
        onClick: PropTypes.func.isRequired,
    }),
    disabledSuccessMessage: PropTypes.bool,
};

ProfileEditFormBox.defaultProps = {
    defaultData: {},
    formConstants: {},
    onSuccessCallback: null,
    disabledSuccessMessage: false,
};
