import instance from './instances/university-service';

export function getUniversity(id) {
    return instance.get(`/v1/universities/${id}`);
}

export function getUniversities(query) {
    let search = new URLSearchParams(query);
    return instance.get(`/v1/universities?${search.toString()}`);
}
export function universitySearch(query) {
    let search = new URLSearchParams(query);
    return instance.get(`/v1/universities/search?${search.toString()}`);
}
