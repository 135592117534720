import { capitalize, get } from 'lodash';

// Schema Enum Keys
import {
    // Tests
    TEST_ENUM,
    // Education
    PG_CERTIFICATE_OR_DIPLOMA,
    CERTIFICATE_OR_DIPLOMA,
    MASTERS,
    PHD,
    TWELTH,
    TENTH,
    EXPERIENCE_CERTIFICATE,
    OFFER_LETTER,
    BACHELOR_DEGREE_3_OR_4,
} from '@/props-constants/student-schema/student-form-schemas/schema';

// Backend Keys: Persist
const LANGUAGE_TEST = 'language_test';

// Backend Keys: General
const PHOTOGRAPH = 'photograph';
const RESUME = 'resume';
const PASSPORT = 'passport';
//const GENERAL_DOCUMENTS_KEYS = [PHOTOGRAPH, RESUME, PASSPORT];

// Backend Keys: Miscellaneous
const SOP = 'sop';
const LOR = 'lor';
const VISA_REFUSAL_DOCUMENT = 'visa_refusal_document';
const DOCUMENTS_OF_SPOUSE = 'documents_of_spouse';
const PHD_DOCUMENTS = 'phd_documents';
// const MISCELLANEOUS_DOCUMENTS_KEYS = [
//     SOP,
//     LOR,
//     VISA_REFUSAL_DOCUMENT,
//     DOCUMENTS_OF_SPOUSE,
//     PHD_DOCUMENTS,
// ];

// document keys
export const VISA_REQUIREMENTS = 'visa_requirements';
export const VISA_APPLICATIONS = 'visa_applications';
export const VISA_APPROVAL_LETTER = 'visa_approval_letter';
export const DEPOSIT_SLIPS = 'deposit_slips';
export const APPLICATION_UPDATES = 'application_updates';

export const APPLICATION_SPECIFIC_DOCUMENTS_KEYS = [
    SOP,
    LOR,
    VISA_REQUIREMENTS,
    VISA_APPLICATIONS,
    VISA_APPROVAL_LETTER,
    DEPOSIT_SLIPS,
    OFFER_LETTER,
    APPLICATION_UPDATES,
];

// Backend Keys: Work
const WORK_EXP_DOCUMENTS = 'work_exp_documents';

//const WORK_DOCUMENTS_KEYS = [OFFER_LETTER, EXPERIENCE_CERTIFICATE];

// Backend Keys: Tests
const TEST_CERTIFICATES = 'test_certificates';
// Key list: Tests
//const TEST_DOCUMENTS_KEYS = [TEST_CERTIFICATES];

// Key list: Works
// const WORK_EXP_DOCUMENTS_KEYS = [WORK_EXP_DOCUMENTS];
// Schema Keys: Test Enum
export const OTHER = 'OTHER';
export const TEST_CATEGORY_LIST = [...TEST_ENUM, OTHER];
export const WORK_EX_CATEGORY_LIST = [EXPERIENCE_CERTIFICATE, OFFER_LETTER];

// Backend Keys: Education
export const MASTERS_DEGREE_MARK_SHEET = 'masters_degree_mark_sheet';
export const MASTERS_DEGREE_CERTIFICATE = 'masters_degree_certificate';
export const MASTERS_DEGREE_BACKLOG_CERTIFICATE = 'masters_degree_backlog_certificate';
export const GRADUATION_DEGREE_MARK_SHEET = 'graduation_degree_semesterwise_mark_sheet';
export const GRADUATION_MARK_SHEET = 'graduation_mark_sheet';
export const GRADUATION_DEGREE_CERTIFICATE = 'graduation_degree_certificate';
export const GRADUATION_DEGREE_BACKLOG_CERTIFICATE = 'graduation_degree_backlog_certificate';
export const GRADE_10_OR_EQUIVALENT_MARK_SHEET = 'grade_10_or_equivalent_mark_sheet';
export const GRADE_12_OR_EQUIVALENT_MARK_SHEET = 'grade_12_or_equivalent_mark_sheet';

export const GRADE_10_MARKSHEET = '10th Marksheet';
export const GRADE_10_PASS_CERTIFICATE = '10th Pass Certificate';
export const GRADE_10_ADMIT_CARD = '10th Admit card';

export const GRADE_12_MARKSHEET = '12th Marksheet';
export const GRADE_12_PASS_CERTIFICATE = '12th Pass Certificate';
export const GRADE_12_ADMIT_CARD = '12th Admit card';

export const DIPLOMA_SEMESTER_WISE_MARKSHEET = 'Diploma Semester Wise Marksheet';
export const DIPLOMA_PASSING_CERTIFICATE = 'Diploma Passing Certificate';

export const BACHELOR_DEGREE_SEMETER_WISE_MARKSHEET = "Bachelor's Degree Semester Wise Marksheet";
export const BACHELOR_DEGREE_TRANSCRIPT = "Bachelor's Degree Transcript";
export const BACHELOR_DEGREE_PROVISIONAL_CERTIFICATE = "Bachelor's Degree Provisional Certificate";
export const BACHELOR_DEGREE_ORIGINAL_CERTIFICATE = "Bachelor's Degree Original Certificate";
export const BACHELOR_BACKLOG_MARKSHEET_OR_CERTIFICATE =
    "Bachelor's Backlog Marksheet / Certificate";

export const PG_DIPLOMA_SEMESTER_WISE_MARKSHEET = 'PG Diploma Semester Wise Marksheet';
export const PG_DIPLOMA_TRANSCRIPT = 'PG Diploma Transcript';
export const PG_DIPLOMA_PASSING_CERTIFICATE = 'PG Diploma Passing Certificate';
export const PG_DIPLOMA_BACKLOG_MARKSHEET_OR_CERTIFICATE =
    'PG Diploma Backlog Marksheet / Certificate';

export const MASTERS_DEGREE_SEMETER_WISE_MARKSHEET = "Master's Degree Semester Wise Marksheet";
export const MASTERS_DEGREE_TRANSCRIPT = "Master's Degree Transcript";
export const MASTERS_DEGREE_PROVISIONAL_CERTIFICATE = "Master's Degree Provisional Certificate";
export const MASTERS_DEGREE_ORIGINAL_CERTIFICATE = "Master's Degree Original Certificate";
export const MASTERS_BACKLOG_MARKSHEET_OR_CERTIFICATE = "Master's Backlog Marksheet / Certificate";

export const PHD_SEMETER_WISE_MARKSHEET = 'Phd Semester Wise Marksheet';
export const PHD_TRANSCRIPT = 'Phd Transcript';
export const PHD_CERTIFICATE = 'Phd Certificate';

// Key list: Education
// const EDUCATION_DOCUMENTS_KEYS = [
//     GRADE_10_OR_EQUIVALENT_MARK_SHEET,
//     GRADE_12_OR_EQUIVALENT_MARK_SHEET,
//     GRADUATION_DEGREE_MARK_SHEET,
//     GRADUATION_DEGREE_CERTIFICATE,
//     GRADUATION_DEGREE_BACKLOG_CERTIFICATE,
//     MASTERS_DEGREE_MARK_SHEET,
//     MASTERS_DEGREE_CERTIFICATE,
//     MASTERS_DEGREE_BACKLOG_CERTIFICATE,
//     PHD_DOCUMENTS,
// ];

// Schema Keys: Form
export const EDUCATION_DOCUMENTS = 'education_documents';
export const TEST_DOCUMENTS = 'test_documents';
export const WORK_DOCUMENTS = 'work_documents';
export const GENERAL_DOCUMENTS = 'general_documents';
export const MISCELLANEOUS_DOCUMENTS = 'miscellaneous_documents';
export const PERSIST_DOCUMENTS = 'persist_documents';
export const REQUIRED_DOCUMENTS = 'required_documents';
export const GTE_DOCUMENTS = 'gte_documents';
export const APPLICATION_DOCUMENTS = 'application_documents';

//Backend document label

export const EDUCATION_DOCUMENT_LABEL = 'Academic Details';
export const TEST_DOCUMENTS_LABEL = 'Test Details';
export const WORK_DOCUMENTS_LABEL = 'Work Experience';
export const GENERAL_DOCUMENTS_LABEL = 'Personal Documents';
export const MISCELLANEOUS_DOCUMENTS_LABEL = 'Miscellaneous Documents';
export const GTE_DOCUMENTS_LABEL = 'GTE';
export const APPLICATION_DOCUMENTS_LABEL = 'Applications';

// Backend Key Data: type
const SINGLE = 'SINGLE';
const MULTI = 'MULTI';

const formKeyToBackendKeyMap = {
    [EDUCATION_DOCUMENTS]: EDUCATION_DOCUMENT_LABEL,
    [TEST_DOCUMENTS]: TEST_DOCUMENTS_LABEL,
    [WORK_DOCUMENTS]: WORK_DOCUMENTS_LABEL,
    [GENERAL_DOCUMENTS]: GENERAL_DOCUMENTS_LABEL,
    [MISCELLANEOUS_DOCUMENTS]: MISCELLANEOUS_DOCUMENTS_LABEL,
    [GTE_DOCUMENTS]: GTE_DOCUMENTS_LABEL,
    [APPLICATION_DOCUMENTS]: APPLICATION_DOCUMENTS_LABEL,
    // [PERSIST_DOCUMENTS]: [
    //     // Miscellaneous
    //     LANGUAGE_TEST,
    //     // Work
    //     WORK_EXP_DOCUMENTS,
    // ],
    // [LANGUAGE_TEST]: [LANGUAGE_TEST],
};

const backendKeyTypeMap = {
    // General
    [PHOTOGRAPH]: SINGLE,
    [PASSPORT]: SINGLE,
    [RESUME]: SINGLE,
    [VISA_REFUSAL_DOCUMENT]: MULTI,
    [SOP]: MULTI,
    [LOR]: MULTI,

    [LANGUAGE_TEST]: SINGLE,
    [DOCUMENTS_OF_SPOUSE]: MULTI,
    // Work
    [WORK_EXP_DOCUMENTS]: MULTI,
    // Tests
    [TEST_CERTIFICATES]: MULTI,
    // Education
    [PHD_DOCUMENTS]: MULTI,
    [MASTERS_DEGREE_MARK_SHEET]: SINGLE,
    [MASTERS_DEGREE_CERTIFICATE]: SINGLE,
    [MASTERS_DEGREE_BACKLOG_CERTIFICATE]: SINGLE,
    [GRADUATION_DEGREE_MARK_SHEET]: SINGLE,
    [GRADUATION_MARK_SHEET]: MULTI,
    [GRADUATION_DEGREE_CERTIFICATE]: SINGLE,
    [GRADUATION_DEGREE_BACKLOG_CERTIFICATE]: SINGLE,
    [GRADE_10_OR_EQUIVALENT_MARK_SHEET]: SINGLE,
    [GRADE_12_OR_EQUIVALENT_MARK_SHEET]: SINGLE,
};

export const formatDocumentsToSend = (documents = [], profileData, parentLabel = null) => {
    const newDocuments = [];

    documents.forEach((document) => {
        // eslint-disable-next-line
        let { verified_by, verification__details, ...newDocument } = document;

        const formattedFileName = formatUploadedFilename(
            document,
            profileData.crm_uid,
            profileData.first_name,
            profileData.last_name,
            documents,
            newDocuments,
        );

        delete newDocument['additional_dropdown'];

        newDocument = {
            ...newDocument,
            original_file_name: formattedFileName,
        };

        let newParentLabel = parentLabel || get(document, 'label');
        if (!newParentLabel) {
            const category = profileData.categoriesKeysMapping.find(
                (categoryObj) => categoryObj.value === document.category,
            );
            newParentLabel = category.parentLabel;
        }

        if (newParentLabel) {
            newDocuments.push({
                ...newDocument,
                upload_platform: 's3',
                label: newParentLabel,
                platform: 'B2B',
            });
        } else {
            newDocuments.push({
                ...newDocument,
                upload_platform: 's3',
                label: 'NA',
                platform: 'B2B',
            });
        }
    });

    return newDocuments;
};

export const getDocumentsDeserializedByCategory = (documents) => {
    return documents.reduce((acc, file) => {
        // Special usecase for test documents
        if (TEST_CATEGORY_LIST.includes(file.category)) {
            if (acc[TEST_CERTIFICATES] && acc[TEST_CERTIFICATES].length) {
                acc[TEST_CERTIFICATES].push(file);
            } else {
                acc[TEST_CERTIFICATES] = [file];
            }
        } else if (WORK_EX_CATEGORY_LIST.includes(file.category)) {
            if (acc[WORK_EXP_DOCUMENTS] && acc[WORK_EXP_DOCUMENTS].length) {
                acc[WORK_EXP_DOCUMENTS].push(file);
            } else {
                acc[WORK_EXP_DOCUMENTS] = [file];
            }
        } else if (backendKeyTypeMap[file.category] === SINGLE && !acc[file.category]) {
            acc[file.category] = file;
        } else if (backendKeyTypeMap[file.category] === MULTI) {
            if (acc[file.category] && acc[file.category].length) {
                acc[file.category].push(file);
            } else {
                acc[file.category] = [file];
            }
        }
        return acc;
    }, {});
};

export const getDocumentsSerializedByCategory = (allDocuments = []) => {
    let documentGroup = {};

    Object.keys(formKeyToBackendKeyMap).forEach((formKey) => {
        documentGroup[formKey] = allDocuments.filter(
            (document) => document.label === formKeyToBackendKeyMap[formKey],
        );
    });

    return documentGroup;
};

// Document Mapping Utils
export const getEducationLevelToDocumentCategoriesMapping = (config) => {
    const educationLevel = config.educationLevel;

    let data = [];
    switch (educationLevel) {
        case TENTH: {
            data = [GRADE_10_MARKSHEET, GRADE_10_PASS_CERTIFICATE, GRADE_10_ADMIT_CARD];
            break;
        }
        case TWELTH: {
            data = [
                GRADE_10_MARKSHEET,
                GRADE_10_PASS_CERTIFICATE,
                GRADE_10_ADMIT_CARD,
                GRADE_12_MARKSHEET,
                GRADE_12_PASS_CERTIFICATE,
                GRADE_12_ADMIT_CARD,
            ];
            break;
        }

        case CERTIFICATE_OR_DIPLOMA: {
            data = [
                GRADE_10_MARKSHEET,
                GRADE_10_PASS_CERTIFICATE,
                GRADE_10_ADMIT_CARD,
                GRADE_12_MARKSHEET,
                GRADE_12_PASS_CERTIFICATE,
                GRADE_12_ADMIT_CARD,
                DIPLOMA_SEMESTER_WISE_MARKSHEET,
                DIPLOMA_PASSING_CERTIFICATE,
            ];
            break;
        }

        case BACHELOR_DEGREE_3_OR_4: {
            data = [
                GRADE_10_MARKSHEET,
                GRADE_10_PASS_CERTIFICATE,
                GRADE_10_ADMIT_CARD,
                GRADE_12_MARKSHEET,
                GRADE_12_PASS_CERTIFICATE,
                GRADE_12_ADMIT_CARD,
                DIPLOMA_SEMESTER_WISE_MARKSHEET,
                DIPLOMA_PASSING_CERTIFICATE,
                BACHELOR_DEGREE_SEMETER_WISE_MARKSHEET,
                BACHELOR_DEGREE_TRANSCRIPT,
                BACHELOR_DEGREE_PROVISIONAL_CERTIFICATE,
                BACHELOR_DEGREE_ORIGINAL_CERTIFICATE,
                BACHELOR_BACKLOG_MARKSHEET_OR_CERTIFICATE,
            ];
            break;
        }
        case PG_CERTIFICATE_OR_DIPLOMA: {
            data = [
                GRADE_10_MARKSHEET,
                GRADE_10_PASS_CERTIFICATE,
                GRADE_10_ADMIT_CARD,
                GRADE_12_MARKSHEET,
                GRADE_12_PASS_CERTIFICATE,
                GRADE_12_ADMIT_CARD,
                DIPLOMA_SEMESTER_WISE_MARKSHEET,
                DIPLOMA_PASSING_CERTIFICATE,
                BACHELOR_DEGREE_SEMETER_WISE_MARKSHEET,
                BACHELOR_DEGREE_TRANSCRIPT,
                BACHELOR_DEGREE_PROVISIONAL_CERTIFICATE,
                BACHELOR_DEGREE_ORIGINAL_CERTIFICATE,
                BACHELOR_BACKLOG_MARKSHEET_OR_CERTIFICATE,
                PG_DIPLOMA_SEMESTER_WISE_MARKSHEET,
                PG_DIPLOMA_TRANSCRIPT,
                PG_DIPLOMA_PASSING_CERTIFICATE,
                PG_DIPLOMA_BACKLOG_MARKSHEET_OR_CERTIFICATE,
            ];
            break;
        }

        case MASTERS: {
            data = [
                GRADE_10_MARKSHEET,
                GRADE_10_PASS_CERTIFICATE,
                GRADE_10_ADMIT_CARD,
                GRADE_12_MARKSHEET,
                GRADE_12_PASS_CERTIFICATE,
                GRADE_12_ADMIT_CARD,
                DIPLOMA_SEMESTER_WISE_MARKSHEET,
                DIPLOMA_PASSING_CERTIFICATE,
                BACHELOR_DEGREE_SEMETER_WISE_MARKSHEET,
                BACHELOR_DEGREE_TRANSCRIPT,
                BACHELOR_DEGREE_PROVISIONAL_CERTIFICATE,
                BACHELOR_DEGREE_ORIGINAL_CERTIFICATE,
                BACHELOR_BACKLOG_MARKSHEET_OR_CERTIFICATE,
                PG_DIPLOMA_SEMESTER_WISE_MARKSHEET,
                PG_DIPLOMA_TRANSCRIPT,
                PG_DIPLOMA_PASSING_CERTIFICATE,
                PG_DIPLOMA_BACKLOG_MARKSHEET_OR_CERTIFICATE,
                MASTERS_DEGREE_SEMETER_WISE_MARKSHEET,
                MASTERS_DEGREE_TRANSCRIPT,
                MASTERS_DEGREE_PROVISIONAL_CERTIFICATE,
                MASTERS_DEGREE_ORIGINAL_CERTIFICATE,
                MASTERS_BACKLOG_MARKSHEET_OR_CERTIFICATE,
            ];
            break;
        }
        case PHD: {
            data = [
                GRADE_10_MARKSHEET,
                GRADE_10_PASS_CERTIFICATE,
                GRADE_10_ADMIT_CARD,
                GRADE_12_MARKSHEET,
                GRADE_12_PASS_CERTIFICATE,
                GRADE_12_ADMIT_CARD,
                DIPLOMA_SEMESTER_WISE_MARKSHEET,
                DIPLOMA_PASSING_CERTIFICATE,
                BACHELOR_DEGREE_SEMETER_WISE_MARKSHEET,
                BACHELOR_DEGREE_TRANSCRIPT,
                BACHELOR_DEGREE_PROVISIONAL_CERTIFICATE,
                BACHELOR_DEGREE_ORIGINAL_CERTIFICATE,
                BACHELOR_BACKLOG_MARKSHEET_OR_CERTIFICATE,
                PG_DIPLOMA_SEMESTER_WISE_MARKSHEET,
                PG_DIPLOMA_TRANSCRIPT,
                PG_DIPLOMA_PASSING_CERTIFICATE,
                PG_DIPLOMA_BACKLOG_MARKSHEET_OR_CERTIFICATE,
                MASTERS_DEGREE_SEMETER_WISE_MARKSHEET,
                MASTERS_DEGREE_TRANSCRIPT,
                MASTERS_DEGREE_PROVISIONAL_CERTIFICATE,
                MASTERS_DEGREE_ORIGINAL_CERTIFICATE,
                MASTERS_BACKLOG_MARKSHEET_OR_CERTIFICATE,
                PHD_SEMETER_WISE_MARKSHEET,
                PHD_TRANSCRIPT,
                PHD_CERTIFICATE,
            ];
            break;
        }
        default: {
            data = [];
        }
    }

    return data.map((item) => ({
        label: capitalize(item.split('_').join(' ')),
        value: item,
    }));
};

export const getEducationLevelToLabelHighlight = (educationLevel) => {
    switch (educationLevel) {
        case TENTH:
            return '10th Certificates';
        case TWELTH:
            return '10th & 12th Certificates';

        case CERTIFICATE_OR_DIPLOMA:
            return '10th, 12th, Certificate or Diploma Certificates';

        case BACHELOR_DEGREE_3_OR_4:
            return '10th, 12th & Undergraduate Certificates';

        case PG_CERTIFICATE_OR_DIPLOMA:
            return 'PG Diploma Certificates';

        case MASTERS:
            return '10th, 12th, Undergraduate & Master Certificates';
        case PHD:
            return '10th, 12th, Undergraduate, Master & Phd Certificates';
        default:
            return '';
    }
};

// Test Mapping Utils
export const getTestLevelToDocumentCategoriesMapping = (config) => {
    const categories = get(config, 'categoriesKeyMapping', []);

    return categories.filter((category) => category.parentLabel === TEST_DOCUMENTS_LABEL);
};

// Work Mapping Utils
export const getWorkLevelToDocumentCategoriesMapping = (config) => {
    const categories = get(config, 'categoriesKeyMapping', []);
    return categories.filter((category) => category.parentLabel === WORK_DOCUMENTS_LABEL);
};

// General Mapping Utils
export const getGeneralDocumentCategoriesMapping = (config) => {
    const categories = get(config, 'categoriesKeyMapping', []);
    return categories.filter((category) => category.parentLabel === GENERAL_DOCUMENTS_LABEL);
};

// Miscellaneous Mapping Utils
export const getMiscellaneousDocumentCategoriesMapping = (config) => {
    const categories = get(config, 'categoriesKeyMapping', []);
    return categories.filter((category) => category.parentLabel === MISCELLANEOUS_DOCUMENTS_LABEL);
};

// GTE Mapping Utils
export const getGTEDocumentCategoriesMapping = (config) => {
    const categories = get(config, 'categoriesKeyMapping', []);
    return categories.filter((category) => category.parentLabel === GTE_DOCUMENTS_LABEL);
};

//Required Mapping Utils
export const getAllRequiredDocumentsMapping = (arr) => {
    return (arr || [])
        .filter((item) => item.required)
        .map((item) => ({
            label: capitalize(item.label).replace(/_/g, ' '),
            value: item.value,
            category: item.parentLabel,
        }));
};

const splitFileNameAndFileExtention = (fileName) => {
    // Use the lastIndexOf method to find the last dot (.) in the filename
    const lastDotIndex = fileName.lastIndexOf('.');

    if (lastDotIndex !== -1) {
        // Use the substring method to split the filename and extension
        const fileExtension = fileName.substring(lastDotIndex + 1);
        const baseName = fileName.substring(0, lastDotIndex);

        return { fileExtension, baseName };
    } else {
        return { baseName: fileName, fileExtension: '' };
    }
};

export const formatUploadedFilename = (
    document,
    crmStudentId,
    first_name,
    last_name,
    // eslint-disable-next-line
    requiredDocuments = [],
    newDocuments,
) => {
    let fileName = document.original_file_name;

    const newFileNameFormatedParts = `${crmStudentId}_${first_name}_${last_name}_${document.category}`;

    if (fileName.includes(newFileNameFormatedParts)) {
        return fileName;
    } else {
        const alreadyPushedNoOfSameCategoryDoc = newDocuments.filter(
            (item) => item.category === document.category,
        ).length;
        const { fileExtension, baseName } = splitFileNameAndFileExtention(
            document.original_file_name,
        );
        fileName = `${newFileNameFormatedParts}_${baseName}_${
            document?.additional_dropdown
                ? `${document?.additional_dropdown.replaceAll(/\s/g, '')}_`
                : ''
        }${alreadyPushedNoOfSameCategoryDoc + 1}${fileExtension ? `.${fileExtension}` : ''}`;
    }

    return fileName;
};

function extractNumberFromString(str) {
    const match = str.match(/_(\d+)$/);
    return match ? parseInt(match[1]) : 0;
}

export function sortDocuments(arr = []) {
    const sortedByNumberArr = arr.sort((a, b) => {
        const numA = extractNumberFromString(
            splitFileNameAndFileExtention(a.original_file_name).baseName,
        );
        const numB = extractNumberFromString(
            splitFileNameAndFileExtention(b.original_file_name).baseName,
        );
        return numB - numA; // Sort in descending order
    });

    let topArr = [];
    let bottomArr = [];

    for (let i = 0; i < sortedByNumberArr.length; i++) {
        let category_exit = false;

        const parts = sortedByNumberArr[i].original_file_name.split('_');
        const category_a = parts[3];
        for (let j = 0; j < topArr.length; j++) {
            const parts = topArr[j].original_file_name.split('_');
            const category_b = parts[3];

            if (category_a === category_b) {
                category_exit = true;
            }
        }
        if (category_exit) {
            bottomArr.push(sortedByNumberArr[i]);
        } else {
            topArr.push(sortedByNumberArr[i]);
        }
    }
    return topArr.concat(bottomArr);
}

export const getUniqueArrayOfObjectByObjectId = (arr) => {
    let newArr = [];
    arr.forEach((ao) => {
        if (!newArr.find((bo) => bo.objectId === ao.objectId)) {
            newArr.push(ao);
        }
    });

    return newArr;
};

export const VIDEO_FORMATS = [
    'video/mp4',
    'video/mpeg',
    'video/x-msvideo',
    'video/quicktime',
    'video/webm',
    'video/x-matroska',
    'video/avi',
    'video/msvideo',
];
