//utils
import { get } from 'lodash';

const steps = [0, 1, 2];

const getProfileSplitByIndex = (profileData, index, dirtyFormData) => {
    switch (index) {
        case 0: {
            const stepDirtyData = dirtyFormData && { ...dirtyFormData };
            const stepData = {
                step: index,
                id: profileData?.id,
                first_name: profileData?.first_name,
                last_name: profileData?.last_name,
                email: profileData?.email,
                mobile:
                    profileData?.mobile?.length > 10
                        ? profileData?.mobile.slice(-10)
                        : profileData?.mobile,
                whatsapp_number:
                    profileData?.whatsapp_number?.length > 10
                        ? profileData?.whatsapp_number.slice(-10)
                        : profileData?.whatsapp_number,
                is_whatsapp_same: profileData?.is_whatsapp_same,
            };

            if (stepDirtyData && get(stepDirtyData, '0.is_whatsapp_same', null)) {
                stepDirtyData[index].whatsapp_number = get(stepDirtyData, '0.mobile', null);
                return stepDirtyData[index];
            }

            if (
                stepData.mobile &&
                stepData.whatsapp_number &&
                stepData.mobile == stepData.whatsapp_number
            ) {
                stepData.is_whatsapp_same = true;
            }
            return stepData;
        }
        case 1:
            return {
                step: index,
                id: profileData?.id,
                password: profileData?.password,
            };

        case 2:
            return {
                step: index,
                id: profileData?.id,
            };
    }

    return { step: index };
};

export const getProfileSplit = (profileData, dirtyFormData) => {
    let profileSplit = {};
    if (profileData) {
        profileSplit = steps.reduce((acc, curr) => {
            acc[curr] = getProfileSplitByIndex(profileData, curr, dirtyFormData);
            return acc;
        }, {});
    } else {
        profileSplit = steps.reduce((acc, curr) => {
            acc[curr] = { step: curr };
            return acc;
        }, {});
    }
    return profileSplit;
};
