import React from 'react';
import PropTypes from 'prop-types';

// mui
import {
    ListItem,
    ListItemText,
    ListItemButton,
    Typography,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// styles
import { withStyles } from '@mui/styles';
import { useStyles } from './styles';

// storybook- comps
import { getIcon } from 'azent-storybook/dist';

//prop-constants
import { getAppPriorityIconStyle } from '@/props-constants/application/application-profile';

const StyledItem = withStyles({
    root: {
        '&.Mui-selected': {
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        padding: '0px',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
})(ListItemButton);

export default function AccordianDetails({
    label,
    applicationList,
    activeAppId,
    onStepperClick,
    panelIndex,
    openPanelIndex,
    onClickPanel,
}) {
    const classes = useStyles();
    return (
        <Accordion
            square
            disableGutters
            classes={{ root: classes.accordianRoot }}
            expanded={panelIndex == openPanelIndex}
            onChange={() => onClickPanel(panelIndex)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <Typography variant="h6">
                    {`${label}`}
                    {` (${applicationList.length})`}
                </Typography>
            </AccordionSummary>
            <AccordionDetails elevation={0}>
                {applicationList.map((application, index) => (
                    <>
                        <ListItem key={index} sx={{ p: 0 }}>
                            <StyledItem
                                selected={application._id == activeAppId}
                                onClick={(event) => onStepperClick(event, application)}>
                                <ListItemText>
                                    <Grid container>
                                        <Grid item xs={9.5}>
                                            <Typography
                                                variant={'h6'}
                                                sx={
                                                    application._id == activeAppId
                                                        ? {
                                                              color: '#78A5FF',
                                                          }
                                                        : { color: '#A9A9A9' }
                                                }>
                                                {(application.program_info &&
                                                    application.program_info.university_name) ||
                                                    '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2.5} sx={{ textAlign: 'right' }}>
                                            <Typography variant="h6">
                                                {application.preference
                                                    ? getIcon(
                                                          'fire',
                                                          'primary',
                                                          'small',
                                                          getAppPriorityIconStyle(
                                                              application.preference,
                                                              classes,
                                                          ),
                                                      )
                                                    : ''}
                                                {application.preference}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Typography
                                        variant={'h6'}
                                        sx={
                                            application._id == activeAppId
                                                ? {
                                                      color: '#000000',
                                                  }
                                                : { color: '#6D6D6D' }
                                        }>
                                        {(application.program_info &&
                                            application.program_info.name) ||
                                            '-'}
                                    </Typography>
                                </ListItemText>
                            </StyledItem>
                        </ListItem>
                        {applicationList.length - 1 != index && (
                            <Divider variant="middle" sx={{ mx: 0, my: 0.5 }}></Divider>
                        )}
                    </>
                ))}
            </AccordionDetails>
        </Accordion>
    );
}

AccordianDetails.propTypes = {
    label: PropTypes.string,
    applicationList: PropTypes.array,
    activeAppId: PropTypes.string,
    onStepperClick: PropTypes.func,
    panelIndex: PropTypes.number,
    openPanelIndex: PropTypes.number,
    onClickPanel: PropTypes.func,
};
