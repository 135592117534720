//api
import { changeSubAgentStatus, transferStudent, SELF_SUBAGENT_DATA } from '@/api/sub-agent';

//prop-constants
import { getTransferStudentFormSchema } from '@/props-constants/sub-agent-schema/transfer-student/schema';
import { getTransferStudentFormUiSchema } from '@/props-constants/sub-agent-schema/transfer-student/uiSchema';
const handleTransferStudent = (data, subAgentProfileData) => {
    if (data.transfer_sudent === 'No') {
        return changeSubAgentStatus(subAgentProfileData.id, {
            is_active: !subAgentProfileData.is_active,
        });
    } else {
        const objToSend = {
            from_agent: subAgentProfileData.id,
            to_agent: data.agent[0].id == SELF_SUBAGENT_DATA.id ? '' : data.agent[0].id,
        };
        return transferStudent(objToSend);
    }
};

export const getTransferStudentProps = (defaultData, subAgentProfileData, partnerId) => {
    const transfer_from_id = subAgentProfileData && subAgentProfileData.id;
    const props = {
        defaultData,
        submitButton: {
            onClick: async (data) => {
                return handleTransferStudent(data, subAgentProfileData);
            },
            fullWidth: false,
            label: 'Save',
            variant: 'contained',
            size: 'medium',
            color: 'success',
        },
        modalWidth: 'sm',
        schema: getTransferStudentFormSchema(),
        uiSchemas: {
            smallScreenUiSchema: {},
            largeScreenUiSchema: getTransferStudentFormUiSchema(partnerId, transfer_from_id),
        },
        showPreview: false,
    };

    return props;
};
