// Material
import { createTheme, adaptV4Theme } from '@mui/material/styles';

const theme = createTheme(
    adaptV4Theme({
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    html: {
                        WebkitFontSmoothing: 'auto',
                        scrollBehavior: 'smooth',
                    },
                    a: {
                        textDecoration: 'none',
                        color: '#131415',
                    },
                    'a-webkit-any-link': {
                        textDecoration: 'none',
                        color: '#131415',
                    },
                },
            },
            MuiButton: {
                root: {
                    borderRadius: '10px',
                    textTransform: 'none',
                    fontSize: '12px',
                    padding: '10px 28px',
                },
                outlined: {
                    padding: '10px 28px',
                },
            },
        },
        palette: {
            primary: {
                main: '#F56A20',
                light: '#F9D7C0',
            },
            secondary: {
                main: '#4081FF',
                light: '#D6E5FC',
            },
            success: {
                main: '#00C853',
                light: '#D4FCD4',
            },
            common: {
                black: '#E5E5E5',
                white: '#FFFFFF',
            },
            text: {
                primary: '#000000',
                secondary: '#F56A20',
                disabled: '#6D6D6D',
                light: '#A9A9A9',
            },
            background: {
                default: '#FFFFFF',
                paper: '#FFFFFF',
            },
            neutral: {
                dark: '#78A5FF',
            },
        },
        shape: {
            borderRadius: 15,
        },
        typography: {
            fontFamily: 'Montserrat',
            fontSize: 16,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
            fontWeightBold: 600,
            h1: {
                fontWeight: 600,
                fontSize: '27px',
            },
            h2: {
                fontWeight: 500,
                fontSize: '22px',
            },
            h3: {
                fontWeight: 600,
                fontSize: '20px',
            },
            h4: {
                fontWeight: 500,
                fontSize: '18px',
            },
            h5: {
                fontWeight: 500,
                fontSize: '16px',
            },
            h6: {
                fontSize: '14px',
                fontWeight: 500,
            },
            subtitle1: {
                fontWeight: 500,
                fontSize: '12px',
            },
            subtitle2: {
                fontWeight: 500,
                fontSize: '10px',
            },
            button: {
                fontSize: '14px',
                fontWeight: 500,
            },
        },
    }),
);
export default theme;
