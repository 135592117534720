// Constants
import { getPlagiarismPreviewHTML } from '@/api/plagiarism';
import { isB2bAdmin } from '@/rbac';
import { getAllRequiredDocumentsMapping } from '@/utils/documents';

const largeScreenUiSchema = (config) => {
    let data = {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'Control',
                scope: '#/properties/required_documents',
                label: 'All Documents',
                options: {
                    component: 'resource',
                    selectCategoryLabel: 'Choose Document Category*',
                    fileCategories: getAllRequiredDocumentsMapping(config.categoriesKeyMapping),
                    showCategorized: true,
                    categoriesKeysMapping: config.categoriesKeyMapping,
                    applications: config.categoriesKeyMapping,
                    showGTEUploadOption: config.showGTEUploadOption,
                    canVerifyDoc: isB2bAdmin(),
                    getPlagiarismPreviewHTML: getPlagiarismPreviewHTML,
                },
            },
        ],
    };
    return data;
};

const smallScreenUiSchema = () => {
    let data = {};
    return data;
};

export default function (config = {}) {
    return {
        smallScreenUiSchema: smallScreenUiSchema(config),
        largeScreenUiSchema: largeScreenUiSchema(config),
    };
}
