import instance from './instances/staging-student-service';

export function getOnboardingPartner() {
    return instance.get(`/v1/partners`);
}

export function updateOnboardingData(data) {
    return instance.put(`/v1/partners`, data);
}

export function updateOnboardingComment(data, id) {
    return instance.patch(`/v1/partners/${id}/comment`, data);
}

export function getPartnerProfile(id) {
    return instance.get(`/v1/partners/${id}`);
}
