import axios from 'axios';
import constants from '@/constants';
import getHeaders from '@/utils/get-headers';

// same as auth except handling of 401/403/404 error is different, used in login module

let instance = axios.create({
    baseURL: constants.authApi,
    headers: getHeaders(),
});

instance.interceptors.request.use(
    function (config) {
        config.headers = getHeaders();
        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);
instance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        // if (error && error.response && error.response.status == 404) {
        //     store.dispatch(replace(RoutePaths.NOT_FOUND));
        // }
        // NOTE: Error message to be displayed on login screen hence not to be redirected.
        // if (status === 401 || status === 403) {
        //     store.dispatch(removeAuthTokenAndGotoLogin());
        // }
        return Promise.reject(error);
    },
);

export default instance;
