import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    dialogHeader: {
        padding: '24px 25px',
        fontWeight: '600',
        fontSize: '16px',
        color: '#000000',
        lineHeight: '20px',
    },
    detailContainer: {
        border: '1px solid #A4A4A4',
        borderRadius: '6px',
        padding: '6px 9px',
        height: '100%',
    },
    detailHeader: {
        color: '#F56921',
        fontWeight: '600',
        fontSize: '14px',
    },
    detailContent: {
        fontSize: '14px',
        fontWeight: '500',
        marginLeft: '6px',
        lineHeight: '17px',
    },
    rowWrapper: {
        paddingBottom: '20px',
    },
    detailWrapper: {
        padding: '0px 25px',
    },
    timeline: {
        margin: '6.5px 0px',
    },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    emailLabel: {
        color: '#78A5FF',
        fontSize: '14px',
        fontWeight: '500',
        cursor: 'pointer',
    },
    icon: {
        fontSize: theme.spacing(2),
        color: theme.palette.text.disabled,
    },
    naLabel: {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '17px',
    },
    copy: {
        fontSize: theme.spacing(1.5),
    },
    copyWrapper: {
        cursor: 'pointer',
    },
    passHidden: {
        marginTop: '6px',
    },
}));
