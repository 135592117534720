import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

// components
import { FormModal } from 'azent-storybook/dist';

//actions
import { addStudentToStore } from '@/store/slices/studentSlice';

// utils
import {
    createApplicationSchema,
    createApplicationUiSchema,
} from '@/props-constants/course-finder';

// api
import { getProfile, updateApplication } from '@/api';

// deserializer
import { deserializeStudent } from '@/utils/deserializers/student-deserializer';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export default function ApplicationIntake({
    classes,
    isIntakeModalOpen,
    setIntakeModalOpen,
    data,
}) {
    const dispatch = useDispatch();
    const hidePriorityField = true;

    const schema = createApplicationSchema(months, null, [''], hidePriorityField);
    const uiSchema = createApplicationUiSchema(null, null, null, hidePriorityField);

    const onClickUpdateIntake = (formdata) => {
        const payload = {
            intake: {
                month: formdata.intake.month,
                year: formdata.intake.year,
            },
        };
        return updateApplication(data._id, payload);
    };

    const onSuccessCallback = () => {
        getProfile(data.profile_id).then((res) =>
            dispatch(addStudentToStore(deserializeStudent(res.data))),
        );
    };

    return (
        <FormModal
            {...{
                open: isIntakeModalOpen,
                defaultData: {},
                schema: schema,
                uiSchemas: {
                    smallScreenUiSchema: uiSchema,
                    largeScreenUiSchema: uiSchema,
                },
                classes: {
                    cModalTitleContainer: classes.cModalTitleContainer,
                    cModalTitle: classes.cModalTitle,
                },
                modalTitle: {
                    title: 'Update application intake',
                },
                isB2bFormModal: true,
                submitButton: {
                    fullWidth: false,
                    label: 'UPDATE',
                    variant: 'contained',
                    size: 'medium',
                    onClick: onClickUpdateIntake,
                },
                onSuccessCallback,
                handleClose: () => setIntakeModalOpen(false),
            }}
        />
    );
}

ApplicationIntake.propTypes = {
    classes: PropTypes.object,
    isIntakeModalOpen: PropTypes.bool,
    setIntakeModalOpen: PropTypes.bool,
    data: PropTypes.object,
};
