import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//utils
import routePaths, { registrationPaths } from '@/utils/route-paths';

// actions
import { goToRoute } from '@/store/actions/navigationActions';

//components
import StepperHorizontal from '@/components/shared/StepperHorizontal';
import ReadyForRegistration from './ReadyForRegistration';
import AgreementInitiated from './AgreementInitiated';
import ApplicationSubmitted from './ApplicationSubmitted';
import Onboarded from './Onboarded';

const stepNames = [
    'Ready for registration',
    'Application Submitted',
    'Agreement Initiated',
    'Onboarded',
];

const getTabDataFromStatus = (path) => {
    switch (path) {
        case 'ready_for_registration': {
            return <ReadyForRegistration />;
        }
        case 'new': {
            return <ReadyForRegistration />;
        }
        case 'application_submitted': {
            return <ApplicationSubmitted />;
        }
        case 'agreement_initiated': {
            return <AgreementInitiated />;
        }
        case 'onboarded': {
            return <Onboarded />;
        }
        default: {
            return <ReadyForRegistration />;
        }
    }
};

const getRegistrationPathFromTabIndex = (activeStep) => {
    switch (activeStep) {
        case 0: {
            return registrationPaths.VIEW;
        }
        case 1: {
            return registrationPaths.APPLICATION_SUBMITTED;
        }
        case 2: {
            return registrationPaths.AGREEMENT_INITIATED;
        }
        case 3: {
            return registrationPaths.ONBOARDED;
        }
        default: {
            return registrationPaths.VIEW;
        }
    }
};

function handleSetCompletedSteps(onboardingStatus) {
    switch (onboardingStatus) {
        case 'ready_for_registration': {
            return {};
        }
        case 'new': {
            return {};
        }
        case 'application_submitted': {
            return { 0: true };
        }
        case 'agreement_initiated': {
            return { 0: true, 1: true };
        }
        case 'onboarded': {
            return { 0: true, 1: true, 2: true };
        }
        default: {
            return {};
        }
    }
}

export default function Registration() {
    const dispatch = useDispatch();
    const onboardingStatus = useSelector((state) => state.partnerData.data.onboarding_status);

    const [tabData, setTabData] = useState();
    const [completed, setCompleted] = useState({});

    useEffect(() => {
        setCompleted(handleSetCompletedSteps(onboardingStatus));
        setTabData(getTabDataFromStatus(onboardingStatus));
    }, [onboardingStatus]);

    const getActiveStep = (onboardingStatus) => {
        switch (onboardingStatus) {
            case 'ready_for_registration': {
                return 0;
            }
            case 'new': {
                return 0;
            }
            case 'application_submitted': {
                return 1;
            }
            case 'agreement_initiated': {
                return 2;
            }
            case 'onboarded': {
                return 3;
            }
            default: {
                return 0;
            }
        }
    };

    const setActiveStep = (activeStep = 0) => {
        if (activeStep == 0) {
            dispatch(
                goToRoute(
                    routePaths.REGISTRATION,
                    {
                        path: getRegistrationPathFromTabIndex(activeStep),
                    },
                    { index: 0 },
                ),
            );
        } else
            dispatch(
                goToRoute(
                    routePaths.REGISTRATION,
                    {
                        path: getRegistrationPathFromTabIndex(activeStep),
                    },
                    {},
                ),
            );
    };

    return (
        <StepperHorizontal
            stepNames={stepNames}
            tabData={tabData}
            activeStep={getActiveStep(onboardingStatus) || 0}
            setActiveStep={setActiveStep}
            showStepperControls={false}
            isStepClickEnabled={false}
            completed={completed}
            setCompleted={setCompleted}></StepperHorizontal>
    );
}
