import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

// storybook-components
import { Table } from 'azent-storybook/dist';

//prop-constants
import { filterMsg } from '@/props-constants/sub-agent/sub-agent-profile';

//utils
import routePaths from '@/utils/route-paths';
import { setToast } from '@/store/slices/toastSlice';
import { toastMessages } from '@/utils/toastMessages';

// actions
import { goToRoute, setQueries } from '@/store/actions/navigationActions';
import { addSubAgentToStore } from '@/store/slices/subAgentSlice';

// styles
import { useStyles } from './style';

//components
import Loader from '@/components/shared/Loader';
import TransferStudentModal from './partials/TransferStudentModal/index';
import { getRawColumns } from './partials/subAgentColumns';
import SubAgentNoData from './partials/SubAgentNoData';

//api
import { getSubAgentUsers, changeSubAgentStatus } from '@/api/sub-agent';
import { getSubAgentProfilesAssigned } from '@/api';

let timeout = null;
let mounted = false;

export default function ListSubAgent() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const location = useSelector((state) => state.router.location);
    const user = useSelector((state) => state.userData);
    const search = new URLSearchParams(location.search);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(search.get('page') || 1);
    const [perPage, setPerPage] = useState(search.get('perPage') || 10);
    const [searchText, setSearchText] = useState(search.get('q') || '');
    const [totalPages, setTotalPages] = useState(0);
    const [selectedSubAgnet, setSelectedSubagent] = useState();
    const [formDataAgentTransfer, setFormDataAgentTransfer] = useState({});

    const partnerId = useMemo(() => {
        return user.userData.id;
    }, [user]);

    const setQuery = function (pageChoice) {
        const query = [
            { key: 'page', value: pageChoice || page },
            { key: 'per_page', value: perPage || '10' },
            { key: 'q', value: searchText || '' },
        ];
        dispatch(setQueries(query));
    };

    const onRowClick = (_event, rowData) => {
        const id = get(rowData, '0', null).value;
        dispatch(goToRoute(routePaths.SUB_AGENT_UPDATE, {}, { step: 'personal-detail', id }));
    };

    const onCreate = () => {
        dispatch(addSubAgentToStore({}));
        dispatch(goToRoute(routePaths.SUB_AGENT_CREATE, {}, { step: 'personal-detail' }));
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    const handleOpen = (rowData) => {
        const subAgentIsActive = rowData.original.is_active;
        const subAgentId = rowData.original.id;
        let assignedStudentsCount = 0;

        if (!subAgentIsActive)
            changeSubAgentStatus(subAgentId, { is_active: true })
                .then((res) => {
                    updateSubAgentUserStatusData(res.data);
                    dispatch(setToast(toastMessages.successMessage));
                })
                .catch((err) => {
                    dispatch(
                        setToast({
                            message:
                                err?.response?.data?.error?.message ||
                                toastMessages.backendIssue.message,
                            toasterColor: toastMessages.backendIssue.toasterColor,
                        }),
                    );
                });
        else if (subAgentIsActive) {
            getSubAgentProfilesAssigned(subAgentId)
                .then((res) => {
                    if (res.data.length) assignedStudentsCount = res.data.length;
                    // make agent inactive, that has 0 students assigned
                    if (assignedStudentsCount == 0) {
                        changeSubAgentStatus(subAgentId, { is_active: false })
                            .then((res) => {
                                updateSubAgentUserStatusData(res.data);
                                dispatch(setToast(toastMessages.successMessage));
                            })
                            .catch((err) => {
                                dispatch(
                                    setToast({
                                        message:
                                            err?.response?.data?.error?.message ||
                                            toastMessages.backendIssue.message,
                                        toasterColor: toastMessages.backendIssue.toasterColor,
                                    }),
                                );
                            });
                    } else {
                        // make agent inactive, has >=1 students assigned
                        setSelectedSubagent(rowData.original);
                        setFormDataAgentTransfer({});
                        setIsModalOpen(true);
                    }
                })
                .catch(() => {
                    dispatch(setToast(toastMessages.backendIssue));
                });
        }
    };

    const updateSubAgentUserStatusData = (respData) => {
        setData(
            data.map((val) => {
                if (val.id == get(respData, 'data.User.id', null)) {
                    val.is_active = get(respData, 'data.User.is_active', false);
                }
                return val;
            }),
        );
    };

    const columns = getRawColumns(handleOpen);

    const getSubAgentData = (location) => {
        let search = new URLSearchParams(location);
        let searchObj = Object.fromEntries(search);
        if (!searchObj.per_page) searchObj.per_page = 10;
        setLoading(true);
        getSubAgentUsers(user.userData.id, searchObj)
            .then((res) => {
                setData(res.data.data.sub_agents);
                setPage(Number(res.headers.page));
                setPerPage(Number(res.headers.per_page));
                setSearchText(search.get('q') || '');
                setTotalPages(Number(res.headers.total_pages));
                setLoading(false);
            })
            .catch(() => {
                dispatch(setToast(toastMessages.backendIssue));
                setLoading(false);
            });
    };

    React.useEffect(() => {
        setTimeout(() => {
            mounted = true;
        });
    }, []);

    React.useEffect(() => {
        if (mounted) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            timeout = setTimeout(() => {
                if (page > 1) {
                    setQuery(1);
                } else {
                    setQuery();
                }
            }, 1000);
        }
    }, [searchText]);

    React.useEffect(() => {
        if (mounted) {
            setQuery();
        }
    }, [page, perPage]);

    React.useEffect(() => {
        getSubAgentData(location.search);
    }, [location.search]);

    return (
        <>
            {loading ? (
                <Loader />
            ) : data.length > 0 || location.query.q ? (
                <Table
                    title={'All Users'}
                    onSelectColumns={() => {}}
                    config={{
                        columns,
                        data,
                        initialState: {
                            hiddenColumns: columns
                                .filter((col) => col.showInit == false)
                                .map((col) => col.Header),
                        },
                        filter: {
                            setFilterText: setSearchText,
                            filterText: searchText,
                        },
                        pagination: {
                            perPageOptions: [5, 10, 15, 20],
                            perPage,
                            setPerPage,
                            page,
                            setPage,
                            totalPages,
                        },
                        actionButton: {
                            label: 'Create New User',
                            variant: 'contained',
                            onClick: onCreate,
                            className: classes.createAppButton,
                        },
                    }}
                    noDataMessage={filterMsg.default}
                    isCustomFilter={false}
                    loading={loading}
                    setSelected={onRowClick}></Table>
            ) : (
                <SubAgentNoData />
            )}
            <TransferStudentModal
                isModalOpen={isModalOpen}
                handleClose={handleClose}
                subAgentProfileData={selectedSubAgnet}
                updateSubAgentUserStatusData={updateSubAgentUserStatusData}
                partnerId={partnerId}
                formData={formDataAgentTransfer}
                setFormData={setFormDataAgentTransfer}
            />
        </>
    );
}
