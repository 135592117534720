import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

//component
import Header from '../service-page/partials/header';
import Faqs from '../service-page/partials/faqs';
import { Tabs } from '../service-page/partials/Tabs/Tabs';
import Description from '../service-page/partials/description';

//mui
import { Grid } from '@mui/material';
import { isEmpty } from 'lodash';
//store
import { fetchServiceFromCms } from '@/api/cms';
import { deserializeServiceCard } from '@/utils/deserializers/servicecard-deserializer';
import { useSelector, useDispatch } from 'react-redux';
import { initializeServiceCard } from '@/store/actions/serviceCmsAction';
import { deserializeAncillaryService } from '@/utils/deserializers/ancillary-service-deserializer';
import { getServices } from '@/api/ancillary';

const ServiceSummary = ({ services, serviceId, studentId }) => {
    const [service, setService] = useState();
    const [serviceCard, setServiceCard] = useState();
    const [value, setValue] = useState(0);
    const [profileloading, setProfileLoading] = useState(false);
    const [isEnquireEnabled, setIsEnquireEnabled] = useState(false);
    const serviceCards = useSelector((state) => state.serviceCards);
    const dispatch = useDispatch();

    function getAncillaryServiceCard() {
        setProfileLoading(true);
        fetchServiceFromCms(serviceId).then((res) => {
            const serviceCardList = [
                ...serviceCards.data,
                deserializeServiceCard(
                    res.data && res.data.data && res.data.data.attributes,
                    res.data && res.data.data && res.data.data.id,
                ),
            ];
            dispatch(initializeServiceCard(serviceCardList));
            setProfileLoading(false);
        });
    }

    function getServicesById(serviceId) {
        services.map((service_) => {
            if (service_.cms_service_id == serviceId) {
                setService(service_);
            }
        });
    }

    useEffect(() => {
        if (serviceCards && serviceCards.data && serviceCards.data.length) {
            const filteredCard = serviceCards.data.filter(
                (serviceCard) => serviceCard.id == serviceId,
            );
            if (filteredCard.length) {
                setServiceCard(filteredCard[0]);
            } else {
                getAncillaryServiceCard();
            }
        } else {
            getAncillaryServiceCard();
        }
    }, [serviceCards.data]);

    function getService(studentId) {
        studentId &&
            getServices(studentId).then((res) => {
                const serviceList = res.data.subscribed_services;
                const deserializeServiceList = deserializeAncillaryService(serviceList);
                if (!isEmpty(deserializeServiceList)) {
                    deserializeServiceList[serviceId]
                        ? setIsEnquireEnabled(deserializeServiceList[serviceId].isEnquireEnabled)
                        : setIsEnquireEnabled(true);
                } else {
                    setIsEnquireEnabled(true);
                }
            });
    }

    useEffect(() => {
        if (serviceId) {
            getService(studentId);
            getServicesById(serviceId);
            getAncillaryServiceCard();
        }
    }, [serviceId]);

    return (
        <>
            {service ? (
                <Grid align="center">
                    <Header
                        {...serviceCard}
                        EnquireId={serviceId}
                        isEnquireEnabled={isEnquireEnabled}
                        profileLoading={profileloading}
                        studentServiceLoading={true}
                        enquireLabel={'Enquire Again'}
                        setIsEnquireEnabled={setIsEnquireEnabled}
                        studentId={studentId}
                    />
                    <Tabs
                        tabList={[
                            {
                                label: 'Description',
                                content: () => {
                                    return (
                                        <Description
                                            {...serviceCard}
                                            profileLoading={profileloading}
                                            studentServiceLoading={true}
                                        />
                                    );
                                },
                            },
                            {
                                label: 'FAQs',
                                content: () => {
                                    return (
                                        <Faqs
                                            {...serviceCard}
                                            profileLoading={profileloading}
                                            studentServiceLoading={true}
                                        />
                                    );
                                },
                            },
                        ]}
                        orientation={`vertical`}
                        value={value}
                        setValue={setValue}
                        verticalGridSize={{
                            tab: 1.5,
                            content: 10.5,
                        }}
                    />
                </Grid>
            ) : (
                <Grid align="center" style={{ margin: '20px' }}>
                    This service is not Enquired
                </Grid>
            )}
        </>
    );
};

ServiceSummary.propTypes = {
    services: PropTypes.array,
    serviceId: PropTypes.string,
    studentId: PropTypes.string,
};

export default ServiceSummary;
