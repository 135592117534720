import { startCase } from 'lodash';

const capitalizeText = (text) => {
    return startCase(text);
};

const splitTextWithDelimiter = (text, delimeter) => {
    return text.split(delimeter).join(' ');
};

const applyElipsis = (text, length) => {
    return text.substring(0, length) + '...';
};

export { capitalizeText, splitTextWithDelimiter, applyElipsis };
