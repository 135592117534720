import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

//prop-constants
import { getTransferStudentProps } from '@/props-constants/sub-agent-schema/transfer-student';

// components
import { FormModal } from 'azent-storybook/dist';

// api
import { changeSubAgentStatus } from '@/api/sub-agent';

//utils
import { toastMessages } from '@/utils/toastMessages';
import { setToast } from '@/store/slices/toastSlice';
import { addSubAgentToStore, addAssignedStudentsToStore } from '@/store/slices/subAgentSlice';
import { resetStudentDataInStore } from '@/store/slices/studentSlice';
import { deserializeSubagentInfo } from '@/utils/deserializers/subagentInfo-deserializer';

export default function TransferStudentModal({
    handleClose,
    isModalOpen,
    subAgentProfileData,
    updateSubAgentUserStatusData,
    partnerId,
    formData,
    setFormData,
}) {
    const dispatch = useDispatch();

    const transferStudentProps = useMemo(() => {
        return getTransferStudentProps(formData, subAgentProfileData, partnerId);
    }, [subAgentProfileData, partnerId]);

    const onChangeApp = (data) => {
        setFormData(data);
    };

    const onSuccessCallback = (respData, data) => {
        if (data?.transfer_sudent == 'Yes') {
            changeSubAgentStatus(subAgentProfileData.id, {
                is_active: !subAgentProfileData.is_active,
            })
                .then((res) => {
                    updateSubAgentUserStatusData(res.data);
                    dispatch(addSubAgentToStore(deserializeSubagentInfo(res.data.data.User)));
                    dispatch(addAssignedStudentsToStore([]));
                    dispatch(resetStudentDataInStore());
                    dispatch(setToast(toastMessages.successMessage));
                })
                .catch((err) => {
                    dispatch(setToast(err?.response?.data?.error?.message));
                });
        } else {
            updateSubAgentUserStatusData(respData);
            dispatch(addSubAgentToStore(deserializeSubagentInfo(respData.data.User)));
            dispatch(setToast(toastMessages.successMessage));
        }
        setFormData({});
        handleClose();
    };

    return (
        <FormModal
            {...transferStudentProps}
            open={isModalOpen}
            onSuccessCallback={onSuccessCallback}
            handleClose={handleClose}
            onChangeApp={onChangeApp}
            isB2bFormModal={true}
        />
    );
}

TransferStudentModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    subAgentProfileData: PropTypes.object,
    updateSubAgentUserStatusData: PropTypes.func,
    partnerId: PropTypes.number,
    formData: PropTypes.object,
    setFormData: PropTypes.func,
};
