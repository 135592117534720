import { connectRouter } from 'connected-react-router';

// reducers
import { globalDataReducer } from './reducers/global';
import serviceCardsReducer from './reducers/serviceCmsReducer';
import servicesReducer from './reducers/servicesReducer';
import { userDataReducer } from './reducers/user';
import { partnerReducer } from './reducers/partner';
import studentReducer from './slices/studentSlice';
import toastReducer from './slices/toastSlice';
import universalDialogReducer from './slices/universalDialogSlice';
import refreshReducer from './slices/refreshSlice';
import subAgentReducer from './slices/subAgentSlice';

// configure root reducer
export default (history) => ({
    router: connectRouter(history),
    globalData: globalDataReducer,
    student: studentReducer,
    refreshPlatformData: refreshReducer,
    userData: userDataReducer,
    toaster: toastReducer,
    universalDialog: universalDialogReducer,
    serviceCards: serviceCardsReducer,
    services: servicesReducer,
    partnerData: partnerReducer,
    subAgentData: subAgentReducer,
});
