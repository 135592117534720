// actions
import { goToRoute } from '@/store/actions/navigationActions';

// utils
import routePaths, {
    applicationListFiltersQueries,
    courseFinderPaths,
    studentListFiltersQueries,
} from '@/utils/route-paths';

const getNavigationDrawerProps = (dispatch, isVisibleActions) => {
    let actionsList = [];
    if (isVisibleActions == true)
        actionsList = [
            {
                icon: 'dashboard',
                label: 'Dashboard',
                goto: () => {
                    dispatch(goToRoute(routePaths.DASHBOARD));
                },
                path: routePaths.DASHBOARD,
                isMobileMenu: true,
            },
            {
                icon: 'location-city',
                label: 'Students',
                goto: () => {
                    dispatch(goToRoute(routePaths.STUDENT_INDEX, {}, studentListFiltersQueries()));
                },
                path: routePaths.STUDENT_INDEX,
                matchPath: 'students',
                isMobileMenu: true,
            },
            {
                icon: 'fact-check',
                label: 'Applications',
                goto: () => {
                    dispatch(
                        goToRoute(routePaths.APPLICATION, {}, applicationListFiltersQueries()),
                    );
                },
                path: routePaths.APPLICATION,
                isMobileMenu: true,
            },
            {
                icon: 'search',
                label: 'Program Search',
                goto: () =>
                    dispatch(
                        goToRoute(
                            routePaths.COURSE_FINDER,
                            {
                                path: courseFinderPaths.VIEW,
                            },
                            { page: 1, per_page: 10, client_type: 'Client' },
                        ),
                    ),
                path: routePaths.COURSE_FINDER,
                matchPath: 'course-finder',
                isMobileMenu: true,
            },
            // {
            //     icon: 'handshake',
            //     label: 'New Tieups',
            //     goto: () =>
            //         dispatch(goToRoute(routePaths.TIEUPS, {}, { page: '1', per_page: '10' })),
            //     path: routePaths.TIEUPS,
            //     isMobileMenu: true,
            // },
        ];

    return {
        actionsList,
        styleProps: {
            width: '100%',
        },
    };
};

export default getNavigationDrawerProps;
