import React from 'react';
import { useDispatch } from 'react-redux';
import { toNumber, get } from 'lodash';
import moment from 'moment';

// mui
import { Box, Typography } from '@mui/material';

// actions
import { goToRoute } from '@/store/actions/navigationActions';

// comps
import { History, ApplicationDetailCard } from 'azent-storybook/dist';

// utils
import routePaths, { studentPaths } from '@/utils/route-paths';
import localStorageActions from '@/utils/localStorage';
import { capitalizeText } from '@/utils/textModifiers';

//prop constants
import { getLabelForApplicationStatus } from '@/props-constants/application/application-status';
import { getAppPriorityIconStyle } from '@/props-constants/application/application-profile';

export const SDS_STATUS = ['Pre Application', 'Application', 'Closed', 'Offer', 'Visa', 'Dropped'];

export const SDS_COLOR = {
    'Pre Application': '#BED3FF',
    Application: '#FFE3C1',
    Closed: '#D4D4D4',
    Offer: '#D5F4D5',
    Visa: '#9FE59F',
    Dropped: '#FFCBCB',
};

export const STUDENT_STATUS_COLOR = {
    profile_received: '#BED3FF',
    profile_assessment: '#BED3FF',
    shortlisting: '#D4D4D4',
    closed: '#FFCBCB',
    application: '#FFE3C1',
};

export const getAbstractStatus = (status) => {
    switch (status) {
        case 'profile_received':
        case 'shortlisting':
        case 'profile_assessment':
            return 'Pre Application';
        case 'application_fee_paid':
        case 'acknowledged_by_university':
        case 'deferred_by_university':
        case 'deferred':
        case 'dropped':
        case 'waitlisted_by_university':
        case 'accepted_by_student':
        case 'rejected_by_student':
        case 'deferred_by_student':
        case 'reported_on_campus':
        case 'rejected_by_university':
        case 'visa_rejected':
        case 'withdrawn':
            return 'Dropped';
        case 'visa_letter_received':
        case 'visa_applied':
        case 'visa_approved':
            return 'Visa';
        case 'deposit_paid':
        case 'offer_accepted_and_deposit_paid':
        case 'offered_by_university':
        case 'conditional_offered_by_university':
        case 'offer_received':
            return 'Offer';
        case 'submitted_by_partner': //NOTE: NEED TO CONFIRM THIS STATUS WITH ROHAN
        case 'reviewing_application':
        case 'building_application':
        case 'application_submitted':
            return 'Application';
        case 'closed':
            return 'Closed';
        default:
            return status;
    }
};

export const getContainerClass = (classes, AppStatus) => {
    switch (AppStatus) {
        case 'Pre Application':
            return classes.containerBlue;
        case 'Dropped':
            return classes.containerRed;
        case 'Visa':
            return classes.containerGreen;
        case 'Offer':
            return classes.containerLightGreen;
        case 'Application':
            return classes.containerYellow;
        case 'Closed':
            return classes.containerGrey;
        default:
            return classes.containerBlue;
    }
};

export const getTitleClass = (classes, AppStatus) => {
    switch (AppStatus) {
        case 'Pre Application':
            return classes.titleBlue;
        case 'Dropped':
            return classes.titleRed;
        case 'Visa':
            return classes.titleGreen;
        case 'Offer':
            return classes.titleLightGreen;
        case 'Application':
            return classes.titleYellow;
        case 'Closed':
            return classes.titleGrey;
        default:
            return classes.titleBlue;
    }
};

export const getStudentContainerClass = (classes, AppStatus) => {
    switch (AppStatus) {
        case 'profile_received':
        case 'Profile Received':
            return classes.containerStudentBlue;
        case 'profile_assessment':
        case 'Profile Assessment':
            return classes.containerStudentYellow;
        case 'shortlisting':
        case 'Shortlisting':
            return classes.containerStudentPurple;
        case 'closed':
        case 'Closed':
            return classes.containerStudentRed;
        case 'application':
        case 'Application':
            return classes.containerStudentGreen;
        default:
            return classes.containerStudentBlue;
    }
};

export const getStudentTitleClass = (classes, AppStatus) => {
    switch (AppStatus) {
        case 'profile_received':
        case 'Profile Received':
            return classes.titleStudentBlue;
        case 'profile_assessment':
        case 'Profile Assessment':
            return classes.titleStudentYellow;
        case 'shortlisting':
        case 'Shortlisting':
            return classes.titleStudentPurple;
        case 'closed':
        case 'Closed':
            return classes.titleStudentRed;
        case 'application':
        case 'Application':
            return classes.titleStudentGreen;
        default:
            return classes.titleStudentBlue;
    }
};

export const getFiltersFromSearchParams = (search) => {
    const filters = {};
    const searchObject = Object.fromEntries(search);
    if (searchObject.intake_month) filters.intake_month = searchObject.intake_month.split(',');
    if (searchObject.last_modified_by)
        filters.last_modified_by = searchObject.last_modified_by.split(',');
    if (searchObject.intake_year)
        filters.intake_year = searchObject.intake_year.split(',').map((d) => toNumber(d));
    if (searchObject.target_program)
        filters.target_program = searchObject.target_program.split(',');
    if (searchObject.target_countries)
        filters.target_countries = searchObject.target_countries.split(',');
    if (searchObject.profile_statuses)
        filters.profile_statuses = searchObject.profile_statuses.split(',');
    if (searchObject.partner) {
        const partners = [];
        searchObject.partner.split(',').forEach((id) => {
            partners.push({ id });
        });
        filters.partner = partners;
    }
    if (searchObject.psa_id) {
        const psa = [];
        searchObject.psa_id.split(',').forEach((id) => {
            psa.push({ id });
        });
        filters.psa = psa;
    }
    if (searchObject.passport_number) filters.passport_number = searchObject.passport_number;
    if (searchObject.owner) {
        const owners = [];
        searchObject.owner.split(',').forEach((id) => {
            owners.push({ id });
        });
        filters.owner = owners;
    }
    if (searchObject.sub_agent) {
        const sub_agents = [];
        searchObject.sub_agent.split(',').forEach((id) => {
            sub_agents.push({ id });
        });
        filters.sub_agent = sub_agents;
    }
    if (searchObject.created_date_from && searchObject.created_date_to)
        filters.created_date = {
            from: searchObject.created_date_from,
            to: searchObject.created_date_to,
        };

    return filters;
};

export const convertFilterDataToUrlFormat = (filterData) => {
    const filters = {};

    if (filterData.intake_month) filters.intake_month = filterData.intake_month;
    if (filterData.last_modified_by) filters.last_modified_by = filterData.last_modified_by;
    if (filterData.intake_year) filters.intake_year = filterData.intake_year;
    if (filterData.target_program) filters.target_program = filterData.target_program;
    if (filterData.target_countries) filters.target_countries = filterData.target_countries;
    if (filterData.profile_statuses) filters.profile_statuses = filterData.profile_statuses;
    if (filterData.partner) {
        const partners = [];
        filterData.partner.forEach((item) => {
            partners.push({ id: item.id });
        });
        filters.partner = partners;
    }
    if (filterData.psa) {
        const psa = [];
        filterData.psa.forEach((item) => {
            psa.push({ id: item.id });
        });
        filters.psa = psa;
    }
    if (filterData.passport_number) filters.passport_number = filterData.passport_number;
    if (filterData.owner) {
        const owners = [];
        filterData.owner.forEach((item) => {
            owners.push({ id: item.id });
        });
        filters.owner = owners;
    }
    if (filterData.sub_agent) {
        const sub_agents = [];
        filterData.sub_agent.forEach((item) => {
            sub_agents.push({ id: item.id });
        });
        filters.sub_agent = sub_agents;
    }
    if (filterData.created_date) {
        filters.created_date = filterData.created_date;
    }

    return filters;
};

export const getStudentListQueries = function (
    filtersData,
    page,
    per_page,
    searchText,
    pageChoice,
) {
    const query = [
        { key: 'page', value: pageChoice || page },
        { key: 'per_page', value: per_page || '10' },
        { key: 'q', value: searchText || '' },
    ];

    if (!filtersData) return query;

    if (filtersData.passport_number)
        query.push({
            key: 'passport_number',
            value: filtersData.passport_number || '',
        });
    if (filtersData.first_name)
        query.push({
            key: 'first_name',
            value: filtersData.first_name || '',
        });
    if (filtersData.last_name)
        query.push({
            key: 'last_name',
            value: filtersData.last_name || '',
        });
    if (filtersData.email) query.push({ key: 'email', value: filtersData.email || '' });
    if (filtersData.mobile) query.push({ key: 'mobile', value: filtersData.mobile || '' });
    if (filtersData.intake_month && filtersData.intake_month.length)
        query.push({
            key: 'intake_month',
            value: filtersData.intake_month ? filtersData.intake_month.join(',') : '',
        });
    if (filtersData.last_modified_by && filtersData.last_modified_by.length)
        query.push({
            key: 'last_modified_by',
            value: filtersData.last_modified_by ? filtersData.last_modified_by.join(',') : '',
        });
    if (filtersData.intake_year && filtersData.intake_year.length)
        query.push({
            key: 'intake_year',
            value: filtersData.intake_year ? filtersData.intake_year.join(',') : '',
        });
    if (filtersData.target_program && filtersData.target_program.length)
        query.push({
            key: 'target_program_keywords',
            value: filtersData.target_program ? filtersData.target_program.join(',') : '',
        });
    if (filtersData.target_countries && filtersData.target_countries.length)
        query.push({
            key: 'target_countries',
            value: filtersData.target_countries ? filtersData.target_countries.join(',') : '',
        });
    if (filtersData.profile_statuses && filtersData.profile_statuses.length)
        query.push({
            key: 'profile_statuses',
            value: filtersData.profile_statuses ? filtersData.profile_statuses.join(',') : '',
        });
    if (filtersData.partner && filtersData.partner.length)
        query.push({
            key: 'partner',
            value: filtersData.partner ? filtersData.partner.map((item) => item.id).join(',') : '',
        });
    if (filtersData.sub_agent && filtersData.sub_agent.length)
        query.push({
            key: 'sub_agent',
            value: filtersData.sub_agent
                ? filtersData.sub_agent.map((item) => item.id).join(',')
                : '',
        });
    if (filtersData.psa && filtersData.psa.length)
        query.push({
            key: 'psa_id',
            value: filtersData.psa ? filtersData.psa.map((item) => item.id).join(',') : '',
        });
    if (filtersData.owner && filtersData.owner.length)
        query.push({
            key: 'owner',
            value: filtersData.owner ? filtersData.owner.map((item) => item.id).join(',') : '',
        });
    if (filtersData.created_date) {
        if (filtersData.created_date.from)
            query.push({
                key: 'created_date_from',
                value: filtersData.created_date.from,
            });
        if (filtersData.created_date.to)
            query.push({
                key: 'created_date_to',
                value: filtersData.created_date.to,
            });
    }

    return query;
};

export const getApplicationsWithSDS = (applications) => {
    const data = SDS_STATUS.reduce((acc, curr) => {
        acc[curr] = {
            count: 0,
            applicationList: [],
            color: SDS_COLOR[curr],
        };
        return acc;
    }, {});
    applications.forEach((application) => {
        const status = getAbstractStatus(application?.application_status?.current_status);
        if (status && SDS_STATUS.includes(status)) {
            data[status].count += 1;
            data[status].applicationList.push(application);
        }
    });
    return data;
};

export const getHistoryProps = (history) => {
    let historyData = [...history];
    historyData = historyData.sort((a, b) => (moment(a.date).isBefore(moment(b.date)) ? 1 : -1));
    return historyData.map((item) => ({
        label: capitalizeText(item.status),
        description: `On: ${moment.utc(item.date).local().format('DD/MM/YYYY HH:mm')}`,
        name: `By: ${item.created_by__name}`,
    }));
};

const deserializeStudentForDrawer = (student, selectedApplicationId) => {
    const dispatch = useDispatch();

    let applicationList = get(student, 'applications', []);
    const profileStatus = get(student, 'profile_status', []);

    applicationList = applicationList.filter((student) => student._id != selectedApplicationId);

    const onViewDetails = (application) => {
        dispatch(
            goToRoute(routePaths.STUDENT_PROFILE_VIEW, {
                id: get(application, 'profile_id'),
                aid: get(application, '_id'),
                path: studentPaths.APPLICATION,
            }),
        );
    };

    const props = {
        name: `${get(student, 'first_name')} ${get(student, 'last_name')}`,
        contact: get(student, 'mobile', ''),
        email: get(student, 'email', ''),
        tabs: {
            tabProps: {
                sx: {
                    fontSize: '14px',
                    fontWeight: 600,
                    '&:active': {
                        color: 'primary.main',
                    },
                    '&.Mui-selected': {
                        color: 'primary.main',
                    },
                    '&.MuiButtonBase-root': {
                        padding: '12px 7px',
                    },
                },
            },
            tabList: [
                {
                    label: selectedApplicationId
                        ? 'Other Applications'
                        : `Total Applications ${
                              applicationList.length ? `(${applicationList.length})` : ''
                          }`,
                    content: () => (
                        <>
                            {applicationList.length ? (
                                <>
                                    {applicationList.map((application) => (
                                        <Box my={1} key={get(application, '_id')}>
                                            <ApplicationDetailCard
                                                universityName={get(
                                                    application,
                                                    'program_info.university_name',
                                                    '-',
                                                )}
                                                programName={get(
                                                    application,
                                                    'program_info.name',
                                                    '-',
                                                )}
                                                status={getLabelForApplicationStatus(
                                                    get(
                                                        application,
                                                        'application_status.current_status',
                                                        '-',
                                                    ),
                                                )}
                                                priority={get(application, 'preference', '')}
                                                getAppPriorityIconStyle={getAppPriorityIconStyle}
                                                comments={get(application, 'comments', [])}
                                                createdOn={get(application, 'created_at', '-')}
                                                ctaAction={() => onViewDetails(application)}
                                            />
                                        </Box>
                                    ))}
                                </>
                            ) : (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    height="100%"
                                    width="100%"
                                    pt={3}>
                                    <Typography textAlign="center" variant="h6">
                                        Connect with the AdmissionBox team to create an application
                                        today.
                                    </Typography>
                                </Box>
                            )}
                        </>
                    ),
                },
            ],
        },
    };

    if (!selectedApplicationId) {
        props.tabs.tabList.push({
            label: 'History',
            content: () => {
                return (
                    <>
                        {profileStatus.length ? (
                            <Box pl={5}>
                                <History history={getHistoryProps(profileStatus)} />
                            </Box>
                        ) : (
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                height="100%"
                                width="100%"
                                pt={3}>
                                <Typography textAlign="center" variant="h6">
                                    Student profile stages are shown here.
                                    <br /> Connect with the AdmissionBox team today.
                                </Typography>
                            </Box>
                        )}
                    </>
                );
            },
        });
    }

    return props;
};

export const getListStudentProps = (student, selectedApplicationId = '', dispatch = () => {}) => {
    const props = {
        studentDrawerProps: {},
        tableProps: {},
    };
    if (student) {
        const drawerProps = deserializeStudentForDrawer(student, selectedApplicationId);
        props.studentDrawerProps = {
            ...drawerProps,
            width: '26%',
            editButton: {
                label: 'Go To Profile',
                color: 'success',
                fullWidth: true,
                onClick: () =>
                    dispatch(
                        goToRoute(routePaths.STUDENT_PROFILE_VIEW, {
                            id: student.id,
                            path: studentPaths.SUMMARY,
                        }),
                    ),
                sx: {
                    padding: '7px 65px',
                    fontSize: '14px',
                },
                variant: 'outlined',
            },
        };
    }

    return props;
};

export const filterMsg = {
    default: 'Please click on Add New Student button',
    filters: 'No entry found! Please remove a few filters',
};

export const manageColumnsData = (title, visibleColumns) => {
    if (title === 'Students')
        localStorageActions.setDataInLocalStorage('StudentColumns', visibleColumns);
    if (title === 'Applications')
        localStorageActions.setDataInLocalStorage('ApplicationColumns', visibleColumns);
};
