import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//mui
import { Grid, Box, Typography, Divider } from '@mui/material';

//utils
import routePaths from '@/utils/route-paths';
import { removeAuthToken } from '@/utils/auth-token-utils';

// navigation action
import { goToRoute } from '@/store/actions/navigationActions';

//style
import { useStyles } from './style';

//components
import ImageCarousel from './imageCarousel';
import { Button } from 'azent-storybook/dist';

export default function Login() {
    const dispatch = useDispatch();
    const search = useSelector((state) => state.router.location.search);
    const params = new URLSearchParams(search);
    const isUTMParamCheck = params.get('utm_source') ? true : false;

    const classes = useStyles();

    useEffect(() => {
        removeAuthToken();
        window.location.pathname.toString() != routePaths.LOGIN &&
            dispatch(goToRoute(routePaths.LOGIN));
    }, []);

    return (
        <Box className={classes.rootContainer}>
            <Grid container alignItems="center">
                <Grid item xs={5}>
                    <ImageCarousel classes={classes} />
                </Grid>
                <Grid item xs={2}>
                    <div className={classes.verticalLine}></div>
                </Grid>
                <Grid item xs={5} pl={5}>
                    <Box className={classes.formHeaderContainer} mb={4}>
                        <Typography className={classes.beginTitle}>
                            Begin your journey with
                        </Typography>
                        <Typography className={classes.azentTitle}>AdmissionBox</Typography>
                    </Box>
                    <Box className={classes.formContentContainer}>
                        <Box className={classes.fieldContainer} sx={{ textAlign: 'center' }}>
                            <Typography className={classes.inputTitle}>{'New user'}</Typography>
                            <Button
                                fullWidth
                                variant={isUTMParamCheck ? 'contained' : 'outlined'}
                                color="primary"
                                onClick={() => dispatch(goToRoute(routePaths.SIGNUP_PAGE))}
                                label={'Register as Referral Partner'}
                                className={
                                    isUTMParamCheck
                                        ? `${classes.greenButton} ${classes.buttonWidth}`
                                        : `${classes.outlinedButton} ${classes.buttonWidth}`
                                }
                            />
                        </Box>
                        <Box my={3}>
                            <Divider>
                                <Typography variant={'title2'}>or</Typography>
                            </Divider>
                        </Box>
                        <Box className={classes.fieldContainer} sx={{ textAlign: 'center' }}>
                            <Typography className={classes.inputTitle}>
                                {'Existing user'}
                            </Typography>
                            <Button
                                fullWidth
                                variant={isUTMParamCheck ? 'oulined' : 'contained'}
                                color="primary"
                                onClick={() => dispatch(goToRoute(routePaths.SIGNIN_PAGE))}
                                label={'Sign In'}
                                className={
                                    isUTMParamCheck
                                        ? `${classes.outlinedButton} ${classes.buttonWidth}`
                                        : `${classes.greenButton} ${classes.buttonWidth}`
                                }
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
