import localStorageActions from './auth-token-utils/local-storage';

const setDataInLocalStorage = (key, value) => {
    try {
        if (localStorageActions.checkLocalStorageEnabled()) {
            window.localStorage.setItem(key, JSON.stringify(value));
            return true;
        }
    } catch {
        return false;
    }
};

const getDataFromLocalStorage = (key) => {
    try {
        if (localStorageActions.checkLocalStorageEnabled()) {
            return window.localStorage.getItem(key);
        }
    } catch {
        return null;
    }
};

const deleteDataFromLocalStorage = (key) => {
    try {
        if (localStorageActions.checkLocalStorageEnabled()) {
            window.localStorage.removeItem(key);
            return true;
        }
    } catch {
        return false;
    }
};

export default {
    setDataInLocalStorage,
    getDataFromLocalStorage,
    deleteDataFromLocalStorage,
};
