import React from 'react';

// mui
import { Box, Typography, Link } from '@mui/material';

// assets
import notAuthorised from '@/assets/svg/403.svg';
// routes
import { handleLogout } from '@/utils/route-paths';

export function NotAuthorised() {
    return (
        <Box
            width="100%"
            height="60%"
            display={'flex'}
            flexDirection={'column'}
            alignItems="center"
            justifyContent={'center'}>
            <img src={notAuthorised} alt="You Are not Authorised to view this page" />
            <Typography variant="body2" my={2}>
                Please connect with your Business Manager
            </Typography>
            <Link component="button" variant="h6" onClick={() => handleLogout()}>
                Go Back
            </Link>
        </Box>
    );
}

NotAuthorised.propTypes = {};
