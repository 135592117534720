import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        borderRadius: theme.spacing(1.25),
        background: '#F1F4F7',
        minHeight: '120px',
        padding: theme.spacing(1, 2, 5, 2),
        color: theme.palette.text.disabled,
        position: 'relative',
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(1, 0, 2, 1),
        },
    },
    value: {
        position: 'absolute',
        bottom: '10px',
    },
}));
