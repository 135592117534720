import moment from 'moment';
import { getCurrentDate, getDateFormat } from '@/utils/date';
import { getDocumentTaggingForComments } from '@/utils/partner-documents';

const monthsData = [
    {
        label: 'Jan',
        index: 0,
    },
    {
        label: 'Feb',
        index: 1,
    },
    {
        label: 'Mar',
        index: 2,
    },
    {
        label: 'Apr',
        index: 3,
    },
    {
        label: 'May',
        index: 4,
    },
    {
        label: 'Jun',
        index: 5,
    },
    {
        label: 'Jul',
        index: 6,
    },
    {
        label: 'Aug',
        index: 7,
    },
    {
        label: 'Sep',
        index: 8,
    },
    {
        label: 'Oct',
        index: 9,
    },
    {
        label: 'Nov',
        index: 10,
    },
    {
        label: 'Dec',
        index: 11,
    },
];
const currentYear = new Date().getFullYear();

export const BUILDINNG_APPLICATION = 'building_application';
export const REVIEWING_APPLICATION = 'reviewing_application';
export const READY_FOR_SUBMISSION = 'ready_for_submission';
export const INTAKE_NOT_OPEN_YET = 'intake_not_open_yet';
export const PAYMENT_REQUESTED = 'payment_requested';
export const PAYMENT_SUCCESSFUL = 'payment_successful';
export const REFUND_PENDING = 'refund_pending';
export const APPLICATION_SUBMITTED = 'application_submitted';
export const EXTRA_DOCUMENTS_REQUIRED_BY_THE_UNIVERSITY =
    'extra_documents_required_by_the_university';
export const OFFER_RECEIVED = 'offer_received';
export const GTE_VERIFICATION_REQUIRED = 'gte_verification_required';
export const GTE_DOCUMENTS_SENT_TO_UNIVERSITY = 'gte_documents_sent_to_university';
export const OFFER_ACCEPTED_AND_DEPOSIT_PAID = 'offer_accepted_and_deposit_paid';
export const VISA_DOCUMENTS_VERIFIED = 'visa_documents_verified';
export const VISA_APPLIED = 'visa_applied';
export const VISA_APPROVED = 'visa_approved';
export const ENROLLED = 'enrolled';
export const DEFERRED = 'deferred';
export const DROPPED = 'dropped';
export const WITHDRAWN = 'widhdrawn';

export const applicationStatus = [
    BUILDINNG_APPLICATION,
    REVIEWING_APPLICATION,
    READY_FOR_SUBMISSION,
    INTAKE_NOT_OPEN_YET,
    PAYMENT_REQUESTED,
    PAYMENT_SUCCESSFUL,
    REFUND_PENDING,
    APPLICATION_SUBMITTED,
    EXTRA_DOCUMENTS_REQUIRED_BY_THE_UNIVERSITY,
    OFFER_RECEIVED,
    GTE_VERIFICATION_REQUIRED,
    GTE_DOCUMENTS_SENT_TO_UNIVERSITY,
    OFFER_ACCEPTED_AND_DEPOSIT_PAID,
    VISA_DOCUMENTS_VERIFIED,
    VISA_APPLIED,
    VISA_APPROVED,
    ENROLLED,
    DEFERRED,
    DROPPED,
];

export const applicationStatusLabelMapping = {
    [BUILDINNG_APPLICATION]: 'Application Created On AdmissionBox',
    [REVIEWING_APPLICATION]: 'Application Incomplete',
    [READY_FOR_SUBMISSION]: 'Ready For Submission',
    [PAYMENT_REQUESTED]: 'Payment Requested',
    [PAYMENT_SUCCESSFUL]: 'Payment Successful',
    [REFUND_PENDING]: 'Refund Pending',
    [INTAKE_NOT_OPEN_YET]: 'Intake Not Open Yet',
    [APPLICATION_SUBMITTED]: 'Application Submitted To University',
    [EXTRA_DOCUMENTS_REQUIRED_BY_THE_UNIVERSITY]: 'Extra Documents Required Post Submission',
    [OFFER_RECEIVED]: 'Offer Received',
    [GTE_VERIFICATION_REQUIRED]: 'GTE Verification Required',
    [GTE_DOCUMENTS_SENT_TO_UNIVERSITY]: 'GTE Documents Sent To University',
    [OFFER_ACCEPTED_AND_DEPOSIT_PAID]: 'Offer Accepted / Deposit Paid',
    [VISA_DOCUMENTS_VERIFIED]: 'CAS / i20 / COE / LOA Received',
    [VISA_APPLIED]: 'Visa Applied',
    [VISA_APPROVED]: 'Visa Approved',
    [ENROLLED]: 'Enrolled',
    [DEFERRED]: 'Deferred',
    [DROPPED]: 'Dropped',
    [WITHDRAWN]: 'Withdrawn', // Deleted
};

export const subStatusChoices = {
    [OFFER_RECEIVED]: ['conditional', 'unconditional'],
    [OFFER_ACCEPTED_AND_DEPOSIT_PAID]: ['deposit_paid', 'deposit_not_required'],
    [DROPPED]: [
        'plan_dropped_by_student',
        'rejected_by_university',
        'rejected_by_university_partner',
        'gte_rejected',
        'fake_academic_documents',
        'fake_financial_documents',
        'non_commissionable_university',
        'non_commissionable_course',
        'documents_not_verified_or_no_proof_available',
        'regional_restriction_as_application_not_accepted_by_the_university',
        'rp_contract_terminated',
        'visa_rejected',
        'financial_constraint',
        'intake_closed',
        'not_eligible',
        'did_not_like_the_services',
        'did_not_join',
        'medical_issues_or_emergency_at_home',
        'high_workload_at_work',
        'did_not_get_the_desired_offer',
        'plan_dropped_because_of_delayed_offer_or_visa',
        'joined_another_program_in_india',
        'joined_another_program_in_abroad',
    ],
};

export const extraKeysOption = {
    missing_documents: [
        '10th Marksheet',
        '10th Pass Certificate',
        '10th Digilocker Marksheet',
        '10th Marksheet Verification Link',
        '12th Marksheet',
        '12th Pass Certificate',
        '12th Digilocker Marksheet',
        '12th Marksheet Verification Link',
        'Diploma Semester Wise Marksheet',
        'Diploma Passing Certificate',
        'Diploma Marksheet Verification Link',
        "Bachelor's Degree Semester Wise Marksheet",
        "Bachelor's Degree Transcript",
        "Bachelor's Degree Provisional Certificate",
        "Bachelor's Degree Original Certificate",
        "Bachelor's Marksheet Verification Link",
        "Bachelor's Backlog Marksheet / Certificate",
        'PG Diploma Semester Wise Marksheet',
        'PG Diploma Transcript',
        'PG Diploma Passing Certificate',
        'PG Diploma Marksheet Verification Link',
        'Diploma Backlog Marksheet / Certificate',
        "Master's Degree Semester Wise Marksheet",
        "Master's Degree Transcript",
        "Master's Degree Provisional Certificate",
        "Master's Degree Original Certificate",
        "Master's Marksheet Verification Link",
        "Master's Backlog Marksheet / Certificate",
        'PhD Semester Wise Marksheet',
        'PhD Transcript',
        'PhD Certificate',
        'PhD Marksheet Verification Link',
        'IELTS Scorecard',
        'TOEFL Scorecard',
        'PTE Scorecard',
        'Duolingo Scorecard',
        'GMAT Scorecard',
        'GRE Scorecard',
        'SAT Scorecard',
        'Any Other Acceptable Test Scorecard',
        'Medium of Instruction Letter',
        'Resume/CV',
        'Passport',
        'University & Course Specific SOP',
        'LOR 1 (Letter Head and Referee Contact Details)',
        'LOR 2 (Letter Head and Referee Contact Details)',
        'LOR 3 (Letter Head and Referee Contact Details)',
        'Covid Vaccination Certificate',
        'Introductory/Video SOP (Send an Email to Application Owner)',
        'Passport Size Photo',
        'Previous Visa Refusal Documents',
        'Old Passport Copy',
        'Gap Justification Documents',
        'Work Experience Letter (All Companies)',
        'Offer/Appointment Letter of Current Company',
        'Affidavit of Support/Finacial Affidavit - Notarized Paper',
        'Last 3 Months Salary Slip',
        'Last 6 Months Salary Slip',
        'Last 1 Year Salary Slip',
        'Last 3 Months Salary Bank Account Statement',
        'Last 6 Months Salary Bank Account Statement',
        'Last 1 Year Salary Bank Account Statement',
        'Form 16 (Last Year)',
        'Last 3 Months Any Other Bank Relevant Account Statement',
        'Last 6 Months Any Other Bank Relevant Account Statement',
        'Last 1 Year Any Other Bank Relevant Account Statement',
        'Business - Company Establishment Letter & Registration Proof',
        'Business - Partnership Deed',
        'Last 1 Year IT Return & Income Computation',
        'Last 2 Years IT Return & Income Computation',
        'Last 3 Years IT Return & Income Computation',
        'Spouse Passport',
        'Marriage Certificate',
        'Educational Marksheets & Certificates of Spouse (10th / 12th / Diploma / UG / PG)',
        'Salary Slip, Work Experience Letter, Last 3/6 Months Bank statement of Spouse',
        'Spouse Business Proof Document',
        'Emergency Contact Details',
        'Credit Card Details',
        'Evaluated Transcripts',
        'Application Login Credentials',
        'Alternate Email Id',
        'Alternate Phone Number',
        'University Application Form',
        'Authority Letter / Consent Form',
        'Change of Agent Authorisation Letter',
        'On Hold',
        'Not Eligible - English Score',
        'Not Eligible - Educational Marks',
        'Not Eligible - High Gap',
        'Not Eligible - Experience',
        'Not Eligible - Others',
        'Others',
    ],
    application_source: [
        'University (Direct)',
        'Navitas',
        'Applyboard',
        'Australian Technical & Management College',
        'Study Group',
        'Kaplan International Pathways',
        'Educo',
        'Career Mosaic',
        'Crizac',
        'Krishna Consultant',
        'Cambridge Education Group - CEG',
        'Ukraine Education Service',
        'INTO',
        'QA Higher Education',
        'Shorelight Education',
        'Council for American Education - CAE',
        'StudyLink',
        'V&U',
        'GUS',
        'EAG',
        'UCAS',
        'MSM',
        'Paper-Based Application',
        'University Options',
    ],
    month: monthsData.map((item) => item.label),
    year: [currentYear, currentYear + 1, currentYear + 2],
};

const extraKeysToStatusMapping = {
    // building_application: [{ key: 'followup_date', required: true }],
    reviewing_application: [
        { key: 'missing_documents', required: true },
        // { key: 'followup_date', required: true },
    ],
    // ready_for_submission: [{ key: 'followup_date', required: true }],
    // intake_not_open_yet: [{ key: 'followup_date', required: true }],
    application_submitted: [
        { key: 'application_source', required: true },
        { key: 'application_id' },
        // { key: 'followup_date', required: true },
        // {
        //     key: 'status_date',
        //     label: 'Please enter the date when application was made on the university platform',
        // },
    ],
    extra_documents_required_by_the_university: [
        { key: 'missing_documents' },
        // { key: 'followup_date', required: true },
    ],
    offer_received: [
        // { key: 'followup_date', required: true },
        // {
        //     key: 'status_date',
        //     label: 'Please enter the date mentioned on the offer letter',
        // },
        // { key: 'offer_acceptance_deadline_date' },
    ],
    offer_accepted_and_deposit_paid: [
        // { key: 'followup_date', required: true },
        // {
        //     key: 'status_date',
        //     required: true,
        //     label: "Please enter the date mentioned on the proof. In case of no proof, enter today's date",
        // },
    ],
    // visa_documents_verified: [{ key: 'followup_date', required: true }],
    // visa_applied: [{ key: 'followup_date', required: true }],
    visa_approved: [
        {
            key: 'status_date',
            required: true,
            label: 'Please enter the correct date, on which visa was approved',
        },
    ],
    deferred: [
        { key: 'deferred_date_month', required: true },
        { key: 'deferred_date_year', required: true },
        // { key: 'followup_date', required: true, options: { formatMaximum: null } },
    ],
    payment_requested: [
        { key: 'currency', required: true },
        { key: 'application_fee', required: true },
    ],
};

export const isSubStatusKey = (appStatus) => {
    return Object.keys(subStatusChoices).includes(appStatus);
};

const addRequiredFieldsToSchemaFromStatus = (appStatus, schema) => {
    if (isSubStatusKey(appStatus)) {
        schema.required.push('application_sub_status');
    }

    if (extraKeysToStatusMapping[appStatus] && extraKeysToStatusMapping[appStatus].length) {
        schema.required.push(
            ...extraKeysToStatusMapping[appStatus]
                .filter((item) => item.required)
                .map((item) => item.key),
        );
    }

    return schema;
};

const addRequiredFieldsToUiSchemaFromStatus = (appStatus) => {
    let fields = [];
    const uiSchemaSkeleton = {
        type: 'HorizontalLayout',
        elements: [],
        rule: {},
    };
    const keyUiSchemas = {
        application_sub_status: {
            type: 'Control',
            scope: '#/properties/application_sub_status',
            label: 'Application Sub Status',
            options: {
                labelTransformation: 'startCase',
            },
        },
        followup_date: {
            type: 'Control',
            scope: '#/properties/followup_date',
            label: 'Enter follow-up date',
            options: {
                formatMinimum: new Date(moment()),
                formatMaximum: new Date(moment().add(2, 'weeks')),
            },
        },
        status_date: {
            type: 'Control',
            scope: '#/properties/status_date',
            label: 'Please enter the correct date',
            options: {
                formatMinimum: new Date(moment().subtract(2, 'weeks')),
            },
        },
        offer_acceptance_deadline_date: {
            type: 'Control',
            scope: '#/properties/offer_acceptance_deadline_date',
            label: 'Deadline for the type of offer received',
            options: {
                formatMinimum: new Date(moment()),
            },
        },
        missing_documents: {
            type: 'Control',
            scope: '#/properties/missing_documents',
            label: 'Missing Documents',
            options: {
                component: 'multiSelect',
            },
        },
        application_source: {
            type: 'Control',
            scope: '#/properties/application_source',
            label: 'Application source',
        },
        application_id: {
            type: 'Control',
            scope: '#/properties/application_id',
            label: 'Application Id',
        },
        deferred_date_month: {
            type: 'Control',
            scope: '#/properties/deferred_date_month',
            label: 'Month',
        },
        deferred_date_year: {
            type: 'Control',
            scope: '#/properties/deferred_date_year',
            label: 'Year',
        },
    };

    if (isSubStatusKey(appStatus)) {
        let uiSchema = { ...uiSchemaSkeleton, elements: [], rule: {} };
        uiSchema.elements.push(keyUiSchemas['application_sub_status']);
        uiSchema.rule = {
            effect: 'SHOW',
            condition: {
                scope: '#/properties/application_status',
                schema: { enum: Object.keys(subStatusChoices) },
            },
        };
        fields.push(uiSchema);
    }

    if (extraKeysToStatusMapping[appStatus] && extraKeysToStatusMapping[appStatus].length) {
        extraKeysToStatusMapping[appStatus].forEach((item) => {
            let uiSchema = { ...uiSchemaSkeleton, elements: [], rule: {} };
            let keyUischema = {};

            if (keyUiSchemas[item.key]) {
                keyUischema = { ...keyUiSchemas[item.key] };
            } else {
                return;
            }
            if (item.label) keyUischema.label = item.label;
            if (item.options) {
                if (keyUischema.options) {
                    keyUischema.options = { ...keyUischema.options, ...item.options };
                } else {
                    keyUischema.options = { ...item.options };
                }
            }
            uiSchema.elements.push(keyUischema);
            uiSchema.rule = {
                effect: 'SHOW',
                condition: {
                    scope: '#/properties/application_status',
                    schema: { const: appStatus },
                },
            };

            fields.push(uiSchema);
        });
    }

    return fields;
};

export const isExtraKeyForStatus = (key, appStatus) => {
    return !!(
        extraKeysToStatusMapping[appStatus] &&
        extraKeysToStatusMapping[appStatus].length &&
        extraKeysToStatusMapping[appStatus].filter((item) => item.key === key).length
    );
};

const getStatusDate = (appStatusDetails = {}) => {
    let date = null;
    let appStatus = appStatusDetails && appStatusDetails.current_status;
    switch (appStatus) {
        case 'application_submitted':
            date = appStatusDetails.application_submitted_date;
            break;
        case 'offer_received':
            date = appStatusDetails.offer_received_date;
            break;
        case 'offer_accepted_and_deposit_paid':
            date = appStatusDetails.offer_accepted_and_deposit_paid_date;
            break;
        case 'visa_approved':
            date = appStatusDetails.visa_approved_date;
            break;
    }
    return date;
};

export const getFollowupDateFromStatus = (
    appStatus,
    { deferred_date_month: month, deferred_date_year: year } = {},
) => {
    let followup_date = getCurrentDate();
    switch (appStatus) {
        case 'building_application':
        case 'reviewing_application':
        case 'ready_for_submission': {
            return followup_date;
        }
        case 'intake_not_open_yet':
        case 'extra_documents_required_by_the_university': {
            return moment().add(1, 'days').format('YYYY-MM-DD');
        }
        case 'application_submitted':
        case 'offer_received':
        case 'offer_accepted_and_deposit_paid':
        case 'visa_applied': {
            return moment().add(7, 'days').format('YYYY-MM-DD');
        }
        case 'visa_documents_verified': {
            return moment().add(3, 'days').format('YYYY-MM-DD');
        }
        case 'deferred': {
            if (!month || !year) {
                return undefined;
            }
            let monthIndex = monthsData.findIndex((item) => item.label === month);
            let calculatedDate = moment([year, monthIndex, 1])
                .subtract(2, 'months')
                .format('YYYY-MM-DD');
            return moment(calculatedDate).isAfter(moment().format('YYYY-MM-DD'))
                ? calculatedDate
                : undefined;
        }
        default: {
            return null;
        }
    }
};

export const getCreateApplicationProps = (appStatus, classes, appData) => {
    let _subStatus = [''];
    if (isSubStatusKey(appStatus)) {
        _subStatus = subStatusChoices[appStatus];
    }

    let schema = {
        type: 'object',
        properties: {
            application_status: {
                type: 'string',
                enum: applicationStatus,
            },
            application_sub_status: {
                type: 'string',
                enum: _subStatus,
            },
            missing_documents: {
                type: 'array',
                items: {
                    type: 'string',
                    enum: extraKeysOption['missing_documents'],
                },
                minItems: appStatus == 'reviewing_application' ? 1 : 0,
            },
            application_source: {
                type: 'string',
                enum: extraKeysOption['application_source'],
            },
            application_id: {
                type: 'string',
            },
            deferred_date_month: {
                type: 'string',
                enum: extraKeysOption['month'],
            },
            deferred_date_year: {
                type: 'number',
                enum: extraKeysOption['year'],
            },
            status_date: {
                type: 'string',
                format: 'date',
            },
            followup_date: {
                type: 'string',
                format: 'date',
            },
            offer_acceptance_deadline_date: {
                type: 'string',
                format: 'date',
            },
            currency: {
                type: 'string',
            },
            application_fee: {
                type: 'number',
                minimum: 1,
                maximum: 999,
                allOf: [{ type: 'integer' }],
                errorMessage: {
                    minimum: 'Application fee cannot be zero or negative',
                    maximum: 'Application fee should be upto 3 digits only',
                },
            },
            files: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        original_file_name: {
                            type: 'string',
                        },
                        url: {
                            type: 'string',
                        },
                        objectId: {
                            type: 'string',
                        },
                        type: {
                            type: 'string',
                        },
                    },
                },
            },
        },
        required: ['application_status'],
    };

    schema = addRequiredFieldsToSchemaFromStatus(appStatus, schema);

    const uiSchema = {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/application_status',
                        label: 'Application Status',
                        options: {
                            enumOptions: applicationStatus.map((item) => ({
                                label: getLabelForApplicationStatus(item),
                                value: item,
                            })),
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/currency',
                        label: 'Currency',
                        options: {
                            readOnly: true,
                        },
                    },
                    {
                        type: 'Control',
                        scope: '#/properties/application_fee',
                        label: 'Application Fee',
                    },
                ],
                rule: {
                    effect: 'SHOW',
                    condition: {
                        scope: '#/properties/application_status',
                        schema: { const: 'payment_requested' },
                    },
                },
            },
            ...addRequiredFieldsToUiSchemaFromStatus(appStatus),
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/files',
                        label: 'Supporting Documents',
                        options: {
                            component: 'resource',
                            selectCategoryLabel: 'Choose Document Category',
                            uniqueCategory: false,
                            fileCategories: getDocumentTaggingForComments(),
                        },
                    },
                ],
                rule: {
                    effect: 'SHOW',
                    condition: {
                        scope: '#/properties/application_status',
                        schema: { enum: applicationStatus },
                    },
                },
            },
        ],
    };

    // sending the default data to form
    let defaultAppData = {
        application_status:
            (appData && appData.application_status && appData.application_status.current_status) ||
            '',
        comment: '',
    };

    if (
        appData &&
        appData.application_status &&
        (appStatus === appData.application_status.current_status || !appStatus)
    ) {
        let application_status = appData.application_status.current_status || '';
        let application_sub_status = appData.application_status.current_sub_status || '';
        let followup_date = appData.application_status.followup_date || null;
        let application_source = appData.application_source || '';
        let application_id = appData.application_id || '';
        let deferred_date_month = appData.application_status.deferred_date_month || '';
        let deferred_date_year = appData.application_status.deferred_date_year || '';
        let offer_acceptance_deadline_date =
            appData.application_status.offer_acceptance_deadline_date || null;
        let isOtherData = !!(
            appData.application_status.other_data && appData.application_status.other_data.length
        );

        let currency = appData.application_fee?.currency;
        let application_fee = appData.application_fee?.amount || '';

        if (isSubStatusKey(application_status))
            defaultAppData.application_sub_status = application_sub_status;
        if (isExtraKeyForStatus('status_date', application_status)) {
            let status_date = getStatusDate(appData.application_status);
            if (status_date) {
                defaultAppData.status_date = getDateFormat(status_date);
            }
        }
        if (followup_date && isExtraKeyForStatus('followup_date', application_status))
            defaultAppData.followup_date = getDateFormat(followup_date);
        if (isOtherData && isExtraKeyForStatus('missing_documents', application_status)) {
            let missingDocuments = appData.application_status.other_data.find(
                (item) => item.name === 'missing_documents',
            );
            if (missingDocuments && missingDocuments.details)
                defaultAppData.missing_documents = missingDocuments.details;
        }
        if (application_source && isExtraKeyForStatus('application_source', application_status))
            defaultAppData.application_source = application_source;
        if (application_id && isExtraKeyForStatus('application_id', application_status))
            defaultAppData.application_id = application_id;
        if (deferred_date_month && isExtraKeyForStatus('deferred_date_month', application_status))
            defaultAppData.deferred_date_month = deferred_date_month;
        if (deferred_date_year && isExtraKeyForStatus('deferred_date_year', application_status))
            defaultAppData.deferred_date_year = deferred_date_year;
        if (
            offer_acceptance_deadline_date &&
            isExtraKeyForStatus('offer_acceptance_deadline_date', application_status)
        )
            defaultAppData.offer_acceptance_deadline_date = getDateFormat(
                offer_acceptance_deadline_date,
            );
        if (currency) defaultAppData.currency = currency;
        if (application_fee) defaultAppData.application_fee = application_fee;
    }

    const props = {
        schema: schema,
        uiSchemas: {
            largeScreenUiSchema: uiSchema,
            smallScreenUiSchema: {},
        },
        modalTitle: {
            title: 'Update application status',
        },
        classes: {
            cModalTitleContainer: classes.cModalTitleContainer,
            cModalTitle: classes.cModalTitle,
        },
        showPreview: false,
        defaultData: defaultAppData,
    };
    return props;
};

export const getLabelForApplicationStatus = (appStatus) => {
    return applicationStatusLabelMapping[appStatus];
};

export const getContainerClass = (classes, AppStatus) => {
    switch (AppStatus) {
        case 'building_application':
        case 'reviewing_application':
            return classes.containerBlue; //blue
        case 'application_submitted':
            return classes.containerYellow; //yellow
        case 'offer_received':
        case 'offer_accepted_and_deposit_paid':
        case 'visa_applied':
        case 'visa_approved':
        case 'payment_requested':
            return classes.containerGreen; //green
        case 'withdrawn':
        case 'deferred':
            return classes.containerGrey; //grey
        case 'dropped':
            return classes.containerRed; //red
        default:
            return classes.containerBlue;
    }
};

export const getTitleClass = (classes, AppStatus) => {
    switch (AppStatus) {
        case 'building_application':
        case 'reviewing_application':
            return classes.titleBlue; //blue
        case 'application_submitted':
            return classes.titleYellow; //yellow
        case 'offer_received':
        case 'offer_accepted_and_deposit_paid':
        case 'visa_applied':
        case 'visa_approved':
            return classes.titleGreen; //green
        case 'withdrawn':
        case 'deferred':
            return classes.titleGrey; //grey
        case 'dropped':
            return classes.titleRed; //red
        default:
            return classes.titleBlue;
    }
};

export const checkPaymentStatus = (appStatus, toastMessages, applicationData) => {
    if (appStatus === 'payment_successful') {
        return toastMessages.paymentSuccessStatus;
    }
    if (appStatus === GTE_DOCUMENTS_SENT_TO_UNIVERSITY) {
        return toastMessages.gteVerificationCompletedStatus;
    }

    const { application_payment_link, application_payment_id } = applicationData;
    if (appStatus === 'payment_requested' && application_payment_link && !application_payment_id) {
        return toastMessages.paymentRequestedError;
    }
    return null;
};

export const getPaymentLink = (appStatus, paymentLink) => {
    return paymentLink && appStatus === 'payment_requested' ? paymentLink : null;
};

export const getApplicationSubstatusEnum = (data) => {
    const temp = data && data.length ? data : [];
    const result = temp.flatMap((value) =>
        value in subStatusChoices ? subStatusChoices[value] : [],
    );
    return result;
};
