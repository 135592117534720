import React from 'react';

// utils
import { getFullName } from '@/utils/deserializers/student-deserializer';
import { getUserAvatarByGender } from '@/utils/get-user-avatar';
import routePaths, { studentPaths, courseFinderPaths } from '@/utils/route-paths';
import { get, startCase, keyBy, mapValues, snakeCase } from 'lodash';
import { getCourseFinderQueries, getStudentDefaultFilters } from '@/utils/courseFinder';

// comps
import ProfileEditFormBox from '@/components/modules/Students/StudentProfile/partials/StudentDetails/partials/ProfileJourney';
import ProfileStepper from '@/components/modules/Students/StudentProfile/partials/StudentDetails/partials/ProfileStepper';
import Summary from '@/components/modules/Students/StudentProfile/partials/StudentDetails/partials/Summary';
import ApplicationStepper from '@/components/modules/Application/StudentApplication/ApplicationStepper';
import StudentApplication from '@/components/modules/Application/StudentApplication';
import AncillaryStepper from '@/components/modules/Services/AncillaryStepper';
import StudentService from '@/components/modules/Services/StudentService/StudentService';

// actions
import { goToRoute } from '@/store/actions/navigationActions';
import { downloadFile, downloadMultipleFiles } from '@/utils/export';

export const STUDENT_STATUS_CHOICES = [
    'profile_received',
    'profile_assessment',
    'shortlisting_required',
    'shortlisting',
    'closed',
    'application',
];

export const decodeSdsChoice = (status) => {
    let sds = '';
    const mapping = {
        profile_received: ['profile_received'],
        profile_assessment: ['profile_assesment', 'profile_assessment'],
        shortlisting: ['editing', 'shortlisting', 'student_followup_required', 'taking_ielts'],
        shortlisting_required: ['shortlisting_required'],
        closed: ['exit_blueprint', 'closed'],
        application: [
            'admit_received',
            'application_submitted',
            'building_application',
            'completed',
            'counseling_pre-departure',
            'counseling_visa',
            'deferred',
            'delayed',
            'deposit_paid',
            'dropped',
            'visa_approved',
            'reviewing_application',
            'offer_received',
            'visa_applied',
            'application',
        ],
    };
    Object.keys(mapping).forEach((key) => {
        if (mapping[key].includes(status)) {
            sds = key;
        }
    });
    return sds;
};
export const getTileIndexFromRoute = (route) => {
    switch (route) {
        case studentPaths.SUMMARY:
            return 0;
        case studentPaths.VIEW:
            return 1;
        case studentPaths.APPLICATION:
            return 4;
        case studentPaths.SERVICES:
            return 5;
    }
};
export const getRouteFromTileIndex = (index) => {
    switch (index) {
        case 0:
            return studentPaths.SUMMARY;
        case 1:
            return studentPaths.VIEW;
        case 4:
            return studentPaths.APPLICATION;
        case 5:
            return studentPaths.SERVICES;
    }
};

export const getTileContentPropsFromIndex = (tileIndex, allProps) => {
    const props = {};
    props.currentFormIndex = allProps.editFormCurrentFormIndex;
    props.onStepChange = allProps.editFormOnStepChange;
    props.formsData = allProps.editFormFormsData;
    props.setCurrentFormIndex = allProps.editFormSetCurrentFormIndex;
    props.applications = allProps.applications;
    props.tileIndex = tileIndex;
    props.studentId = allProps.studentId;
    props.services = allProps.services;
    return props;
};

export const getMainContentPropsFromIndex = (tileIndex, allProps) => {
    const props = {};
    switch (tileIndex) {
        case 0: {
            props.profileData = allProps.profileData;
            return props;
        }
        case 1: {
            props.currentFormIndex = allProps.editFormCurrentFormIndex;
            props.submittedData = allProps.editFormSubmittedData;
            props.formsData = allProps.editFormFormsData;
            props.setSubmittedData = allProps.editFormSetSubmittedData;
            props.styleProps = allProps.editFormStyleProps;
            props.setDirtyFormData = allProps.editFormSetDirtyFormData;
            props.onChangeHandler = allProps.editFormOnChangeHandler;
            props.onSuccessCallback = allProps.editFormOnSuccessCallback;
            props.moveToNextForm = allProps.editFormMoveToNextForm;
            props.onJourneySuccess = allProps.editFormOnJourneySuccess;
            props.previousButton = allProps.editFormPreviousButton;
            props.moveToPreviousForm = allProps.editFormMoveToPreviousForm;
            props.defaultData = allProps.editFormDefaultData;

            return props;
        }
        case 4: {
            props.applicationId = allProps.applicationId;
            props.applications = allProps.applications;
            props.crmStudentId = allProps.profileData.crm_uid;
            props.studentFullName = `${snakeCase(allProps.profileData.first_name)}_${snakeCase(
                allProps.profileData.last_name,
            )}`;

            return props;
        }
        case 5: {
            props.serviceId = allProps.serviceId;
            props.services = allProps.services;
            return props;
        }
    }
};

export const getStudentProfileProps = (studentData = {}, dispatch = () => {}) => {
    const props = {
        studentHeaderProps: {
            avatar: getUserAvatarByGender(studentData.gender),
            name: getFullName(studentData),
            email: get(studentData, 'email', ''),
            mobile: get(studentData, 'mobile', ''),
            studentId: get(studentData, 'crm_uid', ' -'),
            point_of_contact: get(studentData, 'point_of_contact', null),
            inbox_credentials: get(studentData, 'inbox_credentials', null),
            partnerData: {
                partnerName: get(studentData, 'partner__name', ''),
                partnerMobile: get(studentData, 'partner__mobile', ''),
                partnerEmail: get(studentData, 'partner__email', ''),
            },
            studentStatus: getProfileStatusAndSubStatus(studentData),
            psaInfo: {
                email: get(studentData, 'psa_email', ''),
                name: get(studentData, 'psa_name', ''),
                mobile: get(studentData, 'psa_mobile', ''),
            },
            sub_agent: get(studentData, 'sub_agent', {}),
            sub_agent_id: get(studentData, 'sub_agent_id', null),
        },
        studentDetailProps: {
            tiles: [
                {
                    title: 'Summary',
                    onClick: () => {
                        dispatch(
                            goToRoute(routePaths.STUDENT_PROFILE_VIEW, {
                                id: studentData.id,
                                path: studentPaths.SUMMARY,
                            }),
                        );
                    },
                    mainContent: ({ profileData }) => {
                        return <Summary profileData={profileData} />;
                    },
                },
                {
                    title: 'My Profile ',
                    subTitle: studentData.profile_completion_percentage
                        ? `${studentData.profile_completion_percentage}%`
                        : '',
                    tileContent: (props) => {
                        const { formsData, currentFormIndex, onStepChange, setCurrentFormIndex } =
                            props;
                        return (
                            <ProfileStepper
                                steps={formsData.map((formData) => ({
                                    title: formData.title,
                                }))}
                                currentFormIndex={currentFormIndex}
                                setCurrentFormIndex={(index) => {
                                    onStepChange && onStepChange(index, formsData[index].title);
                                    setCurrentFormIndex(index);
                                }}
                            />
                        );
                    },
                    mainContent: (props) => {
                        const {
                            currentFormIndex,
                            submittedData,
                            formsData,
                            setSubmittedData,
                            styleProps,
                            setDirtyFormData,
                            onChangeHandler,
                            onSuccessCallback,
                            moveToNextForm,
                            onJourneySuccess,
                            previousButton,
                            moveToPreviousForm,
                            defaultData,
                        } = props;

                        return (
                            <ProfileEditFormBox
                                key={currentFormIndex}
                                defaultData={
                                    submittedData
                                        ? submittedData[currentFormIndex]
                                        : defaultData[currentFormIndex]
                                }
                                schema={formsData[currentFormIndex].schema}
                                uiSchemas={formsData[currentFormIndex].uiSchemas}
                                downloadFile={downloadFile}
                                downloadMultipleFiles={downloadMultipleFiles}
                                profileData={studentData}
                                submitButton={{
                                    ...formsData[currentFormIndex].submitButton,
                                    onClick: async (formData, formConstants) => {
                                        let finalData = {
                                            ...submittedData,
                                            [currentFormIndex]: {
                                                ...formConstants,
                                                ...formData,
                                            },
                                        };

                                        setSubmittedData(finalData);
                                        return formsData[currentFormIndex].submitButton.onClick(
                                            finalData,
                                            formData,
                                            formConstants,
                                        );
                                    },
                                    onAutoSave: async (formData, formConstants) => {
                                        let finalData = {
                                            ...submittedData,
                                            [currentFormIndex]: {
                                                ...formConstants,
                                                ...formData,
                                            },
                                        };

                                        return formsData[currentFormIndex].submitButton.onAutoSave(
                                            finalData,
                                            formData,
                                            formConstants,
                                        );
                                    },
                                }}
                                styleProps={styleProps}
                                formConstants={formsData[currentFormIndex].formConstants}
                                uploadConfig={formsData[currentFormIndex].uploadConfig}
                                onChangeHandler={(formData, formConstants) => {
                                    let finalData = {
                                        ...submittedData,
                                        [currentFormIndex]: {
                                            ...formConstants,
                                            ...formData,
                                        },
                                    };

                                    setDirtyFormData(finalData);
                                    onChangeHandler && onChangeHandler(finalData);
                                }}
                                onSuccessCallback={async (
                                    responseData,
                                    formData,
                                    formConstants,
                                ) => {
                                    onSuccessCallback &&
                                        (await onSuccessCallback(
                                            responseData,
                                            formData,
                                            formConstants,
                                            currentFormIndex,
                                        ));
                                    if (currentFormIndex < formsData.length - 1) {
                                        moveToNextForm();
                                    } else {
                                        onJourneySuccess();
                                    }
                                }}
                                previousButton={
                                    currentFormIndex === 0
                                        ? null
                                        : {
                                              ...previousButton,
                                              onClick: moveToPreviousForm,
                                          }
                                }
                            />
                        );
                    },
                },
                {
                    title: 'Program Search',
                    onClick: () => {
                        dispatch(
                            goToRoute(
                                routePaths.COURSE_FINDER,
                                {
                                    id: studentData.id,
                                    path: courseFinderPaths.RECOMMENDED_COURSES,
                                },
                                {
                                    page: 1,
                                    per_page: 10,
                                    ...mapValues(
                                        keyBy(
                                            getCourseFinderQueries(
                                                getStudentDefaultFilters(studentData),
                                                '',
                                                studentData.id,
                                            ),
                                            'key',
                                        ),
                                        'value',
                                    ),
                                    goBack: true,
                                },
                            ),
                        );
                    },
                    mainContent: () => {},
                },
                {
                    title: 'Shortlist',
                    onClick: () => {
                        dispatch(
                            goToRoute(
                                routePaths.COURSE_FINDER,
                                {
                                    id: studentData.id,
                                    path: courseFinderPaths.SHORTLISTS,
                                },
                                {
                                    page: 1,
                                    per_page: 10,
                                    profileId: studentData.id,
                                    goBack: true,
                                },
                            ),
                        );
                    },
                    mainContent: () => {},
                },
                {
                    title: `Application${
                        studentData &&
                        studentData.applications &&
                        studentData.applications.length > 0
                            ? studentData.applications.length == 1
                                ? ` (${studentData.applications.length})`
                                : `s (${studentData.applications.length})`
                            : ''
                    }`,
                    tileContent: (props) => {
                        const { applications } = props;
                        return (
                            applications &&
                            applications.length > 0 && (
                                <ApplicationStepper
                                    applications={applications}
                                    applicationCount={applications.length}
                                />
                            )
                        );
                    },
                    mainContent: (props) => {
                        const { applications, applicationId, crmStudentId, studentFullName } =
                            props;
                        return (
                            <StudentApplication
                                applicationId={applicationId}
                                applications={applications}
                                crmStudentId={crmStudentId}
                                studentFullName={studentFullName}
                            />
                        );
                    },
                },
                {
                    title: 'Ancillary Services',
                    tileContent: (props) => {
                        const { services, studentId, serviceId } = props;
                        const service_id = serviceId
                            ? serviceId
                            : !services.length
                            ? null
                            : services[0].cms_service_id;
                        return (
                            services &&
                            services.length > 0 && (
                                <AncillaryStepper
                                    services={services}
                                    servicesCount={services.length}
                                    studentId={studentId}
                                    serviceId={service_id}
                                />
                            )
                        );
                    },
                    mainContent: (props) => {
                        const { services, serviceId } = props;
                        const studentId = studentData.id;
                        const service_id = serviceId
                            ? serviceId
                            : !services.length
                            ? null
                            : services[0].cms_service_id;
                        return (
                            <StudentService
                                studentId={studentId}
                                serviceId={service_id}
                                services={services}
                            />
                        );
                    },
                },
            ],
        },
    };
    return props;
};

export const getReferenceDocs = (history) => {
    const referenceDocuments = [];

    if (history && history.length) {
        history.forEach((item) => {
            if (item.supporting_documents.length)
                item.supporting_documents.forEach((document) =>
                    referenceDocuments.push({
                        ...document,
                        name: item.status,
                        createdBy: item.created_by__name,
                        createdAt: item.date,
                    }),
                );
        });
    }
    return referenceDocuments;
};

export const getCommentDocs = (comments) => {
    if (comments)
        return comments
            .map((comment) => {
                return comment.files.map((file) => {
                    return {
                        file: file,
                        createdBy: comment.created_by__name,
                        createdAt: comment.created_at,
                    };
                });
            })
            .flat()
            .filter((item) => !!item);
    return [];
};

export const getIntakeFromProfile = (profile) => {
    if (
        profile.desired_profile &&
        profile.desired_profile.intake_month &&
        profile.desired_profile.intake_year
    ) {
        return `${profile.desired_profile.intake_month} - ${profile.desired_profile.intake_year}`;
    }
    return '-';
};

export const getTargetCountries = (profile) => {
    if (
        profile.desired_profile &&
        profile.desired_profile.interest_countries &&
        profile.desired_profile.interest_countries.length
    ) {
        return profile.desired_profile.interest_countries.join(', ');
    }
    return '-';
};

export const getProfileStatusAndSubStatus = (profile) => {
    if (profile.profile_status) {
        let profile_status = profile.profile_status.slice(-1).pop();
        if (profile_status) {
            return startCase(profile_status.status);
        }
    }
    return '-';
};

export const getFileName = function (name) {
    if (!name) return name;
    if (name.length < 27) {
        return name;
    } else {
        return name.substr(0, 24) + '...';
    }
};
