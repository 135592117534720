export const schema = {
    type: 'object',
    definitions: {
        resource: {
            type: 'object',
            properties: {
                original_file_name: {
                    type: 'string',
                },
                url: {
                    type: 'string',
                },
                objectId: {
                    type: 'string',
                },
                type: {
                    type: 'string',
                },
            },
        },
    },
    properties: {
        comment: {
            type: 'string',
        },
        files: {
            type: 'array',
            items: {
                $ref: '#/definitions/resource',
            },
        },
    },
    required: ['comment'],
};
