import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// styles
import { useStyles } from './style';

// components
import { FormModal } from 'azent-storybook/dist';

//props
import {
    getCreateApplicationProps,
    months as defaultMonths,
} from '@/props-constants/application/create-application';
import { deserializeStudent } from '@/utils/deserializers/student-deserializer';
import routePaths, { studentPaths } from '@/utils/route-paths';

//utils
import { get } from 'lodash';

// actions
import { addStudentToStore } from '@/store/slices/studentSlice';
import { goToRoute } from '@/store/actions/navigationActions';

// API
import { getProfile, getUniversity, getApplicationPriorityData } from '@/api';

export const CreateApplication = ({ profileData, handleClose, isModalOpen }) => {
    const defaultData = { profile: profileData };
    const classes = useStyles();
    const dispatch = useDispatch();

    const [universityId, setUniversityId] = React.useState(null);
    const [months, setMonths] = React.useState(defaultMonths);
    const [intakeMonth, setIntakeMonth] = React.useState(null);
    const [universityLocation, setUniversityLocation] = React.useState(null);
    const [programId, setProgramId] = React.useState(null);
    const [programFee, setProgramFee] = React.useState(null);
    const [intakeType, setIntakeType] = React.useState(null);
    const [disableButton, setDisableButton] = React.useState(false);
    const [priorityData, setPriorityData] = React.useState(['']);

    useEffect(() => {
        if (universityId) {
            getUniversity(universityId).then((res) => {
                res.data &&
                    res.data.location &&
                    res.data.location.country &&
                    setUniversityLocation(res.data.location.country);
            });
        }
    }, [universityId]);

    useEffect(() => {
        if (!isModalOpen) {
            setUniversityLocation(null);
            setUniversityId(null);
            setProgramId(null);
            setProgramFee(null);
            setIntakeType(null);
            setDisableButton(false);
            setPriorityData(['']);
        }
    }, [isModalOpen]);

    useEffect(() => {
        if (profileData) {
            getApplicationPriorityData(profileData._id)
                .then((res) => {
                    setPriorityData(res.data.priorities);
                })
                .catch(() => {
                    setPriorityData(['']);
                });
        }
    }, [profileData]);

    let onChangeApp = function (formdata) {
        if (
            formdata &&
            formdata.university &&
            formdata.university._id &&
            formdata.university._id != universityId
        ) {
            setUniversityId(formdata.university._id);
            setUniversityLocation(null);
        }
        if (
            formdata &&
            formdata.program &&
            formdata.program._id &&
            formdata.program._id != programId
        ) {
            setProgramId(formdata.program._id);
            setMonths(formdata.program.intake_months);
            if (
                formdata.program.costs &&
                (formdata.program.costs.application_fee ||
                    formdata.program.costs.application_fee == 0)
            )
                setProgramFee({
                    fee: formdata.program.costs.application_fee,
                    currency: get(formdata, 'program.costs.currency', null),
                });
            else setProgramFee(null);
        }
        if (
            formdata &&
            formdata.intake &&
            formdata.intake.month &&
            formdata.intake.month != intakeMonth
        ) {
            setIntakeMonth(formdata.intake.month);
        }

        if (
            formdata &&
            formdata.program &&
            formdata.intake &&
            formdata.intake.month &&
            formdata.intake.year
        ) {
            const intakeType =
                formdata.program &&
                formdata.program.intakes.find(
                    (intake) =>
                        intake.intake_name === `${formdata.intake.month} ${formdata.intake.year}`,
                );
            if (intakeType) {
                if (intakeType.accepting_application) {
                    setIntakeType('Open');
                    setDisableButton(false);
                } else {
                    setIntakeType('Closed');
                    setDisableButton(true);
                }
            } else {
                setIntakeType('Not Open Yet');
                setDisableButton(false);
            }
        }
    };

    const onSuccessCallback = (applicationData) => {
        getProfile(profileData._id)
            .then((res) => {
                dispatch(addStudentToStore(deserializeStudent(res.data)));
                dispatch(
                    goToRoute(routePaths.STUDENT_PROFILE_VIEW, {
                        id: applicationData.profile_id,
                        aid: applicationData._id,
                        path: studentPaths.APPLICATION,
                    }),
                );
            })
            .catch((err) => console.log(err));
        handleClose();
    };

    const correctDependency = (data) => {
        if (
            data &&
            data.university &&
            data.university._id &&
            data.university._id !== universityId
        ) {
            data.program = null;
        }
        return {
            data: data,
        };
    };

    const createApplicationProps = useMemo(() => {
        return getCreateApplicationProps(
            dispatch,
            defaultData,
            classes,
            months,
            universityId,
            profileData,
            null,
            disableButton,
            intakeMonth,
            universityLocation,
            programFee,
            intakeType,
            priorityData,
        );
    }, [
        months,
        intakeType,
        disableButton,
        universityId,
        intakeMonth,
        universityLocation,
        programFee,
        priorityData,
    ]);

    return (
        <FormModal
            {...createApplicationProps}
            open={isModalOpen}
            onSuccessCallback={onSuccessCallback}
            handleClose={handleClose}
            onChangeApp={onChangeApp}
            isB2bFormModal={true}
            correctDependency={correctDependency}
        />
    );
};

CreateApplication.propTypes = {
    profileData: PropTypes.object,
    handleClose: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
};
