import React from 'react';
import PropTypes from 'prop-types';

// mui
import { Grid, Typography, Box } from '@mui/material';

// comps
import InfoCard from './InfoCard';

// styles
import { useStyles } from './styles';

export default function InfoBar({ title, cardList, statusLabelMapping, loading }) {
    const classes = useStyles();

    return (
        <Grid className={classes.root}>
            <Typography variant="h4" className={classes.title}>
                {title}
            </Typography>
            <Box className={classes.cardContainer}>
                {Object.keys(cardList).map(
                    (key, index) =>
                        statusLabelMapping[key] && (
                            <Grid className={classes.infoCardContainer} key={key} item>
                                <InfoCard
                                    loading={loading}
                                    label={statusLabelMapping[key]}
                                    value={cardList[key]}
                                    isSelected={index === 0}
                                    onClickCard={() => {
                                        /* NOTE: To be removed*/
                                    }}
                                />
                            </Grid>
                        ),
                )}
            </Box>
        </Grid>
    );
}

InfoBar.propTypes = {
    title: PropTypes.string,
    cardList: PropTypes.object,
    statusLabelMapping: PropTypes.object,
    loading: PropTypes.bool,
};
