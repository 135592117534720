import React from 'react';
import PropTypes from 'prop-types';

//mui
import { Box } from '@mui/material';

// css
import { useStyles } from './style';

// component
import ApplicationCard from '@/components/shared/ApplicationCard';
import { ActionBox } from 'azent-storybook/dist';

export default function AllApplications({ applications }) {
    const classes = useStyles();

    if (!applications || applications.length == 0)
        return (
            <ActionBox
                subtitle={{
                    text: 'There are no applications to display',
                    variant: 'body2',
                }}
                raised={false}
                elevation={0}
            />
        );

    return (
        <Box>
            {applications &&
                applications.map((data, index) => (
                    <ApplicationCard classes={classes} data={data} key={index}></ApplicationCard>
                ))}
        </Box>
    );
}

AllApplications.propTypes = {
    applications: PropTypes.array,
};
