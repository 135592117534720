export const getOnboardingSchema = (index, isViewPartnerProfile = false) => {
    let schema = {};
    switch (index) {
        case 0: {
            schema = {
                type: 'object',
                properties: {
                    step: {
                        type: 'number',
                    },
                    company_profile: {
                        type: 'object',
                        properties: {
                            organisation_name: {
                                type: 'string',
                            },
                            office_address: {
                                type: 'object',
                                properties: {
                                    address1: {
                                        type: 'string',
                                    },
                                    state: {
                                        type: 'string',
                                    },
                                    city: {
                                        type: 'string',
                                    },
                                    country: {
                                        type: 'string',
                                    },
                                    pin_code: {
                                        type: 'number',
                                    },
                                },

                                required: ['address1', 'country', 'pin_code'],
                            },
                            year_of_establishment: {
                                type: 'string',
                                dateSaveFormat: 'YYYY',
                            },
                            type_of_business: {
                                type: 'string',
                                enum: [
                                    'Sole Proprietorship',
                                    'Partnership',
                                    'Private Limited',
                                    'Individual',
                                    'Other',
                                ],
                            },
                            other_business_details: {
                                type: 'string',
                            },
                            number_of_employees: {
                                type: 'string',
                                enum: ['1-5', '6-10', '11-20', '20+'],
                            },
                            target_countries: {
                                type: 'array',
                                items: {
                                    type: 'string',
                                    enum: [
                                        'USA',
                                        'UK',
                                        'Canada',
                                        'Australia',
                                        'Ireland',
                                        'Germany',
                                        'New-Zealand',
                                        'Singapore',
                                    ],
                                },
                                minItems: 1,
                            },
                        },

                        required: [
                            'organisation_name',
                            'number_of_employees',
                            'year_of_establishment',
                            'type_of_business',
                            'target_countries',
                        ],
                        allOf: [
                            {
                                if: {
                                    properties: {
                                        type_of_business: {
                                            enum: ['Other'],
                                        },
                                    },
                                },
                                then: {
                                    required: ['other_business_details'],
                                },
                            },
                        ],
                    },
                    customer_info: {
                        type: 'object',
                        properties: {
                            students_sent_abroad_each_year: {
                                type: 'string',
                                enum: ['1-50', '51-100', '100+'],
                            },

                            top_three_streams: {
                                type: 'array',
                                items: {
                                    type: 'string',
                                    enum: [
                                        'IT',
                                        'STEM',
                                        'Management',
                                        'Creative Arts and Design',
                                        'Liberal Arts',
                                        'Paramedical',
                                        'Hospitality Mgmt.',
                                        'Agriculture, Building and Planning',
                                        'Medicine',
                                        'Law',
                                    ],
                                },
                                minItems: 1,
                            },
                            other_services: {
                                type: 'array',
                                items: {
                                    type: 'string',
                                    enum: [
                                        'IELTS',
                                        'TOEFL',
                                        'PTE',
                                        'SAT',
                                        'GRE',
                                        'GMAT',
                                        'Counselling',
                                        'Ticketing',
                                        'Visa Process',
                                        'Forex',
                                        'Student Loan',
                                    ],
                                },
                                minItems: 1,
                            },
                        },

                        required: [
                            'students_sent_abroad_each_year',
                            'top_three_streams',
                            'other_services',
                        ],
                    },
                },
            };

            if (isViewPartnerProfile) {
                schema.properties.fname = {
                    type: 'string',
                };
                schema.properties.lname = {
                    type: 'string',
                };
                schema.properties.mobile = {
                    type: 'string',
                };
                schema.properties.email = {
                    type: 'string',
                };
            }
            return schema;
        }
        case 1: {
            schema = {
                type: 'object',
                properties: {
                    point_of_contact: {
                        type: 'object',
                        properties: {
                            first_name: {
                                type: 'string',
                            },
                            last_name: {
                                type: 'string',
                            },
                            mobile: {
                                type: 'string',
                                minLength: 10,
                                maxLength: 10,
                            },
                            whatsapp_number: {
                                type: 'string',
                                minLength: 10,
                                maxLength: 10,
                            },
                            email: {
                                type: 'string',
                                format: 'email',
                                pattern: '^([A-Za-z0-9+_.-])+@([a-zA-Z0-9-])+(\\.[a-zA-Z0-9-]+)+$',
                            },
                            designation: {
                                type: 'string',
                            },
                            copy_number: {
                                type: 'boolean',
                            },
                        },
                        required: ['first_name', 'last_name', 'mobile', 'email', 'whatsapp_number'],
                    },
                },
            };
            return schema;
        }
        case 2: {
            schema = {
                type: 'object',
                definitions: {
                    resource: {
                        type: 'object',
                        properties: {
                            original_file_name: {
                                type: 'string',
                            },
                            url: {
                                type: 'string',
                            },
                            objectId: {
                                type: 'string',
                            },
                            type: {
                                type: 'string',
                            },
                            category: {
                                type: 'string',
                            },
                        },
                    },
                },
                properties: {
                    step: {
                        type: 'number',
                    },
                    company_profile: {
                        type: 'object',
                        properties: {
                            organisation_name: {
                                type: 'string',
                            },
                            office_address: {
                                type: 'object',
                                properties: {
                                    address1: {
                                        type: 'string',
                                    },
                                    state: {
                                        type: 'string',
                                    },
                                    city: {
                                        type: 'string',
                                    },
                                    country: {
                                        type: 'string',
                                    },
                                    pin_code: {
                                        type: 'number',
                                    },
                                },
                            },
                        },
                        required: ['organisation_name'],
                    },
                    onboarding_date: {
                        type: 'string',
                        format: 'date',
                        readOnly: true,
                    },
                    onboarding_location: {
                        type: 'string',
                    },
                    kyc: {
                        type: 'object',
                        properties: {
                            acceptance: {
                                type: 'boolean',
                            },
                        },
                        required: ['acceptance'],
                    },
                    documents: {
                        type: 'array',
                        items: {
                            $ref: '#/definitions/resource',
                        },
                    },
                    required_docs: {
                        type: 'string',
                        enum: [
                            'Documents Required',
                            `1. Company's Pan Card`,
                            `2. Director's Aadhar Card`,
                            `3. Company Registration (Not Required for Freelancer)`,
                            `4. Cancelled Cheque`,
                            `5. GST Certificate`,
                            `6. Govt Registration for Study Overseas Business ( Only for  Punjab based Referral partners)`,
                        ],
                    },
                },

                required: ['onboarding_date', 'onboarding_location', 'documents'],
            };
            return schema;
        }
        default: {
            return schema;
        }
    }
};
