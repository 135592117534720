import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.primary.main,
        zIndex: '3',
        position: 'absolute',
    },
    loading: {
        fontSize: '16px',
        fontWeight: '500',
        '&::after': {
            display: 'inline-block',
            fontSize: '16px',
            fontWeight: '500',
            animation: '$dotty steps(1,end) 2s infinite',
            content: "''",
        },
    },
    '@keyframes dotty': {
        '0%': { content: "''" },
        '25%': { content: "'.'" },
        '50%': { content: "'..'" },
        '75%': { content: "'...'" },
        '100%': { content: "''" },
    },
    loaderImage: {
        height: '40px',
        width: '40px',
    },
}));
