export const toastMessages = {
    backendIssue: {
        message: 'There is a problem to complete this request right now!',
        toasterColor: 'error',
    },
    warningMessage: {
        message: 'You have not been authorised to perform update',
        toasterColor: 'warning',
    },
    successMessage: {
        message: 'Status updated successfully',
        toasterColor: 'success',
    },
    passwordSetMessage: {
        message: 'Password Created Successfully. Kindly login!',
        toasterColor: 'success',
    },
    emailSent: {
        message: 'Email is sent to respective Partner!',
        toasterColor: 'success',
    },
    mobileValidateMessage: {
        message: 'Emergency contact number should not be same as mobile number',
        toasterColor: 'warning',
    },
    partnerDeclarationAcceptance: {
        message: 'Kindly accept the declaration terms to proceed',
        toasterColor: 'warning',
    },
    newPartnerPasswordSet: {
        message: 'Password Created Successfully.',
        toasterColor: 'success',
    },
    applicationLimit: {
        message:
            'You have already created 5 applications. Please connect with your business manager to create more applications',
        toasterColor: 'warning',
    },
    confirmPasswordErrorMsg: {
        message: 'Password must match confirm password',
        toasterColor: 'error',
    },
    passwordGuidelines: {
        message: 'Please check the password guidelines below',
        toasterColor: 'error',
    },
    paymentSuccessStatus: {
        message: 'This status will be updated automatically.',
        toasterColor: 'error',
    },
    gteVerificationCompletedStatus: {
        message: 'This status will be updated automatically.',
        toasterColor: 'error',
    },
    paymentRequestedError: {
        message:
            'Previous link is still active, please copy/paste it from the status history section',
        toasterColor: 'error',
    },
    studentAssignToSubAgent: {
        message: 'Students assigned successfully',
        toasterColor: 'success',
    },
    passwordSetSubAgentMessage: {
        message: 'Password updated successfully! Logging out…',
        toasterColor: 'success',
    },
    passwordDontMatch: {
        message: `Your Passwords Don't Match`,
        toasterColor: 'error',
    },
};
