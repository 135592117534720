import {
    universitySearch,
    getUniversities,
    getProfiles,
    searchCityStateCountry,
    keywordSearch,
} from '@/api';

export const getCourseFinderUiSchema = function ({ countries, gpaPercentage, profileId }) {
    return {
        type: 'VerticalLayout',
        elements: [
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        label: 'Find Programs',
                        scope: '#/properties/global_search',
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/countries',
                        options: {
                            component: 'multiSelect',
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/interest_regions',
                        label: 'Regions',
                        options: {
                            component: 'search',
                            searchApi: searchCityStateCountry,
                            searchApiArgs: [
                                {
                                    countries: countries && countries.length ? countries : [],
                                    cities: `{{search}}`,
                                },
                            ],

                            disableDelayedFetch: true,
                            valueKey: 'label',
                            labelKey: 'label',
                        },
                        rule: {
                            effect: countries && countries.length ? 'ENABLE' : 'DISABLE',
                            condition: {
                                scope: '',
                                schema: {},
                            },
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/universities',
                        options: {
                            component: 'search',
                            searchApi: universitySearch,
                            searchApiArgs:
                                countries && countries.length
                                    ? [
                                          {
                                              q: '{{search}}',
                                              countries: countries,
                                          },
                                      ]
                                    : [{ q: '{{search}}' }],
                            indexApi: getUniversities,
                            indexApiArgs: [{ ids: '{{ids}}' }],
                            valueKey: '_id',
                            labelKey: 'name',
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/categories',
                        label: 'Programs',
                        options: {
                            component: 'search',
                            searchApi: keywordSearch,
                            searchApiArgs: [{ text: '{{search}}' }],
                            valueKey: 'keyword',
                            labelKey: 'keyword',
                        },
                    },
                ],
            },
            /* {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/subCategories',
                        label: 'Program Sub Categories',
                        options: {
                            component: 'search',
                            searchApi: subCategorySearch,
                            searchApiArgs:
                                categories && categories.length
                                    ? [
                                          {
                                              q: '{{search}}',
                                              categories: categories,
                                          },
                                      ]
                                    : [{ q: '{{search}}' }],
                            valueKey: 'name',
                            labelKey: 'name',
                        },
                    },
                ],
                rule: {
                    effect: categories && categories.length ? 'ENABLE' : 'DISABLE',
                    condition: {
                        scope: '',
                        schema: {},
                    },
                },
            }, */
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/intakes',
                        options: {
                            component: 'multiSelect',
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/level_and_type',
                        label: 'Degree Types',
                        options: {
                            component: 'multiSelect',
                        },
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/application_fee',
                    },
                ],
            },
            {
                type: 'HorizontalLayout',
                elements: [
                    {
                        type: 'Control',
                        scope: '#/properties/client_type',
                        default: 'Client',
                        label: 'University Type',
                    },
                ],
            },
            {
                type: 'VerticalLayout',
                elements: [
                    {
                        type: 'VerticalLayout',
                        elements: [
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/exam',
                                        label: 'Eligibility Criteria',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: 'VerticalLayout',
                        elements: [
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/scores/properties/overall',
                                        options: {
                                            smallField: true,
                                            stepCount: 0.5,
                                        },
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/scores/properties/listening',
                                        options: {
                                            smallField: true,
                                            stepCount: 0.5,
                                        },
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/scores/properties/reading',
                                        options: {
                                            smallField: true,
                                            stepCount: 0.5,
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/scores/properties/speaking',
                                        options: {
                                            smallField: true,
                                            stepCount: 0.5,
                                        },
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/scores/properties/written',
                                        options: {
                                            smallField: true,
                                            stepCount: 0.5,
                                        },
                                    },

                                    {
                                        type: 'VerticalLayout',
                                        elements: [],
                                    },
                                ],
                            },
                        ],
                        rule: {
                            effect: 'SHOW',
                            condition: {
                                scope: '#/properties/exam',
                                schema: {
                                    enum: ['IELTS'],
                                },
                            },
                        },
                    },
                    {
                        type: 'VerticalLayout',
                        elements: [
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/scores/properties/overall',
                                        options: {
                                            smallField: true,
                                        },
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/scores/properties/listening',
                                        options: {
                                            smallField: true,
                                        },
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/scores/properties/reading',
                                        options: {
                                            smallField: true,
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/scores/properties/speaking',
                                        options: {
                                            smallField: true,
                                        },
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/scores/properties/written',
                                        options: {
                                            smallField: true,
                                        },
                                    },

                                    {
                                        type: 'VerticalLayout',
                                        elements: [],
                                    },
                                ],
                            },
                        ],
                        rule: {
                            effect: 'SHOW',
                            condition: {
                                scope: '#/properties/exam',
                                schema: {
                                    enum: ['TOEFL', 'PTE'],
                                },
                            },
                        },
                    },
                    {
                        type: 'VerticalLayout',
                        elements: [
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/scores/properties/overall',
                                        options: {
                                            smallField: true,
                                        },
                                    },
                                    {
                                        type: 'Control',
                                        label: 'verbal',
                                        scope: '#/properties/scores/properties/verbalScore',
                                        options: {
                                            smallField: true,
                                        },
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/scores/properties/quantitative',
                                        options: {
                                            smallField: true,
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/scores/properties/written',
                                        options: {
                                            smallField: true,
                                        },
                                    },

                                    {
                                        type: 'VerticalLayout',
                                        elements: [],
                                    },
                                    {
                                        type: 'VerticalLayout',
                                        elements: [],
                                    },
                                ],
                            },
                        ],
                        rule: {
                            effect: 'SHOW',
                            condition: {
                                scope: '#/properties/exam',
                                schema: {
                                    enum: ['GRE', 'GMAT'],
                                },
                            },
                        },
                    },
                    {
                        type: 'VerticalLayout',
                        elements: [
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/scores/properties/overall',
                                        options: {
                                            smallField: true,
                                        },
                                    },
                                    {
                                        type: 'Control',
                                        scope: '#/properties/scores/properties/quantitative',
                                        options: {
                                            smallField: true,
                                        },
                                    },
                                    {
                                        type: 'Control',
                                        label: 'verbal',
                                        scope: '#/properties/scores/properties/verbalScore',
                                        options: {
                                            smallField: true,
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'HorizontalLayout',
                                elements: [
                                    {
                                        type: 'Control',
                                        scope: '#/properties/scores/properties/essay',
                                        options: {
                                            smallField: true,
                                        },
                                    },

                                    {
                                        type: 'VerticalLayout',
                                        elements: [],
                                    },
                                    {
                                        type: 'VerticalLayout',
                                        elements: [],
                                    },
                                ],
                            },
                        ],
                        rule: {
                            effect: 'SHOW',
                            condition: {
                                scope: '#/properties/exam',
                                schema: {
                                    enum: ['SAT'],
                                },
                            },
                        },
                    },
                    {
                        type: 'HorizontalLayout',
                        elements: [
                            {
                                type: 'Control',
                                scope: '#/properties/scores/properties/overall',
                                options: {
                                    smallField: true,
                                },
                            },
                            {
                                type: 'VerticalLayout',
                                elements: [],
                            },
                            {
                                type: 'VerticalLayout',
                                elements: [],
                            },
                        ],
                        rule: {
                            effect: 'SHOW',
                            condition: {
                                scope: '#/properties/exam',
                                schema: {
                                    enum: ['DUOLINGO'],
                                },
                            },
                        },
                    },
                ],
            },
            {
                type: 'VerticalLayout',
                elements: [
                    {
                        type: 'HorizontalLayout',
                        elements: [
                            {
                                type: 'Control',
                                scope: '#/properties/backlogs',
                                options: {
                                    type: 'number',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                type: 'VerticalLayout',
                elements: [
                    {
                        type: 'HorizontalLayout',
                        elements: [
                            {
                                type: 'Control',
                                scope: '#/properties/highest_education',
                            },
                        ],
                        rule: {
                            effect: profileId ? 'DISABLE' : 'HIDE',
                            condition: {
                                scope: '#',
                                schema: {},
                            },
                        },
                    },
                ],
            },
            {
                type: 'VerticalLayout',
                elements: [
                    {
                        type: 'HorizontalLayout',
                        elements: [
                            {
                                type: 'Control',
                                scope: '#/properties/cgpa',
                                label: 'Marks Obtained (Gpa/Percentage)',
                            },
                        ],
                    },
                    {
                        type: 'HorizontalLayout',
                        elements: [
                            {
                                type: 'Control',
                                scope: '#/properties/scale',
                                label: 'Out of Marks (Gpa/percentage)',
                            },
                        ],
                        rule: {
                            effect: gpaPercentage > 15 ? 'DISABLE' : 'ENABLE',
                            condition: {
                                scope: '#/properties/cgpa',
                                schema: {},
                            },
                        },
                    },
                ],
                rule: {
                    effect: 'SHOW',
                    condition: {
                        scope: '#/properties/level_and_type',
                        schema: {
                            enum: [
                                `PG Degree/Masters`,
                                `UG Degree/Bachelors`,
                                `PG Certificate/Diploma`,
                                `UG Certificate/Diploma`,
                                `Integrated Courses (UG+PG)`,
                                `UG Dual Degree`,
                                `PG Dual Degree`,
                                `UG Pathway Courses`,
                                `PG Pathway Courses`,
                            ],
                        },
                    },
                },
            },
        ],
    };
};

export const courseFinderStudentSelectionUiSchema = {
    type: 'HorizontalLayout',
    elements: [
        {
            type: 'Control',
            scope: '#/properties/student',
            options: {
                component: 'search',
                searchApi: getProfiles,
                searchApiArgs: [{ q: '{{search}}' }],
                valueKey: '_id',
                labelExp: '{{first_name}} {{last_name}}, {{mobile}}',
            },
            label: 'Student',
        },
    ],
};
